import useIdCheckDetails from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2/hooks/useIdCheckDetails';
import ErrorDetails from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v2/GovernmentVerification/ErrorDetails';
import Outcome from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v2/GovernmentVerification/Outcome';
import UnknownDetails from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v2/GovernmentVerification/UnknownDetails';
import VerificationDetails from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v2/GovernmentVerification/VerificationDetails';
import styles from 'modules/new-applications/css/IdentificationChecks.css';
import React, { Fragment, ReactElement } from 'react';
import isBlank from 'utils/isBlank';

const GovernmentVerification = ({
  identificationCheck,
  signature,
}): ReactElement | null => {
  const {
    dobVerified,
    idVerified,
    nameVerified,
    pass,
    verificationToken,
  } = useIdCheckDetails(identificationCheck, signature);

  const { status, retryAttempts } = identificationCheck;
  const idType = signature.identificationType;

  if (status === 'completed' && isBlank(idVerified)) {
    return null;
  }

  return (
    <Fragment>
      <div className={styles.e_signature_header}>
        <Outcome
          idVerified={idVerified}
          manuallyApproved={signature.manuallyApproved}
          pass={pass}
          status={status}
        />
        {status === 'completed' && (
          <Fragment>
            <VerificationDetails
              dobVerified={dobVerified}
              idVerified={idVerified}
              nameVerified={nameVerified}
              idType={idType}
              signature={signature}
              verificationToken={verificationToken}
            />
            <UnknownDetails idVerified={idVerified} />
          </Fragment>
        )}
        {status === 'errored' && <ErrorDetails retryAttempts={retryAttempts} />}
      </div>
    </Fragment>
  );
};

export default GovernmentVerification;
