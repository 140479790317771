import { Link } from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/QAndAHelpLink/styles';
import React from 'react';

export const BUSINESS_NUMBER = 'businessNumber';
export const COMPANY_NUMBER = 'companyNumber';
export const AUTHORISATION_REQUEST = 'authorisationRequest';
export const AUTHORISATION_DECLINED = 'authorisationDeclined';
export const NAME_OF_BUSINESS_OR_PERSON_APPLYING =
  'nameOfBusinessOrPersonApplying';

export const HELP_TEXT = {
  [AUTHORISATION_DECLINED]: '',
  [AUTHORISATION_REQUEST]: '',
  [BUSINESS_NUMBER]: 'Find your Business Number',
  [COMPANY_NUMBER]: 'Find your Company Number',
};

export const QUESTION_AND_ANSWER_CONTENTS = [
  {
    description: (
      <div>
        Businesses in Australia are required to register for an ABN for tax
        purposes. You can find your ABN on ABN Look up{' '}
        <Link href="https://abr.business.gov.au" target="_blank">
          abr.business.gov.au
        </Link>
        . If you don&apos;t have one, you may wish to register using this link.
      </div>
    ),
    key: BUSINESS_NUMBER,
    region: 'AU',
    title: 'Australian Business Number',
  },
  {
    description: (
      <div>
        All Companies in Australia are automatically given an ACN upon
        registration. You can find your ACN on the ASIC Connect website{' '}
        <Link href="https://connectonline.asic.gov.au" target="_blank">
          connectonline.asic.gov.au
        </Link>
        .
      </div>
    ),
    key: COMPANY_NUMBER,
    region: 'AU',
    title: 'Australian Company Number',
  },
  {
    description:
      'This means the authoriser has declined your request to apply for trade \
      credit on behalf of this business, please get in touch with this person \
      directly to discuss. If required, you can resend the authorisation.',
    key: AUTHORISATION_DECLINED,
    region: 'all',
    title: 'Authorisation declined',
  },
  {
    description:
      'This business already has a trade profile on the 1Centre technology \
      platform. The individuals listed are associated with the business you \
      are applying for an account with. Due to privacy laws and online data \
      protection requirements, we need their permission to authorise you to \
      apply for an account. Simply select the individuals, and click request \
      authorisation to get their permission.',
    key: AUTHORISATION_REQUEST,
    region: 'all',
    title: 'Authorisation request',
  },
  {
    description:
      "Please select the business (or personal if applicable) you're trading as for this application. If these don't apply, please select 'Other business/person'.",
    key: NAME_OF_BUSINESS_OR_PERSON_APPLYING,
    region: 'all',
    title: 'Name of business/person applying',
  },
  {
    description: (
      <div>
        Businesses in New Zealand are required to register for an NZBN for tax
        purposes. You can find your NZBN on NZBN Look up{' '}
        <Link href="https://www.nzbn.govt.nz" target="_blank">
          www.nzbn.govt.nz
        </Link>
        . If you don&apos;t have one, you may wish to register using this link.
      </div>
    ),
    key: BUSINESS_NUMBER,
    region: 'NZ',
    title: 'New Zealand Business Number',
  },
  {
    description: (
      <div>
        All Companies in New Zealand are automatically given an NZCN upon
        registration. You can find your NZCN on the NZ Companies Office website{' '}
        <Link href="https://www.companiesoffice.govt.nz" target="_blank">
          www.companiesoffice.govt.nz
        </Link>
        .
      </div>
    ),
    key: COMPANY_NUMBER,
    region: 'NZ',
    title: 'New Zealand Company Number',
  },
];
