const overrides = {
  staging: true,
  api: {
    protocol: "https",
    wsProtocal: "wss",
    hostname: "staging-api.cds.1centre.com",
    version: "/v1",
    ttl: 8000,
    headers: { Accept: "application/vnd.api+json" },
  },
  datadog_site: "datadoghq.com",
  datadog_token: "pubdd2261646ef970240f62066be051c4f6",
  datadog_env: "staging",
  nz_post_api: {
    protocol: "https",
    hostname: "api.nzpost.co.nz/addresschecker",
    version: "/1.0",
    ttl: 8000,
    headers: { Accept: "application/json" },
  },
  ip_geolocation_api: {
    protocol: "https",
    hostname: "geoip.1centre.com",
    ttl: 8000,
  },
  mixpanel: "bafec2e79ed69fd2a87c554514f614c9",
  stripe: "pk_test_n6GI5Orm3pR0pGKJoK5YfOnR",
  google_client: "AIzaSyDKGo2WHyJkwXMXwUzQEYemMOPNRYzy2H8",
  sentry_dsn:
    "https://cfee30e30a444d879e1409daed42d0c9@o98413.ingest.sentry.io/1449163",
  sentry_token:
    "67d745e8346f43ea99d0f51f75e08c926de3b6616ef542f4961f8041c76811d0",
  sentry_release: "staging",
  sentry_project: "1centre-app-staging",
  churnzero: "vXbp62SewDQAIK6G2_AUufCOrBX63igJ9Q1gBa02P2s",
  recaptcha_api: {
    hostname: "staging-api.cds.1centre.com/node",
    protocol: "https",
    ttl: 8000,
  },
  recaptcha_key: "6Lca1igaAAAAAPtlb8doOafzCpGDZA0lZkUjZ7Hd",
  visible_reporting_phase: [1, 2],
};
module.exports = overrides;
