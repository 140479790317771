import React, { ReactElement } from 'react';
import ControlWrapper from 'modules/consumer-onboarding/v2/ControlWrapper';

const FormField = (props): ReactElement => {
  const { children, label } = props;

  return (
    <div className="field">
      {label && <label className="label">{label}</label>}
      <ControlWrapper>{children}</ControlWrapper>
    </div>
  );
};

export default FormField;
