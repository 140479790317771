import api from 'api';
import Switch from 'modules/shared/components/widgets/interactive/Switch';
import React, { ReactElement, useEffect, useState } from 'react';

import useParamsCompare from '../../hooks/useParamsCompare';
import useRequestQuery from '../../hooks/useRequestQuery';
import Message from '../Message';
import Panel from '../Panel';
import { Props } from '../Panel/model';
import Feedback from './Feedback';

function CustomerFeedback(props: Props): ReactElement {
  const { accessToken, entityId, filterState, currentRoleTypes } = props;

  const [current, setFeedbackSource] = useState('consumer');

  const params = {
    ...filterState,
    feedback_source: current,
  };

  let responseType = '';

  if (current === 'consumer') {
    responseType = 'customer';
  } else {
    responseType = 'staff';
  }

  const apiKey = 'ask_nicely';
  const apiAction = () =>
    api('reporting', accessToken, entityId).get({ api: apiKey, params });
  const { data: queryData, error, forceUpdate, loading } = useRequestQuery(
    apiAction
  );

  const paramString = JSON.stringify(params);
  const { oldParams, setOldParams } = useParamsCompare(paramString);

  useEffect(() => {
    if (paramString !== oldParams) {
      setOldParams(paramString);
      forceUpdate();
    }
  }, [params]);

  const data = queryData;

  const handleChange = (value: string) => {
    setFeedbackSource(value);
    forceUpdate();
  };

  const visibleSwitch =
    currentRoleTypes.includes('admin') ||
    currentRoleTypes.includes('approvers');

  return (
    <Panel
      {...props}
      action={
        visibleSwitch && (
          <Switch
            leftLabel="customer"
            rightLabel="staff"
            leftOption="consumer"
            rightOption="supplier"
            current={current}
            handleChange={handleChange}
            cssOverrides={{
              marginRight: 0,
              marginTop: '-0.5rem',
            }}
            buttonCssOverrides={{
              display: 'inline-block',
              width: '100px',
            }}
          />
        )
      }
      beta
      error={false}
      loading={loading}
      title="Customer feedback"
      comingSoon={false}
      refresh={forceUpdate}
      tooltip={
        <span>
          NPS feedback will be updated on the hour. Please refresh your screen
          to ensure the latest information. If you use 1Account all feedback
          will be aggregated up based on Tier. Standard users will also see
          feedback from their own customers. Enjoy!
        </span>
      }
    >
      {data &&
        data.length > 0 &&
        data.map((feedbackData) => (
          <Feedback
            key={`${feedbackData.attributes.name}-${feedbackData.attributes.responded_at}`}
            {...feedbackData.attributes}
          />
        ))}
      {!data && (
        <Message
          faProps={{
            icon: ['fas', 'smile-wink'],
          }}
          message="This widget is coming soon."
        />
      )}
      {data && Array.isArray(data) && data.length === 0 && !loading && !error && (
        <Message
          faProps={{
            icon: ['fas', 'check-circle'],
          }}
          message={`No ${responseType} feedback.`}
        />
      )}
    </Panel>
  );
}

export default CustomerFeedback;
