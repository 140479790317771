import React, { useState, useEffect } from 'react';
import FileUpload from 'modules/FileUpload';
import SectionHeader from 'modules/shared/components/v2/SectionHeader';
import { COB_TRADEACCOUNT_LOAD_SIGNATURE } from 'modules/consumer-onboarding/constants/section';
import { AUTHORISATION_UPDATE_FORMATTED_SCRAPED_DETAILS } from 'modules/authorisation/constants';
import {
  IDENTITY_SCRAPE_IMAGE_END,
  IDENTITY_SET_ANTI_FRAUD_RESULT,
} from 'modules/identity/constants';
import { getErrorMessage } from '../components/utils';
import mixpanel from 'mixpanel-browser';
import { get } from 'lodash';

function IDUpload(props) {
  const {
    application,
    authorisation,
    apiType,
    authorisationID,
    currentUser,
    dispatch,
    distinctId,
    getOcrResult,
    hasError,
    idNameMatched,
    image_64,
    isAntiFraudEnabled,
    isAntiFraudPassing,
    isFaceMatchEnabled,
    isValidIdentification,
    isTokenised,
    overrideIdValidation,
    resourceType,
    signature,
  } = props;

  const fileId = 'image_64';
  // Textract has a limit of 10mb for filesize
  // https://docs.aws.amazon.com/textract/latest/dg/limits.html
  const maxFileSize = 10000000;

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const error = getErrorMessage({
      idNameMatched,
      image_64,
      isAntiFraudPassing,
      isFaceMatchEnabled,
      isValidIdentification,
      overrideIdValidation,
      type: fileId,
    });
    setErrorMessage(error);
  }, [image_64, hasError, isAntiFraudPassing, isValidIdentification]);

  const updateSignatureImageScrape = (data) => {
    if (authorisation && authorisation.data) {
      return dispatch({
        type: AUTHORISATION_UPDATE_FORMATTED_SCRAPED_DETAILS,
        payload: data,
      });
    }

    const updatedSignature = {
      ...signature,
      formatted_scraped_details: data,
    };
    dispatch({
      type: COB_TRADEACCOUNT_LOAD_SIGNATURE,
      payload: { ...updatedSignature },
    });
  };

  const callback = (data) => {
    dispatch({ type: IDENTITY_SCRAPE_IMAGE_END, payload: data });
    dispatch({
      type: IDENTITY_SET_ANTI_FRAUD_RESULT,
      payload: data,
    });
    updateSignatureImageScrape(data);
    if (getOcrResult) {
      getOcrResult(data);
    }

    if (isAntiFraudEnabled) {
      const attributes = {
        'Entity ID': get(currentUser, 'currentEntity.id'),
        distinct_id: currentUser.id,
      };

      if (application) {
        attributes['Application ID'] = application.id;
      }

      mixpanel.track('1CAF id uploaded', attributes);
    }
  };

  let noPDF = isAntiFraudEnabled;

  if (typeof isFaceMatchEnabled === 'boolean') {
    noPDF = isFaceMatchEnabled;
  }

  return (
    <div>
      <SectionHeader title="ID Upload">
        <p>
          Please upload your Driver's License or Passport.
          {isTokenised &&
            ' Your ID will be removed from our system once checked.'}
        </p>
      </SectionHeader>
      <FileUpload
        acceptedFileTypes={noPDF && ['jpg', 'jpeg', 'png']}
        apiType={apiType}
        authorisationID={authorisationID}
        attachmentType="identification_image"
        callback={callback}
        distinctId={distinctId}
        errorMessage={errorMessage}
        fileId={fileId}
        hasError={hasError}
        resourceType={resourceType}
        uploadedFile={image_64}
        maxFileSize={maxFileSize}
      />
    </div>
  );
}

export default IDUpload;
