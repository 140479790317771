import capitalize from 'lodash.capitalize';
import get from 'lodash.get';
import AddonConfigModel from 'models/AddonConfigModel';
import { useState } from 'react';
import isBlank from 'utils/isBlank';

const channelToAddonType = (channel: string): string | null => {
  switch (channel) {
    case 'qr':
      return 'qr_code';
    case 'website':
      return 'website_button';
    default:
      return null;
  }
};

export const useApplicationTypeOptions = () => {
  const [applicationTypes, setApplicationTypes] = useState<any[]>([]);

  const applicationTypeOptions = applicationTypes.map((applicationType) => {
    return { label: capitalize(applicationType), value: applicationType };
  });

  return { applicationTypeOptions, applicationTypes, setApplicationTypes };
};

export const getApplicationTypes = async ({
  errorCallback,
  successCallback,
  supplierId,
  channel,
}: {
  errorCallback?: (error: any) => void;
  successCallback?: (applicationTypes: any[]) => void;
  supplierId: string;
  channel: string;
}): Promise<void> => {
  const addonType = channelToAddonType(channel);
  if (isBlank(addonType)) {
    throw new Error(`Invalid channel '${channel}'`);
  }

  try {
    const result = await AddonConfigModel.fetchAddonConfigByAddonType({
      accessToken: null,
      addonType: [addonType],
      entityId: supplierId,
    });

    const config = result[0] || {};
    const latestConfig =
      get(config, 'attributes.history_version.data', [])[0] || {};

    if (successCallback) {
      successCallback(get(latestConfig, 'attributes.application_types', []));
    }
  } catch (error) {
    console.error(error);
    if (errorCallback) {
      errorCallback(error);
    }
  }
};

export const getAddonConfigBySupplierAndChannel = async ({
  supplierId,
  channel,
}: {
  supplierId: string;
  channel: string;
}): Promise<any> => {
  const addonType = channelToAddonType(channel);
  if (isBlank(addonType)) {
    throw new Error(`Invalid channel '${channel}'`);
  }

  return AddonConfigModel.fetchAddonConfigByAddonType({
    accessToken: null,
    addonType: [addonType],
    entityId: supplierId,
  }).then((result) => result[0]);
};

export const setApplicationTypeFromSalesChannel = ({
  isApplicationLoaded,
  loadedApplication,
  setApplicationTypes,
  setIsApplicationTypesLoaded,
}: {
  isApplicationLoaded: boolean;
  loadedApplication: any;
  setApplicationTypes: (options: any[]) => void;
  setIsApplicationTypesLoaded: (isLoaded: boolean) => void;
}): void => {
  if (!isApplicationLoaded) {
    return;
  }

  if (loadedApplication.isNewRecord) {
    throw 'Sales channel needs to have an application.';
  }

  const loadedType = loadedApplication.applicationType;
  if (isBlank(loadedType)) {
    throw 'Sales channel application should have an application type';
  }

  setApplicationTypes([loadedType]);
  setIsApplicationTypesLoaded(true);
};
