/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import VideoIcon from '@material-ui/icons/VideoCam';
import styles from './css/VideoModal.css';
import CloseButton from 'modules/shared/components/inputs/CloseButton';

class VideoModal extends React.Component {
  constructor() {
    super();

    this.state = { showModal: false };
  }

  componentDidMount() {
    // Whether the video modal is initially visible
    if (this.props.isModalVisible) {
      this._showModal();
    }
  }

  _showModal() {
    this._track();
    this.setState({ showModal: true });
  }

  _hideModal() {
    this.setState({ showModal: false });

    if (this.props.onHideModal !== undefined) {
      this.props.onHideModal();
    }
  }

  _track() {
    const { current_user } = this.props;

    if (current_user) {
      mixpanel.track(`Watch video ${this.props.videoName}`, {
        distinct_id: current_user.data.data.id,
        'Entity ID': current_user.current_entity.id,
      });
    }
  }

  _isSupplier() {
    const { current_user } = this.props;

    return !!(
      current_user &&
      current_user.current_entity &&
      current_user.current_entity.attributes &&
      current_user.current_entity.attributes.supplier
    );
  }

  _videoIndicatorDisplay() {
    if (!this._isSupplier()) {
      return;
    }

    const color = this.props.color || 'primary';
    // Whether to show/hide the video icon. If it is hidden, then an external
    // element handles the showing of the modal
    const isVideoIconVisible = this.props.isVideoIconVisible || true;

    if (!isVideoIconVisible) {
      return;
    }

    return (
      <VideoIcon
        style={{ cursor: 'pointer' }}
        color={color}
        onClick={this._showModal.bind(this)}
      />
    );
  }

  render() {
    const { entity_region } = this.props;

    return (
      <span className={styles.container}>
        {this._videoIndicatorDisplay()}
        {this.state.showModal && (
          <div className={styles.modal}>
            <CloseButton
              text="Close"
              css_class={'mandatory_button_close'}
              handleClick={this._hideModal.bind(this)}
            />
            <div className={styles.fade}>
              <div className={styles.content}>
                <iframe
                  src={videoLinks[this.props.videoName][entity_region]}
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        )}
      </span>
    );
  }
}

const videoLinks = {
  additional: {
    NZ: 'https://player.vimeo.com/video/375570068',
    AU: 'https://player.vimeo.com/video/375570068',
  },
  auto_decisioning: {
    NZ: 'https://player.vimeo.com/video/327596180',
    AU: 'https://player.vimeo.com/video/327596180',
  },
  cards: {
    NZ: 'https://player.vimeo.com/video/375571750',
    AU: 'https://player.vimeo.com/video/375571750',
  },
  color_palette: {
    NZ: 'https://player.vimeo.com/video/378932652',
    AU: 'https://player.vimeo.com/video/378932652',
  },
  credit_flow: {
    NZ: 'https://player.vimeo.com/video/319640132',
    AU: 'https://player.vimeo.com/video/347223387',
  },
  cash_flow: {
    NZ: 'https://player.vimeo.com/video/352404762',
    AU: 'https://player.vimeo.com/video/352404762',
  },
  entity_email_templates: {
    NZ: 'https://player.vimeo.com/video/337165739',
    AU: 'https://player.vimeo.com/video/337165739',
  },
  financials: {
    NZ: 'https://player.vimeo.com/video/375573207',
    AU: 'https://player.vimeo.com/video/375573207',
  },
  paperless: {
    NZ: 'https://player.vimeo.com/video/371485098',
    AU: 'https://player.vimeo.com/video/371485098',
  },
  reviewing_application: {
    NZ: 'https://player.vimeo.com/video/325578506',
    AU: 'https://player.vimeo.com/video/325578506',
  },
  setup_supplier_profile: {
    NZ: 'https://player.vimeo.com/video/319640824',
    AU: 'https://player.vimeo.com/video/319640824',
  },
  toggle_feature: {
    NZ: 'https://player.vimeo.com/video/353485792',
    AU: 'https://player.vimeo.com/video/353485792',
  },
  trade_account_invites: {
    NZ: 'https://player.vimeo.com/video/330927937',
    AU: 'https://player.vimeo.com/video/330927937',
  },
  website_button: {
    NZ: 'https://player.vimeo.com/video/334559963',
    AU: 'https://player.vimeo.com/video/334559963',
  },
};

export default connect((state, _ownProps) => {
  return {
    current_user: state.current_user,
    entity_region: state.current_user.current_entity.attributes.region || 'NZ',
  };
})(VideoModal);
