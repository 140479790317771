import SignatureDetails from 'modules/new-applications/components/application-sections/IdentificationCheck/SignatureDetails';
import styles from 'modules/new-applications/css/IdentificationChecks.css';
import React, { ReactElement } from 'react';
import getApplicableAntiFraudRulesForCreditFile from 'utils/anti-fraud/getApplicableAntiFraudRulesForCreditFile';

const IdentificationDetails = ({
  application,
  authorisation,
  signature,
}): ReactElement | null => {
  const antiFraudCategoryRules = getApplicableAntiFraudRulesForCreditFile({
    application,
    authorisation,
  });

  if (
    !antiFraudCategoryRules.requiresIdentificationSection ||
    !signature.identificationDetailsVisible
  ) {
    return null;
  }

  return (
    <div className="mb-3">
      <div className={styles.e_signature_header}>
        <span className="is-underlined">ID details</span>
      </div>
      <SignatureDetails
        label={signature.identificationTypeLabel}
        content={signature.identificationNumber}
      />
      <SignatureDetails
        label="Card number"
        content={signature.identificationCardNumber}
      />
      <SignatureDetails
        label="Version number"
        content={signature.identificationVersion}
      />
      <SignatureDetails
        label="Expiry date"
        content={signature.formattedIdentificationExpiryDate}
      />
      <SignatureDetails label="DOB" content={signature.formattedDOB} />
    </div>
  );
};

export default IdentificationDetails;
