import { isCentraPassAllVerified } from 'models/IdentificationCheckModel';

type FormattedDetails = {
  dob_verified: 'Pass' | 'Fail' | undefined | null;
  identification_verification_string: 'Pass' | 'Fail' | 'Unknown';
  name_verified: 'Pass' | 'Fail' | undefined | null;
  verification_token: string | null;
};

type IdentificationCheck = {
  formattedDetails: FormattedDetails;
  status: 'completed' | 'errored';
};

type Signature = {
  manuallyApproved: boolean;
};

export type ReturnType = {
  dobVerified: 'Pass' | 'Fail' | undefined | null;
  idVerified: 'Pass' | 'Fail' | 'Unknown';
  nameVerified: 'Pass' | 'Fail' | undefined | null;
  verificationToken: string | null;
  pass: boolean | null;
};

export default function useIdCheckDetails(
  identificationCheck: IdentificationCheck,
  signature: Signature
): ReturnType {
  const { formattedDetails, status } = identificationCheck;
  const details = formattedDetails || {};
  const noIdCheck =
    status === 'errored' ||
    (Object.keys(details).length === 0 &&
      typeof signature.manuallyApproved !== 'boolean');

  const {
    dob_verified: dobVerified,
    identification_verification_string: idVerified,
    name_verified: nameVerified,
    verification_token: verificationToken,
  } = details;

  const pass = isCentraPassAllVerified({
    dobVerified,
    idVerified,
    nameVerified,
  });

  return {
    dobVerified,
    idVerified,
    nameVerified,
    pass: noIdCheck ? null : pass,
    verificationToken,
  };
}
