/* Import actions */
import {
  loadAmlCheckRules,
  loadCard,
  loadCardsAddons,
  loadCurrentEntity,
} from 'modules/profile/actions.js';
import AdditionalDocuments from 'modules/profile/components/AdditionalDocuments.js';
import AnzicsCode from 'modules/profile/components/AnzicsCode.js';
/* Import components */
import BasicInfo from 'modules/profile/components/BasicInfo.js';
/* Import CSS */
import styles from 'modules/profile/components/css/EditProfile.css';
import EntityColorPaletteTemplates from 'modules/profile/components/EntityColorPaletteTemplate/index.js';
import EntityEmailTemplates from 'modules/profile/components/EntityEmailTemplates.js';
import ExcludedSources from 'modules/profile/components/ExcludedSources.js';
import GuarantorTerms from 'modules/profile/components/GuarantorTerms.js';
import KeyContact from 'modules/profile/components/KeyContact.js';
import Logo from 'modules/profile/components/Logo.js';
import ManageApprovalLevels from 'modules/profile/components/ManageApprovalLevels.js';
import ManageUsers from 'modules/profile/components/ManageTeam.js';
import MandatoryChecks from 'modules/profile/components/MandatoryChecks.js';
import PaperlessTerms from 'modules/profile/components/PaperlessTerms.js';
import RulesetRedirectModal from 'modules/profile/components/RulesetRedirectModal.js';
import Terms from 'modules/profile/components/Terms.js';
import TradeReferenceCheck from 'modules/profile/components/TradeReferenceCheck.js';
import Clickable from 'modules/shared/components/widgets/interactive/Clickable';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip.js';
import VideoModal from 'modules/shared/components/widgets/interactive/VideoModal';
import AdminTitle from 'modules/shared/components/widgets/static/AdminTitle';
import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
/* Import libraries */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import CustomerChecklist from '../CustomerChecklist';

function EditProfile(props) {
  const {
    active_tab,
    dispatch,
    held_rulesets,
    loading,
    location,
    possible_tabs,
    router,
    user_entity_links,
    title,
  } = props;
  const [shownRulesetModal, setShownRulesetModal] = useState(false);
  const [toolTipTabName, setToolTipTabName] = useState('');

  function changeTab(v) {
    if (v === 'business_details') {
      dispatch(loadCurrentEntity());
    }
    browserHistory.push({
      pathname: location.pathname,
      query: { ...location.query, active_tab: v },
    });
  }

  useEffect(() => {
    dispatch(loadCurrentEntity());
    dispatch(loadAmlCheckRules());
    dispatch(loadCardsAddons());
    dispatch(loadCard());
  }, []);

  function mouseOut() {
    setToolTipTabName('');
  }

  function mouseOver(tabName) {
    setToolTipTabName(tabName);
  }

  const tabs = [];
  Object.keys(possible_tabs).forEach((k) => {
    let tab_class = 'tab_default';
    let tip_name;
    if (possible_tabs[k] !== 0) {
      if (k === 'email_customisation' && isMobile()) return;
      if (active_tab === k) {
        tab_class = 'tab_active';
      }
      if (k === 'your_team' && !isMobile()) {
        tip_name = 'YourTeamPermission';
        if (user_entity_links) {
          if (user_entity_links.length > 1) {
            tip_name = 'YourTeamToggle';
          }
        }
      }
      tabs.push(
        <Clickable
          key={k}
          target={() => changeTab(k)}
          css_style="profile_tab"
          onMouseOver={() => mouseOver(k)}
          onMouseOut={() => mouseOut()}
        >
          <PopperTooltip
            title={toolTips[k]}
            open={Boolean(toolTips[k]) && toolTipTabName === k}
            placement="bottom"
          >
            <span className={styles[tab_class]}>{`${k.replace(
              /_/g,
              ' '
            )}`}</span>
          </PopperTooltip>
        </Clickable>
      );
    }
  });

  if (loading) {
    return <SimpleLoader />;
  }

  return (
    <section className={styles.section}>
      {held_rulesets && !shownRulesetModal && (
        <RulesetRedirectModal
          held_rulesets={held_rulesets}
          dismissHandler={() => setShownRulesetModal(true)}
        />
      )}
      <div className={styles.header_row}>
        <div className={styles.header}>
          <div className={styles.heading}>
            <AdminTitle text={title} />
            <VideoModal videoName="setup_supplier_profile" />
          </div>
        </div>
      </div>
      <br />
      <div className={styles.tabs}>{tabs}</div>
      {possible_tabs[active_tab]}
    </section>
  );
}

const toolTips = {
  your_team:
    'Add new team members here. Also, check out our new Permissions Feature which allows users to tailor checks down to an application level - click <edit> to view.',
};

//TODO: make each tab a singular component
export default connect((state, ownProps) => {
  const defaults = {
    active_tab: 'business_details',
    consumer_tabs: {
      business_details: [
        <BasicInfo key="basic_info" />,
        <Logo key="logo" />,
        <AnzicsCode key="anzic_codes" />,
      ],
      your_team: [<ManageUsers key="your_team" location={ownProps.location} />],
    },
    context_title: 'Settings',
    supplier_tabs: {
      account_rules: [
        <MandatoryChecks key="mandatory_checks" autosave={true} />,
        <TradeReferenceCheck key="trade_reference_check" autosave={true} />,
        <ExcludedSources key="excluded_application_sources" />,
        <AdditionalDocuments key="additional_documents" autosave={true} />,
      ],
      // payment_info: [ <InvoiceOptions key={'invoice_options'} /> ],
      business_details: [
        <BasicInfo key="basic_info" />,
        <CustomerChecklist key="customer_checklist" />,
        <KeyContact key="key_contact" />,
        <Logo key="logo" />,
      ],
      email_customisation: [
        <EntityEmailTemplates key="entity_email_templates" />,
      ],
      supplier_terms: [
        <Terms key="terms" />,
        <GuarantorTerms key="GuarantorTerms" />,
        <PaperlessTerms key="PaperlessTerms" />,
      ],
      your_team: [
        <ManageApprovalLevels key="approval_levels" />,
        <ManageUsers key="your_team" location={ownProps.location} />,
      ],
    },
    title: 'Business profile',
  };

  const entLoading = state.manage_profile.current_entity_loading;
  const noEntity = !state.manage_profile.current_entity;
  const basicInfoUpdating = state.manage_profile.settings_update_loading;
  const contactUpdating = state.manage_profile.settings_contact_update_loading;
  const activeTab = ownProps.location.query.active_tab || defaults.active_tab;

  if (isFeatureEditEnabled('Color')) {
    defaults.supplier_tabs.colour_palette = [
      <EntityColorPaletteTemplates key="colour_palette" />,
    ];
  }

  const settingsAmlCheckRulesLoading =
    state.manage_profile.settingsAmlCheckRulesLoading;
  const settingsCardsAddonLoading =
    state.manage_profile.settingsCardsAddonLoading;

  let legal_type = null;
  let tabs = {};

  if (!noEntity) {
    legal_type = state.manage_profile.current_entity.attributes.legal_type;
    tabs = state.manage_profile.current_entity.attributes.supplier
      ? defaults.supplier_tabs
      : defaults.consumer_tabs;
  }

  const loading =
    entLoading ||
    settingsAmlCheckRulesLoading ||
    settingsCardsAddonLoading ||
    noEntity;

  return {
    active_tab: activeTab,
    context_title: defaults.context_title,
    current_pricing_plan: state.pricing_plans.current_pricing_plan,
    held_rulesets: state.manage_profile.held_rulesets,
    loading,
    possible_tabs: tabs,
    title: defaults.title,
    updating: entLoading || basicInfoUpdating || contactUpdating,
    user_entity_links: state.current_user.data.included
      ? state.current_user.data.included
      : null,
  };
})(EditProfile);
