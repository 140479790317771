import React from 'react';
import IdentificationOutcomeV1 from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v1';
import IdentificationOutcomeV2 from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v2';

const IdentificationOutcome = (props) => {
  const { application } = props;

  if (application.antiFraudEnabled) {
    return <IdentificationOutcomeV2 {...props} />;
  }

  return <IdentificationOutcomeV1 {...props} />;
};

IdentificationOutcome.defaultProps = {
  isErrorsVisible: true,
};

export default IdentificationOutcome;
