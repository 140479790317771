/* Import libraries */
import Button from 'modules/shared/components/inputs/Button';
import EmailInput from 'modules/shared/components/inputs/EmailInput';
/* Import components */
import TextInput from 'modules/shared/components/inputs/TextInput';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';
import React from 'react';
import { connect } from 'react-redux';

/* Import actions */
import {
  clearSettingsState,
  setEntitySettingsFormValue,
  updateEntityContactInfo,
} from '../actions';
/* Import CSS */
import styles from './css/CommonEditProfile.css';

const KeyContact = createClass({
  _emailBlurHandler(value, isValid) {
    if (this.checkAllValid()) this.submit();
  },

  _emailChangeHandler(value, isValid) {
    const { dispatch } = this.props;
    const { form_errors } = this.state;

    dispatch(setEntitySettingsFormValue('key_contact_email', value));

    if (isValid) {
      const { key_contact_email, ...rest } = form_errors;
      this.setState({
        form_errors: { ...rest },
      });
    } else {
      this.setState({
        form_errors: { ...form_errors, key_contact_email: true },
      });
    }
  },

  checkAllValid() {
    const { form_values } = this.props;
    let isAllValid = true;
    let errors = new Object();

    if (
      !form_values['key_contact_first_name'] ||
      form_values['key_contact_first_name'].length < 1
    ) {
      errors = {
        ...errors,
        key_contact_first_name: 'You must enter a first name.',
      };
      isAllValid = false;
    }
    if (
      !form_values['key_contact_last_name'] ||
      form_values['key_contact_last_name'].length < 1
    ) {
      errors = {
        ...errors,
        key_contact_last_name: 'You must enter a last name.',
      };
      isAllValid = false;
    }
    if (
      !form_values['key_contact_phone_number'] ||
      form_values['key_contact_phone_number'].length < 1
    ) {
      errors = {
        ...errors,
        key_contact_phone_number: 'You must enter a phone number.',
      };
      isAllValid = false;
    }

    if (!this.refs.email._validate(form_values['key_contact_email'])) {
      errors = { ...errors, key_contact_email: true };
      isAllValid = false;
    }

    this.setState({
      form_errors: errors,
    });

    return isAllValid;
  },

  checkValid(target) {
    switch (target.name) {
      case 'key_contact_first_name':
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...this.state.form_errors,
              key_contact_first_name: 'You must enter a first name.',
            },
          });
        } else {
          var {
            key_contact_first_name,
            ...rest_errors
          } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case 'key_contact_phone_number':
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...this.state.form_errors,
              key_contact_phone_number: 'You must enter a phone number.',
            },
          });
        } else {
          var {
            key_contact_phone_number,
            ...rest_errors
          } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case 'key_contact_last_name':
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...this.state.form_errors,
              key_contact_last_name: 'You must enter a last name.',
            },
          });
        } else {
          var {
            key_contact_last_name,
            ...rest_errors
          } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
    }
  },

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearSettingsState('key_contact'));
  },

  getInitialState() {
    return { form_errors: new Object() };
  },

  handleBlur(event) {
    if (this.checkAllValid()) this.submit();
  },

  handleChange(event) {
    const { dispatch } = this.props;
    if (event.target) {
      this.checkValid(event.target);
    }
    dispatch(setEntitySettingsFormValue(event.target.name, event.target.value));
  },

  render() {
    const {
      title,
      update_text,
      help_text,
      form_inputs,
      form_values,
      changes_made,
    } = this.props;
    const { form_errors } = this.state;

    let loader = null;
    let update_button_text = update_text;
    let update_click = this.submit;
    let disable_btn = false;

    if (
      !form_values['key_contact_first_name'] ||
      form_values['key_contact_first_name'].length < 1 ||
      !form_values['key_contact_last_name'] ||
      form_values['key_contact_last_name'].length < 1 ||
      !form_values['key_contact_phone_number'] ||
      form_values['key_contact_phone_number'].length < 1 ||
      this.state.form_errors.hasOwnProperty('key_contact_email')
    ) {
      update_button_text = 'invalid info';
      disable_btn = true;
    }

    if (this.props.update_loading) {
      loader = <span className={styles.loading}></span>;
      update_button_text = 'saving';
      update_click = (event) => {};
    }

    let update_btn = null;
    if (changes_made) {
      update_btn = (
        <div className={styles.button}>
          <Button
            handleClick={update_click}
            text={update_button_text}
            disabled={disable_btn}
            grey={disable_btn}
          />
          {loader}
        </div>
      );
    }

    const form_elements = [];

    Object.keys(form_inputs).forEach((value, index) => {
      if (value === 'key_contact_email') {
        form_elements.push(
          <EmailInput
            key={index}
            id={value}
            ref="email"
            handleChange={this._emailChangeHandler}
            handleBlur={this._emailBlurHandler}
            label={form_inputs[value].label}
            value={form_values[value]}
            disabled={!isFeatureEditEnabled('Business')}
            required={form_inputs[value].required}
          />
        );
      } else {
        form_elements.push(
          <TextInput
            key={index}
            id={value}
            handleChange={this.handleChange}
            handleBlur={this.handleBlur}
            label={form_inputs[value].label}
            value={form_values[value]}
            error={form_errors[value]}
            disabled={!isFeatureEditEnabled('Business')}
            required={form_inputs[value].required}
          />
        );
      }
    });

    return (
      <div className={styles.row}>
        <div className={styles.block}>
          <div className={styles.liner}>
            <h2 className={styles.header}>{title}</h2>
            {update_btn}
            <div className={styles.items}>{form_elements}</div>
          </div>
        </div>
        <div className={styles.help}>
          <div
            className={styles.help_info}
            dangerouslySetInnerHTML={{ __html: help_text }}
          ></div>
        </div>
      </div>
    );
  },

  submit() {
    const { dispatch, form_values } = this.props;
    dispatch(updateEntityContactInfo(form_values));
  },
});

/* eslint-disable sort-keys-fix/sort-keys-fix */
const defaults = {
  title: 'Key contact (required)',
  update_text: 'update contact',
  help_text:
    'This is the key contact between your business and 1Centre. Key contacts for trade connections can be viewed in <a href="/dashboard/connections">my connections</a>',
  form_inputs: {
    key_contact_first_name: {
      label: 'First name',
      required: true,
    },
    key_contact_last_name: {
      label: 'Last name',
      required: true,
    },
    key_contact_phone_number: {
      label: 'Contact phone number',
      required: true,
    },
    key_contact_email: {
      label: 'Email',
      required: true,
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

module.exports = connect((state, ownProps) => {
  const ent = state.manage_profile.current_entity.attributes;
  const s = state.manage_profile;

  const key_contact_phone_number =
    s.settings_key_contact_phone_number != null
      ? s.settings_key_contact_phone_number
      : ent.key_contact_phone_number;
  const key_contact_first_name =
    s.settings_key_contact_first_name != null
      ? s.settings_key_contact_first_name
      : ent.key_contact_first_name;
  const key_contact_last_name =
    s.settings_key_contact_last_name != null
      ? s.settings_key_contact_last_name
      : ent.key_contact_last_name;
  const key_contact_email =
    s.settings_key_contact_email != null
      ? s.settings_key_contact_email
      : ent.key_contact_email;
  const changes_made =
    s.settings_section_edited != null
      ? s.settings_section_edited['key_contact']
      : false;

  return {
    changes_made,
    form_inputs: defaults.form_inputs,
    form_values: {
      key_contact_email,
      key_contact_first_name,
      key_contact_last_name,
      key_contact_phone_number,
    },
    help_text: defaults.help_text,
    title: defaults.title,
    update_loading: s.settings_contact_update_loading,
    update_text: defaults.update_text,
  };
})(KeyContact);
