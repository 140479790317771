import { Skeleton } from '@material-ui/lab';
import * as Sentry from '@sentry/browser';
import api from 'api';
import get from 'lodash.get';
import ApplicationModel from 'models/ApplicationModel';
import EntityModel from 'models/EntityModel';
import UserModel from 'models/UserModel';
import SelectField from 'modules/consumer-onboarding/v2/ApplicationDetails/SelectField';
import TradingNameField from 'modules/consumer-onboarding/v2/ApplicationDetails/TradingNameField';
import {
  getConsumerUserEntityLinks,
  useConsumerIdOptions,
} from 'modules/consumer-onboarding/v2/ApplicationDetails/utils/consumerUserEntityLinks';
import {
  getTradingNames,
  useTradingNamesState,
} from 'modules/consumer-onboarding/v2/ApplicationDetails/utils/tradingNames';
import ButtonGroupWrapper from 'modules/consumer-onboarding/v2/ButtonGroupWrapper';
import ColumnWrapper from 'modules/consumer-onboarding/v2/ColumnWrapper';
import { OnboardingChecklistContext } from 'modules/consumer-onboarding/v2/Container';
import {
  FormWrapper,
  SectionHeader,
} from 'modules/consumer-onboarding/v2/styles';
import {
  fetchApplicationDetails,
  hasDetailsRequired,
} from 'modules/consumer-onboarding/v2/utils/applicationDetailsHelper';
import {
  getApplicationTypes,
  setApplicationTypeFromSalesChannel,
  useApplicationTypeOptions,
} from 'modules/consumer-onboarding/v2/utils/applicationTypes';
import Button from 'modules/shared/components/inputs/Button';
import { onSetActiveQuestionAndAnswer } from 'modules/shared/components/top/Overlay/redux/actions';
import { NAME_OF_BUSINESS_OR_PERSON_APPLYING } from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/constants/questionAndAnswers';
import QuestionIcon from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/QAndAHelpLink/QuestionIcon';
import { COUNTRY_OPTIONS } from 'modules/shared/constants/country_states_options';
import useYupValidationResolver from 'modules/shared/hooks/useYupValidationResolver';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-latest';
import { connect } from 'react-redux';
// @ts-ignore-next-line: Type cannot be inferred
import { browserHistory } from 'react-router';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import * as yup from 'yup';

type FormValues = {
  applicationType: string;
  country: string;
  countryState: string;
  consumerId: string;
  tradingNameId: string;
};

const APPLICATION_TYPE_FIELD_NAME = 'applicationType';
const COUNTRY_FIELD_NAME = 'country';
const COUNTRY_STATE_FIELD_NAME = 'countryState';
const CONSUMER_ID_FIELD_NAME = 'consumerId';
const TRADING_NAME_ID_FIELD_NAME = 'tradingNameId';

const buildValidOptions = (options) => {
  const validOptions = options.map((option) => option.value);

  // Need to pass an empty string as part of the `oneOf` Yup validation as
  // a work around because it is prioritising `oneOf` validation over
  // `required` validation
  validOptions.push('');

  return validOptions;
};

const validationSchema = ({
  validApplicationTypes,
  validConsumerIds,
  validCountries,
  validCountryStates,
  validTradingNameIds,
}) =>
  yup.object().shape({
    applicationType: yup
      .string()
      .required('This field is mandatory')
      .oneOf(validApplicationTypes, 'Invalid option'),
    consumerId: yup
      .string()
      .when('$isConsumerIdRequired', {
        is: true,
        otherwise: (schema) => schema,
        then: (schema) => schema.required('This field is mandatory'),
      })
      .oneOf(validConsumerIds, 'Invalid option'),
    country: yup
      .string()
      .required('This field is mandatory')
      .oneOf(validCountries, 'Invalid option'),
    countryState: yup
      .string()
      .when('$isCountryStatesRequired', {
        is: true,
        otherwise: (schema) => schema,
        then: (schema) => schema.required('This field is mandatory'),
      })
      .oneOf(validCountryStates, 'Invalid option'),
    tradingNameId: yup
      .string()
      .when('$isTradingNameRequired', {
        is: true,
        otherwise: (schema) => schema,
        then: (schema) => schema.required('This field is mandatory'),
      })
      .when('$isTradingNameHasValue', {
        is: true,
        otherwise: (schema) =>
          schema.oneOf(validTradingNameIds, 'Invalid option'),
        then: (schema) => schema,
      }),
  });

const setIndividualFieldValue = ({
  fieldName,
  setValue,
  value,
}: {
  fieldName: string;
  setValue: any;
  value: any;
}) => {
  if (isPresent(value)) {
    setValue(fieldName, value);
  }
};

const getRedirectPath = (applicationId: string, search: string) =>
  `/register/consumer/${applicationId}/business/other-details${search}`;

const setDefaultValuesFromLoadedApplication = ({
  application,
  setValue,
}: {
  application: any;
  setValue: any;
}) => {
  setIndividualFieldValue({
    fieldName: APPLICATION_TYPE_FIELD_NAME,
    setValue,
    value: application.applicationType,
  });
  setIndividualFieldValue({
    fieldName: CONSUMER_ID_FIELD_NAME,
    setValue,
    value: application.consumerId,
  });
  setIndividualFieldValue({
    fieldName: COUNTRY_FIELD_NAME,
    setValue,
    value: application.region,
  });
  setIndividualFieldValue({
    fieldName: COUNTRY_STATE_FIELD_NAME,
    setValue,
    value: application.state,
  });
  setIndividualFieldValue({
    fieldName: TRADING_NAME_ID_FIELD_NAME,
    setValue,
    value: application.supplierTradingNameId,
  });
};

const ApplicationDetails = (props): ReactElement => {
  const {
    location: {
      query: { application_id: applicationId, channel },
      search,
    },
  } = props;

  const { activeQuestionAndAnswer, currentUser, dispatch, supplier } = props;
  //#region hook states
  const [loadedApplication, setLoadedApplication] = useState<ApplicationModel>(
    new ApplicationModel({})
  );
  const [isApplicationLoaded, setIsApplicationLoaded] = useState(false);
  const [isApplicationTypesLoaded, setIsApplicationTypesLoaded] = useState(
    false
  );
  const [isTradingNamesLoaded, setIsTradingNamesLoaded] = useState(false);
  const [isConsumerIdsLoaded, setIsConsumerIdsLoaded] = useState(false);
  const [isConsumerIdHasRegion, setIsConsumerIdHasRegion] = useState(false);
  //#endregion

  const isPageReady =
    isApplicationLoaded &&
    isApplicationTypesLoaded &&
    isTradingNamesLoaded &&
    isConsumerIdsLoaded;

  //#region Dropdown option states
  const {
    applicationTypes,
    applicationTypeOptions,
    setApplicationTypes,
  } = useApplicationTypeOptions();

  const {
    consumerIdOptions,
    setConsumerUserEntityLinks,
  } = useConsumerIdOptions(supplier.id);

  // TradingName option can be from any of the following:
  // 1. no trading name
  // 2. Regular account with "store_name"
  // 3. 1Account trading names
  const {
    tradingNameOptions,
    tradingNameRawOptions,
    setTradingNames,
  } = useTradingNamesState(supplier.type);

  const [stateOptions, setStateOptions] = useState<Array<any>>([]);
  //#endregion

  //#region Initialize dropdown values
  // Supplier trading names
  useEffect(() => {
    if (isPresent(supplier.id)) {
      getTradingNames({
        currentUser,
        errorCallback: () => setIsTradingNamesLoaded(true),
        successCallback: (retrievedTradingNames) => {
          setTradingNames(retrievedTradingNames);
          setIsTradingNamesLoaded(true);
        },
        supplierId: supplier.id,
        channel,
      });
    }
  }, [supplier.id]);

  // Application types
  useEffect(() => {
    if (isPresent(applicationId) && channel === 'sales') {
      setApplicationTypeFromSalesChannel({
        isApplicationLoaded,
        loadedApplication,
        setApplicationTypes,
        setIsApplicationTypesLoaded,
      });
    } else if (isPresent(supplier.id)) {
      getApplicationTypes({
        channel,
        errorCallback: () => setIsApplicationTypesLoaded(true),
        successCallback: (applicationTypes) => {
          setApplicationTypes(applicationTypes);
          setIsApplicationTypesLoaded(true);
        },
        supplierId: supplier.id,
      });
    }
  }, [
    supplier.id,
    applicationId,
    // @ts-ignore-next-line: ApplicationModel is not a TS file causing this type error
    loadedApplication.applicationType,
    channel,
    isApplicationLoaded,
  ]);

  useEffect(() => {
    getConsumerUserEntityLinks({
      accessToken: currentUser.accessToken,
      email: currentUser.email,
      errorCallback: () => setIsConsumerIdsLoaded(true),
      successCallback: (result) => {
        setConsumerUserEntityLinks(get(result, 'user_entity_links', []));
        setIsConsumerIdsLoaded(true);
      },
    });
  }, [currentUser.email]);
  //#endregion

  //#region React-Hook-Form related code
  const {
    clearErrors,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      applicationType: '',
      consumerId: '',
      country: '',
      countryState: '',
      tradingNameId: '',
    },
    mode: 'onSubmit',
    resolver: useYupValidationResolver(
      () =>
        validationSchema({
          validApplicationTypes: buildValidOptions(applicationTypeOptions),
          validConsumerIds: buildValidOptions(consumerIdOptions),
          validCountries: buildValidOptions(COUNTRY_OPTIONS),
          validCountryStates: buildValidOptions(stateOptions),
          validTradingNameIds: buildValidOptions(tradingNameOptions),
        }),
      {},
      {
        context: {
          isConsumerIdRequired: consumerIdOptions.length > 1,
          isCountryStatesRequired: isPresent(stateOptions),
          isTradingNameHasValue: isPresent(
            // @ts-ignore-next-line: ApplicationModel is not a TS file causing this type error
            loadedApplication.supplierTradingNameId
          ),
          isTradingNameRequired: tradingNameOptions.length > 1,
        },
      }
    ),
  });

  //#region Register fields
  register(APPLICATION_TYPE_FIELD_NAME);
  register(CONSUMER_ID_FIELD_NAME);
  register(COUNTRY_FIELD_NAME);
  register(COUNTRY_STATE_FIELD_NAME);
  register(TRADING_NAME_ID_FIELD_NAME);
  //#endregion

  const setSelectValue = (event) => {
    const fieldName = get(event, 'target.name');
    const value = get(event, 'target.value');

    setValue(fieldName, value);
    clearErrors(fieldName);
  };

  const setConsumerIdValue = (event) => {
    setSelectValue(event);
    const selectedId = get(event, 'target.value');
    const selectedOption =
      consumerIdOptions.find((option) => option.value === selectedId) || {};

    const selectedOptionRegion = selectedOption.region;
    const selectedOptionState = selectedOption.state;

    if (selectedOptionRegion) {
      setValue(COUNTRY_FIELD_NAME, selectedOptionRegion);
      setValue(COUNTRY_STATE_FIELD_NAME, selectedOptionState);
      setIsConsumerIdHasRegion(true);
    } else {
      setValue(COUNTRY_FIELD_NAME, '');
      setValue(COUNTRY_STATE_FIELD_NAME, '');
      setIsConsumerIdHasRegion(false);
    }
  };
  //#endregion

  //#region Set check application status
  useEffect(() => {
    if (applicationId) {
      fetchApplicationDetails({
        applicationId,
        currentUser,
        errorCallback: () => setIsApplicationLoaded(true),
        successCallback: (result) => {
          const application = new ApplicationModel(
            get(result, 'data.data', {})
          );
          setLoadedApplication(application);

          if (hasDetailsRequired({ application, consumerIdOptions })) {
            browserHistory.push(getRedirectPath(application.id, search));
          } else {
            setIsApplicationLoaded(true);
            setDefaultValuesFromLoadedApplication({
              application,
              setValue,
            });
          }
        },
      });
    } else {
      setIsApplicationLoaded(true);
    }
  }, [applicationId, currentUser]);
  //#endregion

  //#region Set initial form values
  useEffect(() => {
    if (isPageReady && consumerIdOptions.length === 1) {
      setValue(CONSUMER_ID_FIELD_NAME, consumerIdOptions[0].value);
    }
  }, [consumerIdOptions, isPageReady]);

  useEffect(() => {
    if (isPageReady && applicationTypes.length === 1) {
      setValue(APPLICATION_TYPE_FIELD_NAME, applicationTypes[0]);
    }
  }, [applicationTypes, isPageReady]);

  const country = watch(COUNTRY_FIELD_NAME);
  useEffect(() => {
    const selectedOption = COUNTRY_OPTIONS.find(
      (option) => option.value === country
      // eslint-disable-next-line object-curly-spacing
    ) || { states: [] };

    setStateOptions(selectedOption.states || []);

    // Reset the State field to blank if there are no state options
    if (isBlank(selectedOption.states)) {
      setValue(COUNTRY_STATE_FIELD_NAME, '');
    }
  }, [country]);
  //#endregion

  const { onboardingChecklist } = useContext(OnboardingChecklistContext);

  const onSubmit = async (data) => {
    const {
      applicationType: application_type,
      consumerId: consumer_id,
      country: region,
      countryState: state,
      tradingNameId: supplier_trading_name_id,
    } = data;

    const attributes = {
      application_type,
      channel: channel || 'website',
      check_list: onboardingChecklist,
      consumer_id,
      region,
      state,
      supplier_id: supplier.id,
      supplier_trading_name_id,
    };

    if (applicationId) {
      attributes['application_id'] = applicationId;
    }

    const onboardingAPI = api('onboarding', currentUser.accessToken);

    try {
      const response = await onboardingAPI.applicationDetails({ attributes });

      const applicationAttributes = get(response, 'data.data', {});

      if (applicationAttributes.id) {
        browserHistory.push(getRedirectPath(applicationAttributes.id, search));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitError = (error) => {
    console.error(error);
    Sentry.captureException(error, (scope) => {
      scope.setExtra(
        'file',
        'src/modules/consumer-onboarding/v2/ApplicationDetails/index.tsx'
      );
      scope.setExtra('function', 'onSubmitError');
      scope.setExtra('applicationId', applicationId);
      scope.setExtra('channel', channel);
      scope.setExtra('supplierId', supplier.id);
      scope.setExtra('consumerId', currentUser.id);

      return scope;
    });
  };

  let content = (
    <Skeleton animation="wave" variant="rect" height={100} width="100%" />
  );

  if (isPageReady) {
    content = (
      <FormWrapper onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <SelectField
          errors={errors}
          isVisible={
            consumerIdOptions.length > 1 &&
            // @ts-ignore-next-line: ApplicationModel is not a TS file causing this type error
            isBlank(loadedApplication.consumerId)
          }
          label={
            <span>
              Name of business/person applying{' '}
              <span
                onClick={() =>
                  dispatch(
                    onSetActiveQuestionAndAnswer(
                      NAME_OF_BUSINESS_OR_PERSON_APPLYING
                    )
                  )
                }
              >
                <QuestionIcon
                  isActive={
                    activeQuestionAndAnswer ===
                    NAME_OF_BUSINESS_OR_PERSON_APPLYING
                  }
                />
              </span>
            </span>
          }
          name={CONSUMER_ID_FIELD_NAME}
          onChange={setConsumerIdValue}
          options={consumerIdOptions}
          watch={watch}
        />

        <SelectField
          errors={errors}
          isVisible={
            // @ts-ignore-next-line: ApplicationModel is not a TS file causing this type error
            isBlank(loadedApplication.region) && !isConsumerIdHasRegion
          }
          label="Country"
          name={COUNTRY_FIELD_NAME}
          onChange={setSelectValue}
          options={COUNTRY_OPTIONS}
          watch={watch}
        />

        <SelectField
          errors={errors}
          isVisible={
            isPresent(stateOptions) &&
            // @ts-ignore-next-line: ApplicationModel is not a TS file causing this type error
            isBlank(loadedApplication.state) &&
            !isConsumerIdHasRegion
          }
          label="State"
          name={COUNTRY_STATE_FIELD_NAME}
          onChange={setSelectValue}
          options={stateOptions}
          watch={watch}
        />

        <TradingNameField
          errors={errors}
          // @ts-ignore-next-line: ApplicationModel is not a TS file causing this type error
          loadedSupplierTradingNameId={loadedApplication.supplierTradingNameId}
          // @ts-ignore-next-line: ApplicationModel is not a TS file causing this type error
          loadedSupplierName={loadedApplication.supplierName}
          name={TRADING_NAME_ID_FIELD_NAME}
          onChange={setSelectValue}
          options={tradingNameOptions}
          rawOptions={tradingNameRawOptions}
          watch={watch}
        />

        <SelectField
          errors={errors}
          // @ts-ignore-next-line: ApplicationModel is not a TS file causing this type error
          isDisabled={isPresent(loadedApplication.applicationType)}
          isVisible={applicationTypeOptions.length > 1}
          label="Application type"
          name={APPLICATION_TYPE_FIELD_NAME}
          onChange={setSelectValue}
          options={applicationTypeOptions}
          watch={watch}
        />

        <ButtonGroupWrapper>
          <Button text="Next" type="submit" />
        </ButtonGroupWrapper>
      </FormWrapper>
    );
  }

  return (
    <div className="columns is-mobile is-centered">
      <ColumnWrapper>
        <SectionHeader>Application details</SectionHeader>
        {content}
      </ColumnWrapper>
    </div>
  );
};

export default connect((state) => {
  const currentUser = get(state, 'current_user', {});

  return {
    activeQuestionAndAnswer: get(
      state,
      'applicationOverlayReducer.activeQuestionAndAnswer',
      ''
    ),
    currentUser: UserModel.fromCurrentUser(currentUser),
    supplier: new EntityModel(get(state, 'cob_section.supplier', {})),
  };
})(ApplicationDetails);
