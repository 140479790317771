/* eslint-disable max-lines */
import {
  NZ_DRIVER_LICENCE_NUMBER_REGEX,
  NZ_DRIVER_LICENCE_VERSION_REGEX,
  NZ_PASSPORT_NUMBER_REGEX,
} from 'constants';
import get from 'lodash.get';
import {
  idUploadSignatureRequired,
  isFaceMatchEnabled,
} from 'modules/card-management-onboarding/components/Details/utils';
import AddressAndIdDetails from 'modules/card-management-shared/AddressAndIdDetails';
import commonStyles from 'modules/card-management-shared/css/Section.css';
import { getEighteenYearsAgo } from 'modules/card-management-shared/utils';
import IdentityCapture from 'modules/identity/components/IdentityCapture/IdentityCapture';
import Button from 'modules/shared/components/inputs/Button';
import TextInput from 'modules/shared/components/inputs/TextInput';
import SectionHeader from 'modules/shared/components/v2/SectionHeader';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import isPresent from 'utils/isPresent';
import * as yup from 'yup';

function Details(props) {
  const { signature } = props;

  const inputForm = [
    { label: 'First name', name: 'first_name', required: true },
    { label: 'Middle name', name: 'middle_name', required: false },
    { label: 'Last name', name: 'last_name', required: true },
  ];

  return (
    <div className={commonStyles.section_block}>
      <SectionHeader title="Confirm your details" />
      <div className={commonStyles.row}>
        {inputForm.map((input) => (
          <div key={input.label} className={commonStyles.one_third_col}>
            <TextInput
              label={input.label}
              required={input.required}
              value={signature[input.name]}
            />
          </div>
        ))}
        <AddressAndIdDetails {...props} region="NZ" />
      </div>
    </div>
  );
}

export function Authorisation(props) {
  const {
    cardholderAuthorisation,
    cardholderAuthorisationId,
    displayConfig,
    frontFaceImage,
    isFaceMatchEnabled,
    identificationImage,
    signatureRequired,
  } = props;

  let schemaConfig = {};
  const ocrFields = [
    'first_name',
    'last_name',
    'middle_name',
    'dob',
    'identification_type',
    'region',
    'identification_number',
    'identification_version',
    'identification_expiry_date',
  ];

  if (displayConfig.details) {
    schemaConfig = {
      ...schemaConfig,
      address: yup
        .object()
        .required('Please input and select an personal address.')
        .nullable(),
      dob: yup
        .string()
        .required('Please select date of birth.')
        .test(
          'over 18',
          'Please enter valid date of birth. You Must be over 18.',
          (value) =>
            moment(value, 'DD/MM/YYYY')
              .toDate()
              .getTime() <= getEighteenYearsAgo().getTime()
        )
        .nullable(),
      identification_expiry_date: yup
        .string()
        .when('identification_type', {
          is: 'passport',
          then: yup
            .string()
            .required('Please select expiry date.')
            .test(
              'vaild till today',
              'Please enter valid expiry date.',
              (value) => moment(value, 'DD/MM/YYYY') >= moment()
            ),
        })
        .nullable(),
      identification_number: yup
        .string()
        .required('Please input identification number.')
        .when('region', {
          is: 'NZ',
          then: yup
            .string()
            .when('identification_type', {
              is: 'driver_licence',
              then: yup
                .string()
                .required('Please input driver licence number.')
                .test(
                  'version',
                  'Please input a correct driver licence number.',
                  (value) => NZ_DRIVER_LICENCE_NUMBER_REGEX.test(value)
                ),
            })
            .when('identification_type', {
              is: 'passport',
              then: yup
                .string()
                .required('Please input passport number.')
                .test(
                  'version',
                  'Please input a correct passport number.',
                  (value) => NZ_PASSPORT_NUMBER_REGEX.test(value)
                ),
            }),
        })
        .nullable(),
      identification_type: yup
        .string()
        .required('Please select an identity type.')
        .nullable(),
      identification_version: yup
        .string()
        .when('region', {
          is: 'NZ',
          then: yup.string().when('identification_type', {
            is: 'driver_licence',
            then: yup
              .string()
              .required('Please input identification version.')
              .test('version', 'Please input a correct version.', (value) =>
                NZ_DRIVER_LICENCE_VERSION_REGEX.test(value)
              ),
          }),
        })
        .nullable(),
      region: yup
        .string()
        .required('Please select country of identification.')
        .nullable(),
    };
  }

  const authorisationSchema = yup.object().shape(schemaConfig);
  const [signature, setSignature] = useState({
    ...cardholderAuthorisation.signature,
  });
  const [errors, setErrors] = useState({});
  const [validSwitch, setValidswitch] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(!!identificationImage);

  useEffect(() => {
    if (isPresent(cardholderAuthorisation.signature)) {
      setSignature(cardholderAuthorisation.signature);
    }
  }, [cardholderAuthorisation.signature]);

  useEffect(() => {
    if (validSwitch) {
      valid();
    }
  }, [signature]);

  const updateSignature = (attributes) => {
    setSignature({ ...signature, ...attributes });
  };

  const setIdDetailsByOcrResult = (ocrResult) => {
    const result = {};
    ocrFields.forEach((field) => {
      switch (field) {
        case 'dob':
        case 'identification_expiry_date':
          if (ocrResult[field]) {
            result[field] = ocrResult[field].replace(/-/g, '/');
          }
          break;
        default:
          if (ocrResult[field]) {
            result[field] = ocrResult[field];
          }
          break;
      }
    });

    updateSignature({ ...result });
  };

  const valid = (callback) => {
    cardholderAuthorisation.isLoading = true;
    authorisationSchema
      .validate(signature, { abortEarly: false })
      .then((val) => {
        setErrors({});
        if (callback) {
          callback(val);
        }
      })
      .catch((err) => {
        cardholderAuthorisation.isLoading = false;
        const errorMessages = {};
        err.inner.forEach((validationError) => {
          errorMessages[validationError.params.path] = validationError.message;
        });
        setErrors(errorMessages);
      });
  };

  const applicationSubmit = (data) => {
    if (uploadCompleted) {
      const processedData = {
        ...(signatureRequired
          ? data
          : { identification_image: data.identification_image }),
      };

      delete processedData['anti_fraud_check_passed'];
      delete processedData['front_face_image'];

      cardholderAuthorisation.setAttributes({
        cardholderSignature: processedData,
      });
      (async () => {
        const result = await cardholderAuthorisation.updateSignature(
          cardholderAuthorisationId
        );
        if (result && result.status === 200) {
          props.toNextSection();
        }
      })();
    }
  };

  const onSubmit = () => {
    setValidswitch(true);
    valid(applicationSubmit);
  };

  return (
    <div>
      <div className={commonStyles.container}>
        <section className={commonStyles.section}>
          <IdentityCapture
            panelTitleSize={'14px'}
            distinctId={cardholderAuthorisationId}
            authorisationID={cardholderAuthorisationId}
            imageUrl={identificationImage}
            frontFaceImageUrl={frontFaceImage}
            page_validation_start={validSwitch}
            handleComplete={(completed) => setUploadCompleted(completed)}
            getOcrResult={(ocrResult) => setIdDetailsByOcrResult(ocrResult)}
            needDobAddress={displayConfig.details}
            isProofOfAddressVisible={displayConfig.proofOfAddress}
            isFaceMatchEnabled={isFaceMatchEnabled}
            isValidIdentification={true} // TO DO: Temporary fix to allow CMM to proceed. Needs proper fix
            proofOfAddress={signature.proof_of_address}
            {...props}
          />
          <Details
            application={cardholderAuthorisation}
            signature={signature}
            errors={errors}
            updateSignature={updateSignature}
            isVisible={displayConfig.details}
            {...props}
          />
          <div className={commonStyles.flow_buttons}>
            <Button text="Back" onClick={props.toPrevSection} />
            <Button
              text="Next"
              type="submit"
              onClick={onSubmit}
              loading={cardholderAuthorisation.isLoading}
            />
          </div>
        </section>
      </div>
    </div>
  );
}

export default connect((state, ownProps) => {
  const identity = state.identity;
  const { cardholderAuthorisation } = ownProps;
  const {
    requireAntiFraudCheck,
    requireAmlCheck,
    requireIdCheck,
  } = cardholderAuthorisation;
  const signatureRequired = idUploadSignatureRequired(cardholderAuthorisation);

  const displayConfig = {
    details: requireAntiFraudCheck || requireIdCheck,
    proofOfAddress: requireAmlCheck,
  };

  const { image_64, front_face_image, antiFraudCheckPassed } = identity;

  const frontFaceImage =
    front_face_image ||
    get(cardholderAuthorisation, 'signature.front_face_image');
  const identificationImage =
    image_64 || get(cardholderAuthorisation, 'signature.identification_image');
  const isAntiFraudPassing =
    antiFraudCheckPassed ||
    get(cardholderAuthorisation, 'signature.anti_fraud_check_passed');

  return {
    deliveryAddressData: state.cob_business.delivery_address_raw_list,
    deliveryAddressLoading: state.cob_business.delivery_address_loading,
    deliveryAddressOptions: state.cob_business.delivery_address_list,
    displayConfig,
    frontFaceImage,
    identificationImage,
    isAntiFraudPassing,
    isFaceMatchEnabled: isFaceMatchEnabled({ cardholderAuthorisation }),
    signatureRequired,
  };
})(Authorisation);
