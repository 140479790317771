export const MANAGE_PROFILE_SETTINGS_BUSINESS_SET_CONTACT_PHONE_NUMBER =
  'MANAGE_PROFILE_SETTINGS_BUSINESS_SET_CONTACT_PHONE_NUMBER';
export const MANAGE_PROFILE_SETTINGS_BUSINESS_SET_WEBSITE_URL =
  'MANAGE_PROFILE_SETTINGS_BUSINESS_SET_WEBSITE_URL';
export const MANAGE_PROFILE_SETTINGS_BUSINESS_SET_STAFF_COUNT =
  'MANAGE_PROFILE_SETTINGS_BUSINESS_SET_STAFF_COUNT';
export const MANAGE_PROFILE_SETTINGS_BUSINESS_SET_PHYSICAL_ADDRESS =
  'MANAGE_PROFILE_SETTINGS_BUSINESS_SET_PHYSICAL_ADDRESS';
export const MANAGE_PROFILE_SETTINGS_BUSINESS_SET_POSTAL_ADDRESS =
  'MANAGE_PROFILE_SETTINGS_BUSINESS_SET_POSTAL_ADDRESS';

export const MANAGE_PROFILE_CREATE_USER_START =
  'MANAGE_PROFILE_CREATE_USER_START';
export const MANAGE_PROFILE_CREATE_USER_SUCCESS =
  'MANAGE_PROFILE_CREATE_USER_SUCCESS';
export const MANAGE_PROFILE_CREATE_USER_ERROR =
  'MANAGE_PROFILE_CREATE_USER_ERROR';

export const MANAGE_PROFILE_CREATE_USER_ENTITY_LINK_START =
  'MANAGE_PROFILE_CREATE_USER_ENTITY_LINK_START';
export const MANAGE_PROFILE_CREATE_USER_ENTITY_LINK_SUCCESS =
  'MANAGE_PROFILE_CREATE_USER_ENTITY_LINK_SUCCESS';
export const MANAGE_PROFILE_CREATE_USER_ENTITY_LINK_ERROR =
  'MANAGE_PROFILE_CREATE_USER_ENTITY_LINK_ERROR';

export const MANAGE_PROFILE_REMOVE_USER_START =
  'MANAGE_PROFILE_REMOVE_USER_START';
export const MANAGE_PROFILE_REMOVE_USER_SUCCESS =
  'MANAGE_PROFILE_REMOVE_USER_SUCCESS';
export const MANAGE_PROFILE_REMOVE_USER_ERROR =
  'MANAGE_PROFILE_REMOVE_USER_ERROR';

export const MANAGE_PROFILE_SETTINGS_TEMP_LOGO =
  'MANAGE_PROFILE_SETTINGS_TEMP_LOGO';

export const MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT =
  'MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT';

export const MANAGE_PROFILE_SETTINGS_BUSINESS_SET_COMPANY_NAME =
  'MANAGE_PROFILE_SETTINGS_BUSINESS_SET_COMPANY_NAME';
export const MANAGE_PROFILE_SETTINGS_BUSINESS_SET_TRADING_NAME =
  'MANAGE_PROFILE_SETTINGS_BUSINESS_SET_TRADING_NAME';
export const MANAGE_PROFILE_SETTINGS_CONTACT_SET_PHONE_NUMBER =
  'MANAGE_PROFILE_SETTINGS_CONTACT_SET_PHONE_NUMBER';
export const MANAGE_PROFILE_SETTINGS_CONTACT_SET_FIRST_NAME =
  'MANAGE_PROFILE_SETTINGS_CONTACT_SET_FIRST_NAME';
export const MANAGE_PROFILE_SETTINGS_CONTACT_SET_LAST_NAME =
  'MANAGE_PROFILE_SETTINGS_CONTACT_SET_LAST_NAME';
export const MANAGE_PROFILE_SETTINGS_CONTACT_SET_EMAIL =
  'MANAGE_PROFILE_SETTINGS_CONTACT_SET_EMAIL';

export const MANAGE_PROFILE_SET_TERMS_DOCUMENT =
  'MANAGE_PROFILE_SET_TERMS_DOCUMENT';
export const MANAGE_PROFILE_SET_CUSTOM_TERMS_INTENT =
  'MANAGE_PROFILE_SET_CUSTOM_TERMS_INTENT';
export const MANAGE_PROFILE_SET_VIEWED_TERMS =
  'MANAGE_PROFILE_SET_VIEWED_TERMS';

export const MANAGE_PROFILE_TERMS_DOCUMENT_SET_START =
  'MANAGE_PROFILE_TERMS_DOCUMENT_SET_START';
export const MANAGE_PROFILE_TERMS_DOCUMENT_SET_ERROR =
  'MANAGE_PROFILE_TERMS_DOCUMENT_SET_ERROR';
export const MANAGE_PROFILE_TERMS_DOCUMENT_SET_SUCCESS =
  'MANAGE_PROFILE_TERMS_DOCUMENT_SET_SUCCESS';

export const MANAGE_PROFILE_SET_GUARANTOR_TERMS_DOCUMENT =
  'MANAGE_PROFILE_SET_GUARANTOR_TERMS_DOCUMENT';
export const MANAGE_PROFILE_SET_GUARANTOR_CUSTOM_TERMS_INTENT =
  'MANAGE_PROFILE_SET_GUARANTOR_CUSTOM_TERMS_INTENT';
export const MANAGE_PROFILE_SET_GUARANTOR_VIEWED_TERMS =
  'MANAGE_PROFILE_SET_GUARANTOR_VIEWED_TERMS';

export const MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_START =
  'MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_START';
export const MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_ERROR =
  'MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_ERROR';
export const MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_SUCCESS =
  'MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_SUCCESS';

export const MANAGE_PROFILE_SET_PAPERLESS_TERMS_DOCUMENT =
  'MANAGE_PROFILE_SET_PAPERLESS_TERMS_DOCUMENT';
export const MANAGE_PROFILE_SET_PAPERLESS_CUSTOM_TERMS_INTENT =
  'MANAGE_PROFILE_SET_PAPERLESS_CUSTOM_TERMS_INTENT';
export const MANAGE_PROFILE_SET_PAPERLESS_VIEWED_TERMS =
  'MANAGE_PROFILE_SET_PAPERLESS_VIEWED_TERMS';
export const MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_START =
  'MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_START';
export const MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_ERROR =
  'MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_ERROR';
export const MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_SUCCESS =
  'MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_SUCCESS';

export const MANAGE_PROFILE_SET_QUESTION = 'MANAGE_PROFILE_SET_QUESTION';
export const MANAGE_PROFILE_SET_QUESTION_INDEX =
  'MANAGE_PROFILE_SET_QUESTION_INDEX';

export const MANAGE_PROFILE_SET_QUESTIONS_START =
  'MANAGE_PROFILE_SET_QUESTIONS_START';
export const MANAGE_PROFILE_SET_QUESTIONS_ERROR =
  'MANAGE_PROFILE_SET_QUESTIONS_ERROR';
export const MANAGE_PROFILE_SET_QUESTIONS_SUCCESS =
  'MANAGE_PROFILE_SET_QUESTIONS_SUCCESS';

export const MANAGE_PROFILE_SET_QUESTIONS_APPLIES_START =
  'MANAGE_PROFILE_SET_QUESTIONS_APPLIES_START';
export const MANAGE_PROFILE_SET_QUESTIONS_APPLIES_ERROR =
  'MANAGE_PROFILE_SET_QUESTIONS_APPLIES_ERROR';
export const MANAGE_PROFILE_SET_QUESTIONS_APPLIES_SUCCESS =
  'MANAGE_PROFILE_SET_QUESTIONS_APPLIES_SUCCESS';

export const MANAGE_PROFILE_SET_EXCLUDED_SOURCES_INDEX =
  'MANAGE_PROFILE_SET_EXCLUDED_SOURCES_INDEX';
export const MANAGE_PROFILE_SET_EXCLUDED_SOURCES =
  'MANAGE_PROFILE_SET_EXCLUDED_SOURCES';
export const MANAGE_PROFILE_REMOVE_EXCLUDED_SOURCE =
  'MANAGE_PROFILE_REMOVE_EXCLUDED_SOURCE';
export const MANAGE_PROFILE_ADD_EXCLUDED_SOURCE =
  'MANAGE_PROFILE_ADD_EXCLUDED_SOURCE';

export const MANAGE_PROFILE_SET_EXCLUDED_SOURCES_START =
  'MANAGE_PROFILE_SET_EXCLUDED_SOURCES_START';
export const MANAGE_PROFILE_SET_EXCLUDED_SOURCES_SUCCESS =
  'MANAGE_PROFILE_SET_EXCLUDED_SOURCES_SUCCESS';
export const MANAGE_PROFILE_SET_EXCLUDED_SOURCES_ERROR =
  'MANAGE_PROFILE_SET_EXCLUDED_SOURCES_ERROR';

export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_START =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_START';
export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_SUCCESS =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_SUCCESS';
export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_ERROR =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_ERROR';

export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_IDENTIFICATION_CHECK_LEVEL =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_IDENTIFICATION_CHECK_LEVEL';
export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_IDENTIFICATION_CHECK =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_IDENTIFICATION_CHECK';

export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_INITIAL_VALUES =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_INITIAL_VALUES';
export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK';
export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_TYPE =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_TYPE';
export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_USERNAME =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_USERNAME';
export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_PASSWORD =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_PASSWORD';
export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_SHOW_AUTH_FIELDS =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_SHOW_AUTH_FIELDS';
export const MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_START =
  'MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_START';
export const MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_SUCCESS =
  'MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_SUCCESS';
export const MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_ERROR =
  'MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_ERROR';

export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_GUARENTEES =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_GUARENTEES';
export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_MINIMUM_GUARANTEES =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_MINIMUM_GUARANTEES';

export const MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_START =
  'MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_START';
export const MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_SUCCESS =
  'MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_SUCCESS';
export const MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_ERROR =
  'MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_ERROR';

export const MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_MINIMUM_TRADE_REFERENCES =
  'MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_MINIMUM_TRADE_REFERENCES';
export const MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_TRADE_REFERENCE_CHECK =
  'MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_TRADE_REFERENCE_CHECK';

export const MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_START =
  'MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_START';
export const MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_ERROR =
  'MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_ERROR';
export const MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_SUCCESS =
  'MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_SUCCESS';

export const MANAGE_PROFILE_SETTINGS_SET_MIN_APPROVERS =
  'MANAGE_PROFILE_SETTINGS_SET_MIN_APPROVERS';

export const MANAGE_PROFILE_SETTINGS_BUSINESS_SET_START =
  'MANAGE_PROFILE_SETTINGS_BUSINESS_SET_START';
export const MANAGE_PROFILE_SETTINGS_BUSINESS_SET_SUCCESS =
  'MANAGE_PROFILE_SETTINGS_BUSINESS_SET_SUCCESS';
export const MANAGE_PROFILE_SETTINGS_BUSINESS_SET_ERROR =
  'MANAGE_PROFILE_SETTINGS_BUSINESS_SET_ERROR';

export const MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_START =
  'MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_START';
export const MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_SUCCESS =
  'MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_SUCCESS';
export const MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_ERROR =
  'MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_ERROR';

export const MANAGE_PROFILE_SETTINGS_CONTACT_SET_START =
  'MANAGE_PROFILE_SETTINGS_CONTACT_SET_START';
export const MANAGE_PROFILE_SETTINGS_CONTACT_SET_SUCCESS =
  'MANAGE_PROFILE_SETTINGS_CONTACT_SET_SUCCESS';
export const MANAGE_PROFILE_SETTINGS_CONTACT_SET_ERROR =
  'MANAGE_PROFILE_SETTINGS_CONTACT_SET_ERROR';

export const MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_START =
  'MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_START';
export const MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_ERROR =
  'MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_ERROR';
export const MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_SUCCESS =
  'MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_SUCCESS';

export const MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_START =
  'MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_START';
export const MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_SUCCESS =
  'MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_SUCCESS';
export const MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_ERROR =
  'MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_ERROR';

export const MANAGE_PROFILE_SETTINGS_INVOICE_SET_START =
  'MANAGE_PROFILE_SETTINGS_INVOICE_SET_START';
export const MANAGE_PROFILE_SETTINGS_INVOICE_SET_SUCCESS =
  'MANAGE_PROFILE_SETTINGS_INVOICE_SET_SUCCESS';
export const MANAGE_PROFILE_SETTINGS_INVOICE_SET_ERROR =
  'MANAGE_PROFILE_SETTINGS_INVOICE_SET_ERROR';

export const MANAGE_PROFILE_SETTINGS_SET_INVOICE_FREQUENCY =
  'MANAGE_PROFILE_SETTINGS_SET_INVOICE_FREQUENCY';
export const MANAGE_PROFILE_SETTINGS_SET_INVOICE_DAY =
  'MANAGE_PROFILE_SETTINGS_SET_INVOICE_DAY';
export const MANAGE_PROFILE_SETTINGS_SET_INVOICE_DUE =
  'MANAGE_PROFILE_SETTINGS_SET_INVOICE_DUE';

export const MANAGE_PROFILE_SETTINGS_LOGO_SET_START =
  'MANAGE_PROFILE_SETTINGS_LOGO_SET_START';
export const MANAGE_PROFILE_SETTINGS_LOGO_SET_SUCCESS =
  'MANAGE_PROFILE_SETTINGS_LOGO_SET_SUCCESS';
export const MANAGE_PROFILE_SETTINGS_LOGO_SET_ERROR =
  'MANAGE_PROFILE_SETTINGS_LOGO_SET_ERROR';

export const MANAGE_PROFILE_ANZSIC_DIVISIONS_START =
  'MANAGE_PROFILE_ANZSIC_DIVISIONS_START';
export const MANAGE_PROFILE_ANZSIC_DIVISIONS_SUCCESS =
  'MANAGE_PROFILE_ANZSIC_DIVISIONS_SUCCESS';
export const MANAGE_PROFILE_ANZSIC_DIVISIONS_ERROR =
  'MANAGE_PROFILE_ANZSIC_DIVISIONS_ERROR';

export const MANAGE_PROFILE_SET_ANZSIC_DIVISION =
  'MANAGE_PROFILE_SET_ANZSIC_DIVISION';
export const MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_START =
  'MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_START';
export const MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_SUCCESS =
  'MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_SUCCESS';
export const MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_ERROR =
  'MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_ERROR';
export const MANAGE_PROFILE_SET_ANZSIC_SUBDIVISION =
  'MANAGE_PROFILE_SET_ANZSIC_SUBDIVISION';
export const MANAGE_PROFILE_ANZSIC_GROUPS_START =
  'MANAGE_PROFILE_ANZSIC_GROUPS_START';
export const MANAGE_PROFILE_ANZSIC_GROUPS_SUCCESS =
  'MANAGE_PROFILE_ANZSIC_GROUPS_SUCCESS';
export const MANAGE_PROFILE_ANZSIC_GROUPS_ERROR =
  'MANAGE_PROFILE_ANZSIC_GROUPS_ERROR';
export const MANAGE_PROFILE_SET_ANZSIC_GROUP =
  'MANAGE_PROFILE_SET_ANZSIC_GROUP';
export const MANAGE_PROFILE_ANZSIC_CLASSES_START =
  'MANAGE_PROFILE_ANZSIC_CLASSES_START';
export const MANAGE_PROFILE_ANZSIC_CLASSES_SUCCESS =
  'MANAGE_PROFILE_ANZSIC_CLASSES_SUCCESS';
export const MANAGE_PROFILE_ANZSIC_CLASSES_ERROR =
  'MANAGE_PROFILE_ANZSIC_CLASSES_ERROR';
export const MANAGE_PROFILE_SET_ANZSIC_CLASS =
  'MANAGE_PROFILE_SET_ANZSIC_CLASS';

export const MANAGE_PROFILE_LOAD_CURRENT_ENTITY_START =
  'MANAGE_PROFILE_LOAD_CURRENT_ENTITY_START';
export const MANAGE_PROFILE_LOAD_CURRENT_ENTITY_SUCCESS =
  'MANAGE_PROFILE_LOAD_CURRENT_ENTITY_SUCCESS';
export const MANAGE_PROFILE_LOAD_CURRENT_ENTITY_ERROR =
  'MANAGE_PROFILE_LOAD_CURRENT_ENTITY_ERROR';

export const MANAGE_PROFILE_SET_PARTY_DETAILS_START =
  'MANAGE_PROFILE_SET_PARTY_DETAILS_START';
export const MANAGE_PROFILE_SET_PARTY_DETAILS_ERROR =
  'MANAGE_PROFILE_SET_PARTY_DETAILS_ERROR';
export const MANAGE_PROFILE_SET_PARTY_DETAILS_SUCCESS =
  'MANAGE_PROFILE_SET_PARTY_DETAILS_SUCCESS';

export const MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_START =
  'MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_START';
export const MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_ERROR =
  'MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_ERROR';
export const MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_SUCCESS =
  'MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_SUCCESS';

export const MANAGE_PROFILE_LOAD_PEOPLE_START =
  'MANAGE_PROFILE_LOAD_PEOPLE_START';
export const MANAGE_PROFILE_LOAD_PEOPLE_ERROR =
  'MANAGE_PROFILE_LOAD_PEOPLE_ERROR';
export const MANAGE_PROFILE_LOAD_PEOPLE_SUCCESS =
  'MANAGE_PROFILE_LOAD_PEOPLE_SUCCESS';

export const MANAGE_PROFILE_LOAD_USERS_START =
  'MANAGE_PROFILE_LOAD_USERS_START';
export const MANAGE_PROFILE_LOAD_USERS_SUCCESS =
  'MANAGE_PROFILE_LOAD_USERS_SUCCESS';
export const MANAGE_PROFILE_LOAD_USERS_ERROR =
  'MANAGE_PROFILE_LOAD_USERS_ERROR';

export const MANAGE_PROFILE_LOAD_USER_PROFILE_SUCCESS =
  'MANAGE_PROFILE_LOAD_USER_PROFILE_SUCCESS';
export const MANAGE_PROFILE_LOAD_USER_PROFILE_ERROR =
  'MANAGE_PROFILE_LOAD_USER_PROFILE_ERROR';
export const MANAGE_PROFILE_LOAD_USER_PROFILE_START =
  'MANAGE_PROFILE_LOAD_USER_PROFILE_START';

export const MANAGE_PROFILE_LOAD_CARD_START = 'MANAGE_PROFILE_LOAD_CARD_START';
export const MANAGE_PROFILE_LOAD_CARD_SUCCESS =
  'MANAGE_PROFILE_LOAD_CARD_SUCCESS';
export const MANAGE_PROFILE_LOAD_CARD_ERROR = 'MANAGE_PROFILE_LOAD_CARD_ERROR';

export const MANAGE_PROFILE_SET_PARTY_DETAILS_INDEX =
  'MANAGE_PROFILE_SET_PARTY_DETAILS_INDEX';
export const MANAGE_PROFILE_SET_PARTY_DETAILS =
  'MANAGE_PROFILE_SET_PARTY_DETAILS';
export const MANAGE_PROFILE_ADD_ANOTHER_PERSON =
  'MANAGE_PROFILE_ADD_ANOTHER_PERSON';
export const MANAGE_PROFILE_REMOVE_PERSON = 'MANAGE_PROFILE_REMOVE_PERSON';

export const MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_CLEAR =
  'MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_CLEAR';
export const MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_START =
  'MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_START';
export const MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_SUCCESS =
  'MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_SUCCESS';
export const MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_ERROR =
  'MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_ERROR';

export const MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_CLEAR =
  'MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_CLEAR';
export const MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_START =
  'MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_START';
export const MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_SUCCESS =
  'MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_SUCCESS';
export const MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_ERROR =
  'MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_ERROR';

export const MANAGE_PROFILE_SETTINGS_SET_POSTAL_ADDRESS =
  'MANAGE_PROFILE_SETTINGS_SET_POSTAL_ADDRESS';
export const MANAGE_PROFILE_SETTINGS_SET_PHYSICAL_ADDRESS =
  'MANAGE_PROFILE_SETTINGS_SET_PHYSICAL_ADDRESS';

export const MANAGE_PROFILE_SETTINGS_SELECT_POSTAL_ADDRESS =
  'MANAGE_PROFILE_SETTINGS_SELECT_POSTAL_ADDRESS';
export const MANAGE_PROFILE_SETTINGS_SELECT_PHYSICAL_ADDRESS =
  'MANAGE_PROFILE_SETTINGS_SELECT_PHYSICAL_ADDRESS';

export const MANAGE_PROFILE_SETTINGS_BUSINESS_SET_REGION =
  'MANAGE_PROFILE_SETTINGS_BUSINESS_SET_REGION';
export const MANAGE_PROFILE_SETTINGS_BUSINESS_SET_STATE =
  'MANAGE_PROFILE_SETTINGS_BUSINESS_SET_STATE';
export const MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_START =
  'MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_START';
export const MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_SUCCESS =
  'MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_SUCCESS';
export const MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_ERROR =
  'MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_ERROR';

export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_CREDIT_CHECK_RULES =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_CREDIT_CHECK_RULES';

export const MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_START =
  'MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_START';
export const MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_SUCCESS =
  'MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_SUCCESS';
export const MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_ERROR =
  'MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_ERROR';

export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_IDENTIFICATION_CHECK_RULES =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_IDENTIFICATION_CHECK_RULES';

export const MANAGE_PROFILE_SETTINGS_SET_CREDIT_WORKS_STATUS =
  'MANAGE_PROFILE_SETTINGS_SET_CREDIT_WORKS_STATUS';

export const MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_STATE =
  'MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_STATE';
export const MANAGE_PROFILE_CLEAR_STATE = 'MANAGE_PROFILE_CLEAR_STATE';

export const MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_SELECTED_CREDIT_CHECK_RULES =
  'MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_SELECTED_CREDIT_CHECK_RULES';
export const MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_SELECTED_IDENTIFICATION_CHECK_RULES =
  'MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_SELECTED_IDENTIFICATION_CHECK_RULES';

export const MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_CREDIT_CHECK =
  'MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_CREDIT_CHECK';
export const MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_IDENTIFICATION_CHECK =
  'MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_IDENTIFICATION_CHECK';
export const MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_GUARANTEES =
  'MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_GUARANTEES';
export const MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_TRADE_REFERENCE_CHECK =
  'MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_TRADE_REFERENCE_CHECK';

export const MANAGE_PROFILE_CLEAR_SETTINGS_BUSINESS_INFO =
  'MANAGE_PROFILE_CLEAR_SETTINGS_BUSINESS_INFO';
export const MANAGE_PROFILE_CLEAR_SETTINGS_KEY_CONTACT =
  'MANAGE_PROFILE_CLEAR_SETTINGS_KEY_CONTACT';
export const MANAGE_PROFILE_CLEAR_SETTINGS_INVOICE =
  'MANAGE_PROFILE_CLEAR_SETTINGS_INVOICE';

export const MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_START =
  'MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_START';
export const MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_SUCCESS =
  'MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_SUCCESS';
export const MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_ERROR =
  'MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_ERROR';

export const MANAGE_PROFILE_SET_APPROVAL_LEVELS_START =
  'MANAGE_PROFILE_SET_APPROVAL_LEVELS_START';
export const MANAGE_PROFILE_SET_APPROVAL_LEVELS_SUCCESS =
  'MANAGE_PROFILE_SET_APPROVAL_LEVELS_SUCCESS';
export const MANAGE_PROFILE_SET_APPROVAL_LEVELS_ERROR =
  'MANAGE_PROFILE_SET_APPROVAL_LEVELS_ERROR';

export const MANAGE_PROFILE_SET_USERS_APPROVAL_LEVEL_START =
  'MANAGE_PROFILE_SET_USERS_APPROVAL_LEVEL_START';
export const MANAGE_PROFILE_SET_USERS_APPROVAL_LEVEL_SUCCESS =
  'MANAGE_PROFILE_SET_USERS_APPROVAL_LEVEL_SUCCESS';
export const MANAGE_PROFILE_SET_USERS_APPROVAL_LEVEL_ERROR =
  'MANAGE_PROFILE_SET_USERS_APPROVAL_LEVEL_ERROR';

export const MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_START =
  'MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_START';
export const MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_SUCCESS =
  'MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_SUCCESS';
export const MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_ERROR =
  'MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_ERROR';

export const MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_START =
  'MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_START';
export const MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_SUCCESS =
  'MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_SUCCESS';
export const MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_ERROR =
  'MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_ERROR';

export const MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_START =
  'MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_START';
export const MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_SUCCESS =
  'MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_SUCCESS';
export const MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_ERROR =
  'MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_ERROR';

export const MANAGE_PROFILE_UPDATE_ENTITY_STATUS_START =
  'MANAGE_PROFILE_UPDATE_ENTITY_STATUS_START';
export const MANAGE_PROFILE_UPDATE_ENTITY_STATUS_SUCCESS =
  'MANAGE_PROFILE_UPDATE_ENTITY_STATUS_SUCCESS';
export const MANAGE_PROFILE_UPDATE_ENTITY_STATUS_ERROR =
  'MANAGE_PROFILE_UPDATE_ENTITY_STATUS_ERROR';

export const MANAGE_PROFILE_SET_EMAIL_TEMPLATE =
  'MANAGE_PROFILE_SET_EMAIL_TEMPLATE';
export const MANAGE_PROFILE_SET_EMAIL_TEMPLATE_VALUE =
  'MANAGE_PROFILE_SET_EMAIL_TEMPLATE_VALUE';
export const MANAGE_PROFILE_GET_EMAIL_TEMPLATE_START =
  'MANAGE_PROFILE_GET_EMAIL_TEMPLATE_START';
export const MANAGE_PROFILE_GET_EMAIL_TEMPLATE_SUCCESS =
  'MANAGE_PROFILE_GET_EMAIL_TEMPLATE_SUCCESS';
export const MANAGE_PROFILE_GET_EMAIL_TEMPLATE_ERROR =
  'MANAGE_PROFILE_GET_EMAIL_TEMPLATE_ERROR';
export const MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_START =
  'MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_START';
export const MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_SUCCESS =
  'MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_SUCCESS';
export const MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_ERROR =
  'MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_ERROR';
export const MANAGE_PROFILE_PREVIEW_EMAIL_START =
  'MANAGE_PROFILE_PREVIEW_EMAIL_START';
export const MANAGE_PROFILE_PREVIEW_EMAIL_SUCCESS =
  'MANAGE_PROFILE_PREVIEW_EMAIL_SUCCESS';
export const MANAGE_PROFILE_PREVIEW_EMAIL_ERROR =
  'MANAGE_PROFILE_PREVIEW_EMAIL_ERROR';

/**
 * AML Constants
 */
export const MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_ERROR =
  'MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_ERROR';
export const MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_START =
  'MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_START';
export const MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_SUCCESS =
  'MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_SUCCESS';
export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_AML_CHECK_LEVEL =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_AML_CHECK_LEVEL';
export const MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_AML_CHECK_PROVIDER =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_AML_CHECK_PROVIDER';
export const MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_ERROR =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_ERROR';
export const MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_START =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_START';
export const MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_SUCCESS =
  'MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_SUCCESS';
export const MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_ERROR =
  'MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_ERROR';
export const MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_START =
  'MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_START';
export const MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_SUCCESS =
  'MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_SUCCESS';

/**
 * Cardholder addon
 */
export const MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_ERROR =
  'MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_ERROR';
export const MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_START =
  'MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_START';
export const MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_SUCCESS =
  'MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_SUCCESS';

/**
 * Partnership / Trust agreements
 */
export const MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_ERROR =
  'MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_ERROR';
export const MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_START =
  'MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_START';
export const MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_SUCCESS =
  'MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_SUCCESS';
export const MANAGE_PROFILE_SETTINGS_REQUIRES_TRUST_DEED =
  'MANAGE_PROFILE_SETTINGS_REQUIRES_TRUST_DEED';
export const MANAGE_PROFILE_SETTINGS_SET_PARTNERSHIP_AGREEMENT_UPLOAD_VISIBLE =
  'MANAGE_PROFILE_SETTINGS_SET_PARTNERSHIP_AGREEMENT_UPLOAD_VISIBLE';
export const MANAGE_PROFILE_SETTINGS_SET_TRUST_DEED_UPLOAD_VISIBLE =
  'MANAGE_PROFILE_SETTINGS_SET_TRUST_DEED_UPLOAD_VISIBLE';

export const MANAGE_PROFILE_UPDATE_COLOR_PALETTE_ERROR =
  'MANAGE_PROFILE_UPDATE_COLOR_PALETTE_ERROR';
export const MANAGE_PROFILE_UPDATE_COLOR_PALETTE_START =
  'MANAGE_PROFILE_UPDATE_COLOR_PALETTE_START';
export const MANAGE_PROFILE_UPDATE_COLOR_PALETTE_SUCCESS =
  'MANAGE_PROFILE_UPDATE_COLOR_PALETTE_SUCCESS';

export const MANAGE_PROFILE_GET_COLOR_PALETTE_ERROR =
  'MANAGE_PROFILE_GET_COLOR_PALETTE_ERROR';
export const MANAGE_PROFILE_GET_COLOR_PALETTE_START =
  'MANAGE_PROFILE_GET_COLOR_PALETTE_START';
export const MANAGE_PROFILE_GET_COLOR_PALETTE_SUCCESS =
  'MANAGE_PROFILE_GET_COLOR_PALETTE_SUCCESS';
export const MANAGE_PROFILE_SET_COLOR_PALETTE =
  'MANAGE_PROFILE_SET_COLOR_PALETTE';

export const MANAGE_PROFILE_LOAD_TIMEZONE_ERROR =
  'MANAGE_PROFILE_LOAD_TIMEZONE_ERROR';
export const MANAGE_PROFILE_LOAD_TIMEZONE_START =
  'MANAGE_PROFILE_LOAD_TIMEZONE_START';
export const MANAGE_PROFILE_LOAD_TIMEZONE_SUCCESS =
  'MANAGE_PROFILE_LOAD_TIMEZONE_SUCCESS';

/**
 * Anti fraud constants
 */
export const MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_ERROR =
  'MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_ERROR';
export const MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_START =
  'MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_START';
export const MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_SUCCESS =
  'MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_SUCCESS';

/**
 * Customer checklist constants
 */
export const MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_ERROR =
  'MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_ERROR';
export const MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_START =
  'MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_START';
export const MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_SUCCESS =
  'MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_SUCCESS';
