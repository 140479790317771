import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import commonStyles from 'modules/card-management-onboarding/css/Section.css';
import styles from 'modules/card-management-onboarding/css/Terms.css';
import Button from 'modules/shared/components/inputs/Button';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import UncontrolledTextInput from 'modules/shared/components/inputs/UncontrolledTextInput';
import SectionHeader from 'modules/shared/components/v2/SectionHeader';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { isSanitizedStringEqual } from 'utils/sanitizeName';
import * as yup from 'yup';

export function checkListItems(application) {
  const supplierName = application.supplierName;
  const isTokenised = get(application, 'antiFraudCheckConfig.config.tokenised');

  const items = [
    {
      label: 'I am authorised to sign this form.',
      name: 'authorised',
    },
    {
      label: `I have read and agree to the terms and conditions for ${supplierName}`,
      name: 'read_terms',
    },
  ];

  if (isTokenised && !items.find(({ name }) => name === 'anti_fraud_id_hold')) {
    items.push({
      label: `I confirm that ${supplierName} has the right to hold my\n
        ID documents on file until my identity has been confirmed`,
      name: 'anti_fraud_id_hold',
    });
  }

  return items;
}

function CheckListItem(props) {
  const [isChecked, setIsChecked] = useState(false);

  const onToggleIsChecked = () => {
    const bool = !isChecked;
    if (bool) props.clearError(props.checkboxName);
    setIsChecked(bool);
  };

  return (
    <div>
      <div className={commonStyles.row} onClick={onToggleIsChecked}>
        <SquareCheckbox
          checked={isChecked}
          label={props.label}
          inputRef={props.inputRef}
          checkboxName={props.checkboxName}
        />
      </div>
      <div className={commonStyles.error}>
        {get(props.errors, `${props.checkboxName}.message`, '')}
      </div>
    </div>
  );
}

function CheckList(props) {
  const { application } = props;
  const checkList = checkListItems(application);

  return (
    <div className={styles.check_list}>
      {checkList.map((item) => (
        <CheckListItem
          key={item.name}
          checkboxName={item.name}
          label={item.label}
          {...props}
        />
      ))}
    </div>
  );
}

function TermsAndConditions(props) {
  const { application } = props;

  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.terms_block}>
        <span className={styles.terms}>{application.terms}</span>
      </div>
      <CheckList application={application} {...props} />
    </div>
  );
}

function DigitalSignature(props) {
  return (
    <div>
      <div className={commonStyles.row}>
        <div className={commonStyles.half_col}>
          <UncontrolledTextInput
            id={'first_name'}
            label={'First name'}
            error={get(props.errors, 'first_name.message', '')}
            inputRef={props.inputRef}
          />
        </div>
        <div className={commonStyles.half_col}>
          <UncontrolledTextInput
            id={'last_name'}
            label={'Last name'}
            error={get(props.errors, 'last_name.message', '')}
            inputRef={props.inputRef}
          />
        </div>
      </div>
    </div>
  );
}

export default function Terms(props) {
  const { cardManagementApplication, applicant, supplierId } = props;
  const signatureRequired =
    cardManagementApplication.signatureRequired === 'on';

  const termsSchema = yup.object().shape({
    authorised: yup
      .boolean()
      .test('authorised', 'Please check to agree.', (value) => value),
    first_name: yup
      .string()
      .required('Please input first name.')
      .test('first_name_test', 'Incorrect first name.', (value) =>
        signatureRequired
          ? isSanitizedStringEqual(applicant.first_name, value)
          : true
      ),
    last_name: yup
      .string()
      .required('Please input last name.')
      .test('last_name_test', 'Incorrect last name.', (value) =>
        signatureRequired
          ? isSanitizedStringEqual(applicant.last_name, value)
          : true
      ),
    read_terms: yup
      .boolean()
      .test('readTerms', 'Please check to agree.', (value) => value),
  });

  const onSubmit = (data) => {
    cardManagementApplication.setAttributes({
      applicant_signature: {
        first_name: data.first_name,
        last_name: data.last_name,
      },
      approved: true,
    });

    (async () => {
      const signatureResult = await cardManagementApplication.saveApplicantSignature(
        supplierId
      );
      if (signatureResult.status !== 200) {
        return;
      }

      const result = await cardManagementApplication.saveDecision(supplierId);
      if (result.status === 200) {
        mixpanel.people.set({
          $first_name: data.first_name,
          $last_name: data.last_name,
        });

        props.toNextSection();
      }
    })();
  };

  const { clearError, errors, handleSubmit, register } = useForm({
    mode: 'onBlur',
    validationSchema: termsSchema,
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={commonStyles.container}>
          <SectionHeader title="T&Cs" />
          <section className={commonStyles.section}>
            <TermsAndConditions
              application={cardManagementApplication}
              inputRef={register}
              errors={errors}
              clearError={clearError}
            />
            <DigitalSignature inputRef={register} errors={errors} />
            <div className={commonStyles.flow_buttons}>
              <Button text="Back" onClick={props.toPrevSection} />
              <Button
                text="Agree"
                type="submit"
                loading={cardManagementApplication.isLoading}
              />
            </div>
          </section>
        </div>
      </form>
    </div>
  );
}
