const EMAIL_REG_EX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NUMBER_REG = /^\d+$/;

export function isValidEmail(string) {
  return EMAIL_REG_EX.test(string);
}

export function isAllNumber(string) {
  return NUMBER_REG.test(string);
}
