/* Supplier onboarding business section constants */
export const COB_BUSINESS_SECTION_COMPLETE = 'COB_BUSINESS_SECTION_COMPLETE';
export const COB_BUSINESS_COMPONENT_COMPLETE =
  'COB_BUSINESS_COMPONENT_COMPLETE';

export const COB_BUSINESS_SELECT_ENTITY_TYPE =
  'COB_BUSINESS_SELECT_ENTITY_TYPE';

export const COB_BUSINESS_SELECT_ENTITY_PARTY_COUNT =
  'COB_BUSINESS_SELECT_ENTITY_PARTY_COUNT';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_INDEX =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_INDEX';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_FIRSTNAME =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_FIRSTNAME';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_LASTNAME =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_LASTNAME';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_MIDDLENAME =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_MIDDLENAME';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMAIL =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMAIL';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_SHARE =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_SHARE';
export const COB_BUSINESS_REMOVE_ENTITY_PARTY_DETAILS =
  'COB_BUSINESS_REMOVE_ENTITY_PARTY_DETAILS';

export const COB_BUSINESS_SET_ENTITY_NAME = 'COB_BUSINESS_SET_ENTITY_NAME';
export const COB_BUSINESS_SET_APPLICATION_TYPE =
  'COB_BUSINESS_SET_APPLICATION_TYPE';
export const COB_BUSINESS_SET_ENTITY_LOGO = 'COB_BUSINESS_SET_ENTITY_LOGO';
export const COB_BUSINESS_SET_STAFF_COUNT = 'COB_BUSINESS_SET_STAFF_COUNT';
export const COB_BUSINESS_SET_PHONE = 'COB_BUSINESS_SET_PHONE';
export const COB_BUSINESS_SET_WEBSITE = 'COB_BUSINESS_SET_WEBSITE';
export const COB_BUSINESS_SET_ADDRESS = 'COB_BUSINESS_SET_ADDRESS';
export const COB_BUSINESS_SET_POSTAL = 'COB_BUSINESS_SET_POSTAL';

export const COB_BUSINESS_UPDATE_OTHER_DETAILS_PERCENTAGE =
  'COB_BUSINESS_UPDATE_OTHER_DETAILS_PERCENTAGE';

export const COB_BUSINESS_SET_SECTION_INDEX = 'COB_BUSINESS_UPDATE_SECTION';
export const COB_BUSINESS_COMPANY_SET_NAME = 'COB_BUSINESS_COMPANY_SET_NAME';
export const COB_BUSINESS_COMPANY_NAME_SELECT =
  'COB_BUSINESS_COMPANY_NAME_SELECT';
export const COB_BUSINESS_COMPANY_NAME_LOOKUP_CLEAR =
  'COB_BUSINESS_COMPANY_NAME_LOOKUP_CLEAR';

export const COB_BUSINESS_COMPANY_NAME_LOOKUP_START =
  'COB_BUSINESS_COMPANY_NAME_LOOKUP_START';
export const COB_BUSINESS_COMPANY_NAME_LOOKUP_SUCCESS =
  'COB_BUSINESS_COMPANY_NAME_LOOKUP_SUCCESS';
export const COB_BUSINESS_COMPANY_NAME_LOOKUP_ERROR =
  'COB_BUSINESS_COMPANY_NAME_LOOKUP_ERROR';

export const COB_BUSINESS_COMPANY_DETAILS_START =
  'COB_BUSINESS_COMPANY_DETAILS_START';
export const COB_BUSINESS_COMPANY_DETAILS_SUCCESS =
  'COB_BUSINESS_COMPANY_DETAILS_SUCCESS';
export const COB_BUSINESS_COMPANY_DETAILS_ERROR =
  'COB_BUSINESS_COMPANY_DETAILS_ERROR';

export const COB_BUSINESS_SET_DIRECTORS_CONTAINER_INDEX =
  'COB_BUSINESS_SET_DIRECTORS_CONTAINER_INDEX';

export const COB_BUSINESS_SET_KEY_CONTACT_FIRST_NAME =
  'COB_BUSINESS_SET_KEY_CONTACT_FIRST_NAME';
export const COB_BUSINESS_SET_KEY_CONTACT_LAST_NAME =
  'COB_BUSINESS_SET_KEY_CONTACT_LAST_NAME';
export const COB_BUSINESS_SET_KEY_CONTACT_PHONE =
  'COB_BUSINESS_SET_KEY_CONTACT_PHONE';
export const COB_BUSINESS_SET_KEY_CONTACT_COUNTRY_CODE =
  'COB_BUSINESS_SET_KEY_CONTACT_COUNTRY_CODE';
export const COB_BUSINESS_SET_KEY_CONTACT_EMAIL =
  'COB_BUSINESS_SET_KEY_CONTACT_EMAIL';
export const COB_BUSINESS_SET_KEY_CONTACT_PASSWORD =
  'COB_BUSINESS_SET_KEY_CONTACT_PASSWORD';

export const COB_BUSINESS_UNIQUE_EMAIL_START =
  'COB_BUSINESS_UNIQUE_EMAIL_START';
export const COB_BUSINESS_UNIQUE_EMAIL_SUCCESS =
  'COB_BUSINESS_UNIQUE_EMAIL_SUCCESS';
export const COB_BUSINESS_UNIQUE_EMAIL_ERROR =
  'COB_BUSINESS_UNIQUE_EMAIL_ERROR';

export const COB_BUSINESS_UPDATE_KEY_CONTACT_PERCENTAGE =
  'COB_BUSINESS_UPDATE_KEY_CONTACT_PERCENTAGE';

export const COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_CLEAR =
  'COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_CLEAR';
export const COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_START =
  'COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_START';
export const COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_SUCCESS =
  'COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_SUCCESS';
export const COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_ERROR =
  'COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_ERROR';

export const COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_CLEAR =
  'COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_CLEAR';
export const COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_START =
  'COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_START';
export const COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_SUCCESS =
  'COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_SUCCESS';
export const COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_ERROR =
  'COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_ERROR';

export const COB_BUSINESS_SET_POSTAL_ADDRESS =
  'COB_BUSINESS_SET_POSTAL_ADDRESS';
export const COB_BUSINESS_SET_PHYSICAL_ADDRESS =
  'COB_BUSINESS_SET_PHYSICAL_ADDRESS';

export const COB_BUSINESS_SELECT_POSTAL_ADDRESS =
  'COB_BUSINESS_SELECT_POSTAL_ADDRESS';
export const COB_BUSINESS_SELECT_PHYSICAL_ADDRESS =
  'COB_BUSINESS_SELECT_PHYSICAL_ADDRESS';
export const COB_BUSINESS_PRESET_PHYSICAL_ADDRESS =
  'COB_BUSINESS_PRESET_PHYSICAL_ADDRESS';
export const COB_BUSINESS_PRESET_POSTAL_ADDRESS =
  'COB_BUSINESS_PRESET_POSTAL_ADDRESS';

export const COB_BUSINESS_SET_REGION = 'COB_BUSINESS_SET_REGION';
export const COB_BUSINESS_SET_STATE = 'COB_BUSINESS_SET_STATE';

export const COB_BUSINESS_COMPANY_MANUAL_SET =
  'COB_BUSINESS_COMPANY_MANUAL_SET';

export const COB_BUSINESS_SET_DEFAULT_ENTITY_DETAILS =
  'COB_BUSINESS_SET_DEFAULT_ENTITY_DETAILS';

export const COB_BUSINESS_SET_RECOVERY_EMAIL =
  'COB_BUSINESS_SET_RECOVERY_EMAIL';
export const COB_BUSINESS_SHOW_WARNING = 'COB_BUSINESS_SHOW_WARNING';

export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PREVIOUS_LASTNAME =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PREVIOUS_LASTNAME';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_POSITION =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_POSITION';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_ADDRESS =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_ADDRESS';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_API_ID =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_API_ID';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_TYPE =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_TYPE';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMPLOYER =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMPLOYER';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CONTACT_PHONE_NUMBER =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CONTACT_PHONE_NUMBER';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CONTACT_COUNTRY_CODE =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CONTACT_COUNTRY_CODE';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_IS_APPLICANT =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_IS_APPLICANT';

export const COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_CLEAR =
  'COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_CLEAR';
export const COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_START =
  'COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_START';
export const COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_SUCCESS =
  'COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_SUCCESS';
export const COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_ERROR =
  'COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_ERROR';

export const COB_BUSINESS_SET_SUPPLIER_TRADING_NAME_ID =
  'COB_BUSINESS_SET_SUPPLIER_TRADING_NAME_ID';

export const COB_BUSINESS_SET_ENTITY_ID = 'COB_BUSINESS_SET_ENTITY_ID';

export const COB_BUSINESS_SET_DELIVERY_INSTRUCTION =
  'COB_BUSINESS_SET_DELIVERY_INSTRUCTION';
export const COB_BUSINESS_SET_INCORPORATED_NUMBER =
  'COB_BUSINESS_SET_INCORPORATED_NUMBER';

export const COB_BUSINESS_SET_LEGAL_ENTITY_AGREEMENT =
  'COB_BUSINESS_SET_LEGAL_ENTITY_AGREEMENT';

export const COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_CLEAR =
  'COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_CLEAR';
export const COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_ERROR =
  'COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_ERROR';
export const COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_START =
  'COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_START';
export const COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_SUCCESS =
  'COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_SUCCESS';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PERSON_POSTAL_ADDRESS =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PERSON_POSTAL_ADDRESS';
export const COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PERSON_POSTAL_ADDRESS_API_ID =
  'COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PERSON_POSTAL_ADDRESS_API_ID';

export const COB_BUSINESS_PENDING_APPLICATION_ERROR =
  'COB_BUSINESS_PENDING_APPLICATION_ERROR';
export const COB_BUSINESS_PENDING_APPLICATION_START =
  'COB_BUSINESS_PENDING_APPLICATION_START';
export const COB_BUSINESS_PENDING_APPLICATION_SUCCESS =
  'COB_BUSINESS_PENDING_APPLICATION_SUCCESS';

export const COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_CLEAR =
  'COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_CLEAR';
export const COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_ERROR =
  'COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_ERROR';
export const COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_START =
  'COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_START';
export const COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_SUCCESS =
  'COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_SUCCESS';

export const COB_BUSINESS_SET_ENTITY_BUSINESS_API_DETAILS =
  'COB_BUSINESS_SET_ENTITY_BUSINESS_API_DETAILS';

export const COB_ENTITY_TYPE_MISSING = 'COB_ENTITY_TYPE_MISSING';
