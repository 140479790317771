import get from "lodash.get";
import AddonConfigModel from "models/AddonConfigModel";
import store from "stores/store";

import { FormBuilderRenderParams } from "../components/widgets/interactive/form_builder/types";
import { ApplicationType, LegalType } from "../types/application";

function activeIUFList(): AddonConfigModel[] {
  const list = get(store.getState(), "add_ons.internal_use_fields", []);

  return list.filter(addonConfig => get(addonConfig, "active", false));
}

export function activeIUF(applicationType: ApplicationType): AddonConfigModel | undefined {
  return activeIUFList().find(addonConfig => (
    get(addonConfig, "latestVersion.applicationTypes", []).includes(applicationType)
  ));
}

export function activeIUFConfig(applicationType: ApplicationType, legalType?: LegalType) {
  const addonVersion = activeIUF(applicationType)?.latestVersion;

  if (!addonVersion) {
    return;
  }

  const activeConfig = {
    ...addonVersion.config,
    addon_version_id: addonVersion.id,
  };

  if (!legalType) {
    return activeConfig;
  }

  const isLegalType = addonVersion.legalTypes.includes(legalType);

  if (!isLegalType) {
    return;
  }

  return activeConfig;
}

function isFieldTurnedOn(field: string, type?: ApplicationType): boolean {
  if (type) {
    return get(activeIUFConfig(type), field, false)
  }

  return activeIUFList().some(addonConfig => get(addonConfig, `latestVersion.config.${field}`, false));
}

export function isIUFApprovalEnabled(type?: ApplicationType): boolean {
  return isFieldTurnedOn("pricing.approval_enabled", type);
}

export function isIUFApprovalRequired(type?: ApplicationType): boolean {
  return isFieldTurnedOn("pricing.approval_required", type);
}

export function hasActiveIUFSearchSendConfig(type: ApplicationType): boolean {
  const config = activeIUFConfig(type);

  if (!config) {
    return false;
  }

  if (!config.search_send_enabled) {
    return false;
  }

  return ["account_level", "pricing"].some(configKey => {
    const { components } = config[configKey];

    return (
      components.some(({ search_send_applied }) => search_send_applied)
    )
  });
}

export function isIUFApplicationType(type: ApplicationType): boolean {
  const application_types = activeIUFConfig(type).application_types || []
  return application_types.includes(type);
}

function areFieldsCompleted(sections: [boolean][] = []): boolean {
  function _fieldCompleted(fields) {
    return fields.every(field => field);
  }

  return sections.every(fields => _fieldCompleted(fields));
}

export function isIUFSearchSendCompleted(): boolean {
  const { iufAccountLevel = [true], iufPricing = [true]} = get(store.getState(), "shared_data");

  return areFieldsCompleted([iufAccountLevel, iufPricing]);
}

export const isIUFCompleted = (): boolean => {
  const sharedData = get(store.getState(), "shared_data");
  const iufKeys = Object.keys(sharedData).filter(key => key.startsWith("iuf"))
  const iufSections = iufKeys.map(key => sharedData[key]);

  return areFieldsCompleted(iufSections);
}

export type IUFAnswers = {
  [key:string]: { answers: FormBuilderRenderParams[] },
}

export function updatedAnswers(answers, params) {
  const targetIndex = answers.findIndex(
    ({ description }) => description === params.description
  );

  if (targetIndex === -1) {
    answers.push(params);
  } else {
    answers[targetIndex] = params;
  }

  return answers;
}

export function updatedIUFAnswers({ iufAnswers, key, params }: {
  iufAnswers: IUFAnswers;
  key: string;
  params: FormBuilderRenderParams;
}): IUFAnswers {
  const answers = get(iufAnswers[key], "answers", []) as FormBuilderRenderParams[];

  return {
    ...iufAnswers,
    [key]: { answers: updatedAnswers(answers, params) },
  };
}
