import {
  COB_PAPERLESS_COMPONENT_COMPLETE,
  COB_PAPERLESS_CLEAR_ANSWER,
  COB_PAPERLESS_LOAD_ANSWER,
  COB_PAPERLESS_SET_ANSWER,
} from '../constants/paperless';

const intitialState = {
  answers: {
    applicantAuthorised: false,
    jointSignatories: [{ email: null, first_name: null, last_name: null }],
    multiSignature: false,
  },
  completed: {},
};

export const consumerOnboardingPaperlessReducer = (
  state = intitialState,
  action
) => {
  switch (action.type) {
    case COB_PAPERLESS_SET_ANSWER:
      return {
        ...state,
        answers: {
          ...state.answers,
          ...action.payload,
        },
      };
    case COB_PAPERLESS_COMPONENT_COMPLETE: {
      const componentState = {};
      componentState[action.payload.component] = action.payload.state;
      return { ...state, completed: { ...state.completed, ...componentState } };
    }
    case COB_PAPERLESS_LOAD_ANSWER: {
      let answers = action.payload.answers;
      if (answers.jointSignatories.length === 0) {
        answers = {
          ...answers,
          jointSignatories: intitialState.answers.jointSignatories,
        };
      }

      return {
        ...state,
        ...action.payload,
        answers,
      };
    }
    case COB_PAPERLESS_CLEAR_ANSWER:
      return {
        ...state,
        ...intitialState,
      };
    default:
      return state;
  }
};
