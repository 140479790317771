/* eslint-disable max-lines */
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import get from 'lodash.get';
import { collateralTypeHasDescription } from 'models/PpsrFinancingStatementCollateralModel';
import NZPPSRCollateralAdditionalForms from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRForm/NZPPSRCollateralAdditionalForms';
import setCollateralTypeDetails from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRForm/utils/setCollateralTypeDetails';
import {
  AddDebtor,
  AddressFinder,
  getAvailableCollateralTypeOptions,
  getSelectedDebtorTypeOption,
  OrganisationNameForm,
  PersonDateOfBirthForm,
  useSelectedCollateralTypeState,
} from 'modules/new-applications/components/application-actions/PPSRRegister/shared/FormComponents';
import { getDateFiveYearsFromNow } from 'modules/new-applications/components/application-actions/PPSRRegister/validationSchema/NZValidationSchema';
import styles from 'modules/new-applications/css/PPSRRegister.css';
import useAddressState from 'modules/new-applications/hooks/useAddressState';
import GridContent from 'modules/shared/components/containers/GridContent';
import BorderedAutocomplete from 'modules/shared/components/inputs/BorderedAutocomplete';
import BorderedCalendarPicker from 'modules/shared/components/inputs/BorderedCalendarPicker';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import FormCheckBox from 'modules/shared/components/inputs/FormCheckBox';
import LabeledInputContent from 'modules/shared/components/widgets/static/LabeledInputContent';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';

const DEBTOR_TYPE_OPTIONS = [
  { label: 'Organisation', value: 'organisation' },
  { label: 'Person', value: 'person' },
];

function getDefaultExpiryDate(expiryDate) {
  if (isPresent(expiryDate)) {
    return expiryDate;
  }

  return getDateFiveYearsFromNow();
}

function ExpiryDateForm(props) {
  const { errors, isVisible, onSelectDate, register, selectedDate } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <GridContent>
      <LabeledInputContent label="Expiry date">
        <BorderedCalendarPicker
          textFieldProps={{
            error: Boolean(errors.expiryDate),
            helperText: get(errors, 'expiryDate.message', ' '),
            id: 'expiry-date-datepicker',
            inputRef: register,
            name: 'expiryDate',
          }}
          minDate={new Date()}
          maxDate={getDateFiveYearsFromNow()}
          value={selectedDate}
          onChange={onSelectDate}
        />
      </LabeledInputContent>
    </GridContent>
  );
}

function FinancingStatementForm(props) {
  const {
    control,
    errors,
    ppsrFinancingStatement,
    register,
    setValue,
    watch,
  } = props;

  const [selectedDate, setSelectedDate] = useState(
    moment(ppsrFinancingStatement.expiryDate).format('DD/MM/YYYY')
  );
  const onSelectDate = (date) => {
    setSelectedDate(date);
    setValue('expiryDate', date);
  };

  const isAutoRenew = watch(
    'autoRenew',
    ppsrFinancingStatement.autoRenew || false
  );

  useEffect(() => {
    if (isAutoRenew) {
      setSelectedDate(null);
      setValue('expiryDate', null);
      setValue('expiryDuration', 'five_years');
    } else {
      const expiryDate = getDefaultExpiryDate(
        ppsrFinancingStatement.expiryDate
      );
      setSelectedDate(expiryDate);
      setValue('expiryDate', expiryDate);
      setValue('expiryDuration', 'custom');
    }
  }, [isAutoRenew, ppsrFinancingStatement.expiryDate]);

  return (
    <Fragment>
      <div className={styles.sub_header}>Financing statement</div>
      <GridContent>
        <input
          name="expiryDuration"
          type="hidden"
          ref={register}
          defaultValue="custom"
        />
        <div className={styles.checkbox_container}>
          <FormCheckBox
            id="autoRenew"
            label="Auto renewal"
            control={control}
            value={true}
            register={register}
            defaultValue={ppsrFinancingStatement.autoRenew || false}
            error={get(errors, 'autoRenew.message', '')}
            isCompact={true}
          />
        </div>
      </GridContent>
      <ExpiryDateForm
        errors={errors}
        isVisible={!isAutoRenew}
        ppsrFinancingStatement={ppsrFinancingStatement}
        register={register}
        selectedDate={selectedDate}
        setValue={setValue}
        onSelectDate={onSelectDate}
      />
    </Fragment>
  );
}

function CollateralDetailForm(props) {
  const {
    availableOptions,
    currentUser,
    errors,
    field,
    fields,
    index,
    isCollateralTypeFieldEnabled,
    onSetAlert,
    register,
    remove,
    setValue,
  } = props;
  const {
    selectedCollateralType,
    selectedCollateralTypeOption,
    setSelectedCollateralType,
  } = useSelectedCollateralTypeState({
    defaultValue: field.collateralType,
    region: 'NZ',
  });

  const collateralPrefix = `collaterals[${index}]`;
  const collateralTypeFieldName = `${collateralPrefix}.collateralType`;
  const collateralDescriptionFieldName = `${collateralPrefix}.collateralDescription`;

  const [collateralDescription, setCollateralDescription] = useState(
    field.collateralDescription
  );

  const onSelectCollateralType = (_, value) =>
    setCollateralTypeDetails({
      collateralDescriptionFieldName,
      collateralTypeFieldName,
      selectedOption: value,
      setCollateralDescription,
      setSelectedCollateralType,
      setValue,
    });

  useEffect(() => {
    register({ name: collateralTypeFieldName });
    setValue(collateralTypeFieldName, selectedCollateralType);
  }, [index, field.fieldId, fields, register]);

  let isCollateralTypeEnabled = isCollateralTypeFieldEnabled;
  if (isBlank(field.id)) {
    isCollateralTypeEnabled = true;
  }

  const hasDescription = collateralTypeHasDescription(selectedCollateralType);

  const onChangeDescription = (event) => {
    const description = get(event, 'target.value', '');
    setCollateralDescription(description);
    setValue(collateralDescriptionFieldName, description);
  };

  const onBlurDescription = (event) => {
    const description = get(event, 'target.value', '').trim();
    setCollateralDescription(description);
    setValue(collateralDescriptionFieldName, description);
  };

  return (
    <Fragment>
      <div className={styles.collateral_detail_item_row}>
        <input
          name={`${collateralPrefix}.id`}
          type="hidden"
          ref={register()}
          defaultValue={field.id}
        />
        <BorderedAutocomplete
          disabled={!isCollateralTypeEnabled}
          textFieldProps={{
            error: Boolean(errors.collateralType),
            helperText: get(errors, 'collateralType.message', ' '),
            label: 'Type',
            name: collateralTypeFieldName,
          }}
          options={availableOptions}
          value={selectedCollateralTypeOption}
          onChange={onSelectCollateralType}
        />
        {!hasDescription && (
          <HighlightOffIcon
            className={styles.remove_collateral_action}
            onClick={remove}
          />
        )}
        {hasDescription && (
          <div className={styles.collateral_detail_item_row_second_column}>
            <BorderedTextField
              label="Description"
              placeholder="Description"
              inputRef={register()}
              value={collateralDescription}
              onChange={onChangeDescription}
              onBlur={onBlurDescription}
              name={collateralDescriptionFieldName}
              error={Boolean(errors.collateralDescription)}
              helperText={get(errors, 'collateralDescription.message', ' ')}
            />
            <div>
              <HighlightOffIcon
                className={styles.remove_collateral_action}
                onClick={remove}
              />
            </div>
          </div>
        )}
      </div>
      <NZPPSRCollateralAdditionalForms
        collateralPrefix={collateralPrefix}
        collateralType={selectedCollateralType}
        currentUser={currentUser}
        errors={errors}
        field={field}
        onSetAlert={onSetAlert}
        register={register}
        setValue={setValue}
      />
    </Fragment>
  );
}

function CollateralFormError(props) {
  const { error } = props;

  if (isBlank(error)) {
    return null;
  }

  return <div className="has-text-danger">{error}</div>;
}

export function CollateralsForm(props) {
  const {
    clearError,
    collateralFieldArray,
    currentUser,
    errors,
    isCollateralTypeFieldEnabled,
    onSetAlert,
    ppsrAddonConfig,
    register,
    setValue,
  } = props;

  const { append, fields, remove } = collateralFieldArray;
  const collateralErrors = errors.collaterals || [];

  const availableOptions = getAvailableCollateralTypeOptions({
    config: ppsrAddonConfig,
    region: 'NZ',
  });

  const collaterals = fields.map((field, index) => (
    <CollateralDetailForm
      availableOptions={availableOptions}
      currentUser={currentUser}
      errors={collateralErrors[index] || {}}
      field={field}
      fields={fields}
      index={index}
      isCollateralTypeFieldEnabled={isCollateralTypeFieldEnabled}
      key={field.fieldId}
      onSetAlert={onSetAlert}
      register={register}
      remove={() => remove(index)}
      setValue={setValue}
    />
  ));

  const addCollateral = () => {
    clearError('collaterals');
    append({ collateralDescription: ' ' });
  };

  return (
    <Fragment>
      <div className={styles.sub_header}>Collateral details</div>
      <CollateralFormError error={get(errors, 'collaterals.message')} />
      {collaterals}
      <div className={styles.add_collateral_action} onClick={addCollateral}>
        + Add another collateral
      </div>
    </Fragment>
  );
}

CollateralsForm.defaultProps = {
  isCollateralTypeFieldEnabled: true,
};

function DebtorDetailForm(props) {
  const {
    application,
    currentUser,
    errors,
    field,
    fields,
    index,
    register,
    remove,
    setValue,
  } = props;

  const debtorTypeFieldName = `debtors[${index}].debtorType`;
  const debtorAddressFieldName = `debtors[${index}].debtorAddress`;

  const {
    fullAddress: debtorFullAddress,
    onSetNewRawAddress: onSetDebtorRawAddress,
    rawAddress: debtorRawAddress,
  } = useAddressState({
    initialFullAddress: get(field, 'debtorAddress.fullAddress', ''),
    initialRawAddress: get(field, 'debtorAddress.rawData', {}),
    onSetAddressCallback: (newRawAddress) => {
      setValue(debtorAddressFieldName, {
        fullAddress: newRawAddress.full_address,
        rawData: newRawAddress,
      });
    },
  });

  const selectedDebtorTypeOption = getSelectedDebtorTypeOption(
    DEBTOR_TYPE_OPTIONS,
    field.debtorType
  );

  useEffect(() => {
    register({ name: debtorTypeFieldName });
    setValue(debtorTypeFieldName, field.debtorType);

    register({ name: debtorAddressFieldName });
    setValue(debtorAddressFieldName, {
      fullAddress: debtorFullAddress,
      rawData: debtorRawAddress,
    });
  }, [index, field.fieldId, fields, register]);

  let removeAction = null;
  if (index > 0) {
    removeAction = (
      <div className="has-text-danger cursor-pointer" onClick={remove}>
        remove debtor
      </div>
    );
  }

  return (
    <Fragment>
      <div className={`${styles.sub_header} is-flex`}>
        <div className="has-text-weight-normal mr-2">Debtor {index + 1}</div>
        {removeAction}
      </div>
      <input
        name={`debtors[${index}].id`}
        type="hidden"
        ref={register()}
        defaultValue={field.id}
      />
      <input
        name={`debtors[${index}].authorisationId`}
        type="hidden"
        ref={register()}
        defaultValue={field.authorisationId}
      />
      <GridContent>
        <BorderedAutocomplete
          disabled={true}
          textFieldProps={{
            error: Boolean(errors.debtorType),
            helperText: get(errors, 'debtorType.message', ' '),
            label: 'Debtor type',
            name: debtorTypeFieldName,
          }}
          options={DEBTOR_TYPE_OPTIONS}
          value={selectedDebtorTypeOption}
        />
        <OrganisationNameForm
          defaultValue={field.organisationName}
          errors={errors}
          fieldName={`debtors[${index}].organisationName`}
          isVisible={field.debtorType === 'organisation'}
          register={register}
        />
        <BorderedTextField
          label="First name"
          placeholder="First name"
          defaultValue={field.personFirstName}
          disabled={true}
          inputRef={register()}
          name={`debtors[${index}].personFirstName`}
          error={Boolean(errors.personFirstName)}
          helperText={get(errors, 'personFirstName.message', ' ')}
        />
        <BorderedTextField
          label="Last name"
          placeholder="Last name"
          defaultValue={field.personLastName}
          disabled={true}
          inputRef={register()}
          name={`debtors[${index}].personLastName`}
          error={Boolean(errors.personLastName)}
          helperText={get(errors, 'personLastName.message', ' ')}
        />
        <BorderedTextField
          label="Debtor reference"
          placeholder="Debtor reference"
          defaultValue={field.debtorReference}
          inputRef={register()}
          name={`debtors[${index}].debtorReference`}
          error={Boolean(errors.debtorReference)}
          helperText={get(errors, 'debtorReference.message', ' ')}
        />
        <BorderedTextField
          label="Email"
          placeholder="Email"
          defaultValue={field.debtorEmail}
          inputRef={register()}
          name={`debtors[${index}].debtorEmail`}
          error={Boolean(errors.debtorEmail)}
          helperText={get(errors, 'debtorEmail.message', ' ')}
        />
        <AddressFinder
          currentUser={currentUser}
          errors={errors}
          fieldName={debtorAddressFieldName}
          rawAddress={debtorRawAddress}
          region={application.region}
          setRawAddress={onSetDebtorRawAddress}
        />
        <BorderedTextField
          label="Phone"
          placeholder="Phone"
          defaultValue={field.debtorPhone}
          inputRef={register()}
          name={`debtors[${index}].debtorPhone`}
          error={Boolean(errors.debtorPhone)}
          helperText={get(errors, 'debtorPhone.message', ' ')}
        />
        <PersonDateOfBirthForm
          errors={errors}
          fieldName={`debtors[${index}].personDateOfBirth`}
          isVisible={field.debtorType === 'person'}
          defaultValue={field.personDateOfBirth}
          register={register}
          setValue={setValue}
        />
      </GridContent>
    </Fragment>
  );
}

export function DebtorsForm(props) {
  const {
    application,
    debtorFieldArray,
    currentUser,
    errors,
    register,
    setValue,
  } = props;

  const { append, fields, remove } = debtorFieldArray;
  const debtorErrors = errors.debtors || [];

  const debtors = fields.map((field, index) => (
    <DebtorDetailForm
      application={application}
      currentUser={currentUser}
      errors={debtorErrors[index] || {}}
      field={field}
      fields={fields}
      index={index}
      key={field.fieldId}
      register={register}
      setValue={setValue}
      remove={() => remove(index)}
    />
  ));

  return (
    <Fragment>
      <div className={styles.sub_header}>Debtor details</div>
      {debtors}
      <AddDebtor append={append} application={application} fields={fields} />
    </Fragment>
  );
}

export default function NZPPSRForm(props) {
  const {
    application,
    clearError,
    collateralFieldArray,
    control,
    currentUser,
    debtorFieldArray,
    errors,
    onSetAlert,
    ppsrFinancingStatement,
    register,
    setValue,
    watch,
  } = props;

  return (
    <Fragment>
      <DebtorsForm
        debtorFieldArray={debtorFieldArray}
        application={application}
        currentUser={currentUser}
        errors={errors}
        register={register}
        setValue={setValue}
      />
      <FinancingStatementForm
        application={application}
        control={control}
        errors={errors}
        ppsrFinancingStatement={ppsrFinancingStatement}
        register={register}
        setValue={setValue}
        watch={watch}
      />
      <CollateralsForm
        clearError={clearError}
        collateralFieldArray={collateralFieldArray}
        currentUser={currentUser}
        errors={errors}
        onSetAlert={onSetAlert}
        ppsrAddonConfig={application.ppsrAddonConfig || {}}
        register={register}
        setValue={setValue}
      />
    </Fragment>
  );
}
