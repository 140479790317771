/* Color constants */
export const STYLE_COLOR_BASE = '#00afef';
export const STYLE_COLOR_BASE_ALT = '#7fcef3';
export const STYLE_COLOR_SHADE_DARKEST = '#1a1a1a';
export const STYLE_COLOR_SHADE_DARK = '#5e5e5e';
export const STYLE_COLOR_SHADE = '#a5a5a5';
export const STYLE_COLOR_SHADE_LIGHT = '#c9c9c9';
export const STYLE_COLOR_SHADE_LIGHTEST = '#f6f6f6';
export const STYLE_COLOR_WHITE = '#ffffff';
export const STYLE_COLOR_HIGHLIGHT = '#F44335';

export const STYLE_COLOR_BASE_BLUE = '#00afef';
export const STYLE_COLOR_BASE_BLUE_LIGHT = '#7fcef3';
export const STYLE_COLOR_BASE_GREY_MEDIUM = '#5e5e5e';
export const STYLE_COLOR_BASE_GREY_LIGHT = '#c9c9c9';
export const STYLE_COLOR_BASE_GREY_LIGHTER = '#e9e9e9';
export const STYLE_COLOR_BASE_RED = '#F44335';

export const STYLE_FONTS_SIZE = '18px';
export const STYLE_FONTS_FAMILY = 'Arial, sans-serif';

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NZ_DRIVER_LICENCE_VERSION_REGEX = /^\d{3}$/;
export const NZ_DRIVER_LICENCE_NUMBER_REGEX = /^[a-zA-Z]{2}\d{6}$/;
export const NZ_PASSPORT_NUMBER_REGEX = /^[a-zA-Z]{1,2}\d{6}$/;

export const TERMS_AND_CONDITIONS = {
  AU: {
    guarantor:
      'https://d20chkok2cesbo.cloudfront.net/pdfs/files/1centre_terms_document/20180112+AU+FINAL+1Centre+Guarantee+Doc+(00494982xE3943).pdf',
    paperless:
      'https://d20chkok2cesbo.cloudfront.net/pdfs/files/1centre_terms_document/AU_Paperless_T%26C.pdf',
    supplier:
      'https://d20chkok2cesbo.cloudfront.net/pdfs/files/1centre_terms_document/20180112+FINAL+AU+Generic+Supplier+-+Business+Customer+T+Cs+(00494970xE3....pdf',
  },
  NZ: {
    guarantor:
      'https://d20chkok2cesbo.cloudfront.net/pdfs/files/1centre_terms_document/NZ_Guarantor_T%26C.pdf',
    paperless:
      'https://d20chkok2cesbo.cloudfront.net/pdfs/files/1centre_terms_document/NZ_Paperless_T%26C.pdf',
    supplier:
      'https://d20chkok2cesbo.cloudfront.net/pdfs/files/1centre_terms_document/NZ_Supplier_T%26C.pdf',
  },
};
