import React from 'react';
import MenuItem from './MenuItem';

import './menulist.scss';

const MenuList = ({ menuItems, activeIndex, setActiveIndex }) => (
  <div className="menu-list">
    {menuItems.map((item, index) => (
      <MenuItem
        key={index}
        indicator={item.indicator}
        isActive={activeIndex === index}
        isIndicatorVisible={item.isIndicatorVisible}
        onClick={() => setActiveIndex(index)}
        title={item.title}
      />
    ))}
  </div>
);

export default MenuList;
