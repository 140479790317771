import get from 'lodash.get';
import AntiFraudAddonVersionModel from 'models/AntiFraudAddonVersionModel';
import ApplicationModel from 'models/ApplicationModel';
import store from 'stores/store';
import getApplicableAntiFraudRulesForApplicationFlow from 'utils/anti-fraud/getApplicableAntiFraudRulesForApplicationFlow';

export function isTokenised() {
  const state = store.getState();
  const authorisation = state.authorisation;
  const cobSection = state.cob_section;
  const antiFraud = cobSection.antiFraud || authorisation.antiFraud;

  return antiFraud && antiFraud.config.tokenised;
}

export function getIsIdentificationDisclaimerVisible() {
  const state = store.getState();
  const antiFraudConfig = new AntiFraudAddonVersionModel(
    get(state, 'cob_section.antiFraud', {})
  );
  const antiFraudCategoryRules = getApplicableAntiFraudRulesForApplicationFlow({
    antiFraud: antiFraudConfig,
    application: new ApplicationModel(
      get(state, 'cob_section.application', {})
    ),
    cards: [],
    guarantors: {},
    paperless: [],
    people: [],
    requestedLimit: get(state, 'cob_money.requested_limit'),
  });

  return isTokenised() && antiFraudCategoryRules.requiresIdentificationImage;
}
