import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { countTypeValue } from './helpers/summary';
import { displayEmpty } from 'utils/formatting';
import { enquiryResponseData } from './helpers/reportHelpers';
import { stringToSimpleDate } from 'utils/dateFormatter';
import styles from '../../../css/CommonReviewBusiness.css';

var CurrentDirectors = createClass({
  _address(address) {
    if (address === null || address === undefined) return displayEmpty(address);

    return [
      address.street_number,
      address.street_name,
      address.suburb,
      address.state,
      address.postcode,
    ].join(' ');
  },

  _birthDetails(birthDetails) {
    if (birthDetails === null || birthDetails === undefined)
      return displayEmpty(birthDetails);

    return [birthDetails.birth_locality, birthDetails.birth_state].join(' ');
  },

  _documentDetails(documentDetails) {
    if (documentDetails === null || documentDetails === undefined)
      return displayEmpty(documentDetails);

    return displayEmpty(documentDetails.document_number);
  },

  _directors() {
    const { data, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      directorList = response.ownership_officers.directors_list || {},
      directors = directorList.directors || [];

    if (directors.constructor === Object) directors = [directors];

    return directors.map((director, index) => {
      let name = director.individual_name;

      return (
        <div className={styles.table_wrapper} key={index}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Director Name</th>
                <td>
                  {''.concat(
                    name.family_name,
                    ', ',
                    name.first_given_name,
                    ' ',
                    name.other_given_name
                  )}
                </td>
                <th>File Number</th>
                <td>{displayEmpty(director.bureau_reference)}</td>
              </tr>
              <tr>
                <th>Appointment Date</th>
                <td>{stringToSimpleDate(director.appointment_date)}</td>
                <th>Resident Overseas</th>
                <td>{director.residency_overseas}</td>
              </tr>
              <tr>
                <th>Date of Birth</th>
                <td>{stringToSimpleDate(director.date_of_birth)}</td>
                <th>Address</th>
                <td>{this._address(director.address)}</td>
              </tr>
              <tr>
                <th>Place of Birth</th>
                <td>{this._birthDetails(director.birth_details)}</td>
                <th>Document Number</th>
                <td>{this._documentDetails(director.document_details)}</td>
              </tr>
              <tr>
                <th>Gender</th>
                <td>{director.gender}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  },

  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    return (
      <ReviewContainer
        subHeading="Current Directors"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <strong>Total: {countTypeValue(summaryEntry, 'Directors')}</strong>
        {this._directors()}
      </ReviewContainer>
    );
  },
});

export default CurrentDirectors;
