import { createMuiTheme } from '@material-ui/core/styles';
import get from 'lodash.get';
import store from 'stores/store';

export const defaultTheme = {
  main_color: '#00afef',
  main_color_active: '#00a5e1',
  main_font_color: '#ffffff',
};

function _theme() {
  return (
    _settingsColorPaletteValue().theme ||
    store.getState().cob_section.color_palette ||
    store.getState().authorisation.theme ||
    get(store.getState(), 'cob_section.supplier.attributes.theme') ||
    get(store.getState(), 'permissions_flow.settings_color_palette.theme') ||
    defaultTheme
  );
}

function _settingsColorPaletteValue() {
  return store.getState().manage_profile.settings_color_palette || {};
}

export function setTheme(theme) {
  document.documentElement.style.setProperty('--main-color', theme.main_color);
  document.documentElement.style.setProperty(
    '--main-color-active',
    theme.main_color_active
  );
  document.documentElement.style.setProperty(
    '--main-font-color',
    theme.main_font_color
  );
}

export function muiTheme() {
  return createMuiTheme({
    overrides: {
      MuiCheckbox: {
        colorSecondary: {
          '&$checked': {
            color: _theme().main_color,
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          /* boxShadow: "0 0 0 4px rgba(var(--main-color),0.1)", */
          // backgroundColor: "white",
        },
      },
      MuiRadio: {
        colorSecondary: {
          '&$checked': {
            color: _theme().main_color,
          },
        },
      },
    },
    palette: {
      primary: {
        main: _theme().main_color,
      },
      secondary: {
        main: _theme().main_color_active,
      },
    },
    typography: {
      useNextVariants: true,
    },
  });
}
