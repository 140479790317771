/* eslint-disable max-lines */
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import get from 'lodash.get';
import snakeCase from 'lodash.snakecase';
import AddressModel from 'models/AddressModel';
import PpsrFinancingStatementDebtorModel, {
  DEFAULT_DEBTOR_REFERENCE,
} from 'models/PpsrFinancingStatementDebtorModel';
import PpsrFinancingStatementModel, {
  MULTIPLE_REGISTRATION_SUPPORTED_REGION,
} from 'models/PpsrFinancingStatementModel';
import PPSRForm from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRForm/PPSRForm';
import PPSRRegisterDetails from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRRegisterDetails';
import PPSRRegisterDischarge from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRRegisterDischarge';
import PPSRRegisterModify from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRRegisterModify';
import PPSRRegisterRenew from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRRegisterRenew';
import defaultExpiryDateByRegion from 'modules/new-applications/components/application-actions/PPSRRegister/shared/defaultExpiryDateByRegion';
import { validateExpiryDate } from 'modules/new-applications/components/application-actions/PPSRRegister/validationSchema/NZValidationSchema';
import {
  getCollateralDetailsValidationSchema,
  getDebtorDetailsValidationSchema,
  getFinancingStatementValidationSchema,
} from 'modules/new-applications/components/application-actions/PPSRRegister/validationSchema/validationSchema';
import RecordHistory from 'modules/new-applications/components/RecordHistory';
import styles from 'modules/new-applications/css/PPSRRegister.css';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import ContentWithFooter from 'modules/shared/components/containers/ContentWithFooter';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import ScrollableContent from 'modules/shared/components/containers/ScrollableContent';
import Button from 'modules/shared/components/inputs/Button';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import objectKeysToSnakeCase from 'utils/objectKeysToSnakeCase';
import sortByCreatedAt from 'utils/sortByCreatedAt';
import * as yup from 'yup';

const NAVIGATION_PAGES = {
  details: PPSRRegisterDetails,
  discharge: PPSRRegisterDischarge,
  modify: PPSRRegisterModify,
  renew: PPSRRegisterRenew,
};

const REQUIRE_DEBTOR_ADDRESS = {
  AU: false,
  NZ: true,
};

// TODO: Remove references to this const. This has been moved to
// NZValidationSchema.js
// Used in PPSRModify
export const VALIDATION_SCHEMA = yup.object().shape({
  autoRenew: yup.boolean(),
  expiryDate: yup
    .string()
    .test('expiry-date', 'Please enter expiry date.', validateExpiryDate),
});

function validateCollaterals({
  clearError,
  collaterals,
  region,
  setError,
  status,
}) {
  clearError('collaterals');

  if (status === 'draft') {
    return false;
  }

  if (isBlank(collaterals)) {
    setError('collaterals', 'required', 'Please add at least one collateral.');
    return;
  }

  const collateralValidationSchema = getCollateralDetailsValidationSchema(
    region
  );

  collaterals.forEach((collateral, index) => {
    try {
      collateralValidationSchema.validateSync(collateral, {
        abortEarly: false,
      });
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        for (const innerError of error.inner) {
          setError(
            `collaterals[${index}].${innerError.path}`,
            innerError.type,
            innerError.message
          );
        }
      }
    }
  });
}

function validateDebtors({ clearError, debtors, region, setError, status }) {
  clearError('debtors');

  if (status === 'draft') {
    return;
  }

  debtors.forEach((debtor, index) => {
    const validationSchema = getDebtorDetailsValidationSchema(
      debtor.debtorType,
      region
    );

    try {
      validationSchema.validateSync(debtor, { abortEarly: false });
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        for (const innerError of error.inner) {
          setError(
            `debtors[${index}].${innerError.path}`,
            innerError.type,
            innerError.message
          );
        }
      }
    }
  });
}

export function validateArrayFields({
  clearError,
  getValues,
  region,
  setError,
  status,
}) {
  const { collaterals, debtors } = getValues({ nest: true });

  validateDebtors({ clearError, debtors, region, setError, status });
  validateCollaterals({ clearError, collaterals, region, setError, status });
}

export function getAlertErrorMessage(error) {
  const errorResponse = get(error, 'response.data.error', null);
  const genericErrorMessage = (
    <Fragment>
      <div>There was an error connecting to the PPSR API.</div>
      Try again later or contact support@1centre.com.
    </Fragment>
  );

  if (isBlank(errorResponse)) {
    return genericErrorMessage;
  }

  try {
    const parsedError = JSON.parse(errorResponse) || {};

    if (Object.keys(parsedError).includes('errorMessage')) {
      return (
        <Fragment>
          <div>{parsedError.errorMessage}</div>
          Try again later or contact support@1centre.com.
        </Fragment>
      );
    }

    if (Object.keys(parsedError).includes('error_message')) {
      return (
        <Fragment>
          <div>{parsedError.error_message}</div>
          Try again later or contact support@1centre.com.
        </Fragment>
      );
    }
  } catch (error) {
    return genericErrorMessage;
  }

  return genericErrorMessage;
}

function convertToBoolean(value) {
  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return value;
}

// TODO: Not sure why react-hook-form is not inferring the correct datatype
// and the boolean value TRUE is being returned as a string "true".
function sanitizeCollateralAdditionalDetails(additionalDetails) {
  if (isBlank(additionalDetails)) {
    return additionalDetails;
  }

  const additionalDetailsAttributes = {};

  for (const key of Object.keys(additionalDetails)) {
    const value = convertToBoolean(additionalDetails[key]);

    additionalDetailsAttributes[snakeCase(key)] = value;
  }

  return additionalDetailsAttributes;
}

const sanitizeCollateralAttributes = (collateralAttributes) => {
  const { collateralDescription } = collateralAttributes;

  collateralAttributes['collateralDescription'] = collateralDescription.trim();

  return collateralAttributes;
};

export function buildCollateralsAttributes(collaterals) {
  return collaterals
    .filter(
      (collateral) =>
        isPresent(collateral.collateralType) ||
        isPresent(collateral.collateralDescription.trim())
    )
    .map((collateral) => {
      const { additionalDetails, ...collateralAttributes } = collateral;
      const sanitizedAdditionalDetails = sanitizeCollateralAdditionalDetails(
        additionalDetails
      );

      const sanitizedCollateralAttributes = sanitizeCollateralAttributes(
        collateralAttributes
      );

      return {
        additional_details: sanitizedAdditionalDetails,
        ...objectKeysToSnakeCase(sanitizedCollateralAttributes),
      };
    });
}

export function buildDebtorsAttributes({ debtors, region }) {
  return debtors.map((debtor) => {
    const { debtorAddress, ...otherAttributes } = debtor;
    const attributes = objectKeysToSnakeCase(otherAttributes);

    const isDebtorAddressRequired = REQUIRE_DEBTOR_ADDRESS[region];

    if (isPresent(debtorAddress) && isDebtorAddressRequired) {
      attributes['debtor_address_attributes'] = debtorAddress.rawData;
    }

    return attributes;
  });
}

function Form(props) {
  const {
    application,
    currentUser,
    onFetchApplicationRecord,
    onSetAlert,
    onSetPPSRFinancingStatement,
    ppsrFinancingStatement,
  } = props;

  const { region } = application;
  const {
    isLoading: isSavingDraft,
    setIsLoading: setIsSavingDraft,
  } = useIsLoadingState();
  const {
    isLoading: isSaving,
    setIsLoading: setIsSaving,
  } = useIsLoadingState();

  const {
    clearError,
    control,
    errors,
    getValues,
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
  } = useForm({
    defaultValues: ppsrFinancingStatement.formValues,
    validationSchema: getFinancingStatementValidationSchema(region),
  });

  const debtorFieldArray = useFieldArray({
    control,
    keyName: 'fieldId',
    name: 'debtors',
  });
  const collateralFieldArray = useFieldArray({
    control,
    keyName: 'fieldId',
    name: 'collaterals',
  });

  useEffect(() => {
    const debtorValues = ppsrFinancingStatement.formDebtors || [];
    setValue('debtors', debtorValues);

    const collateralValues = [{ collateralDescription: ' ' }];
    setValue(
      'collaterals',
      get(ppsrFinancingStatement, 'formValues.collaterals', collateralValues)
    );
  }, [register]);

  const onSaveDraft = (event) => {
    onSubmit({
      event,
      resetLoadingSate: () => setIsSavingDraft(false),
      setLoadingState: () => setIsSavingDraft(true),
      status: 'draft',
    });
  };

  const onRegister = (event) => {
    onSubmit({
      event,
      resetLoadingSate: () => setIsSaving(false),
      setLoadingState: () => setIsSaving(true),
      status: 'registered',
    });
  };

  const onSubmit = ({ event, resetLoadingSate, setLoadingState, status }) => {
    event.preventDefault();

    if (isSavingDraft || isSaving) {
      return;
    }

    setValue('status', status);

    validateArrayFields({ clearError, getValues, region, setError, status });

    if (isPresent(errors)) {
      return;
    }

    handleSubmit((data) => {
      const {
        collaterals = [],
        debtors = [],
        expiryDate,
        ...attributes
      } = data;

      setLoadingState();

      const collateralAttributes = buildCollateralsAttributes(collaterals);
      const debtorAttributes = buildDebtorsAttributes({ debtors, region });

      attributes.expiryDate = moment(expiryDate, 'DD/MM/YYYY').toDate();
      attributes.ppsr_financing_statement_collaterals_attributes = collateralAttributes;
      attributes.ppsr_financing_statement_debtors_attributes = debtorAttributes;

      ppsrFinancingStatement.save({
        application,
        attributes,
        currentUser,
        onErrorCallback: (error) => {
          onSetAlert({ message: getAlertErrorMessage(error), type: 'error' });

          const responsePPSRFinancingStatement = get(
            error,
            'response.data.ppsr_financing_statement'
          );
          if (responsePPSRFinancingStatement) {
            const newPpsrFinancingStatement = new PpsrFinancingStatementModel(
              responsePPSRFinancingStatement.data,
              responsePPSRFinancingStatement.included
            );
            onSetPPSRFinancingStatement(newPpsrFinancingStatement);
          }

          resetLoadingSate(false);
        },
        onSuccessCallback: (newPpsrFinancingStatement) => {
          onSetPPSRFinancingStatement(newPpsrFinancingStatement);

          let alertMessage = 'Saved.';

          if (status === 'registered') {
            alertMessage = 'Submitted.';

            // Fetch application record to load the notes.
            onFetchApplicationRecord();
          }

          onSetAlert({ message: alertMessage, type: 'success' });
          resetLoadingSate(false);
        },
      });
    })();
  };

  let warningMessage = null;
  if (isPresent(application.ppsrRegisteredInOtherBranch)) {
    warningMessage = (
      <div className={styles.warning_message_container}>
        *Warning:{' '}
        <span className={styles.warning_message}>
          This customer already exists in{' '}
          {application.ppsrRegisteredInOtherBranch} and already has a PPSR
          registered.
        </span>
      </div>
    );
  }

  return (
    <form>
      {warningMessage}
      <PPSRForm
        application={application}
        clearError={clearError}
        collateralFieldArray={collateralFieldArray}
        control={control}
        currentUser={currentUser}
        debtorFieldArray={debtorFieldArray}
        errors={errors}
        onSetAlert={onSetAlert}
        ppsrAddonConfig={application.ppsrAddonConfig || {}}
        ppsrFinancingStatement={ppsrFinancingStatement}
        region={application.region}
        register={register}
        setValue={setValue}
        watch={watch}
      />
      <div>
        <input
          id="status"
          name="status"
          type="hidden"
          ref={register}
          defaultValue={ppsrFinancingStatement.status}
        />
        <Button
          text="Save draft"
          loading={isSavingDraft}
          loading_text="Saving draft"
          disabled={isSavingDraft || isSaving}
          style={{ marginRight: 60 }}
          handleClick={onSaveDraft}
        />
        <Button
          text="Submit"
          loading={isSaving}
          loading_text="Submitting"
          disabled={isSavingDraft || isSaving}
          handleClick={onRegister}
        />
      </div>
    </form>
  );
}

function getOrganisationNumber(application) {
  if (isPresent(application.companyNumber)) {
    return application.companyNumber;
  }

  if (application.businessNumber) {
    return application.businessNumber;
  }

  return application.incorporatedNumber;
}

function buildOrganisationFinancingStatementAttributes(application) {
  const debtorAddress = new AddressModel({});
  debtorAddress.setAttributes({
    alpha2: get(application, 'physicalAddress.attributes.alpha2', ''),
    api_id: get(application, 'physicalAddress.attributes.api_id', ''),
    api_provider: get(
      application,
      'physicalAddress.attributes.api_provider',
      ''
    ),
    full_address: get(
      application,
      'physicalAddress.attributes.full_address',
      ''
    ),
    raw_data: get(application, 'physicalAddress.attributes.raw_data', ''),
  });

  return {
    debtorAddress,
    debtorEmail: get(application, 'consumer.keyContactEmail', ''),
    debtorPhone: application.contactPhoneNumber,
    debtorType: 'organisation',
    organisationName: application.formattedBusinessName,
    organisationNumber: getOrganisationNumber(application),
    personFirstName: application.consumerContactFirstName,
    personLastName: application.consumerContactLastName,
  };
}

function retrievePersonalApplicationApplicant({
  people,
  applicantAuthorisation,
}) {
  if (people.length === 1) {
    return people[0];
  }

  const person = people.find((person) => person.isApplicant);
  if (isPresent(person)) {
    return person;
  }

  return (
    people.find(
      (person) =>
        person.email === applicantAuthorisation.email &&
        person.firstName === applicantAuthorisation.firstName &&
        person.lastName === applicantAuthorisation.lastName
    ) || {}
  );
}

function buildPersonFinancingStatementAttributes(application) {
  const applicantAuthorisation = application.authorisations.find(
    (authorisation) => authorisation.isApplicant
  );

  if (isBlank(applicantAuthorisation)) {
    return {};
  }

  const person = retrievePersonalApplicationApplicant({
    applicantAuthorisation,
    people: get(application, 'consumer.people', []),
  });

  const debtorAddress = new AddressModel({});
  debtorAddress.setAttributes({
    alpha2: get(person, 'address.attributes.alpha2', ''),
    api_id: get(person, 'address.attributes.api_id', ''),
    api_provider: get(person, 'address.attributes.api_provider', ''),
    full_address: get(person, 'address.attributes.full_address', ''),
    raw_data: get(person, 'address.attributes.raw_data', ''),
  });

  return {
    authorisationId: applicantAuthorisation.id,
    debtorAddress,
    debtorEmail: applicantAuthorisation.email,
    debtorPhone: person.contactPhoneNumber,
    debtorType: 'person',
    personDateOfBirth: get(applicantAuthorisation, 'signature.dob'),
    personFirstName: applicantAuthorisation.firstName,
    personLastName: applicantAuthorisation.lastName,
  };
}

function buildInitialDebtor(application) {
  let attributes = {};
  if (application.legalType === 'personal') {
    attributes = buildPersonFinancingStatementAttributes(application);
  } else {
    attributes = buildOrganisationFinancingStatementAttributes(application);
  }

  attributes.debtorReference = DEFAULT_DEBTOR_REFERENCE;

  const { debtorAddress, ...debtorAttributes } = attributes;
  const debtorModel = new PpsrFinancingStatementDebtorModel({
    attributes: debtorAttributes,
  });
  debtorModel.debtorAddress = debtorAddress;

  return debtorModel;
}

function getDefaultAutoRenewAndExpiryDate(application) {
  const { ppsrAddonConfig } = application;

  const autoRenewConfigured = get(
    ppsrAddonConfig,
    'financing.auto_renew',
    false
  );

  const expiryDate = defaultExpiryDateByRegion(application.region);

  return { autoRenew: autoRenewConfigured, expiryDate };
}

function buildNewFinancingStatementRecord(application) {
  const financingStatementModel = new PpsrFinancingStatementModel({});

  const { autoRenew, expiryDate } = getDefaultAutoRenewAndExpiryDate(
    application
  );

  financingStatementModel.setAttributes({
    autoRenew,
    expiryDate,
    status: 'draft',
  });

  const debtorModel = buildInitialDebtor(application);
  financingStatementModel.ppsrFinancingStatementDebtors = [debtorModel];

  return financingStatementModel;
}

function assignDischargedPPSRFinancingStatementToApplication({
  application,
  dischargedPPSRFinancingStatement,
}) {
  const ppsrFinancingStatements = application.ppsrFinancingStatements || [];
  const dischargedRecordIndex = ppsrFinancingStatements.findIndex(
    (ppsrFinancingStatement) =>
      ppsrFinancingStatement.id === dischargedPPSRFinancingStatement.id
  );

  if (dischargedRecordIndex >= 0) {
    ppsrFinancingStatements.splice(
      dischargedRecordIndex,
      1,
      dischargedPPSRFinancingStatement
    );
  }

  application.ppsrFinancingStatements = ppsrFinancingStatements;
}

function assignMultiplePPSRFinancingStatementsToApplication({
  application,
  dischargedPPSRFinancingStatement,
  isDischarged,
  newPPSRFinancingStatement,
}) {
  const ppsrFinancingStatements = application.ppsrFinancingStatements || [];

  if (isDischarged) {
    assignDischargedPPSRFinancingStatementToApplication({
      application,
      dischargedPPSRFinancingStatement,
    });
  }

  const matchingRecordIndex = ppsrFinancingStatements.findIndex(
    (ppsrFinancingStatement) =>
      ppsrFinancingStatement.id === newPPSRFinancingStatement.id
  );

  if (matchingRecordIndex >= 0) {
    ppsrFinancingStatements.splice(
      matchingRecordIndex,
      1,
      newPPSRFinancingStatement
    );
    application.ppsrFinancingStatements = ppsrFinancingStatements;

    return;
  }

  ppsrFinancingStatements.push(newPPSRFinancingStatement);
}

function assignSinglePPSRFinancingStatementsToApplication({
  application,
  dischargedPPSRFinancingStatement,
  isDischarged,
  newPPSRFinancingStatement,
}) {
  if (isDischarged) {
    application.ppsrFinancingStatements = [dischargedPPSRFinancingStatement];

    return;
  }

  application.ppsrFinancingStatements = [newPPSRFinancingStatement];
}

function assignApplicationPPSRFinancingStatements({
  application,
  dischargedPPSRFinancingStatement,
  isDischarged,
  isMultipleRegistrationsSupported,
  newPPSRFinancingStatement,
}) {
  if (isMultipleRegistrationsSupported) {
    assignMultiplePPSRFinancingStatementsToApplication({
      application,
      dischargedPPSRFinancingStatement,
      isDischarged,
      newPPSRFinancingStatement,
    });

    return;
  }

  assignSinglePPSRFinancingStatementsToApplication({
    application,
    dischargedPPSRFinancingStatement,
    isDischarged,
    newPPSRFinancingStatement,
  });
}

function usePPSRFinancingStatementState(props) {
  const {
    application,
    defaultPpsrFinancingStatement,
    isMultipleRegistrationsSupported,
    onUpdateApplicationState,
  } = props;

  const [ppsrFinancingStatement, setPpsrFinancingStatement] = useState(
    defaultPpsrFinancingStatement ||
      buildNewFinancingStatementRecord(application)
  );

  const onSetDischargedPPSRFinancingStatement = (
    dischargedPPSRFinancingStatement
  ) => {
    const newPPSRFinancingStatement = buildNewFinancingStatementRecord(
      application
    );
    setPpsrFinancingStatement(newPPSRFinancingStatement);
    assignApplicationPPSRFinancingStatements({
      application,
      dischargedPPSRFinancingStatement,
      isDischarged: true,
      isMultipleRegistrationsSupported,
      newPPSRFinancingStatement,
    });

    onUpdateApplicationState();
  };

  const onSetPPSRFinancingStatement = (newPPSRFinancingStatement) => {
    setPpsrFinancingStatement(newPPSRFinancingStatement);
    assignApplicationPPSRFinancingStatements({
      application,
      isDischarged: false,
      isMultipleRegistrationsSupported,
      newPPSRFinancingStatement,
    });
    onUpdateApplicationState();
  };

  return {
    onSetDischargedPPSRFinancingStatement,
    onSetPPSRFinancingStatement,
    ppsrFinancingStatement,
  };
}

function useNavigationState() {
  const [currentPage, setCurrentPage] = useState('details');
  const onClickBackToDetails = () => setCurrentPage('details');

  return {
    Component: NAVIGATION_PAGES[currentPage],
    onClickBackToDetails,
    setCurrentPage,
  };
}

function SinglePPSRRegister(props) {
  const {
    ppsrFinancingStatement: defaultPpsrFinancingStatement,
    ...otherProps
  } = props;

  const {
    application,
    isMultipleRegistrationsSupported,
    onUpdateApplicationState,
  } = otherProps;

  const {
    onSetDischargedPPSRFinancingStatement,
    onSetPPSRFinancingStatement,
    ppsrFinancingStatement,
  } = usePPSRFinancingStatementState({
    application,
    defaultPpsrFinancingStatement,
    isMultipleRegistrationsSupported,
    onUpdateApplicationState,
  });

  const {
    Component,
    onClickBackToDetails,
    setCurrentPage,
  } = useNavigationState();

  if (
    ppsrFinancingStatement &&
    ppsrFinancingStatement.status === 'registered'
  ) {
    return (
      <Component
        ppsrFinancingStatement={ppsrFinancingStatement}
        onClickBackToDetails={onClickBackToDetails}
        onSetDischargedPPSRFinancingStatement={
          onSetDischargedPPSRFinancingStatement
        }
        onSetPPSRFinancingStatement={onSetPPSRFinancingStatement}
        setCurrentPage={setCurrentPage}
        {...otherProps}
      />
    );
  }

  return (
    <Form
      ppsrFinancingStatement={ppsrFinancingStatement}
      onSetPPSRFinancingStatement={onSetPPSRFinancingStatement}
      {...otherProps}
    />
  );
}

function TabPanel(props) {
  const { children, isVisible } = props;

  if (!isVisible) {
    return null;
  }

  return <div className="mt-3">{children}</div>;
}

function useTabState(initialTab) {
  const [currentTab, setCurrentTab] = useState(initialTab);
  const onChangeTab = (_, newTab) => setCurrentTab(newTab);

  return { currentTab, onChangeTab };
}

function MultiplePPSRRegisters(props) {
  const { application, isMultipleRegistrationsSupported } = props;
  const tabState = useTabState(0);

  const ppsrFinancingStatements = sortByCreatedAt(
    application.ppsrFinancingStatements
  )
    .reverse()
    .filter(
      (ppsrFinancingStatement) => ppsrFinancingStatement.status !== 'discharged'
    );

  const tabs = [];
  const tabPanels = [];

  ppsrFinancingStatements.forEach((ppsrFinancingStatement, index) => {
    let tabLabel = `PPSR ${index + 1}`;

    const { financingStatementKey } = ppsrFinancingStatement;

    if (isPresent(financingStatementKey)) {
      tabLabel += ` - ${financingStatementKey}`;
    } else {
      tabLabel += ' - (draft)';
    }

    tabs.push(
      <Tab
        key={`tab-${ppsrFinancingStatement.id}`}
        value={index}
        label={tabLabel}
        classes={{ root: styles.tab_root }}
      />
    );

    tabPanels.push(
      <TabPanel
        key={`tab-panel-${ppsrFinancingStatement.id}`}
        isVisible={tabState.currentTab === index}
      >
        <SinglePPSRRegister
          isMultipleRegistrationsSupported={isMultipleRegistrationsSupported}
          ppsrFinancingStatement={ppsrFinancingStatement}
          {...props}
        />
      </TabPanel>
    );
  });

  tabs.push(
    <Tab
      key="tab-new-ppsr"
      value={ppsrFinancingStatements.length}
      label="+ Add collateral/registration"
      classes={{ root: styles.tab_root }}
    />
  );

  tabPanels.push(
    <TabPanel
      key="tab-panel-new-ppsr"
      isVisible={tabState.currentTab === ppsrFinancingStatements.length}
    >
      <SinglePPSRRegister
        isMultipleRegistrationsSupported={isMultipleRegistrationsSupported}
        ppsrFinancingStatement={buildNewFinancingStatementRecord(application)}
        {...props}
      />
    </TabPanel>
  );

  return (
    <Fragment>
      <Paper square elevation={0}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={tabState.currentTab}
          onChange={tabState.onChangeTab}
        >
          {tabs}
        </Tabs>
      </Paper>
      {tabPanels}
    </Fragment>
  );
}

function PPSRRegistrationContent(props) {
  const { application } = props;
  const isMultipleRegistrationsSupported = MULTIPLE_REGISTRATION_SUPPORTED_REGION.includes(
    application.region
  );

  if (
    isMultipleRegistrationsSupported &&
    isPresent(application.activePPSRFinancingStatement)
  ) {
    return (
      <MultiplePPSRRegisters
        isMultipleRegistrationsSupported={isMultipleRegistrationsSupported}
        {...props}
      />
    );
  }

  return (
    <SinglePPSRRegister
      isMultipleRegistrationsSupported={isMultipleRegistrationsSupported}
      ppsrFinancingStatement={application.activePPSRFinancingStatement}
      {...props}
    />
  );
}

export default function PPSRRegister(props) {
  const { application } = props;

  if (application.isNewRecord) {
    return null;
  }

  const ppsrFinancingStatementHistories =
    application.ppsrFinancingStatementHistories || [];

  const content = (
    <FixedContent header="PPSR financing statement" withBottomMargin={false}>
      <PPSRRegistrationContent {...props} />
    </FixedContent>
  );

  if (ppsrFinancingStatementHistories.length > 0) {
    return (
      <ContentWithFooter
        footer={<RecordHistory histories={ppsrFinancingStatementHistories} />}
      >
        {content}
      </ContentWithFooter>
    );
  }

  return <ScrollableContent>{content}</ScrollableContent>;
}
