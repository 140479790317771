/* eslint-disable max-len */
import get from 'lodash.get';
import AddonConfigModel from 'models/AddonConfigModel';

import {
  ADD_ONS_ADDITIONAL_CREATE_RULESET_ERROR,
  ADD_ONS_ADDITIONAL_CREATE_RULESET_START,
  ADD_ONS_ADDITIONAL_CREATE_RULESET_SUCCESS,
  ADD_ONS_ADDITIONAL_LOAD_ERROR,
  ADD_ONS_ADDITIONAL_LOAD_START,
  ADD_ONS_ADDITIONAL_LOAD_SUCCESS,
  ADD_ONS_ADDITIONAL_RESET_CURRENT,
  ADD_ONS_ADDITIONAL_SET_CURRENT,
  ADD_ONS_ADDITIONAL_SET_VALUE,
  ADD_ONS_ADDITIONAL_UPDATE_RULESET_ERROR,
  ADD_ONS_ADDITIONAL_UPDATE_RULESET_START,
  ADD_ONS_ADDITIONAL_UPDATE_RULESET_SUCCESS,
  ADD_ONS_ALERT_CREATE_RULESET_ERROR,
  ADD_ONS_ALERT_CREATE_RULESET_START,
  ADD_ONS_ALERT_CREATE_RULESET_SUCCESS,
  ADD_ONS_ALERT_LOAD_ERROR,
  ADD_ONS_ALERT_LOAD_START,
  ADD_ONS_ALERT_LOAD_SUCCESS,
  ADD_ONS_ALERT_RESET_CURRENT,
  ADD_ONS_ALERT_SET_CONFIG_VALUE,
  ADD_ONS_ALERT_SET_CURRENT,
  ADD_ONS_ALERT_SET_VALUE,
  ADD_ONS_ALERT_UPDATE_RULESET_ERROR,
  ADD_ONS_ALERT_UPDATE_RULESET_START,
  ADD_ONS_ALERT_UPDATE_RULESET_SUCCESS,
  ADD_ONS_AUTO_DECISION_LOAD_ERROR,
  ADD_ONS_AUTO_DECISION_LOAD_START,
  ADD_ONS_AUTO_DECISION_LOAD_SUCCESS,
  ADD_ONS_AUTO_DECISION_TOGGLE_ERROR,
  ADD_ONS_AUTO_DECISION_TOGGLE_START,
  ADD_ONS_AUTO_DECISION_TOGGLE_SUCCESS,
  ADD_ONS_CARDS_CREATE_RULESET_ERROR,
  ADD_ONS_CARDS_CREATE_RULESET_START,
  ADD_ONS_CARDS_CREATE_RULESET_SUCCESS,
  ADD_ONS_CARDS_LOAD_ERROR,
  ADD_ONS_CARDS_LOAD_START,
  ADD_ONS_CARDS_LOAD_SUCCESS,
  ADD_ONS_CARDS_RESET_CURRENT,
  ADD_ONS_CARDS_SET_CURRENT,
  ADD_ONS_CARDS_SET_VALUE,
  ADD_ONS_CARDS_UPDATE_RULESET_ERROR,
  ADD_ONS_CARDS_UPDATE_RULESET_START,
  ADD_ONS_CARDS_UPDATE_RULESET_SUCCESS,
  ADD_ONS_CUSTOM_LEAD_CONFIGURATION_ERROR,
  ADD_ONS_CUSTOM_LEAD_CONFIGURATION_START,
  ADD_ONS_CUSTOM_LEAD_CONFIGURATION_SUCCESS,
  ADD_ONS_FINANCIALS_CREATE_RULESET_ERROR,
  ADD_ONS_FINANCIALS_CREATE_RULESET_START,
  ADD_ONS_FINANCIALS_CREATE_RULESET_SUCCESS,
  ADD_ONS_FINANCIALS_LOAD_ERROR,
  ADD_ONS_FINANCIALS_LOAD_START,
  ADD_ONS_FINANCIALS_LOAD_SUCCESS,
  ADD_ONS_FINANCIALS_RESET_CURRENT,
  ADD_ONS_FINANCIALS_SET_ADDITIONAL_FIELDS,
  ADD_ONS_FINANCIALS_SET_CURRENT,
  ADD_ONS_FINANCIALS_SET_ENTITY_TYPE,
  ADD_ONS_FINANCIALS_SET_INDICATOR_FIELDS,
  ADD_ONS_FINANCIALS_SET_MANDATORY_FIELDS,
  ADD_ONS_FINANCIALS_SET_VALUE,
  ADD_ONS_FINANCIALS_UPDATE_RULESET_ERROR,
  ADD_ONS_FINANCIALS_UPDATE_RULESET_START,
  ADD_ONS_FINANCIALS_UPDATE_RULESET_SUCCESS,
  ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_ERROR,
  ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_START,
  ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_SUCCESS,
  ADD_ONS_INTERNAL_USE_FIELDS_LOAD_ERROR,
  ADD_ONS_INTERNAL_USE_FIELDS_LOAD_START,
  ADD_ONS_INTERNAL_USE_FIELDS_LOAD_SUCCESS,
  ADD_ONS_INTERNAL_USE_FIELDS_RESET_CURRENT,
  ADD_ONS_INTERNAL_USE_FIELDS_SET_CONFIG_VALUE,
  ADD_ONS_INTERNAL_USE_FIELDS_SET_CURRENT,
  ADD_ONS_INTERNAL_USE_FIELDS_SET_VALUE,
  ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_ERROR,
  ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_START,
  ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_SUCCESS,
  ADD_ONS_LOAD_ERROR,
  ADD_ONS_LOAD_START,
  ADD_ONS_LOAD_SUCCESS,
  ADD_ONS_PAPERLESS_CREATE_RULESET_ERROR,
  ADD_ONS_PAPERLESS_CREATE_RULESET_START,
  ADD_ONS_PAPERLESS_CREATE_RULESET_SUCCESS,
  ADD_ONS_PAPERLESS_LOAD_ERROR,
  ADD_ONS_PAPERLESS_LOAD_START,
  ADD_ONS_PAPERLESS_LOAD_SUCCESS,
  ADD_ONS_PAPERLESS_RESET_CURRENT,
  ADD_ONS_PAPERLESS_SET_CONFIG_VALUE,
  ADD_ONS_PAPERLESS_SET_CURRENT,
  ADD_ONS_PAPERLESS_SET_VALUE,
  ADD_ONS_PAPERLESS_UPDATE_RULESET_ERROR,
  ADD_ONS_PAPERLESS_UPDATE_RULESET_START,
  ADD_ONS_PAPERLESS_UPDATE_RULESET_SUCCESS,
  ADD_ONS_WEBSITE_BUTTON_UPDATE_RULESET_ERROR,
  ADD_ONS_WEBSITE_BUTTON_UPDATE_RULESET_START,
  ADD_ONS_WEBSITE_BUTTON_UPDATE_RULESET_SUCCESS,
  ADD_ONS_WEBSITE_BUTTON_LOAD_ERROR,
  ADD_ONS_WEBSITE_BUTTON_LOAD_START,
  ADD_ONS_WEBSITE_BUTTON_SET_CURRENT,
  ADD_ONS_WEBSITE_BUTTON_CREATE_RULESET_START,
  ADD_ONS_WEBSITE_BUTTON_CREATE_RULESET_SUCCESS,
  ADD_ONS_WEBSITE_BUTTON_CREATE_RULESET_ERROR,
  ADD_ONS_WEBSITE_BUTTON_RESET_CURRENT,
  ADD_ONS_QR_LOAD_START,
  ADD_ONS_QR_LOAD_SUCCESS,
  ADD_ONS_QR_LOAD_ERROR,
  ADD_ONS_QR_UPDATE_RULESET_START,
  ADD_ONS_QR_UPDATE_RULESET_SUCCESS,
  ADD_ONS_QR_UPDATE_RULESET_ERROR,
  ADD_ONS_QR_CREATE_RULESET_START,
  ADD_ONS_QR_CREATE_RULESET_SUCCESS,
  ADD_ONS_QR_CREATE_RULESET_ERROR,
  ADD_ONS_QR_RESET_CURRENT,
  ADD_ONS_CALL_CENTRE_LOAD_START,
  ADD_ONS_CALL_CENTRE_LOAD_SUCCESS,
  ADD_ONS_CALL_CENTRE_LOAD_ERROR,
  ADD_ONS_CALL_CENTRE_UPDATE_RULESET_START,
  ADD_ONS_CALL_CENTRE_UPDATE_RULESET_SUCCESS,
  ADD_ONS_CALL_CENTRE_UPDATE_RULESET_ERROR,
  ADD_ONS_CALL_CENTRE_CREATE_RULESET_START,
  ADD_ONS_CALL_CENTRE_CREATE_RULESET_SUCCESS,
  ADD_ONS_CALL_CENTRE_CREATE_RULESET_ERROR,
  ADD_ONS_CALL_CENTRE_RESET_CURRENT,
  ADD_ONS_SALES_CHANNEL_STATUS_LOAD_START,
  ADD_ONS_SALES_CHANNEL_STATUS_LOAD_SUCCESS,
  ADD_ONS_SALES_CHANNEL_STATUS_LOAD_ERROR,
  BRANCHES_LOAD_END,
  BRANCHES_LOAD_START,
  BRANCHES_LOAD_SUCCESS,
} from './constants';

const CARDS_DEFAULT_CONFIG = {
  additional_fields: [],
  mandatory: true,
};

const CARDS_DEFAULT_SETTINGS = {
  cardIdToUpdate: null,
  cards: [],
  current_card: {
    attributes: {
      addon_module_name: 'cards',
      config: { ...CARDS_DEFAULT_CONFIG },
      legal_types: [],
      max_credit_value: 0,
      min_credit_value: 0,
      name: '',
    },
    serial_number: null,
  },
  isCardCurrentlyUpdating: false,
  isCardsLoading: false,
};

const ALERT_DEFAULT_SETTINGS = {
  current_alert: {
    attributes: {
      addon_module_name: 'alert_module',
      alert_types: [],
      config: {},
    },
  },
};

const WEBSITE_DEFAULT_SETTINGS = {
  website_button: new AddonConfigModel({
    attributes: { addon_module_name: 'website_button' },
  }),
};

const QR_DEFAULT_SETTINGS = {
  qr_code: new AddonConfigModel({
    attributes: { addon_module_name: 'qr_code' },
  }),
};

const CALL_CENTRE_DEFAULT_SETTINGS = {
  call_centre: new AddonConfigModel({
    attributes: { addon_module_name: 'call_centre' },
  }),
};

const addOnsDefaults = {
  add_ons_errors: null,
  add_ons_loading: false,
  add_ons_types: {
    auto_decisioning: {
      title: 'Auto Decisioning',
    },
  },
  additional: [],
  additional_active_updating: false,
  additional_active_updating_id: null,
  additional_loading: false,
  additional_updating: false,
  auto_decisioning: {
    current_rule_set: null,
  },
  auto_decisions: [],
  auto_decisions_enabling_loading: null,
  auto_decisions_errors: null,
  auto_decisions_loading: false,
  branches: [],
  current_additional: {
    attributes: {
      addon_module_name: 'additional',
      application_types: [],
      config: { components: [] },
      legal_types: [],
      max_credit_value: 0,
      min_credit_value: 0,
      name: null,
    },
    serial_number: null,
  },
  current_financials: {
    attributes: {
      addon_module_name: 'financials',
      config: {
        additional_fields: {
          assets: [
            {
              component_type: 'pair_question',
              field_description_1: 'Address of property ownership',
              field_description_2: 'Property valuation',
              indicator: false,
              mandatory: true,
            },
          ],
          expenses: [],
          income: [],
          liabilities: [
            {
              component_type: 'pair_question',
              field_description_1: 'Name of mortgagee - bank/lender',
              field_description_2: 'Outstanding mortgage owed',
              indicator: false,
              mandatory: true,
            },
          ],
        },
        indicator_fields: [],
        mandatory_fields: [],
      },
      legal_types: [],
      max_credit_value: 0,
      min_credit_value: 0,
      name: null,
    },
    serial_number: null,
  },
  current_internal_use_fields: null,
  current_paperless: {
    attributes: {
      addon_module_name: 'paperless',
      config: {
        mandatory: false,
        terms: '',
      },
      legal_types: [],
      max_credit_value: 0,
      min_credit_value: 0,
      name: null,
    },
    serial_number: null,
  },
  custom_lead_configuration_loading: false,
  custom_lead_configurations: [],
  financials: [],
  financials_active_updating: false,
  financials_active_updating_id: null,
  financials_loading: false,
  financials_updating: false,
  internal_use_fields: [],
  internal_use_fields_active_updating: false,
  internal_use_fields_active_updating_id: null,
  internal_use_fields_loaded: false,
  internal_use_fields_loading: false,
  internal_use_fields_updating: false,
  loading_branches: false,
  paperless: [],
  paperless_active_updating: false,
  paperless_active_updating_id: null,
  paperless_loading: false,
  paperless_updating: false,

  ...CARDS_DEFAULT_SETTINGS,
  ...ALERT_DEFAULT_SETTINGS,
  ...WEBSITE_DEFAULT_SETTINGS,
  ...QR_DEFAULT_SETTINGS,
  ...CALL_CENTRE_DEFAULT_SETTINGS,
};

function setCurrentAlert(data) {
  if (!data || data.length === 0) {
    return addOnsDefaults.current_alert;
  }
  return get(data[0], 'attributes');
}

function setCurrentAddon(data, add_on_name) {
  if (!data || data.length === 0) {
    return addOnsDefaults[add_on_name];
  }
  return data[0];
}

function updateSalesChannelStatusOverview(state, payload) {
  const salesChannelAddons = ['website_button', 'qr_code', 'call_centre'];
  const { entityId, result } = payload;
  const responseData = get(result, 'data.data', []);

  const currentEntityData = responseData.filter(
    (entityData) => entityData.id === entityId
  )[0];

  return salesChannelAddons.reduce((salesChannelAddonData, addonType) => {
    salesChannelAddonData = {
      ...salesChannelAddonData,
      [addonType]: {
        ...state[addonType],
        attributes: {
          ...state[addonType].attributes,
          active: currentEntityData.attributes[`${addonType}_active`],
        },
      },
    };

    return salesChannelAddonData;
  }, {});
}

// eslint-disable-next-line complexity
export function addOnsReducer(state = addOnsDefaults, action) {
  switch (action.type) {
    case ADD_ONS_LOAD_START:
      return { ...state, add_ons_loading: true };
    case ADD_ONS_LOAD_SUCCESS:
      return {
        ...state,
        add_ons_loading: false,
        add_ons_types: action.payload,
      };
    case ADD_ONS_LOAD_ERROR:
      return {
        ...state,
        add_ons_loading: false,
        add_ons_types_erros: action.payload,
      };
    case ADD_ONS_AUTO_DECISION_LOAD_START:
      return { ...state, auto_decisions_loading: true };
    case ADD_ONS_AUTO_DECISION_LOAD_SUCCESS:
      return {
        ...state,
        auto_decisions: action.payload,
        auto_decisions_loading: false,
      };
    case ADD_ONS_AUTO_DECISION_LOAD_ERROR:
      return {
        ...state,
        auto_decisions_errors: action.payload,
        auto_decisions_loading: false,
      };
    case ADD_ONS_AUTO_DECISION_TOGGLE_START:
      return { ...state, auto_decisions_enabling_loading: action.payload };
    case ADD_ONS_AUTO_DECISION_TOGGLE_SUCCESS:
      return {
        ...state,
        auto_decisions: action.payload,
        auto_decisions_enabling_loading: null,
      };
    case ADD_ONS_AUTO_DECISION_TOGGLE_ERROR:
      return {
        ...state,
        auto_decisions_enabling_loading: null,
        auto_decisions_errors: action.payload,
      };
    case BRANCHES_LOAD_START:
      return { ...state, loading_branches: true };
    case BRANCHES_LOAD_SUCCESS:
      return { ...state, branches: action.payload, loading_branches: false };
    case BRANCHES_LOAD_END:
      return { ...state, loading_branches: false };

    //
    // Financials Add-ons reducers
    //
    case ADD_ONS_FINANCIALS_SET_ENTITY_TYPE:
      return {
        ...state,
        current_financials: {
          ...state.current_financials,
          attributes: {
            ...state.current_financials.attributes,
            legal_types: action.payload,
          },
        },
      };
    case ADD_ONS_FINANCIALS_SET_MANDATORY_FIELDS:
      return {
        ...state,
        current_financials: {
          ...state.current_financials,
          attributes: {
            ...state.current_financials.attributes,
            config: {
              ...state.current_financials.attributes.config,
              mandatory_fields: action.payload,
            },
          },
        },
      };
    case ADD_ONS_FINANCIALS_SET_INDICATOR_FIELDS:
      return {
        ...state,
        current_financials: {
          ...state.current_financials,
          attributes: {
            ...state.current_financials.attributes,
            config: {
              ...state.current_financials.attributes.config,
              indicator_fields: action.payload,
            },
          },
        },
      };
    case ADD_ONS_FINANCIALS_SET_VALUE:
      return {
        ...state,
        current_financials: {
          ...state.current_financials,
          attributes: {
            ...state.current_financials.attributes,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case ADD_ONS_FINANCIALS_CREATE_RULESET_START:
      return { ...state, financials_updating: true };
    case ADD_ONS_FINANCIALS_CREATE_RULESET_SUCCESS:
    case ADD_ONS_FINANCIALS_CREATE_RULESET_ERROR:
      return { ...state, financials_updating: false };
    case ADD_ONS_FINANCIALS_RESET_CURRENT:
      return {
        ...state,
        current_financials: addOnsDefaults.current_financials,
      };
    case ADD_ONS_FINANCIALS_LOAD_START:
      return { ...state, financials_loading: true };
    case ADD_ONS_FINANCIALS_LOAD_SUCCESS:
      return {
        ...state,
        financials: action.payload,
        financials_loading: false,
      };
    case ADD_ONS_FINANCIALS_LOAD_ERROR:
      return { ...state, financials_loading: false };
    case ADD_ONS_FINANCIALS_SET_CURRENT:
      return { ...state, current_financials: action.payload };
    case ADD_ONS_FINANCIALS_UPDATE_RULESET_START:
      return {
        ...state,
        financials_active_updating: true,
        financials_active_updating_id: action.payload,
      };
    case ADD_ONS_FINANCIALS_UPDATE_RULESET_SUCCESS:
      return {
        ...state,
        financials: action.payload,
        financials_active_updating: false,
        financials_active_updating_id: null,
      };
    case ADD_ONS_FINANCIALS_UPDATE_RULESET_ERROR:
      return {
        ...state,
        financials_active_updating: false,
        financials_active_updating_id: null,
      };

    //
    // Additional Section Add-ons reducers
    //
    case ADD_ONS_ADDITIONAL_SET_VALUE:
      return {
        ...state,
        current_additional: {
          ...state.current_additional,
          attributes: {
            ...state.current_additional.attributes,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case ADD_ONS_ADDITIONAL_CREATE_RULESET_START:
      return { ...state, additional_updating: true };
    case ADD_ONS_ADDITIONAL_CREATE_RULESET_SUCCESS:
    case ADD_ONS_ADDITIONAL_CREATE_RULESET_ERROR:
      return { ...state, additional_updating: false };
    case ADD_ONS_ADDITIONAL_LOAD_START:
      return { ...state, additional_loading: true };
    case ADD_ONS_ADDITIONAL_LOAD_SUCCESS:
      return {
        ...state,
        additional: action.payload,
        additional_loading: false,
      };
    case ADD_ONS_ADDITIONAL_LOAD_ERROR:
      return { ...state, additional_loading: false };
    case ADD_ONS_ADDITIONAL_SET_CURRENT:
      return { ...state, current_additional: action.payload };
    case ADD_ONS_ADDITIONAL_RESET_CURRENT:
      return {
        ...state,
        current_additional: addOnsDefaults.current_additional,
      };
    case ADD_ONS_ADDITIONAL_UPDATE_RULESET_START:
      return {
        ...state,
        additional_active_updating: true,
        additional_active_updating_id: action.payload,
      };
    case ADD_ONS_ADDITIONAL_UPDATE_RULESET_SUCCESS:
      return {
        ...state,
        additional: action.payload,
        additional_active_updating: false,
        additional_active_updating_id: null,
      };
    case ADD_ONS_ADDITIONAL_UPDATE_RULESET_ERROR:
      return {
        ...state,
        additional_active_updating: false,
        additional_active_updating_id: null,
      };
    case ADD_ONS_FINANCIALS_SET_ADDITIONAL_FIELDS:
      return {
        ...state,
        current_financials: {
          ...state.current_financials,
          attributes: {
            ...state.current_financials.attributes,
            config: {
              ...state.current_financials.attributes.config,
              additional_fields: {
                ...state.current_financials.attributes.config.additional_fields,
                [action.payload.name]: action.payload.value,
              },
            },
          },
        },
      };

    //
    // Cards Add-ons reducers
    //
    case ADD_ONS_CARDS_SET_VALUE:
      return {
        ...state,
        current_card: {
          ...state.current_card,
          attributes: {
            ...state.current_card.attributes,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case ADD_ONS_CARDS_CREATE_RULESET_START:
      return { ...state, cards_updating: true };
    case ADD_ONS_CARDS_CREATE_RULESET_SUCCESS:
    case ADD_ONS_CARDS_CREATE_RULESET_ERROR:
      return { ...state, cards_updating: false };
    case ADD_ONS_CARDS_RESET_CURRENT:
      return {
        ...state,
        current_card: {
          ...CARDS_DEFAULT_SETTINGS.current_card,
          attributes: {
            ...CARDS_DEFAULT_SETTINGS.current_card.attributes,
            config: {
              additional_fields: [],
            },
          },
        },
      };
    case ADD_ONS_CARDS_SET_CURRENT:
      return { ...state, current_card: action.payload };
    case ADD_ONS_CARDS_LOAD_START:
      return { ...state, isCardsLoading: true };
    case ADD_ONS_CARDS_LOAD_SUCCESS:
      return {
        ...state,
        cards: action.payload,
        isCardsLoading: false,
      };
    case ADD_ONS_CARDS_LOAD_ERROR:
      return { ...state, isCardsLoading: false };
    case ADD_ONS_CARDS_UPDATE_RULESET_START:
      return {
        ...state,
        cardIdToUpdate: action.payload,
        isCardCurrentlyUpdating: true,
      };
    case ADD_ONS_CARDS_UPDATE_RULESET_SUCCESS:
      return {
        ...state,
        cardIdToUpdate: null,
        cards: action.payload,
        isCardCurrentlyUpdating: false,
      };
    case ADD_ONS_CARDS_UPDATE_RULESET_ERROR:
      return {
        ...state,
        cardIdToUpdate: null,
        isCardCurrentlyUpdating: false,
      };

    //
    // Internal Use Fields Add-ons reducers
    //
    case ADD_ONS_INTERNAL_USE_FIELDS_LOAD_START:
      return { ...state, internal_use_fields_loading: true };
    case ADD_ONS_INTERNAL_USE_FIELDS_LOAD_SUCCESS:
      return {
        ...state,
        internal_use_fields: action.payload,
        internal_use_fields_loaded: true,
        internal_use_fields_loading: false,
      };
    case ADD_ONS_INTERNAL_USE_FIELDS_LOAD_ERROR:
      return { ...state, internal_use_fields_loading: false };
    case ADD_ONS_INTERNAL_USE_FIELDS_RESET_CURRENT:
      return {
        ...state,
        current_internal_use_fields: addOnsDefaults.current_internal_use_fields,
      };
    case ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_START:
      return { ...state, internal_use_fields_updating: true };
    case ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_SUCCESS:
    case ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_ERROR:
      return { ...state, internal_use_fields_updating: false };
    case ADD_ONS_INTERNAL_USE_FIELDS_SET_VALUE:
      return {
        ...state,
        current_internal_use_fields: {
          ...state.current_internal_use_fields,
          attributes: {
            ...state.current_internal_use_fields.attributes,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case ADD_ONS_INTERNAL_USE_FIELDS_SET_CONFIG_VALUE:
      return {
        ...state,
        current_internal_use_fields: {
          ...state.current_internal_use_fields,
          attributes: {
            ...state.current_internal_use_fields.attributes,
            config: {
              ...state.current_internal_use_fields.attributes.config,
              [action.payload.name]: action.payload.value,
            },
          },
        },
      };
    case ADD_ONS_INTERNAL_USE_FIELDS_SET_CURRENT:
      return { ...state, current_internal_use_fields: action.payload };
    case ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_START:
      return {
        ...state,
        internal_use_fields_active_updating: true,
        internal_use_fields_active_updating_id: action.payload,
      };
    case ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_SUCCESS:
      return {
        ...state,
        internal_use_fields: action.payload,
        internal_use_fields_active_updating: false,
        internal_use_fields_active_updating_id: null,
      };
    case ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_ERROR:
      return {
        ...state,
        internal_use_fields_active_updating: false,
        internal_use_fields_active_updating_id: null,
      };

    // Custom lead configuration
    case ADD_ONS_CUSTOM_LEAD_CONFIGURATION_START:
      return { ...state, custom_lead_configuration_loading: true };
    case ADD_ONS_CUSTOM_LEAD_CONFIGURATION_SUCCESS:
      return {
        ...state,
        custom_lead_configuration_loading: false,
        custom_lead_configurations: action.payload,
      };
    case ADD_ONS_CUSTOM_LEAD_CONFIGURATION_ERROR:
      return { ...state, custom_lead_configuration_loading: false };

    //
    // Paperless Add-ons reducers
    //
    case ADD_ONS_PAPERLESS_LOAD_START:
      return { ...state, paperless_loading: true };
    case ADD_ONS_PAPERLESS_LOAD_SUCCESS:
      return {
        ...state,
        paperless: action.payload,
        paperless_loading: false,
      };
    case ADD_ONS_PAPERLESS_LOAD_ERROR:
      return { ...state, paperless_loading: false };
    case ADD_ONS_PAPERLESS_RESET_CURRENT:
      return {
        ...state,
        current_paperless: addOnsDefaults.current_paperless,
      };
    case ADD_ONS_PAPERLESS_CREATE_RULESET_START:
      return { ...state, paperless_updating: true };
    case ADD_ONS_PAPERLESS_CREATE_RULESET_SUCCESS:
    case ADD_ONS_PAPERLESS_CREATE_RULESET_ERROR:
      return { ...state, paperless_updating: false };
    case ADD_ONS_PAPERLESS_SET_VALUE:
      return {
        ...state,
        current_paperless: {
          ...state.current_paperless,
          attributes: {
            ...state.current_paperless.attributes,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case ADD_ONS_PAPERLESS_SET_CONFIG_VALUE:
      return {
        ...state,
        current_paperless: {
          ...state.current_paperless,
          attributes: {
            ...state.current_paperless.attributes,
            config: {
              ...state.current_paperless.attributes.config,
              [action.payload.name]: action.payload.value,
            },
          },
        },
      };
    case ADD_ONS_PAPERLESS_SET_CURRENT:
      return { ...state, current_paperless: action.payload };
    case ADD_ONS_PAPERLESS_UPDATE_RULESET_START:
      return {
        ...state,
        paperless_active_updating: true,
        paperless_active_updating_id: action.payload,
      };
    case ADD_ONS_PAPERLESS_UPDATE_RULESET_SUCCESS:
      return {
        ...state,
        paperless: action.payload,
        paperless_active_updating: false,
        paperless_active_updating_id: null,
      };
    case ADD_ONS_PAPERLESS_UPDATE_RULESET_ERROR:
      return {
        ...state,
        paperless_active_updating: false,
        paperless_active_updating_id: null,
      };

    //
    // Alerts module Add-ons reducers
    //
    case ADD_ONS_ALERT_LOAD_START:
      return { ...state, alert_loading: true };
    case ADD_ONS_ALERT_LOAD_SUCCESS:
      return {
        ...state,
        alert: action.payload,
        alert_loading: false,
      };
    case ADD_ONS_ALERT_LOAD_ERROR:
      return { ...state, alert_loading: false };
    case ADD_ONS_ALERT_RESET_CURRENT:
      return {
        ...state,
        current_alert: addOnsDefaults.current_alert,
      };
    case ADD_ONS_ALERT_CREATE_RULESET_START:
      return { ...state, alert_updating: true };
    case ADD_ONS_ALERT_CREATE_RULESET_SUCCESS:
    case ADD_ONS_ALERT_CREATE_RULESET_ERROR:
      return { ...state, alert_updating: false };
    case ADD_ONS_ALERT_SET_VALUE:
      return {
        ...state,
        current_alert: {
          ...state.current_alert,
          attributes: {
            ...state.current_alert.attributes,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case ADD_ONS_ALERT_SET_CONFIG_VALUE:
      return {
        ...state,
        current_alert: {
          ...state.current_alert,
          attributes: {
            ...state.current_alert.attributes,
            config: {
              ...state.current_alert.attributes.config,
              [action.payload.name]: action.payload.value,
            },
          },
        },
      };
    case ADD_ONS_ALERT_SET_CURRENT:
      return { ...state, current_alert: setCurrentAlert(action.payload) };

    //
    // Website module Add-ons reducers
    //
    case ADD_ONS_WEBSITE_BUTTON_CREATE_RULESET_START:
      return { ...state, website_button_updating: true };
    case ADD_ONS_WEBSITE_BUTTON_CREATE_RULESET_SUCCESS:
    case ADD_ONS_WEBSITE_BUTTON_CREATE_RULESET_ERROR:
      return { ...state, website_button_updating: false };
    case ADD_ONS_WEBSITE_BUTTON_LOAD_START:
      return { ...state, website_button_updating: true };
    case ADD_ONS_WEBSITE_BUTTON_SET_CURRENT:
      return {
        ...state,
        website_button: setCurrentAddon(action.payload, 'website_button'),
        website_button_updating: false,
      };
    case ADD_ONS_WEBSITE_BUTTON_LOAD_ERROR:
      return { ...state, website_updating: false };
    case ADD_ONS_WEBSITE_BUTTON_UPDATE_RULESET_START:
      return {
        ...state,
        website_button_updating: true,
        website_button_updating_id: action.payload,
      };
    case ADD_ONS_WEBSITE_BUTTON_UPDATE_RULESET_SUCCESS:
      return {
        ...state,
        website_button: action.payload,
        website_button_updating: false,
        website_button_updating_id: null,
      };
    case ADD_ONS_WEBSITE_BUTTON_UPDATE_RULESET_ERROR:
      return {
        ...state,
        website_button_updating: false,
        website_button_updating_id: null,
      };
    case ADD_ONS_WEBSITE_BUTTON_RESET_CURRENT:
      return state;

    //
    // QR module Add-ons reducers
    //
    case ADD_ONS_QR_CREATE_RULESET_START:
      return { ...state, qr_code_updating: true };
    case ADD_ONS_QR_CREATE_RULESET_SUCCESS:
    case ADD_ONS_QR_CREATE_RULESET_ERROR:
      return { ...state, qr_code_updating: false };
    case ADD_ONS_QR_UPDATE_RULESET_START:
      return {
        ...state,
        qr_code_updating: true,
        qr_code_updating_id: action.payload,
      };
    case ADD_ONS_QR_UPDATE_RULESET_SUCCESS:
      return {
        ...state,
        qr_code: action.payload,
        qr_code_updating: false,
        qr_code_updating_id: null,
      };
    case ADD_ONS_QR_UPDATE_RULESET_ERROR:
      return {
        ...state,
        qr_code_updating: false,
        qr_code_updating_id: null,
      };
    case ADD_ONS_QR_LOAD_START:
      return { ...state, qr_code_loading: true };
    case ADD_ONS_QR_LOAD_SUCCESS:
      return {
        ...state,
        qr_code: setCurrentAddon(action.payload, 'qr_code'),
        qr_code_loading: false,
      };
    case ADD_ONS_QR_LOAD_ERROR:
      return { ...state, qr_code_loading: false };
    case ADD_ONS_QR_RESET_CURRENT:
      return state;

    //
    // Call Centre module Add-ons reducers
    //
    case ADD_ONS_CALL_CENTRE_CREATE_RULESET_START:
      return { ...state, call_centre_updating: true };
    case ADD_ONS_CALL_CENTRE_CREATE_RULESET_SUCCESS:
    case ADD_ONS_CALL_CENTRE_CREATE_RULESET_ERROR:
      return { ...state, call_centre_updating: false };
    case ADD_ONS_CALL_CENTRE_UPDATE_RULESET_START:
      return {
        ...state,
        call_centre_updating: true,
        call_centre_updating_id: action.payload,
      };
    case ADD_ONS_CALL_CENTRE_UPDATE_RULESET_SUCCESS:
      return {
        ...state,
        call_centre: action.payload,
        call_centre_updating: false,
        call_centre_updating_id: null,
      };
    case ADD_ONS_CALL_CENTRE_UPDATE_RULESET_ERROR:
      return {
        ...state,
        call_centre_updating: false,
        call_centre_updating_id: null,
      };
    case ADD_ONS_CALL_CENTRE_LOAD_START:
      return { ...state, call_centre_loading: true };
    case ADD_ONS_CALL_CENTRE_LOAD_SUCCESS:
      return {
        ...state,
        call_centre: setCurrentAddon(action.payload, 'call_centre'),
        call_centre_loading: false,
      };
    case ADD_ONS_CALL_CENTRE_LOAD_ERROR:
      return { ...state, call_centre_loading: false };
    case ADD_ONS_CALL_CENTRE_RESET_CURRENT:
      return state;

    //Sales Channel status overview

    case ADD_ONS_SALES_CHANNEL_STATUS_LOAD_START:
      return { ...state, sales_channel_status_loading: true };
    case ADD_ONS_SALES_CHANNEL_STATUS_LOAD_SUCCESS:
      return {
        ...state,
        ...updateSalesChannelStatusOverview(state, action.payload),
        sales_channel_status_loading: false,
      };
    case ADD_ONS_SALES_CHANNEL_STATUS_LOAD_ERROR:
      return { ...state, sales_channel_status_loading: false };

    case ADD_ONS_ALERT_UPDATE_RULESET_START:
      return {
        ...state,
        alert_active_updating: true,
        alert_active_updating_id: action.payload,
      };
    case ADD_ONS_ALERT_UPDATE_RULESET_SUCCESS:
      return {
        ...state,
        alert: action.payload,
        alert_active_updating: false,
        alert_active_updating_id: null,
      };
    case ADD_ONS_ALERT_UPDATE_RULESET_ERROR:
      return {
        ...state,
        alert_active_updating: false,
        alert_active_updating_id: null,
      };
    default:
      return { ...state };
  }
}
