export const TOOLTIP_TEXT = {
  applicationInfo:
    'A quick summary of this application. Tagged approvers and review dates ' +
    'will also populate here if initiated.',
  indentificationCheck:
    'Digital signatures of the applicant, signatories (joint, partners, trustees), ' +
    'guarantors, and/or cardholders. You can track progress, change the email address, ' +
    'resend authorisation emails and pass/fail. Digital signatures are also imprinted ' +
    'onto the final PDF document if approved.',
  limitsNApprovals:
    'Before you can approve/decline, an approver needs to be tagged. You can tag ' +
    'yourself or another person from the same level. The full history of approvers ' +
    'is shown at the bottom of this screen, and you can filter search by approvers ' +
    'in your sales pipeline.',
  pendingStatus:
    'Set the application status to what you are waiting on, you can select multiple. ' +
    "You can filter by 'Pending status' via your sales pipeline.  *Don’t forget to " +
    'turn it off once complete.',
  reviewDate:
    'Set a date for reviewing purposes - this can be for any reason you deem appropriate. ' +
    "This date will populate in the 'Application info' section. You can also search " +
    'review dates in your filter search in your sales pipeline.',
};
