import React from 'react';
import { formatMoney } from 'utils/formatting';
import styles from './css/NumApplicationsBlock.css';

function withTheme(Component) {
  return function(props) {
    let themes = [styles.NumApplicationsBlock];

    switch (props.color) {
      case 'medium-grey':
        themes.push(styles.MediumGrey);
        break;
      case 'grey':
        themes.push(styles.Grey);
        break;
      case 'blue':
        themes.push(styles.Blue);
        break;
      case 'light-blue':
        themes.push(styles.LightBlue);
        break;
      case 'red':
        themes.push(styles.Red);
        break;
      case 'white':
        themes.push(styles.White);
        break;
      case 'green':
        themes.push(styles.Green);
        break;
      default:
        themes.push(styles.Black);
        break;
    }

    if (props.active) {
      themes.push(styles.Active);
    }

    if (props.currencyMode) {
      themes.push(styles.noInput);
    }

    if (props.isCompact) {
      themes.push(styles.compact);
    }

    return <Component {...props} themes={themes} />;
  };
}

function NumApplicationsBlock(props) {
  const { currencyMode, value, onClick, title, themes } = props;

  return (
    <div className={themes.join(' ')} onClick={onClick || null}>
      <div className={styles.Title}>{title}</div>
      <div className={styles.Value}>
        {currencyMode && value
          ? '$' + formatMoney(value, 0, 3, ',', '.')
          : value}
        {currencyMode && <span>&nbsp;</span>}
      </div>
    </div>
  );
}

NumApplicationsBlock.defaultProperty = {
  isCompact: false,
};

export default withTheme(NumApplicationsBlock);
