import ScrollableContent from 'modules/shared/components/containers/ScrollableContent';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import Table from 'modules/shared/components/table/Table';
import api from 'api';
import get from 'lodash.get';

const DATA_COLUMNS = [
  {
    Header: 'Activity Started',
    accessor: 'created_at',
  },
  {
    Header: 'User Name',
    accessor: 'real_user_full_name',
  },
  {
    Header: 'Email',
    accessor: 'real_user_email',
  },
];

type CurrentUser = {
  accessToken: string;
  currentEntity: {
    id: string;
  };
};

type Application = {
  id: string;
};

type Props = {
  application: Application;
  currentUser: CurrentUser;
};

export default function CallCentreLog(props: Props): ReactElement | null {
  const { application, currentUser } = props;
  const accessToken = get(currentUser, 'accessToken');
  const entityId = get(currentUser, 'currentEntity.id');
  const applicationId = get(application, 'id');

  if (!applicationId) {
    return null;
  }

  const [callCentreActivities, setCallCentreActivities] = useState([]);

  useEffect(() => {
    api('call_centre_activities', accessToken, entityId)
      .get(applicationId)
      .then((response) => {
        const responseData = get(response, 'data.data', []);
        const transformedData = responseData.map((item) => {
          return {
            ...item.attributes,
            created_at: new Date(item.attributes.created_at).toLocaleString(),
          };
        });
        setCallCentreActivities(transformedData);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [applicationId, currentUser, entityId]);

  return (
    <ScrollableContent>
      <Table
        columns={DATA_COLUMNS}
        data={callCentreActivities}
        dataLabel="activities"
        withPagination={false}
      />
    </ScrollableContent>
  );
}
