import styled from 'styled-components';

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0px;
`;

export const Header = styled.div`
  margin: 5px 0px 5px 0px;
`;

export const ImageContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: center;
`;

export const Logo = styled.div`
  display: inline-block;
  max-height: 70px;
  max-width: 100px;
  vertical-align: middle;
`;

export const LogoImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const HamburgerButton = styled.button`
  height: 50px;
  width: 50px;
  margin-right: 20px;
  justify-content: center;
  flex-direction: column;
  background-color: grey;
  border-color: grey;

  &.active-button {
    background-color: var(--main-color-active) !important;
    border-color: var(--main-color-active) !important;
    color: var(--main-font-color) !important;
  }
`;
