import React from 'react';
import { Skeleton } from '@material-ui/lab';
import arrayFromNumber from 'modules/shared/helpers/arrayFromNumber';

function TableListSkeleton() {
  return arrayFromNumber(10, (number) => (
    <Skeleton
      key={`reporting-list-skeleton-${number}`}
      animation="wave"
      className="mb-4"
      height={25}
      variant="rect"
    />
  ));
}

export default TableListSkeleton;
