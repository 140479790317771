const USER_TYPES = {
  admin: {
    description:
      'This user/s has overall responsibility for the 1account view and can view all Branches that sit under it. This user sets the account rules which also apply at a Branch level, although an individual Branch can tailor rules to suit their needs.',
    label: 'Admin',
  },
  approver: {
    description:
      'This user can send and approve applications, as well as view all activity in the 1account view. This user cannot add additional Branches.',
    label: 'Admin',
  },
  consumer_manager: {
    description:
      'This user can apply for credit/cash applications on behalf of the business.',
    label: 'Consumer',
  },
  standard: {
    description:
      'This user can send and view applications generated themselves. They do not have visibility of all Branches under the 1account view.',
    label: 'Standard',
  },
};

export default USER_TYPES;
