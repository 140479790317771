import api from '../api';
import { calculateMonthlyCost } from './subscriptionsCalculate';
import { formatMoney } from 'utils/formatting';
import get from 'lodash.get';

export function setChurnZeroAttributes(entity_id, getState) {
  // TODO: Enable this when ChurnZero AppKey is sorted out.
  // Temporarily disable ChurnZero
  return;

  if (typeof getState === 'function') {
    getState = getState();
  }

  if (!entity_id) {
    entity_id = getState.current_user.current_entity.id;
  }

  // block 1centre domain
  if (
    getState.current_user.data.data.attributes.email.indexOf('@1centre.com') >
    -1
  ) {
    return;
  }

  let applications = api(
    'entity_consumer_applications',
    getState.current_user.access_token,
    entity_id,
    { headers: { Accept: 'application/json' } }
  );
  let data = {};

  applications.getEntityConsumerApplications(
    (result) => {
      if (result.data.data.length) {
        data = result.data.data[0].attributes;

        let monthly_amount = monthlyCost(data);

        ChurnZero.push(['setAppKey', process.env.CHURNZERO_KEY]); // AppKey from ChurnZero
        ChurnZero.push([
          'setContact',
          entity_id,
          getState.current_user.data.data.attributes.email,
        ]);

        ChurnZero.push([
          'setAttribute',
          'account',
          {
            total_apps_overall: data.total_apps_overall || 0,
            total_apps_month: data.total_apps_month || 0,
            total_apps_week: data.total_apps_week || 0,
            total_apps_day: data.total_apps_day || 0,
            deleted_apps: data.deleted_apps || 0,
            incomplete_apps: data.incomplete_apps || 0,
            pending_apps: data.pending_apps || 0,
            accepted_apps: data.accepted_apps || 0,
            declined_apps: data.declined_apps || 0,
            archived_apps: data.archived_apps || 0,
            total_limit_overall: `$${Number(
              data.total_limit_approved_overall || 0
            )
              .toLocaleString()
              .toString()}`,
            total_limit_month: `$${Number(data.total_limit_approved_month || 0)
              .toLocaleString()
              .toString()}`,
            total_limit_week: `$${Number(data.total_limit_approved_week || 0)
              .toLocaleString()
              .toString()}`,
            total_limit_day: `$${Number(data.total_limit_approved_day || 0)
              .toLocaleString()
              .toString()}`,
            conversion_rate: `${(
              ((data.submitted_apps || 0) / (data.total_apps_overall || 0)) *
              100
            )
              .toFixed(2)
              .toString()}%`,
            NextRenewalDate: data.plan_anniversary_date,
            TotalContractAmount: calculateMonthlyCost(
              data.plan_no,
              data.number_of_users,
              data.per_user_charge
            ),
            LicenseCount: data.number_of_users || 1,
            plan: data.plan_name || '',
            number_of_users: data.number_of_users || 1,
            plan_anniversary_date: data.plan_anniversary_date,
            monthly_amount: monthly_amount,
            Name: data.trading_name || '',
            StartDate: data.created_at,
            trading_name: data.trading_name || '',
            contact_phone_number: `${data.contact_country_code} ${data.contact_phone_number}`,
            created_at: data.created_at,
            logo:
              data.supplier_logo && data.supplier_logo.logo
                ? data.supplier_logo.logo.url || ''
                : '',
            staff_count: data.staff_count || '',
            key_contact_last_name: data.key_contact_last_name || '',
            key_contact_first_name: data.key_contact_first_name || '',
            key_contact_email: data.key_contact_email || '',
            key_contact_phone_number: `${data.key_contact_country_code} ${data.key_contact_phone_number}`,
            company_name: data.company_name || '',
            physical_address: data.full_physical_address || '',
            state: data.state || '',
            region: data.region || '',
            website_button: data.website_button || false,
            invoice_day: data.invoice_day || '',
            invoice_due: data.invoice_due || '',
            minimum_approvers: data.minimum_approvers || 1,
            trade_account_limit: `$${Number(data.trade_account_limit || 0)
              .toLocaleString()
              .toString()}`,
            requires_credit_check: data.requires_credit_check || false,
            requires_identity_check: data.requires_identity_check || false,
            requires_guarantees: data.requires_guarantees || false,
            requires_trade_reference_check:
              data.requires_trade_reference_check || false,
            minimum_guarantees: data.minimum_guarantees || 0,
            minimum_trade_references: data.minimum_trade_references || 0,
            excluded_application_sources: data.excluded_application_sources
              ? data.excluded_application_sources.toString()
              : '',
            subscription_state: data.subscription_state,
            supplier: !!data.is_supplier,
            legal_type: data.legal_type || '',
            profile_updated_at: data.profile_updated_at,
            team_updated_at: data.team_updated_at,
            payments_updated_at: data.payments_updated_at,
            terms_updated_at: data.terms_updated_at,
            approval_hierarchy_version: data.approval_hierarchy_version,
            'Company Credit Checks New Zealand':
              data.nz_rule_selection_name || '',
            'Company Credit Checks Australia':
              data.au_rule_selection_name || '',
            'Personal Credit Checks New Zealand':
              data.nz_personal_rule_selection_name || '',
            'Personal Credit Checks Australia':
              data.au_personal_rule_selection_name || '',
          },
        ]);

        ChurnZero.push([
          'setAttribute',
          'contact',
          {
            FirstName: data.first_name || '',
            LastName: data.last_name || '',
            Email: data.email || '',
            user_email: data.email || '',
            user_ph_no: `${data.user_contact_country_code} ${data.user_contact_phone_number}`,
            created_at: data.user_created_at,
            roles: data.role_types,
            mandatory_checks: data.permissions ? data.permissions == 1 : false,
            receive_email_notifications:
              data.receive_email_notifications || false,
          },
        ]);
      }
    },
    (error) => {}
  );
}

export function setChurnZeroAttributesWithoutRedux(currentUser) {
  // TODO: Enable this when ChurnZero AppKey is sorted out.
  // Temporarily disable ChurnZero
  return;

  const entityId = currentUser.currentEntity.id;

  // block 1centre domain
  if (currentUser.email.indexOf('@1centre.com') > -1) {
    return;
  }

  const applications = api(
    'entity_consumer_applications',
    currentUser.accessToken,
    entityId,
    { headers: { Accept: 'application/json' } }
  );
  let data = {};

  applications.getEntityConsumerApplications(
    (result) => {
      if (result.data.data.length) {
        data = result.data.data[0].attributes;

        let monthly_amount = monthlyCost(data);

        ChurnZero.push(['setAppKey', process.env.CHURNZERO_KEY]); // AppKey from ChurnZero
        ChurnZero.push(['setContact', entityId, currentUser.email]);

        ChurnZero.push([
          'setAttribute',
          'account',
          {
            total_apps_overall: data.total_apps_overall || 0,
            total_apps_month: data.total_apps_month || 0,
            total_apps_week: data.total_apps_week || 0,
            total_apps_day: data.total_apps_day || 0,
            deleted_apps: data.deleted_apps || 0,
            incomplete_apps: data.incomplete_apps || 0,
            pending_apps: data.pending_apps || 0,
            accepted_apps: data.accepted_apps || 0,
            declined_apps: data.declined_apps || 0,
            archived_apps: data.archived_apps || 0,
            total_limit_overall: `$${Number(
              data.total_limit_approved_overall || 0
            )
              .toLocaleString()
              .toString()}`,
            total_limit_month: `$${Number(data.total_limit_approved_month || 0)
              .toLocaleString()
              .toString()}`,
            total_limit_week: `$${Number(data.total_limit_approved_week || 0)
              .toLocaleString()
              .toString()}`,
            total_limit_day: `$${Number(data.total_limit_approved_day || 0)
              .toLocaleString()
              .toString()}`,
            conversion_rate: `${(
              ((data.submitted_apps || 0) / (data.total_apps_overall || 0)) *
              100
            )
              .toFixed(2)
              .toString()}%`,
            NextRenewalDate: data.plan_anniversary_date,
            TotalContractAmount: calculateMonthlyCost(
              data.plan_no,
              data.number_of_users,
              data.per_user_charge
            ),
            LicenseCount: data.number_of_users || 1,
            plan: data.plan_name || '',
            number_of_users: data.number_of_users || 1,
            plan_anniversary_date: data.plan_anniversary_date,
            monthly_amount: monthly_amount,
            Name: data.trading_name || '',
            StartDate: data.created_at,
            trading_name: data.trading_name || '',
            contact_phone_number: `${data.contact_country_code} ${data.contact_phone_number}`,
            created_at: data.created_at,
            logo:
              data.supplier_logo && data.supplier_logo.logo
                ? data.supplier_logo.logo.url || ''
                : '',
            staff_count: data.staff_count || '',
            key_contact_last_name: data.key_contact_last_name || '',
            key_contact_first_name: data.key_contact_first_name || '',
            key_contact_email: data.key_contact_email || '',
            key_contact_phone_number: `${data.key_contact_country_code} ${data.key_contact_phone_number}`,
            company_name: data.company_name || '',
            physical_address: data.full_physical_address || '',
            state: data.state || '',
            region: data.region || '',
            website_button: data.website_button || false,
            invoice_day: data.invoice_day || '',
            invoice_due: data.invoice_due || '',
            minimum_approvers: data.minimum_approvers || 1,
            trade_account_limit: `$${Number(data.trade_account_limit || 0)
              .toLocaleString()
              .toString()}`,
            requires_credit_check: data.requires_credit_check || false,
            requires_identity_check: data.requires_identity_check || false,
            requires_guarantees: data.requires_guarantees || false,
            requires_trade_reference_check:
              data.requires_trade_reference_check || false,
            minimum_guarantees: data.minimum_guarantees || 0,
            minimum_trade_references: data.minimum_trade_references || 0,
            excluded_application_sources: data.excluded_application_sources
              ? data.excluded_application_sources.toString()
              : '',
            subscription_state: data.subscription_state,
            supplier: !!data.is_supplier,
            legal_type: data.legal_type || '',
            profile_updated_at: data.profile_updated_at,
            team_updated_at: data.team_updated_at,
            payments_updated_at: data.payments_updated_at,
            terms_updated_at: data.terms_updated_at,
            approval_hierarchy_version: data.approval_hierarchy_version,
            'Company Credit Checks New Zealand':
              data.nz_rule_selection_name || '',
            'Company Credit Checks Australia':
              data.au_rule_selection_name || '',
            'Personal Credit Checks New Zealand':
              data.nz_personal_rule_selection_name || '',
            'Personal Credit Checks Australia':
              data.au_personal_rule_selection_name || '',
          },
        ]);

        ChurnZero.push([
          'setAttribute',
          'contact',
          {
            FirstName: data.first_name || '',
            LastName: data.last_name || '',
            Email: data.email || '',
            user_email: data.email || '',
            user_ph_no: `${data.user_contact_country_code} ${data.user_contact_phone_number}`,
            created_at: data.user_created_at,
            roles: data.role_types,
            mandatory_checks: data.permissions ? data.permissions == 1 : false,
            receive_email_notifications:
              data.receive_email_notifications || false,
          },
        ]);
      }
    },
    (error) => {}
  );
}

export function setChurnZeroLogout() {
  //ChurnZero.push(['trackEvent', 'Logout']);
  ChurnZero.push(['stop']);
}

function monthlyCost(currentPlan) {
  let perUserCharge = currentPlan.per_user_charge;

  if (perUserCharge === null || perUserCharge === undefined) return '';

  let cost = calculateMonthlyCost(
    currentPlan.plan_no,
    currentPlan.number_of_users,
    currentPlan.per_user_charge
  );

  if (cost === null || cost === undefined) return '-';

  let formattedCost = `$${formatMoney(cost, 2)}`;
  if (cost === 0) return formattedCost;

  return `${formattedCost} +GST`;
}
