import axios from './axios';

export default class Authorisations {
  constructor(config) {
    config.timeout = 5 * 60 * 1000;

    this.axios = axios(config);
    this.type = 'authorisations';
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      'v1',
      'v2'
    );
    this.path = `/${this.type}`;
  }

  updateAuthorisation(id, attributes) {
    return this.axios.patch(`${this.path}/${id}`, {
      data: { attributes, type: this.type },
    });
  }

  getAuthorisation(id) {
    return this.axios.get(`${this.path}/${id}`);
  }

  getSuperAdminAuthorisationLink(id) {
    return this.axios.get(`${this.path}/${id}/super_admin_authorisation_link`);
  }

  uploadIdentificationImage(id, file) {
    return this.axios.patch(`${this.path}/${id}/upload_identification_image`, {
      file,
    });
  }

  uploadProofOfAddress(id, file) {
    return this.axios.patch(`${this.path}/${id}/upload_proof_of_address`, {
      file,
    });
  }

  updateEmailAndResend(id, email) {
    return this.axios.patch(`${this.path}/${id}/update_email_and_resend`, {
      email,
    });
  }

  updateInProgressState(id) {
    return this.axios.patch(`${this.path}/${id}/in_progress`);
  }

  updateFilename(id, params) {
    return this.axios.patch(`${this.path}/${id}/update_filename`, params);
  }
}
