import React from 'react';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import get from 'lodash.get';
import { InputAdornment } from '@material-ui/core';
import formatMoney from 'utils/formatMoney';

const DIGIT_REGEX = /\D/g;

interface IValue {
  maxCreditValue: number | string | undefined;
  minCreditValue: number | string | undefined;
}

type TFieldNames = 'max_credit_value' | 'min_credit_value';

const LimitRange = ({
  errors,
  handleLimitChange,
  readOnly,
  tradeAccountLimit,
  values,
}: {
  errors: { credit_limit?: string };
  handleLimitChange: (key: TFieldNames, value: string) => void;
  readOnly: boolean;
  tradeAccountLimit?: number;
  values: IValue;
}): JSX.Element => {
  const onChange = (key: TFieldNames, value: string) => {
    const sanitizedValue = value.replace(DIGIT_REGEX, '') || '0';
    handleLimitChange(key, sanitizedValue);
  };

  return (
    <div className="columns">
      <div className="column is-3">
        <BorderedTextField
          placeholder="min"
          customProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          value={formatMoney(values.minCreditValue)}
          onChange={(e) => onChange('min_credit_value', e.target.value)}
          disabled={readOnly || !tradeAccountLimit}
        />
      </div>
      <div className="column is-1 has-text-centered">
        <span style={{ display: 'inline-block', paddingTop: '1.5rem' }}>
          &mdash;
        </span>
      </div>
      <div className="column is-3">
        <BorderedTextField
          placeholder="max"
          customProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          value={formatMoney(values.maxCreditValue)}
          onChange={(e) => onChange('max_credit_value', e.target.value)}
          error={!!errors.credit_limit}
          helperText={get(errors, 'credit_limit', '')}
          disabled={readOnly || !tradeAccountLimit}
        />
      </div>
    </div>
  );
};

export default LimitRange;
