import Logo from 'images/svgs/external_link.svg';
import React, { ReactElement } from 'react';

import { Description, DrawerPanel, Header } from '../styles';
import { IconHolder, SupplierLink } from './styles';

export const TermsPanel = ({
  supplierTerms,
}: {
  supplierTerms: string;
}): ReactElement => {
  const onClick = () => {
    const win = window.open('', 'pdf_window');
    if (win && supplierTerms) {
      win.open(supplierTerms, 'pdf_window');
    }
  };

  return (
    <DrawerPanel>
      <Header>Terms &amp; Conditions</Header>
      <Description>
        {' '}
        T&amp;C&apos;s are downloadable if required, click{' '}
        <SupplierLink onClick={onClick}> here</SupplierLink> to access in new
        tab
        <IconHolder>
          <Logo height="20px" width="20px" onClick={onClick} />
        </IconHolder>
      </Description>
    </DrawerPanel>
  );
};
