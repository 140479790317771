import {
  setGuarantorFormItem,
  toggleGuarantorIsApplicant,
} from '../../actions/guarantors';
import { EMAIL_REGEX } from 'constants';
import Guarantor from './Guarantor';
import Radiobox from 'modules/shared/components/inputs/Radiobox';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import isBlank from 'utils/isBlank';
import styles from './css/Guarantors.css';
import getIsThisYouLabel from 'utils/getIsThisYouLabel';
import PageHeader from 'modules/shared/components/v2/PageHeader';
import SignatorySwitchModal from './SignatorySwitchModal';
import {
  displayDataLostWarning,
  getAddonRules,
  getAffectedPages,
  setAffectedPages,
} from 'modules/consumer-onboarding/actions/section';
import { cobApplication } from 'modules/consumer-onboarding/helpers';

class GuarantorsConfirm extends React.Component {
  constructor(props) {
    super(props);

    const { minimum_guarantees } = props;
    const form_errors = [];

    minimum_guarantees;
    for (var i = 0; i < minimum_guarantees; i++) {
      form_errors.push({ name: '', email: false, isApplicant: '' });
    }

    this.state = {
      form_errors,
      personalAccountGuarantorError: '',
      modal: {
        display: false,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { handleComplete } = this.props;
    const isComplete = this.isComplete(nextProps.form_values);
    handleComplete(isComplete);

    if (nextProps.page_validation_start) {
      this.checkValidAll();
    }
  }

  checkValidAll = () => {
    const {
      dropdown_values,
      form_values,
      minimum_guarantees,
      setPageValidationStartFinish,
    } = this.props;
    let { form_errors } = this.state;
    let errors = form_errors;

    for (var i = 0; i < minimum_guarantees; i++) {
      this.refs[`guarantor-${i}`].refs.email._onBlur();
      const formValue = form_values[i];
      const noGuarantorSelected =
        formValue &&
        !dropdown_values.some((x) => x.value === `${formValue.name}`);

      if (formValue === undefined || noGuarantorSelected) {
        errors[i] = { ...errors[i], name: 'Please select the guarantor.' };
      }
      if (formValue && isBlank(formValue.is_applicant)) {
        errors[i] = {
          ...errors[i],
          isApplicant: 'Please select yes or no if this is you.',
        };
      } else {
        errors[i] = { ...errors[i], isApplicant: '' };
      }
    }

    this.setState({ form_errors: errors });

    setPageValidationStartFinish();
  };

  isComplete = (values) => {
    const { dropdown_values, entity_type, minimum_guarantees } = this.props;
    const { form_errors } = this.state;
    const re = EMAIL_REGEX;

    let applicantIsGuarantor = false;
    let personalAccountGuarantorError = '';

    let form_values = values;
    if (!form_values) {
      form_values = this.props.form_values;
    }

    if (form_values.length < minimum_guarantees) {
      return false;
    }

    for (let error of form_errors) {
      if (error.hasOwnProperty('email') && error.email) {
        return false;
      }
    }

    for (let i = 0; i < minimum_guarantees; i++) {
      const value = form_values[i];

      if (!re.test(value.email)) {
        return false;
      }

      if (isBlank(value.is_applicant)) {
        return false;
      }

      const name = value.name;
      if (!dropdown_values.some((x) => x.value === name)) {
        return false;
      }

      if (!applicantIsGuarantor && value.is_applicant) {
        applicantIsGuarantor = true;
      }
    }

    if (entity_type === 'personal') {
      if (!applicantIsGuarantor && minimum_guarantees > 1) {
        personalAccountGuarantorError =
          'Please select your name as a guarantor for this application';
      }

      this.setState({ ...this.state, personalAccountGuarantorError });

      if (personalAccountGuarantorError.length > 0) {
        return false;
      }
    }

    return true;
  };

  _onChangeNameSelection = (
    index,
    value,
    percentage_share,
    position,
    first_name,
    last_name,
    middle_name
  ) => {
    const { dispatch, guarantorList } = this.props;
    const { form_errors } = this.state;
    let errors = form_errors;

    errors[index] = { ...errors[index], name: '' };
    this.setState({
      form_errors: errors,
    });

    dispatch(setGuarantorFormItem(index, 'name', value));
    dispatch(setGuarantorFormItem(index, 'first_name', first_name));
    dispatch(setGuarantorFormItem(index, 'last_name', last_name));
    dispatch(setGuarantorFormItem(index, 'middle_name', middle_name));
    dispatch(setGuarantorFormItem(index, 'percentage_share', percentage_share));
    dispatch(setGuarantorFormItem(index, 'position', position));

    guarantorList.forEach((person) => {
      const name = person.name;
      if (name === value && person.email) {
        dispatch(setGuarantorFormItem(index, 'email', person.email));
      }
    });

    setTimeout(() => {
      this.refs[`guarantor-${index}`].refs.name._onBlur();
    }, 300);
  };

  _clearNonApplicantGuarantorsEmail = () => {
    const { dispatch, form_values } = this.props;

    const previousApplicantIndex = form_values.findIndex(
      ({ is_applicant }) => is_applicant
    );

    if (previousApplicantIndex === -1) {
      return;
    }

    dispatch(setGuarantorFormItem(previousApplicantIndex, 'email', ''));
  };

  _onChangeEmail = (index, value, isValid) => {
    const { dispatch } = this.props;
    const { form_errors } = this.state;

    let errors = form_errors;

    dispatch(setGuarantorFormItem(index, 'email', value));

    if (isValid) {
      const { email, ...rest } = form_errors[index];
      errors[index] = { ...rest };
    } else {
      errors[index] = { ...errors[index], email: true };
    }

    this.setState({
      form_errors: errors,
    });
  };

  componentDidMount() {
    const {
      cobMoney,
      current_selected_values,
      dispatch,
      form_values,
      handleComplete,
    } = this.props;

    dispatch(getAddonRules());

    if (current_selected_values.length == 0) {
      form_values.forEach((item, index) => {
        dispatch(setGuarantorFormItem(index, 'name', item.name));
        dispatch(setGuarantorFormItem(index, 'first_name', item.first_name));
        dispatch(setGuarantorFormItem(index, 'last_name', item.last_name));
        dispatch(setGuarantorFormItem(index, 'middle_name', item.middle_name));
        dispatch(
          setGuarantorFormItem(index, 'percentage_share', item.percentage_share)
        );
        dispatch(setGuarantorFormItem(index, 'position', item.position));

        if (item.email) {
          dispatch(setGuarantorFormItem(index, 'email', item.email));
        }
      });
    }

    handleComplete(this.isComplete());
  }

  dispatchToggleIsApplicant = (index, value, isAffecting = false) => {
    const { application, dispatch, form_values } = this.props;
    const affectedPages = dispatch(getAffectedPages());

    const boolean_converter = {
      Yes: true,
      No: false,
    };
    const { form_errors } = this.state;
    form_errors[index].isApplicant = '';
    this.setState({ form_errors });

    const guarantor = form_values[index];
    const consumerEmail = application.consumerContactEmail;
    const isConsumerEmail = guarantor['email'] === consumerEmail;

    if (value === 'Yes') {
      this._clearNonApplicantGuarantorsEmail();

      if (!isConsumerEmail) {
        dispatch(setGuarantorFormItem(index, 'email', consumerEmail));
        delete form_errors[index].email;

        this.setState({ form_errors });
      }
    }

    if (value === 'No' && isConsumerEmail) {
      dispatch(setGuarantorFormItem(index, 'email', ''));
    }

    dispatch(
      toggleGuarantorIsApplicant(index, boolean_converter[value], guarantor)
    );

    if (isAffecting && affectedPages.length > 0) {
      dispatch(setAffectedPages(affectedPages));
    }
  };

  toggleIsApplicant = (index, event) => {
    const { dispatch } = this.props;
    const value = event.target.value;

    const willAffectPages = dispatch(displayDataLostWarning());
    if (willAffectPages) {
      return this.setState({
        modal: {
          display: true,
          index,
          value,
        },
      });
    }

    this.dispatchToggleIsApplicant(index, value);
  };

  isApplicantText = (isApplicant) => {
    if (isBlank(isApplicant)) {
      return '';
    }

    return isApplicant ? 'Yes' : 'No';
  };

  render() {
    const {
      application,
      dispatch,
      dropdown_values,
      form_values,
      minimum_guarantees,
      title,
    } = this.props;
    const { form_errors, modal } = this.state;

    const guarantors = [];
    const addonRuleKeys = dispatch(getAffectedPages());

    let title_name = title;

    if (minimum_guarantees > 1) {
      title_name = `${title_name}s`;
    }

    for (var i = 0; i < minimum_guarantees; i++) {
      const selected = [];
      const formValue = form_values[i];

      let sort_dropdown = dropdown_values;
      let current_name = '';
      let current_email = '';
      let current_percentage_share, current_position, is_applicant;
      let current_first_name = '';
      let current_last_name = '';
      let current_middle_name = '';

      if (formValue) {
        current_name = formValue.name;
        current_email = formValue.email;
        current_percentage_share = formValue.percentage_share;
        current_position = formValue.position;
        is_applicant = formValue.is_applicant;
        current_first_name = formValue.first_name;
        current_last_name = formValue.last_name;
        current_middle_name = formValue.middle_name;
      }

      form_values.forEach((item, _index) => {
        let name = item.name;
        if (name !== current_name) {
          selected.push({
            id: 'name',
            label: name,
            value: name,
          });
        }
      });

      sort_dropdown = sort_dropdown.filter(
        (x) => !selected.some((y) => y.value === x.value)
      );

      const isThisYouLabel = getIsThisYouLabel({
        firstName: current_first_name,
        lastName: current_last_name,
      });

      guarantors.push(
        <div key={i}>
          <div className={styles.guarantor_container}>
            <Guarantor
              key={i}
              index={i}
              ref={`guarantor-${i}`}
              error={form_errors[i]}
              name={current_name}
              email={current_email}
              sort_dropdown={sort_dropdown}
              onChangeNameSelection={this._onChangeNameSelection}
              onChangeEmail={this._onChangeEmail}
              percentage_share={current_percentage_share}
              position={current_position}
              first_name={current_first_name}
              last_name={current_last_name}
              middle_name={current_middle_name}
              isApplicant={is_applicant}
              isApplicationSubmitted={application.isSubmitted}
            />
          </div>
          <div className={styles.is_me_container}>
            <div className={styles.right_col}>
              <Radiobox
                id={`g-radio-${i}`}
                name={`g-radio-${i}`}
                error={form_errors[i].isApplicant}
                handleChange={this.toggleIsApplicant.bind(this, i)}
                label={isThisYouLabel}
                value={this.isApplicantText(is_applicant)}
                radioList={['Yes', 'No']}
                disabled={application.isSubmitted}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <Fragment>
        <section className={styles.section}>
          <PageHeader title={title_name}>
            <p>
              <span className={styles.emphasize}>
                Completing the details does not mean acceptance of guarantee.{' '}
              </span>
              An email will be sent to your nominated guarantors to accept or
              decline.
            </p>
          </PageHeader>
          <div className={styles.guarantors_list}>{guarantors}</div>
        </section>
        {modal.display && (
          <SignatorySwitchModal
            affectedPages={addonRuleKeys}
            closeModal={() => this.setState({ modal: { display: false } })}
            handleSubmit={() =>
              this.dispatchToggleIsApplicant(modal.index, modal.value, true)
            }
          />
        )}
      </Fragment>
    );
  }
}

const defaults = {
  title: 'Nominate Guarantor',
};

export default connect((state, ownProps) => {
  const component_index = ownProps.component_index || 0;
  const dropdown_values = [];
  let form_values = [];
  let minimum_guarantees = 0;
  const people = state.cob_section.people;

  let companyDetails = state.cob_business.company_details || {},
    shareHolders = companyDetails.shareholders || [],
    peopleWithoutShareholidng = [];

  people.forEach((p) => {
    const index = shareHolders.findIndex((s) => s.name === p.name);

    if (index === -1) {
      peopleWithoutShareholidng.push({
        ...p,
        position: p.position || p.relationship || p.legal_type,
      });
    }
  });

  let guarantorList =
    state.cob_business.entity_type == 'company' &&
    state.cob_business.entity_region === 'NZ'
      ? peopleWithoutShareholidng.concat(shareHolders)
      : peopleWithoutShareholidng;

  if (state.cob_section.application) {
    minimum_guarantees =
      state.cob_section.application.attributes.minimum_guarantees;
  }

  if (guarantorList.length < minimum_guarantees) {
    minimum_guarantees = guarantorList.length;
  }

  if (guarantorList.length > 0) {
    guarantorList.forEach((person, index) => {
      const name = person.name;
      const first_name = person.first_name;
      const last_name = person.last_name;
      const middle_name = person.middle_name;
      let label = name;
      let percentage_share = null;
      let position = null;

      if (state.cob_business.entity_type == 'company') {
        if (state.cob_business.entity_region === 'NZ') {
          position = person.position ? person.position : 'Director';
          percentage_share = person.percentage_share
            ? person.percentage_share
            : null;
          label =
            label +
            ' (' +
            position +
            (percentage_share ? ', ' + percentage_share + '%' : '') +
            ')';
        } else {
          label = `${label} (Director)`;
        }
      }

      dropdown_values.push({
        first_name,
        id: 'name',
        label,
        last_name,
        middle_name,
        percentage_share,
        position,
        value: name,
      });
    });
  }

  if (state.cob_guarantors.form_values.length > 0) {
    form_values = state.cob_guarantors.form_values;
  } else {
    for (var i = 0; i < minimum_guarantees; i++) {
      form_values.push(guarantorList[i]);
    }
  }

  return {
    application: cobApplication(),
    complete: state.cob_guarantors.completed['confirm'],
    component_index,
    current_selected_values: state.cob_guarantors.form_values,
    dropdown_values,
    entity_type: state.cob_business.entity_type,
    form_values,
    guarantorList,
    minimum_guarantees,
    people,
    title: defaults.title,
    addonRules: state.cob_section.addonRules,
  };
})(GuarantorsConfirm);
