import { MAXIMUM_RETRY_ATTEMPTS } from 'models/IdentificationCheckModel.js';
import React, { ReactElement } from 'react';

const ErrorDetails = ({
  retryAttempts,
}: {
  retryAttempts: number;
}): ReactElement => {
  let message = '';

  if (retryAttempts >= MAXIMUM_RETRY_ATTEMPTS) {
    message =
      'Due to technical issues we have been unsuccessful in verifying this ID via the appropriate agency.';
  } else {
    message = "We'll be trying again 3x over the next 24hours.";
  }

  return <div>{message}</div>;
};

export default ErrorDetails;
