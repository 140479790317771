/* eslint-disable max-lines */
import get from 'lodash.get';
import { COLLATERAL_PROPERTY_TYPE_OPTIONS } from 'models/PpsrFinancingStatementModel';
import setCollateralTypeDetails from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRForm/utils/setCollateralTypeDetails';
import {
  AddDebtor,
  getAvailableCollateralTypeOptions,
  getSelectedDebtorTypeOption,
  OrganisationNameForm,
  PersonDateOfBirthForm,
  useSelectedCollateralTypeState,
} from 'modules/new-applications/components/application-actions/PPSRRegister/shared/FormComponents';
import {
  COLLATERAL_PROCEEDS_TO_BE_CLAIMED_MAX_CHARACTERS,
  COLLATERAL_TYPE_WITHOUT_DESCRIPTION,
  getSevenYearsFromNow,
  getTwentyFiveYearsFromNow,
} from 'modules/new-applications/components/application-actions/PPSRRegister/validationSchema/AUValidationSchema';
import styles from 'modules/new-applications/css/PPSRRegister.css';
import GridContent from 'modules/shared/components/containers/GridContent';
import BorderedAutocomplete from 'modules/shared/components/inputs/BorderedAutocomplete';
import BorderedCalendarPicker from 'modules/shared/components/inputs/BorderedCalendarPicker';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import BorderedTextArea from 'modules/shared/components/inputs/BorderedTextArea';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import FormCheckBox from 'modules/shared/components/inputs/FormCheckBox';
import LabeledInputContent from 'modules/shared/components/widgets/static/LabeledInputContent';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';

const DEBTOR_TYPE_OPTIONS = [
  { label: 'Organisation', value: 'organisation' },
  { label: 'Individual', value: 'person' },
];

export const DURATION_OPTIONS = [
  {
    allowedCollateralPropertyType: ['Commercial', 'Consumer'],
    label: '7 years',
    presetKey: 'register_zero_to_seven_years',
    value: 'seven_years',
  },
  {
    allowedCollateralPropertyType: ['Commercial'],
    label: '25 years',
    presetKey: 'register_eight_to_twenty_five',
    value: 'twenty_five_years',
  },
  {
    allowedCollateralPropertyType: ['Commercial'],
    label: 'No stated end time registration',
    presetKey: 'register_no_end_time',
    value: 'indefinite',
  },
  {
    allowedCollateralPropertyType: ['Commercial', 'Consumer'],
    label: 'Custom date',
    presetKey: 'custom',
    value: 'custom',
  },
];

const AVAILABLE_COLLATERAL_TYPES_BY_CONSUMER_PROPERTY_TYPE = [
  'Agriculture',
  'AllPapNoExcept',
  'AllPapWithExcept',
  'Currency',
  'IntermediatedSecurity',
  'InvestmentInstrument',
  'NegotiableInstrument',
  'OtherGoods',
];

const COLLATERAL_ADDITIONAL_DETAILS_BY_TYPE = {
  Agriculture: [
    'inventory',
    'purchaseMoney',
    'subjectToControl',
    'subordinate',
    'toBeClaimed',
  ],
  AllPapNoExcept: ['subordinate'],
  AllPapWithExcept: ['subordinate', 'toBeClaimed'],
  Crops: [
    'inventory',
    'purchaseMoney',
    'subjectToControl',
    'subordinate',
    'toBeClaimed',
  ],
  Currency: [
    'inventory',
    'purchaseMoney',
    'subjectToControl',
    'subordinate',
    'toBeClaimed',
  ],
  IntermediatedSecurity: [
    'inventory',
    'subjectToControl',
    'subordinate',
    'toBeClaimed',
  ],
  InvestmentInstrument: [
    'inventory',
    'subjectToControl',
    'subordinate',
    'toBeClaimed',
  ],
  Livestock: [
    'inventory',
    'purchaseMoney',
    'subjectToControl',
    'subordinate',
    'toBeClaimed',
  ],
  NegotiableInstrument: ['subjectToControl', 'subordinate', 'toBeClaimed'],
  OtherGoods: [
    'inventory',
    'purchaseMoney',
    'subjectToControl',
    'subordinate',
    'toBeClaimed',
  ],
};

const COLLATERAL_ADDITIONAL_DETAILS_BY_CONSUMER_PROPERTY_TYPE = {
  Agriculture: ['subordinate', 'toBeClaimed'],
  AllPapNoExcept: ['subordinate'],
  AllPapWithExcept: ['subordinate', 'toBeClaimed'],
  Currency: ['subordinate', 'toBeClaimed'],
  IntermediatedSecurity: ['subordinate', 'toBeClaimed'],
  InvestmentInstrument: ['subordinate', 'toBeClaimed'],
  NegotiableInstrument: ['subordinate', 'toBeClaimed'],
  OtherGoods: ['subordinate', 'toBeClaimed'],
};

export function ReferenceForm(props) {
  const { errors, register } = props;

  return (
    <Fragment>
      <div className={styles.sub_header}>Giving of notice identifier</div>
      <div className="mb-3">
        This is the reference number or text which is used to identify this
        registration in your secured party. It can have up to 50 characters
        including letters, number and special characters.
      </div>
      <GridContent>
        <BorderedTextField
          label="Reference"
          placeholder="Reference"
          inputRef={register}
          name="registrationReference"
          error={Boolean(errors.registrationReference)}
          helperText={get(errors, 'registrationReference.message', ' ')}
        />
      </GridContent>
    </Fragment>
  );
}

const getMaxExpiryDateAllowed = (collateralPropertyType) => {
  if (collateralPropertyType === 'Consumer') {
    return getSevenYearsFromNow();
  }

  return getTwentyFiveYearsFromNow();
};

function ExpiryDateForm(props) {
  const {
    errors,
    expiryDateMaxDate,
    isVisible,
    onSelectDate,
    register,
    selectedDate,
  } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <GridContent>
      <LabeledInputContent label="Expiry date">
        <BorderedCalendarPicker
          textFieldProps={{
            error: Boolean(errors.expiryDate),
            helperText: get(errors, 'expiryDate.message', ' '),
            id: 'expiry-date-datepicker',
            inputRef: register,
            name: 'expiryDate',
          }}
          minDate={new Date()}
          maxDate={expiryDateMaxDate}
          value={selectedDate}
          onChange={onSelectDate}
        />
      </LabeledInputContent>
    </GridContent>
  );
}

function useDurationState(props) {
  const { clearError, expiryDuration, setIsAutoRenewVisible, setValue } = props;

  const [selectedDuration, setSelectedDuration] = useState(expiryDuration);

  const onSelectDuration = (event) => {
    const value = event.target.value;

    setValue('expiryDuration', value);
    setSelectedDuration(value);
    clearError('expiryDuration');

    if (setIsAutoRenewVisible) {
      setIsAutoRenewVisible(value !== 'indefinite');
    }

    if (value === 'indefinite') {
      setValue('autoRenew', false);
    }
  };

  return { onSelectDuration, selectedDuration };
}

export function DurationForm(props) {
  const {
    availableDurationOptions,
    clearError,
    collateralPropertyType,
    errors,
    isAutoRenew,
    ppsrFinancingStatement,
    register,
    selectedDate,
    setIsAutoRenewVisible,
    setValue,
    onSelectDate,
  } = props;

  const { onSelectDuration, selectedDuration } = useDurationState({
    clearError,
    expiryDuration: ppsrFinancingStatement.expiryDuration,
    setIsAutoRenewVisible,
    setValue,
  });

  useEffect(() => {
    if (isAutoRenew && selectedDuration === 'custom') {
      onSelectDuration({ target: { value: '' } });
      setValue('expiryDuration', '');
      setValue('expiryDate', null);
    }
  }, [isAutoRenew]);

  const isCalendarVisible = selectedDuration === 'custom';
  const expiryDateMaxDate = getMaxExpiryDateAllowed(collateralPropertyType);

  return (
    <Fragment>
      <GridContent>
        <BorderedSelect
          label="Duration"
          value={selectedDuration}
          options={availableDurationOptions}
          error={Boolean(errors.expiryDuration)}
          helperText={get(errors, 'expiryDuration.message', ' ')}
          onChange={onSelectDuration}
        />
      </GridContent>
      <ExpiryDateForm
        errors={errors}
        expiryDateMaxDate={expiryDateMaxDate}
        isVisible={isCalendarVisible}
        ppsrFinancingStatement={ppsrFinancingStatement}
        register={register}
        selectedDate={selectedDate}
        onSelectDate={onSelectDate}
      />
    </Fragment>
  );
}

export function getAvailableDurationOptions({
  allowIndefiniteDuration = true,
  collateralPropertyType,
  config,
  isAutoRenew,
}) {
  const { functions: functionPresets } = config;

  // Custom duration is not available with auto renew
  functionPresets.custom = !isAutoRenew;

  const selectedFunctionKeys = Object.keys(functionPresets).filter(
    (key) => functionPresets[key]
  );

  return DURATION_OPTIONS.filter((option) =>
    isDurationOptionAvailable({
      allowIndefiniteDuration,
      collateralPropertyType,
      option,
      selectedFunctionKeys,
    })
  );
}

function isDurationOptionAvailable({
  allowIndefiniteDuration,
  collateralPropertyType,
  option,
  selectedFunctionKeys,
}) {
  if (!allowIndefiniteDuration && option.value === 'indefinite') {
    return false;
  }

  return (
    selectedFunctionKeys.includes(option.presetKey) &&
    option.allowedCollateralPropertyType.includes(collateralPropertyType)
  );
}

function AutoRenewForm(props) {
  const {
    control,
    errors,
    isVisible,
    ppsrFinancingStatement,
    register,
  } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <GridContent>
      <div className={styles.checkbox_container}>
        <FormCheckBox
          id="autoRenew"
          label="Auto renewal"
          control={control}
          value={true}
          register={register}
          defaultValue={ppsrFinancingStatement.autoRenew || false}
          error={get(errors, 'autoRenew.message', '')}
          isCompact={true}
        />
      </div>
    </GridContent>
  );
}

function FinancingStatementForm(props) {
  const {
    clearError,
    control,
    errors,
    ppsrAddonConfig,
    ppsrFinancingStatement,
    register,
    collateralPropertyType,
    setValue,
    watch,
  } = props;

  const [isAutoRenewVisible, setIsAutoRenewVisible] = useState(
    ppsrFinancingStatement.expiryDuration !== 'indefinite'
  );

  let initialExpiryDate = null;

  if (ppsrFinancingStatement.expiryDate) {
    initialExpiryDate = moment(ppsrFinancingStatement.expiryDate).format(
      'DD/MM/YYYY'
    );
  }
  const [selectedDate, setSelectedDate] = useState(initialExpiryDate);
  const onSelectDate = (date) => {
    setSelectedDate(date);
    setValue('expiryDate', date);
  };

  const isAutoRenew = watch(
    'autoRenew',
    ppsrFinancingStatement.autoRenew || false
  );

  const availableDurationOptions = getAvailableDurationOptions({
    collateralPropertyType,
    config: ppsrAddonConfig,
    isAutoRenew,
  });

  return (
    <Fragment>
      <div className={styles.sub_header}>Duration of registration</div>
      <DurationForm
        availableDurationOptions={availableDurationOptions}
        clearError={clearError}
        collateralPropertyType={collateralPropertyType}
        errors={errors}
        isAutoRenew={isAutoRenew}
        ppsrFinancingStatement={ppsrFinancingStatement}
        register={register}
        selectedDate={selectedDate}
        setIsAutoRenewVisible={setIsAutoRenewVisible}
        setValue={setValue}
        onSelectDate={onSelectDate}
      />
      <AutoRenewForm
        control={control}
        errors={errors}
        isVisible={isAutoRenewVisible}
        ppsrFinancingStatement={ppsrFinancingStatement}
        register={register}
      />
    </Fragment>
  );
}

function getIsCollateralAdditionalDetailVisible({
  additionalDetails,
  collateralPropertyType,
  collateralType,
}) {
  const availableOptions = COLLATERAL_ADDITIONAL_DETAILS_BY_TYPE[
    collateralType
  ].includes(additionalDetails);

  if (collateralPropertyType === 'Commercial') {
    return availableOptions;
  }

  return COLLATERAL_ADDITIONAL_DETAILS_BY_CONSUMER_PROPERTY_TYPE[
    collateralType
  ].includes(additionalDetails);
}

function CollateralAdditionalDetails(props) {
  const {
    additionalDetailName,
    collateralFieldName,
    control,
    errors,
    field,
    isDisabled,
    isVisible,
    label,
    register,
    value,
  } = props;

  if (!isVisible) {
    return null;
  }

  const fieldName = `${collateralFieldName}.${additionalDetailName}`;

  return (
    <div className={styles.checkbox_container}>
      <FormCheckBox
        control={control}
        defaultValue={field[fieldName] || false}
        disabled={isDisabled}
        error={get(errors, `${fieldName}.message`, ' ')}
        id={fieldName}
        isCompact={true}
        label={label}
        register={register()}
        value={value}
      />
    </div>
  );
}

CollateralAdditionalDetails.defaultProps = {
  isDisabled: false,
  isVisible: true,
};

function getProceedsToBeClaimedHelperText({
  description,
  error,
  isNumberOfCharactersVisible,
}) {
  if (error) {
    return error.message;
  }

  if (isNumberOfCharactersVisible) {
    const remainingCharacters =
      COLLATERAL_PROCEEDS_TO_BE_CLAIMED_MAX_CHARACTERS - description.length;

    return `${remainingCharacters} characters left`;
  }

  return ' ';
}

function useProceedsToBeClaimedDescription(props) {
  const { error, clearError, fieldName, initialValue, setValue } = props;

  const [description, setDescription] = useState(initialValue);

  const onChangeDescription = (value) => {
    setDescription(value);
    setValue(fieldName, value);

    if (error) {
      clearError(fieldName);
    }
  };

  return {
    description,
    descriptionLength: description.length,
    onChangeDescription,
  };
}

function CollateralProceedsToBeClaimedDescription(props) {
  const {
    error,
    helperText,
    isVisible,
    onChangeProceedsDescription,
    register,
    textAreaFieldName,
  } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <BorderedTextArea
      label=""
      placeholder=""
      inputRef={register()}
      name={textAreaFieldName}
      error={Boolean(error)}
      helperText={helperText}
      onChange={onChangeProceedsDescription}
    />
  );
}

function CollateralProceedsToBeClaimed(props) {
  const {
    additionalDetailName,
    clearError,
    collateralFieldName,
    control,
    errors,
    field,
    isVisible,
    register,
    setValue,
    value,
    watch,
  } = props;

  if (!isVisible) {
    return null;
  }

  const checkboxFieldName = `${collateralFieldName}.${additionalDetailName}`;
  const isProceedsToBeClaimed = watch(
    checkboxFieldName,
    field[checkboxFieldName] || false
  );

  const error = get(
    errors,
    `additionalDetails.${additionalDetailName}Description`
  );

  const textAreaFieldName = `${checkboxFieldName}Description`;
  const {
    description,
    onChangeDescription,
  } = useProceedsToBeClaimedDescription({
    clearError,
    error,
    fieldName: textAreaFieldName,
    initialValue: field[textAreaFieldName] || '',
    setValue,
  });

  useEffect(() => {
    let defaultDescription = '';

    if (isProceedsToBeClaimed) {
      defaultDescription = 'All present and after acquired property.';
    }

    onChangeDescription(defaultDescription);
  }, [isProceedsToBeClaimed]);

  const helperText = getProceedsToBeClaimedHelperText({
    description,
    error,
    isNumberOfCharactersVisible: isProceedsToBeClaimed,
  });

  const onChangeProceedsDescription = (event) => {
    onChangeDescription(event.target.value);
  };

  return (
    <Fragment>
      <CollateralAdditionalDetails
        additionalDetailName={additionalDetailName}
        collateralFieldName={collateralFieldName}
        control={control}
        errors={errors}
        field={field}
        isVisible={isVisible}
        label="Proceeds to be claimed"
        register={register}
        value={value}
      />
      <CollateralProceedsToBeClaimedDescription
        error={error}
        helperText={helperText}
        isVisible={isProceedsToBeClaimed && isVisible}
        onChangeProceedsDescription={onChangeProceedsDescription}
        register={register}
        textAreaFieldName={textAreaFieldName}
      />
    </Fragment>
  );
}

function CollateralIsInventory(props) {
  const {
    collateralFieldName,
    control,
    errors,
    field,
    isDisabled,
    isVisible,
    register,
    watch,
  } = props;

  if (!isVisible) {
    return null;
  }

  const inventoryFieldName = 'inventory';
  const subjectToControlFieldName = 'subjectToControl';
  const inventoryCheckboxFieldName = `${collateralFieldName}.${inventoryFieldName}`;
  const isInventorySelected = watch(
    inventoryCheckboxFieldName,
    field[inventoryCheckboxFieldName] || false
  );

  return (
    <Fragment>
      <CollateralAdditionalDetails
        additionalDetailName={inventoryFieldName}
        collateralFieldName={collateralFieldName}
        control={control}
        errors={errors}
        field={field}
        isDisabled={isDisabled}
        isVisible={isVisible}
        label="The collateral is inventory"
        register={register}
        value={true}
      />
      <CollateralAdditionalDetails
        additionalDetailName={subjectToControlFieldName}
        collateralFieldName={collateralFieldName}
        control={control}
        errors={errors}
        field={field}
        isDisabled={isDisabled || !isInventorySelected}
        isVisible={isVisible}
        label="Current assets are subject to control"
        register={register}
        value={true}
      />
    </Fragment>
  );
}

export function useSelectedCollateralPropertyTypeState(defaultValue) {
  const [
    selectedCollateralPropertyType,
    setSelectedCollateralPropertyType,
  ] = useState(defaultValue);

  const selectedCollateralPropertyTypeOption =
    COLLATERAL_PROPERTY_TYPE_OPTIONS.find(
      (option) => option.value === selectedCollateralPropertyType
    ) || {};

  return {
    selectedCollateralPropertyType,
    selectedCollateralPropertyTypeOption,
    setSelectedCollateralPropertyType,
  };
}

function CollateralDescription(props) {
  const {
    collateralDescription,
    collateralDescriptionFieldName,
    errors,
    isVisible,
    onBlur,
    onChange,
    register,
  } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <GridContent>
      <BorderedTextField
        label="Description"
        placeholder="Description"
        inputRef={register()}
        value={collateralDescription}
        onChange={onChange}
        onBlur={onBlur}
        name={collateralDescriptionFieldName}
        error={Boolean(errors.collateralDescription)}
        helperText={get(errors, 'collateralDescription.message', ' ')}
      />
    </GridContent>
  );
}

export function useCollateralDescriptionVisibilityState(props) {
  const { collateralType } = props;
  const [isVisible, setIsVisible] = useState(
    !COLLATERAL_TYPE_WITHOUT_DESCRIPTION.includes(collateralType)
  );

  const onToggleVisibility = (newCollateralType) => {
    setIsVisible(
      !COLLATERAL_TYPE_WITHOUT_DESCRIPTION.includes(newCollateralType)
    );
  };

  return { isVisible, onToggleVisibility };
}

function useCollateralAditionalDetailsVisibilityState(props) {
  const { collateralType } = props;
  const [isVisible, setIsVisible] = useState(isPresent(collateralType));

  const onToggleVisibility = (newCollateralType) => {
    setIsVisible(isPresent(newCollateralType));
  };

  return { isVisible, onToggleVisibility };
}

function CollateralAdditionalDetailsForm(props) {
  const {
    clearError,
    collateralFieldName,
    collateralPropertyType,
    collateralType,
    control,
    errors,
    field,
    isCollateralInventoryEnabled,
    isCollateralPurchaseMoneyEnabled,
    isVisible,
    register,
    setValue,
    watch,
  } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <GridContent>
      <div>
        <div className={styles.sub_header}>Additional details</div>
        <CollateralAdditionalDetails
          additionalDetailName="purchaseMoney"
          collateralFieldName={collateralFieldName}
          collateralType={collateralType}
          control={control}
          errors={errors}
          field={field}
          isDisabled={!isCollateralPurchaseMoneyEnabled}
          isVisible={getIsCollateralAdditionalDetailVisible({
            additionalDetails: 'purchaseMoney',
            collateralPropertyType,
            collateralType,
          })}
          label="Purchase money security interest applies"
          register={register}
          value={true}
        />
        <CollateralIsInventory
          collateralFieldName={collateralFieldName}
          collateralType={collateralType}
          control={control}
          errors={errors}
          field={field}
          isDisabled={!isCollateralInventoryEnabled}
          isVisible={
            getIsCollateralAdditionalDetailVisible({
              additionalDetails: 'inventory',
              collateralPropertyType,
              collateralType,
            }) &&
            getIsCollateralAdditionalDetailVisible({
              additionalDetails: 'subjectToControl',
              collateralPropertyType,
              collateralType,
            })
          }
          register={register}
          watch={watch}
        />
        <CollateralAdditionalDetails
          additionalDetailName="subjectToControl"
          collateralFieldName={collateralFieldName}
          control={control}
          errors={errors}
          field={field}
          isVisible={
            !getIsCollateralAdditionalDetailVisible({
              additionalDetails: 'inventory',
              collateralPropertyType,
              collateralType,
            }) &&
            getIsCollateralAdditionalDetailVisible({
              additionalDetails: 'subjectToControl',
              collateralPropertyType,
              collateralType,
            })
          }
          label="Current assets are subject to control"
          register={register}
          value={true}
        />
        <CollateralAdditionalDetails
          additionalDetailName="subordinate"
          collateralFieldName={collateralFieldName}
          collateralType={collateralType}
          control={control}
          errors={errors}
          field={field}
          isVisible={getIsCollateralAdditionalDetailVisible({
            additionalDetails: 'subordinate',
            collateralPropertyType,
            collateralType,
          })}
          label="This registration is subordinate to another registration"
          register={register}
          value={true}
        />
        <CollateralProceedsToBeClaimed
          additionalDetailName="toBeClaimed"
          clearError={clearError}
          collateralFieldName={collateralFieldName}
          collateralType={collateralType}
          control={control}
          errors={errors}
          field={field}
          isVisible={getIsCollateralAdditionalDetailVisible({
            additionalDetails: 'toBeClaimed',
            collateralPropertyType,
            collateralType,
          })}
          register={register}
          setValue={setValue}
          value={true}
          watch={watch}
        />
      </div>
    </GridContent>
  );
}

const getAvailableCollateralPropertyTypeOptions = (legalType) => {
  if (legalType === 'personal') {
    return COLLATERAL_PROPERTY_TYPE_OPTIONS;
  }

  return COLLATERAL_PROPERTY_TYPE_OPTIONS.filter(
    (option) => option.value === 'Commercial'
  );
};

function CollateralDetailForm(props) {
  const {
    application,
    availableOptions,
    clearError,
    control,
    errors,
    field,
    fields,
    index,
    isCollateralInventoryEnabled,
    isCollateralPropertyTypeFieldEnabled,
    isCollateralTypeFieldEnabled,
    register,
    selectedCollateralPropertyTypeState,
    setValue,
    watch,
  } = props;

  const collateralPropertyTypeOptions = getAvailableCollateralPropertyTypeOptions(
    application.legalType
  );

  const collateralPropertyTypeFieldName = `collaterals[${index}].collateralPropertyType`;
  const collateralTypeFieldName = `collaterals[${index}].collateralType`;
  const collateralDescriptionFieldName = `collaterals[${index}].collateralDescription`;
  const collateralAdditionalDetails = `collaterals[${index}].additionalDetails`;

  const {
    selectedCollateralType,
    selectedCollateralTypeOption,
    setSelectedCollateralType,
  } = useSelectedCollateralTypeState({
    defaultValue: field.collateralType,
    region: 'AU',
  });

  const collateralDescriptionVisibilityState = useCollateralDescriptionVisibilityState(
    {
      collateralType: selectedCollateralType,
    }
  );

  const collateralAdditionalDetailsVisibilityState = useCollateralAditionalDetailsVisibilityState(
    { collateralType: selectedCollateralType }
  );

  const [collateralDescription, setCollateralDescription] = useState(
    field.collateralDescription
  );

  const onSelectCollateralType = (_, value) => {
    const selectedValue = (value || {}).value;

    setCollateralTypeDetails({
      collateralDescriptionFieldName,
      collateralTypeFieldName,
      selectedOption: value,
      setCollateralDescription,
      setSelectedCollateralType,
      setValue,
    });

    collateralDescriptionVisibilityState.onToggleVisibility(selectedValue);
    collateralAdditionalDetailsVisibilityState.onToggleVisibility(
      selectedValue
    );
  };

  const {
    selectedCollateralPropertyType,
    selectedCollateralPropertyTypeOption,
    setSelectedCollateralPropertyType,
  } = selectedCollateralPropertyTypeState;

  const onSelectCollateralPropertyType = (_, value) => {
    const selectedValue = (value || {}).value;

    setSelectedCollateralPropertyType(selectedValue);
    setValue(collateralPropertyTypeFieldName, selectedValue);
  };

  useEffect(() => {
    register({ name: collateralTypeFieldName });
    setValue(collateralTypeFieldName, selectedCollateralType);

    register({ name: collateralPropertyTypeFieldName });
    setValue(collateralPropertyTypeFieldName, selectedCollateralPropertyType);
  }, [index, field.fieldId, fields, register]);

  let isCollateralTypeEnabled = isCollateralTypeFieldEnabled;
  if (isBlank(field.id)) {
    isCollateralTypeEnabled = true;
  }

  const onChangeDescription = (event) => {
    const description = get(event, 'target.value', '');
    setCollateralDescription(description);
    setValue(collateralDescriptionFieldName, description);
  };

  const onBlurDescription = (event) => {
    const description = get(event, 'target.value', '').trim();
    setCollateralDescription(description);
    setValue(collateralDescriptionFieldName, description);
  };

  return (
    <Fragment>
      <GridContent>
        <input
          name={`collaterals[${index}].id`}
          type="hidden"
          ref={register()}
          defaultValue={field.id}
        />
        <BorderedAutocomplete
          disabled={!isCollateralPropertyTypeFieldEnabled}
          textFieldProps={{
            error: Boolean(errors.collateralPropertyType),
            helperText: get(errors, 'collateralPropertyType.message', ' '),
            label: 'Type',
            name: collateralPropertyTypeFieldName,
          }}
          options={collateralPropertyTypeOptions}
          value={selectedCollateralPropertyTypeOption}
          onChange={onSelectCollateralPropertyType}
        />
        <BorderedAutocomplete
          disabled={!isCollateralTypeEnabled}
          textFieldProps={{
            error: Boolean(errors.collateralType),
            helperText: get(errors, 'collateralType.message', ' '),
            label: 'Class',
            name: collateralTypeFieldName,
          }}
          options={availableOptions}
          value={selectedCollateralTypeOption}
          onChange={onSelectCollateralType}
        />
      </GridContent>
      <CollateralDescription
        collateralDescription={collateralDescription}
        collateralDescriptionFieldName={collateralDescriptionFieldName}
        errors={errors}
        field={field}
        isVisible={collateralDescriptionVisibilityState.isVisible}
        register={register}
        onChange={onChangeDescription}
        onBlur={onBlurDescription}
      />
      <CollateralAdditionalDetailsForm
        clearError={clearError}
        collateralFieldName={collateralAdditionalDetails}
        collateralPropertyType={selectedCollateralPropertyType}
        collateralType={selectedCollateralType}
        control={control}
        errors={errors}
        field={field}
        isCollateralInventoryEnabled={isCollateralInventoryEnabled}
        isVisible={collateralAdditionalDetailsVisibilityState.isVisible}
        register={register}
        setValue={setValue}
        watch={watch}
      />
    </Fragment>
  );
}

function CollateralFormError(props) {
  const { error } = props;

  if (isBlank(error)) {
    return null;
  }

  return <div className="has-text-danger">{error}</div>;
}

export function CollateralsForm(props) {
  const {
    application,
    clearError,
    collateralFieldArray,
    control,
    errors,
    isCollateralInventoryEnabled,
    isCollateralPropertyTypeFieldEnabled,
    isCollateralPurchaseMoneyEnabled,
    isCollateralTypeFieldEnabled,
    ppsrAddonConfig,
    register,
    selectedCollateralPropertyTypeState,
    setValue,
    watch,
  } = props;

  const { fields } = collateralFieldArray;
  const collateralErrors = errors.collaterals || [];

  const availableOptions = getAvailableCollateralTypeOptions({
    config: ppsrAddonConfig,
    region: 'AU',
  });

  const collaterals = fields.map((field, index) => (
    <CollateralDetailForm
      application={application}
      availableOptions={availableOptions}
      clearError={clearError}
      control={control}
      errors={collateralErrors[index] || {}}
      field={field}
      fields={fields}
      index={index}
      isCollateralInventoryEnabled={isCollateralInventoryEnabled}
      isCollateralTypeFieldEnabled={isCollateralTypeFieldEnabled}
      isCollateralPropertyTypeFieldEnabled={
        isCollateralPropertyTypeFieldEnabled
      }
      isCollateralPurchaseMoneyEnabled={isCollateralPurchaseMoneyEnabled}
      key={field.fieldId}
      register={register}
      selectedCollateralPropertyTypeState={selectedCollateralPropertyTypeState}
      setValue={setValue}
      watch={watch}
    />
  ));

  return (
    <Fragment>
      <div className={styles.sub_header}>Collateral details</div>
      <CollateralFormError error={get(errors, 'collaterals.message')} />
      {collaterals}
    </Fragment>
  );
}

CollateralsForm.defaultProps = {
  isCollateralInventoryEnabled: true,
  isCollateralPropertyTypeFieldEnabled: true,
  isCollateralPurchaseMoneyEnabled: true,
  isCollateralTypeFieldEnabled: true,
};

function GrantorBusinessNumberForm(props) {
  const { errors, field, index, isVisible, register } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <BorderedTextField
      label="ACN/ABN"
      placeholder="ACN/ABN"
      defaultValue={field.organisationNumber}
      disabled={true}
      inputRef={register()}
      name={`debtors[${index}].organisationNumber`}
      error={Boolean(errors.organisationNumber)}
      helperText={get(errors, 'organisationNumber.message', ' ')}
    />
  );
}

function GrantorIndividualTypeForm(props) {
  const { errors, field, index, isVisible, register } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <Fragment>
      <BorderedTextField
        label="First name"
        placeholder="First name"
        defaultValue={field.personFirstName}
        disabled={true}
        inputRef={register()}
        name={`debtors[${index}].personFirstName`}
        error={Boolean(errors.personFirstName)}
        helperText={get(errors, 'personFirstName.message', ' ')}
      />
      <BorderedTextField
        label="Last name"
        placeholder="Last name"
        defaultValue={field.personLastName}
        disabled={true}
        inputRef={register()}
        name={`debtors[${index}].personLastName`}
        error={Boolean(errors.personLastName)}
        helperText={get(errors, 'personLastName.message', ' ')}
      />
    </Fragment>
  );
}

function GrantorDetailForm(props) {
  const {
    errors,
    field,
    fields,
    index,
    isModifyMode,
    register,
    remove,
    setValue,
  } = props;

  const debtorTypeFieldName = `debtors[${index}].debtorType`;
  const debtorAddressFieldName = `debtors[${index}].debtorAddress`;

  const selectedDebtorTypeOption = getSelectedDebtorTypeOption(
    DEBTOR_TYPE_OPTIONS,
    field.debtorType
  );

  useEffect(() => {
    register({ name: debtorTypeFieldName });
    setValue(debtorTypeFieldName, field.debtorType);

    register({ name: debtorAddressFieldName });
  }, [index, field.fieldId, fields, register]);

  let removeAction = null;
  if (index > 0) {
    removeAction = (
      <div className="has-text-danger cursor-pointer" onClick={remove}>
        remove debtor
      </div>
    );
  }

  // PPSR AU does not allow modifying existing guarantors
  const isPersisted = isPresent(field.id);
  const isPersonDateOfBirthDisabled = isModifyMode && isPersisted;

  return (
    <Fragment>
      <div className={`${styles.sub_header} is-flex`}>
        <div className="has-text-weight-normal mr-2">Grantor {index + 1}</div>
        {removeAction}
      </div>
      <input
        name={`debtors[${index}].id`}
        type="hidden"
        ref={register()}
        defaultValue={field.id}
      />
      <input
        name={`debtors[${index}].authorisationId`}
        type="hidden"
        ref={register()}
        defaultValue={field.authorisationId}
      />
      <GridContent>
        <BorderedAutocomplete
          disabled={true}
          textFieldProps={{
            error: Boolean(errors.debtorType),
            helperText: get(errors, 'debtorType.message', ' '),
            label: 'Grantor type',
            name: debtorTypeFieldName,
          }}
          options={DEBTOR_TYPE_OPTIONS}
          value={selectedDebtorTypeOption}
        />
        <OrganisationNameForm
          defaultValue={field.organisationName}
          errors={errors}
          fieldName={`debtors[${index}].organisationName`}
          isVisible={field.debtorType === 'organisation'}
          register={register}
        />
        <GrantorIndividualTypeForm
          errors={errors}
          field={field}
          index={index}
          isVisible={field.debtorType === 'person'}
          register={register}
        />
        <GrantorBusinessNumberForm
          errors={errors}
          field={field}
          index={index}
          isVisible={field.debtorType === 'organisation'}
          register={register}
        />
        <PersonDateOfBirthForm
          errors={errors}
          fieldName={`debtors[${index}].personDateOfBirth`}
          isDisabled={isPersonDateOfBirthDisabled}
          isVisible={field.debtorType === 'person'}
          defaultValue={field.personDateOfBirth}
          register={register}
          setValue={setValue}
        />
      </GridContent>
    </Fragment>
  );
}

export function GrantorsForm(props) {
  const {
    application,
    debtorFieldArray,
    currentUser,
    errors,
    isModifyMode,
    register,
    setValue,
  } = props;

  const { append, fields, remove } = debtorFieldArray;
  const debtorErrors = errors.debtors || [];

  const debtors = fields.map((field, index) => (
    <GrantorDetailForm
      application={application}
      currentUser={currentUser}
      errors={debtorErrors[index] || {}}
      field={field}
      fields={fields}
      index={index}
      isModifyMode={isModifyMode}
      key={field.fieldId}
      register={register}
      setValue={setValue}
      remove={() => remove(index)}
    />
  ));

  return (
    <Fragment>
      <div className={styles.sub_header}>Grantor details</div>
      {debtors}
      <AddDebtor
        label="grantor"
        append={append}
        application={application}
        fields={fields}
      />
    </Fragment>
  );
}

GrantorsForm.defaultProps = {
  isModifyMode: false,
};

export default function AUPPSRForm(props) {
  const {
    application,
    currentUser,
    debtorFieldArray,
    errors,
    register,
    setValue,
    ppsrFinancingStatement,
    control,
    watch,
    clearError,
    collateralFieldArray,
  } = props;

  useEffect(() => {
    register({ name: 'expiryDuration' });
  }, [register]);

  const selectedCollateralPropertyTypeState = useSelectedCollateralPropertyTypeState(
    collateralFieldArray.fields[0].collateralPropertyType
  );

  return (
    <Fragment>
      <GrantorsForm
        debtorFieldArray={debtorFieldArray}
        application={application}
        currentUser={currentUser}
        errors={errors}
        register={register}
        setValue={setValue}
      />
      <ReferenceForm errors={errors} register={register} />
      <CollateralsForm
        application={application}
        clearError={clearError}
        collateralFieldArray={collateralFieldArray}
        control={control}
        errors={errors}
        ppsrAddonConfig={application.ppsrAddonConfig || {}}
        register={register}
        selectedCollateralPropertyTypeState={
          selectedCollateralPropertyTypeState
        }
        setValue={setValue}
        watch={watch}
      />
      <FinancingStatementForm
        clearError={clearError}
        control={control}
        errors={errors}
        ppsrAddonConfig={application.ppsrAddonConfig || {}}
        ppsrFinancingStatement={ppsrFinancingStatement}
        register={register}
        collateralPropertyType={
          selectedCollateralPropertyTypeState.selectedCollateralPropertyType
        }
        setValue={setValue}
        watch={watch}
      />
    </Fragment>
  );
}
