import AddressModel from 'models/AddressModel';
import BaseModel from 'models/BaseModel';
import moment from 'moment';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';

export default class PersonModel extends BaseModel {
  get formattedAppointedAt() {
    return moment(this.appointedAt).format('DD/MM/YYYY');
  }

  get formattedResidentialAddress() {
    const address = this.fullAddress;

    if (isBlank(address)) {
      return '';
    }

    const { country, postcode, street } = address;

    return `${street.join(' ')} ${country} ${postcode}`;
  }

  get formattedPhoneNumber() {
    return [this.contactCountryCode, this.contactPhoneNumber]
      .filter((number) => isPresent(number))
      .join(' ');
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  /** Private functions */

  assignRelationships() {
    this.assignSingleRelationship({
      key: 'address',
      model: AddressModel,
    });
  }
}
