import axios from './axios';

const Webhooks = function(config) {
  this.axios = axios(config);
  this.type = 'web_hooks';
};

Webhooks.prototype.saveWebhook = function(webhook, success, error) {
  const { id, ...attributes } = webhook;
  const data = {
    attributes,
    id,
    type: this.type,
  };
  if (id) {
    return this.axios
      .patch(`/${this.type}/${id}`, { data })
      .then(success)
      .catch(error);
  }
  return this.axios
    .post(`/${this.type}`, { data })
    .then(success)
    .catch(error);
};

Webhooks.prototype.getWebhook = function(id, success, error) {
  return this.axios
    .get(`/${this.type}/${id}`)
    .then(success)
    .catch(error);
};

Webhooks.prototype.deleteWebhook = function(id, success, error) {
  return this.axios
    .delete(`/${this.type}/${id}`)
    .then(success)
    .catch(error);
};

Webhooks.prototype.sendTest = function(id, applicationId, success, error) {
  return this.axios
    .get(`/${this.type}/${id}/send_test?application_id=${applicationId}`)
    .then(success)
    .catch(error);
};

export default Webhooks;
