const overrides = {
  api: {
    headers: { Accept: "application/vnd.api+json" },
    hostname:
      (process.env.LOCAL_IP ? `${process.env.LOCAL_IP}:` : "localhost:") +
      (process.env.REACT_APP_API_PORT || 3000),
    protocol: "http",
    ttl: 8000,
    version: "/v1",
    wsProtocal: "ws",
  },
  churnzero: "xO67YBVbk4-f3WmogOvNavcPsFf7xW0Yy7Mr5mvtYL8",
  development: true,
  google_client: "AIzaSyDKGo2WHyJkwXMXwUzQEYemMOPNRYzy2H8",
  ip_geolocation_api: {
    hostname: "geoip.1centre.com",
    protocol: "http",
    ttl: 8000,
  },
  mixpanel: "fd8272f1e4542cd518b97ca180dd62ba",
  nz_post_api: {
    headers: { Accept: "application/json" },
    hostname: "api.nzpost.co.nz/addresschecker",
    protocol: "https",
    ttl: 8000,
    version: "/1.0",
  },
  sentry_dsn: "https://8b38409d5782472fa263a422e4a97a60@sentry.io/1298646",
  stripe: "pk_test_n6GI5Orm3pR0pGKJoK5YfOnR",
  recaptcha_api: {
    hostname: "uat-api.cd.1centre.com/node",
    protocol: "https",
    ttl: 8000,
  },
  recaptcha_key: "6Lca1igaAAAAAPtlb8doOafzCpGDZA0lZkUjZ7Hd",
  visible_reporting_phase: [1, 2],
};
module.exports = overrides;
