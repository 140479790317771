import config from '../../config';
import api from '../../src/api';
import { getBrowser } from './getBrowser';
import { getDevice } from './getDevice';

export function loadingAskNicely(
  name,
  email,
  created_at,
  supplier,
  event_flow,
  supplier_name
) {
  let crypto = require('crypto');

  let secret =
    'pqYavR9w6vJxEZ-WDpM1UwcmuJkjH25J0wHFTL-gOCj0BiLU_8eT7fQrJOKNCWSofQjcQ-x4zU16nYp_Q9NZJEm0MZl5kuwlPG5Epj-rKGyXk1zho_DgfFpbuHOSBKIN';
  let email_hash = crypto
    .createHmac('sha256', secret)
    .update(email)
    .digest('hex');
  let created = new Date(created_at).getTime() / 1000;
  let entity_type = supplier || false ? 'Supplier' : 'Consumer';
  let env = config.env;

  let s_device = getDevice(navigator.userAgent);
  let s_browser = getBrowser(navigator.userAgent);

  window.asknicelySettings = {
    domain_id: '1centre.asknice.ly', // Domain ID - required
    domain_key: '1centre', // Domain Key - required
    name: name, // Optional - but nice to have
    email: email, // Required - if no email, this should be a unique id for this customer in an email-like format. But a real email address is more powerful for follow-ups
    email_hash: email_hash, // Required - *security hash of this customers email - see below
    created: created, // Highly desired - unix timestamp when this customer joined your service.
    mode: 'docked', // Required - Currently the only supported mode is "docked" (at bottom of page)
    segment: 'InApp',
    entity_type: entity_type,
    event_flow: event_flow,
    environment: env,
    supplier_name: supplier_name,
    device: s_device,
    browser: s_browser,
  };

  let fc_JS = document.createElement('script');
  fc_JS.type = 'text/javascript';
  fc_JS.async = true;
  fc_JS.src = 'https://live.asknice.ly/widgetv2.js';
  (document.body
    ? document.body
    : document.getElementsByTagName('head')[0]
  ).appendChild(fc_JS);
}

export function sendSurvey({
  name,
  email,
  supplier,
  event_flow,
  supplier_name,
  access_token,
  websitebutton,
  supplier_id,
  application_id,
  consumer_id,
  consumer_name,
}) {
  let entity_type = supplier || false ? 'Supplier' : 'Consumer';
  let env = config.env;
  let s_device = getDevice(navigator.userAgent);
  let s_browser = getBrowser(navigator.userAgent);

  let attributes = {
    email: email, // Required - Contact email
    name: name, // Optional - Full name
    segment: 'EmailFromApp', // Optional
    entity_type: entity_type, // Optional
    event_flow: event_flow, // Optional
    environment: env, // Optional
    supplier_name: supplier_name, // Optional
    device: s_device, // Optional
    browser: s_browser, // Optional,
    websitebutton: websitebutton || false, // Optional,
    supplier_id: supplier_id,
    application_id: application_id,
    consumer_id: consumer_id,
    consumer_name: consumer_name,
    //triggeremail:   true // true for testing
  };

  let askNicely = api('asknicely', access_token);
  askNicely.createSurvey(attributes, (result) => {}, (error) => {});
}
