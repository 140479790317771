import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import React from 'react';

type CheckboxGroupOption = {
  label: string;
};
interface CheckboxGroup {
  passedValue: string[];
  handleChange: (newState) => void;
  disabled?: boolean;
  options: { [key: string]: CheckboxGroupOption };
  direction?: 'vertical' | 'horizontal';
}

const CheckboxGroup = ({
  passedValue,
  handleChange,
  disabled,
  options,
  direction = 'horizontal',
}: CheckboxGroup) => {
  const [checkedState, setCheckedState] = React.useState(passedValue);

  const handleCheckChange = (event) => {
    const value = event.target.name;
    const currentSelected = checkedState ? checkedState : [];
    const selection = currentSelected.includes(value)
      ? currentSelected.filter((selectedValue) => selectedValue !== value)
      : [...currentSelected, value];

    setCheckedState(selection);
    handleChange(selection);
  };

  React.useEffect(() => {
    setCheckedState(passedValue);
  }, [passedValue]);

  return (
    <div
      style={{
        display: 'flex',
        gap: '1rem',
        marginTop: '1rem',
        ...(direction === 'vertical'
          ? { flexDirection: 'column', gap: '0rem', marginBottom: '2rem' }
          : {}),
      }}
    >
      {Object.keys(options).map((key) => (
        <div key={`checkbox-${key}`}>
          <SquareCheckbox
            key={key}
            checkboxId={key}
            checkboxName={key}
            checked={checkedState.includes(key)}
            onClick={handleCheckChange}
            label={options[key].label}
            disabled={disabled}
          />
        </div>
      ))}
    </div>
  );
};

export default CheckboxGroup;
