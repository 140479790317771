import { InputAdornment } from '@material-ui/core';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form-latest';
import { formatMoney } from 'utils/formatting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Error } from './types';

type Props = {
  control: any;
  customProps?: any;
  error?: Error;
  inputProps?: any;
  isMoney?: boolean;
  isLoading?: boolean;
  label?: string;
  labelShrink?: boolean;
  name: string;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: (e, processedValue) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  noWhiteSpaces?: boolean;
  value?: any;
};

function _formatMoney(money: string | number): string {
  const isNumber = typeof money === 'number';

  if (!isNumber && isNaN(parseInt(money))) {
    return '';
  }

  const processedMoney = typeof money === 'string' ? parseInt(money) : money;

  return formatMoney(processedMoney, null, null, null, null);
}

const processValue = ({ isMoney, noWhiteSpaces, value }) => {
  let processedValue = value;

  if (noWhiteSpaces && processedValue) {
    processedValue = processedValue.replace(/\s/g, '');
  }
  processedValue = processMoneyValue({ isMoney, value: processedValue });

  return processedValue;
};

// eslint-disable-next-line arrow-body-style
const processMoneyValue = ({ isMoney, value }) => {
  return isMoney && value ? parseInt(value.replace(/\D/g, '')) || '' : value;
};

function RHFBorderedTextField(props: Props): ReactElement {
  const {
    customProps = {},
    error,
    inputProps = {},
    isLoading,
    isMoney,
    label,
    labelShrink,
    onBlur,
    onChange,
    placeholder,
    readOnly,
    required,
    noWhiteSpaces,
    value: passedValue,
    onFocus,
    ...controllerProps
  } = props;

  if (isMoney) {
    customProps['startAdornment'] = (
      <InputAdornment position="start">$</InputAdornment>
    );
  }

  if (isLoading) {
    customProps['endAdornment'] = (
      <InputAdornment position="end">
        <FontAwesomeIcon icon="spinner" spin />
      </InputAdornment>
    );
  }

  const processedLabel = required ? `${label}*` : label;

  return (
    <Controller
      {...controllerProps}
      render={({
        field: {
          ref,
          onChange: defaultOnChange,
          onBlur: defaultOnBlur,
          value,
          ...textFieldProps
        },
        fieldState: { error: fieldError },
      }) => {
        const targetValue = passedValue || value;
        const processedError = error || fieldError;
        const processedValue =
          isMoney && targetValue ? _formatMoney(targetValue) : targetValue;

        return (
          <BorderedTextField
            {...textFieldProps}
            inputProps={inputProps}
            customProps={customProps}
            error={!!processedError}
            helperText={processedError && processedError.message}
            onFocus={onFocus}
            onChange={(e) => {
              const value = e.target.value;
              const processedValue = processValue({
                isMoney,
                noWhiteSpaces,
                value,
              });

              if (onChange) {
                onChange(e, processedValue);
              }

              defaultOnChange(processedValue);
            }}
            disabled={readOnly}
            onBlur={onBlur || defaultOnBlur}
            inputRef={ref}
            placeholder={placeholder}
            label={processedLabel}
            labelShrink={labelShrink}
            value={processedValue}
          />
        );
      }}
    />
  );
}

RHFBorderedTextField.defaultProps = {
  isLoading: false,
  labelShrink: false,
  noWhiteSpaces: false,
};

export default RHFBorderedTextField;
