/* Import libraries */
import React from 'react';

import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';
import EmailInput from 'modules/shared/components/inputs/EmailInput';

import styles from './css/Guarantors.css';

class Guarantor extends React.Component {
  _nameChangeHandler = (e) => {
    const { index, onChangeNameSelection } = this.props;
    onChangeNameSelection(
      index,
      e.value,
      e.percentage_share,
      e.position,
      e.first_name,
      e.last_name,
      e.middle_name
    );
  };

  _emailChangeHandler = (value, isValid) => {
    const { isApplicant, index, onChangeEmail } = this.props;

    if (isApplicant) {
      return;
    }

    onChangeEmail(index, value, isValid);
  };

  render() {
    const {
      email,
      error,
      first_name,
      index,
      isApplicant,
      isApplicationSubmitted,
      last_name,
      middle_name,
      name,
      percentage_share,
      position,
      sort_dropdown,
    } = this.props;

    return (
      <div className={styles.left_col}>
        <div className={styles.form}>
          <OptionsDropdown
            key={`name-${index}`}
            ref="name"
            error={error.name}
            handleChange={this._nameChangeHandler}
            value={name}
            label={`Guarantor ${index + 1}`}
            required={true}
            options={sort_dropdown}
            percentage_share={percentage_share}
            position={position}
            first_name={first_name}
            last_name={last_name}
            middle_name={middle_name}
            disabled={isApplicationSubmitted && isApplicant}
          />
          <EmailInput
            key={`email-${index}`}
            type="email"
            ref="email"
            triggerValidation={error.email}
            handleChange={this._emailChangeHandler}
            handleBlur={this._emailChangeHandler}
            label="Email address"
            required={true}
            disabled={isApplicant}
            value={email}
          />
        </div>
      </div>
    );
  }
}

export default Guarantor;
