const config = {
  env: process.env.NODE_ENV || 'development',
  api: {
    protocol: 'https',
    hostname: 'api-aws.1centre.com',
    version: '/v1',
    ttl: 8000,
    headers: { 'Accept': 'application/vnd.api+json' }
  },
  nz_post_api: {
    protocol: 'https',
    hostname: 'api.nzpost.co.nz/addresschecker',
    version: '/1.0',
    ttl: 8000,
    headers: { 'Accept': 'application/json' }
  },
  ip_geolocation_api: {
    protocol: 'https',
    hostname: 'geoip.1centre.com',
    ttl: 8000
  },
  churnzero: 'xO67YBVbk4-f3WmogOvNavcPsFf7xW0Yy7Mr5mvtYL8',
  recaptcha_api: {
    hostname: "google.com/recaptcha/api/siteverify",
    protocol: "https",
    ttl: 8000,
  },
  recaptcha_key: "6Lca1igaAAAAAPtlb8doOafzCpGDZA0lZkUjZ7Hd",
};
module.exports = config;
