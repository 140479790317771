import { TERMS_AND_CONDITIONS } from 'constants';
import Button from 'modules/shared/components/inputs/Button';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import BackAndNextButtons from 'modules/shared/components/widgets/interactive/BackAndNextButtons';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip.js';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import PdfReader from 'modules/shared/components/widgets/static/pdfReader';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { customTermsUrl } from 'utils/extractAttachmentUrl';
import flattenArray from 'utils/flattenArray';

import { setValue, updateAuthorisation } from '../actions';
import styles from './css/Section.css';

const CHECKBOX_TERMS_BY_SIGNATORY = {
  antiFraud: ['antiFraudIdHeld'],
  guarantor: ['guarantor'],
  paperless: [
    'paperless',
    'paperlessOver18',
    'paperlessAuthorised',
    'paperlessDiscloseInformation',
  ],
  supplier: ['supplier'],
};

class Terms extends Component {
  constructor() {
    super();
    this.state = {
      antiFraudIdHold: false,
      guarantor: false,
      hasCheckedAllBoxes: false,
      hasScrolled: true,
      paperless: false,
      paperlessAuthorised: false,
      paperlessDiscloseInformation: false,
      paperlessOver18: false,
      showBoxesHint: false,
      showScrollHint: false,
      supplier: false,
    };
  }

  declineAll() {
    const {
      dispatch,
      hasCardholder,
      hasGuarantor,
      hasPaperless,
      params,
      toLastSection,
    } = this.props;
    if (hasGuarantor) dispatch(setValue('guarantorApproved', false));
    if (hasCardholder) dispatch(setValue('cardholderApproved', false));
    if (hasPaperless) dispatch(setValue('paymentApproved', false));

    dispatch(
      updateAuthorisation(params.authorisation_id, () => {
        toLastSection();
      })
    );
  }

  checkBox(name) {
    const checkboxValue = !this.state[name];

    if (this.validateAllBoxes(name, checkboxValue)) {
      this.setState({ hasCheckedAllBoxes: true });
      this.setState({ showBoxesHint: false });
    } else {
      this.setState({ hasCheckedAllBoxes: false });
    }
    this.setState({ [name]: checkboxValue });
  }

  onNextClick() {
    const { toNextSection } = this.props;
    const { hasScrolled, hasCheckedAllBoxes } = this.state;
    if (!hasScrolled) {
      this.setState({ showScrollHint: true });
    }
    if (!hasCheckedAllBoxes) {
      this.setState({ showBoxesHint: true });
    }
    if (hasScrolled && hasCheckedAllBoxes) {
      toNextSection();
    }
  }

  validateAllBoxes(boxName, value) {
    const { termList } = this.props;
    const isAllTermsAccepted = termList.map((term) =>
      this.isSignatoryTermsAccepted(term, boxName, value)
    );

    return flattenArray(isAllTermsAccepted).every((isAccepted) => isAccepted);
  }

  // This function returns an array of boolean values wherein all items in the
  // array should be true if the user accepted all the terms for a specific
  // signatory, i.e. applicant, guarantor or paperless
  isSignatoryTermsAccepted(signatoryTerm, boxName, value) {
    if (!this.props.termList.includes(signatoryTerm)) {
      return [];
    }

    return CHECKBOX_TERMS_BY_SIGNATORY[signatoryTerm].map((checkbox) =>
      checkbox === boxName ? value : this.state[checkbox]
    );
  }

  onScrollToBottom() {
    if (!this.state.hasScrolled) {
      this.setState({ hasScrolled: true });
      this.setState({ showScrollHint: false });
    }
  }

  renderPdf() {
    const { termList, customTermsConfigs, region } = this.props;
    const termUrls = [];
    termList.forEach((termType) => {
      let termUrl = defaults[termType][region];

      if (customTermsConfigs[termType].uses_custom_terms) {
        termUrl = customTermsUrl(customTermsConfigs[termType].custom_terms).url;
      }
      termUrls.push(termUrl);
    });
    return (
      <PdfReader
        urls={termUrls}
        onScrollToBottom={this.onScrollToBottom.bind(this, event)}
      />
    );
  }

  renderCheckBoxes() {
    return [
      ...this.renderSupplierCheckboxes(),
      ...this.renderGuarantorCheckboxes(),
      ...this.renderPaperlessCheckboxes(),
    ];
  }

  renderSupplierCheckboxes() {
    if (this.props.termList.includes('supplier')) {
      return [this.renderSupplierTermsCheckbox()];
    }

    return [];
  }

  renderSupplierTermsCheckbox() {
    const supplierName = this.props.supplierName;
    const label = `I have read ${supplierName} T&C's and will abide by the rules
                   of ${supplierName}`;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'supplier')}
      >
        <SquareCheckbox label={label} checked={this.state.supplier} />
      </div>
    );
  }

  renderGuarantorCheckboxes() {
    if (this.props.termList.includes('guarantor')) {
      return [this.renderGuarantorTermsCheckbox()];
    }

    return [];
  }

  renderGuarantorTermsCheckbox() {
    const supplierName = this.props.supplierName;
    const label = `I have read and understood the guarantor T&C’s and I
                   acknowledge that ${supplierName} recommend that I obtain
                   independent legal advice as to the effect of this guarantee
                   and my potential liability as guarantor, and I confirm that
                   I have elected not to obtain such independent advice and
                   have agreed to provide the guarantee in favour of the
                   applicant.`;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'guarantor')}
      >
        <SquareCheckbox label={label} checked={this.state.guarantor} />
      </div>
    );
  }

  renderPaperlessCheckboxes() {
    if (this.props.termList.includes('paperless')) {
      return [
        this.renderPaperlessAuthorised(),
        this.renderPaperlessDisclaimer(),
        this.renderPaperlessDiscloseInformation(),
        this.renderPaperlessOver18(),
      ];
    }

    return [];
  }

  renderIdHoldDisclaimer() {
    const supplierName = this.props.supplierName;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'antiFraudIdHold')}
      >
        <SquareCheckbox
          label={`I confirm that ${supplierName} has the right to hold my ID documents on file until my identity has been confirmed`}
          checked={this.state.antiFraudIdHold}
        />
      </div>
    );
  }

  renderPaperlessDisclaimer() {
    const supplierName = this.props.supplierName;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'paperless')}
      >
        <SquareCheckbox
          label={`I have read and understood the terms and conditions of the direct debit authority for ${supplierName}`}
          checked={this.state.paperless}
        />
      </div>
    );
  }

  renderPaperlessDiscloseInformation() {
    const supplierName = this.props.supplierName;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'paperlessDiscloseInformation')}
      >
        <SquareCheckbox
          label={`I understand that ${supplierName} will not use, store or disclose my information except as authorised by the terms and conditions`}
          checked={this.state.paperlessDiscloseInformation}
        />
      </div>
    );
  }

  renderPaperlessOver18() {
    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'paperlessOver18')}
      >
        <SquareCheckbox
          label="I am not less than 18 years of age"
          checked={this.state.paperlessOver18}
        />
      </div>
    );
  }

  renderPaperlessAuthorised() {
    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, 'paperlessAuthorised')}
      >
        <SquareCheckbox
          label="I am authorised to sign this direct debit"
          checked={this.state.paperlessAuthorised}
        />
      </div>
    );
  }

  render() {
    const { toPreviousSection, updating } = this.props;

    return (
      <div>
        <div className={styles.container}>
          <section className={styles.section}>
            <div className={styles.row}>
              <div className={styles.panel}>
                <PanelTitle text={defaults.title} />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.content}>
                <PopperTooltip
                  title={tooltips.scroll}
                  placement="bottom-end"
                  open={this.state.showScrollHint}
                >
                  {this.renderPdf()}
                </PopperTooltip>
                <PopperTooltip
                  title={tooltips.checkBox}
                  placement="bottom-start"
                  open={this.state.showBoxesHint}
                >
                  <div className={styles.checkbox_container}>
                    {this.renderCheckBoxes()}
                  </div>
                </PopperTooltip>
              </div>
            </div>
          </section>
        </div>
        <BackAndNextButtons
          additionalButtons={
            <Button
              text="Decline all"
              css_style="button_small_font"
              handleClick={() => this.declineAll()}
              loading={updating}
              disableOnLoading={true}
            />
          }
          onNextClick={this.onNextClick.bind(this)}
          onBackClick={toPreviousSection}
        />
      </div>
    );
  }
}

const defaults = {
  guarantor: {
    AU: TERMS_AND_CONDITIONS.AU.guarantor,
    NZ: TERMS_AND_CONDITIONS.NZ.guarantor,
  },
  paperless: {
    AU: TERMS_AND_CONDITIONS.AU.paperless,
    NZ: TERMS_AND_CONDITIONS.NZ.paperless,
  },
  supplier: {
    AU: TERMS_AND_CONDITIONS.AU.supplier,
    NZ: TERMS_AND_CONDITIONS.NZ.supplier,
  },
  title: 'Terms and conditions',
};

const tooltips = {
  checkBox: 'Please agree to all the declarations.',
  scroll: 'You must scroll through the Ts & Cs before you can agree to them.',
};

export default connect((state, ownProps) => {
  const authorisation = state.authorisation;

  const termList = ['supplier'];
  if (ownProps.hasGuarantor && authorisation.guarantorApproved) {
    termList.push('guarantor');
  }

  if (ownProps.hasPaperless && authorisation.paymentApproved) {
    termList.push('paperless');
  }

  const applicationAttributes = authorisation.data.application.attributes;
  const customTermsConfigs = {
    guarantor: {
      custom_terms: applicationAttributes.custom_guarantor_terms,
      uses_custom_terms: applicationAttributes.uses_custom_guarantor_terms,
    },
    paperless: {
      custom_terms: applicationAttributes.custom_paperless_terms,
      uses_custom_terms: applicationAttributes.uses_custom_paperless_terms,
    },
    supplier: {
      custom_terms: applicationAttributes.custom_terms,
      uses_custom_terms: applicationAttributes.uses_custom_supplier_terms,
    },
  };

  return {
    customTermsConfigs,
    region: applicationAttributes.region,
    termList,
    updating: authorisation.updating,
  };
})(Terms);
