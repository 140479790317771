import get from 'lodash.get';
import AddonConfigModel, {
  DIGITAL_ONBOARDING_MODULES,
} from 'models/AddonConfigModel';
import UserModel from 'models/UserModel';
import AddOnsNotSavePopup from 'modules/addons/addons_form/components/AddOnsNotSavePopup';
import PPSRModuleComponents from 'modules/addons/components/digital-onboarding/PPSRRegistration/index';
import Dot from 'modules/shared/components/widgets/static/Dot';
import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import isPresent from 'utils/isPresent';
import mixpanel from 'mixpanel-browser';
import commonStyles from './css/CommonAddOns.css';
import styles from 'modules/addons/components/css/DigitalOnboarding.css';
import CardManagementModuleComponents from './digital-onboarding/CardManagementModuleComponents';
import DirectDebitModuleComponents from './digital-onboarding/DirectDebitModuleComponents';
import {
  isHeadquarter,
  isRegular,
  isFeatureEditEnabled,
} from 'modules/shared/helpers/headquarterDetect';
import IUF from './digital-onboarding/IUF';
import AlertModuleComponents from './digital-onboarding/AlertModuleComponents';
import { loadAntiFraud } from 'modules/profile/actions';

const COMPONENTS_BY_MODULE = {
  card_management_module: {
    availableRegions: ['AU', 'NZ'],
    edit: CardManagementModuleComponents.Edit,
    listItem: CardManagementModuleComponents.Item,
    isVisible: () => {
      if (isRegular()) {
        return true;
      }

      return isHeadquarter && isFeatureEditEnabled('Cards');
    },
  },
  direct_debit_module: {
    availableRegions: ['AU', 'NZ'],
    edit: DirectDebitModuleComponents.Edit,
    listItem: DirectDebitModuleComponents.Item,
    isVisible: () => {
      if (isRegular()) {
        return true;
      }

      return isHeadquarter && isFeatureEditEnabled('DirectDebit');
    },
  },
  ppsr_module: {
    availableRegions: ['AU', 'NZ'],
    edit: PPSRModuleComponents.Edit,
    listItem: PPSRModuleComponents.Item,
    isVisible: () => {
      if (isRegular()) {
        return true;
      }

      return isHeadquarter && isFeatureEditEnabled('PPSR');
    },
  },
  ppsr_australia_module: {
    availableRegions: ['AU', 'NZ'],
    edit: PPSRModuleComponents.Edit,
    listItem: PPSRModuleComponents.Item,
    isVisible: () => {
      if (isRegular()) {
        return true;
      }

      return isHeadquarter && isFeatureEditEnabled('PPSR');
    },
  },
  internal_use_fields: {
    availableRegions: ['AU', 'NZ'],
    edit: IUF.Edit,
    listItem: IUF.Item,
    multipleConfig: true,
    isVisible: () => {
      if (isRegular()) {
        return true;
      }

      return isHeadquarter && isFeatureEditEnabled('IUF');
    },
  },
  alert_module: {
    availableRegions: ['AU', 'NZ'],
    edit: AlertModuleComponents.Edit,
    listItem: AlertModuleComponents.Item,
    isVisible: () => {
      if (isRegular()) {
        return true;
      }

      return isHeadquarter && isFeatureEditEnabled('Alerts');
    },
  },
};

export const DigitalOnboardingContext = createContext({
  currentPage: 'list',
  onClickBack: () => {},
  setCurrentPage: () => {},
});

export function DigitalOnboardingHeader(props) {
  return <div className={styles.header}>{props.children}</div>;
}

export function ContentContainer(props) {
  const { children, header } = props;

  return (
    <div className={styles.content_container}>
      <div className={styles.content_header}>{header}</div>
      {children}
    </div>
  );
}

export function DirtyPopup(props) {
  const { isVisible, onHidePopup, onLeave, onSave, redirectUrl } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <AddOnsNotSavePopup
      handleSave={onSave}
      handleLeave={onLeave}
      hidePopup={onHidePopup}
      redirectUrl={redirectUrl}
    />
  );
}

function getOrInitializeAddonModule(digitalOnboardingModules, moduleName) {
  const addonConfig = digitalOnboardingModules.find(
    (addon) => addon.addonModuleName === moduleName
  );

  if (isPresent(addonConfig)) {
    return addonConfig;
  }

  return new AddonConfigModel({
    attributes: { addon_module_name: moduleName },
  });
}

function DigitalOnboardingModules(props) {
  const { currentUser, digitalOnboardingModules } = props;
  const region = get(currentUser, 'currentEntity.region');

  return DIGITAL_ONBOARDING_MODULES.filter(
    (module) =>
      COMPONENTS_BY_MODULE[module].availableRegions.includes(region) &&
      COMPONENTS_BY_MODULE[module].isVisible()
  ).map((module, index) => {
    const multiple = COMPONENTS_BY_MODULE[module].multipleConfig;
    let addonConfig = {};
    let addonConfigs = [];

    if (multiple) {
      addonConfigs = digitalOnboardingModules.filter(
        (addonConfig) => addonConfig.addonModuleName === module
      );
    } else {
      addonConfig = getOrInitializeAddonModule(
        digitalOnboardingModules,
        module
      );
    }
    const ListItemComponent = COMPONENTS_BY_MODULE[module].listItem;

    return (
      <ListItemComponent
        key={`digital-onboarding-module-${index + 1}`}
        addonConfig={addonConfig}
        addonConfigs={addonConfigs}
        {...props}
      />
    );
  });
}

function DigitalOnboardingList(props) {
  return (
    <div>
      <DigitalOnboardingHeader>Credit control modules</DigitalOnboardingHeader>
      <div className={styles.digital_onboarding_container}>
        <div className={styles.info_body}>
          These modules allow you, as a Supplier, easy access and set up of
          functionality that informs the customer onboarding process. They are
          aimed at credit teams who want to control and digitally enhance more
          of the credit control functionality – which can include payments,
          notifications, card management and more. You will have access to any
          new functionality automatically through your 1Centre account.
        </div>
        <div className={styles.cards_container}>
          <DigitalOnboardingModules {...props} />
        </div>
      </div>
    </div>
  );
}

function Content(props) {
  const { addonConfigs, currentAddonConfigToEdit, currentPage } = useContext(
    DigitalOnboardingContext
  );

  if (currentPage === 'list') {
    return <DigitalOnboardingList {...props} />;
  }

  const DigitalOnboardingComponent =
    COMPONENTS_BY_MODULE[currentAddonConfigToEdit.addonModuleName][currentPage];
  return (
    <DigitalOnboardingComponent
      addonConfig={currentAddonConfigToEdit}
      addonConfigs={addonConfigs}
      {...props}
    />
  );
}

function useCurrentComponentState(props) {
  const { currentUser } = props;
  const [currentPage, setCurrentPage] = useState('list');
  const [currentAddonConfigToEdit, setCurrentAddonConfigToEdit] = useState(
    null
  );
  const [addonConfigs, setAddonConfigs] = useState(null);

  const onClickBack = () => {
    setCurrentPage('list');
    setCurrentAddonConfigToEdit(null);
  };

  const onSetAddonToEdit = (addonConfigData) => {
    let addonConfig = addonConfigData;

    setCurrentPage('edit');

    if (Array.isArray(addonConfigData)) {
      addonConfig = addonConfigData[0];
      setAddonConfigs(addonConfigData);
    }

    setCurrentAddonConfigToEdit(addonConfig);

    mixpanel.track(
      `Credit control - ${addonConfig.humanReadableName} clicked`,
      {
        distinct_id: currentUser.id,
      }
    );
  };

  return {
    addonConfigs,
    currentAddonConfigToEdit,
    currentPage,
    onClickBack,
    onSetAddonToEdit,
  };
}

async function fetchAddons({
  currentUser,
  setDigitalOnboardingModules,
  setIsCardAddonConfigured,
  setIsIUFAddonConfigured,
  setIsPaperlessAddonConfigured,
  setDefaultCardsAdditionalFields,
}) {
  const addonConfigs = await AddonConfigModel.fetchAddonConfigByAddonType({
    accessToken: currentUser.accessToken,
    addonType: [
      'paperless',
      'cards',
      'direct_debit_module',
      'card_management_module',
      'ppsr_module',
      'ppsr_australia_module',
      'internal_use_fields',
      'alert_module',
    ],
    entityId: get(currentUser, 'currentEntity.id'),
  });
  const paperlessAddon = addonConfigs.find(
    (addonConfig) => addonConfig.addonModuleName === 'paperless'
  );
  setIsPaperlessAddonConfigured(isPresent(paperlessAddon));

  const cardsAddon = addonConfigs.find(
    (addonConfig) => addonConfig.addonModuleName === 'cards'
  );
  setIsCardAddonConfigured(isPresent(cardsAddon));

  if (isPresent(cardsAddon)) {
    setDefaultCardsAdditionalFields(
      cardsAddon.latestVersion.config.additional_fields
    );
  }

  const iufAddon = addonConfigs.filter(
    (addonConfig) => addonConfig.addonModuleName === 'internal_use_fields'
  );
  setIsIUFAddonConfigured(iufAddon.length > 0);

  const digitalOnboardingModules = addonConfigs.filter(
    (addonConfig) => !addonConfig.addonModuleName.includes('paperless')
  );
  setDigitalOnboardingModules(digitalOnboardingModules);
}

function useDigitalOnboardingModulesState(props) {
  const { currentUser } = props;
  const [isPaperlessAddonConfigured, setIsPaperlessAddonConfigured] = useState(
    false
  );
  const [isIUFAddonConfigured, setIsIUFAddonConfigured] = useState(false);
  const [isCardAddonConfigured, setIsCardAddonConfigured] = useState(false);
  const [
    defaultCardsAdditionalFields,
    setDefaultCardsAdditionalFields,
  ] = useState([]);
  const [digitalOnboardingModules, setDigitalOnboardingModules] = useState([]);

  const onRefreshDigitalOnboardingModules = () => {
    (async () =>
      await fetchAddons({
        currentUser,
        setDefaultCardsAdditionalFields,
        setDigitalOnboardingModules,
        setIsCardAddonConfigured,
        setIsPaperlessAddonConfigured,
        setIsIUFAddonConfigured,
      }))();
  };

  const onUpdateModuleItem = (addonConfig) => {
    const modules = [...digitalOnboardingModules];
    const indexToUpdate = modules.findIndex(
      (module) => module.id === addonConfig.id
    );
    modules.splice(indexToUpdate, 1, addonConfig);

    setDigitalOnboardingModules(modules);
  };

  return {
    defaultCardsAdditionalFields,
    digitalOnboardingModules,
    isCardAddonConfigured,
    isIUFAddonConfigured,
    isPaperlessAddonConfigured,
    onRefreshDigitalOnboardingModules,
    onUpdateModuleItem,
  };
}

function getDotColor(isActive) {
  return isActive ? 'blue' : 'red';
}

function getHeaderColor(isActive) {
  return isActive ? 'active' : 'inactive';
}

function DigitalOnboardingItemActionButtons(props) {
  const {
    areActionButtonsVisible,
    activeState,
    onClickToggleState,
    onClickEdit,
  } = props;

  if (!areActionButtonsVisible) {
    return null;
  }

  const label = activeState ? 'Pause' : 'Enable';
  const color = getDotColor(!activeState);

  return (
    <div className={styles.card_action_buttons}>
      {onClickToggleState && (
        <div
          className={styles[`${color}_state_toggle_action`]}
          onClick={onClickToggleState}
        >
          {label}
        </div>
      )}
      <div className={styles.edit_action} onClick={onClickEdit}>
        Edit
      </div>
    </div>
  );
}

function DigitalOnboardingItemContent(props) {
  const { activeState, body, header, formattedState, onClickEdit } = props;
  const dotColor = getDotColor(activeState);
  const headerColor = getHeaderColor(activeState);
  const headerStyle = `${styles.card_header} ${
    styles[`card_header_${headerColor}`]
  }`;

  return (
    <Fragment>
      <div className={headerStyle} onClick={onClickEdit}>
        {header}
      </div>
      <div className={styles.card_body}>
        <Dot color={dotColor} text={formattedState} onClick={onClickEdit} />
        <div className={styles.card_body_info} onClick={onClickEdit}>
          {body}
        </div>
        <DigitalOnboardingItemActionButtons {...props} />
      </div>
    </Fragment>
  );
}

export function DigitalOnboardingItem(props) {
  const { isConfigured, isEnabled, onClickEdit } = props;

  if (isConfigured) {
    return (
      <div className={styles.card_content}>
        <DigitalOnboardingItemContent {...props} />
      </div>
    );
  }

  if (isEnabled) {
    return (
      <div className={styles.card_content_configurable} onClick={onClickEdit}>
        <DigitalOnboardingItemContent {...props} />
      </div>
    );
  }

  return (
    <div className={styles.card_content}>
      <DigitalOnboardingItemContent {...props} />
    </div>
  );
}

function DigitalOnboarding(props) {
  const { currentUser, dispatch } = props;
  const currentComponentState = useCurrentComponentState({ currentUser });
  const {
    defaultCardsAdditionalFields,
    digitalOnboardingModules,
    isPaperlessAddonConfigured,
    isCardAddonConfigured,
    isIUFAddonConfigured,
    onUpdateModuleItem,
    onRefreshDigitalOnboardingModules,
  } = useDigitalOnboardingModulesState(props);

  useEffect(() => {
    onRefreshDigitalOnboardingModules();
    dispatch(loadAntiFraud());
  }, []);

  return (
    <DigitalOnboardingContext.Provider value={{ ...currentComponentState }}>
      <div className={commonStyles.row}>
        <div className={commonStyles.block_wide}>
          <Content
            isPaperlessAddonConfigured={isPaperlessAddonConfigured}
            isCardAddonConfigured={isCardAddonConfigured}
            isIUFAddonConfigured={isIUFAddonConfigured}
            defaultCardsAdditionalFields={defaultCardsAdditionalFields}
            digitalOnboardingModules={digitalOnboardingModules}
            onUpdateModuleItem={onUpdateModuleItem}
            onRefreshDigitalOnboardingModules={
              onRefreshDigitalOnboardingModules
            }
            {...props}
          />
        </div>
      </div>
    </DigitalOnboardingContext.Provider>
  );
}

export default connect((state) => {
  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
  };
})(DigitalOnboarding);
