/* eslint-disable max-lines */
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import get from 'lodash.get';
import { lookupAddress } from 'modules/consumer-onboarding/actions/onboarding';
import {
  setSelectedTradingNames as setSelectedTradingNamesAction,
  setTradingNameOptions as setTradingNameOptionsAction,
} from 'modules/consumer-onboarding/actions/section';
import TextInput from 'modules/shared/components/inputs/TextInput';
import PageHeader from 'modules/shared/components/v2/PageHeader';
import AutoSuggest from 'modules/shared/components/widgets/interactive/AutoSuggest';
import SimpleMultiSelectDropdown from 'modules/shared/components/widgets/interactive/SimpleMultiSelectDropdown';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { throttle } from 'throttle-debounce';
import isBlank from 'utils/isBlank';
import toggleArrayValue from 'utils/toggleArrayValue';
import { isValidEmail } from 'utils/validators';

import styles from '../css/BusinessChildTradingName';
import { Delete, Section } from './styles';

function BusinessChildTradingName(props) {
  const {
    deliveryAddressData,
    deliveryAddressLoading,
    deliveryAddressOptions,
    dispatch,
    physicalAddress,
    entityRegion,
    handleComplete,
    page_validation_start: pageValidationStart,
    setPageValidationStartFinish,
  } = props;

  const [error, setError] = useState({});
  const [selectedTradingNameIds, setSelectedTradingNameIds] = useState(
    props.selectedTradingNameIds
  );
  const [tradingNamesOptions, setTradingNameOptions] = useState(
    props.tradingNamesOptions
  );
  const [validationTrigger, setValidationTrigger] = useState(false);

  const DELAY_VALIDATION_DURATION = 500;
  const THROTTLE_SPEED = 500;
  const throttledLookupDeliveryAddress = throttle(
    THROTTLE_SPEED,
    false,
    lookupDeliveryAddress
  );

  function validate() {
    const validationResult = isAllValid();
    handleComplete(validationResult);
    setValidationTrigger(false);
  }

  useEffect(() => {
    if (pageValidationStart) {
      validate();
      setPageValidationStartFinish();
    }
  }, [pageValidationStart]);

  useEffect(() => {
    validate();
  }, [validationTrigger]);

  useEffect(() => {
    setDefaultValues();

    // UGLY! Hacking with timeout
    // Reason: Page animation unmount BusinessOtherDetails only after
    // the page is fully mounted. Validation from BusinessOtherDetails
    // is triggered after BusinessChildTradingName is mounted. Not spending
    // another century to convert BusinessOtherDetails to a functional component.
    const delayedValidate = setTimeout(validate, DELAY_VALIDATION_DURATION);

    return () => clearTimeout(delayedValidate);
  }, []);

  function isAllValid() {
    if (selectedTradingNameIds.length === 0) {
      return false;
    }
    let result = true;
    const fieldsError = {};
    selectedTradingNameIds.forEach((id) => {
      const { result: fieldResult, fieldError } = tradingNameDetailsValid({
        ...tradingNamesOptions[id],
        id,
      });
      result = result && fieldResult;
      fieldsError[id] = fieldError;
    });
    setError(fieldsError);

    return result;
  }

  function tradingNameDetailsValid(tradingName) {
    if (!selectedTradingNameIds.includes(tradingName.id)) {
      return true;
    }

    let result = true;

    const fieldError = {};
    Object.keys(formInputs).forEach((key) => {
      const inputValue = tradingName[key];
      const inputMandatory = formInputs[key].required;
      const inputValid = inputValidation(key, inputValue, inputMandatory);
      fieldError[key] = inputValid ? null : errorMessage(key, inputValue);
      result = result && inputValid;
    });

    return {
      fieldError,
      result,
    };
  }

  function inputValidation(inputName, value, required) {
    switch (inputName) {
      case 'delivery_address_details':
        return !!(
          value &&
          value.api_id &&
          value.api_provider &&
          value.full_address
        );
      case 'contact_email':
        if (isBlank(value)) {
          return false;
        }

        return isValidEmail(value);
      default:
        return !(required && isBlank(value));
    }
  }

  function errorMessage(inputName, value) {
    switch (inputName) {
      case 'delivery_address_details':
        if (!value) {
          return 'You must enter delivery address';
        }
        return 'Please select one address';
      case 'contact_email':
        return 'Please enter a valid email';
      default:
        return `You must enter ${formInputs[inputName].label.toLowerCase()}`;
    }
  }

  function setDefaultValues() {
    if (!tradingNamesOptions || selectedTradingNameIds.length !== 0) {
      return;
    }

    const firstTradingNameId = 'new_trading_name_1';

    const firstTradingName = {
      [firstTradingNameId]: {
        deletable: true,
        delivery_address_details: {
          full_address: '',
        },
        editable: true,
        trading_name: '',
      },
    };

    _setTradingNameOptions({
      ...tradingNamesOptions,
      ...firstTradingName,
    });

    setSelectedTradingNames([...selectedTradingNameIds, firstTradingNameId]);
  }

  function _setTradingNameOptions(tradingNamesOptions) {
    setTradingNameOptions(tradingNamesOptions);
    dispatch(setTradingNameOptionsAction(tradingNamesOptions));
  }

  function setSelectedTradingNames(selectedTradingNameIds) {
    setSelectedTradingNameIds(selectedTradingNameIds);
    dispatch(setSelectedTradingNamesAction(selectedTradingNameIds));
  }

  function lookupDeliveryAddress(keyword) {
    dispatch(lookupAddress(keyword, 'Delivery', entityRegion));
  }

  function handleDeliveryAddressChange(event, id) {
    const keyword = event.target.value;
    const processedTradingNamesOptions = { ...tradingNamesOptions };
    processedTradingNamesOptions[id].delivery_address_details = {
      full_address: event.target.value,
    };
    _setTradingNameOptions(processedTradingNamesOptions);
    if (keyword.length > 0) {
      event.persist();
      throttledLookupDeliveryAddress(keyword);
    }
  }

  function handleDeliveryAddressClick(value, id) {
    const processedTradingNamesOptions = { ...tradingNamesOptions };
    processedTradingNamesOptions[id].delivery_address_details =
      deliveryAddressData[value];
    _setTradingNameOptions(processedTradingNamesOptions);
    setValidationTrigger(true);
  }

  function setCurrentTradingNameDetails(key, event, id) {
    let value = event.target.value;
    if (key === 'contact_email') value = value.toLowerCase();
    const processedTradingNamesOptions = { ...tradingNamesOptions };
    processedTradingNamesOptions[id][key] = value;
    _setTradingNameOptions(processedTradingNamesOptions);
    setValidationTrigger(true);
  }

  function selectTradingName(id) {
    const selectedIds = toggleArrayValue(selectedTradingNameIds, id);
    setSelectedTradingNames(selectedIds);
  }

  function addNewTradingName() {
    // TODO: Consider using other method for new id generation
    const idPrefix = 'new_trading_name_';
    const tradingNamesOptionsKeys = Object.keys(tradingNamesOptions).filter(
      (key) => key.includes(idPrefix)
    );
    const tradingNamesOptionsKeyInts = tradingNamesOptionsKeys.map((key) =>
      parseInt(key.replace(idPrefix, ''))
    );
    const currentMaxId =
      tradingNamesOptionsKeyInts.length > 0
        ? tradingNamesOptionsKeyInts.reduce((a, b) => Math.max(a, b))
        : 0;
    const newTradingNameCount = currentMaxId + 1;
    const newTradingNameId = `${idPrefix}${newTradingNameCount}`;

    const processedTradingNamesOptions = {};

    processedTradingNamesOptions[newTradingNameId] = {
      deletable: true,
      delivery_address_details: {
        full_address: '',
      },
      editable: true,
      trading_name: '',
    };

    const tradingNamesOptions_ = modifyTradingNamesOptions({
      ...processedTradingNamesOptions,
      ...tradingNamesOptions,
    });

    _setTradingNameOptions(tradingNamesOptions_);
    selectTradingName(newTradingNameId);
    handleComplete(false);
  }

  function renderTradingOptions() {
    const options = Object.keys(props.tradingNamesOptions).map((key) => {
      const label = props.tradingNamesOptions[key].label;

      return {
        brief: label,
        label,
        value: key,
      };
    });

    const error = selectedTradingNameIds.length === 0;
    const errorMessage = 'You must select at least one trading name';

    return (
      <div className="columns is-multiline">
        <div className="column is-6">
          <SimpleMultiSelectDropdown
            label="Trading name"
            error={error ? errorMessage : ''}
            onChange={({ value }) => {
              setSelectedTradingNames(value);
            }}
            multiple
            options={options}
            required
            value={selectedTradingNameIds}
            variant="underlined"
          />
          <a onClick={addNewTradingName}>+ Add new trading entity</a>
        </div>
      </div>
    );
  }

  function renderInput(inputName, id) {
    const currentTradingName = tradingNamesOptions[id] || {};
    const type = formInputs[inputName].type;
    const selected = selectedTradingNameIds.includes(id);
    const { editable } = currentTradingName;
    const disabled = !selected || !editable;
    const textInputValue = currentTradingName[inputName];
    const displayError = selected;
    const fieldError = displayError && error[id] && error[id][inputName];

    switch (inputName) {
      case 'delivery_address_details': {
        const deliveryAddress =
          currentTradingName.delivery_address_details || physicalAddress;
        return (
          <AutoSuggest
            key={`delivery-address-details-${id}`}
            label={formInputs[inputName].label}
            value={deliveryAddress.full_address}
            loading={deliveryAddressLoading}
            suggest_items={deliveryAddressOptions}
            error={fieldError}
            handleChange={(e) => handleDeliveryAddressChange(e, id)}
            handleClick={(e) => handleDeliveryAddressClick(e, id)}
            required={formInputs[inputName].required}
            css_class={'address_lookup'}
            disabled={disabled}
          />
        );
      }
      case 'trading_name':
        return (
          <TextInput
            key={`trading-name-input-${id}`}
            type={type}
            label={formInputs[inputName].label}
            required={formInputs[inputName].required}
            value={textInputValue}
            handleChange={(e) => setCurrentTradingNameDetails(inputName, e, id)}
            disabled={disabled}
            error={fieldError}
          />
        );
      default:
        return (
          <TextInput
            key={`${type}-${id}`}
            type={type}
            label={formInputs[inputName].label}
            required={formInputs[inputName].required}
            value={textInputValue}
            handleChange={(e) => setCurrentTradingNameDetails(inputName, e, id)}
            disabled={disabled}
            error={fieldError}
          />
        );
    }
  }

  function renderInputs() {
    if (!tradingNamesOptions) {
      return null;
    }

    const tradingNameOptionsKeys = Object.keys(tradingNamesOptions);
    const displayDelete = selectedTradingNameIds.length > 1;

    return tradingNameOptionsKeys.map((id, index) => {
      const { label } = tradingNamesOptions[id];
      const selected = selectedTradingNameIds.includes(id);

      if (!selected) {
        return null;
      }

      return (
        <Section key={`trading-name-${index}-${id}`} unselected={!selected}>
          <div className="columns is-multiline">
            <div className="column is-12">
              <h3>{label} </h3>
              {displayDelete ? (
                <Delete
                  type="button"
                  className="delete is-medium"
                  onClick={() => selectTradingName(id)}
                />
              ) : (
                ''
              )}
            </div>
            {Object.keys(formInputs).map((key) => (
              <div className="column is-6" key={key}>
                <div className={styles.form}>{renderInput(key, id)}</div>
              </div>
            ))}
          </div>
        </Section>
      );
    });
  }

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <div className="mb-6">
        <Section>
          <PageHeader title="Trading entities" />
          {renderTradingOptions()}
        </Section>
        <PageHeader title="Trading details" />
        {renderInputs()}
      </div>
    </MuiThemeProvider>
  );
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
const formInputs = {
  trading_name: {
    defaultValueKey: 'companyName',
    label: 'Trading name',
    required: true,
    type: 'text',
  },
  contact_name: {
    defaultValueKey: 'consumerName',
    label: 'Contact person',
    required: true,
  },
  contact_email: {
    defaultValueKey: 'consumerEmail',
    label: 'Contact email address',
    required: true,
  },
  contact_phone_number: {
    defaultValueKey: 'entityPhone',
    label: 'Contact phone number',
    required: true,
    type: 'tel',
  },
  delivery_address_details: {
    defaultValueKey: 'physicalAddress',
    label: 'Delivery address',
    required: true,
  },
  delivery_instruction: {
    label: 'Delivery instructions',
    required: false,
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

const modifyTradingNamesOptions = (tradingNamesOptions) => {
  const result = Object.keys(tradingNamesOptions)
    .sort((left, right) => {
      const propLeft = tradingNamesOptions[left].trading_name;
      const propRight = tradingNamesOptions[right].trading_name;
      if (propLeft > propRight) {
        return 1;
      }
      if (propLeft < propRight) {
        return -1;
      }
      return 0;
    })
    .reduce((acc, key, index) => {
      acc[key] = {
        ...tradingNamesOptions[key],
        label: `Trading name ${index + 1}`,
      };
      return acc;
    }, {});
  return result;
};

export default connect((state) => {
  return {
    companyName: get(state, 'cob_business.company_details.name', ''),
    consumerEmail: get(
      state,
      'cob_section.application.attributes.consumer_contact_email',
      ''
    ),
    consumerName: get(
      state,
      'cob_section.application.attributes.consumer_contact_full_name',
      ''
    ),
    deliveryAddressData: state.cob_business.delivery_address_raw_list,
    deliveryAddressLoading: state.cob_business.delivery_address_loading,
    deliveryAddressOptions: state.cob_business.delivery_address_list,
    entityName: state.cob_business.entity_name,
    entityPhone: state.cob_business.entity_phone,
    entityRegion: state.cob_business.entity_region,
    physicalAddress: get(state, 'cob_business.physical_address', {}),
    selectedTradingNameIds: state.cob_section.selected_trading_names,
    tradingNamesOptions: modifyTradingNamesOptions(
      state.cob_section.trading_names || {}
    ),
  };
})(BusinessChildTradingName);
