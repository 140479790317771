import api from 'api';
import SideMenuPanel from 'containers/next-gen/components/shared/Panel';
import PanelSection from 'containers/next-gen/components/shared/Section';
import { capitalize } from 'lodash';
import get from 'lodash.get';
import Table from 'modules/shared/components/table/Table';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Switch3 from 'modules/shared/components/widgets/interactive/Switch3';
import mixpanel from 'mixpanel-browser';
import isPresent from 'utils/isPresent';
// @ts-ignore-next-line: TS is not able to find `Link` as an exported value of react-router but definitely there is
import { Link } from 'react-router';

const DATA_COLUMNS = [
  {
    Header: 'User',
    accessor: 'name',
    filter: 'text',
  },
  {
    Header: 'Email',
    accessor: 'email',
    disableFilters: true,
  },
  {
    Header: 'Tier',
    accessor: 'tier',
    disableFilters: true,
  },
  {
    Header: 'User type',
    accessor: 'roleTypes',
    disableFilters: true,
  },
];

const transformTierData = (attributes) => {
  const { tier, trading_name: tradingName } = attributes;
  if (tier === 'T1') {
    return tier;
  }

  return `${tier} - ${tradingName}`;
};

const transformNameData = (attributes) => {
  const { email, first_name: firstName, last_name: lastName } = attributes;

  const name = `${firstName || ''} ${lastName || ''}`.trim();
  if (isPresent(name)) {
    return name;
  }

  return email.split('@')[0];
};

const transformData = (attributes) => {
  const roleTypes = attributes.role_types
    .map((roleType) => capitalize(roleType))
    .join(', ');

  return {
    email: attributes.email,
    name: transformNameData(attributes),
    roleTypes,
    tier: transformTierData(attributes),
  };
};

const fetchRepChannel = async ({
  accessToken,
  entityId,
  fetchParams,
  successCallback,
  errorCallback,
}: {
  accessToken: string;
  entityId: string;
  setUsers: any;
  fetchParams?: any;
  successCallback?: (res: any) => void;
  errorCallback?: (res: any) => void;
}) => {
  const salesChannelsAPI = api('sales_channels', accessToken);

  try {
    const response = await salesChannelsAPI.fetchRepChannel(entityId, {
      params: fetchParams,
    });

    if (successCallback) {
      successCallback(response);
    }
  } catch (error) {
    if (errorCallback) {
      errorCallback(error);
    }
    console.error(error);
  }
};

const downloadBlob = (response) => {
  const url = URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', 'rep_channel.csv');

  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const RepChannelContent = ({
  currentUser,
  entityId,
}: {
  currentUser: any;
  entityId: string;
}): ReactElement => {
  const [users, setUsers] = useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [tableLoading, setTableLoading] = React.useState(false);

  useEffect(() => {
    if (entityId) {
      fetchData(loadData);
    }

    mixpanel.track('Sales channels - Rep channel', {
      'Entity ID': entityId,
      distinct_id: get(currentUser, 'data.data.id'),
    });
  }, []);

  const loadData = (response) => {
    const responseData = get(response, 'data.data', []);
    const { total } = get(response, 'data.meta', {});

    const transformedData = responseData.map((data) =>
      transformData(get(data, 'attributes', {}))
    );
    setUsers(transformedData);

    if (totalRecords !== total) {
      setTotalRecords(total);
    }
  };

  const fetchData = (
    successCallback: (res: any) => void,
    fetchParams?: any
  ) => {
    setTableLoading(true);
    (async () => {
      fetchRepChannel({
        accessToken: currentUser.accessToken,
        entityId,
        setUsers,
        successCallback: (res) => {
          successCallback(res);
          setTableLoading(false);
        },
        errorCallback: () => setTableLoading(false),
        fetchParams,
      });
    })();
  };

  const handleTableChange = React.useCallback((tableInfo) => {
    const params = {
      page: tableInfo.pageIndex + 1,
      per_page: tableInfo.pageSize,
      ...(tableInfo.searchFilter ? { keyword: tableInfo.searchFilter } : {}),
    };

    fetchData(loadData, params);
  }, []);

  // Table Download

  const downloadTableDataFn = async () => {
    const salesChannelsAPI = api('sales_channels', currentUser.accessToken);

    try {
      const response = await salesChannelsAPI.downloadRepChannel(entityId);
      downloadBlob(response);

      mixpanel.track('Sales channels - Rep channel - Download user list', {
        'Entity ID': entityId,
        distinct_id: get(currentUser, 'data.data.id'),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const DownloadTableData = () => (
    <span className="span-as-link" onClick={downloadTableDataFn}>
      Download user list
    </span>
  );

  return (
    <SideMenuPanel>
      <PanelSection>
        Your rep channel defaults to active as the core channel on your account.
        Below are the active users, which will automatically populate as you add
        them into{' '}
        <Link to="/dashboard/profile?active_tab=your_team">Your Team.</Link>{' '}
        Search users below to see where they are in your organisation.
        <div className="switch-container">
          <Switch3 label="On" value={true} isDisabled={true} />
        </div>
      </PanelSection>
      <PanelSection>
        <Table
          columns={DATA_COLUMNS}
          data={users}
          toolbarComponents={<DownloadTableData />}
          dataLabel="users"
          fetchData={handleTableChange}
          withBackendFilter
          totalRecords={totalRecords}
          loading={tableLoading}
        />
      </PanelSection>
    </SideMenuPanel>
  );
};

export default connect((state) => {
  const currentUser = get(state, 'current_user', {});
  const entityId = get(currentUser, 'current_entity.id');

  return {
    currentUser,
    entityId,
  };
})(RepChannelContent);
