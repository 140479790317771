/* eslint-disable max-lines */
import _ from 'lodash';
import AccordianContainer from 'modules/shared/components/containers/AccordianContainer';
import Button from 'modules/shared/components/inputs/Button';
import Radiobox from 'modules/shared/components/inputs/Radiobox';
import BackAndNextButtons from 'modules/shared/components/widgets/interactive/BackAndNextButtons';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';

import { setValue, updateAuthorisation } from '../actions';
import styles from './css/Section.css';
import ReviewCardholder from './ReviewCardholder';
import ReviewGuarantor from './ReviewGuarantor';
import ReviewPaperless from './ReviewPaperless';
import ReviewSignatory from './ReviewSignatory';

function useAccordionIndexState() {
  const [sectionIndex, setSectionIndex] = useState(null);

  const onClickSection = (selectedSectionIndex) => {
    let newIndex = selectedSectionIndex;
    if (newIndex === sectionIndex) {
      newIndex = null;
    }

    setSectionIndex(newIndex);
  };

  return { onClickSection, sectionIndex };
}

function ApprovedActionDescription(props) {
  const { consumerName, isApproved, role } = props;
  if (typeof isApproved !== 'boolean') {
    return null;
  }

  const actionTaken = isApproved ? 'accepted' : 'declined';

  return (
    <div className="has-text-primary">
      You have <span className="underlined">{actionTaken}</span> to be the{' '}
      {role} for {consumerName}.
    </div>
  );
}

function AgreeAction(props) {
  const {
    attributes,
    consumerName,
    isApplicationLocked,
    isApproved,
    onClickDecision,
    role,
  } = props;

  if (attributes.approved) {
    return <p>You have already approved</p>;
  }

  let approvedValue = null;
  if (typeof isApproved === 'boolean') {
    approvedValue = isApproved ? 'Accept' : 'Decline';
  }

  let content = null;
  if (!isApplicationLocked) {
    content = (
      <Radiobox
        id="approved"
        name="approved"
        label=""
        radioList={['Decline', 'Accept']}
        value={approvedValue}
        handleChange={onClickDecision}
      />
    );
  }

  return (
    <div>
      {content}
      <ApprovedActionDescription
        consumerName={consumerName}
        isApproved={isApproved}
        role={role}
      />
    </div>
  );
}
function ReviewGuarantorSection(props) {
  const {
    consumerName,
    guarantor,
    guarantorApproved,
    hasGuarantor,
    isApplicationLocked,
    key,
    label,
    onHandleChangeValue,
    supplierName,
    ...rest
  } = props;

  if (!hasGuarantor) {
    return null;
  }

  const { onClickSection, sectionIndex } = useAccordionIndexState();

  const onClickDecision = (event) => {
    onHandleChangeValue('guarantorApproved', event.target.value === 'Accept');
  };

  const sections = [
    <ReviewGuarantor
      consumerName={consumerName}
      data-accordianLabel={label}
      id={key}
      key={key}
      supplierName={supplierName}
      {...rest}
    />,
  ];

  return (
    <Fragment>
      <AccordianContainer
        type="review"
        subPages={sections}
        subsection_index={sectionIndex}
        handleClick={onClickSection}
      />

      <AgreeAction
        attributes={guarantor.attributes}
        consumerName={consumerName}
        isApplicationLocked={isApplicationLocked}
        isApproved={guarantorApproved}
        onClickDecision={onClickDecision}
        role="personal guarantor"
      />
    </Fragment>
  );
}

function ReviewCardholderSection(props) {
  const {
    cardholder,
    cardholderApproved,
    consumerName,
    hasCardholder,
    isApplicationLocked,
    key,
    label,
    onHandleChangeValue,
    supplierName,
    ...rest
  } = props;

  if (!hasCardholder) {
    return null;
  }

  const { onClickSection, sectionIndex } = useAccordionIndexState();

  const sections = [
    <ReviewCardholder
      consumerName={consumerName}
      data-accordianLabel={label}
      id={key}
      key={key}
      supplierName={supplierName}
      {...rest}
    />,
  ];

  const onClickDecision = (event) => {
    onHandleChangeValue('cardholderApproved', event.target.value === 'Accept');
  };

  return (
    <Fragment>
      <AccordianContainer
        type="review"
        subPages={sections}
        subsection_index={sectionIndex}
        handleClick={onClickSection}
      />

      <AgreeAction
        attributes={cardholder.attributes}
        consumerName={consumerName}
        isApplicationLocked={isApplicationLocked}
        isApproved={cardholderApproved}
        onClickDecision={onClickDecision}
        role="cardholder"
      />
    </Fragment>
  );
}

function ReviewPaperlessSection(props) {
  const {
    consumerName,
    hasPaperless,
    isApplicationLocked,
    key,
    label,
    onHandleChangeValue,
    paperless,
    paymentApproved,
    ...rest
  } = props;

  if (!hasPaperless) {
    return null;
  }

  const { onClickSection, sectionIndex } = useAccordionIndexState();

  const sections = [
    <ReviewPaperless
      data-accordianLabel={label.replace('consumer_trading_name', consumerName)}
      id={key}
      key={key}
      {...rest}
    />,
  ];

  const onClickDecision = (event) => {
    onHandleChangeValue('paymentApproved', event.target.value === 'Accept');
  };

  return (
    <Fragment>
      <AccordianContainer
        type="review"
        subPages={sections}
        subsection_index={sectionIndex}
        handleClick={onClickSection}
      />

      <AgreeAction
        attributes={paperless.attributes}
        consumerName={consumerName}
        isApplicationLocked={isApplicationLocked}
        isApproved={paymentApproved}
        onClickDecision={onClickDecision}
        role="direct debit signatory"
      />
    </Fragment>
  );
}

function ReviewSignatorySection(props) {
  const {
    consumerName,
    hasSignatory,
    isApplicationLocked,
    key,
    label,
    onHandleChangeValue,
    signatory,
    signatoryApproved,
    ...rest
  } = props;

  if (!hasSignatory) {
    return null;
  }

  const { onClickSection, sectionIndex } = useAccordionIndexState();

  const sections = [
    <ReviewSignatory
      data-accordianLabel={label}
      id={key}
      key={key}
      consumerName={consumerName}
      {...rest}
    />,
  ];

  const onClickDecision = (event) => {
    onHandleChangeValue('signatoryApproved', event.target.value === 'Accept');
  };

  return (
    <Fragment>
      <AccordianContainer
        type="review"
        subPages={sections}
        subsection_index={sectionIndex}
        handleClick={onClickSection}
      />

      <AgreeAction
        attributes={signatory.attributes}
        consumerName={consumerName}
        isApplicationLocked={isApplicationLocked}
        isApproved={signatoryApproved}
        onClickDecision={onClickDecision}
        role="signatory"
      />
    </Fragment>
  );
}

class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerIndex: null,
      errors: '',
    };
  }

  declineAll() {
    const {
      dispatch,
      hasCardholder,
      hasGuarantor,
      hasPaperless,
      hasSignatory,
      params,
      toLastSection,
    } = this.props;
    if (hasGuarantor) this.onHandleChangeValue('guarantorApproved', false);
    if (hasCardholder) this.onHandleChangeValue('cardholderApproved', false);
    if (hasPaperless) this.onHandleChangeValue('paymentApproved', false);
    if (hasSignatory) this.onHandleChangeValue('signatoryApproved', false);

    dispatch(
      updateAuthorisation(params.authorisation_id, () => {
        toLastSection();
      })
    );
  }

  handleNext() {
    const {
      authorisation,
      hasCardholder,
      hasGuarantor,
      hasPaperless,
      hasSignatory,
      toNextSection,
    } = this.props;
    if (
      (hasGuarantor && typeof authorisation.guarantorApproved !== 'boolean') ||
      (hasCardholder &&
        typeof authorisation.cardholderApproved !== 'boolean') ||
      (hasSignatory && typeof authorisation.signatoryApproved !== 'boolean') ||
      (hasPaperless && typeof authorisation.paymentApproved !== 'boolean')
    ) {
      this.setState({ errors: "Please click 'Accept' or 'Decline' on below" });
      return false;
    }

    this.setState({ errors: '' });

    const approved =
      authorisation.guarantorApproved ||
      authorisation.cardholderApproved ||
      authorisation.paymentApproved ||
      authorisation.signatoryApproved;

    if (approved) {
      toNextSection();
    } else {
      this.declineAll();
    }
  }

  onHandleChangeValue(key, value) {
    const { dispatch } = this.props;
    dispatch(setValue(key, value));
  }

  updateContainerIndex(reviewContainerIndex) {
    const { containerIndex } = this.state;
    let index = reviewContainerIndex;
    if (containerIndex === index) {
      index = null;
    }
    this.setState({ containerIndex: index });
  }

  render() {
    const {
      allApproved,
      isApplicationLocked,
      options,
      toPreviousSection,
      updating,
      ...rest
    } = this.props;
    const { errors } = this.state;

    return (
      <div>
        <div className={styles.container}>
          <section className={styles.section}>
            <div className={styles.row}>
              <div className={styles.panel}>
                <PanelTitle text="Review details" />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.error}>{errors}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.panel}>
                <ReviewGuarantorSection
                  isApplicationLocked={isApplicationLocked}
                  label={options['guarantor'].label}
                  onHandleChangeValue={(key, value) =>
                    this.onHandleChangeValue(key, value)
                  }
                  {...rest}
                />
                <ReviewSignatorySection
                  isApplicationLocked={isApplicationLocked}
                  label={options['signatory'].label}
                  onHandleChangeValue={(key, value) =>
                    this.onHandleChangeValue(key, value)
                  }
                  {...rest}
                />
                <ReviewCardholderSection
                  isApplicationLocked={isApplicationLocked}
                  label={options['cardholder'].label}
                  onHandleChangeValue={(key, value) =>
                    this.onHandleChangeValue(key, value)
                  }
                  {...rest}
                />
                <ReviewPaperlessSection
                  isApplicationLocked={isApplicationLocked}
                  label={options['paperless'].label}
                  onHandleChangeValue={(key, value) =>
                    this.onHandleChangeValue(key, value)
                  }
                  {...rest}
                />
              </div>
            </div>
          </section>
        </div>
        {!updating && (
          <BackAndNextButtons
            onNextClick={() => this.handleNext()}
            onBackClick={toPreviousSection}
            hideNext={allApproved || isApplicationLocked}
            hideBack={true}
          />
        )}
      </div>
    );
  }
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
const defaults = {
  options: {
    cardholder: {
      component: ReviewCardholder,
      label: 'Cardholder',
    },
    guarantor: {
      component: ReviewGuarantor,
      label: 'Personal guarantor',
    },
    paperless: {
      component: ReviewPaperless,
      label: 'Signatory for consumer_trading_name bank account',
    },
    signatory: {
      component: ReviewSignatory,
      label: 'Signatory of application',
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

export default connect((state, ownProrps) => {
  const authorisation = state.authorisation;
  const options = _.cloneDeep(defaults.options);
  const isGuarantorAlreadyApproved = ownProrps.hasGuarantor
    ? !!authorisation.guarantor.attributes.approved
    : false;
  const isCardholderAlreadyApproved = ownProrps.hasCardholder
    ? !!authorisation.cardholder.attributes.approved
    : false;
  const isPaperlessAlreadyApproved = ownProrps.hasPaperless
    ? !!authorisation.paperless.attributes.approved
    : false;
  const isSignatoryAlreadyApproved = ownProrps.hasSignatory
    ? !!authorisation.signatory.attributes.approved
    : false;

  if (isGuarantorAlreadyApproved) {
    options.guarantor.label = `${defaults.options.guarantor.label} (Approved)`;
  }
  if (isCardholderAlreadyApproved) {
    options.cardholder.label = `${defaults.options.cardholder.label} (Approved)`;
  }
  if (isPaperlessAlreadyApproved) {
    options.paperless.label = `${defaults.options.paperless.label} (Approved)`;
  }
  if (isSignatoryAlreadyApproved) {
    options.signatory.label = `${defaults.options.signatory.label} (Approved)`;
  }

  const anyApproved =
    (ownProrps.hasGuarantor && isGuarantorAlreadyApproved) ||
    (ownProrps.hasCardholder && isCardholderAlreadyApproved) ||
    (ownProrps.hasPaperless && isPaperlessAlreadyApproved) ||
    (ownProrps.hasSignatory && isSignatoryAlreadyApproved);

  const allApproved =
    (ownProrps.hasGuarantor ? isGuarantorAlreadyApproved : true) &&
    (ownProrps.hasCardholder ? isCardholderAlreadyApproved : true) &&
    (ownProrps.hasPaperless ? isPaperlessAlreadyApproved : true) &&
    (ownProrps.hasSignatory ? isSignatoryAlreadyApproved : true);

  return {
    allApproved,
    anyApproved,
    authorisation,
    cardholder: state.authorisation.cardholder,
    cardholderApproved: state.authorisation.cardholderApproved,
    containerIndex: state.authorisation.reviewContainerIndex,
    guarantor: state.authorisation.guarantor,
    guarantorApproved: state.authorisation.guarantorApproved,
    options,
    paperless: state.authorisation.paperless,
    paymentApproved: state.authorisation.paymentApproved,
    signatory: state.authorisation.signatory,
    signatoryApproved: state.authorisation.signatoryApproved,
    updating: state.authorisation.updating,
  };
})(Review);
