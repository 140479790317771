/* eslint-disable max-classes-per-file */
/**
 * formatMoney(number, n, x, s, c)
 *
 * @param number number: number to format
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
/* eslint-disable max-params */
// Fix maximum number of params
export function formatMoney(number, n, x, s, c) {
  const DEFAULT_X = 3;

  /* eslint-disable prefer-template */
  /* eslint-disable no-bitwise */
  // Fix `re` assignment to use string template
  // Fix `~~n` bitwise notation
  const re =
    '\\d(?=(\\d{' + (x || DEFAULT_X) + '})+' + (n > 0 ? '\\D' : '$') + ')';
  const num = number.toFixed(Math.max(0, ~~n));
  /* eslint-disable prefer-template */
  /* eslint-enable no-bitwise */

  return (c ? num.replace('.', c) : num).replace(
    new RegExp(re, 'g'),
    '$&' + (s || ',')
  );
}
/* eslint-enable max-params */
export function displayEmpty(value, replacement = '-') {
  if (value === null || typeof value === 'undefined') return replacement;
  if (value.length === 0) return replacement;

  return value;
}

export function capitalize(str) {
  return str.replace(/\b[a-z]/g, (f) => f.toUpperCase());
}

export function capitalizeSentence(str) {
  if (typeof str === 'string') {
    return str
      .toLowerCase()
      .replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());
  }
  return '';
}

export function ynToYesNo(value) {
  const ynMapping = { n: 'NO', y: 'YES' };

  return displayEmpty(ynMapping[value.toLowerCase()]);
}
/* eslint-enable max-classes-per-file */
