/* Import libraries */
import React, { Component } from 'react';
import Clickable from 'modules/shared/components/widgets/interactive/Clickable';
import Collapse from '@material-ui/core/Collapse';
import Dot from 'modules/shared/components/widgets/static/Dot';
import Icon from 'modules/shared/components/svg/Icon';
import { connect } from 'react-redux';
import { formatMoney } from 'utils/formatting';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import { loadCurrentEntity } from 'modules/user/actions';
import styles from './css/AddOnsListItem.css';
import { updateAddonRuleset } from '../actions';
import { formatLocalTime } from 'utils/dateFormatter';
import { getConflictingRulesetNames } from '../helpers';

class AddOnsListItem extends Component {
  //todo: should be improved depends on addOn structure
  constructor(props) {
    super(props);
    this.state = {
      historyOpen: false,
      showEditMenu: false,
      showConflictError: false,
    };
  }

  showEditMenu() {
    this.setState({ showEditMenu: true });
  }

  hideEditMenu() {
    this.setState({ showEditMenu: false });
  }

  handleCollapseToggle() {
    const { historyOpen } = this.state;
    this.setState({ historyOpen: !historyOpen });
  }

  toggleRuleSet() {
    const {
      dispatch,
      currentAddonData,
      addonType,
      rulesetConflicts,
    } = this.props;

    if (rulesetConflicts && !currentAddonData.attributes.active) {
      this.setState({ showConflictError: true });
      return;
    }
    if (addonType === 'financials') {
      dispatch(
        updateAddonRuleset(
          currentAddonData.id,
          currentAddonData.attributes,
          addonType,
          dispatch(loadCurrentEntity())
        )
      );
    } else {
      dispatch(
        updateAddonRuleset(
          currentAddonData.id,
          currentAddonData.attributes,
          addonType
        )
      );
    }
  }

  renderHistory() {
    const { addonVersions, currentAddonData, viewHistory } = this.props;
    const { historyOpen } = this.state;

    return (
      <Collapse
        in={historyOpen}
        classes={{ container: styles['collapse-container'] }}
      >
        {addonVersions.map((addonVersion, index) => (
          <div
            key={`addon_version_${index}`}
            className={styles['history-item']}
            onClick={() => {
              viewHistory(
                currentAddonData.attributes.serial_number,
                addonVersion
              );
            }}
          >
            <div>
              {addonVersion.attributes.name} (Version{' '}
              {addonVersion.attributes.version})
            </div>
            <div>
              {addonVersion.attributes.editor_first_name}{' '}
              {formatLocalTime(addonVersion.attributes.created_at, 'minute')}
            </div>
          </div>
        ))}
      </Collapse>
    );
  }

  render() {
    const {
      addonType,
      currentAddonData,
      addonVersions,
      onEdit,
      disabled,
      rulesetConflicts,
    } = this.props;
    const { historyOpen, showConflictError } = this.state;

    const latestConfig = addonVersions[0].attributes;
    const status = currentAddonData.attributes.active
      ? statusConfig.active
      : statusConfig.paused;

    let iconChevronStyle = `${styles['collapse-arrow']}`;
    if (historyOpen) {
      iconChevronStyle = `${styles['collapse-arrow']} ${
        styles['collapse-arrow-open']
      }`;
    }

    // TODO - Update condition if application_types are apply to other addonType;
    const isCreditApplication = (latestConfig.application_types || []).includes(
      'credit'
    );

    const displayCreditLimit =
      addonType !== 'internal_use_fields' &&
      ((addonType === 'additional' && isCreditApplication) ||
        addonType !== 'additional');

    const isPaperlessCashApplication =
      addonType === 'paperless' &&
      (latestConfig.application_types || []).includes('cash') &&
      !isCreditApplication;

    return (
      <li
        className={styles.item}
        id="item"
        onClick={(e) => {
          if (e.target.id.includes('item')) {
            onEdit(
              currentAddonData.attributes.serial_number,
              currentAddonData.attributes.history_version.data[0]
            );
          }
        }}
      >
        {isMobile() && this.renderHistory()}
        <div className={styles.header}>
          <div className={styles.details}>
            <div id="item-name" className={styles.name_input}>
              #{currentAddonData.attributes.serial_number} {latestConfig.name}
              {!displayCreditLimit && (
                <span>&nbsp; (Version {latestConfig.version})</span>
              )}
              &nbsp;&nbsp;&nbsp;
              <Dot color={status.color} text={status.text} />
            </div>
            {displayCreditLimit && (
              <div id="item-info" className={styles.info_container}>
                <span id="item-limit" className={styles.details_text}>
                  Limit:{' '}
                  {isPaperlessCashApplication
                    ? 'N/A'
                    : `$${formatMoney(
                        parseFloat(latestConfig.min_credit_value)
                      )} - $${formatMoney(
                        parseFloat(latestConfig.max_credit_value)
                      )}`}
                  &nbsp; (Version {latestConfig.version})
                </span>
              </div>
            )}
            <div id="item-info" className={styles.info_container}>
              <span id="item-limit" className={styles.details_text}>
                Last edited by {latestConfig.editor_first_name} at{' '}
                {formatLocalTime(latestConfig.created_at, 'minute')}
              </span>
            </div>
            {showConflictError && (
              <div id="item-info" className={styles.info_container}>
                <span id="item-limit" className={styles.details_text_error}>
                  {`Unable to activate ruleset, conflicts with: ${getConflictingRulesetNames(
                    rulesetConflicts
                  ).join(', ')}.`}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.options}>
          <div
            className={[
              styles.edit_menu,
              !this.state.showEditMenu && styles.hide,
            ].join(' ')}
            onPointerEnter={this.showEditMenu.bind(this)}
            onPointerLeave={this.hideEditMenu.bind(this)}
          >
            <Icon icon="more_vert" />
            <ul>
              <Clickable
                onClick={() =>
                  onEdit(
                    currentAddonData.attributes.serial_number,
                    currentAddonData.attributes.history_version.data[0]
                  )
                }
              >
                {disabled ? 'view' : 'edit'}
              </Clickable>
              {status.btnText && !disabled && (
                <Clickable onClick={() => this.toggleRuleSet()}>
                  {status.btnText}
                </Clickable>
              )}
            </ul>
          </div>
          <img
            onClick={() => {
              this.handleCollapseToggle();
            }}
            src={require('images/icon_chevron_down-white.svg')}
            className={iconChevronStyle}
          />
        </div>
        {!isMobile() && this.renderHistory()}
      </li>
    );
  }
}

const statusConfig = {
  active: {
    btnText: 'pause',
    color: 'blue',
    text: 'active',
  },
  paused: {
    btnText: 'enable',
    color: 'red',
    text: 'paused',
  },
};

export default connect(() => {
  return {};
})(AddOnsListItem);
