import SimpleMultiSelectDropdown from 'modules/shared/components/widgets/interactive/SimpleMultiSelectDropdown';
import React, { ReactElement } from 'react';

import { SelectDropdownProps } from './types';

const accountOptions = [
  { brief: 'Credit', label: 'Credit', value: 'credit' },
  { brief: 'Cash', label: 'Cash', value: 'cash' },
  { brief: 'Leads', label: 'Leads', value: 'leads' },
];

function AccountTypeSelectDropdown(props: SelectDropdownProps): ReactElement {
  const { handleChange, options } = props;

  return (
    <SimpleMultiSelectDropdown
      options={options || accountOptions}
      onChange={handleChange}
      id="entity-type-select"
      {...props}
    />
  );
}

export default AccountTypeSelectDropdown;
