import api from '../../api';
import { loadingLiveChat, hideLiveChat } from 'utils/liveChat.js';
import {
  PRICING_PLAN_LIST_LOAD_START,
  PRICING_PLAN_LIST_LOAD_SUCCESS,
  PRICING_PLAN_LIST_LOAD_ERROR,
  PRICING_PLAN_SET_SELECTED_PLAN,
  PRICING_PLAN_LOAD_START,
  PRICING_PLAN_LOAD_SUCCESS,
  PRICING_PLAN_LOAD_ERROR,
  PRICING_PLAN_UPDATE_CURRENT_PLAN_START,
  PRICING_PLAN_UPDATE_CURRENT_PLAN_SUCCESS,
  PRICING_PLAN_UPDATE_CURRENT_PLAN_ERROR,
  PRICING_PLAN_SHOW,
} from './constants';
import get from 'lodash.get';

export function getPricingPlans() {
  return (dispatch, getState) => {
    dispatch({
      type: PRICING_PLAN_LIST_LOAD_START,
    });
    const pricing_plans = api(
      'pricing_plans',
      getState().current_user.access_token
    );
    pricing_plans.getPricingPlans(
      (result) => {
        dispatch({
          type: PRICING_PLAN_LIST_LOAD_SUCCESS,
          payload: result.data.data,
        });
      },
      (error) => {
        dispatch({
          type: PRICING_PLAN_LIST_LOAD_ERROR,
          payload: error,
        });
      }
    );
  };
}

export function setSelectedPlan(value) {
  return {
    type: PRICING_PLAN_SET_SELECTED_PLAN,
    payload: value,
  };
}

export function getCurrentPricingPlan(entity_id, callback) {
  return (dispatch, getState) => {
    dispatch({
      type: PRICING_PLAN_LOAD_START,
    });

    if (!entity_id) {
      entity_id = getState().current_user.current_entity.id;
    }
    var entities = api(
      'entities',
      getState().current_user.access_token,
      entity_id
    );
    entities.getCurrentPricingPlan(
      entity_id,
      (result) => {
        if (result.data.data) {
          dispatch({
            type: PRICING_PLAN_LOAD_SUCCESS,
            payload: result.data.data,
          });
          if (get(result, 'data.data.attributes.live_chat')) {
            const userAttributes = {
              id: getState().current_user.data.data.id,
              firstName: getState().current_user.data.data.attributes
                .first_name,
              lastName: getState().current_user.data.data.attributes.last_name,
              email: getState().current_user.data.data.attributes.email,
              restoreId: getState().current_user.data.data.attributes
                .freshchat_restore_id,
            };
            if (!window.fcWidget || !window.fcWidget.isLoaded()) {
              loadingLiveChat('supplier', userAttributes);
            }
          } else {
            hideLiveChat();
          }
        }
        if (callback) {
          callback(result.data.data);
        }
      },
      (error) => {
        console.error(error);
        dispatch({
          type: PRICING_PLAN_LOAD_ERROR,
          payload: error,
        });
      }
    );
  };
}

export function upgradeCurrentPlan(plan) {
  return (dispatch, getState) => {
    dispatch({
      type: PRICING_PLAN_UPDATE_CURRENT_PLAN_START,
    });

    let entity_plans_id = getState().pricing_plans.current_pricing_plan.id;
    let plan_attr = plan ? plan.attributes : { plan_no: 0 };

    const pricing_plans = api(
      'pricing_plans',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    pricing_plans.upgrade(
      entity_plans_id,
      plan_attr,
      (result) => {
        dispatch({
          type: PRICING_PLAN_UPDATE_CURRENT_PLAN_SUCCESS,
          payload: result.data.data,
          meta: {
            mixpanel: {
              event: 'Pricing plan upgraded',
              props: {
                distinct_id: getState().current_user.data.data.id,
                'Trading name': getState().current_user.current_entity
                  .attributes.trading_name,
                'Current plan': getState().pricing_plans.current_pricing_plan
                  .attributes.plan_no,
                'Updated plan': plan_attr.plan_no,
                'Entity ID': getState().current_user.current_entity.id,
              },
            },
          },
        });

        dispatch(getCurrentPricingPlan());
      },
      (error) => {
        dispatch({
          type: PRICING_PLAN_UPDATE_CURRENT_PLAN_ERROR,
          payload: error,
        });
      }
    );
  };
}

export function downgradeCurrentPlan(plan) {
  return (dispatch, getState) => {
    dispatch({
      type: PRICING_PLAN_UPDATE_CURRENT_PLAN_START,
    });

    let entity_plans_id = getState().pricing_plans.current_pricing_plan.id;
    let plan_attr = plan ? plan.attributes : { plan_no: 0 };

    const pricing_plans = api(
      'pricing_plans',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    pricing_plans.downgrade(
      entity_plans_id,
      plan_attr,
      (result) => {
        dispatch({
          type: PRICING_PLAN_UPDATE_CURRENT_PLAN_SUCCESS,
          payload: result.data.data,
          meta: {
            mixpanel: {
              event: 'Pricing plan downgraded',
              props: {
                distinct_id: getState().current_user.data.data.id,
                'Trading name': getState().current_user.current_entity
                  .attributes.trading_name,
                'Current plan': getState().pricing_plans.current_pricing_plan
                  .attributes.plan_no,
                'downgraded plan': plan_attr.plan_no,
                'Entity ID': getState().current_user.current_entity.id,
              },
            },
          },
        });

        dispatch(getCurrentPricingPlan());
      },
      (error) => {
        dispatch({
          type: PRICING_PLAN_UPDATE_CURRENT_PLAN_ERROR,
          payload: error,
        });
      }
    );
  };
}

export function showPricingPlan(value, type, redirect) {
  return {
    type: PRICING_PLAN_SHOW,
    payload: {
      show: value,
      type: type ? type : null,
      redirect: redirect ? redirect : null,
    },
  };
}
