/* Import libraries */
import get from "lodash.get";
import { setNewConsumerInviteFormValue } from "modules/consumer-invite/actions/invite";
/* Import CSS */
import SupplierFormRender from "modules/shared/components/widgets/interactive/form_builder/SupplierFormRender";
import { Component, FormBuilderRenderParams } from "modules/shared/components/widgets/interactive/form_builder/types";
import { activeIUF, IUFAnswers, updatedIUFAnswers } from "modules/shared/helpers/internalUseFieldsHelper";
import { ApplicationType } from "modules/shared/types/application";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  SectionTitle,
  SectionWrapper,
} from "modules/consumer-invite/v2/ConsumerInvite/FullPage/styles";
import { Section, Title } from "./styles";

type Props = {
  application_type: ApplicationType,
  consumerIUFAnswers: IUFAnswers,
  dispatch: any,
}

function InternalFields(props: Props) {
  const { application_type, consumerIUFAnswers, dispatch } = props;
  const activeAddonConfig = activeIUF(application_type);
  const { account_level, pricing } = activeAddonConfig?.latestVersion.config;

  useEffect(() => {
    if (!get(consumerIUFAnswers, "addon_config_id")) {
      dispatch(
        setNewConsumerInviteFormValue("consumer_iuf_answers", {
          ...consumerIUFAnswers,
          addon_version_id: activeAddonConfig?.latestVersion.id,
        })
      )
    }
  }, []);

  function handleChange(
    key: "account_level" | "pricing",
    params: FormBuilderRenderParams,
  ) {
    const updatedAnswers = updatedIUFAnswers({
      iufAnswers: consumerIUFAnswers,
      key,
      params,
    })

    dispatch(setNewConsumerInviteFormValue("consumer_iuf_answers", updatedAnswers));
  }

  function processedComponents(components: Component[]) {
    // Display fields which applied to search and send.
    // Override field mandatory from search_send_mandatory
    const filteredComponents = components.filter(({ search_send_applied }) => search_send_applied);
    return filteredComponents.map(component => {
      return {
        ...component,
        mandatory: component.search_send_mandatory,
      }
    })
  }

  const accountLevelComponents = processedComponents(
    get(account_level, "components", [])
  );

  const pricingComponents = processedComponents(
    get(pricing, "components", [])
  );

  return (
    <SectionWrapper>
      <SectionTitle>Internal use fields (IUFs)</SectionTitle>
      <div>
        Please note that you are required to complete any mandatory IUFs
        prior to sending out credit applications.
      </div>
      <Section nfc_title_pTop="30px">
        { accountLevelComponents.length > 0 && (
          <div className="columns">
            <div className="column">
              <SupplierFormRender
                borderedStyle={true}
                components={accountLevelComponents}
                onChange={params => handleChange("account_level", params)}
                reduxKey="iufAccountLevel"
              />
            </div>
          </div>
        )}
        { pricingComponents.length > 0 && (
          <div className="columns">
            <div className="column">
              <Title>
                Pricing
              </Title>
              <SupplierFormRender
                borderedStyle={true}
                components={pricingComponents}
                onChange={params => handleChange("pricing", params)}
                reduxKey="iufPricing"
              />
            </div>
          </div>
        )}
      </Section>
    </SectionWrapper>
  );
}

export default connect(state => {
  return {
    consumerIUFAnswers: get(state, "cns_invite.iuf_answers") || {},
  };
})(InternalFields);
