import bankValidator from 'nz-bank-account-validator/lib/NZ-Bank-Account-Validator';
import * as yup from 'yup';

const newZealandValidationSchema = {
  bank_account_number: yup
    .string()
    .required('Please input a bank account number.')
    .test(
      'bankAccountNumber',
      'Please input a valid number like (XX-XXXX-XXXXXXX-XXX)',
      (value) => bankValidator.validate(value)
    )
    .trim(),
};

export default newZealandValidationSchema;
