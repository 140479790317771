import get from 'lodash.get';
import ApplicationInaccessible from 'modules/consumer-onboarding/v2/ApplicationInaccessible';
import { INACCESSIBLE_MESSAGE } from 'modules/consumer-onboarding/v2/hooks/useApplicationAccessibilityState';
import { GridContainer } from 'modules/request-permission-flow/containers/Layout/styles';
import ApplicationOverlay from 'modules/shared/components/top/ApplicationOverlay';
import Overlay from 'modules/shared/components/top/Overlay';
import Footer from 'modules/shared/components/v2/Footer';
import Loader from 'modules/shared/components/widgets/static/Loader';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import getApplicableAntiFraudRulesForAuthorisationFlow from 'utils/anti-fraud/getApplicableAntiFraudRulesForAuthorisationFlow';
import extractAttachmentUrl from 'utils/extractAttachmentUrl';
import isPresent from 'utils/isPresent';

import { authorisationLogin, loadAuthorisation } from '../actions';
import { componentFactory } from '../index';
import styles from './css/AuthorisationOnBoarding.css';

class AuthorisationOnBoarding extends Component {
  state = {
    currentSectionIndex: 0,
    showLoader: true,
  };

  componentDidMount() {
    const {
      dispatch,
      location: {
        query: { t },
      },
      params,
    } = this.props;
    if (params.authorisation_id && t) {
      dispatch(
        authorisationLogin(params.authorisation_id, t, () => {
          dispatch(loadAuthorisation(params.authorisation_id));
        })
      );
    }
  }

  redirect(sectionNameIndex) {
    const { sections, params, location } = this.props;
    browserHistory.push({
      pathname: `/authorisation/${params.authorisation_id}/${sections[
        sectionNameIndex
      ].replace('_', '-')}`,
      query: location.query,
    });
  }

  toNextSection() {
    const currentSectionIndex = this.state.currentSectionIndex + 1;
    this.setState({ currentSectionIndex });
    this.redirect(currentSectionIndex);
  }

  toPreviousSection() {
    const currentSectionIndex = this.state.currentSectionIndex - 1;
    this.setState({ currentSectionIndex });
    this.redirect(currentSectionIndex);
  }

  toLastSection() {
    const { sections } = this.props;
    const currentSectionIndex = sections.length - 1;
    this.setState({ currentSectionIndex });
    this.redirect(currentSectionIndex);
  }

  sectionProps() {
    const {
      consumerName,
      hasCardholder,
      hasGuarantor,
      hasPaperless,
      hasSignatory,
      requireProofOfAddress,
      params,
      supplierName,
    } = this.props;

    return {
      consumerName,
      hasCardholder,
      hasGuarantor,
      hasPaperless,
      hasSignatory,
      params,
      requireProofOfAddress,
      supplierName,
      toLastSection: this.toLastSection.bind(this),
      toNextSection: this.toNextSection.bind(this),
      toPreviousSection: this.toPreviousSection.bind(this),
    };
  }

  loadingComplete() {
    this.setState({ showLoader: false });
  }

  render() {
    const {
      authorisation,
      sections,
      loading,
      supplierName,
      logoUrl,
    } = this.props;
    const { showLoader } = this.state;
    const childComponent = componentFactory(
      sections[this.state.currentSectionIndex],
      this.sectionProps()
    );

    const applicationInaccessibleReason = get(
      authorisation,
      'application.attributes.inaccessible_reason'
    );

    if (showLoader) {
      return (
        <Loader
          message="Please wait while we load your progress."
          loading={loading || !authorisation}
          handleComplete={() => this.loadingComplete()}
        />
      );
    }

    if (isPresent(applicationInaccessibleReason)) {
      return (
        <GridContainer>
          <Overlay supplierLogoUrl={logoUrl} withButtons={false} />
          <ApplicationInaccessible
            header="Application inaccessible"
            message={`${INACCESSIBLE_MESSAGE[applicationInaccessibleReason]} Please directly get in touch with ${supplierName} to discuss any questions you may have.`}
          />
          <Footer />
        </GridContainer>
      );
    }

    let overlay = (
      <ApplicationOverlay
        trading_name={supplierName}
        logo_url={logoUrl}
        label={'Authorisation'}
        color={'xlight'}
      />
    );

    if (sections[this.state.currentSectionIndex] === 'complete') {
      overlay = null;
    }

    return (
      <div>
        <div className={styles.controls}>{overlay}</div>
        <div className={styles.page}>
          <div className={styles.container}>
            <div className={styles.application_content}>{childComponent}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  const sections = [
    'abstract',
    'identity_details',
    'terms',
    'confirm',
    'complete',
  ];

  const antiFraudCategoryRules = getApplicableAntiFraudRulesForAuthorisationFlow(
    state.authorisation
  );

  if (!antiFraudCategoryRules.requiresIdentificationSection) {
    const indexOfIdentity = sections.indexOf('identity_details');
    sections.splice(indexOfIdentity, 1);
  }

  const logo = extractAttachmentUrl(
    get(state, 'authorisation.data.supplier.attributes.logo', {}),
    'logo'
  );
  const requireProofOfAddress =
    state.authorisation.data &&
    state.authorisation.data.authorisation.attributes.require_proof_of_address;

  return {
    authorisation: state.authorisation.data,
    consumerName: state.authorisation.consumerName,
    hasCardholder: state.authorisation.hasCardholder,
    hasGuarantor: state.authorisation.hasGuarantor,
    hasPaperless: state.authorisation.hasPaperless,
    hasSignatory: state.authorisation.hasSignatory,
    loading: state.authorisation.loading,
    logoUrl: logo.url,
    requireProofOfAddress,
    sections,
    supplierName: state.authorisation.supplierName,
  };
})(AuthorisationOnBoarding);
