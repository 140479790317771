import get from 'lodash.get';
import { onToggleSidebar } from 'modules/shared/components/top/Overlay/redux/actions';
import React from 'react';
import { connect } from 'react-redux';

import { NavButton } from './styles';

const RoundButton = (props) => {
  const { activeSidebar, dispatch, label, sideBarName, forcedActive } = props;

  return (
    <NavButton
      type="button"
      className={`button is-rounded is-outlined ${
        activeSidebar === sideBarName || forcedActive ? 'active-button' : ''
      }`}
      onClick={() => dispatch(onToggleSidebar(sideBarName))}
    >
      {label}
    </NavButton>
  );
};

export default connect((state) => {
  return {
    activeSidebar: get(state, 'applicationOverlayReducer.activeSidebar', ''),
  };
})(RoundButton);
