import IAdditionalFields from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRForm/NZPPSRCollateralAdditionalForms/CollateralsAdditionalForms/MVCollateralDetailsForm/Types/IAdditionalFields';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import React, { ReactElement } from 'react';

const CollateralItemTextField = (props: IAdditionalFields): ReactElement => {
  const { error, field, fieldName, label, placeholder, register } = props;

  return (
    <BorderedTextField
      defaultValue={field[fieldName]}
      error={Boolean(error)}
      helperText={(error || { message: ' ' }).message}
      inputRef={register()}
      label={label}
      name={fieldName}
      placeholder={placeholder}
      InputLabelProps={{ shrink: true }}
    />
  );
};

export default CollateralItemTextField;
