import { resetIdentityDetails } from 'modules/identity/actions';

import {
  COB_GUARANTOR_COMPONENT_COMPLETE,
  COB_GUARANTOR_PRESET_DATA,
  COB_GUARANTOR_SECTION_COMPLETE,
  COB_GUARANTOR_SET_GUARANTOR,
  COB_GUARANTOR_TOGGLE_IS_APPLICANT,
} from '../constants/guarantors';

export function setGuarantorFormItem(index, id, value) {
  return (dispatch, getState) => {
    dispatch({
      payload: {
        id,
        index,
        value,
      },
      type: COB_GUARANTOR_SET_GUARANTOR,
    });
  };
}

export function guarantorsComplete(complete) {
  return (dispatch, getState) => {
    const section_state = getState().cob_guarantors;

    const data = {
      guarantors: section_state.form_values,
    };

    dispatch({
      payload: {
        complete,
        data,
      },
      type: COB_GUARANTOR_SECTION_COMPLETE,
    });
  };
}

export function guarantorComponentComplete(component, state) {
  return (dispatch, getState) => {
    let meta = {};

    if (state === true) {
      meta = {
        mixpanel: {
          event: 'Consumer onboarding',
          props: {
            Component: component || 'start',
            Section: 'guarantors',
            distinct_id: getState().current_user.data.data.id,
          },
        },
      };
    }

    dispatch({
      meta,
      payload: {
        cob_guarantors: getState().cob_guarantors,
        component,
        state,
      },
      type: COB_GUARANTOR_COMPONENT_COMPLETE,
    });
  };
}

export function preSetGuarantorData(value) {
  return (dispatch, getState) => {
    dispatch({
      payload: value,
      type: COB_GUARANTOR_PRESET_DATA,
    });
  };
}

export function toggleGuarantorIsApplicant(index, is_applicant, guarantor) {
  return (dispatch, getState) => {
    dispatch({
      payload: {
        guarantor,
        index,
        is_applicant,
      },
      type: COB_GUARANTOR_TOGGLE_IS_APPLICANT,
    });

    resetIdentityDetails({
      dispatch,
      identity: getState().identity,
      isApplicant: is_applicant,
      person: guarantor,
    });
  };
}
