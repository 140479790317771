import React, { Fragment, ReactElement } from 'react';
import ManualPassContent from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v2/ManualVerification/ManualPassContent';

const getColorStyle = ({
  idVerified,
  pass,
  status,
}: {
  idVerified: 'Pass' | 'Fail' | 'Unknown';
  pass: boolean | null;
  status: 'completed' | 'errored';
}): { color?: 'red' | 'green' } => {
  if (status === 'errored') {
    return { color: 'red' };
  }

  if (idVerified !== 'Unknown') {
    return { color: pass ? 'green' : 'red' };
  }

  return {};
};

const getOutcomeMessage = (idVerified, pass, status) => {
  if (status === 'errored') {
    return 'API fail';
  }

  if (!pass && typeof idVerified !== 'undefined' && idVerified !== 'Unknown') {
    return 'Fail';
  }

  return idVerified;
};

const Outcome = ({
  idVerified,
  pass,
  manuallyApproved,
  status,
}: {
  idVerified: 'Pass' | 'Fail' | 'Unknown';
  pass: boolean | null;
  manuallyApproved: boolean | null;
  status: 'completed' | 'errored';
}): ReactElement => {
  if (manuallyApproved) {
    return <ManualPassContent />;
  }

  const style = getColorStyle({ idVerified, pass, status });
  const outcomeMessage = getOutcomeMessage(idVerified, pass, status);

  return (
    <Fragment>
      <span className="underlined">ID check outcome:</span>
      <span style={style}>&nbsp;{outcomeMessage}</span>
    </Fragment>
  );
};

export default Outcome;
