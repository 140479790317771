import Tabs from 'modules/shared/components/widgets/interactive/Tabs';
import AdminInnerTitle from 'modules/shared/components/widgets/static/AdminInnerTitle';
import React from 'react';
import { connect } from 'react-redux';

import styles from './css/commonStyles.css';

class ConnectedApps extends React.Component {
  render() {
    const { title, tabs } = this.props;

    return (
      <section className={styles.section}>
        <div className={styles.header}>
          <AdminInnerTitle text={title} isNormalFont={true} />
        </div>
        <Tabs
          tab_list={tabs}
          base_path="/dashboard/connected-apps"
          active_path={location.pathname}
        />
        {this.props.children}
      </section>
    );
  }
}

const defaults = {
  tabs: [
    {
      path: '/webhooks',
      title: 'webhooks',
    },
  ],
  title: 'Integrations',
};

module.exports = connect(() => {
  return {
    tabs: defaults.tabs,
    title: defaults.title,
  };
})(ConnectedApps);
