import get from 'lodash.get';
import ApplicationModel from 'models/ApplicationModel';
import store from 'stores/store';

export function cobApplication() {
  const attributes = get(
    store.getState(),
    'cob_section.application.attributes'
  );
  return new ApplicationModel({ attributes });
}
