/* Import libraries */
import Icon from 'modules/shared/components/svg/Icon';
/* Import components */
import Clickable from 'modules/shared/components/widgets/interactive/Clickable';
import SimpleDropdown from 'modules/shared/components/widgets/interactive/SimpleDropdown';
import React, { Component } from 'react';

/* Import CSS */
import styles from '../css/DashboardContainer.css';

class MenuItem extends Component {
  render() {
    const {
      items,
      title,
      icon,
      target,
      active,
      style,
      hideHandler,
      visible,
      navActive,
      showTooltip,
      indicator,
    } = this.props;

    if (visible === false) {
      return null;
    }

    let link_style = style || styles.link;

    let dropdown = null;
    let arrow = null;
    let menuItem = styles.menu_item;
    if (this.props.children) {
      if (active) {
        arrow = <i className={`${styles.arrow_up} ${styles.hidden_md_lg}`}></i>;
        menuItem = `${styles.menu_item} ${styles.menu_item_active}`;
        dropdown = (
          <div
            className={`${styles.dropdown_list} ${styles.show_dropdown_list}`}
          >
            <SimpleDropdown
              parent={this.id}
              hideHandler={hideHandler}
              pullLeft={true}
            >
              {this.props.children}
            </SimpleDropdown>
          </div>
        );
      } else {
        arrow = (
          <i className={`${styles.arrow_down} ${styles.hidden_md_lg}`}></i>
        );
        dropdown = (
          <div
            className={`${styles.dropdown_list} ${styles.hide_dropdown_list}`}
          >
            <SimpleDropdown
              parent={this.id}
              hideHandler={hideHandler}
              pullLeft={true}
            >
              {this.props.children}
            </SimpleDropdown>
          </div>
        );
      }
    }

    if (navActive) {
      link_style = styles.link_active;
    }

    if (indicator) {
      return (
        <li key={`${title}-menu`} className={styles.menu_item}>
          <div className={styles.link1}>
            <Icon icon={icon} />
            <span className={styles.link_label}>
              {title}
              {...indicator}
            </span>
          </div>
        </li>
      );
    }
    return (
      <li key={`${title}-menu`} className={menuItem} onMouseEnter={showTooltip}>
        <Clickable target={target}>
          <div className={link_style}>
            <Icon icon={icon} shadow={true} />
            <span className={styles.link_label}>
              {title}
              {arrow}
            </span>
          </div>
        </Clickable>
        {dropdown}
      </li>
    );
  }
}

export default MenuItem;
