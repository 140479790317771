import api from 'api';
import capitalize from 'lodash.capitalize';
import get from 'lodash.get';
import BaseModel from 'models/BaseModel';
import { isHeadquarter } from 'modules/shared/helpers/headquarterDetect';
import { formatLocalTime } from 'utils/dateFormatter';
import { formatMoney } from 'utils/formatting';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';

const STATUS_SORT_ORDER = [
  'Application',
  'Cards',
  'Direct debit',
  'Guarantor',
  'Signatory',
  'Reference checks',
  'Credit checks',
  'eSignatures',
  'Approval',
  'PPSR status',
  'Loaded in system',
];

function formatStatusDate(date) {
  return isPresent(date) ? formatLocalTime(date) : '';
}

function sortStatuses(statuses) {
  return statuses.sort((a, b) => {
    if (a.sortOrder > b.sortOrder) {
      return 1;
    }

    if (a.sortOrder < b.sortOrder) {
      return -1;
    }

    return 0;
  });
}

export default class ReportApplicationModel extends BaseModel {
  static async loadApplications({ accessToken, entityId, params }) {
    const reportApplicationsAPI = api(
      'report_applications',
      accessToken,
      entityId
    );

    try {
      const result = await reportApplicationsAPI.getApplications({ params });

      const reportApplications = get(result, 'data.data', []);
      const meta = get(result, 'data.meta', {});

      const applications = reportApplications.map((application) => {
        const app = new ReportApplicationModel(application);
        app.actingAsSupplier = params.acting_as_supplier;
        return app;
      });

      return { applications, meta };
    } catch (error) {
      console.error(error);
      return { applications: [], meta: {} };
    }
  }

  static async loadApplicationFilterOptions({ accessToken, entityId }) {
    const reportApplicationsAPI = api(
      'report_applications',
      accessToken,
      entityId
    );
    try {
      const result = await reportApplicationsAPI.getApplicationFilterOptions();
      const options = get(result, 'data', {});
      return options;
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  static async exportApplications({ accessToken, entityId, params }) {
    const applicationsAPI = api('applications', accessToken, entityId);
    try {
      const result = await applicationsAPI.exportApplications(null, null, {
        params,
      });
      // const blob = new Blob([result.data], { type: "text/csv;charset=utf-8;" })
      // const link = document.createElement("a");
      // const url = URL.createObjectURL(blob)
      // link.href = url;
      // link.target = "_blank";
      // link.download = "applications.csv";
      // document.body.appendChild(link);
      // link.click();
    } catch (error) {
      console.error(error);
    }
  }

  get actingAsSupplier() {
    return this._actingAsSupplier;
  }

  set actingAsSupplier(value) {
    this._actingAsSupplier = value;
  }

  get name() {
    if (this.actingAsSupplier) {
      return this.applicationDisplayName;
    }

    return this.supplierName;
  }

  get applicationDisplayName() {
    if (this.isFloating) {
      return this.consumerContactEmail;
    }

    return this.consumerName;
  }

  // An application is floating if it is not assigned to a consumer_id yet
  get isFloating() {
    return isBlank(this.consumerId);
  }

  get startedOn() {
    const date_started = this.statuses[0].started_at;
    if (date_started) {
      return formatLocalTime(date_started);
    }
    return '-';
  }

  get isCompleted() {
    return this.isDeclined || this.isAccepted;
  }

  get completedOn() {
    return this.isCompleted ? formatLocalTime(this.updatedAt) : '-';
  }

  get limit() {
    if ((parseFloat(this.requestedLimit) || 0) === 0) {
      return '-';
    }

    let limit = this.requestedLimit;
    if (this.isAccepted) {
      limit = this.tradeAccountLimit;
    }

    return `$${formatMoney(parseInt(limit) || 0)}`;
  }

  get formattedRequestedLimit() {
    if ((parseFloat(this.requestedLimit) || 0) === 0) {
      return '-';
    }

    return `$${formatMoney(parseInt(this.requestedLimit) || 0)}`;
  }

  get formattedAcceptedLimit() {
    if (this.isAccepted) {
      return `$${formatMoney(parseInt(this.tradeAccountLimit) || 0)}`;
    }

    return this.formattedRequestedLimit;
  }

  get branchName() {
    return this.actingAsSupplier ? this.supplierName : this.consumerName;
  }

  get initiator() {
    return this.initiatorName;
  }

  get branchOrOwner() {
    return isHeadquarter() ? this.branchName : this.supplierContactName;
  }

  get contactName() {
    if (this.actingAsSupplier) {
      return this.consumerContactName;
    }

    return this.supplierContactName;
  }

  get contactEmail() {
    if (this.actingAsSupplier) {
      this.consumerContactEmail;
    }

    return this.supplierContactEmail;
  }

  get statusesFormatted() {
    const statuses = this.statuses.map((status, index) => {
      status.started_on = formatStatusDate(status.started_at);
      status.completed_on = formatStatusDate(status.completed_at);

      if (index === 0) {
        status.other = this.contactName;
      }

      if (index === 1) {
        status.other = this.contactEmail;
      }

      status.sortOrder = STATUS_SORT_ORDER.indexOf(status.name);

      return status;
    });

    return sortStatuses(statuses);
  }

  get formattedAlertType() {
    return capitalize(this.alertType || '');
  }
}
