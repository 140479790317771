import React, { ReactElement } from 'react';
import ApproverContent from 'modules/new-applications/components/application-sections/LimitAndApprovals/ReviewContent/ApproverContent';
import ReviewerContent from 'modules/new-applications/components/application-sections/LimitAndApprovals/ReviewContent/ReviewerContent';
import RevertDecision from 'modules/new-applications/components/application-sections/LimitAndApprovals/RevertDecision';
import UserModel from 'models/UserModel';

const ReviewContent = ({
  application,
  currentUser,
  onRevertDecisionCallback,
  onSetAlert,
  review,
}: {
  application: { [key: string]: any };
  currentUser: UserModel;
  onRevertDecisionCallback: () => void;
  onSetAlert: () => void;
  review: { [key: string]: any };
}): ReactElement => {
  let content: ReactElement | null = null;

  if (review.isReviewerLevel) {
    content = <ReviewerContent review={review} />;
  } else {
    content = <ApproverContent application={application} review={review} />;
  }

  return (
    <>
      {content}
      {review.id === application.mostRecentActionedReview.id && (
        <RevertDecision
          application={application}
          currentUser={currentUser}
          onRevertDecisionCallback={onRevertDecisionCallback}
          onSetAlert={onSetAlert}
        />
      )}
    </>
  );
};

export default ReviewContent;
