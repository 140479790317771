import styled from 'styled-components';

export const FormWrapper = styled.form`
  width: 100%;
`;

export const ButtonWrapper = styled.span`
  button {
    margin-top: 0 !important;
  }
`;
