// import "bulma/css/bulma.css";

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import api from 'api';
import google from 'images/google.png';
import microsoft from 'images/microsoft.png';
import Button from 'modules/shared/components/inputs/Button';
import SquareModal from 'modules/shared/components/widgets/static/SquareModal';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import React, { useEffect, useState } from 'react';

import styles from './css/CommonEditProfile.css';

const ConfirmPopup = ({ popup, handleClose, handleConfirm, handleCancel }) =>
  popup && (
    <SquareModal title="Important" size="small" dismissHandler={handleClose}>
      <div>
        <p className={styles.confirm_title}>
          Single Sign-On (SSO) is a session and user authentication service that
          permits a user to use one set of login credentials - for example, a
          name and password to access multiple applications. In this instance,
          turning on SSO in your 1Centre account{' '}
          <span className={styles.confirm_emphasize}>
            will force your users to sign in using your domain credentials.{' '}
          </span>
          It also safe guards your 1Centre account, ensuring only users with a
          correct and current email address can login.{' '}
        </p>
        <div>
          <Button text={'Yes, I confirm'} handleClick={handleConfirm} />
          <Button text={'Cancel'} white={true} handleClick={handleCancel} />
        </div>
      </div>
    </SquareModal>
  );

const SsoComponent = ({ eid, access_token }) => {
  const [provider, setProvider] = useState(null);
  const [popup, setPopup] = useState(false);
  const [targetValue, setTargetValue] = useState(null);

  useEffect(() => {
    let isSubscribed = true;

    (async function() {
      const res = await api('entities', access_token, eid).getEntity(eid);

      if (!res || !isSubscribed) {
        return;
      }

      setProvider(res.data.data.attributes.sso_provider);
    })();

    return () => {
      isSubscribed = false;
    };
  }, []);

  const tips = {
    google: 'This section requires users to log in with Google account',
    microsoft: 'This section requires users to log in with Microsoft account',
    no_provider: "This section doesn't require single sign on(SSO)",
  };

  async function update(v) {
    if (!v) {
      return;
    }
    try {
      await api('entities').setSSOProvider(eid, {
        sso_provider: v,
      });
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <FormControl component="fieldset" className={styles.sso_font_size}>
        <div className="mb-2">
          <label htmlFor="require_sso">Single sign on (SSO) log in</label>
        </div>
        <RadioGroup
          row
          aria-label="sso-provider"
          name="sso-provider"
          value={provider}
          onChange={(e) => {
            setPopup(true);
            setTargetValue(e.target.value);
          }}
        >
          <FormControlLabel
            value="no_provider"
            control={<Radio />}
            label="None"
            classes={{
              label: styles.radio_first_label,
              root: styles.radio_root,
            }}
          />
          <FormControlLabel
            value="google"
            control={<Radio />}
            label="Google"
            classes={{
              label: styles.radio_next_label,
              root: styles.radio_root,
            }}
          />
          <img src={google} className={styles.m_img} />
          <FormControlLabel
            value="microsoft"
            control={<Radio />}
            label="Microsoft"
            classes={{
              label: styles.radio_next_label,
              root: styles.radio_root,
            }}
          />
          <img src={microsoft} className={styles.m_img} />
        </RadioGroup>
        <div className="mt-2">
          <label className="pl-1">{tips[provider]}</label>
        </div>
        <br></br>
        <br></br>
      </FormControl>

      <ConfirmPopup
        provider={provider}
        popup={popup}
        handleCancel={() => setPopup(false)}
        handleConfirm={async function() {
          setPopup(false);
          setProvider(targetValue);
          await update(targetValue);
        }}
        handleClose={() => setPopup(false)}
      />
    </MuiThemeProvider>
  );
};

export default SsoComponent;
