import axios from './axios';

const Leads = function(config) {
  this.axios = axios(config);
  this.type = 'leads';
};

Leads.prototype.getLeads = function(params, success, error) {
  return this.axios
    .get(`/${this.type}`, { params })
    .then(success)
    .catch(error);
};

Leads.prototype.getLeadReport = function(success, error) {
  return this.axios
    .get(`/${this.type}/report`)
    .then(success)
    .catch(error);
};

Leads.prototype.updateLead = function(id, attributes, success, error) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        attributes,
        id,
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

Leads.prototype.getLead = function(id, success, error, options = {}) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

Leads.prototype.createLead = function(attributes, success, error) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios
    .post(`/${this.type}`, attributes)
    .then(success)
    .catch(error);
};

Leads.prototype.updateLeadV2 = function(id, attributes, success, error) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios
    .patch(`/${this.type}/${id}`, attributes)
    .then(success)
    .catch(error);
};

Leads.prototype.loadedInSystem = function(id) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');

  return this.axios.patch(`/${this.type}/${id}/loaded_in_system`);
};

export default Leads;
