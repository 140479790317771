import mixpanel from 'mixpanel-browser';
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import MixpanelMiddleware from 'redux-mixpanel-middleware';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import rootSaga from 'sagas';

import config from '../../config';
import ApplicationReducer from '../modules/reducers';
import { middleware as idleMiddleware } from '../modules/redux-idle-monitor';

export default function configureStore(initialState = {}) {
  mixpanel.init(config.mixpanel);
  const mixpanelMiddleware = new MixpanelMiddleware(mixpanel);

  const sagaMiddleware = createSagaMiddleware();
  const loggerMiddleware = createLogger({
    // Do not show the Idle Monitor logs in the console as it just spams the
    // logs and you can get lost looking for the actual action you are really
    // interested in
    predicate: (_getState, action) => !action.type.includes('IDLE_TODO-APP'),
  });
  const middleware = [
    mixpanelMiddleware,
    thunk,
    // loggerMiddleware,
    sagaMiddleware,
  ];

  const store = createStore(
    ApplicationReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );

  sagaMiddleware.run(rootSaga);

  if (process.env.NODE_ENV !== 'production') {
    window.__store = store;
  }
  return store;
}
