/* eslint-disable max-lines */
import * as Sentry from '@sentry/browser';
import api from 'api';
import capitalize from 'lodash.capitalize';
import compact from 'lodash.compact';
import get from 'lodash.get';
import AddonAnswerModel from 'models/AddonAnswerModel';
import AddonRuleModel from 'models/AddonRuleModel';
import AddressModel from 'models/AddressModel';
import AntiFraudAddonVersionModel from 'models/AntiFraudAddonVersionModel';
import ApplicationHistoryModel from 'models/ApplicationHistoryModel';
import ApplicationTradingNameLinkModel from 'models/ApplicationTradingNameLinkModel';
import AuthorisationModel from 'models/AuthorisationModel';
import BaseModel from 'models/BaseModel';
import CardholderModel from 'models/CardholderModel';
import ConnectionModel from 'models/ConnectionModel';
import CreditCheckModel from 'models/CreditCheckModel';
import CreditCheckRuleModel from 'models/CreditCheckRuleModel';
import EntityModel from 'models/EntityModel';
import GuarantorModel from 'models/GuarantorModel';
import IdentificationCheckRuleModel from 'models/IdentificationCheckRuleModel';
import IUFApprovalModel from 'models/IUFApprovalModel';
import NoteModel from 'models/NoteModel';
import PaymentModel from 'models/PaymentModel';
import PpsrFinancingStatementModel from 'models/PpsrFinancingStatementModel';
import ReviewModel, {
  isReviewerLevel,
  REVIEWER_LEVEL,
} from 'models/ReviewModel';
import SignatoryModel from 'models/SignatoryModel';
import SignatureModel from 'models/SignatureModel';
import StopCreditModel from 'models/StopCreditModel';
import TradeReferenceModel from 'models/TradeReferenceModel';
import VedaCheckModel from 'models/VedaCheckModel';
import moment from 'moment';
import { formatDate, formatLocalTime } from 'utils/dateFormatter';
import { entityTypeTitle, isOtherEntityType } from 'utils/entityTypeTitle';
import flattenArray from 'utils/flattenArray';
import { formatMoney } from 'utils/formatting';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import sortByCreatedAt from 'utils/sortByCreatedAt';
import sortByDate from 'utils/sortByDate';

const ALLOWED_NUMBER_OF_DAYS_TO_REVERT = 30;

// Turn into array, push parts if permissions allowed task/5744
const getApplicationIncluded = ({ can_review_credit_checks }) => {
  const creditChecks = [
    'credit_checks',
    'veda_checks',
    'veda_checks.signature',
  ];

  const included = [
    'consumer',
    'physical_address',
    'postal_address',
    'credit_check_rule',
    'guarantors',
    'trade_references',
    'trade_references.trade_reference_check',
    'trade_references.trade_reference_check.notes',
    'consumer.people',
    'consumer.people.address',
    'addon_answers',
    'addon_answers.addon_answer_histories',
    'addon_rules',
    'cardholders',
    'payments',
    'authorisations',
    'authorisations.signature',
    'authorisations.authorisation_histories',
    'authorisations.identification_check',
    'authorisations.anti_fraud_check',
    'application_trading_name_links',
    'application_trading_name_links.addon_answer',
    'application_trading_name_links.trading_name',
    'signatories',
    'reviews',
    'reviews.review_histories',
    'notes',
    'application_histories',
    'stop_credits',
    'stop_credits.stop_credit_histories',
    'active_stop_credit',
    'connection',
    'connection.notes',
    'iuf_approval',
    'iuf_approval.iuf_approval_histories',
    'ppsr_financing_statements',
    'ppsr_financing_statements.ppsr_financing_statement_debtors',
    'ppsr_financing_statements.ppsr_financing_statement_debtors.debtor_address',
    'ppsr_financing_statements.ppsr_financing_statement_collaterals',
    'ppsr_financing_statements.ppsr_financing_statement_histories',
    ...(can_review_credit_checks ? creditChecks : []),
  ];

  return included.join(',');
};

const GET_APPLICATION_CREDIT_CHECKS_INCLUDED =
  'credit_checks,veda_checks,veda_checks.signature';

const CREDIT_CHECK_REFRESH_INTERVAL = 15000;
const DEFAULT_DECIMAL_PLACES = 2;

const AUTO_DECISION_DEFERRED_REASONS = {
  'aml_verification?': 'AML check requirements are not met.',
  'anti_fraud_verification?': '1CAF requirements are not met.',
  'bankruptcy?': 'bankruptcy requirements are not met.',
  'credit_check_met?': 'credit check requirements are not met.',
  'credit_risk_indicator?': 'credit check indicator requirements are not met.',
  'credit_risk_indicator_init_equifax?':
    'issues with the integrity of the credit report. Please contact support@1centre.com',
  'credit_value_check?': 'credit value limit is exceeded.',
  'defaults?': 'defaults requirements are not met.',
  'financials_check?': 'financials requirements are not met.',
  'guarantors?': 'guarantor requirements are not met.',
  'id_verification?': 'ID check requirements are not met.',
  'iuf?': 'internal use fields requirements are not met.',
  'judgements?': 'judgements requirements are not met.',
  'paperless_check?': 'paperless requirements are not met.',
  'signatory?': 'signatory requirements are not met.',
  'time_in_business_check?': 'time in business requirements are not met.',
  'trade_references?': 'trade reference requirements are not met.',
};

const isAuthorisationVisible = (
  authorisation,
  isCardholderSignatureRequired
) => {
  if (authorisation.cardholderOnly && !isCardholderSignatureRequired) {
    return false;
  }

  return true;
};

function compareCreditCheck({ creditCheck, retrievedCreditChecks }) {
  if (isPresent(creditCheck.id)) {
    const retrievedCreditCheck = retrievedCreditChecks.find(
      (check) => check.id === creditCheck.id
    );

    if (isPresent(retrievedCreditCheck)) {
      return {
        retrievedCreditCheck,
        shouldRefresh: retrievedCreditCheck.status !== creditCheck.status,
      };
    }

    return { shouldRefresh: false };
  }

  if (creditCheck.isCompanyCheck) {
    const retrievedCreditCheck = retrievedCreditChecks.find(
      (check) => check.isCompanyCheck
    );

    if (isPresent(retrievedCreditCheck)) {
      return { retrievedCreditCheck, shouldRefresh: true };
    }

    return { shouldRefresh: false };
  }

  const retrievedCreditCheck = retrievedCreditChecks.find(
    (check) => check.authorisationId === creditCheck.authorisationId
  );

  if (isPresent(retrievedCreditCheck)) {
    return { retrievedCreditCheck, shouldRefresh: true };
  }

  return { shouldRefresh: false };
}

function compareRetrievedCreditChecks({ application, retrievedApplication }) {
  const creditChecks = application.creditChecks;
  const retrievedCreditChecks = retrievedApplication.creditChecks;

  const newCreditChecks = [];
  let shouldRefreshApplication =
    application.creditCheckStatus !== retrievedApplication.creditCheckStatus;

  for (const creditCheck of creditChecks) {
    const { retrievedCreditCheck, shouldRefresh } = compareCreditCheck({
      creditCheck,
      retrievedCreditChecks,
    });

    if (isPresent(retrievedCreditCheck)) {
      newCreditChecks.push(retrievedCreditCheck);
    }

    if (shouldRefresh) {
      shouldRefreshApplication = true;
    }
  }

  return { newCreditChecks, shouldRefreshApplication };
}

export default class ApplicationModel extends BaseModel {
  static async fetchApplicationWithCreditChecks({ accessToken, entityId, id }) {
    const applicationAPI = api('applications', accessToken, entityId);

    try {
      const result = await applicationAPI.awaitableGetApplication(id, {
        params: { include: GET_APPLICATION_CREDIT_CHECKS_INCLUDED },
      });

      return new ApplicationModel(
        get(result, 'data.data'),
        get(result, 'data.included')
      );
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchApplicationWithRelationships(
    { accessToken, entityId, id, pagePermissions },
    onSetAlert
  ) {
    const applicationAPI = api('applications', accessToken, entityId);

    try {
      const result = await applicationAPI.awaitableGetApplication(id, {
        params: {
          include: getApplicationIncluded(pagePermissions),
          page_context: 'virtual_credit_file',
        },
      });

      return new ApplicationModel(
        get(result, 'data.data'),
        get(result, 'data.included')
      );
    } catch (error) {
      console.error(error);
      if (get(error, 'response.status') === 403) {
        Sentry.captureException(error);
        return new ApplicationModel({ attributes: { noPermission: true } });
      }
      return new ApplicationModel();
    }
  }

  get companyCreditCheckSelectedName() {
    if (isBlank(this.region)) {
      return '';
    }

    return this.creditCheckRule[
      `${this.region.toLowerCase()}RuleSelectionName`
    ];
  }

  get companyCreditCheckSelectedSlug() {
    if (isBlank(this.region)) {
      return '';
    }

    return this.creditCheckRule[
      `${this.region.toLowerCase()}RuleSelectionSlug`
    ];
  }

  static async refreshCreditCheckRecords({
    accessToken,
    application,
    entityId,
    onRefreshCallback,
  }) {
    const creditChecks = application.creditChecks;
    const pendingCreditChecks = creditChecks.filter(
      (creditCheck) => creditCheck.isLoading
    );

    if (isBlank(pendingCreditChecks)) {
      application.clearCreditCheckPollingTimer();
      return;
    }

    const retrievedApplication = await ApplicationModel.fetchApplicationWithCreditChecks(
      {
        accessToken,
        entityId,
        id: application.id,
      }
    );
    const {
      newCreditChecks,
      shouldRefreshApplication,
    } = compareRetrievedCreditChecks({ application, retrievedApplication });

    if (shouldRefreshApplication) {
      application.setAttributes({
        credit_check_status: retrievedApplication.creditCheckStatus,
      });
      application.setCreditChecks(newCreditChecks);
      application.clearCreditCheckPollingTimer();

      const newApplication = application.cloneRecord();

      onRefreshCallback(newApplication);
    } else {
      application.clearCreditCheckPollingTimer();
      application.onPollCreditCheckRecords({
        accessToken,
        entityId,
        onRefreshCallback,
      });
    }
  }

  static updateApplication({
    accessToken,
    application,
    attributes,
    entityId,
    onSuccessCallback,
  }) {
    const applicationAPI = api('applications', accessToken, entityId);

    applicationAPI.updateApplication(
      application.id,
      attributes,
      onSuccessCallback,
      (error) => console.error(error)
    );
  }

  get formattedApplicationType() {
    return capitalize(this.applicationType || '');
  }

  get formattedLegalType() {
    const defaultLegalType = capitalize(this.legalType || '').replace('_', ' ');
    const businessApiDetails = this.businessApiDetails || {};

    return (
      businessApiDetails.entity_type_name ||
      get(this.businessApiDetails, 'abn_details.entity_type_name') ||
      defaultLegalType
    );
  }

  get formattedPersonalType() {
    if (this.isPersonalIndividual) {
      return 'Individual';
    }

    if (this.isPersonalJoint) {
      return 'Joint';
    }

    return '';
  }

  get formattedRegion() {
    if (this.region === 'NZ') {
      return 'New Zealand';
    }

    if (this.region === 'AU') {
      return 'Australia';
    }

    return '';
  }

  get formattedRegisteredAt() {
    if (isPresent(this.registeredAt)) {
      return moment(this.registeredAt).format('DD/MM/YYYY');
    }

    return '';
  }

  get formattedBusinessName() {
    if (this.legalType === 'company') {
      return this.companyName;
    }

    if (isPresent(this.tradingName)) {
      return this.tradingName;
    }

    return this.consumerContactEmail || '';
  }

  get formattedTradeAccountLimit() {
    return `$${formatMoney(
      parseFloat(this.tradeAccountLimit) || 0,
      DEFAULT_DECIMAL_PLACES
    )}`;
  }

  get formattedFinalLimit() {
    return `$${formatMoney(
      parseFloat(this.finalLimit) || 0,
      DEFAULT_DECIMAL_PLACES
    )}`;
  }

  /**
   * This is a combination of the submission_status and status
   */
  get overallStatus() {
    if (this.archived) {
      return 'Archived';
    }

    if (['not_started', 'in_progress'].includes(this.submissionStatus)) {
      return this.submissionStatus;
    }

    return this.status;
  }

  get consumerTradingNames() {
    const applicationTradingNameLinks = this.applicationTradingNameLinks || [];

    if (applicationTradingNameLinks.length === 0) {
      return [];
    }

    return applicationTradingNameLinks.map(({ tradingName }) => tradingName);
  }

  get defaultTradingName() {
    if (this.legalType !== 'company') {
      return '';
    }

    const defaultTradingName = this.consumerTradingNames.find(
      (consumerTradingName) => consumerTradingName.default
    );
    if (isPresent(defaultTradingName)) {
      return defaultTradingName;
    }

    return this.consumerTradingNames[0] || {};
  }

  get applicantAuthorisation() {
    return (
      this.authorisations.find((authorisation) =>
        isPresent(authorisation.userId)
      ) || {}
    );
  }

  get applicantSignature() {
    if (isBlank(this.applicantAuthorisation)) {
      return {};
    }

    const id = this.applicantAuthorisation.signatureId;
    if (isBlank(id)) {
      return {};
    }

    const attributes = this.getIncludedAttributes({ id, type: 'signatures' });
    return new SignatureModel(attributes);
  }

  get cardsAddonRule() {
    return this.findAddonRule('cards');
  }

  get financialsAddonRule() {
    return this.findAddonRule('financials') || {};
  }

  get financialsAddonAnswers() {
    return this.findAddonAnswer('financials') || {};
  }

  get additionalsAddonRule() {
    return this.findAddonRule('additional');
  }

  get additionalAddonAnswers() {
    return this.findAddonAnswer('additional');
  }

  get paperlessAddonRule() {
    return this.findAddonRule('paperless') || {};
  }

  get iufAddonRule() {
    return this.findAddonRule('internal_use_fields') || {};
  }

  get alertDateInUnix() {
    const stringDates = compact([
      moment(this.liquidationAt, 'DD/MM/YYYY').unix(),
      moment(this.deregisteredAt, 'DD/MM/YYYY').unix(),
    ]);
    return Math.max.apply(null, stringDates);
  }

  get alertDate() {
    return moment.unix(this.alertDateInUnix).format('DD/MM/YYYY');
  }

  get alertType() {
    if (this.deregisteredAt === this.alertDate) {
      return 'deregistered';
    }

    if (this.liquidationAt === this.alertDate) {
      return 'liquidated';
    }

    return '';
  }

  get formattedAlertType() {
    return capitalize(this.alertType || '');
  }

  get iufAddonAnswers() {
    return this.findAddonAnswer('internal_use_fields');
  }

  get isTradeReferenceCheckActionRequired() {
    if (
      this.isViewOnly ||
      !this.isSubmitted ||
      this.requiresTradeReferenceCheck
    ) {
      return false;
    }

    return this.tradeReferenceCheckStatus !== 'complete';
  }

  get isCreditCheckActionRequired() {
    if (this.isViewOnly || !this.isSubmitted) {
      return false;
    }

    return this.creditCheckStatus !== 'complete';
  }

  get visibleAuthorisations() {
    return this.authorisations.filter((authorisation) =>
      isAuthorisationVisible(
        authorisation,
        this.requiresCardholderSignature || false
      )
    );
  }

  get isIdentificationCheckStatusRequired() {
    if (
      this.isViewOnly ||
      !this.isSubmitted ||
      !this.isIdentificationCheckRequired
    ) {
      return false;
    }

    for (const authorisation of this.visibleAuthorisations) {
      const identificationCheck = authorisation.getIdentificationCheck(this);
      const signature = authorisation.signature;

      if (
        !identificationCheck.isIdentificationCheckSuccess &&
        !signature.isNewRecord &&
        !signature.isManuallyApproved
      ) {
        return true;
      }
    }

    return false;
  }

  get isNotStarted() {
    return this.status === 'not_started';
  }

  get isAccepted() {
    return this.status === 'accepted';
  }

  get isDeclined() {
    return this.status === 'declined';
  }

  get isDeleted() {
    return this.status === 'deleted';
  }

  get isFated() {
    return ['accepted', 'declined'].includes(this.status);
  }

  get isArchivedOrDeleted() {
    return this.archived || this.isDeleted;
  }

  get isViewOnly() {
    return this.isFated || this.isArchivedOrDeleted;
  }

  get isReviewed() {
    return isPresent(
      (this.reviews.find((review) => isPresent(review.decision)) || {}).id
    );
  }

  get isAutoDecisioned() {
    return isPresent(this.autoDecisioningState);
  }

  get autoDecisionDeferredReason() {
    const prefix = 'This application is deferred by Auto Decisioning because ';
    const lastDeferredReason =
      AUTO_DECISION_DEFERRED_REASONS[this.lastDeferredReason] || '';

    if (this.lastDeferredReason === 'aml_verification?') {
      return `${prefix} ${lastDeferredReason} ${this.lastDeferredReasonDetail}.`;
    }

    return `${prefix} ${lastDeferredReason}`;
  }

  get activeTradeReferences() {
    return this.tradeReferences.filter(
      (tradeReference) => !tradeReference.archived
    );
  }

  get isCompanyCreditCheckRequired() {
    if (this.legalType !== 'company' || isBlank(this.region)) {
      return false;
    }

    const selectedId = this.creditCheckRule[
      `${this.region.toLowerCase()}RuleSelectionId`
    ];

    return isPresent(selectedId);
  }

  get personalCreditCheckSelectedName() {
    if (isBlank(this.region)) {
      return '';
    }

    return this.creditCheckRule[
      `${this.region.toLowerCase()}PersonalRuleSelectionName`
    ];
  }

  get isCreditCheckRequired() {
    return (
      this.isCompanyCreditCheckRequired || this.isPersonalCreditCheckRequired
    );
  }

  get personalCreditCheckSelectedSlug() {
    if (isBlank(this.region)) {
      return '';
    }

    return this.creditCheckRule[
      `${this.region.toLowerCase()}PersonalRuleSelectionSlug`
    ];
  }

  get isPersonalCreditCheckRequired() {
    const applicableLegalTypes = [
      'company',
      'partnership',
      'personal',
      'sole_trader',
      'trust',
    ];

    if (
      !applicableLegalTypes.includes(this.legalType) ||
      isBlank(this.region)
    ) {
      return false;
    }

    const selectedId = this.creditCheckRule[
      `${this.region.toLowerCase()}PersonalRuleSelectionId`
    ];

    return isPresent(selectedId);
  }

  static async refreshCreditCheckRecords({
    accessToken,
    application,
    entityId,
    onRefreshCallback,
  }) {
    const creditChecks = application.creditChecks;
    const pendingCreditChecks = creditChecks.filter(
      (creditCheck) => creditCheck.isLoading
    );

    if (isBlank(pendingCreditChecks)) {
      application.clearCreditCheckPollingTimer();
      return;
    }

    const retrievedApplication = await ApplicationModel.fetchApplicationWithCreditChecks(
      {
        accessToken,
        entityId,
        id: application.id,
      }
    );
    const {
      newCreditChecks,
      shouldRefreshApplication,
    } = compareRetrievedCreditChecks({ application, retrievedApplication });

    if (shouldRefreshApplication) {
      application.setAttributes({
        credit_check_status: retrievedApplication.creditCheckStatus,
      });
      application.setCreditChecks(newCreditChecks);
      application.clearCreditCheckPollingTimer();

      const newApplication = application.cloneRecord();

      onRefreshCallback(newApplication);
    } else {
      application.clearCreditCheckPollingTimer();
      application.onPollCreditCheckRecords({
        accessToken,
        entityId,
        onRefreshCallback,
      });
    }
  }

  static updateApplication({
    accessToken,
    application,
    attributes,
    entityId,
    onSuccessCallback,
  }) {
    const applicationAPI = api('applications', accessToken, entityId);

    applicationAPI.updateApplication(
      application.id,
      attributes,
      onSuccessCallback,
      (error) => console.error(error)
    );
  }

  get companyCreditCheck() {
    return (
      this.creditChecks.find((creditCheck) => creditCheck.isCompanyCheck) || {}
    );
  }

  get personalCreditChecks() {
    return (
      this.creditChecks.filter((creditCheck) => !creditCheck.isCompanyCheck) ||
      []
    );
  }

  get isPersonalJoint() {
    return this.legalType === 'personal' && this.consumer.people.length > 1;
  }

  get isPersonalIndividual() {
    return this.legalType === 'personal' && this.consumer.people.length === 1;
  }

  get peopleToCreditCheck() {
    if (this.legalType === 'company') {
      return this.guarantors.filter((guarantor) => guarantor.approved);
    }

    if (
      ['partnership', 'trust'].includes(this.legalType) ||
      this.isPersonalJoint
    ) {
      return this.signatories.filter((signatory) => signatory.approved);
    }

    if (this.legalType === 'sole_trader' || this.isPersonalIndividual) {
      return [
        {
          authorisation: this.applicantAuthorisation,
          authorisationId: this.applicantAuthorisation.id,
          name: this.applicantSignature.fullName,
          signature: this.applicantSignature,
          signatureId: this.applicantSignature.id,
        },
      ];
    }

    return [];
  }

  get AMLCheckRule() {
    const addonRule = this.addonRules.find(
      (addonRule) => addonRule.addonModuleName === 'aml_check'
    );

    if (isBlank(addonRule)) {
      return {};
    }

    return addonRule.addonVersion || {};
  }

  get sortedReviewsByLevel() {
    return this.reviews
      .filter((review) => isPresent(review.taggedApproverId))
      .sort((a, b) => {
        if (a.level > b.level) {
          return 1;
        }

        if (a.level < b.level) {
          return -1;
        }

        return 0;
      });
  }

  get mostRecentActionedReview() {
    const sortedReviews = sortByDate({
      dateAttribute: 'reviewedAt',
      direction: 'descending',
      records: this.reviews.filter((review) => review.isActioned),
    });

    return sortedReviews[0];
  }

  get canRevertDecision() {
    if (this.isArchivedOrDeleted) {
      return false;
    }

    // An application can be reverted as well if the application review is
    // still in progress and there are existing decisions already.
    if (this.isReviewed && this.status === 'in_progress') {
      return true;
    }

    // An application can be reverted if it has been accepted or declined
    // within a 30-day period.
    const today = moment();
    if (this.isAccepted) {
      return (
        today.diff(moment(this.approvedAt), 'days') <=
        ALLOWED_NUMBER_OF_DAYS_TO_REVERT
      );
    }

    if (this.isDeclined) {
      return (
        today.diff(moment(this.finalApprovalModifiedAt), 'days') <=
        ALLOWED_NUMBER_OF_DAYS_TO_REVERT
      );
    }

    return false;
  }

  get reallocateReassignHistories() {
    const histories = this.applicationHistories.filter(
      (history) =>
        isPresent(history.content) &&
        ['reallocate', 'reassign'].includes(history.historyType)
    );

    return sortByCreatedAt(histories);
  }

  get creditControlHistories() {
    const reviewDateHistories = this.applicationHistories.filter(
      (history) =>
        isPresent(history.content) && history.historyType === 'review_date'
    );

    const stopCredits = this.stopCredits;
    const stopCreditHistories = flattenArray(
      stopCredits.map((stopCredit) => stopCredit.stopCreditHistories)
    ).filter((history) => isPresent(history.content));

    const histories = [...reviewDateHistories, ...stopCreditHistories];

    return sortByCreatedAt(histories);
  }

  get reviewHistories() {
    return sortByDate({
      dateAttribute: 'createdAt',
      direction: 'descending',
      records: this.applicationHistories.filter(
        (history) =>
          [
            'broker',
            'revert_decision',
            'review_decision',
            'review_deescalate_decision',
            'review_tag_approver',
            'review_tag_reset',
          ].includes(history.historyType) && isPresent(history.content)
      ),
    });
  }

  get loadedInSystemHistories() {
    const histories = this.applicationHistories.filter(
      (history) =>
        isPresent(history.content) && history.historyType === 'loaded_in_system'
    );

    return sortByCreatedAt(histories);
  }

  get authorisationHistories() {
    const authorisationHistories = flattenArray(
      this.authorisations.map(
        (authorisation) => authorisation.authorisationHistories
      )
    ).filter((history) => isPresent(history.content));

    const manuallyApprovedSignatures = this.authorisations
      .map((authorisation) => {
        const signature = authorisation.signature;

        return {
          createdAt: signature.manuallyApprovedAt,
          formattedContent: signature.manuallyApprovedText,
        };
      })
      .filter((signature) => isPresent(signature.formattedContent));

    const histories = [
      ...authorisationHistories,
      ...manuallyApprovedSignatures,
    ];

    return sortByCreatedAt(histories);
  }

  get archivedHistories() {
    const histories = this.applicationHistories.filter(
      (history) =>
        isPresent(history.content) && history.historyType === 'archived'
    );
    return sortByCreatedAt(histories);
  }

  get formattedApprovedAt() {
    if (isPresent(this.approvedAt)) {
      return formatLocalTime(this.approvedAt, 'minute');
    }

    return null;
  }

  get formattedBrokerEmailSentAt() {
    if (isPresent(this.brokerEmailSentAt)) {
      return formatLocalTime(this.brokerEmailSentAt, 'minute');
    }

    return 'N/A';
  }

  get formattedBrokerStatusUpdatedAt() {
    if (isPresent(this.brokerStatusUpdatedAt)) {
      return formatLocalTime(this.brokerStatusUpdatedAt, 'minute');
    }

    return 'N/A';
  }

  get formattedReviewDate() {
    if (isPresent(this.reviewDate)) {
      return formatDate(this.reviewDate, 'DD/MM/YY');
    }

    return null;
  }

  get mainNotes() {
    return this.allNotes.filter((note) => isBlank(note.parentId));
  }

  get allNotes() {
    return [...this.notes, ...this.connectionNotes];
  }

  get connectionNotes() {
    return this.connection.notes || [];
  }

  get isTradeReferencesVisible() {
    if (this.legalType === 'personal') {
      return false;
    }

    return this.minimumTradeReferences > 0 && this.tradeReferences.length > 0;
  }

  get isDeletable() {
    return ['in_progress', 'not_started', 'invite_email_bounced'].includes(
      this.submissionStatus
    );
  }

  get isSubmitted() {
    return ['complete', 'review_request'].includes(this.submissionStatus);
  }

  get tradingNameLabel() {
    if (this.region === 'AU') {
      return 'Main trading name';
    }

    if (isOtherEntityType(this.legalType)) {
      return entityTypeTitle(this.legalType, 'trading_name');
    }

    return 'Business name';
  }

  get businessNumberLabel() {
    if (this.region === 'AU') {
      return 'ABN';
    }

    return 'Business number';
  }

  get companyNumberLabel() {
    if (this.region === 'AU') {
      return 'ACN';
    }

    return 'Company number';
  }

  // ACN and ABN numbers are visible for AU companies, trustees, partnership
  // and sole-traders
  get isAustraliaNumberVisible() {
    if (this.region !== 'AU') {
      return false;
    }

    return ['company', 'trust', 'partnership', 'sole_trader'].includes(
      this.legalType
    );
  }

  get isIdentificationCheckRequired() {
    if (this.antiFraudEnabled) {
      return true;
    }

    const amlPersonToCheck = get(
      this.AMLCheckRule,
      'config.person_to_check',
      []
    );
    const amlCheckRule = get(this.AMLCheckRule, 'config.NZ');
    const isAMLCheckTurnedOn =
      isPresent(amlPersonToCheck) && isPresent(amlCheckRule);

    if (isAMLCheckTurnedOn) {
      return true;
    }

    const requiresPersonToIdCheck =
      this.requiresApplicantIdentificationCheck ||
      this.requiresGuaranteesIdentificationCheck ||
      this.requiresCardholderIdentificationCheck ||
      this.requiresSignatoriesIdentificationCheck;

    return this.identificationCheckRuleId && requiresPersonToIdCheck;
  }

  get activePPSRFinancingStatement() {
    if (this.ppsrFinancingStatements.length === 0) {
      return null;
    }

    return this.ppsrFinancingStatements.filter(
      (ppsrFinancingStatement) => ppsrFinancingStatement.status !== 'discharged'
    )[0];
  }

  get ppsrFinancingStatementHistories() {
    const histories = flattenArray(
      this.ppsrFinancingStatements.map(
        (ppsrFinancingStatement) =>
          ppsrFinancingStatement.ppsrFinancingStatementHistories
      )
    ).filter((history) => isPresent(history.content));

    return sortByCreatedAt(histories);
  }

  // This is to determine if the application requires a reviewer (level 0) or
  // not
  get derivedCurrentApprovalLevel() {
    if (!this.approvalHierarchyReviewerEnabled) {
      return this.currentApprovalLevel;
    }

    const review = this.reviews.find(
      (review) => isReviewerLevel(review.level) && review.isActioned
    );

    if (review) {
      return this.currentApprovalLevel;
    }

    return REVIEWER_LEVEL;
  }

  // This is the highest approval level setup by the supplier in 1CAH
  get highestApproverLevel() {
    const approverLevels = this.approvalHierarchyLevels.map(
      (hierarchy) => hierarchy.level
    );

    return Math.max(...approverLevels);
  }

  // This is the highest approval level required for the application based on
  // the requested limit
  get highestRequiredApproverLevelByLimit() {
    const requiredApproverLevels = this.approvalHierarchy.map(
      (hierarchy) => hierarchy.level
    );

    return Math.max(...requiredApproverLevels);
  }

  get antiFraudAddonVersion() {
    return new AntiFraudAddonVersionModel({
      attributes: { ...get(this.antiFraudAddonRule, 'addonVersion', {}) },
      id: get(this.antiFraudAddonRule, 'addonVersion.id'),
    });
  }

  get antiFraudAddonRule() {
    return this.addonRules.find((addonRule) => addonRule.isAntiFraudAddon);
  }

  get isIUFApprovalEnabled() {
    return get(this.iufAddonRule, 'config.pricing.approval_enabled', false);
  }

  get isIUFApprovalRequired() {
    return (
      this.isIUFApprovalEnabled &&
      get(this.iufAddonRule, 'config.pricing.approval_required', false)
    );
  }

  get isIUFRequired() {
    return this.requiresIuf;
  }

  get isIUFApproved() {
    return Boolean(this.iufApproval.approvedAt);
  }

  get isIUFSubmitted() {
    return get(this.iufAddonAnswers, 'status') === 'completed';
  }

  isIUFCompleted() {
    const visibilityArray = [this.isSubmitted];
    const isIUFRequired = this.isIUFRequired;
    const isIUFApprovalRequired = this.isIUFApprovalRequired;
    const isIUFApproved = this.isIUFApproved;

    if (isIUFRequired) {
      const iufAddonAnswers = this.iufAddonAnswers;
      visibilityArray.push(
        iufAddonAnswers && iufAddonAnswers.status === 'completed'
      );
    }

    if (isIUFApprovalRequired) {
      visibilityArray.push(isIUFApproved);
    }

    return visibilityArray.every((visibility) => visibility);
  }

  get isRequiredIUFPendingSubmission() {
    return this.isIUFRequired && !this.isIUFSubmitted;
  }

  get isRequiredIUFApprovalPendingDecision() {
    return this.isIUFApprovalRequired && !this.isIUFApproved;
  }

  get isApprovalBlockedByIUF() {
    if (this.isRequiredIUFPendingSubmission) {
      return true;
    }

    return this.isRequiredIUFApprovalPendingDecision;
  }

  get isLimitApprovalVisible() {
    return this.isSubmitted;
  }

  get isLoadedInSystemVisible() {
    return this.isIUFCompleted();
  }

  get canReallocate() {
    const reviewsWithDecisions = this.reviews.some((review) =>
      isPresent(review.decision)
    );
    return !reviewsWithDecisions;
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
    this.creditCheckPollingTimer = null;
  }

  onPollCreditCheckRecords({ accessToken, entityId, onRefreshCallback }) {
    if (isPresent(this.creditCheckPollingTimer)) {
      this.clearCreditCheckPollingTimer();
    }

    this.creditCheckPollingTimer = setTimeout(() => {
      ApplicationModel.refreshCreditCheckRecords({
        accessToken,
        application: this,
        entityId,
        onRefreshCallback,
      });
    }, CREDIT_CHECK_REFRESH_INTERVAL);
  }

  clearCreditCheckPollingTimer() {
    clearTimeout(this.creditCheckPollingTimer);
    this.creditCheckPollingTimer = null;
  }

  cloneRecord() {
    const clonedRecord = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this
    );
    clonedRecord.assignPropertiesFromAttributes();

    return clonedRecord;
  }

  runCreditCheck({
    creditCheck,
    creditCheckType,
    currentUser,
    person,
    onSuccess,
  }) {
    if (isPresent(creditCheck)) {
      creditCheck.initiating();
      this.updateCreditCheck({
        creditCheckType,
        currentUser,
        person,
        onSuccess: (response) => onSuccess(response, creditCheck),
      });
    } else {
      const createdCreditCheck = this.createCreditCheck({
        creditCheckType,
        currentUser,
        person,
        onSuccess,
      });

      if (isPresent(createdCreditCheck)) {
        this.addCreditCheck(createdCreditCheck);
      }
    }
  }

  canRunCreditCheck({ creditCheckType, currentUser, person, onSuccess }) {
    CreditCheckModel.canRunCreditCheck({
      application: this,
      creditCheckType,
      currentUser,
      person,
      onSuccess,
    });
  }

  setCreditChecks(creditChecks) {
    this.creditChecks = creditChecks;
  }

  addReview(review, index) {
    if (typeof index === 'undefined') {
      this.reviews.push(review);
    } else {
      this.reviews.splice(index, 1, review);
    }
  }

  addNote(note, index) {
    if (typeof index === 'undefined' || index < 0) {
      this.notes.unshift(note);
    } else {
      this.notes.splice(index, 1, note);
    }
  }

  isAMLCheckRequired({ actingAs, proofOfAddressUrl, region }) {
    if (isPresent(proofOfAddressUrl)) {
      return true;
    }

    if (!this.AMLCheckRule.active) {
      return false;
    }

    const { config } = this.AMLCheckRule || {};
    const ruleByRegion = config[region];

    if (isBlank(ruleByRegion)) {
      return false;
    }

    const personToCheck = config.person_to_check || [];
    for (const person of actingAs) {
      if (personToCheck.includes(person)) {
        return true;
      }
    }

    return false;
  }

  getEscalatedReviewOnLevel(level) {
    return this.reviews.find(
      (review) => review.decision === 'escalated' && review.level === level
    );
  }

  isReviewLevelEscalated(level) {
    return this.reviews.some(
      (review) => review.level === level && review.decision === 'escalated'
    );
  }

  canReview(level) {
    return isReviewerLevel(level);
  }

  canApprove(level) {
    return level > REVIEWER_LEVEL;
  }

  canDecline(level) {
    return level > REVIEWER_LEVEL;
  }

  canEscalate(level) {
    if (level === 1 && this.highestApproverLevel > 1) {
      return true;
    }

    const reviewLevels = this.reviews.map((review) => review.level);
    const highestReviewLevel = Math.max(...reviewLevels);

    return highestReviewLevel < this.highestRequiredApproverLevelByLimit;
  }

  canDeescalate(level) {
    if (isReviewerLevel(level)) {
      return false;
    }

    if (level === 1 && !this.approvalHierarchyReviewerEnabled) {
      return false;
    }

    const isReviewLevelActioned = this.reviews.some(
      (review) => review.level === level && isPresent(review.decision)
    );

    if (isReviewLevelActioned) {
      return false;
    }

    // A review can be de-escalated if the one level down from the current
    // approval level had an escalate decision
    return this.reviews.some(
      (review) => review.level === level - 1 && review.decision === 'escalated'
    );
  }

  downloadPDF({ currentUser }) {
    const applicationAPI = api(
      'applications',
      currentUser.accessToken,
      get(currentUser, 'currentEntity.id')
    );

    const win = window.open('', 'pdf_window');
    applicationAPI.getApplicationPDF(this.id, (result) =>
      win.open(get(result, 'data.url'), 'pdf_window')
    );
  }

  update({ attributes, currentUser, onSuccessCallback }) {
    this.isLoading = true;

    const successCallback = (result) => {
      const updatedApplication = new ApplicationModel(
        get(result, 'data.data'),
        get(result, 'data.included')
      );

      this.isLoading = false;
      onSuccessCallback(updatedApplication);
    };

    ApplicationModel.updateApplication({
      accessToken: currentUser.accessToken,
      application: this,
      attributes,
      entityId: get(currentUser, 'currentEntity.id'),
      onSuccessCallback: successCallback,
    });
  }

  async reallocateAndReassign({ attributes, currentUser, onSuccessCallback }) {
    const applicationAPI = api(
      'applications',
      currentUser.accessToken,
      get(currentUser, 'currentEntity.id')
    );

    try {
      await applicationAPI.reallocateAndReassign(this.id, attributes);

      onSuccessCallback();
    } catch (error) {
      console.error(error);
    }
  }

  /** Private functions */

  assignRelationships() {
    /** Single Relationships */
    this.assignSingleRelationship({
      included: this.included,
      key: 'connection',
      model: ConnectionModel,
    });
    this.assignSingleRelationship({
      included: this.included,
      key: 'consumer',
      model: EntityModel,
    });
    this.assignSingleRelationship({
      included: this.included,
      key: 'credit_check_rule',
      model: CreditCheckRuleModel,
    });
    this.assignSingleRelationship({
      included: this.included,
      key: 'identification_check_rule',
      model: IdentificationCheckRuleModel,
    });
    this.assignSingleRelationship({
      key: 'physical_address',
      model: AddressModel,
    });
    this.assignSingleRelationship({
      key: 'postal_address',
      model: AddressModel,
    });
    this.assignSingleRelationship({
      key: 'postal_address',
      model: AddressModel,
    });
    this.assignSingleRelationship({
      key: 'active_stop_credit',
      model: StopCreditModel,
    });

    /** Many relationships */
    this.assignManyRelationship({
      key: 'application_histories',
      model: ApplicationHistoryModel,
    });
    this.assignManyRelationship({
      key: 'credit_checks',
      model: CreditCheckModel,
    });
    this.assignManyRelationship({
      filter: (included) =>
        ['commercial', 'veda_score'].includes(
          get(included, 'attributes.service_type')
        ),
      key: 'veda_checks',
      model: VedaCheckModel,
      overrideKey: 'creditChecks',
    });
    this.assignManyRelationship({
      filter: (included) =>
        ['identity_plus', 'driver_licence', 'passport'].includes(
          get(included, 'attributes.service_type')
        ),
      key: 'veda_checks',
      model: VedaCheckModel,
      overrideKey: 'identificationChecks',
    });
    this.assignManyRelationship({
      included: this.included,
      key: 'addon_answers',
      model: AddonAnswerModel,
    });
    this.assignManyRelationship({ key: 'addon_rules', model: AddonRuleModel });
    this.assignManyRelationship({
      included: this.included,
      key: 'authorisations',
      model: AuthorisationModel,
    });
    this.assignManyRelationship({ key: 'cardholders', model: CardholderModel });
    this.assignManyRelationship({ key: 'guarantors', model: GuarantorModel });
    this.assignManyRelationship({
      included: this.included,
      key: 'application_trading_name_links',
      model: ApplicationTradingNameLinkModel,
    });
    this.assignManyRelationship({
      key: 'notes',
      model: NoteModel,
    });
    this.assignManyRelationship({
      key: 'payments',
      model: PaymentModel,
    });
    this.assignManyRelationship({
      included: this.included,
      key: 'ppsr_financing_statements',
      model: PpsrFinancingStatementModel,
    });
    this.assignManyRelationship({
      included: this.included,
      key: 'reviews',
      model: ReviewModel,
    });
    this.assignManyRelationship({ key: 'signatories', model: SignatoryModel });
    this.assignManyRelationship({
      included: this.included,
      key: 'stop_credits',
      model: StopCreditModel,
    });
    this.assignManyRelationship({
      included: this.included,
      key: 'trade_references',
      model: TradeReferenceModel,
    });
    this.assignSingleRelationship({
      included: this.included,
      key: 'iuf_approval',
      model: IUFApprovalModel,
    });
  }

  findAddonRule(moduleName) {
    return (
      this.addonRules.find(
        (addonRule) => addonRule.addonModuleName === moduleName
      ) || {}
    );
  }

  findAddonAnswer(moduleName) {
    return (
      this.addonAnswers.find(
        (answer) => answer.addonModuleName === moduleName
      ) || {}
    );
  }

  addCreditCheck(creditCheck) {
    this.creditChecks.push(creditCheck);
  }

  getSelectedCreditCheckNameByType(creditCheckType) {
    if (creditCheckType === 'company') {
      return this.companyCreditCheckSelectedName;
    }

    return this.personalCreditCheckSelectedName;
  }

  getSelectedCreditCheckSlugByType(creditCheckType) {
    if (creditCheckType === 'company') {
      return this.companyCreditCheckSelectedSlug;
    }

    return this.personalCreditCheckSelectedSlug;
  }

  createCreditCheck({ creditCheckType, currentUser, person, onSuccess }) {
    const selectedCreditCheck = this.getSelectedCreditCheckSlugByType(
      creditCheckType
    );

    if (selectedCreditCheck === 'nz_equifax') {
      return VedaCheckModel.runCreditCheck({
        application: this,
        creditCheckType,
        currentUser,
        person,
        onSuccess,
      });
    }

    return CreditCheckModel.runCreditCheck({
      application: this,
      creditCheckType,
      currentUser,
      person,
      selectedCreditCheck,
      onSuccess,
    });
  }

  canCreateCreditCheck({ creditCheckType, currentUser, person }) {
    const selectedCreditCheck = this.getSelectedCreditCheckSlugByType(
      creditCheckType
    );

    return CreditCheckModel.canRunCreditCheck({
      application: this,
      creditCheckType,
      currentUser,
      person,
      selectedCreditCheck,
    });
  }

  updateCreditCheck({ creditCheckType, currentUser, person, onSuccess }) {
    const selectedCreditCheck = this.getSelectedCreditCheckSlugByType(
      creditCheckType
    );

    if (selectedCreditCheck === 'nz_equifax') {
      VedaCheckModel.create({
        application: this,
        creditCheckType,
        currentUser,
        person,
        onSuccess,
      });
    } else {
      CreditCheckModel.create({
        application: this,
        creditCheckType,
        currentUser,
        person,
        selectedCreditCheck,
        onSuccess,
      });
    }
  }
}
