import mixpanel from 'mixpanel-browser';
import UserModel from 'models/UserModel';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import ShareTheLoveImg from '../../images/btn-share-the-love.png';
import Modal from '../../modules/shared/components/widgets/static/Modal';
import ShareTheLove from './index.js';
import { ShareTheLoveBtn } from './styles';
import { getShareTheLovePrepopulateData } from './utils';

const ShareTheLoveModal = ({ currentUser, currentEntity }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    mixpanel.track('Share the love - Pop up', {
      'Entity ID': currentEntity.id,
      distinct_id: currentUser.id,
    });

    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  if (!currentEntity || !currentEntity.attributes.supplier) {
    return null;
  }

  return (
    <Fragment>
      <ShareTheLoveBtn type="button" tabIndex={0} onClick={openModal}>
        <img src={ShareTheLoveImg} alt="Share the love button" />
      </ShareTheLoveBtn>

      {modalIsOpen && (
        <Modal dismissHandler={closeModal}>
          <ShareTheLove
            source="In Product"
            userIsLoggedIn={!!currentUser}
            {...getShareTheLovePrepopulateData(currentUser)}
          />
        </Modal>
      )}
    </Fragment>
  );
};

export default connect((state) => {
  return {
    currentEntity: state.current_user.current_entity,
    currentUser: UserModel.fromCurrentUser(state.current_user),
  };
})(ShareTheLoveModal);
