import Input from '@material-ui/core/Input';
import get from 'lodash.get';
import {
  createAddonVersion,
  loadAddons,
  resetCurrentAddonRuleset,
  setAddonConfigValue,
  setAddonEntityType,
  setAddonValue,
} from 'modules/addons/actions';
import AddOnsNotSavePopup from 'modules/addons/addons_form/components/AddOnsNotSavePopup';
import PreviewSaveButtons from 'modules/addons/addons_form/components/PreviewSaveButtons';
import styles from 'modules/addons/components/css/AddOnsDetails.css';
import {
  getConflictingRulesetNames,
  getCreditLimitError,
  getLegalTypesError,
  getRulesetConflicts,
} from 'modules/addons/helpers.js';
import BankNumber from 'modules/addons/paperless/components/PaperlessDetails/BankNumber';
import BorderedTextArea from 'modules/shared/components/inputs/BorderedTextArea';
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import ContentContainer from 'modules/shared/components/v2/ContentContainer';
import AccountTypeSelectDropdown from 'modules/shared/components/v2/Form/SelectDropdown/AccountTypeSelectDropdown';
import EntityTypeSelectDropdown from 'modules/shared/components/v2/Form/SelectDropdown/EntityTypeSelectDropdown';
import LimitRange from 'modules/shared/components/v2/LimitRange/LimitRange';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';

const Stepper = ({ children, header }) => (
  <ContentContainer header={header}>
    <div className="pb-4">{children}</div>
  </ContentContainer>
);

const accountTypeOptions = [
  { brief: 'Credit', label: 'Credit', value: 'credit' },
  { brief: 'Cash', label: 'Cash', value: 'cash' },
];

class PaperlessDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      notSaved: false,
      notSavedPopup: false,
      redirectUrl: null,
    };
  }

  get addonName() {
    const { data, readOnly } = this.props;
    const attributes = data.attributes;
    const name = attributes.name;

    if (readOnly) {
      return `${name} (Version ${attributes.version})`;
    }

    return name;
  }

  addonContentChanged() {
    this.setState({ notSaved: true });
  }

  showNotSavedPopup() {
    this.setState({ notSavedPopup: true });
  }

  hideNotSavedPopup() {
    this.setState({ notSavedPopup: false });
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetCurrentAddonRuleset(moduleName));
  }

  discard() {
    const { handleDiscard } = this.props;
    if (this.state.notSaved) {
      this.showNotSavedPopup();
    } else {
      handleDiscard();
    }
  }

  submit() {
    const { data, dispatch, handleDiscard } = this.props;
    if (this.checkValidAll()) {
      dispatch(
        createAddonVersion(data.attributes, moduleName, () => {
          handleDiscard();
          dispatch(loadAddons(moduleName));
        })
      );
    } else {
      throw 'Paramter is invalid';
    }
  }

  checkValidAll() {
    const { currentEntity, data, paperless } = this.props;
    const { attributes } = data;

    const definedApplicationType = Boolean(attributes.application_types);
    const hasApplicationType = attributes.application_types.length !== 0;

    const hasCreditApplication =
      definedApplicationType && attributes.application_types.includes('credit');

    let creditLimitError = {};
    if (hasCreditApplication) {
      creditLimitError = getCreditLimitError(
        attributes.min_credit_value,
        attributes.max_credit_value,
        get(currentEntity, 'trade_account_limit')
      );
    }

    let applicationTypeError = {};
    if (!definedApplicationType || !hasApplicationType) {
      applicationTypeError = {
        application_types: 'Please select an account application type',
      };
    }

    const conflictingRulesets = getRulesetConflicts(paperless, data);
    let entityError = {};
    if (conflictingRulesets) {
      const conflictingRulesetNames = getConflictingRulesetNames(
        conflictingRulesets
      );
      entityError = {
        legal_types: `The selected entity types conflict with another Paperless ruleset: '${conflictingRulesetNames.join(
          ', '
        )}'.`,
      };
    }

    const legalTypesError = getLegalTypesError(attributes.legal_types);
    const errors = {
      ...legalTypesError,
      ...creditLimitError,
      ...applicationTypeError,
      ...entityError,
    };
    this.setState({ errors });

    return Object.keys(errors).length === 0;
  }

  redirect(url) {
    this.setState({ redirectUrl: url });
    if (this.state.notSaved) {
      this.showNotSavedPopup();
    } else {
      browserHistory.push(url);
    }
  }

  mandatory() {
    const { data, readOnly, region } = this.props;
    const { errors } = this.state;

    return (
      <React.Fragment>
        <div className={styles.info}>
          This is the information we collect from the applicant
        </div>
        <div className={styles.row}>
          <SquareCheckbox
            key={'mandatory'}
            checkboxId={'mandatory'}
            checkboxName={'mandatory'}
            checked={data.attributes.config.mandatory}
            handleChange={() => {
              this.onHandleMandatoryChanges();
            }}
            label={'Are direct debits mandatory?'}
            disabled={readOnly}
          />
        </div>
        <div className={styles.row}>
          <p>First name</p>
          <p>Last name</p>
          <p>Email</p>
          <p>Name of bank account to be debited</p>
          <BankNumber region={region} />
          <p>Account number</p>
        </div>
      </React.Fragment>
    );
  }

  onHandleMandatoryChanges() {
    const { data, dispatch } = this.props;
    const value = !data.attributes.config.mandatory;
    dispatch(setAddonConfigValue('mandatory', value, moduleName));
    this.addonContentChanged();
  }

  onHandleTermsChanges(value) {
    const { dispatch } = this.props;
    dispatch(setAddonConfigValue('terms', value, moduleName));
    this.addonContentChanged();
  }

  terms() {
    const { data, readOnly } = this.props;
    return (
      <React.Fragment>
        <div className={styles.row}>
          <BorderedTextArea
            id={'terms'}
            key={'terms'}
            onChange={(e) => {
              this.onHandleTermsChanges(e.target.value);
            }}
            label={'Special conditions related to notices and disputes'}
            value={data.attributes.config.terms}
            required={false}
            rows={12}
            disabled={readOnly}
          />
        </div>
        <div className={styles.row}>
          Upload t&amp;c&apos;s&nbsp;
          <Link
            onClick={() =>
              this.redirect('/dashboard/profile?active_tab=supplier_terms')
            }
          >
            here
          </Link>
          .
        </div>
      </React.Fragment>
    );
  }

  emailCustomisation() {
    return (
      <React.Fragment>
        <div className={styles.row}>
          Customise the content of the email that the account holder will
          receive&nbsp;
          <Link
            onClick={() =>
              this.redirect('/dashboard/profile?active_tab=email_customisation')
            }
          >
            here
          </Link>
          .
        </div>
      </React.Fragment>
    );
  }

  renderNotSavedPopup() {
    const { handleDiscard } = this.props;

    return (
      <AddOnsNotSavePopup
        handleSave={this.submit.bind(this)}
        handleLeave={handleDiscard}
        hidePopup={this.hideNotSavedPopup.bind(this)}
        redirectUrl={this.state.redirectUrl}
      />
    );
  }

  selectType(event) {
    const { errors } = this.state;
    delete errors.legal_types;
    this.setState({ errors });

    const { dispatch } = this.props;
    dispatch(setAddonEntityType(event.target.id, moduleName));
    this.addonContentChanged();
  }

  setValue(name, value) {
    this.resetCreditLimitError(name);

    const { dispatch } = this.props;
    dispatch(setAddonValue(name, value, moduleName));
    this.addonContentChanged();
  }

  resetCreditLimitError(name) {
    if (!['max_credit_value', 'min_credit_value'].includes(name)) {
      return;
    }

    const { errors } = this.state;
    delete errors.credit_limit;
    this.setState({ errors });
  }

  render() {
    const { currentEntity, data, readOnly, updating } = this.props;
    const { errors, notSavedPopup } = this.state;

    return (
      <section className={styles.container}>
        <div>
          {data.serial_number && (
            <span className={styles.serial}>#{data.serial_number}</span>
          )}
          <Input
            classes={{ input: styles.name_text, root: styles.input_parent }}
            id="name"
            value={this.addonName}
            placeholder="Name your ruleset here - eg: Company"
            onChange={(e) => {
              this.setValue(e.target.id, e.target.value);
            }}
            required
            disabled={readOnly}
          />
          <CloseButton handleClick={this.discard.bind(this)} />
        </div>
        <div className={styles.section}>
          <div className={styles.row}>
            <div className={styles.info}>{description}</div>
          </div>
        </div>
        <div className="mt-6">
          <Stepper header="Account Types">
            <div className={`${styles.info} pb-4`}>
              Select the account type(s) you wish to apply to this Add on.
            </div>
            <div className="columns">
              <div className="column is-6">
                <AccountTypeSelectDropdown
                  handleChange={(target) =>
                    this.setValue('application_types', target.value)
                  }
                  value={data.attributes.application_types || []}
                  multiple
                  bulkSelect
                  disabled={readOnly}
                  options={accountTypeOptions}
                  error={errors.application_types}
                />
              </div>
            </div>
          </Stepper>
          <Stepper header="Entity Types">
            <div className={`${styles.info} pb-4`}>
              Select the entity type(s) you wish to apply to this Add on.
            </div>
            <div className="columns">
              <div className="column is-6">
                <EntityTypeSelectDropdown
                  bulkSelect
                  disabled={readOnly}
                  handleChange={(target) =>
                    this.setValue('legal_types', target.value)
                  }
                  multiple
                  value={data.attributes.legal_types || []}
                  error={errors.legal_types}
                />
              </div>
            </div>
          </Stepper>
          {get(data, 'attributes.application_types', []).includes('credit') && (
            <Stepper header="Limit Range">
              <div className={`${styles.info} pb-4`}>
                The direct debit module will only display in the customer
                application flow where the applicant applies for credit between
                the min - max range you select here.
              </div>
              <LimitRange
                errors={errors}
                handleLimitChange={(key, value) => this.setValue(key, value)}
                readOnly={readOnly}
                tradeAccountLimit={get(currentEntity, 'trade_account_limit')}
                values={{
                  maxCreditValue: get(data, 'attributes.max_credit_value', 0),
                  minCreditValue: get(data, 'attributes.min_credit_value', 0),
                }}
              />
            </Stepper>
          )}
          <Stepper header="Mandatory Fields">{this.mandatory()}</Stepper>
          <Stepper header="Terms">{this.terms()}</Stepper>
          <Stepper header="Email Customisation">
            {this.emailCustomisation()}
          </Stepper>
        </div>
        <PreviewSaveButtons
          moduleName={moduleName}
          previewAddon={data.attributes}
          handleSubmit={() => {
            this.submit();
          }}
          loading={updating}
          readOnly={readOnly}
        />
        {notSavedPopup && this.renderNotSavedPopup()}
      </section>
    );
  }
}

const moduleName = 'paperless';
const description =
  "You must be an authorised Paperless Direct Debit agent, signed off by your bank. \
This capability is usually available to Enterprise level business only.  When setting this up consider \
entity types - eg:  unlikely government departments accept, and limits, so lower limits generally suit \
direct debits.  Your prospect experience will be dynamically changed based on how you set this up.  \
Once set up click on the 'view' tab at bottom of the screen for your customers experience.";

export default connect((state) => {
  const data = state.add_ons.current_paperless;
  const paperless = state.add_ons.paperless;
  const updating = state.add_ons.paperless_updating;

  const region = get(state, 'current_user.current_entity.attributes.region');

  return {
    currentEntity: get(state, 'current_user.current_entity.attributes'),
    data,
    paperless,
    region,
    updating,
  };
})(PaperlessDetails);
