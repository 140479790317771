import React, { ReactElement } from 'react';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import styles from 'modules/new-applications/css/LimitAndApprovals.css';

const getReviewMessage = (review) => {
  if (review.decision === 'escalated') {
    return `Escalated approval from L${review.level} to L${review.level +
      1} on ${review.formattedReviewedAt}.`;
  }

  return `${review.formattedDecision} at ${review.formattedReviewedAt} (approval hierarchy version ${review.approvalHierarchyVersion})`;
};

const getApprovedMessage = (application, review) => {
  let actionMessage = 'Recommended';

  if (application.finalReviewId === review.id) {
    actionMessage = 'Approved';
  }

  return `${actionMessage} ${review.formattedTradeAccountLimit} with ${review.formattedDiscount} discount`;
};

const ApproverContent = ({
  application,
  review,
}: {
  application: { [key: string]: any };
  review: { [key: string]: any };
}): ReactElement => (
  <FixedContent withBottomSeparator={false} withBottomMargin={false}>
    <p className={styles.approver_name}>
      {`Approver name: ${review.reviewerName}`}
    </p>
    {review.decision === 'accepted' && (
      <p>{getApprovedMessage(application, review)}</p>
    )}
    <p>{getReviewMessage(review)}</p>
    <p>{review.notes || 'No notes.'}</p>
  </FixedContent>
);

export default ApproverContent;
