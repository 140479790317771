/* TODO - Improve legacy codes */
import LegalEntityAgreementUploader, {
  isLegalEntityAgreementUploaderVisible,
} from 'modules/legal-entity-agreement-uploader/components/LegalEntityAgreementUploader';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import DateString from 'modules/shared/components/widgets/static/DateString';
import LabeledInformation from 'modules/shared/components/widgets/static/LabeledInformation';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import extractAttachmentUrl from 'utils/extractAttachmentUrl';
import styles from './css/Review.css';
import { getTradingNameTitleByRegion } from 'utils/entityTypeTitle';

const getNonCompanyBusinessComponents = (props) => {
  const { application, region, tradingNameLabel } = props;

  if (region === 'AU') {
    return [
      <ReviewContainer
        key="entity_name"
        css_class="block_noborder"
        subHeading="Business information"
      >
        <div className={styles.full_input}>
          <LabeledInformation
            label="Entity name"
            data={application.company_name}
          />
        </div>
      </ReviewContainer>,
      <ReviewContainer key="trading_name" css_class="block_noborder">
        <div className={styles.col}>
          <LabeledInformation
            label={tradingNameLabel}
            data={application.trading_name}
            noHumanize={true}
          />
        </div>
        <div className={styles.col}>
          <LabeledInformation
            label="Business type"
            data={application.legal_type}
          />
        </div>
      </ReviewContainer>,
    ];
  }

  return [
    <ReviewContainer
      key="trading_name"
      subHeading="Business information"
      css_class="block_noborder"
    >
      <div className={styles.col}>
        <LabeledInformation
          label={tradingNameLabel}
          data={application.trading_name}
          noHumanize={true}
        />
      </div>
      <div className={styles.col}>
        <LabeledInformation
          label="Business type"
          data={application.legal_type}
        />
      </div>
    </ReviewContainer>,
  ];
};

function ReviewBusiness(props) {
  const { data } = props;
  const attr = data.consumer.attributes;
  const application = data.application.attributes;

  const company_info = [];
  const people = [];
  let peopleMarkup = null;
  let personLabel = 'Director';
  const business_info = [];

  if (application.legal_type === 'trust') {
    personLabel = 'Trustee';
  } else if (application.legal_type === 'partnership') {
    personLabel = 'Partner';
  } else if (application.legal_type === 'personal') {
    personLabel = 'Individual';
  }
  if (data.people) {
    if (application.region === 'NZ' && application.legal_type === 'company') {
      data.people.forEach((v, i) => {
        people.push(
          <div className={styles.people_col} key={`person-${i}`}>
            <LabeledInformation
              label={`${personLabel} ${i + 1}`}
              data={v.attributes.name}
            />
            <LabeledInformation
              label="Appointment date"
              noHumanize={true}
              data={
                v.attributes.appointed_at ? (
                  <DateString value={v.attributes.appointed_at} />
                ) : (
                  '-'
                )
              }
            />
            <LabeledInformation
              label="Residential address"
              noHumanize={true}
              data={
                v.attributes.full_address
                  ? v.attributes.full_address.street
                  : '-'
              }
            />
          </div>
        );
      });
    } else if (application.legal_type === 'personal') {
      data.people.forEach((v, i) => {
        people.push(
          <div className={styles.people_col} key={`person-${i}`}>
            <LabeledInformation
              label={`${personLabel} ${i + 1}`}
              data={v.attributes.name}
            />
            <LabeledInformation
              label="Previous last name"
              data={v.attributes.previous_last_name}
              showEmpty={true}
            />
            <LabeledInformation
              label="Email"
              noHumanize={true}
              data={v.attributes.email}
            />
            <LabeledInformation
              label="Phone number"
              noHumanize={true}
              data={`${v.attributes.contact_country_code} ${v.attributes.contact_phone_number}`}
            />
            <LabeledInformation
              label="Residential address"
              noHumanize={true}
              data={
                v.attributes.full_residential_address
                  ? v.attributes.full_residential_address.split(',')
                  : '-'
              }
            />
            <LabeledInformation
              label="Residential type"
              data={v.attributes.residential_type}
            />
            <LabeledInformation
              label="Postal address"
              noHumanize={true}
              data={
                v.attributes.full_postal_address
                  ? v.attributes.full_postal_address.split(',')
                  : '-'
              }
            />
            <LabeledInformation
              label="Employer"
              data={v.attributes.employer}
              showEmpty={true}
            />
            <LabeledInformation
              label="Position"
              data={v.attributes.position}
              showEmpty={true}
            />
          </div>
        );
      });
    } else {
      data.people.forEach((v, i) => {
        people.push(
          <div className={styles.people_col} key={`person-${i}`}>
            <LabeledInformation
              label={`${personLabel} ${i + 1}`}
              data={v.attributes.name}
            />
            <LabeledInformation
              label="Email"
              noHumanize={true}
              data={v.attributes.email}
            />
          </div>
        );
      });
    }

    if (people.length) {
      if (people.length > 1) personLabel = `${personLabel}s`;
      peopleMarkup = (
        <ReviewContainer
          css_class="block_noborder"
          content_class="content_people"
          subHeading={`${personLabel}`}
        >
          {people}
        </ReviewContainer>
      );
    }
  }

  let physicalAddress = '';
  if (data.physical_address) {
    physicalAddress = data.physical_address.attributes.full_address;
  }

  let postalAddress = '';
  if (data.postal_address) {
    postalAddress = data.postal_address.attributes.full_address;
  }

  let locationDetails = '';
  if (application.region === 'NZ') {
    locationDetails = 'New Zealand';
  } else if (application.region === 'AU') {
    locationDetails = `Australia, ${application.state}`;
  }

  const trading_name_label = getTradingNameTitleByRegion({
    entityType: application.legal_type,
    region: application.region,
  });

  let registration_date_label = 'Business start date';
  let registration_date = moment(application.registered_at).format(
    'DD/MM/YYYY'
  );
  if (
    [
      'education',
      'society',
      'association',
      'club',
      'government',
      'other',
    ].includes(application.legal_type)
  ) {
    const current_year = new Date().getFullYear();
    const registration_date_year = moment(application.registered_at).year();
    registration_date = `${`${current_year}` - registration_date_year}`;
    registration_date_label = 'Years in operation';
  }

  if (application.legal_type === 'company' || application.region === 'AU') {
    company_info.push(
      <ReviewContainer key="business-number" css_class="block_noborder">
        <div className={styles.col}>
          <LabeledInformation
            label={application.region === 'NZ' ? 'Company number' : 'ACN'}
            data={application.company_number ? application.company_number : '-'}
            noHumanize={true}
          />
        </div>
        <div className={styles.col}>
          <LabeledInformation
            label={application.region === 'NZ' ? 'Business number' : 'ABN'}
            data={
              application.business_number ? application.business_number : '-'
            }
            noHumanize={true}
          />
        </div>
      </ReviewContainer>
    );
  }

  if (attr.legal_type === 'personal') {
    business_info.push(
      <ReviewContainer
        key="customer-information"
        subHeading="Customer information"
        css_class="block_noborder"
      >
        <div className={styles.col}>
          <LabeledInformation
            label="Entity type"
            data={application.legal_type}
          />
        </div>
        <div className={styles.col}>
          <LabeledInformation
            label="Personal type"
            data={people.length > 1 ? 'Joint' : 'Individual'}
          />
        </div>
      </ReviewContainer>
    );
    business_info.push(
      <ReviewContainer key="customer-information-2" css_class="block_noborder">
        <div className={styles.col}>
          <LabeledInformation
            label={trading_name_label}
            data={application.trading_name}
            noHumanize={true}
          />
        </div>
        <div className={styles.col}>
          <LabeledInformation
            label="Account type"
            data={application.application_type}
          />
        </div>
      </ReviewContainer>
    );
  } else if (attr.legal_type === 'company') {
    business_info.push(
      <ReviewContainer
        key="business-information"
        subHeading="Business information"
        css_class="block_noborder"
      >
        <div className={styles.col}>
          <LabeledInformation
            label="Company/Business name"
            noHumanize={true}
            data={application.company_name ? application.company_name : '-'}
          />
        </div>
        <div className={styles.col}>
          <LabeledInformation
            label={trading_name_label}
            data={application.trading_name}
            noHumanize={true}
          />
        </div>
      </ReviewContainer>
    );
    business_info.push(
      <ReviewContainer key="business-type" css_class="block_noborder">
        <div className={styles.col}>
          <LabeledInformation
            label="Business type"
            data={application.legal_type}
          />
        </div>
        <div className={styles.col}>
          <LabeledInformation
            label="Account type"
            data={application.application_type}
          />
        </div>
      </ReviewContainer>
    );
  } else {
    const nonCompanyBusinessComponent = getNonCompanyBusinessComponents({
      application,
      region: application.region,
      tradingNameLabel: trading_name_label,
    });
    business_info.push(...nonCompanyBusinessComponent);
    business_info.push(
      <ReviewContainer key="account-type" css_class="block_noborder">
        <div className={styles.col}>
          <LabeledInformation
            label="Account type"
            data={application.application_type}
          />
        </div>
        <div className={styles.col}>
          <LabeledInformation
            label={registration_date_label}
            data={registration_date}
          />
        </div>
      </ReviewContainer>
    );
  }

  function renderLegalEntityAgreementUploader() {
    const {
      legal_entity_agreement,
      legal_type,
      partnership_agreement_upload_visible,
      trust_deed_upload_visible,
    } = data.application.attributes;

    const legalEntityAgreement = extractAttachmentUrl(
      legal_entity_agreement,
      'legal_entity_agreement'
    );
    const isVisible = isLegalEntityAgreementUploaderVisible({
      entityType: legal_type,
      partnershipAgreementUploadVisible: partnership_agreement_upload_visible,
      trustDeedUploadVisible: trust_deed_upload_visible,
    });

    if (!isVisible) {
      return;
    }

    return (
      <ReviewContainer key="legal-entity-agreement">
        <LegalEntityAgreementUploader
          entityType={legal_type}
          partnershipAgreementUploadVisible={
            partnership_agreement_upload_visible
          }
          trustDeedUploadVisible={trust_deed_upload_visible}
          legalEntityAgreement={legalEntityAgreement}
          disabled={true}
          onUpload={() => null}
        />
      </ReviewContainer>
    );
  }

  return (
    <section className={styles.expandable}>
      <span className={styles.shadow} />
      {business_info}
      {company_info}

      <ReviewContainer
        css_class="block_noborder"
        hideCheck={application.legal_type.includes('personal')}
      >
        <div className={styles.col}>
          <LabeledInformation
            label="Business phone number"
            data={application.contact_phone_number}
          />
        </div>
        <div className={styles.col}>
          <LabeledInformation
            label="Number of staff"
            data={application.staff_count}
          />
        </div>
      </ReviewContainer>
      <ReviewContainer
        css_class="block_noborder"
        hideCheck={[
          'personal',
          'education',
          'society',
          'association',
          'club',
          'government',
          'other',
        ].includes(application.legal_type)}
      >
        <div className={styles.col}>
          <LabeledInformation
            label="Business location"
            noHumanize={true}
            data={locationDetails ? locationDetails : '-'}
          />
        </div>
      </ReviewContainer>
      <ReviewContainer
        css_class="block_noborder"
        hideCheck={
          ![
            'education',
            'society',
            'association',
            'club',
            'government',
            'other',
          ].includes(application.legal_type)
        }
      >
        <div className={styles.col}>
          <LabeledInformation
            label="Incorporation/Registration number"
            noHumanize={true}
            data={application.incorporated_number || '-'}
          />
        </div>
        <div className={styles.col}>
          <LabeledInformation
            label="Business location"
            noHumanize={true}
            data={locationDetails ? locationDetails : '-'}
          />
        </div>
      </ReviewContainer>
      <ReviewContainer css_class="block_bottom_border">
        <div className={styles.col}>
          <LabeledInformation label="Industry" data={attr.industry} />
        </div>
        <div className={styles.col}></div>
      </ReviewContainer>

      {renderLegalEntityAgreementUploader()}

      <ReviewContainer
        subHeading="Address"
        hideCheck={application.legal_type.includes('personal')}
        css_class="block_noborder"
      >
        <div className={styles.col}>
          <LabeledInformation
            label="Business Address"
            noHumanize={true}
            data={physicalAddress}
          />
        </div>
        <div className={styles.col}>
          <LabeledInformation
            label="Postal/delivery Address"
            noHumanize={true}
            data={postalAddress ? postalAddress : '-'}
          />
        </div>
      </ReviewContainer>

      <ReviewContainer
        css_class="block_bottom_border"
        hideCheck={application.legal_type.includes('personal')}
      >
        <div className={styles.col}>
          <LabeledInformation
            label="Delivery instructions"
            noHumanize={true}
            data={application.delivery_instruction || '-'}
          />
        </div>
        <div className={styles.col}></div>
      </ReviewContainer>

      {peopleMarkup}
    </section>
  );
}

export default connect()(ReviewBusiness);
