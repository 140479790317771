import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { countTypeValue } from './helpers/summary';
import { displayEmpty } from 'utils/formatting';
import { enquiryResponseData } from './helpers/reportHelpers';
import { stringToSimpleDate } from 'utils/dateFormatter';
import styles from '../../../css/CommonReviewBusiness.css';

var CurrentSecretaries = createClass({
  _address(address) {
    if (address === null || address === undefined) return displayEmpty(address);

    return [
      address.street_details,
      address.locality_details,
      address.state,
      address.postcode,
    ].join(' ');
  },

  _birthDetails(birthDetails) {
    if (birthDetails === null || birthDetails === undefined)
      return displayEmpty(birthDetails);

    return [birthDetails.birth_locality, birthDetails.birth_state].join(' ');
  },

  _documentDetails(documentDetails) {
    if (documentDetails === null || documentDetails === undefined)
      return displayEmpty(documentDetails);

    return displayEmpty(documentDetails.document_number);
  },

  _secretaries() {
    const { data, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      secretaryList = response.ownership_officers.secretary_list || {},
      secretaries = secretaryList.secretaries || [];

    if (secretaries.constructor === Object) secretaries = [secretaries];

    return secretaries.map((secretary, index) => {
      let individualOfficer = secretary.individual_officer,
        name = individualOfficer.individual_name;

      return (
        <div className={styles.table_wrapper} key={index}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Secretary Name</th>
                <td>
                  {''.concat(
                    name.family_name,
                    ', ',
                    name.first_given_name,
                    ' ',
                    name.other_given_name
                  )}
                </td>
                <th>Place of Birth</th>
                <td>{this._birthDetails(individualOfficer.birth_details)}</td>
              </tr>
              <tr>
                <th>Appointment Date</th>
                <td>{stringToSimpleDate(secretary.appointment_date)}</td>
                <th>Address</th>
                <td>{this._address(secretary.address_lines)}</td>
              </tr>
              <tr>
                <th>Date of Birth</th>
                <td>{stringToSimpleDate(individualOfficer.date_of_birth)}</td>
                <th>Document Number</th>
                <td>{this._documentDetails(secretary.document_details)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  },

  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    return (
      <ReviewContainer
        subHeading="Current Secretaries"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <strong>Total: {countTypeValue(summaryEntry, 'Secretaries')}</strong>
        {this._secretaries()}
      </ReviewContainer>
    );
  },
});

export default CurrentSecretaries;
