import React, { Component } from 'react';
import {
  isHeadquarter,
  isRegular,
} from 'modules/shared/helpers/headquarterDetect';
import { loadAddOns, loadAutoDecisions } from '../actions';
import AdditionalList from './AdditionalList';
import AdminInnerTitle from 'modules/shared/components/widgets/static/AdminInnerTitle';
import AutoDecisioningList from './AutoDecisioningList';
import CardList from './CardList';
import Clickable from 'modules/shared/components/widgets/interactive/Clickable';
import DigitalOnboarding from './DigitalOnboarding';
import EntityStructure from './EntityStructure';
import FinancialList from './FinancialList';
import IntegrationList from 'modules/integrations/components/IntegrationList';
import PaperlessList from './PaperlessList';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip.js';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import styles from './css/AddOns.css';
import mixpanel from 'mixpanel-browser';
import SalesChannels from '../sales_channels/SalesChannels';
import BarLoader from 'modules/shared/components/widgets/static/BarLoader/BarLoader';

class AddOns extends Component {
  constructor() {
    super();
    this.state = {
      toolTipTabName: '',
    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(loadAddOns());
    dispatch(loadAutoDecisions());
  }

  changeTab(v) {
    const { currentUser, location } = this.props;

    mixpanel.track('Add ons - tab clicked', {
      'Tab name': v,
      distinct_id: currentUser.id,
    });

    browserHistory.push({
      pathname: location.pathname,
      query: Object.assign(location.query, { active_tab: v }),
      state: location.state,
    });
  }

  mouseOver(tabName) {
    this.setState({ toolTipTabName: tabName });
  }

  mouseOut() {
    this.setState({ toolTipTabName: '' });
  }

  render() {
    const {
      title,
      loading,
      currentUserLoading,
      possibleTabs,
      activeTab,
    } = this.props;

    const isLoading = loading || currentUserLoading;
    if (isLoading) {
      return <BarLoader text="Getting everything ready..." quickLoad />;
    }

    const tabs = [];
    Object.keys(possibleTabs).forEach((k) => {
      let tabClass = 'tab_default';
      if (possibleTabs[k] !== 0) {
        if (activeTab === k) {
          tabClass = 'tab_active';
        }
        const tabText = tabTexts[k];
        tabs.push(
          <Clickable
            key={k}
            target={() => this.changeTab(k)}
            onMouseOver={() => this.mouseOver(k)}
            onMouseOut={() => this.mouseOut()}
          >
            <PopperTooltip
              title={tabText.tooltip}
              open={Boolean(tabText.tooltip) && this.state.toolTipTabName === k}
              placement={'bottom'}
            >
              <span className={styles[tabClass]}>{tabText.name}</span>
            </PopperTooltip>
          </Clickable>
        );
      }
    });

    return (
      <section className={styles.section}>
        <div className={styles.header}>
          <AdminInnerTitle text={title} isNormalFont={true} />
        </div>
        <div className={styles.tabs}>{tabs}</div>
        {possibleTabs[activeTab]}
      </section>
    );
  }
}

const tabTexts = {
  '1account': {
    name: '1account',
  },
  additional: {
    name: 'additional',
    tooltip: 'Add additional questions to your applications here.',
  },
  auto_decisioning: {
    name: 'auto decisioning',
    tooltip:
      'Automate your credit policy framework to allow the system to approve or refer applications as they come through.',
  },
  cards: {
    name: 'cards',
    tooltip:
      'If you issue cards to your customers, you can now collect appropriate cardholder information to facilitate this process.',
  },
  connected_apps: {
    name: 'connected apps',
  },
  digital_onboarding: {
    name: 'credit control',
    tooltip:
      'These modules allow you easy access and set up of functionality that informs the customer onboarding process.',
  },
  financials: {
    name: 'financials',
    tooltip:
      'Collect financials from select entity type(s) & credit limit range.',
  },
  paperless: {
    name: 'direct debit',
    tooltip:
      'For authorised suppliers who have Paperless Direct Debit sign off from their bank.',
  },
  sales_channels: {
    name: 'sales channels',
  },
};

export default connect((state, ownProps) => {
  let commonTabs = {
    sales_channels: [
      <SalesChannels key="sales_channels" location={ownProps.location} />,
    ],
    auto_decisioning: [
      <AutoDecisioningList
        key="auto_decisioning"
        location={ownProps.location}
      />,
    ],
    paperless: [<PaperlessList key="paperless" location={ownProps.location} />],
    financials: [
      <FinancialList key="financials" location={ownProps.location} />,
    ],
    additional: [
      <AdditionalList key="additional" location={ownProps.location} />,
    ],
    cards: [<CardList key="cards" location={ownProps.location} />],
    digital_onboarding: [
      <DigitalOnboarding
        key="digital_onboarding"
        location={ownProps.location}
      />,
    ],
    connected_apps: [<IntegrationList />],
  };

  const defaults = {
    active_tab: 'sales_channels',
    branch_tabs: { ...commonTabs },
    context_title: 'Settings',
    headquarter_tabs: {
      '1account': [
        <EntityStructure key="1account" location={ownProps.location} />,
      ],
      ...commonTabs,
    },
    regular_tabs: { ...commonTabs },
    title: 'Add Ons',
  };

  let activeTab = ownProps.location.query.active_tab || defaults.active_tab;
  let possibleTabs = defaults.branch_tabs;

  if (isRegular()) {
    possibleTabs = defaults.regular_tabs;
  }

  // enable 1ccount by default?
  if (
    isHeadquarter()
    // || state.current_user.current_entity.attributes.entity_plan_no === 0
  ) {
    possibleTabs = defaults.headquarter_tabs;
    activeTab = ownProps.location.query.active_tab || '1account';
  }

  return {
    activeTab,
    context_title: defaults.context_title,
    currentUser: state.current_user,
    currentUserLoading: state.current_user.loading,
    loading: state.add_ons.add_ons_loading,
    possibleTabs,
    show_call_to_action: false,
    title: defaults.title,
    user_entity_links: state.current_user.data.included
      ? state.current_user.data.included
      : null,
  };
})(AddOns);
