import React, { ReactElement } from 'react';
import isPresent from 'utils/isPresent';
import SelectField from 'modules/consumer-onboarding/v2/ApplicationDetails/SelectField';

const LABEL = "Please select who you'd like to trade with";

const TradingNameField = (props): ReactElement | null => {
  const {
    errors,
    loadedSupplierTradingNameId,
    loadedSupplierName,
    name,
    onChange,
    options,
    rawOptions,
    watch,
  } = props;

  if (isPresent(loadedSupplierTradingNameId)) {
    return (
      <SelectField
        errors={errors}
        isDisabled={true}
        isVisible={true}
        label={LABEL}
        name={name}
        onChange={onChange}
        options={[
          {
            label: loadedSupplierName,
            value: loadedSupplierTradingNameId,
          },
        ]}
        watch={watch}
      />
    );
  }

  return (
    <SelectField
      errors={errors}
      isDisabled={false}
      isVisible={isPresent(options)}
      label={LABEL}
      name={name}
      onChange={onChange}
      options={options}
      rawOptions={rawOptions}
      watch={watch}
    />
  );
};

export default TradingNameField;
