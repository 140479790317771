import api from 'api';

import {
  AUTO_DECISIONS_SET_COMPANYS,
  AUTO_DECISIONS_SET_TIME_IN_BUSINESS,
  AUTO_DECISIONS_SET_VALUE,
  AUTO_DECISIONS_CREATE_RULESET,
  AUTO_DECISIONS_CREATE_RULESET_SUCCESS,
  AUTO_DECISIONS_CREATE_RULESET_ERROR,
  AUTO_DECISIONS_UPDATE_RULESET,
  AUTO_DECISIONS_UPDATE_RULESET_SUCCESS,
  AUTO_DECISIONS_UPDATE_RULESET_ERROR,
  AUTO_DECISIONS_SET_RULESET,
  AUTO_DECISIONS_RESET_CURRENT,
} from './constants';
import get from 'lodash.get';
import isPresent from 'utils/isPresent';

export function toggleEntityType(type) {
  return (dispatch, getState) => {
    let list =
      getState().auto_decisions.current_rule_set.attributes.legal_types.slice(
        0
      ) || []; //need this to clone array and stop referencing the same array
    if (list && list.includes(type)) {
      list.splice(list.indexOf(type), 1);
    } else {
      list.push(type);
    }
    dispatch({
      type: AUTO_DECISIONS_SET_COMPANYS,
      payload: list,
    });
  };
}

export function resetCurrentRuleset() {
  return (dispatch) => {
    dispatch({
      type: AUTO_DECISIONS_RESET_CURRENT,
    });
  };
}

export function setTimeInBusiness(value) {
  return (dispatch) => {
    dispatch({
      type: AUTO_DECISIONS_SET_TIME_IN_BUSINESS,
      payload: value,
    });
  };
}

export function setCurrentRuleset(rule_set) {
  return (dispatch) => {
    if (!rule_set) {
      rule_set = {
        attributes: {
          legal_types: [],
        },
      };
    }
    dispatch({
      type: AUTO_DECISIONS_SET_RULESET,
      payload: rule_set,
    });
  };
}

const setCreditCheckValue = (property, value) => {
  return (dispatch, getState) => {
    dispatch({
      payload: { property, value },
      type: AUTO_DECISIONS_SET_VALUE,
    });

    const creditCheckRules = get(
      getState(),
      'current_user.current_entity.attributes.selected_credit_check_rules',
      {}
    );

    const hasPersonalCreditCheckRule =
      isPresent(creditCheckRules.nz_personal_rule_selection) ||
      isPresent(creditCheckRules.au_personal_rule_selection);

    if (hasPersonalCreditCheckRule) {
      dispatch({
        payload: { property: 'guarantors', value: true },
        type: AUTO_DECISIONS_SET_VALUE,
      });

      dispatch({
        payload: { property: 'guarantors_count', value: 1 },
        type: AUTO_DECISIONS_SET_VALUE,
      });

      dispatch({
        payload: { property: 'signatories', value: true },
        type: AUTO_DECISIONS_SET_VALUE,
      });

      dispatch({
        payload: { property: 'signatories_count', value: 1 },
        type: AUTO_DECISIONS_SET_VALUE,
      });
    }
  };
};

export function setValue(property, value) {
  if (property === 'credit_checks_required') {
    return (dispatch) => dispatch(setCreditCheckValue(property, value));
  }

  return (dispatch) => {
    dispatch({
      type: AUTO_DECISIONS_SET_VALUE,
      payload: { property, value },
    });
  };
}

function transformRuleSet(rule_set) {
  return { ...rule_set, name: rule_set.name || 'Auto Decisioning Ruleset' };
}

export function createRuleSet(rule_set, onSuccess) {
  return (dispatch, getState) => {
    dispatch({
      type: AUTO_DECISIONS_CREATE_RULESET,
      payload: rule_set,
    });

    const rulesets = api(
      'auto_decisioning_rulesets',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    rulesets.createRuleSet(
      transformRuleSet(rule_set),
      (success) => {
        dispatch({
          type: AUTO_DECISIONS_CREATE_RULESET_SUCCESS,
          payload: success.data,
          meta: {
            mixpanel: {
              event: 'Create auto decisioning ruleset',
              props: {
                distinct_id: getState().current_user.data.data.id,
                'Entity ID': getState().current_user.current_entity.id,
                Ruleset: success.data,
              },
            },
          },
        });
        dispatch(resetCurrentRuleset());
        onSuccess();
      },
      (error) => {
        dispatch({
          type: AUTO_DECISIONS_CREATE_RULESET_ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateRuleSet(id, rule_set, onSuccess) {
  return (dispatch, getState) => {
    dispatch({
      type: AUTO_DECISIONS_UPDATE_RULESET,
      payload: rule_set,
    });

    var rulesets = api(
      'auto_decisioning_rulesets',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    rulesets.updateRuleSet(
      id,
      transformRuleSet(rule_set),
      (success) => {
        dispatch({
          type: AUTO_DECISIONS_UPDATE_RULESET_SUCCESS,
          payload: success.data,
          meta: {
            mixpanel: {
              event: 'Update auto decisioning ruleset',
              props: {
                distinct_id: getState().current_user.data.data.id,
                'Ruleset ID': id,
                'Entity ID': getState().current_user.current_entity.id,
                Ruleset: success.data,
              },
            },
          },
        });
        onSuccess();
      },
      (error) => {
        dispatch({
          type: AUTO_DECISIONS_UPDATE_RULESET_ERROR,
          payload: error,
        });
      }
    );
  };
}
