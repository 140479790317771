import React, { ReactElement } from 'react';
import isBlank from 'utils/isBlank';

const SignatureDetails = ({
  label,
  content,
}: {
  label: string;
  content: string | undefined | null;
}): null | ReactElement => {
  if (isBlank(content)) {
    return null;
  }

  return <div>{`${label}: ${content}`}</div>;
};

export default SignatureDetails;
