/* Import libraries */

/* Import actions */
import { loadTeam } from 'modules/manage-users/actions';
import CreateUser from 'modules/manage-users/components/CreateUser';
import RemoveUser from 'modules/manage-users/components/RemoveUser';
/* Import components */
import UserList from 'modules/manage-users/components/UserList';
import PlusButton from 'modules/shared/components/inputs/PlusButton';
import { isBranch } from 'modules/shared/helpers/headquarterDetect';
import React from 'react';
import { connect } from 'react-redux';

import { showPricingPlan } from '../../pricing-plans/actions';
import { createUser, createUserEntityLink, removeUser } from '../actions';
/* Import CSS */
import styles from './css/CommonEditProfile.css';
import SsoComponent from './SsoComponent';
/* Import actions */

const ManageTeam = createClass({
  createUser(attributes, isExistsUser) {
    const { dispatch } = this.props;
    this.setState({
      creating_user: true,
    });
    if (isExistsUser) {
      dispatch(
        createUserEntityLink(attributes, () => {
          this.setState({
            creating_user: false,
            showCreateUser: false,
          });
          dispatch(loadTeam());
        })
      );
    } else {
      dispatch(
        createUser(attributes, () => {
          this.setState({
            creating_user: false,
            showCreateUser: false,
          });
          dispatch(loadTeam());
        })
      );
    }
  },

  getCreateUserModal() {
    const { showCreateUser, creating_user } = this.state;
    return showCreateUser ? (
      <CreateUser
        submitting={creating_user}
        onCancel={this.showCreateUser.bind(null, false)}
        onSubmit={this.createUser}
      />
    ) : null;
  },

  getInitialState() {
    return {
      creating_user: false,
      removeUserModal: null,
      removing_user: false,
      showCreateUser: false,
    };
  },

  hideRemoveUser() {
    this.setState({
      removeUserModal: null,
      removing_user: false,
    });
  },

  removeUser(user) {
    const { dispatch } = this.props;
    dispatch(
      removeUser(user.id, () => {
        this.hideRemoveUser();
        dispatch(loadTeam());
      })
    );
  },

  removeUserModal(id) {
    const { user_list } = this.props;
    const user = user_list.find((v) => v.id === id);
    this.setState({
      removeUserModal: (
        <RemoveUser
          user={user}
          onRemove={this.removeUser}
          onCancel={this.hideRemoveUser}
        />
      ),
    });
  },
  render() {
    const { title, location, loading, user_list, isSupplier } = this.props;
    const { removeUserModal } = this.state;

    return (
      <div className={styles.row}>
        <div className={styles.block_wide}>
          <div className={styles.liner}>
            <h2 className={styles.header}>{title}</h2>
            {!isBranch() && (
              <SsoComponent
                eid={this.props.entity.id}
                access_token={this.props.access_token}
              />
            )}

            {isSupplier && (
              <div className={styles.button}>
                <PlusButton
                  text="Add"
                  handleClick={this.showCreateUser.bind(null, true)}
                  style={isBranch() ? { marginTop: 30 } : { marginTop: 78 }}
                />
              </div>
            )}
            <UserList
              location={location}
              user_list={user_list}
              loading={loading}
              onRemove={this.removeUserModal}
            />
          </div>
        </div>
        {this.getCreateUserModal()}
        {removeUserModal}
      </div>
    );
  },

  showCreateUser(show) {
    const { dispatch, current_pricing_plan } = this.props;
    if (current_pricing_plan) {
      if (current_pricing_plan.attributes.plan_no == 1) {
        dispatch(showPricingPlan(true, 'others'));
      } else {
        this.setState({
          showCreateUser: show,
        });
      }
    }
  },
});

const defaults = {
  title: 'Manage users',
};

export default connect((state) => {
  const s = state.manage_profile;

  return {
    access_token: state.current_user.access_token,
    current_pricing_plan: state.pricing_plans.current_pricing_plan,
    entity: state.current_user.current_entity,
    isSupplier: state.current_user.current_entity.attributes.supplier,
    loading: s.current_entity_users_loading,
    title: defaults.title,
    user_list: state.manage_users.team_list,
  };
})(ManageTeam);
