import axios from './axios';

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#notifications-viewing-a-user-s-notifications
 */
const Notifications = function(config) {
  this.axios = axios(config);
  this.type = 'notifications';
};

Notifications.prototype.getNotifications = function(
  success,
  error,
  options = {}
) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios
    .get(`/${this.type}`, options)
    .then(success)
    .catch(error);
};

Notifications.prototype.markActioned = function(id, success, error) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v2', 'v1');
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        attributes: {
          actioned: true,
        },
        id,
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

Notifications.prototype.clear = function(success, error) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios
    .delete(`/${this.type}`, {
      data: {
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

Notifications.prototype.unreads = function() {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios.get(`/${this.type}/unreads`);
};

export default Notifications;
