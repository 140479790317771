/* eslint-disable complexity */
import { getSudoToken } from 'modules/shared/auth/actions';
import isBlank from 'utils/isBlank';

import config from '../../config/';
import Abn from './abn';
import AddonAnswers from './addonAnswers';
import AddonConfigs from './addonConfigs';
import AddonVersions from './addonVersions';
import Addresses from './addresses';
import AddressSearch from './addressSearch';
import AmlCheckLookup from './amlCheckLookup';
import ApplicationMonthlyReports from './applicationMonthlyReports';
import ApplicationReports from './applicationReports';
import Applications from './applications';
import ApprovalHierarchies from './approvalHierarchies';
import AskNicely from './askNicely';
import Auth from './auth';
import Authorisations from './authorisations';
import AuthorityDeclarations from './authorityDeclarations';
import AutoDecisioning from './autoDecisioning';
import AwaitingPermissions from './awaitingPermissions';
import axios from './axios';
import BusinessDescriptions from './businessDescriptions';
import CallCentreActivities from './callCentreActivities';
import Cardholders from './cardholders';
import Cards from './cards';
import ColorPalette from './colorPalettes';
import CompanySearch from './companySearch';
import Connections from './connections';
import CreditCheckCredentials from './creditCheckCredentials';
import CreditCheckLookup from './creditCheckLookup';
import CreditChecks from './creditChecks';
import Deactivate from './deactivate';
import EmailActivities from './emailActivities';
import EmailPreviews from './emailPreviews';
import EmailTemplates from './emailTemplates';
import EmailValidation from './emailValidation';
import Entities from './entities';
import EntityConsumerApplications from './entityConsumerApplications';
import EntityEmailTemplates from './entityEmailTemplates';
import EntityNameSearch from './entityNameSearch';
import FreshchatIds from './freshchatIds';
import Guarantors from './guarantors';
import Headquarters from './headquarters';
import IdentificationCheckLookup from './identificationCheckLookup';
import Identity from './identity';
import Integrations from './integrations';
import Invites from './invites';
import IpGeolocation from './ipGeolocation';
import IUFApprovals from './IUFApprovals';
import Leads from './leads';
import LoginDisposition from './loginDisposition';
import Messages from './messages';
import ModuleCardholderApplicationFlows from './ModuleCardholderApplicationFlows';
import ModuleCardholderApplications from './moduleCardholderApplications';
import ModuleCardholderAuthorisationFlows from './ModuleCardholderAuthorisationFlows';
import ModulePaperlessApplicationFlows from './ModulePaperlessApplicationFlows';
import ModulePaperlessApplications from './modulePaperlessApplications';
import ModulePaperlessAuthorisationFlows from './ModulePaperlessAuthorisationFlows';
import Notes from './notes';
import Notifications from './notifications';
import Nzbn from './nzbn';
import Onboarding from './onboarding';
import OnboardingEntityDetails from './onboardingEntityDetails';
import PasswordResets from './passwordResets';
import PaymentTerms from './paymentTerms';
import People from './people';
import PpsrFinancingStatements from './ppsrFinancingStatements';
import PresignedUrl from './presignedUrl';
import PricingPlans from './pricingPlans';
import QRCode from './qrCode';
import Recaptcha from './recaptcha';
import RecoveryCandidate from './recoveryCandidate';
import Referrals from './referrals';
import ReportApplications from './reportApplications';
import Reporting from './reporting';
import ReportingTierOptions from './reportingTierOptions';
import ReportingTradingHours from './reportingTradingHours';
import Reviews from './reviews';
import SalesChannels from './salesChannels';
import SecuredPartyGroups from './securedPartyGroups';
import Signatures from './signatures';
import States from './states';
import StopCredits from './stopCredits';
import Subscriptions from './subscriptions';
import Suppliers from './Suppliers';
import SupplierTerms from './supplierTerms';
import TermsDocuments from './termsDocuments';
import Timezones from './timezones';
import Tokens from './tokens';
import TradeReferenceChecks from './tradeReferenceChecks';
import TradeReferences from './tradeReferences';
import TradingNames from './tradingNames';
import Transactions from './transactions';
import Trm from './trm';
import UserEntityLinks from './userEntityLinks';
import UserInformations from './UserInformations';
import UserPopUps from './userPopUps';
import UserRoles from './userRoles';
import Users from './users';
import Vehicles from './vehicles';
import VoucherCodes from './VoucherCodes';
import Webhooks from './webhooks';
import WebhookSchemas from './webhookSchemas';
import Websitebuttons from './websitebuttons';

/**
 * Wrapper around all API comms;
 * This function is a factory pattern that allows us to have multiple endpoints and configs within the same
 * app instance. Parse different auth and overrides params to the factory when you are requesting an API
 * wrapper.
 *
 * eg: The following could exist in the same scope, the tokens handler would use the App default and the
 *     users handler would use the overrides and a different auth token.
 *
 *  var tokens = api('tokens', my_auth);
 *  tokens.requestToken(data);
 *
 *  var users = api('users', other_auth_token, { hostname: 'uat.1centre.co.nz', version: '/v2' });
 *  users.getUser(user_id, success, error);
 *
 * @param name
 * @param auth
 * @param overrides
 * @returns {*}
 */
// TODO - Remove auth since it is in cookies now
export default function(name, auth = '', currentEntity = '', overrides = {}) {
  const mediaType = { 'Content-Type': 'application/vnd.api+json' };
  const entity = { 'X-Entity-ID': currentEntity };
  // Create the config
  const newConfig = Object.assign({}, config.api, overrides);
  let headers = currentEntity
    ? Object.assign({}, mediaType, entity, newConfig.headers)
    : Object.assign({}, mediaType, newConfig.headers);

  const sudoToken = getSudoToken();
  if (!isBlank(sudoToken)) {
    headers = Object.assign(headers, { 'X-Sudo-Token': sudoToken });
  }

  const apiConfig = {
    baseURL: `${newConfig.protocol}://${newConfig.hostname}${newConfig.version}`,
    headers,
    timeout: newConfig.ttl,
    withCredentials: true,
  };

  // Alternate IpGeolocation config
  const newIpGeolocationConfig = Object.assign(
    {},
    config.ip_geolocation_api,
    overrides
  );
  const ipGeolocationApiConfig = {
    baseURL: `${newIpGeolocationConfig.protocol}://${newIpGeolocationConfig.hostname}`,
    timeout: newIpGeolocationConfig.ttl,
  };

  const recaptchaApiConfigObject = Object.assign(
    {},
    config.recaptcha_api,
    overrides
  );
  const recaptchaApiConfig = {
    baseURL: `${recaptchaApiConfigObject.protocol}://${recaptchaApiConfigObject.hostname}`,
    timeout: recaptchaApiConfigObject.ttl,
  };

  // Load the correct API wrapper
  switch (name) {
    case 'auth':
      return new Auth(apiConfig);
    case 'tokens':
      return new Tokens(apiConfig);
    case 'entities':
      return new Entities(apiConfig);
    case 'business_descriptions':
      return new BusinessDescriptions(apiConfig);
    case 'users':
      return new Users(apiConfig);
    case 'webhooks':
      return new Webhooks(apiConfig);
    case 'nzbn':
      return new Nzbn(apiConfig);
    case 'company_search':
      return new CompanySearch(apiConfig);
    case 'states':
      return new States(apiConfig);
    case 'people':
      return new People(apiConfig);
    case 'supplier_terms':
      return new SupplierTerms(apiConfig);
    case 'payment_terms':
      return new PaymentTerms(apiConfig);
    case 'terms_documents':
      return new TermsDocuments(apiConfig);
    case 'password_resets':
      return new PasswordResets(apiConfig);
    case 'applications':
      return new Applications(apiConfig);
    case 'reviews':
      return new Reviews(apiConfig);
    case 'entity_name_search':
      return new EntityNameSearch(apiConfig);
    case 'connections':
      return new Connections(apiConfig);
    case 'authority_declarations':
      return new AuthorityDeclarations(apiConfig);
    case 'messages':
      return new Messages(apiConfig);
    case 'notes':
      return new Notes(apiConfig);
    case 'notifications':
      return new Notifications(apiConfig);
    case 'guarantors':
      return new Guarantors(apiConfig);
    case 'trade_references':
      return new TradeReferences(apiConfig);
    case 'trade_reference_checks':
      return new TradeReferenceChecks(apiConfig);
    case 'invites':
      return new Invites(apiConfig);
    case 'addresses':
      return new Addresses(apiConfig);
    case 'address_tokens':
      return new AddressSearch(apiConfig);
    case 'address_search':
      return new AddressSearch(apiConfig);
    case 'cards':
      return new Cards(apiConfig);
    case 'subscriptions':
      return new Subscriptions(apiConfig);
    case 'transactions':
      return new Transactions(apiConfig);
    case 'stop_credits':
      return new StopCredits(apiConfig);
    case 'user_exists_email':
      return new EmailValidation(apiConfig);
    case 'login_disposition':
      return new LoginDisposition(apiConfig);
    case 'application_summary_report':
      return new ApplicationReports(apiConfig);
    case 'application_monthly_report':
      return new ApplicationMonthlyReports(apiConfig);
    case 'signatures':
      return new Signatures(apiConfig);
    case 'integrations':
      return new Integrations(apiConfig);
    case 'deactivate':
      return new Deactivate(apiConfig);
    case 'ip_geolocation':
      return new IpGeolocation(ipGeolocationApiConfig);
    case 'credit_check_credentials':
      return new CreditCheckCredentials(apiConfig);
    case 'credit_check_lookup':
      return new CreditCheckLookup(apiConfig);
    case 'identity':
      return new Identity(apiConfig);
    case 'identification_check_lookup':
      return new IdentificationCheckLookup(apiConfig);
    case 'trm':
      return new Trm(apiConfig);
    case 'user_entity_links':
      return new UserEntityLinks(apiConfig);
    case 'websitebuttons':
      return new Websitebuttons(apiConfig);
    case 'pricing_plans':
      return new PricingPlans(apiConfig);
    case 'user_pop_ups':
      return new UserPopUps(apiConfig);
    case 'report_applications':
      return new ReportApplications(apiConfig);
    case 'asknicely':
      return new AskNicely(apiConfig);
    case 'recovery_candidate':
      return new RecoveryCandidate(apiConfig);
    case 'credit_checks':
      return new CreditChecks(apiConfig);
    case 'entity_consumer_applications':
      return new EntityConsumerApplications(apiConfig);
    case 'auto_decisioning_rulesets':
      return new AutoDecisioning(apiConfig);
    case 'approval_hierarchies':
      return new ApprovalHierarchies(apiConfig);
    case 'headquarters':
      return new Headquarters(apiConfig);
    case 'entity_email_templates':
      return new EntityEmailTemplates(apiConfig);
    case 'email_templates':
      return new EmailTemplates(apiConfig);
    case 'email_previews':
      return new EmailPreviews(apiConfig);
    case 'leads':
      return new Leads(apiConfig);
    case 'trading_names':
      return new TradingNames(apiConfig);
    case 'addon_versions':
      return new AddonVersions(apiConfig);
    case 'addon_configs':
      return new AddonConfigs(apiConfig);
    case 'addon_answers':
      return new AddonAnswers(apiConfig);
    case 'cardholders':
      return new Cardholders(apiConfig);
    case 'aml_check_lookup':
      return new AmlCheckLookup(apiConfig);
    case 'authorisations':
      return new Authorisations(apiConfig);
    case 'color_palettes':
      return new ColorPalette(apiConfig);
    case 'voucher_codes':
      return new VoucherCodes(apiConfig);
    case 'freshchat_ids':
      return new FreshchatIds(apiConfig);
    case 'timezones':
      return new Timezones(apiConfig);
    case 'webhook_schemas':
      return new WebhookSchemas(apiConfig);
    case 'qr_code':
      return new QRCode(apiConfig);
    case 'module_paperless_applications':
      return new ModulePaperlessApplications(apiConfig);
    case 'user_informations':
      return new UserInformations(apiConfig);
    case 'module_paperless_application_flows':
      return new ModulePaperlessApplicationFlows(apiConfig);
    case 'module_paperless_authorisation_flows':
      return new ModulePaperlessAuthorisationFlows(apiConfig);
    case 'module_cardholder_application_flows':
      return new ModuleCardholderApplicationFlows(apiConfig);
    case 'module_cardholder_applications':
      return new ModuleCardholderApplications(apiConfig);
    case 'module_cardholder_authorisation_flows':
      return new ModuleCardholderAuthorisationFlows(apiConfig);
    case 'suppliers':
      return new Suppliers(apiConfig);
    case 'secured_party_groups':
      return new SecuredPartyGroups(apiConfig);
    case 'ppsr_financing_statements':
      return new PpsrFinancingStatements(apiConfig);
    case 'user_roles':
      return new UserRoles(apiConfig);
    case 'referrals':
      return new Referrals(apiConfig);
    case 'recaptcha':
      return new Recaptcha(recaptchaApiConfig);
    case 'presigned_url':
      return new PresignedUrl(apiConfig);
    case 'abn':
      return new Abn(apiConfig);
    case 'reporting':
      return new Reporting(apiConfig);
    case 'reporting_trading_hours':
      return new ReportingTradingHours(apiConfig);
    case 'reporting_tier_options':
      return new ReportingTierOptions(apiConfig);
    case 'vehicles':
      return new Vehicles(apiConfig);
    case 'email_activities':
      return new EmailActivities(apiConfig);
    case 'iuf_approvals':
      return new IUFApprovals(apiConfig);
    case 'onboarding':
      return new Onboarding(apiConfig);
    case 'onboarding_entity_details':
      return new OnboardingEntityDetails(apiConfig);
    case 'awaiting_permissions':
      return new AwaitingPermissions(apiConfig);
    case 'call_centre_activities':
      return new CallCentreActivities(apiConfig);
    case 'sales_channels':
      return new SalesChannels(apiConfig);
  }
}
