import Button from 'modules/shared/components/inputs/Button';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import React from 'react';
import RejectTerms from './RejectTerms';
import StandardTermsAndConditions from 'modules/shared/components/text/StandardTermsAndConditions';
import styles from './css/Terms.css';

export default class Terms extends React.Component {
  toggleAgreement(i) {
    const agreements = this.state.agreements.map(function(a) {
      return {
        agreed: a.id === i ? !a.agreed : a.agreed,
        id: a.id,
      };
    });

    this.setState({ agreements });
  }

  agreements() {
    return (
      <div>
        <div
          className={
            this.state.agreements[0].agreed ? styles.link_active : styles.link
          }
          onClick={() => this.toggleAgreement(1)}
        >
          I confirm that I am authorised to act on behalf of the business I name
          as the Supplier and I have the authority to bind such Supplier
        </div>
        <div
          className={
            this.state.agreements[1].agreed ? styles.link_active : styles.link
          }
          onClick={() => this.toggleAgreement(2)}
        >
          I have read and agree to the above terms and conditions individually
          and on behalf of the Supplier
        </div>
        <div
          className={
            this.state.agreements[2].agreed ? styles.link_active : styles.link
          }
          onClick={() => this.toggleAgreement(3)}
        >
          I acknowledge&nbsp;
          <a
            href="https://www.1centre.com/Privacy/index.html"
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            1Centre’s privacy policy
          </a>
          &nbsp;and consent to receiving communications from 1Centre
          electronically
        </div>
        <div
          className={
            this.state.agreements[3].agreed ? styles.link_active : styles.link
          }
          onClick={() => this.toggleAgreement(4)}
        >
          If I give 1Centre information about another person, I confirm I have
          their consent to do this and 1Centre can communicate with them
          electronically
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      agreements: [
        { agreed: false, id: 1 },
        { agreed: false, id: 2 },
        { agreed: false, id: 3 },
        { agreed: false, id: 4 },
      ],
      hasScrolled: !(props.region === 'NZ'),
      showSorryModal: false,
      termsWarning: null,
    };
  }

  disagree = () => {
    this.setState({ showSorryModal: true });
  };

  cancelModal = () => {
    this.setState({ showSorryModal: false });
  };

  onScrollToBottom = () => {
    if (!this.state.hasScrolled) {
      this.setState({ hasScrolled: true });
      this.setState({ termsWarning: null });
    }
  };

  completed() {
    let agreed = true;
    for (let i = 0; i < this.state.agreements.length; i++) {
      agreed = agreed && this.state.agreements[i].agreed;
    }
    return this.state.hasScrolled && agreed;
  }

  render() {
    const { loading, handleBail, handleAgree, region } = this.props;
    const { showSorryModal, hasScrolled } = this.state;
    const completed = this.completed();

    let modal;
    let agree_button_text = 'agree';

    if (showSorryModal) {
      modal = (
        <RejectTerms
          handleBail={() => handleBail()}
          handleDismiss={this.cancelModal}
        />
      );
    }

    const terms = (
      <StandardTermsAndConditions
        region={region}
        onScrollToBottom={this.onScrollToBottom}
      />
    );

    if (!hasScrolled) {
      agree_button_text = 'scroll through the Ts & Cs';
    } else if (!completed) {
      agree_button_text = 'agree declarations';
    }

    return (
      <div>
        <div className={styles.row}>
          <div className={styles.panel}>
            <PanelTitle text="Do you agree to the 1Centre Ts and Cs" />
          </div>
        </div>
        <div className={styles.row_terms}>
          <div className={styles.terms}>{terms}</div>
        </div>
        {this.agreements()}
        <div className={styles.buttons}>
          <Button text="disagree" grey={true} handleClick={this.disagree} />
          <Button
            text={agree_button_text}
            handleClick={() => handleAgree()}
            loading={loading}
            disableOnLoading={true}
            loading_text={'submitting'}
            disabled={!completed}
          />
        </div>
        {modal}
      </div>
    );
  }
}
