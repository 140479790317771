import {
  COB_TRADEACCOUNT_CLEAR_DATA,
  COB_TRADEACCOUNT_LOAD_ADDON_RULES,
  COB_TRADEACCOUNT_LOAD_AML_CHECK,
  COB_TRADEACCOUNT_LOAD_ANTI_FRAUD,
  COB_TRADEACCOUNT_LOAD_APPLICABLE_LEGAL_TYPES,
  COB_TRADEACCOUNT_LOAD_APPLICATION_ERROR,
  COB_TRADEACCOUNT_LOAD_APPLICATION_START,
  COB_TRADEACCOUNT_LOAD_APPLICATION_SUCCESS,
  COB_TRADEACCOUNT_LOAD_AUTHORISATION,
  COB_TRADEACCOUNT_LOAD_COLOR_PALETTE,
  COB_TRADEACCOUNT_LOAD_CONSUMER_TRADING_NAMES,
  COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_ERROR,
  COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_START,
  COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_SUCCESS,
  COB_TRADEACCOUNT_LOAD_CURRENT_PEOPLE_SUCCESS,
  COB_TRADEACCOUNT_LOAD_CURRENT_TRADING_NAMES_SUCCESS,
  COB_TRADEACCOUNT_LOAD_SIGNATURE,
  COB_TRADEACCOUNT_LOAD_SUPPLIER_ENTITY_ERROR,
  COB_TRADEACCOUNT_LOAD_SUPPLIER_ENTITY_START,
  COB_TRADEACCOUNT_LOAD_SUPPLIER_ENTITY_SUCCESS,
  COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_ERROR,
  COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_START,
  COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_SUCCESS,
  COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_ERROR,
  COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_START,
  COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_SUCCESS,
  COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_ERROR,
  COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_START,
  COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_SUCCESS,
  COB_TRADEACCOUNT_SET_CARDHOLDERS_COUNT,
  COB_TRADEACCOUNT_SET_COLOR_PALETTE,
  COB_TRADEACCOUNT_SET_PEOPLE_FIRSTNAME,
  COB_TRADEACCOUNT_SET_PEOPLE_LASTNAME,
  COB_TRADEACCOUNT_SET_SELECTED_TRADING_NAMES,
  COB_TRADEACCOUNT_SET_SECTIONS_TO_CLEAR,
  COB_TRADEACCOUNT_SET_START_COMPLETE,
  COB_TRADEACCOUNT_SET_TRADING_NAMES,
  COB_SECTION_SET_IS_BUSINESS_OVERVIEW_LOADED,
} from '../constants/section';

import { STATE_LOAD_STATE_SUCCESS } from '../../state/constants.js';

const cob_defaults = {
  addonRules: [],
  amlCheck: null,
  antiFraud: null,
  applicableLegalTypes: null,
  application: null,
  authorisation: null,
  current_entity: null,
  current_people: null,
  isBusinessOverviewLoaded: false,
  loading_error: null,
  loading: false,
  people_loading: false,
  people: null,
  sectionsToClear: [],
  selected_trading_names: [],
  signature: null,
  start_complete: false,
  supplier: null,
  trading_names: null,
};

export function consumerOnbardingSectionReducer(state = cob_defaults, action) {
  switch (action.type) {
    case COB_TRADEACCOUNT_CLEAR_DATA:
      return { ...cob_defaults };
    case COB_TRADEACCOUNT_LOAD_APPLICATION_START:
      return { ...state, loading: true, loading_error: null };
    case COB_TRADEACCOUNT_LOAD_APPLICATION_SUCCESS:
      return {
        ...state,
        application: action.payload,
        loading: false,
        loading_error: null,
      };
    case COB_TRADEACCOUNT_LOAD_APPLICATION_ERROR:
      return { ...state, loading: false, loading_error: action.payload };

    case COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_START:
      return { ...state, people_loading: true };
    case COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_SUCCESS:
      return { ...state, people: action.payload, people_loading: false };
    case COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_ERROR:
      return { ...state, people_loading: false };

    case COB_TRADEACCOUNT_LOAD_SUPPLIER_ENTITY_START:
      return { ...state, loading: true, loading_error: null };
    case COB_TRADEACCOUNT_LOAD_SUPPLIER_ENTITY_SUCCESS:
      return {
        ...state,
        supplier: action.payload,
        loading: false,
        loading_error: null,
      };
    case COB_TRADEACCOUNT_LOAD_SUPPLIER_ENTITY_ERROR:
      return { ...state, loading: false, loading_error: action.payload };
    case STATE_LOAD_STATE_SUCCESS:
      if (action.payload['cob_section']) {
        return { ...state, ...action.payload.cob_section };
      }
    case COB_TRADEACCOUNT_LOAD_SIGNATURE:
      return { ...state, signature: action.payload };

    case COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_START:
      return { ...state };
    case COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_ERROR:
      return { ...state };
    case COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_SUCCESS:
      return { ...state, current_entity: action.payload };
    case COB_TRADEACCOUNT_LOAD_CURRENT_PEOPLE_SUCCESS:
      return { ...state, current_people: action.payload };
    case COB_TRADEACCOUNT_LOAD_CURRENT_TRADING_NAMES_SUCCESS:
      return { ...state, trading_names: action.payload };
    case COB_TRADEACCOUNT_SET_START_COMPLETE:
      return { ...state, start_complete: action.payload };

    case COB_TRADEACCOUNT_SET_PEOPLE_FIRSTNAME:
      var form_values = state.people.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          first_name: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          first_name: action.payload.value,
        };
      }
      return { ...state, people: form_values };

    case COB_TRADEACCOUNT_SET_PEOPLE_LASTNAME:
      var form_values = state.people.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          last_name: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          last_name: action.payload.value,
        };
      }
      return { ...state, people: form_values };

    case COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_START:
      return { ...state, loading: true, loading_error: null };
    case COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_SUCCESS:
      return {
        ...state,
        supplier: action.payload,
        loading: false,
        loading_error: null,
      };
    case COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_ERROR:
      return { ...state, loading: false, loading_error: action.payload };

    case COB_TRADEACCOUNT_LOAD_ADDON_RULES:
      return { ...state, addonRules: action.payload };
    case COB_TRADEACCOUNT_SET_CARDHOLDERS_COUNT:
      return {
        ...state,
        application: {
          ...state.application,
          attributes: {
            ...state.application.attributes,
            cardholders_count: action.payload,
          },
        },
      };
    case COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_ERROR:
      return {
        ...state,
        loading: false,
        loading_error: action.payload,
      };
    case COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_START:
      return { ...state, loading: true, loading_error: null };
    case COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_SUCCESS:
      return { ...state, loading: true, loading_error: null };
    case COB_TRADEACCOUNT_LOAD_AUTHORISATION:
      return { ...state, authorisation: action.payload };
    case COB_TRADEACCOUNT_LOAD_AML_CHECK:
      return { ...state, amlCheck: action.payload };
    case COB_TRADEACCOUNT_LOAD_ANTI_FRAUD:
      return { ...state, antiFraud: action.payload };
    case COB_TRADEACCOUNT_SET_COLOR_PALETTE:
      return { ...state, color_palette: action.payload };
    case COB_TRADEACCOUNT_LOAD_COLOR_PALETTE:
      return { ...state };
    case COB_TRADEACCOUNT_LOAD_APPLICABLE_LEGAL_TYPES:
      return { ...state, applicableLegalTypes: action.payload };
    case COB_TRADEACCOUNT_LOAD_CONSUMER_TRADING_NAMES:
      return { ...state, selected_trading_names: action.payload };
    case COB_TRADEACCOUNT_SET_SELECTED_TRADING_NAMES:
      return { ...state, selected_trading_names: action.payload };
    case COB_TRADEACCOUNT_SET_TRADING_NAMES:
      return { ...state, trading_names: action.payload };
    case COB_TRADEACCOUNT_SET_SECTIONS_TO_CLEAR:
      return { ...state, sectionsToClear: action.payload };
    case COB_SECTION_SET_IS_BUSINESS_OVERVIEW_LOADED:
      return { ...state, isBusinessOverviewLoaded: action.payload };
    default:
      return { ...state };
  }
}
