import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import styles from '../css/material-select.css';
import BorderedSelect from '../../inputs/BorderedSelect';

function HelperText({
  helperCondition,
  hide_helper_text,
  error,
  helperText,
  label,
}) {
  if (helperCondition === 'or' && hide_helper_text) {
    return hide_helper_text;
  }

  if (helperCondition === 'and' && hide_helper_text) {
    return null;
  }

  return (
    <FormHelperText
      id={`${label}-helper-text`}
      error={error}
      className={styles.material_helper_text}
      style={
        error
          ? { fontSize: 10, fontWeight: 800 }
          : { fontSize: 10, fontWeight: 400 }
      }
    >
      {helperText}
    </FormHelperText>
  );
}

function Dropdown(props) {
  const { error, id, label, name, onChange, options, value } = props;

  return (
    <Fragment>
      <FormControl error={error} className={`${styles.material_form_control}`}>
        <InputLabel
          htmlFor={id}
          classes={{
            root: styles.material_label,
            shrink: styles.material_label_shrink,
          }}
        >
          {label}
        </InputLabel>
        <Select
          name={name}
          value={value}
          id={id}
          className={styles.material_select}
          startAdornment={<span />}
          onChange={onChange}
        >
          {options.map((region) => (
            <MenuItem
              key={region.value}
              name={region.name}
              value={region.value}
            >
              <div className={styles.material_menuitem}>{region.label}</div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <HelperText {...props} />
    </Fragment>
  );
}

function RegionStatesDropdown(props) {
  const {
    borderedStyle,
    regionValue,
    stateValue,
    label,
    regionError,
    stateError,
    handleChange,
    regionId,
    stateId,
    regions,
    region,
    hide_helper_text,
    required,
    noStates,
  } = props;
  let states = region && (!noStates && region.states);

  const SelectComponent = borderedStyle ? BorderedSelect : Dropdown;

  const selectWrapperClass = states ? styles.xs_8 : styles.xs_12;
  let wrapperProps = {
    className: styles.material_container,
    style: {
      display: 'flex',
    },
  };
  let selectProps = {
    helperCondition: 'and',
  };

  if (borderedStyle) {
    wrapperProps = {};
    selectProps = {
      required,
    };
  }

  return (
    <div {...wrapperProps}>
      <div className={borderedStyle ? '' : selectWrapperClass}>
        <SelectComponent
          label={label}
          error={Boolean(regionError)}
          helperText={regionError}
          hide_helper_text={hide_helper_text}
          id="region-select"
          name={regionId}
          onChange={(e) => handleChange({ ...e.target, id: regionId })}
          options={regions}
          value={regionValue}
          {...selectProps}
        />
      </div>
      {states && (
        <div className={styles.xs_4}>
          <SelectComponent
            error={Boolean(stateError)}
            helperText={stateError}
            hide_helper_text={hide_helper_text}
            id="state-select"
            label="State"
            name={stateId}
            onChange={(e) => handleChange({ ...e.target, id: stateId })}
            options={states}
            value={stateValue}
            {...selectProps}
          />
        </div>
      )}
    </div>
  );
}

RegionStatesDropdown.defaultProps = {
  regionValue: '',
  stateValue: '',
};

export default connect((state, ownProps) => {
  let regions = [
    {
      id: ownProps.regionId,
      name: ownProps.regionId,
      label: 'New Zealand',
      value: 'NZ',
    },
    {
      id: ownProps.regionId,
      name: ownProps.regionId,
      label: 'Australia',
      value: 'AU',
      states: [
        {
          id: ownProps.stateId,
          name: ownProps.stateId,
          label: 'NSW',
          value: 'NSW',
        },
        {
          id: ownProps.stateId,
          name: ownProps.stateId,
          label: 'QLD',
          value: 'QLD',
        },
        {
          id: ownProps.stateId,
          name: ownProps.stateId,
          label: 'SA',
          value: 'SA',
        },
        {
          id: ownProps.stateId,
          name: ownProps.stateId,
          label: 'TAS',
          value: 'TAS',
        },
        {
          id: ownProps.stateId,
          name: ownProps.stateId,
          label: 'VIC',
          value: 'VIC',
        },
        {
          id: ownProps.stateId,
          name: ownProps.stateId,
          label: 'WA',
          value: 'WA',
        },
        {
          id: ownProps.stateId,
          name: ownProps.stateId,
          label: 'ACT',
          value: 'ACT',
        },
        {
          id: ownProps.stateId,
          name: ownProps.stateId,
          label: 'NT',
          value: 'NT',
        },
        {
          id: ownProps.stateId,
          name: ownProps.stateId,
          label: 'All',
          value: 'All',
        },
      ],
    },
  ];

  if (ownProps.allow_all) {
    regions.push({
      id: ownProps.stateId,
      name: ownProps.stateId,
      label: 'All',
      value: 'All',
    });
  }

  let region = regions.find((region) => ownProps.regionValue === region.value);

  return {
    regions: regions,
    region,
  };
})(RegionStatesDropdown);
