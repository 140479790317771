import axios from './axios';

let AutoDecisioning = function(config) {
  config.timeout = 30000;
  this.axios = axios(config);
  this.type = 'auto_decisioning_rulesets';
};

AutoDecisioning.prototype.createRuleSet = function(attributes, success, error) {
  return this.axios
    .post(`/add_on/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

AutoDecisioning.prototype.updateRuleSet = function(
  id,
  { history_data, current_index, credit_check_rule, ...attributes },
  success,
  error
) {
  return this.axios
    .patch(`/add_on/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

AutoDecisioning.prototype.getRuleSet = function(
  id,
  success,
  error,
  options = {}
) {
  return this.axios
    .get(`/add_on/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

AutoDecisioning.prototype.getRuleSets = function(success, error, options = {}) {
  return this.axios
    .get(`/add_on/${this.type}`, options)
    .then(success)
    .catch(error);
};

AutoDecisioning.prototype.deleteRuleSet = function(id, success, error) {
  return this.axios
    .delete(`/add_on/${this.type}/${id}`)
    .then(success)
    .catch(error);
};

export default AutoDecisioning;
