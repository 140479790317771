import ApplicationOverlay from 'modules/shared/components/top/ApplicationOverlay';
import ArrowButton from 'modules/shared/components/widgets/interactive/ArrowButton';
import ApplicationProgressDots from 'modules/shared/components/widgets/static/ApplicationProgressDots';
/* Import libraries */
import React, { useState } from 'react';

import consumerApplicationStyles from 'modules/consumer-onboarding/containers/css/ConsumerApplication.css';
import styles from '../css/PreviewModal.css';

function BCFlow(props) {
  const {
    config,
    currentEntity,
    moduleName,
    previewAddon,
    sharedData,
    totalPage,
  } = props;

  const [index, setIndex] = useState(0);
  const [validationTrigger, setValidationTrigger] = useState(false);

  function clickNext() {
    if (isValid()) {
      if (index < totalPage - 1) {
        setIndex(index + 1);
      }
    } else {
      setValidationTrigger(true);
    }
  }

  function clickBack() {
    if (index > 0) {
      setIndex(index - 1);
    }
  }

  function isValid() {
    if (moduleName === 'financials') {
      return false;
    }

    const validationFlags =
      sharedData[`${config.modules.additional.validationFlagsKey}_${index}`];

    if (moduleName === 'additional') {
      const { active, mandatory } = previewAddon.attachDocument || {};

      if (active && mandatory) {
        return false;
      }
    }
    return validationFlags.reduce((flag1, flag2) => flag1 && flag2);
  }

  const activeModule = config.modules[moduleName];

  let previewTitle = config.title;
  let childComponent;

  if (activeModule) {
    const {
      childComponent: activeChildComponent,
      overridePreviewTitle,
      title,
    } = activeModule;
    config.sections.section.title = title;
    childComponent = React.cloneElement(activeChildComponent, {
      componentIndex: index,
      validationTrigger: validationTrigger,
    });

    if (overridePreviewTitle) {
      previewTitle = title;
    }
  }

  let sectionIndex = 0;
  const links = [];
  const sortSections = config.sections;

  sectionIndex = Object.keys(sortSections).indexOf('section');

  Object.keys(sortSections).forEach((item, index) => {
    links.push({
      complete: !(sectionIndex < index),
      label: sortSections[item].title,
    });
  });

  const onboardingButtons = (
    <div className={consumerApplicationStyles.bottom_buttons}>
      <div>
        <ArrowButton
          css_style="button_small_font"
          onClick={clickNext}
          down={false}
          next={false}
          text="Next"
        />
      </div>
      <div>
        <ArrowButton
          css_style="button_small_font"
          onClick={clickBack}
          prev={false}
          text="Back"
        />
      </div>
    </div>
  );

  let applicationContentStyle = {};

  if (activeModule.hideProgress) {
    applicationContentStyle['marginTop'] = '6rem';
  }

  if (activeModule.style) {
    applicationContentStyle = {
      ...applicationContentStyle,
      ...activeModule.style,
    };
  }

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={consumerApplicationStyles.controls}>
          {!activeModule.hideProgress && (
            <ApplicationProgressDots
              type="horizontal"
              index={sectionIndex}
              links={links}
            />
          )}
          <ApplicationOverlay
            trading_name={currentEntity.trading_name}
            logo_url={currentEntity.logo ? currentEntity.logo.url : ''}
            label={previewTitle}
          />
        </div>
        <div className={consumerApplicationStyles.page}>
          <div className={consumerApplicationStyles.container}>
            <div
              className={consumerApplicationStyles.application_content}
              style={applicationContentStyle}
            >
              {childComponent}
              {!activeModule.hideOnboardingButtons && onboardingButtons}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BCFlow;
