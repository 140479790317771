import * as Sentry from "@sentry/browser";
import get from "lodash.get";
import {
  clearApplicationStates,
  loadSupplierWithCallbacks,
} from "modules/consumer-onboarding/actions/section";
import ApplicationInaccessible from "modules/consumer-onboarding/v2/ApplicationInaccessible";
import { GridContainer } from "modules/consumer-onboarding/v2/Container/style";
import useApplicationAccessibilityState from "modules/consumer-onboarding/v2/hooks/useApplicationAccessibilityState";
import {
  UserContext,
  useUserState,
} from "modules/consumer-onboarding/v2/hooks/UserContext";
import useSupplierLoadedState from "modules/consumer-onboarding/v2/hooks/useSupplierLoadedState";
import useWebsiteButtonState from "modules/consumer-onboarding/v2/hooks/useWebsiteButtonState";
import {
  getAddonConfigBySupplierAndChannel,
} from "modules/consumer-onboarding/v2/utils/applicationTypes";
import getApplicationAccessibility from "modules/consumer-onboarding/v2/utils/getApplicationAccessibility";
import Overlay from "modules/shared/components/top/Overlay";
import Footer from "modules/shared/components/v2/Footer";
import Layout from "modules/user/containers/Layout";
import React, { createContext, ReactElement, useEffect, useState } from "react";
import { connect } from "react-redux";

interface IOnboardingChecklist {
  label: string;
  value: string;
}

interface IOnboardingChecklistContext {
  onTickChecklist: null | ((payload: any) => void);
  onboardingChecklist: Array<IOnboardingChecklist>;
}

export const OnboardingChecklistContext = createContext<
  IOnboardingChecklistContext
>({
  onTickChecklist: null,
  onboardingChecklist: [],
});

const VALID_CHANNELS = ["qr", "sales", "website"];
const isValidChannel = channel => VALID_CHANNELS.includes(channel);

const Container = (props): ReactElement => {
  const {
    dispatch,
    location,
    supplierChecklist,
    supplierLogoUrl,
    supplierTerms,
    supplierTradingName,
  } = props;
  const {
    query: {
      application_id: applicationId,
      channel,
      supplier_id: supplierId,
      t: token,
      sudo_token: sudoToken,
    },
  } = location;

  const userState = useUserState();
  const {
    isSupplierAPIComplete,
    isSupplierLoadedSuccessfully,
    onLoadSupplierError,
    onLoadSupplierSuccess,
  } = useSupplierLoadedState();

  let content = (
    <UserContext.Provider value={userState}>
      {props.children}
    </UserContext.Provider>
  );

  if (!sudoToken && !isValidChannel(channel)) {
    console.error("Invalid URL requested");
    Sentry.captureException(
      `Invalid URL requested: ${location.pathname}${location.search}`
    );

    content = (
      <ApplicationInaccessible
        header="Service unavailable"
        message="Looks like the URL you are accessing is invalid"
      />
    );
  }

  const {
    isReady,
    allowWebsiteButtonFlow,
    setIsWebsiteButtonEnabled,
  } = useWebsiteButtonState(channel);

  const {
    isApplicationAccessible,
    isApplicationAccessibleLoaded,
    isAuthorized,
    message,
    onSetApplicationAccessibility,
    setIsApplicationAccessibleLoaded,
    setIsAuthorized,
  } = useApplicationAccessibilityState();

  useEffect(() => {
    dispatch(clearApplicationStates());
  }, []);

  useEffect(() => {
    dispatch(
      loadSupplierWithCallbacks({
        errorCallback: onLoadSupplierError,
        id: supplierId,
        successCallback: onLoadSupplierSuccess,
      })
    );
    getAddonConfigBySupplierAndChannel({
      channel,
      supplierId,
    }).then(addonConfig => {
      const enabled = addonConfig.active && (addonConfig?.latestVersion?.applicationTypes || []).length > 0;
      setIsWebsiteButtonEnabled(enabled);
    })
      .catch(_error => {
        setIsWebsiteButtonEnabled(false);
      });
  }, [supplierId]);

  useEffect(() => {
    if (!applicationId) {
      setIsApplicationAccessibleLoaded(true);
      return;
    }

    getApplicationAccessibility({
      applicationId,
      errorCallback: () => setIsAuthorized(false),
      successCallback: result => {
        const { accessible, email, reason } = get(result, "data", {});

        onSetApplicationAccessibility(accessible, reason);
        userState.setEmailFromParams(email);
      },
      token,
    });
  }, [applicationId]);

  const [onboardingChecklist, onSetOnboardingChecklist] = useState([]);

  useEffect(() => {
    onSetOnboardingChecklist((supplierChecklist || {}).checklist);
  }, [(supplierChecklist || {}).checklist]);

  const onTickChecklistCallback = payload => {
    onSetOnboardingChecklist(payload.checklistState);
  };

  if (isReady && !allowWebsiteButtonFlow) {
    content = (
      <ApplicationInaccessible
        header="Service unavailable"
        message="Looks like the supplier does not have this service. Please
    contact them for more information."
      />
    );
  }

  if (isApplicationAccessibleLoaded && !isApplicationAccessible) {
    content = (
      <ApplicationInaccessible
        header="Application inaccessible"
        message={`${message} Please directly get in touch with ${supplierTradingName} to discuss any questions you may have.`}
      />
    );
  }

  if (!isAuthorized) {
    content = (
      <ApplicationInaccessible
        header="Unauthorised access"
        message="Looks like you do not have access to the application. Please verify if you have the correct link."
      />
    );
  }

  const overlay = (
    <Overlay
      applicationChecklist={supplierChecklist}
      supplierLogoUrl={supplierLogoUrl}
      supplierTerms={supplierTerms}
      trading_name={supplierTradingName}
    />
  );

  if (isSupplierAPIComplete && !isSupplierLoadedSuccessfully) {
    return (
      <Layout withParentDiv={false} {...props}>
        <GridContainer>
          {overlay}
          <ApplicationInaccessible
            header="Unable to load your configuration"
            message="Sorry, we are unable to load the configuration of your supplier. Please refresh the page and try again."
          />
          <Footer />
        </GridContainer>
      </Layout>
    );
  }

  return (
    <Layout withParentDiv={false} {...props}>
      {isSupplierAPIComplete && (
        <GridContainer>
          <OnboardingChecklistContext.Provider
            value={{
              onTickChecklist: onTickChecklistCallback,
              onboardingChecklist,
            }}
          >
            {overlay}
            {content}
          </OnboardingChecklistContext.Provider>
          <Footer />
        </GridContainer>
      )}
    </Layout>
  );
};

export default connect(state => {
  const supplierAttributes = get(state, "cob_section.supplier.attributes", {});

  const supplierChecklist = supplierAttributes.supplier_checklist;
  const supplierLogoUrl = get(supplierAttributes, "logo.url");
  const supplierTradingName = supplierAttributes.trading_name;
  const supplierTerms = supplierAttributes.terms_and_conditions;
  const hqName = supplierAttributes.hq_company_name;

  return {
    hqName,
    supplierChecklist,
    supplierLogoUrl,
    supplierTerms,
    supplierTradingName,
  };
})(Container);
