const overrides = {
  production: true,
  api: {
    protocol: "https",
    wsProtocal: "wss",
    hostname: "api-aws.1centre.com",
    version: "/v1",
    ttl: 8000,
    headers: { Accept: "application/vnd.api+json" },
  },
  datadog_site: "datadoghq.com",
  datadog_token: "pub00fe826f542fa6922301fb421a28e907",
  datadog_env: "production",
  nz_post_api: {
    protocol: "https",
    hostname: "api.nzpost.co.nz/addresschecker",
    version: "/1.0",
    ttl: 8000,
    headers: { Accept: "application/json" },
  },
  ip_geolocation_api: {
    protocol: "https",
    hostname: "geoip.1centre.com",
    ttl: 8000,
  },
  mixpanel: "369df8b944e50dd28a8d65a1117bdb22",
  stripe: "pk_live_sCpwWCXeUPCTy8bqrEy7No30",
  google_client: "AIzaSyATiej1VH-kqOeEuf-BaO0Zcx8ieMZpswM",
  sentry_dsn: "https://7a73b3c062314c769b7bb09e6df4990f@sentry.io/215731",
  sentry_token:
    "67d745e8346f43ea99d0f51f75e08c926de3b6616ef542f4961f8041c76811d0",
  sentry_project: "1centre-app",
  churnzero: "qXxD11qHeofVmpp--hkMo3otZMDrrJaj0qTdzPlIp9s",
  recaptcha_api: {
    hostname: "api-aws.1centre.com/node",
    protocol: "https",
    ttl: 8000,
    wsProtocal: "wss",
  },
  recaptcha_key: "6LcvujAaAAAAAMmGIQMrd-1eNnCwRvU0K93Tmg2m",
  visible_reporting_phase: [1],
};
module.exports = overrides;
