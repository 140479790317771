import {
  createLimitConflictMessage,
  findLimitBreakpoints,
  findLimitConflicts,
} from 'modules/addons/helpers';

/* Import components */
import Button from 'modules/shared/components/inputs/Button';
import Clickable from 'modules/shared/components/widgets/interactive/Clickable';
import Collapse from '@material-ui/core/Collapse';
import ConflictModal from './ConflictModal';
import Dot from 'modules/shared/components/widgets/static/Dot';
import Icon from 'modules/shared/components/svg/Icon';
/* Import libraries */
import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { formatLocalTime } from 'utils/dateFormatter';
import { formatMoney } from 'utils/formatting';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import moment from 'moment';
/* Import CSS */
import styles from './css/AutoDecisioningListItem.css';
/* Import actions */
import { toggleRuleSet } from '../actions';
import { updateRuleSet } from '../auto_decisioning/actions';

var AutoDecisioningListItem = createClass({
  getInitialState() {
    return {
      name: null,
      showEditMenu: false,
      history_open: false,
      show_conflict_modal: false,
    };
  },

  toggleRuleSet: function() {
    const { rule_set, dispatch, id, auto_decisions } = this.props;

    let limit_breakpoints = findLimitBreakpoints(auto_decisions, rule_set);
    let limit_conflicts = findLimitConflicts(limit_breakpoints, rule_set);
    if (limit_conflicts && rule_set.attributes.paused) {
      this.setState({
        show_conflict_modal: true,
        conflict_message: createLimitConflictMessage(limit_conflicts),
      });
    } else {
      dispatch(toggleRuleSet(id, rule_set.attributes));
    }
  },

  showEditMenu: function() {
    this.setState({
      showEditMenu: true,
    });
  },

  hideEditMenu: function() {
    this.setState({
      showEditMenu: false,
    });
  },

  handleNameChange: function(e) {
    const { id, dispatch, rule_set } = this.props;
    this.setState({
      name: e.target.value,
    });
    debounce(
      dispatch(updateRuleSet(id, { ...rule_set, name: e.target.value }), 1000)
    );
  },

  viewHistoryDetails: function(version) {
    browserHistory.push({
      pathname: '/dashboard/addons',
      query: {
        id: version.id,
        version_index: version.current_index,
        task: 'view-auto',
      },
    });
  },

  handleCollapseToggle: function(history_data) {
    if (!history_data.length) return;
    const { history_open } = this.state;
    this.setState({ history_open: !history_open });
  },

  render: function() {
    const {
      title,
      loading,
      show_call_to_action,
      rule_set,
      disabled,
      updating,
      id,
      onEdit,
      view_only,
    } = this.props;
    const {
      showEditMenu,
      history_open,
      show_conflict_modal,
      conflict_message,
    } = this.state;
    let status = rule_set.attributes.paused
      ? statusConfig.paused
      : statusConfig.active;
    if (rule_set.attributes.on_hold) status = statusConfig.on_hold;

    let legal_types_parsed = '';
    if (rule_set.attributes.legal_types) {
      rule_set.attributes.legal_types.forEach((legal_type, index) => {
        legal_types_parsed = legal_types_parsed.concat(
          legal_types[legal_type].name
        );
        if (index != rule_set.attributes.legal_types.length - 1) {
          legal_types_parsed = legal_types_parsed.concat(', ');
        }
      });
    }

    let history_data = rule_set.attributes.history_data;
    let history_section = (
      <Collapse
        in={history_open}
        classes={{
          container: styles['collapse-container'],
        }}
      >
        {history_data &&
          rule_set.attributes.history_data.map((version) => {
            return (
              <div
                className={styles['history-item']}
                onClick={this.viewHistoryDetails.bind(this, version)}
              >
                <div>
                  {version.name} (Version {version.current_index})
                </div>
                <div>
                  {version.editor_first_name}{' '}
                  {formatLocalTime(version.updated_at, 'minute')}
                </div>
              </div>
            );
          })}
      </Collapse>
    );

    return (
      <li
        className={styles.item}
        id="item"
        onClick={(e) => {
          if (e.target.id.includes('item')) {
            onEdit(rule_set);
          }
        }}
      >
        {isMobile() && history_section}
        <div className={styles.header}>
          <div className={styles.details}>
            <div id="item-name" className={styles.name_input}>
              #{rule_set.attributes.serial_number} {rule_set.attributes.name}
              &nbsp;&nbsp;&nbsp;
              <Dot color={status.color} text={status.text} />
            </div>
            <div id="item-info" className={styles.info_container}>
              <span id="item-limit" className={styles.details_text}>
                Limit: $
                {formatMoney(parseFloat(rule_set.attributes.min_credit_value))}{' '}
                - $
                {formatMoney(parseFloat(rule_set.attributes.max_credit_value))}{' '}
                {legal_types_parsed}- (Version{' '}
                {rule_set.attributes.current_index})
              </span>
            </div>
            <div id="item-info" className={styles.info_container}>
              <span id="item-limit" className={styles.details_text}>
                Last edited by {rule_set.attributes.editor_first_name} at{' '}
                {formatLocalTime(rule_set.attributes.updated_at, 'minute')}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.options}>
          <div
            className={[styles.edit_menu, !showEditMenu && styles.hide].join(
              ' '
            )}
            onPointerEnter={this.showEditMenu}
            onPointerLeave={this.hideEditMenu}
          >
            <Icon icon="more_vert" />
            <ul>
              <Clickable onClick={() => onEdit(rule_set)}>
                {view_only ? 'view' : 'edit'}
              </Clickable>
              {status.btnText && !view_only && (
                <Clickable onClick={() => this.toggleRuleSet()}>
                  {' '}
                  {status.btnText}{' '}
                </Clickable>
              )}
            </ul>
          </div>
          <img
            onClick={this.handleCollapseToggle.bind(null, history_data)}
            src={require('images/icon_chevron_down-white.svg')}
            className={
              history_open
                ? `${styles['collapse-arrow']} ${styles['collapse-arrow-open']}`
                : history_data
                ? styles['collapse-arrow']
                : `${styles['collapse-arrow']} ${styles['collapse-empty']}`
            }
          />
          {show_conflict_modal && (
            <ConflictModal
              dismissHandler={() =>
                this.setState({ show_conflict_modal: false })
              }
              conflict_message={conflict_message}
            />
          )}
        </div>
        {!isMobile() && history_section}
      </li>
    );
  },
});
/* eslint-disable sort-keys-fix/sort-keys-fix */
const legal_types = {
  company: {
    name: 'Company',
  },
  partnership: {
    name: 'Partnership',
  },
  personal: {
    name: 'Personal',
  },
  sole_trader: {
    name: 'Sole Trader',
  },
  trust: {
    name: 'Trust',
  },
  association: {
    name: 'Association',
  },
  club: {
    name: 'Club',
  },
  education: {
    name: 'Education',
  },
  government: {
    name: 'Government',
  },
  other: {
    name: 'Other',
  },
  society: {
    name: 'Society',
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

const statusConfig = {
  active: {
    btnText: 'pause',
    color: 'blue',
    text: 'active',
  },
  on_hold: {
    // btnText: "enable",
    color: 'grey',
    text: 'on hold',
  },
  paused: {
    btnText: 'enable',
    color: 'red',
    text: 'paused',
  },
};

module.exports = connect((state, ownProps) => {
  return {
    rule_set: ownProps.rule_set,
    updating: ownProps.updating,
    disabled: ownProps.disabled,
    id: ownProps.id,
    onEdit: ownProps.onEdit,
    auto_decisions: state.add_ons.auto_decisions,
  };
})(AutoDecisioningListItem);
