type ENTITY_TYPES =
  | 'company'
  | 'other'
  | 'partnership'
  | 'personal'
  | 'sole_trader'
  | 'government'
  | 'trust';

const abnEntityTypes: { [key: string]: ENTITY_TYPES } = {
  ADF: 'other', // Approved Deposit Fund
  ARF: 'other', // APRA Regulated Fund (Fund Type Unknown)
  CCB: 'government', // Commonwealth Government Public Company
  CCC: 'government', // Commonwealth Government Co-operative
  CCL: 'government', // Commonwealth Government Limited Partnership
  CCN: 'government', // Commonwealth Government Other Unincorporated Entity
  CCO: 'government', // Commonwealth Government Other Incorporated Entity
  CCP: 'government', // Commonwealth Government Pooled Development Fund
  CCR: 'government', // Commonwealth Government Private Company
  CCS: 'government', // Commonwealth Government Strata Title
  CCT: 'government', // Commonwealth Government Public Trading Trust
  CCU: 'government', // Commonwealth Government Corporate Unit Trust
  CGA: 'government', // Commonwealth Government Statutory Authority
  CGC: 'government', // Commonwealth Government Company
  CGE: 'government', // Commonwealth Government Entity
  CGP: 'government', // Commonwealth Government Partnership
  CGS: 'government', // Commonwealth Government Super Fund
  CGT: 'government', // Commonwealth Government Trust
  CMT: 'trust', // Cash Management Trust
  COP: 'other', // Co-operative
  CSA: 'government', // Commonwealth Government APRA Regulated Public Sector Fund
  CSP: 'government', // Commonwealth Government APRA Regulated Public Sector Scheme
  CSS: 'government', // Commonwealth Government Non-Regulated Super Fund
  CTC: 'government', // Commonwealth Government Cash Management Trust
  CTD: 'government', // Commonwealth Government Discretionary Services Management Trust
  CTF: 'government', // Commonwealth Government Fixed Trust
  CTH: 'government', // Commonwealth Government Hybrid Trust
  CTI: 'government', // Commonwealth Government Discretionary Investment Trust
  CTL: 'government', // Commonwealth Government Listed Public Unit Trust
  CTQ: 'government', // Commonwealth Government Unlisted Public Unit Trust
  CTT: 'government', // Commonwealth Government Discretionary Trading Trust
  CTU: 'government', // Commonwealth Government Fixed Unit Trust
  CUT: 'government', // Corporate Unit Trust
  DES: 'other', // Deceased Estate
  DIP: 'government', // Diplomatic/Consulate Body or High Commissioner
  DIT: 'trust', // Discretionary Investment Trust
  DST: 'trust', // Discretionary Services Management Trust
  DTT: 'trust', // Discretionary Trading Trust
  FHS: 'trust', // First Home Saver Accounts Trust
  FPT: 'partnership', // Family Partnership
  FUT: 'trust', // Fixed Unit Trust
  FXT: 'trust', // Fixed Trust
  HYT: 'trust', // Hybrid Trust
  IND: 'sole_trader', // Trader Individual/Sole Trader
  LCB: 'government', // Local Government Public Company
  LCC: 'government', // Local Government Co-operative
  LCL: 'government', // Local Government Limited Partnership
  LCN: 'government', // Local Government Other Unincorporated Entity
  LCO: 'government', // Local Government Other Incorporated Entity
  LCP: 'government', // Local Government Pooled Development Fund
  LCR: 'government', // Local Government Private Company
  LCS: 'government', // Local Government Strata Title
  LCT: 'government', // Local Government Public Trading Trust
  LCU: 'government', // Local Government Corporate Unit Trust
  LGA: 'government', // Local Government Statutory Authority
  LGC: 'government', // Local Government Company
  LGE: 'government', // Local Government Entity
  LGP: 'government', // Local Government Partnership
  LGT: 'government', // Local Government Trust
  LPT: 'partnership', // Limited Partnership
  LSA: 'government', // Local Government APRA Regulated Public Sector Fund
  LSP: 'government', // Local Government APRA Regulated Public Sector Scheme
  LSS: 'government', // Local Government Non-Regulated Super Fund
  LTC: 'government', // Local Government Cash Management Trust
  LTD: 'government', // Local Government Discretionary Services Management Trust
  LTF: 'government', // Local Government Fixed Trust
  LTH: 'government', // Local Government Hybrid Trust
  LTI: 'government', // Local Government Discretionary Investment Trust
  LTL: 'government', // Local Government Listed Public Unit Trust
  LTQ: 'government', // Local Government Unlisted Public Unit Trust
  LTT: 'government', // Local Government Discretionary Trading Trust
  LTU: 'government', // Local Government Fixed Unit Trust
  NPF: 'government', // APRA Regulated Non-Public Offer Fund
  NRF: 'other', // Non-Regulated Superannuation Fund
  OIE: 'other', // Other Incorporated Entity
  PDF: 'other', // Pooled Development Fund
  POF: 'government', // APRA Regulated Public Offer Fund
  PQT: 'trust', // Unlisted Public Unit Trust
  PRV: 'company', // Australian Private Company
  PST: 'trust', // Pooled Superannuation Trust
  PTR: 'partnership', // Other Partnership
  PTT: 'trust', // Public Trading trust
  PUB: 'company', // Australian Public Company
  PUT: 'government', // Listed Public Unit Trust
  SAF: 'government', // Small APRA Regulated Fund
  SCB: 'government', // State Government Public Company
  SCC: 'government', // State Government Co-operative
  SCL: 'government', // State Government Limited Partnership
  SCN: 'government', // State Government Other Unincorporated Entity
  SCO: 'government', // State Government Other Incorporated Entity
  SCP: 'government', // State Government Pooled Development Fund
  SCR: 'government', // State Government Private Company
  SCS: 'government', // State Government Strata Title
  SCT: 'government', // State Government Public Trading Trust
  SCU: 'government', // State Government Corporate Unit Trust
  SGA: 'government', // State Government Statutory Authority
  SGC: 'government', // State Government Company
  SGE: 'government', // State Government Entity
  SGP: 'government', // State Government Partnership
  SGT: 'government', // State Government Trust
  SMF: 'government', // ATO Regulated Self-Managed Superannuation Fund
  SSA: 'government', // State Government APRA Regulated Public Sector Fund
  SSP: 'government', // State Government APRA Regulated Public Sector Scheme
  SSS: 'government', // State Government Non-Regulated Super Fund
  STC: 'government', // State Government Cash Management Trust
  STD: 'government', // State Government Discretionary Services Management Trust
  STF: 'government', // State Government Fixed Trust
  STH: 'government', // State Government Hybrid Trust
  STI: 'government', // State Government Discretionary Investment Trust
  STL: 'government', // State Government Listed Public Unit Trust
  STQ: 'government', // State Government Unlisted Public Unit Trust
  STR: 'other', // Strata-title
  STT: 'government', // State Government Discretionary Trading Trust
  STU: 'government', // State Government Fixed Unit Trust
  SUP: 'other', // Super Fund
  TCB: 'government', // Territory Government Public Company
  TCC: 'government', // Territory Government Co-operative
  TCL: 'government', // Territory Government Limited Partnership
  TCN: 'government', // Territory Government Other Unincorporated Entity
  TCO: 'government', // Territory Government Other Incorporated Entity
  TCP: 'government', // Territory Government Pooled Development Fund
  TCR: 'government', // Territory Government Private Company
  TCS: 'government', // Territory Government Strata Title
  TCT: 'government', // Territory Government Public Trading Trust
  TCU: 'government', // Territory Government Corporate Unit Trust
  TGA: 'government', // Territory Government Statutory Authority
  TGE: 'government', // Territory Government Entity
  TGP: 'government', // Territory Government Partnership
  TGT: 'government', // Territory Government Trust
  TRT: 'trust', // Other trust
  TSA: 'government', // Territory Government APRA Regulated Public Sector Fund
  TSP: 'government', // Territory Government APRA Regulated Public Sector Scheme
  TSS: 'government', // Territory Government Non-Regulated Super Fund
  TTC: 'government', // Territory Government Cash Management Trust
  TTD: 'government', // Territory Government Discretionary Services Management Trust
  TTF: 'government', // Territory Government Fixed Trust
  TTH: 'government', // Territory Government Hybrid Trust
  TTI: 'government', // Territory Government Discretionary Investment Trust
  TTL: 'government', // Territory Government Listed Public Unit Trust
  TTQ: 'government', // Territory Government Unlisted Public Unit Trust
  TTT: 'government', // Territory Government Discretionary Trading Trust
  TTU: 'government', // Territory Government Fixed Unit Trust
  UIE: 'other', // Other Unincorporated Entity
};

export default abnEntityTypes;
