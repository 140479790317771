import { Checkbox, FormControlLabel } from '@material-ui/core';
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import RecordHistory from 'modules/new-applications/components/RecordHistory';
import styles from 'modules/new-applications/css/LoadedInSystem.css';
import ContentWithFooter from 'modules/shared/components/containers/ContentWithFooter';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import React, { useState } from 'react';

export default function LoadedInSystem(props) {
  const { application, currentUser, onFetchApplicationRecord } = props;
  const { loadedInSystemHistories } = application;
  const [isLoadedInSystem, setIsLoadedInSystem] = useState(
    application.loadedInSystem || false
  );

  const onSuccessCallback = (updatedApplication) => {
    mixpanel.track('Loaded in system', {
      Application: application.id,
      'Entity ID': get(currentUser, 'currentEntity.id'),
      distinct_id: currentUser.id,
      loaded_in_system: updatedApplication.loadedInSystem || false,
    });

    onFetchApplicationRecord();
  };

  const onChange = (event) => {
    const isChecked = get(event, 'target.checked', false);

    setIsLoadedInSystem(isChecked);

    application.update({
      attributes: { loaded_in_system: isChecked },
      currentUser,
      onSuccessCallback,
    });
  };

  const content = (
    <FixedContent header="Loaded in system">
      <GridContent>
        <FormControlLabel
          classes={{
            label: styles.checkbox_label,
          }}
          control={
            <Checkbox
              className={styles.checkbox}
              checked={isLoadedInSystem}
              onChange={onChange}
            />
          }
          label="Loaded in system?"
        />
      </GridContent>
    </FixedContent>
  );

  if (loadedInSystemHistories.length > 0) {
    return (
      <ContentWithFooter
        footer={<RecordHistory histories={loadedInSystemHistories} />}
      >
        {content}
      </ContentWithFooter>
    );
  }

  return content;
}
