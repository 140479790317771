/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';

/* Import components */
import ToolTip from 'modules/shared/components/widgets/interactive/ToolTip';

import styles from './css/Review.css';
import { getIsIdentificationDisclaimerVisible } from '../utils';

class ReviewTerms extends React.Component {
  agreements = () => {
    const {
      entity_type,
      isIdentificationDisclaimerVisible,
      supplierName,
      termsUrl,
      agreements,
      toggleAgreement,
    } = this.props;

    let agree_text_1 = 'individually and on behalf of the Business Customer';
    if (entity_type === 'personal') {
      agree_text_1 = 'individually and on behalf of the applicant/s';
    }

    return (
      <div>
        <div className={styles.mainDisclaimer}>
          <p>
            1Centre is the software that powers this platform. We facilitate the
            aggregation of relevant data for your supplier to make a decision
            about forming a trading relationship with you.
          </p>
          <br></br>
        </div>
        <div
          className={agreements[0].agreed ? styles.link_active : styles.link}
          onClick={() => toggleAgreement(1)}
        >
          I have read and agree to the{' '}
          <a href={termsUrl} target="_blank">
            1Centre Terms & Conditions
          </a>{' '}
          {agree_text_1}
        </div>
        <div
          className={agreements[1].agreed ? styles.link_active : styles.link}
          onClick={() => toggleAgreement(2)}
        >
          I acknowledge&nbsp;
          <a href={defaults.policy} target="_blank">
            1Centre’s privacy policy
          </a>
          &nbsp;and consent to receiving relevant communications electronically
        </div>
        {isIdentificationDisclaimerVisible && agreements[2] && (
          <div
            className={agreements[2].agreed ? styles.link_active : styles.link}
            onClick={() => toggleAgreement(3)}
          >
            I confirm that {supplierName} has the right to hold my ID documents
            on file until my identity has been confirmed.
          </div>
        )}
      </div>
    );
  };

  render() {
    const { isAgreeTooltipActive } = this.props;

    return (
      <div>
        <div className={styles.agree_section}>
          {this.agreements()}
          {isAgreeTooltipActive && (
            <ToolTip
              tip_name="ReviewTermsAgree"
              css_style="review_supplier_terms_agree"
            />
          )}
        </div>
      </div>
    );
  }
}

const defaults = {
  title: '1Centre T&Cs',
  t_and_c_url: {
    AU:
      'https://s3-ap-southeast-2.amazonaws.com/s3-1centre-production/pdfs/files/1centre_terms_document/20180112+FINAL+1Centre+Business+Customer+T+Cs+(00494980-2xE3943).pdf',
    NZ:
      'https://s3-1centre-production.s3-ap-southeast-2.amazonaws.com/pdfs/files/1centre_terms_document/FINAL+2021+-+1Centre+-+NZ+Business+Customer+-+Terms+of+Use.pdf',
  },
  policy: 'https://www.1centre.com/Privacy',
};

export default connect((state, ownProps) => {
  const cobBusiness = state.cob_business;
  const cobSection = state.cob_section;
  const region = cobBusiness.entity_region;
  const termsUrl = defaults.t_and_c_url[region];

  return {
    entity_region: region,
    entity_type: cobBusiness.entity_type,
    query_params: state.current_user.query_params,
    submitting: state.cob_review.submitting,
    supplierName: cobSection.application.attributes.supplier_name,
    isIdentificationDisclaimerVisible: getIsIdentificationDisclaimerVisible(),
    termsUrl,
    title: defaults.title,
  };
})(ReviewTerms);
