import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import get from 'lodash.get';
import styles from 'modules/addons/components/css/AddOnsDetails.css';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import UncontrolledTextInput from 'modules/shared/components/inputs/UncontrolledTextInput';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import React, { Fragment, ReactElement } from 'react';

interface Props {
  config: any;
  data: any;
  errors: any;
  onChange: (params: any) => void;
  readOnly: boolean;
}

const MandatoryOptions = [
  { label: 'Mandatory', value: true },
  { label: 'Optional', value: false },
];

function AttachDocument(props: Props): ReactElement {
  const { config, errors, onChange, readOnly } = props;
  const { active, question, mandatory } = config;

  const handleToggle = () => {
    onChange({ active: !active });
  };

  const handleChange = (name: string, value) => {
    onChange({ [name]: value });
  };

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <div className={styles.section_no_border}>
        <h3>Attach document</h3>
        <div className={styles.row}>
          <SquareCheckbox
            checkboxId="attach_document"
            checkboxName="attach_document"
            checked={active}
            handleChange={handleToggle}
            label="Add additional document upload (PDF, PNG, JPG and JPEG)"
            disabled={readOnly}
          />
        </div>
        {active && (
          <Fragment>
            <div className={styles.row}>
              <RadioGroup>
                {MandatoryOptions.map(({ label, value }) => (
                  <FormControlLabel
                    name={'mandatory'}
                    disabled={readOnly}
                    key={`radio-${label}`}
                    value={value}
                    control={<Radio />}
                    label={<div>{label}</div>}
                    checked={mandatory === value}
                    onChange={(e) =>
                      handleChange(
                        'mandatory',
                        (e.target as HTMLInputElement).value === 'true'
                      )
                    }
                  />
                ))}
              </RadioGroup>
            </div>
            <div className={styles.row}>
              <p className="mb-4">Type your question/section name here.</p>
              <UncontrolledTextInput
                id="question"
                disabled={readOnly}
                defaultValue={question}
                error={errors}
                onChange={(e) => handleChange('question', e.target.value)}
              />
            </div>
          </Fragment>
        )}
      </div>
    </MuiThemeProvider>
  );
}

export default AttachDocument;
