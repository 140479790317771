/* eslint-disable max-lines */
import get from 'lodash.get';
import LegalEntityAgreementUploader from 'modules/legal-entity-agreement-uploader/components/LegalEntityAgreementUploader';
import styles from 'modules/new-applications/css/BusinessDetails.css';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import React, { Fragment } from 'react';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import { isSanitizedStringEqual } from 'utils/sanitizeName';

const PEOPLE_HEADER = {
  company: 'Director',
  partnership: 'Partner',
  personal: 'Individual',
  sole_trader: 'Sole trader',
  trust: 'Trustee',
};

const PEOPLE_COMPONENT = {
  company: Director,
  partnership: GenericPerson,
  personal: Individual,
  sole_trader: SoleTrader,
  trust: GenericPerson,
};

function FlexContent(props) {
  const { children } = props;

  return <div className={styles.flex_contents}>{children}</div>;
}

function CompanyInformation(props) {
  const { application } = props;

  return (
    <FixedContent header="Business information">
      <GridContent>
        <div>
          <LabeledContent
            label="Company name"
            content={application.companyName}
          />
          <LabeledContent
            label="Account type"
            content={application.formattedApplicationType}
          />
          <LabeledContent
            label={application.businessNumberLabel}
            content={application.businessNumber}
          />
          <LabeledContent
            label="Business start date"
            content={application.formattedRegisteredAt}
          />
          <LabeledContent
            label="Number of staff"
            content={application.staffCount}
          />
        </div>
        <div>
          <LabeledContent
            label="Business type"
            content={application.formattedLegalType}
          />
          <LabeledContent
            label="Business location"
            content={application.formattedRegion}
          />
          <LabeledContent
            label={application.companyNumberLabel}
            content={application.companyNumber}
          />
          <LabeledContent
            label="Business phone number"
            content={application.contactPhoneNumber}
          />
        </div>
      </GridContent>
    </FixedContent>
  );
}

const EntityName = (props) => {
  const { application } = props;

  if (application.region !== 'AU') {
    return null;
  }

  return (
    <LabeledContent label="Entity name" content={application.companyName} />
  );
};

function NonCompanyInformation(props) {
  const { application } = props;

  return (
    <FixedContent header="Business information">
      <GridContent>
        <EntityName application={application} />
        <LabeledContent
          label={application.tradingNameLabel}
          content={application.tradingName}
        />

        <LabeledContent
          label="Business type"
          content={application.formattedLegalType}
        />
        <LabeledContent
          label="Account type"
          content={application.formattedApplicationType}
        />

        <LabeledContent
          label={application.businessNumberLabel}
          content={application.businessNumber}
          isVisibleWhenEmpty={application.isAustraliaNumberVisible}
        />
        <LabeledContent
          label={application.companyNumberLabel}
          content={application.companyNumber}
          isVisibleWhenEmpty={application.isAustraliaNumberVisible}
        />

        <LabeledContent
          label="Business location"
          content={application.formattedRegion}
        />
        <LabeledContent
          label="Business start date"
          content={application.formattedRegisteredAt}
          isVisibleWhenEmpty={false}
        />

        <LabeledContent
          label="Business phone number"
          content={application.contactPhoneNumber}
          isVisibleWhenEmpty={false}
        />
        <LabeledContent
          label="Number of staff"
          content={application.staffCount}
          isVisibleWhenEmpty={false}
        />
      </GridContent>
    </FixedContent>
  );
}

function PersonalInformation(props) {
  const { application } = props;

  return (
    <FixedContent header="Customer details">
      <GridContent>
        <div>
          <LabeledContent
            label="Account name"
            content={application.formattedBusinessName}
          />
          <LabeledContent
            label="Account type"
            content={application.formattedApplicationType}
          />
          <LabeledContent
            label="Business location"
            content={application.formattedRegion}
          />
        </div>
        <div>
          <LabeledContent
            label="Entity type"
            content={application.formattedLegalType}
          />
          <LabeledContent
            label="Personal type"
            content={application.formattedPersonalType}
            isVisibleWhenEmpty={false}
          />
        </div>
      </GridContent>
    </FixedContent>
  );
}

function BusinessInformation(props) {
  const { application } = props;

  if (application.legalType === 'company') {
    return <CompanyInformation {...props} />;
  }

  if (application.legalType === 'personal') {
    return <PersonalInformation {...props} />;
  }

  return <NonCompanyInformation {...props} />;
}

function Addresses(props) {
  const { application } = props;

  if (application.legalType === 'personal') {
    return null;
  }

  const physicalAddress = get(application, 'physicalAddress');
  const postalAddress = get(application, 'postalAddress');

  let postalAddressLabel = 'Postal/delivery address';
  if (application.legalType === 'company') {
    postalAddressLabel = 'Postal address';
  }

  return (
    <FixedContent header="Address">
      <GridContent>
        <div>
          <LabeledContent
            label="Business address"
            content={physicalAddress.fullAddress}
          />
          <LabeledContent
            label="Delivery instructions"
            content={application.deliveryInstruction}
            isVisibleWhenEmpty={application.legalType !== 'company'}
          />
        </div>

        <div>
          <LabeledContent
            label={postalAddressLabel}
            content={postalAddress.fullAddress}
          />
        </div>
      </GridContent>
    </FixedContent>
  );
}

function NewZealandDirector(props) {
  const { index, person } = props;

  return (
    <div>
      <LabeledContent label={`Director ${index}`} content={person.name} />
      <LabeledContent
        label="Appointment date"
        content={person.formattedAppointedAt}
      />
      <LabeledContent
        label="Residential address"
        content={person.formattedResidentialAddress}
      />
    </div>
  );
}

function retrieveDirectorAddress({ application, person }) {
  if (isPresent(person.formattedResidentialAddress)) {
    return person.formattedResidentialAddress;
  }

  const guarantor = (application.guarantors || []).find(
    (g) =>
      isSanitizedStringEqual(g.firstName, person.firstName) &&
      isSanitizedStringEqual(g.lastName, person.lastName)
  );
  if (isBlank(guarantor)) {
    return null;
  }

  const authorisation = (application.authorisations || []).find(
    (a) => a.id === guarantor.authorisationId
  );
  if (isBlank(authorisation)) {
    return null;
  }

  return get(authorisation, 'signature.fullResidentialAddress');
}

function AustraliaDirector(props) {
  const { application, index, person } = props;
  const residentialAddress = retrieveDirectorAddress({ application, person });

  return (
    <div>
      <LabeledContent label={`Director ${index}`} content={person.name} />
      <LabeledContent
        label="Email"
        content={person.email}
        isVisibleWhenEmpty={false}
      />
      <LabeledContent
        label="Residential address"
        content={residentialAddress}
      />
    </div>
  );
}

function Director(props) {
  const { application } = props;

  if (application.region === 'NZ') {
    return <NewZealandDirector {...props} />;
  }

  return <AustraliaDirector {...props} />;
}

function SoleTrader(props) {
  const { index, person } = props;

  return (
    <div>
      <LabeledContent label={`Sole trader ${index}`} content={person.name} />
      <LabeledContent label="Email" content={person.email} />
      <LabeledContent
        label="Residential address"
        content={person.fullResidentialAddress}
      />
    </div>
  );
}

function Individual(props) {
  const { index, person } = props;

  return (
    <div>
      <LabeledContent label={`Individual ${index}`} content={person.name} />
      <LabeledContent
        label="Previous last name"
        content={person.previousLastName}
      />
      <LabeledContent label="Email" content={person.email} />
      <LabeledContent
        label="Phone number"
        content={person.formattedPhoneNumber}
      />
      <LabeledContent
        label="Residential address"
        content={person.fullResidentialAddress}
      />
      <LabeledContent
        label="Postal address"
        content={person.fullPostalAddress}
      />
      <LabeledContent label="Employer" content={person.employer} />
      <LabeledContent label="Position" content={person.position} />
    </div>
  );
}

function GenericPerson(props) {
  const { index, label, person } = props;

  return (
    <div>
      <LabeledContent label={`${label} ${index}`} content={person.name} />
      <LabeledContent label="Email" content={person.email} />
    </div>
  );
}

function isPeopleComponentVisible(legalType, applicationType) {
  const visible = ['company', 'personal', 'sole_trader'];

  if (applicationType === 'credit') {
    visible.push('partnership', 'trust');
  }

  return visible.includes(legalType);
}

function People(props) {
  const { application } = props;
  const { legalType } = application;

  const people = get(application, 'consumer.people', []);

  if (legalType === 'sole_trader') {
    const authorisation =
      application.authorisations.find((auth) => auth.isApplicant) || {};

    const { email, firstName, lastName } = authorisation;

    const soleTrader = {
      email,
      fullResidentialAddress: get(
        authorisation,
        'signature.attributes.full_residential_address'
      ),
      name: firstName && `${firstName} ${lastName}`,
    };

    if (people.length === 0) {
      people.push(soleTrader);
    }
  }

  if (!isPeopleComponentVisible(legalType, application.applicationType)) {
    return null;
  }

  const label = PEOPLE_HEADER[application.legalType];
  const Component = PEOPLE_COMPONENT[legalType];
  const peopleComponents = people.map((person, index) => (
    <Component
      key={`person-${index + 1}`}
      index={index + 1}
      application={application}
      label={label}
      person={person}
    />
  ));

  if (peopleComponents.length === 0) {
    return null;
  }

  return (
    <FixedContent header={label}>
      <FlexContent>{peopleComponents}</FlexContent>
    </FixedContent>
  );
}

function attachmentLabel(application) {
  const { legalType } = application;

  if (legalType === 'trust') {
    return 'Trust deed';
  }

  if (legalType === 'partnership') {
    return 'Partnership agreement';
  }

  return '';
}

function Attachments(props) {
  const { application, currentUser, onFetchApplicationRecord } = props;
  const { isLoading, setIsLoading } = useIsLoadingState();

  if (!['partnership', 'trust'].includes(application.legalType)) {
    return null;
  }

  const {
    legalEntityAgreement,
    partnershipAgreementUploadVisible,
    trustDeedUploadVisible,
  } = application;
  const isAttachmentVisible =
    trustDeedUploadVisible || partnershipAgreementUploadVisible;

  if (!isAttachmentVisible) {
    return null;
  }

  const onSuccessCallback = () => {
    onFetchApplicationRecord(() => setIsLoading(false));
  };

  const onUpload = (data) => {
    setIsLoading(true);

    application.update({
      attributes: {
        legal_entity_agreement: data,
      },
      currentUser,
      onSuccessCallback,
    });
  };

  return (
    <FixedContent header={attachmentLabel(application)}>
      <LegalEntityAgreementUploader
        entityType={application.legalType}
        partnershipAgreementUploadVisible={partnershipAgreementUploadVisible}
        trustDeedUploadVisible={trustDeedUploadVisible}
        legalEntityAgreement={legalEntityAgreement}
        disabled={application.isFated}
        isLoading={isLoading}
        onUpload={onUpload}
      />
    </FixedContent>
  );
}

export default function BusinessDetails(props) {
  const { application, currentUser, onFetchApplicationRecord } = props;

  return (
    <Fragment>
      <BusinessInformation application={application} />
      <Addresses application={application} />
      <People application={application} />
      <Attachments
        application={application}
        currentUser={currentUser}
        onFetchApplicationRecord={onFetchApplicationRecord}
      />
    </Fragment>
  );
}
