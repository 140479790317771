import { TERMS_AND_CONDITIONS } from 'constants';
import Button from 'modules/shared/components/inputs/Button';
import ExtraDescription from 'modules/shared/components/text/ExtraDescription';
import SupplierAcknowledgements from 'modules/shared/components/text/SupplierAcknowledgements';
import FileWidget from 'modules/shared/components/widgets/interactive/FileWidget';
import PDFLogo from 'modules/shared/components/widgets/static/PDFLogo';
/* Import components */
import PdfReader from 'modules/shared/components/widgets/static/pdfReader';
import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader';
import SquareModal from 'modules/shared/components/widgets/static/SquareModal';
import {
  isFeatureEditEnabled,
  isHeadquarter,
} from 'modules/shared/helpers/headquarterDetect';
/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';
import extractAttachmentUrl from 'utils/extractAttachmentUrl';
import { termsDocuments } from 'utils/termsDocuments';

/* Import actions */
import {
  setPaperlessCustomTermsIntent,
  setPaperlessTermsAndConditionsDoc,
  setViewedPaperlessTerms,
  updateTermsDoc,
} from '../actions';
import admin_styles from './css/Admin.css';
/* Import CSS */
import styles from './css/CommonEditProfile.css';

const PaperlessTerms = createClass({
  docReady(data) {
    const { dispatch } = this.props;
    dispatch(setPaperlessTermsAndConditionsDoc(data));
  },

  getInitialState() {
    return {
      showAcknowledgements: false,
    };
  },

  hideAcknowledgements() {
    this.setState({ showAcknowledgements: false });
  },

  onScrollToBottom() {
    const { dispatch } = this.props;
    dispatch(setViewedPaperlessTerms(true));
  },

  render() {
    const {
      title,
      terms_explanation,
      upload_label,
      use_recommended_label,
      show_upload,
      src,
      update_loading,
      loading_options,
      changes_made,
      viewed_terms,
      region,
    } = this.props;

    const { showAcknowledgements } = this.state;

    let content = [];
    let completeWidget;

    let view_button = null;
    if (src && src.url) {
      if (src.url.startsWith('data:application/pdf;')) {
        view_button = (
          <a
            className={admin_styles.link}
            target="_blank"
            href={src.url}
            rel="noopener"
            download
          >
            view
          </a>
        );
      } else {
        view_button = (
          <a
            className={admin_styles.link}
            target="_blank"
            href={src.url}
            rel="noopener"
          >
            view
          </a>
        );
      }
    }

    const terms = (
      <PdfReader
        url={TERMS_AND_CONDITIONS[region].paperless}
        onScrollToBottom={this.onScrollToBottom}
      />
    );

    if (show_upload) {
      if (src) {
        completeWidget = <PDFLogo alt="PDF Upload" />;
      }
      content = [
        <div key="PaperlessTerms" className={admin_styles.sections_upload}>
          <FileWidget
            handleFileCallback={this.docReady}
            text="Drag"
            completeWidget={completeWidget}
            accept=".pdf"
            id="PaperlessTerms"
            disableChangeButton={!isFeatureEditEnabled('TC')}
          />
          {view_button}
        </div>,
      ];
    } else {
      content = (
        <div className={admin_styles.sections_terms}>
          <div className={admin_styles.terms}>{terms}</div>
        </div>
      );
    }

    let loader = null;
    let update_button_text = 'save your selection';
    let update_click = this.submit;
    let update_button = null;
    let confirmation_text = null;
    let acknowledgement_modal = null;
    let grey = false;

    if (!changes_made) {
      update_button_text = 'save as is';
    }

    if (!viewed_terms && !show_upload) {
      update_button_text = 'please read all of the terms before saving them';
      update_click = null;
      grey = true;
    }

    if (!show_upload) {
      confirmation_text = (
        <div className={styles.terms_warning}>
          In opting to use the generic standard terms I have read and understand{' '}
          <a onClick={this.showAcknowledgements}>the acknowledgements</a> about
          these terms and I consider the terms are appropriate for the Supplier.
        </div>
      );
    }

    if (update_loading) {
      loader = <span className={styles.loading}></span>;
      update_button_text = 'saving';
      update_click = null;
    }

    update_button = (
      <div className={styles.button_right}>
        <Button
          handleClick={update_click}
          text={update_button_text}
          grey={grey}
        />
        {loader}
      </div>
    );

    if (loading_options) {
      content.push(<SimpleLoader />);
    }

    if (showAcknowledgements) {
      acknowledgement_modal = (
        <SquareModal
          dismissHandler={this.hideAcknowledgements}
          title="Supplier acknowledgements"
        >
          <SupplierAcknowledgements scrollable region={region} />
        </SquareModal>
      );
    }

    return (
      <div className={styles.row}>
        <div className={styles.messages}>
          <div className={styles.block_wide}>
            <div className={styles.liner}>
              <h2 className={styles.header}>{title}</h2>
              <ExtraDescription
                hintName={'headquarterSettingHint'}
                show={isHeadquarter()}
              />
              <div className={admin_styles.row}>
                <div className={admin_styles.sections_message}>
                  {terms_explanation.map(function(text, i) {
                    return <p key={i}>{text}</p>;
                  })}
                  {isFeatureEditEnabled('TC') && (
                    <div className={admin_styles.buttons}>
                      <Button
                        white={show_upload}
                        disabled={false}
                        handleClick={this.showTerms}
                        text={use_recommended_label}
                      />
                      <Button
                        white={!show_upload}
                        disabled={show_upload}
                        handleClick={this.showUpload}
                        text={upload_label}
                      />
                    </div>
                  )}
                </div>
                {content}
                <div className={styles.button_bottom_right}>
                  {changes_made && confirmation_text}
                  {changes_made && update_button}
                  {viewed_terms}
                </div>
              </div>
              {acknowledgement_modal}
            </div>
          </div>
        </div>
      </div>
    );
  },

  showAcknowledgements() {
    this.setState({ showAcknowledgements: true });
  },

  showTerms() {
    const { dispatch } = this.props;
    dispatch(setPaperlessCustomTermsIntent(false));
  },

  showUpload() {
    const { dispatch } = this.props;
    dispatch(setPaperlessCustomTermsIntent(true));
  },

  submit() {
    const { dispatch, show_upload: use_custom, src, viewed_terms } = this.props;
    if (use_custom && src) {
      dispatch(updateTermsDoc(use_custom, src.url, 'paperless'));
    } else {
      dispatch(updateTermsDoc(false, null, 'paperless'));
    }
  },
});

const defaults = {
  terms_explanation: ['Scan and upload your own'],
  title: "Set your T&C's for paperless direct debit",
  upload_label: 'upload T&C’s',
  use_recommended_label: 'use generic',
};

module.exports = connect((state) => {
  const s = state.manage_profile;
  const ent = s.current_entity.attributes;
  const terms_doc = termsDocuments(
    s.entity_includes,
    'terms_documents',
    'paperless'
  );
  const terms_src =
    terms_doc && extractAttachmentUrl(terms_doc.attributes, 'file');
  const show_upload =
    s.settings_paperless_custom_terms != null
      ? s.settings_paperless_custom_terms
      : ent.uses_custom_paperless_terms;
  const src =
    s.settings_paperless_terms_document != null
      ? { url: s.settings_paperless_terms_document }
      : terms_src;
  const changes_made =
    s.settings_section_edited != null
      ? s.settings_section_edited['paperless_terms']
      : false;
  return {
    changes_made,
    region: ent.region,
    show_upload,
    src,
    terms_explanation: defaults.terms_explanation,
    title: defaults.title,
    update_loading: state.manage_profile.settings_paperless_terms_updating,
    upload_label: defaults.upload_label,
    use_recommended_label: defaults.use_recommended_label,
    viewed_terms: state.manage_profile.settings_paperless_terms_viewed,
  };
})(PaperlessTerms);
