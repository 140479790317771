import Button from 'modules/shared/components/inputs/Button';
import Clickable from 'modules/shared/components/widgets/interactive/Clickable';
import React, { Fragment } from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import equifaxStyles from './css/EquifaxPersonalComponent.css';
import get from 'lodash.get';
import { formatDate } from 'utils/dateFormatter';
import { formatMoney } from 'utils/formatting';
import logo from 'images/logo-equifax.png';
import moment from 'moment';
import scoreStyles from './css/Score.css';
import styles from '../../../css/CommonReviewBusiness.css';

const EquifaxPersonalComponent = createClass({
  getInitialState() {
    return {};
  },

  formatTime(data) {
    let hm = data.split(':');
    return `${hm[0]}:${hm[1]}`;
  },

  getData() {
    const { data } = this.props;
    let productHeader = get(data, 'response.product_header', {}),
      productData = get(data, 'response.product_data', {}),
      scoreData = get(productData, 'score_data', {}),
      enquiryReport = get(productData, 'enquiry_report', {}),
      creditEnquiries = get(
        enquiryReport,
        'commercial_credit_file.credit_enquiries',
        {}
      ),
      creditEnquiry = get(creditEnquiries, 'credit_enquiry', {}),
      primaryMatch = get(enquiryReport, 'primary_match', {}),
      individualName = get(primaryMatch, 'individual_name', {}),
      employment = get(enquiryReport, 'employment', {}),
      employer = get(employment, 'employer', {}),
      addresses = get(enquiryReport, 'addresses', {}),
      address = get(addresses, 'address', []),
      characteristic = get(
        productData,
        'summary_data.data_block.characteristic',
        []
      ),
      directorships = get(
        enquiryReport,
        'public_record_or_consumer_credit_information.directorships',
        {}
      ),
      directorship = get(directorships, 'directorship', []);

    return {
      productHeader,
      productData,
      scoreData,
      enquiryReport,
      creditEnquiries,
      creditEnquiry,
      primaryMatch,
      individualName,
      addresses,
      address,
      characteristic,
      directorships,
      directorship,
      employment,
      employer,
    };
  },

  _renderHeader() {
    let {
      address,
      characteristic,
      individualName,
      primaryMatch,
      productHeader,
    } = this.getData();

    let ageOfFile = characteristic.find((obj) => obj['@id'] === 'NA8904'),
      numOfCrossRef = characteristic.find((obj) => obj['@id'] === 'NA8900');

    if (address.constructor === Array) {
      address = address.find((address) => address['@type'] === 'C');
    }
    let refDesc =
      numOfCrossRef && numOfCrossRef.value > 0
        ? `(+ ${numOfCrossRef.value} cross-reference)`
        : '';
    let renderAddress = (address) => {
      return (
        <Fragment>
          {address.street_number} {address.street_name} {address.street_type},{' '}
          {address.suburb} {address.state} {address.postcode}
        </Fragment>
      );
    };
    return (
      <div className={equifaxStyles.header}>
        <div className={equifaxStyles.row}>
          <div className={equifaxStyles.col_7}>
            <div className={equifaxStyles.row}>
              <div className={equifaxStyles.header_title}>Report for:</div>
              <div className={equifaxStyles.header_value}>
                {individualName.first_given_name} {individualName.family_name}{' '}
                {refDesc}
                <br />
                D.O.B.{' '}
                {formatDate(primaryMatch.date_of_birth, 'DD MMM YYYY') || '-'}
                <br />
                <br />
                {address && renderAddress(address)}
              </div>
            </div>

            <div className={equifaxStyles.row}>
              <div className={equifaxStyles.header_title}>Age of file:</div>
              <div className={equifaxStyles.header_value}>
                {ageOfFile && ageOfFile.value
                  ? Math.round(ageOfFile.value)
                  : '-'}{' '}
                month(s)
              </div>
            </div>
            <div className={equifaxStyles.row}>
              <div className={equifaxStyles.header_title}>Permission type:</div>
              <div className={equifaxStyles.header_value}>
                {productHeader.permission_type}
              </div>
            </div>
          </div>

          <div className={equifaxStyles.col_5}>
            <div className={equifaxStyles.row}>
              <div className={equifaxStyles.header_title}>Data level:</div>
              <div className={equifaxStyles.header_value}>
                {productHeader.product_data_level}
              </div>
            </div>
            <div className={equifaxStyles.row}>
              <div className={equifaxStyles.header_title}>Data generated:</div>
              <div className={equifaxStyles.header_value}>
                {moment(productHeader.datetime_generated)
                  .parseZone()
                  .format('DD MMM YYYY - HH:mm')}
              </div>
            </div>
            <div className={equifaxStyles.row}>
              <div className={equifaxStyles.header_title}>Transaction ID:</div>
              <div className={equifaxStyles.header_value}>
                {productHeader.enquiry_id}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },

  _scoreGraph(score) {
    if (!score.score_masterscale) return null;

    let score_masterscale = score.score_masterscale;
    let maxValue = 1200;
    let minValue = -200;
    let valuePosition = minValue;
    let riskOdds = parseFloat(score.risk_odds);
    let probabilityAdverse = 100 / riskOdds;

    if (score_masterscale < minValue) score_masterscale = minValue;
    valuePosition =
      (
        ((score_masterscale - minValue) / (maxValue - minValue)) *
        100
      ).toString() + '%';

    return (
      <div className={styles.overview + ' pt-6 px-6 mb-4'}>
        <div className={styles.overview_rating_full}>
          <div className={styles.liner}>
            <div className={styles.overview_rating_graph}>
              <div
                className={
                  styles.overview_rating_indicator +
                  ' is-size-2 has-text-warning has-text-weight-bold'
                }
                style={{ left: valuePosition }}
              >
                {score_masterscale}
              </div>
              <div className={scoreStyles.overview_rating_graph_bg_reverse} />
              <span className={equifaxStyles.overview_rating_graph_minus_20}>
                -200
              </span>
              <span className={equifaxStyles.overview_rating_graph_0}>0</span>
              <span className={equifaxStyles.overview_rating_graph_20}>
                200
              </span>
              <span className={equifaxStyles.overview_rating_graph_40}>
                400
              </span>
              <span className={equifaxStyles.overview_rating_graph_60}>
                600
              </span>
              <span className={equifaxStyles.overview_rating_graph_80}>
                800
              </span>
              <span className={equifaxStyles.overview_rating_graph_100}>
                1000
              </span>
              <span className={equifaxStyles.overview_rating_graph_120}>
                1200
              </span>

              <span
                className={equifaxStyles.overview_rating_graph_minus_20_label}
              >
                <strong>Increased Risk</strong>
              </span>
              <span className={equifaxStyles.overview_rating_graph_120_label}>
                <strong>Reduced Risk</strong>
              </span>
            </div>
          </div>
        </div>

        <div className={equifaxStyles.row + ' pt-6 ' + equifaxStyles.risk_odds}>
          <p>
            <span className="is-size-5 has-text-weight-semibold">
              {probabilityAdverse.toFixed(2)}%
            </span>{' '}
            chance of adverse (incl. repayment 90+ days overdue) recorded at
            Equifax in the next 12 months
          </p>
        </div>
      </div>
    );
  },

  _renderScore() {
    let { productData, scoreData } = this.getData();

    let factors =
      (scoreData &&
        scoreData.score &&
        scoreData.score.key_contributing_factors.contributing_factor) ||
      [];
    if (!Array.isArray(factors)) {
      factors = [factors];
    }

    if (!productData || !scoreData || !scoreData.score) {
      return <p>Information Unavailable</p>;
    }

    const scoreFactors = [];
    factors.forEach((factor) => {
      scoreFactors.push(
        <li className="pb-4">
          <label className="has-text-weight-semibold mb-2">{factor.name}</label>
          <p>{factor.description}</p>
        </li>
      );
    });

    return (
      <div>
        <div className={equifaxStyles.row}>
          <div className={equifaxStyles.col_4 + ' mb-4'}>
            <label className="has-text-danger has-text-weight-semibold">
              Scores
            </label>
          </div>
        </div>

        <div className={equifaxStyles.score_bg}>
          <br />
          <h3 className="mt-4 pl-6 has-text-weight-semibold">Score</h3>
          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.col_7}>
              {this._scoreGraph(scoreData.score)}
            </div>
            <div className={equifaxStyles.col_5}>
              <h3 className="has-text-weight-semibold mb-4">
                Score key contributing factors
              </h3>
              <ul className="mt-2 pr-6">{scoreFactors}</ul>
            </div>
          </div>

          <div className={equifaxStyles.score_desc}>
            <hr />
            <div className="py-4 px-6">
              <p>
                <div className="has-text-weight-medium">About the score</div>
                <div>
                  Bureau scores use available Equifax bureau data to calculate a
                  risk estimate. The primary purpose of this score is to predict
                  the likelihood of a future adverse event being recorded on the
                  individual's Equifax bureau records in the next 12 months.
                  Please note where Individuals Trading History has been
                  included it is not able to be factored into the score at this
                  time and should be reviewed separately.
                </div>
              </p>

              <p className="mt-4 mb-4">
                <div className="has-text-weight-medium">Disclaimer</div>
                <div>
                  The score is a statistical rank ordering tool only and as such
                  should always be used in conjunction with your organisation's
                  credit policies and procedures and other relevant information
                  you may have about the individual, company or business. The
                  score should not be and is not intended to be the sole basis
                  for making a decision about whether or not to deal with a
                  particular individual, company or business.
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  },

  _renderHeadlines() {
    let { characteristic } = this.getData();

    let adverseOnFile =
        characteristic.find((obj) => obj['@id'] === 'NY7601') || {},
      insolvenciesAndActions =
        characteristic.find((obj) => obj['@id'] === 'NP8510') || {},
      creditEnquiries =
        characteristic.find((obj) => obj['@id'] === 'NY8006') || {},
      defaults = characteristic.find((obj) => obj['@id'] === 'NY7514') || {},
      currentDirectorships =
        characteristic.find((obj) => obj['@id'] === 'NP8500') || {},
      prevDirectorships =
        characteristic.find((obj) => obj['@id'] === 'NP8509') || {},
      disqDirectorships =
        characteristic.find((obj) => obj['@id'] === 'NP8503') || {};

    const adverseFileValueMapping = { N: 'No', Y: 'Yes' };
    const adverseOnFileValue =
      adverseFileValueMapping[adverseOnFile.value] || adverseOnFile.value;
    const businessRelationshipsCount =
      parseInt(currentDirectorships.value) +
      parseInt(prevDirectorships.value) +
      parseInt(disqDirectorships.value);

    return (
      <div>
        <div className={equifaxStyles.table + ' ' + equifaxStyles.bg_space}>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_3}>
              Adverse on file
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_3}>
              {!adverseOnFile || adverseOnFile.value < 0
                ? '-'
                : adverseOnFileValue}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_3}>
              Insolvencies & Actions
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_3}>
              {!insolvenciesAndActions || insolvenciesAndActions.value < 0
                ? '-'
                : insolvenciesAndActions.value}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_3}>
              Credit enquiries
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_3}>
              {!creditEnquiries || creditEnquiries.value < 0
                ? '-'
                : creditEnquiries.value}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_3}>
              Business relationships
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_3}>
              {!businessRelationshipsCount || businessRelationshipsCount < 0
                ? 0
                : businessRelationshipsCount}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_3}>
              Defaults
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_3}>
              {!defaults || defaults.value < 0 ? '-' : defaults.value}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_3}>
              Disq. directorships
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_3}>
              {!disqDirectorships || disqDirectorships.value < 0
                ? 0
                : disqDirectorships.value}
            </div>
          </div>
        </div>
      </div>
    );
  },

  _renderEnquiriesDefaults() {
    let { characteristic } = this.getData();

    let timeSinceLastEnquiry =
        characteristic.find((obj) => obj['@id'] === 'NY8059') || {},
      numEnquiries3months =
        characteristic.find((obj) => obj['@id'] === 'NY8001') || {},
      totalEnquiries3months =
        characteristic.find((obj) => obj['@id'] === 'NY8062') || {},
      numEnquiries5years =
        characteristic.find((obj) => obj['@id'] === 'NY8006') || {},
      totalEnquiries5years =
        characteristic.find((obj) => obj['@id'] === 'NY8063') || {},
      numDefaults = characteristic.find((obj) => obj['@id'] === 'NY7516') || {},
      totalDefaults =
        characteristic.find((obj) => obj['@id'] === 'NY7588') || {};

    if (characteristic.length < 1) return <p>Information Unavailable</p>;

    return (
      <div>
        <p className="is-pulled-right">
          No. of enquiries: {numEnquiries5years.value}
          &emsp; No. of defaults: {numDefaults.value}
        </p>
        <br />
        <div className={equifaxStyles.table + ' mt-2'}>
          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_4}>
              Data
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_8}>
              Commercial
            </div>
          </div>
          <div className={equifaxStyles.row}>
            <div
              className={equifaxStyles.th_underline + ' ' + equifaxStyles.col_4}
            >
              Time since last enquiry
            </div>
            <div
              className={equifaxStyles.th_underline + ' ' + equifaxStyles.col_8}
            >
              {!timeSinceLastEnquiry || timeSinceLastEnquiry.value < 0
                ? '-'
                : `Less than ${timeSinceLastEnquiry.value} month(s)`}
            </div>
          </div>
        </div>

        <div className={equifaxStyles.table}>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}></div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              <strong>Number</strong>
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              <strong>Total</strong>
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              Enquiries in the last 3 months
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {numEnquiries3months.value}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!totalEnquiries3months || totalEnquiries3months.value < 0
                ? '-'
                : `$${formatMoney(parseFloat(totalEnquiries3months.value))}`}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              Enquiries in the last 5 years
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {numEnquiries5years.value}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!totalEnquiries5years || totalEnquiries5years.value < 0
                ? '-'
                : `$${formatMoney(parseFloat(totalEnquiries5years.value))}`}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              Defaults
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {numDefaults.value}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!totalDefaults || totalDefaults.value < 0
                ? '-'
                : `$${formatMoney(parseFloat(totalDefaults.value))}`}
            </div>
          </div>
        </div>
      </div>
    );
  },

  _renderActions() {
    let { characteristic } = this.getData();

    let numInsolvency =
        characteristic.find((obj) => obj['@id'] === 'NP8510') || {},
      numDefaultJudgement =
        characteristic.find((obj) => obj['@id'] === 'NP7504') || {},
      numOtherCourtActions =
        characteristic.find((obj) => obj['@id'] === 'NP7511') || {},
      numInsolvencyValue = parseInt(numInsolvency.value) || 0,
      numDefaultJudgementValue = parseInt(numDefaultJudgement.value) || 0,
      numOtherCourtActionsValue = parseInt(numOtherCourtActions.value) || 0;

    if (characteristic.length < 1) return <p>Information Unavailable</p>;

    return (
      <div>
        <p className="is-pulled-right">
          No. of actions:{' '}
          {numInsolvencyValue +
            numDefaultJudgementValue +
            numOtherCourtActionsValue}
        </p>
        <br />
        <div className={equifaxStyles.table + ' mt-2'}>
          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_4}>
              Data
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_8}>
              No. of actions
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              Personal insolvency
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              {numInsolvency.value}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              Default judgement
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              {numDefaultJudgement.value}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              Other court actions
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              {numOtherCourtActions.value}
            </div>
          </div>
        </div>
      </div>
    );
  },

  _renderBusinessRelationships() {
    let { characteristic } = this.getData();

    let currentDirectorships =
        characteristic.find((obj) => obj['@id'] === 'NP8500') || {},
      prevDirectorships =
        characteristic.find((obj) => obj['@id'] === 'NP8509') || {},
      externalAdminCompanies =
        characteristic.find((obj) => obj['@id'] === 'NP8506') || {},
      businessHeld =
        characteristic.find((obj) => obj['@id'] === 'NP8508') || {},
      disqDirectorships =
        characteristic.find((obj) => obj['@id'] === 'NP8503') || {},
      noRelationships =
        parseInt(currentDirectorships.value) +
        parseInt(prevDirectorships.value) +
        parseInt(externalAdminCompanies.value) +
        parseInt(businessHeld.value);

    return (
      <div>
        <p className="is-pulled-right">
          No. of relationships: {!noRelationships ? 0 : noRelationships}
          &emsp; Disqualified directorships:
          {!disqDirectorships || disqDirectorships.value < 0
            ? '-'
            : disqDirectorships.value}
        </p>
        <br />
        <div className={equifaxStyles.table + ' mt-2'}>
          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_8}>
              Data
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_4}>
              No. of relationships
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              Current directorships
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!currentDirectorships || currentDirectorships.value < 0
                ? '-'
                : currentDirectorships.value}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              Previous directorships in the last 10 years
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!prevDirectorships || prevDirectorships.value < 0
                ? '-'
                : prevDirectorships.value}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              Number of external administration companies for director, or
              disqualified directorships
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!externalAdminCompanies || externalAdminCompanies.value < 0
                ? '-'
                : externalAdminCompanies.value}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              Business names held
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!businessHeld || businessHeld.value < 0
                ? '-'
                : businessHeld.value}
            </div>
          </div>
        </div>
      </div>
    );
  },

  _renderIdentity() {
    let {
      enquiryReport,
      primaryMatch,
      individualName,
      address,
      employer,
    } = this.getData();

    let dateOfBirth = primaryMatch.date_of_birth || {},
      gender = primaryMatch.gender || null,
      fileNo = primaryMatch.bureau_reference || null,
      driversLicence = primaryMatch.drivers_licence || {};

    if (!enquiryReport) return <p>Information Unavailable</p>;

    if (address.constructor !== Array) {
      address = [{ ...address }];
    }

    return (
      <div>
        <p className="is-pulled-right">
          Identities:{' '}
          {!primaryMatch['@id_index'] ? 0 : primaryMatch['@id_index']}
        </p>
        <br />
        <div className={equifaxStyles.table + ' mt-2'}>
          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_2}>
              ID source
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_4}>
              Names
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_2}>
              First reported date
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_2}>
              Last reported date
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_2}>
              Last credit enquiry
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_12}>
              Primary
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              P
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {individualName.first_given_name +
                ' ' +
                individualName.other_given_name +
                ' ' +
                individualName.family_name}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              {!individualName['@first_reported_date']
                ? '-'
                : formatDate(
                    individualName['@first_reported_date'],
                    'DD MMM YYYY'
                  )}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              {!individualName['@last_credit_enquiry_date']
                ? '-'
                : formatDate(
                    individualName['@last_credit_enquiry_date'],
                    'DD MMM YYYY'
                  )}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              {!individualName['@last_reported_date']
                ? '-'
                : formatDate(
                    individualName['@last_reported_date'],
                    'DD MMM YYYY'
                  )}
            </div>
          </div>
        </div>
        <div className={equifaxStyles.table + ' mt-6'}>
          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_2}>
              ID data
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_4}>
              Details
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_2}>
              First reported date
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_2}>
              Last reported date
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_2}>
              ID source
            </div>
          </div>

          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              Date of birth
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!dateOfBirth ? '-' : formatDate(dateOfBirth, 'DD MMM YYYY')}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              {!dateOfBirth['@first_reported_date']
                ? '-'
                : formatDate(
                    dateOfBirth['@first_reported_date'],
                    'DD MMM YYYY'
                  )}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              {!dateOfBirth['@last_reported_date']
                ? '-'
                : formatDate(dateOfBirth['@last_reported_date'], 'DD MMM YYYY')}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              P
            </div>
          </div>

          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              Drivers licence
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!driversLicence.number ? '-' : driversLicence.number}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              {!driversLicence['@first_reported_date']
                ? '-'
                : formatDate(
                    driversLicence['@first_reported_date'],
                    'DD MMM YYYY'
                  )}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              {!driversLicence['@last_reported_date']
                ? '-'
                : formatDate(
                    driversLicence['@last_reported_date'],
                    'DD MMM YYYY'
                  )}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              P
            </div>
          </div>

          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              Gender
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!gender ? '-' : gender}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              {!gender || !gender['@first_reported_date']
                ? '-'
                : formatDate(gender['@first_reported_date'], 'DD MMM YYYY')}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              {!gender || !gender['@last_reported_date']
                ? '-'
                : formatDate(gender['@last_reported_date'], 'DD MMM YYYY')}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              P
            </div>
          </div>

          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              File no.
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!fileNo ? '-' : fileNo}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              {!fileNo || !fileNo['@fileNo']
                ? '-'
                : formatDate(gender['@fileNo'], 'DD MMM YYYY')}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              {!fileNo || !fileNo['@last_reported_date']
                ? '-'
                : formatDate(fileNo['@last_reported_date'], 'DD MMM YYYY')}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              P
            </div>
          </div>
        </div>
        <hr />
        <div className={equifaxStyles.table + ' mt-2'}>
          {address.map((a, index) => {
            return (
              <div className={equifaxStyles.tr}>
                <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
                  {index == 0 ? 'Addresses' : ''}
                </div>
                <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
                  {`${!a.unit_number ? '' : `${a.unit_number}/`}
                     ${a.street_number || ''} ${a.street_name ||
                    ''} ${a.street_type || ''}
                     ${a.suburb || ''} ${a.state || ''} ${a.postcode || ''}`}
                </div>
                <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
                  {!a['@first_reported_date']
                    ? '-'
                    : formatDate(a['@first_reported_date'], 'DD MMM YYYY')}
                </div>
                <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
                  {!a['@last_reported_date']
                    ? '-'
                    : formatDate(a['@last_reported_date'], 'DD MMM YYYY')}
                </div>
                <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
                  P
                </div>
              </div>
            );
          })}
        </div>
        <hr />
        <div className={equifaxStyles.table + ' mt-2'}>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              Employer
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!employer.name ? '-' : employer.name}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              {!employer['@first_reported_date']
                ? '-'
                : formatDate(employer['@first_reported_date'], 'DD MMM YYYY')}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              {!employer['@last_reported_date']
                ? '-'
                : formatDate(employer['@last_reported_date'], 'DD MMM YYYY')}
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
              P
            </div>
          </div>
        </div>
      </div>
    );
  },

  _renderPossibleMatch() {
    return (
      <div>
        <div className={equifaxStyles.row}>
          <div className={equifaxStyles.col_4}>
            <p>Not present on file</p>
          </div>
          <div className={equifaxStyles.col_8}>
            <p className="is-pulled-right">Possible match: 0</p>
          </div>
        </div>
      </div>
    );
  },

  _renderCommercialEnquiries() {
    let { creditEnquiries, creditEnquiry, characteristic } = this.getData();
    let totalValue = 0,
      totalNumber = characteristic.find((obj) => obj['@id'] === 'NA8922') || {};

    if (creditEnquiries && creditEnquiry) {
      if (creditEnquiry.constructor !== Array) {
        creditEnquiry = [{ ...creditEnquiry }];
      }
      totalValue = creditEnquiry.reduce((prev, current) => {
        return prev + +current.enquiry_amount;
      }, 0);
    }

    return (
      <div>
        <div className={equifaxStyles.row}>
          <div className={equifaxStyles.col_4}>
            <label className="has-text-danger has-text-weight-semibold">
              Commercial enquiries
            </label>
          </div>
          <div className={equifaxStyles.col_8}>
            <p className="is-pulled-right">
              Total number: {creditEnquiry.length}
              &emsp; Total value: {`$${formatMoney(parseFloat(totalValue))}`}
            </p>
          </div>
        </div>

        <div className={equifaxStyles.table + ' mt-4'}>
          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_1}>
              Date
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_3}>
              Enquirer
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_1}>
              ID source
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_2}>
              Account type
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_1}>
              Amount
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_1}>
              Role
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_1}>
              Co-borrower
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_2}>
              Reference no.
            </div>
          </div>
          {creditEnquiry.map((ce) => {
            return (
              <div className={equifaxStyles.tr}>
                <div className={equifaxStyles.td + ' ' + equifaxStyles.col_1}>
                  {!ce['@enquiry_date']
                    ? '-'
                    : formatDate(ce['@enquiry_date'], 'DD MMM YYYY')}
                </div>
                <div className={equifaxStyles.td + ' ' + equifaxStyles.col_3}>
                  {ce['credit_enquirer'] && ce['credit_enquirer'].name}
                </div>
                <div className={equifaxStyles.td + ' ' + equifaxStyles.col_1}>
                  P
                </div>
                <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
                  {ce['account_type']}
                </div>
                <div
                  className={
                    equifaxStyles.td + ' has-text-right ' + equifaxStyles.col_1
                  }
                >
                  {`$${formatMoney(parseFloat(ce['enquiry_amount']))}`}
                </div>
                <div className={equifaxStyles.td + ' ' + equifaxStyles.col_1}>
                  {ce['relationship'] == "Principal's Account (sole)"
                    ? 'Principal (sole)'
                    : ce['relationship']}
                </div>
                <div
                  className={equifaxStyles.td + ' ' + equifaxStyles.col_1}
                ></div>
                <div className={equifaxStyles.td + ' ' + equifaxStyles.col_2}>
                  {ce['enquiry_client_reference']}
                </div>
              </div>
            );
          })}
        </div>

        <br />
        <div className={equifaxStyles.row + ' mt-4'}>
          <div className={equifaxStyles.col_4}>
            <label className="has-text-danger has-text-weight-semibold">
              Commercial File notes
            </label>
            <p>Not present on file</p>
          </div>
          <div className={equifaxStyles.col_4}>
            <p>
              Total number: &nbsp;
              {!totalNumber || totalNumber.value < 0 ? 0 : totalNumber.value}
            </p>
          </div>
        </div>
      </div>
    );
  },

  _renderInsolvenciesCourtActions() {
    let { characteristic } = this.getData();

    let personalInsolvencies =
        characteristic.find((obj) => obj['@id'] === 'NP8510') || {},
      defaultJudgements =
        characteristic.find((obj) => obj['@id'] === 'NP7504') || {},
      numOtherCourtActions =
        characteristic.find((obj) => obj['@id'] === 'NP7511') || {};

    return (
      <div>
        <div className={equifaxStyles.row}>
          <div className={equifaxStyles.col_4}>Personal Insolvencies</div>
          <div className={equifaxStyles.col_4}>
            Total number: &nbsp;
            {!personalInsolvencies || personalInsolvencies.value < 0
              ? '-'
              : personalInsolvencies.value}
          </div>
        </div>
        <div className={equifaxStyles.row}>
          <div className={equifaxStyles.col_4}>Default Judgement</div>
          <div className={equifaxStyles.col_4}>
            Total number: &nbsp;
            {!defaultJudgements || defaultJudgements.value < 0
              ? '-'
              : defaultJudgements.value}
          </div>
        </div>
        <div className={equifaxStyles.row}>
          <div className={equifaxStyles.col_4}>Other court actions</div>
          <div className={equifaxStyles.col_4}>
            Total number: &nbsp;
            {!numOtherCourtActions || numOtherCourtActions.value < 0
              ? '-'
              : numOtherCourtActions.value}
          </div>
        </div>
      </div>
    );
  },

  _renderCommercialAccounts() {
    return (
      <div>
        <div className={equifaxStyles.row + ' mt-4'}>
          <div className={equifaxStyles.col_4}>
            <label className="has-text-danger has-text-weight-semibold">
              Commercial Accounts
            </label>
            <p>Not present on file</p>
          </div>
        </div>
      </div>
    );
  },

  _renderCurrDDetails(directorship, presentDirectorship) {
    if (directorship.length < 1)
      return <p className="mt-2">Information Unavailable</p>;

    return (
      <div>
        <h6 className="my-4 has-text-weight-semibold">
          {!presentDirectorship['organisation']
            ? '-'
            : presentDirectorship['organisation'].name}
        </h6>
        <div className={equifaxStyles.table}>
          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_8}>
              Data
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_4}>
              Detail
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              Company Status
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!presentDirectorship['organisation']
                ? '-'
                : presentDirectorship['organisation'].status}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              Australian Company Number
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!presentDirectorship['organisation']
                ? '-'
                : presentDirectorship['organisation'].organisation_number}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              Australian Business Number
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!presentDirectorship['organisation']
                ? '-'
                : presentDirectorship['organisation'].abn}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              Appointment date
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!presentDirectorship['date_appointed']
                ? '-'
                : formatDate(
                    presentDirectorship['date_appointed'],
                    'DD MMM YYYY'
                  )}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              File Number
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!presentDirectorship['organisation']
                ? '-'
                : presentDirectorship['organisation'].bureau_reference}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              ASIC extract date &amp; time (1)
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!presentDirectorship['@last_extract_date']
                ? '-'
                : `${formatDate(
                    presentDirectorship['@last_extract_date'],
                    'DD MMM YYYY'
                  )}`}
              {!presentDirectorship['@last_extract_time']
                ? '-'
                : `- ${this.formatTime(
                    presentDirectorship['@last_extract_time']
                  )}`}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              Last ASIC update date &amp; time (2)
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!presentDirectorship['@last_update_date']
                ? '-'
                : `${formatDate(
                    presentDirectorship['@last_update_date'],
                    'DD MMM YYYY'
                  )}`}
              {!presentDirectorship['@last_update_time']
                ? '-'
                : `- ${this.formatTime(
                    presentDirectorship['@last_update_time']
                  )}`}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              ID Source
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              P
            </div>
          </div>
        </div>
        <div className="mt-2 is-size-6">
          <p>
            1. Details were sourced from ASIC's Business Names Record on the
            give date & time.
          </p>
          <p>
            2. Updates to the Business Name, Business Registration Number &
            Business Registration State sources from ASIC on the given date &
            time.
          </p>
        </div>
      </div>
    );
  },

  _renderPrevDDetails(directorship, prevDirectorship) {
    if (directorship.length < 1)
      return <p className="mt-2">Information Unavailable</p>;
    return (
      <div>
        <h6 className="my-4 has-text-weight-semibold">
          {!prevDirectorship['organisation']
            ? '-'
            : prevDirectorship['organisation'].name}
        </h6>
        <div className={equifaxStyles.table}>
          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_8}>
              Data
            </div>
            <div className={equifaxStyles.th + ' ' + equifaxStyles.col_4}>
              Detail
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              Australian Company Number
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!prevDirectorship['organisation']
                ? '-'
                : prevDirectorship['organisation'].organisation_number}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              Australian Business Number
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!prevDirectorship['organisation']
                ? '-'
                : prevDirectorship['organisation'].abn}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              Appointment date
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!prevDirectorship['date_appointed']
                ? '-'
                : formatDate(prevDirectorship['date_appointed'], 'DD MMM YYYY')}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              Cease date
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}></div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              Last Known As Director
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!prevDirectorship['date_last_known_as_director']
                ? '-'
                : formatDate(
                    prevDirectorship['date_last_known_as_director'],
                    'DD MMM YYYY'
                  )}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              File Number
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!prevDirectorship['organisation']
                ? '-'
                : prevDirectorship['organisation'].bureau_reference}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              ASIC extract date &amp; time (1)
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!prevDirectorship['@last_extract_date']
                ? '-'
                : `${formatDate(
                    prevDirectorship['@last_extract_date'],
                    'DD MMM YYYY'
                  )}`}
              {!prevDirectorship['@last_extract_time']
                ? '-'
                : `- ${this.formatTime(
                    prevDirectorship['@last_extract_time']
                  )}`}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              Last ASIC update date &amp; time (2)
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              {!prevDirectorship['@last_update_date']
                ? '-'
                : `${formatDate(
                    prevDirectorship['@last_update_date'],
                    'DD MMM YYYY'
                  )}`}
              {!prevDirectorship['@last_update_time']
                ? '-'
                : `- ${this.formatTime(prevDirectorship['@last_update_time'])}`}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_8}>
              ID Source
            </div>
            <div className={equifaxStyles.td + ' ' + equifaxStyles.col_4}>
              P
            </div>
          </div>
        </div>
        <div className="mt-2 is-size-6">
          <p>
            1. Details were sourced from ASIC's Business Names Record on the
            give date & time.
          </p>
          <p>
            2. Updates to the Organisation Name, Company Status, ACN & ABN
            sources from ASIC on the given date & time.
          </p>
        </div>
      </div>
    );
  },

  _renderDirectorships() {
    let { characteristic, directorship } = this.getData();

    let businessNames =
        characteristic.find((obj) => obj['@id'] === 'NP8508') || {},
      currentDirectorships =
        characteristic.find((obj) => obj['@id'] === 'NP8500') || {},
      previousDirectorships =
        characteristic.find((obj) => obj['@id'] === 'NP8509') || {},
      disqDirectorships =
        characteristic.find((obj) => obj['@id'] === 'NP8503') || {};

    if (!Array.isArray(directorship)) {
      directorship = [directorship];
    }
    let latestDirectorships = directorship.sort(function(a, b) {
      return b['@last_extract_date'] - a['@last_extract_date'];
    });
    const presentDirectorship = latestDirectorships[0],
      prevDirectorship = latestDirectorships[1];

    const currentDirectorshipDetails = presentDirectorship
      ? this._renderCurrDDetails(directorship, presentDirectorship)
      : null;
    const prevDirectorshipDetails = prevDirectorship
      ? this._renderPrevDDetails(directorship, prevDirectorship)
      : null;

    return (
      <div>
        <div className={equifaxStyles.row}>
          <div className={equifaxStyles.col_4}>
            <label className="has-text-danger has-text-weight-semibold">
              Business names
            </label>
            <p>Not present on file</p>
          </div>
          <div className={equifaxStyles.col_4}>
            Total number: &nbsp;
            {!businessNames || businessNames.value < 0
              ? '-'
              : businessNames.value}
          </div>
        </div>
        <br />
        <div className={equifaxStyles.row + ' mt-4'}>
          <div className={equifaxStyles.col_4}>
            <label className="has-text-danger has-text-weight-semibold">
              Current directorships
            </label>
          </div>
          <div className={equifaxStyles.col_4}>
            Total number: &nbsp;
            {!currentDirectorships || currentDirectorships.value < 0
              ? '-'
              : currentDirectorships.value}
          </div>
        </div>
        {currentDirectorshipDetails}
        <br />
        <div className={equifaxStyles.row + ' mt-4'}>
          <div className={equifaxStyles.col_4}>
            <label className="has-text-danger has-text-weight-semibold">
              Previous directorships
            </label>
          </div>
          <div className={equifaxStyles.col_4}>
            Total number: &nbsp;
            {!previousDirectorships || previousDirectorships.value < 0
              ? '-'
              : previousDirectorships.value}
          </div>
        </div>
        {prevDirectorshipDetails}
        <br />
        <div className={equifaxStyles.row + ' mt-4 mb-4'}>
          <div className={equifaxStyles.col_4}>
            <label className="has-text-danger has-text-weight-semibold">
              Disqualified directorships
            </label>
            <p>Not present on file</p>
          </div>
          <div className={equifaxStyles.col_4}>
            Total number: &nbsp;
            {!disqDirectorships || disqDirectorships.value < 0
              ? '-'
              : disqDirectorships.value}
          </div>
        </div>
        <br />
      </div>
    );
  },

  _pdf() {
    const { pdf } = this.props;
    if (!pdf) return null;

    return (
      <Clickable target={() => this._downloadPdf(pdf)}>
        <Button css_style="button_white_outer" text="download pdf" />
      </Clickable>
    );
  },

  _downloadPdf: function(url) {
    var win = window.open(url, 'equfiax_personal_pdf_window');
  },

  _faultDescription() {
    const { data } = this.props;

    let fault = data.fault || {},
      faultString = fault.faultstring,
      detail = fault.detail || {},
      error = detail.error || {};

    let errorMessage = error.description;

    if (
      errorMessage === null ||
      errorMessage === undefined ||
      errorMessage === 'null'
    ) {
      errorMessage = faultString;
    }

    return <p>{errorMessage}</p>;
  },

  _errorDescription() {
    const { data } = this.props;

    let response = data.response || {},
      errors = response.errors || {},
      error = errors.error || {};

    let errorMessage = error.fault_string;

    return <p>{errorMessage}</p>;
  },

  _errorPage(type) {
    let error;
    if (type == 'fault') {
      error = this._faultDescription();
    } else if (type == 'errors') {
      error = this._errorDescription();
    }

    return (
      <div>
        <ReviewContainer
          subHeading={'Equifax Commercial Apply'}
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="large"
        >
          {error}
          <p>
            Contact <a href="mailto:support@1centre.com">support@1centre.com</a>{' '}
            or live chat with us.
          </p>
        </ReviewContainer>
      </div>
    );
  },

  render() {
    const { data } = this.props;
    if (!data || !data.response) {
      if (data.fault) {
        return this._errorPage('fault');
      } else {
        return null;
      }
    } else if (data.response.errors) {
      return this._errorPage('errors');
    }

    return (
      <div className="pt-6">
        <img
          className={styles.veda_logo}
          src={logo}
          alt="Equifax"
          width="277.9"
          height="79.1"
        />
        <h2 className={styles.heading_large}>Equifax Commercial Apply</h2>

        <ReviewContainer
          content_class="content_wide"
          css_class="block_noborder"
        >
          {this._renderHeader()}
        </ReviewContainer>
        <ReviewContainer
          subHeading="Summary"
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._renderScore()}
        </ReviewContainer>
        <ReviewContainer
          subHeading="Headlines"
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._renderHeadlines()}
        </ReviewContainer>
        <ReviewContainer
          subHeading="Credit enquiries &amp; defaults"
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._renderEnquiriesDefaults()}
        </ReviewContainer>
        <ReviewContainer
          subHeading="Personal insolvencies &amp; court actions"
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._renderActions()}
        </ReviewContainer>
        <ReviewContainer
          subHeading="Business relationships"
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._renderBusinessRelationships()}
        </ReviewContainer>
        <ReviewContainer
          subHeading="Identity details"
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._renderIdentity()}
        </ReviewContainer>
        <ReviewContainer
          subHeading="Possible match"
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._renderPossibleMatch()}
        </ReviewContainer>
        <ReviewContainer
          subHeading="Credit enquiries"
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._renderCommercialEnquiries()}
        </ReviewContainer>
        <ReviewContainer
          subHeading="Insolvencies &amp; court actions"
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._renderInsolvenciesCourtActions()}
        </ReviewContainer>
        <ReviewContainer
          subHeading="Defaults &amp; CCPs"
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._renderCommercialAccounts()}
        </ReviewContainer>
        <ReviewContainer
          subHeading="Business relationships"
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._renderDirectorships()}
        </ReviewContainer>
        <ReviewContainer
          content_class="content_wide"
          css_class="block_noborder"
        >
          {this._pdf()}
        </ReviewContainer>
      </div>
    );
  },
});

export default EquifaxPersonalComponent;
