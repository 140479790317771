import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import commonStyles from 'modules/new-applications/css/common.css';
import styles from 'modules/new-applications/css/LimitAndApprovals.css';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import GridContent from 'modules/shared/components/containers/GridContent';
import BorderedTextArea from 'modules/shared/components/inputs/BorderedTextArea';
import Button from 'modules/shared/components/inputs/Button';
import React, { ReactElement, useState } from 'react';
import { sendSurvey } from 'utils/askNicely';
import { setChurnZeroAttributesWithoutRedux } from 'utils/churnZero';

type VALID_REVIEW_DECISIONS =
  | 'accepted'
  | 'declined'
  | 'deescalated'
  | 'escalated'
  | 'reviewed';

interface IActionDetailsContent {
  askNicelyAction: string;
  mixpanelEvent: string;
  mixpanelOutcome: string;
  reviewActionLabel: ({ application, review }) => string;
  reviewButtonText: string;
  reviewDecision: VALID_REVIEW_DECISIONS;
  reviewNotesLabel: string;
  reviewNotesPlaceholder: string;
}

interface IActionDetails {
  decline: IActionDetailsContent;
  deescalate: IActionDetailsContent;
  escalate: IActionDetailsContent;
  review: IActionDetailsContent;
}

const ACTION_DETAILS: IActionDetails = {
  decline: {
    askNicelyAction: 'Review - Declined',
    mixpanelEvent: 'Review Declined',
    mixpanelOutcome: 'Declined',
    reviewActionLabel: ({ application }) =>
      `Team reason for decline of ${application.formattedBusinessName}'s account`,
    reviewButtonText: 'Send decline',
    reviewDecision: 'declined',
    reviewNotesLabel: 'Decline notes',
    reviewNotesPlaceholder: 'Notes shared with your team (optional)',
  },
  deescalate: {
    askNicelyAction: 'Review - De-escalated',
    mixpanelEvent: 'Review De-escalated',
    mixpanelOutcome: 'De-escalated',
    reviewActionLabel: ({ application, review }) => {
      const lowerLevel = review.level - 1;
      const escalatedReview = application.getEscalatedReviewOnLevel(lowerLevel);

      if (escalatedReview) {
        return `You are de-escalating this application back to ${escalatedReview.reviewerName}.`;
      }

      return `You are de-escalating this application to a L${lowerLevel} approver.`;
    },
    reviewButtonText: 'De-escalate',
    reviewDecision: 'deescalated',
    reviewNotesLabel: 'Reason for de-escalation here',
    reviewNotesPlaceholder: 'Reason for de-escalation here',
  },
  escalate: {
    askNicelyAction: 'Review - Escalated',
    mixpanelEvent: 'Review Escalated',
    mixpanelOutcome: 'Escalated',
    reviewActionLabel: ({ review }) =>
      `You are escalating this application to a L${review.level + 1} approver.`,
    reviewButtonText: 'Escalate',
    reviewDecision: 'escalated',
    reviewNotesLabel: 'Reason for escalation here',
    reviewNotesPlaceholder: 'Reason for escalation here',
  },
  review: {
    askNicelyAction: 'Application Reviewed',
    mixpanelEvent: 'Application reviewed',
    mixpanelOutcome: 'Reviewed',
    reviewActionLabel: () =>
      'When ready, submit this application as reviewed with applicable notes.',
    reviewButtonText: 'Review',
    reviewDecision: 'reviewed',
    reviewNotesLabel: 'Reviewer notes',
    reviewNotesPlaceholder: 'Reviewer notes',
  },
};

interface IEscalateFormProps {
  action: 'decline' | 'escalate';
  application: { [key: string]: any };
  currentUser: { [key: string]: any };
  index: number;
  onCancel: () => void;
  onFetchApplicationRecord: (callback: () => void) => void;
  review: { [key: string]: any };
}

const onSubmitReview = ({
  application,
  currentUser,
  decision,
  onSuccessCallback,
  review,
  reviewNotes,
}: {
  application: { [key: string]: any };
  currentUser: { [key: string]: any };
  decision: VALID_REVIEW_DECISIONS;
  onSuccessCallback: () => void;
  review: { [key: string]: any };
  reviewNotes: string | null;
}): void => {
  if (['declined', 'escalated', 'reviewed'].includes(decision)) {
    const attributes = {
      decision,
      notes: reviewNotes,
      tradeAccountLimit: 0,
    };

    review.save({
      application,
      attributes,
      currentUser,
      onSuccessCallback,
    });

    return;
  }

  review.deescalate({
    currentUser,
    notes: reviewNotes,
    onSuccessCallback,
  });
};

const GenericApproverForm = ({
  action,
  application,
  currentUser,
  onCancel,
  onFetchApplicationRecord,
  review,
}: IEscalateFormProps): ReactElement => {
  const { isLoading, setIsLoading } = useIsLoadingState();
  const [reviewNotes, setReviewNotes] = useState(null);
  const actionDetails = ACTION_DETAILS[action];

  const onChangeReviewNotes = (event) => {
    setReviewNotes(get(event, 'target.value'));
  };

  const onSuccessCallback = () => {
    sendSurvey({
      access_token: currentUser.accessToken,
      application_id: null,
      consumer_id: null,
      consumer_name: null,
      email: currentUser.email,
      event_flow: actionDetails.askNicelyAction,
      name: currentUser.fullName,
      supplier: true,
      supplier_id: get(currentUser, 'currentEntity.id'),
      supplier_name: get(currentUser, 'currentEntity.companyName'),
      websitebutton: application.websitebuttonApplication,
    });

    mixpanel.track(actionDetails.mixpanelEvent, {
      Application: application.id,
      'Entity ID': get(currentUser, 'currentEntity.id'),
      Outcome: actionDetails.mixpanelOutcome,
      distinct_id: currentUser.id,
    });

    setChurnZeroAttributesWithoutRedux(currentUser);
    onFetchApplicationRecord(() => {
      setIsLoading(false);
      onCancel();
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);

    onSubmitReview({
      application,
      currentUser,
      decision: actionDetails.reviewDecision,
      onSuccessCallback,
      review,
      reviewNotes,
    });
  };

  return (
    <GridContent gridColumnTemplate="two_thirds">
      <form onSubmit={onSubmit}>
        <div className={styles.current_approver_label}>
          {actionDetails.reviewActionLabel({ application, review })}
        </div>
        <BorderedTextArea
          label={actionDetails.reviewNotesLabel}
          placeholder={actionDetails.reviewNotesPlaceholder}
          value={reviewNotes}
          onChange={onChangeReviewNotes}
        />
        <div className={commonStyles.buttons}>
          <Button
            loading={isLoading}
            type="submit"
            text={actionDetails.reviewButtonText}
            style={{ marginRight: 60 }}
          />
          <Button grey text="Cancel" handleClick={onCancel} />
        </div>
      </form>
    </GridContent>
  );
};

export default GenericApproverForm;
