/* Import libraries */
import { setPaperlessAnswer } from 'modules/consumer-onboarding/actions/paperless';
import Button from 'modules/shared/components/inputs/Button';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import TextInput from 'modules/shared/components/inputs/TextInput';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { isValidEmail } from 'utils/validators';
import styles from './css/Paperless.css';
import NewZealandPaperlessDetails from 'modules/consumer-onboarding/components/paperless/NewZealandPaperlessDetails';
import AustraliaPaperlessDetails from 'modules/consumer-onboarding/components/paperless/AustraliaPaperlessDetails';
import validateAccountNumber from 'utils/direct-debit/validateAccountNumber';
import validateBankNumber from 'utils/direct-debit/validateBankNumber';
import bankNumberLabel from 'utils/direct-debit/bankNumberLabel';

class PaperlessDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      triggerValidation: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      handleComplete,
      page_validation_start: pageValidationStart,
      region,
      setPageValidationStartFinish,
    } = props;
    const noValidate = PaperlessDetails.noNeedForValidate(props);
    if (noValidate) {
      if (handleComplete) handleComplete(true);
      return { error: {}, triggerValidation: false };
    }
    // In JS false || undefined will returns undefined, but undefined || false will return false
    // the following code can make sure pageValidationStart has a default value
    const triggerValidation =
      state.triggerValidation || (pageValidationStart || false);
    const validationResult = PaperlessDetails.validate(props.answers, region);
    if (handleComplete) handleComplete(validationResult.isValid, 'paperless');
    if (setPageValidationStartFinish) setPageValidationStartFinish();
    return {
      error: triggerValidation && validationResult.error,
      triggerValidation,
    };
  }

  static validate(values, region) {
    const validationInfo = { error: {}, isValid: true };
    const fieldList = [
      'account',
      'accountNumber',
      'bankNumber',
      'applicantAuthorised',
      'multiSignature',
    ];
    if (values.multiSignature) fieldList.push('jointSignatories');
    fieldList.forEach((componentName) => {
      const ComponentValidationResult = PaperlessDetails.componentValidator({
        componentName,
        region,
        values,
      });
      validationInfo.isValid =
        validationInfo.isValid && ComponentValidationResult.isValid;
      if (!ComponentValidationResult.isValid) {
        validationInfo.error = {
          ...validationInfo.error,
          ...ComponentValidationResult.error,
        };
      }
    });
    return validationInfo;
  }

  static componentValidator({ componentName, region, values }) {
    const value = values[componentName];
    let isValid = true;
    let error = {};
    switch (componentName) {
      case 'account':
      case 'first_name':
      case 'last_name':
        isValid = Boolean(value);
        error = PaperlessDetails.errorMessage({ componentName, region, value });
        break;
      case 'accountNumber':
        isValid = validateAccountNumber(region, value);
        error = PaperlessDetails.errorMessage({ componentName, region, value });
        break;
      case 'bankNumber':
        isValid = validateBankNumber(region, value);
        error = PaperlessDetails.errorMessage({ componentName, region, value });
        break;
      case 'email':
        isValid = isValidEmail(value);
        error = PaperlessDetails.errorMessage({ componentName, region, value });
        break;
      case 'jointSignatories': {
        const signatoriesValidationResult = PaperlessDetails.jointSignatoriesValidator(
          value,
          region
        );
        isValid = signatoriesValidationResult.isValid;
        error = { jointSignatories: signatoriesValidationResult.error };
        break;
      }
      case 'applicantAuthorised':
        isValid = value || values.multiSignature;
        error = PaperlessDetails.errorMessage({ componentName, region, value });
        break;
      case 'multiSignature':
        isValid = value || values.applicantAuthorised;
        error = PaperlessDetails.errorMessage({ componentName, region, value });
    }
    return { error, isValid };
  }

  static errorMessage({ componentName, region, value }) {
    switch (componentName) {
      case 'account':
      case 'first_name':
      case 'last_name':
        return {
          [componentName]: `Please input ${componentName.replace('_', ' ')}`,
        };
      case 'accountNumber':
        if (value) {
          return { accountNumber: 'Please input a valid account number' };
        }
        return { accountNumber: 'Please input account number' };
      case 'bankNumber':
        return {
          bankNumber: `Please enter a valid ${
            bankNumberLabel[region.toUpperCase()]
          }`,
        };
      case 'email':
        if (value) return { email: 'Please input a valid email' };
        return { email: 'Please input email' };
      case 'applicantAuthorised':
      case 'multiSignature':
        return {
          signature: 'Does this account require other signatories?',
        };
    }
  }

  static jointSignatoriesValidator(signatories = [], region) {
    const validationInfo = { error: [], isValid: true };
    signatories.forEach((signatory) => {
      let signatoryError = {};
      Object.keys(signatory).forEach((component) => {
        const ComponentValidationResult = PaperlessDetails.componentValidator({
          componentName: component,
          region,
          values: signatory,
        });
        validationInfo.isValid =
          validationInfo.isValid && ComponentValidationResult.isValid;
        if (!ComponentValidationResult.isValid) {
          signatoryError = {
            ...ComponentValidationResult.error,
            ...signatoryError,
          };
        }
      });
      validationInfo.error.push(signatoryError);
    });
    return validationInfo;
  }

  static noNeedForValidate(props) {
    return (
      props.optional &&
      !props.answers.account &&
      !props.answers.accountNumber &&
      !props.answers.applicantAuthorised &&
      !props.answers.multiSignature
    );
  }

  updateAnswer = (answer) => {
    const { dispatch } = this.props;
    dispatch(setPaperlessAnswer(answer));
  };

  setSignatoryValue(inputValue, inputName, index) {
    const { answers } = this.props;
    const jointSignatories = answers.jointSignatories;
    if (inputName === 'email') {
      inputValue = inputValue.toLowerCase();
    }
    jointSignatories[index][inputName] = inputValue;
    this.updateAnswer({ jointSignatories });
  }

  addSignatory() {
    const { answers } = this.props;
    const jointSignatories = answers.jointSignatories;
    jointSignatories.push({ email: null, first_name: null, last_name: null });
    this.updateAnswer({ jointSignatories });
  }

  deleteSignatory(index) {
    const { answers } = this.props;
    const jointSignatories = answers.jointSignatories;
    jointSignatories.splice(index, 1);
    if (jointSignatories.length <= 0) {
      jointSignatories.push({ email: null, first_name: null, last_name: null });
    }
    this.updateAnswer({ jointSignatories });
  }

  renderInputs() {
    const { answers, region } = this.props;
    const { error } = this.state;

    let detailsByRegion = null;

    switch (region) {
      case 'nz':
        detailsByRegion = (
          <NewZealandPaperlessDetails
            answers={answers}
            error={error}
            onSetState={(newState) => this.setState(newState)}
            onSetValue={(inputValue, inputName) =>
              this.updateAnswer({ [inputName]: inputValue })
            }
          />
        );
        break;
      case 'au':
        detailsByRegion = (
          <AustraliaPaperlessDetails
            answers={answers}
            error={error}
            onSetState={(newState) => this.setState(newState)}
            onSetValue={(inputValue, inputName) =>
              this.updateAnswer({ [inputName]: inputValue })
            }
          />
        );
        break;
    }

    return detailsByRegion;
  }

  renderCheckBoxes() {
    const { answers } = this.props;
    const components = [];
    ['applicantAuthorised', 'multiSignature'].forEach((componentName) => {
      components.push(
        <div className={styles.full_col} key={componentName}>
          <div
            onClick={() => {
              this.updateAnswer({
                [componentName]: !answers[componentName],
              });
            }}
          >
            <SquareCheckbox
              id={componentName}
              label={inputs[componentName].label}
              checked={answers[componentName]}
            />
          </div>
        </div>
      );
    });
    return components;
  }

  renderSignatureError() {
    const { error } = this.state;
    if (!error.signature) return;
    return (
      <div className={styles.full_col}>
        <span className={styles.error_text}>{error.signature}</span>
      </div>
    );
  }

  renderJointSignatories() {
    const { answers } = this.props;
    const { error } = this.state;
    const jointSignatories = answers.jointSignatories;
    const jointSignatoriesErrors = error.jointSignatories;
    if (!answers.multiSignature) {
      return;
    }
    const components = [];
    const numberOfComponents = jointSignatories.length;
    jointSignatories.forEach((signatory, index) => {
      components.push(
        <div key={`signatory-${index + 1}`}>
          <div className={`${styles.full_col} ${styles.signatory_title}`}>
            <span>Signatory {index + 1}</span>
          </div>
          <div className={styles.full_col}>
            {['first_name', 'middle_name', 'last_name', 'email'].map(
              (field) => (
                <div key={`${field}-${index}`} className={styles.quarter_col}>
                  <TextInput
                    key={`${field}-${index}`}
                    label={inputs.jointSignatories[field].label}
                    required={inputs.jointSignatories[field].require}
                    onChange={(event) => {
                      this.setSignatoryValue(event.target.value, field, index);
                    }}
                    value={signatory[field]}
                    error={
                      jointSignatoriesErrors &&
                      jointSignatoriesErrors[index] &&
                      jointSignatoriesErrors[index][field]
                    }
                  />
                </div>
              )
            )}
            {numberOfComponents > 1 && (
              <Button
                css_style={'button_small_font'}
                style={{ margin: '10px', zIndex: 90 }}
                text={'Delete'}
                onClick={() => this.deleteSignatory(index)}
              />
            )}
            {index + 1 === numberOfComponents && (
              <Button
                css_style={'button_small_font'}
                style={{ margin: '10px', zIndex: 90 }}
                text={'Add'}
                onClick={() => this.addSignatory()}
              />
            )}
          </div>
        </div>
      );
    });
    return components;
  }

  renderTerms() {
    const { terms, reviewMode } = this.props;
    if (reviewMode) return;
    return (
      <div className={styles.full_col}>
        <span className={styles.terms}>{terms}</span>
      </div>
    );
  }

  render() {
    const { optional } = this.props;
    return (
      <section>
        <div className={styles.section}>
          <div className={styles.row}>
            <div className={styles.full_col}>
              <PanelTitle
                text={
                  optional ? `${defaults.title} (optional)` : defaults.title
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.section} style={{ paddingBottom: '20px' }}>
          <div className={styles.row}>{this.renderInputs()}</div>
        </div>
        <div className={styles.section}>
          <div className={styles.row}>{this.renderTerms()}</div>
        </div>
        <div className={styles.section}>
          <div className={styles.row}>
            {this.renderCheckBoxes()}
            {this.renderSignatureError()}
            <div className={styles.full_col} style={{ margin: '10px' }}></div>
            {this.renderJointSignatories()}
          </div>
        </div>
      </section>
    );
  }
}

const defaults = {
  moduleName: 'paperless',
  title: 'Direct debit',
};

const inputs = {
  account: {
    label: 'Account name to be debited',
    require: true,
  },
  accountNumber: {
    label: 'Bank account number',
    require: true,
  },
  applicantAuthorised: {
    label: 'I am authorised to sign this direct debit',
  },
  jointSignatories: {
    email: {
      label: 'Email',
      require: true,
    },
    first_name: {
      label: 'First name',
      require: true,
    },
    last_name: {
      label: 'Last name',
      require: true,
    },
    middle_name: {
      label: 'Middle name',
      require: false,
    },
  },
  multiSignature: {
    label: 'Does this account require more than one signature?',
  },
};

export default connect((state, ownProps) => {
  const previewAddon = ownProps.previewAddon;

  let config;
  let region;

  if (previewAddon) {
    config = previewAddon.config;
    region = ownProps.region;
  } else {
    const addon = state.cob_section.addonRules.find(
      (rule) => rule.attributes.addon_module_name === defaults.moduleName
    );
    config = addon.attributes.addon_version.data.attributes.config;
    region = state.cob_business.entity_region;
  }
  return {
    answers: state.cob_paperless.answers,
    optional: !config.mandatory,
    terms: config.terms,
    region: region.toLowerCase(),
  };
})(PaperlessDetails);
