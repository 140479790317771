import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fileSize from 'filesize';
import { clickEvent, setIdentityValue } from 'modules/identity/actions';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';

import Actions from './Actions/Actions';
import Camera from './Camera/Camera';
import { InnerWrapper, Status, Wrapper } from './styles';
import UploadedFile from './UploadedFile/UploadedFile';
import {
  getBase64,
  getPdfImage,
  getPresignedUrl,
  mb2Bytes,
  storeFileToS3,
  toPreviewObj,
  updateFilename,
} from './utils';

/* eslint-disable sort-keys-fix/sort-keys-fix */
const FILE_TYPES = {
  jpeg: {
    format: 'image/jpeg',
    name: 'JPEG',
  },
  jpg: {
    format: 'image/jpg',
    name: 'JPG',
  },
  png: {
    format: 'image/png',
    name: 'PNG',
  },
  pdf: {
    format: 'application/pdf',
    name: 'one page PDF',
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

function FileUpload(props) {
  const {
    attachmentType,
    callback,
    cameraOnly,
    dispatch,
    distinctId,
    fileId,
    hasError,
    errorMessage,
    acceptedFileTypes: passedFileTypes,
    uploadedFile,
    maxFileSize,
  } = props;

  const latestProps = useRef();
  latestProps.current = props;
  const [file, setFile] = useState(toPreviewObj(uploadedFile));
  const [status, setStatus] = useState({
    messsage: null,
    type: null,
  });
  const [cameraMode, setCameraMode] = useState(false);
  const [progress, setProgress] = useState(null);
  const hasFile = !!file;
  const acceptedFileTypes = passedFileTypes
    ? passedFileTypes
    : Object.keys(FILE_TYPES);
  const fileTypeFormats = acceptedFileTypes.map(
    (fileType) => FILE_TYPES[fileType].format
  );

  useEffect(() => {
    if (hasError) {
      setStatus({ message: errorMessage, type: 'danger' });
    }

    if (!hasError && status.type === 'danger') {
      setStatus({ message: null, type: null });
    }
  }, [hasError, errorMessage]);

  const icons = {
    danger: {
      icon: 'times-circle',
    },
    loading: {
      icon: 'spinner',
      spin: true,
    },
    success: {
      icon: 'check-circle',
    },
  };

  const uploadIcon = cameraOnly ? 'camera' : 'upload';

  function saveFile(file) {
    if (maxFileSize && file.size > maxFileSize) {
      const processedFileSize = fileSize(maxFileSize, { exponent: 2 });

      return setStatus({
        message: `File size cannot be over than ${processedFileSize}.`,
        type: 'danger',
      });
    }

    setCameraMode(false);
    setFile(file);

    return uploadFile(file);
  }

  async function uploadFile(file) {
    try {
      dispatch(clickEvent('upload photo', distinctId));

      setStatus({
        message: 'Uploading',
        type: 'loading',
      });

      const data = await getPresignedUrl({ ...props, file });
      await storeFileToS3(data.url, file, setProgress);

      const { filename } = data;
      const ocrData = await updateFilename({ ...props, filename });

      dispatch(setIdentityValue(fileId, ocrData[`${attachmentType}_url`]));

      if (callback) {
        callback(ocrData);
      }

      if (!latestProps.current.hasError) {
        setStatus({
          message: 'Upload completed!',
          type: 'success',
        });
      }
    } catch (e) {
      const message = `${e.error ? e.error : 'Error.'} Please try again.`;
      // console.log(e);
      return setStatus({ message, type: 'danger' });
    }
  }

  function removeFile() {
    dispatch(setIdentityValue(fileId, null));
    setFile(null);
    setStatus({ message: null, type: null });
  }

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) {
      return;
    }

    setStatus({
      message: 'Processing',
      type: 'loading',
    });

    const preview =
      file.type === 'application/pdf'
        ? await getPdfImage(file)
        : await getBase64(file);

    saveFile(Object.assign(file, { preview }));
  };

  const onDropRejected = (rejectedFiles) => {
    const file = rejectedFiles[0];
    const error = file.errors[0];
    let message = error.message;
    let fileTypes = acceptedFileTypes
      .filter((_, index) => index < acceptedFileTypes.length - 1)
      .map((type) => FILE_TYPES[type].name)
      .join(', ');

    if (acceptedFileTypes.length > 1) {
      const lastFile = acceptedFileTypes[acceptedFileTypes.length - 1];
      fileTypes = `${fileTypes} or a ${FILE_TYPES[lastFile].name}`;
    }

    if (error.code === 'file-invalid-type') {
      message = `Upload failed. Please upload ${fileTypes}.`;
    }

    setStatus({ message, type: 'danger' });
  };

  const { getInputProps, getRootProps, open } = useDropzone({
    accept: fileTypeFormats.join(','),
    disabled: cameraOnly,
    maxFiles: 1,
    maxSize: mb2Bytes(20),
    noClick: true,
    noKeyboard: true,
    onDrop,
    onDropRejected,
  });

  function openFileDialog() {
    open();
  }

  useEffect(() => {
    if (file) {
      URL.revokeObjectURL(file.preview);
    }
  }, [file]);

  return (
    <Wrapper
      {...getRootProps({ className: 'dropzone' })}
      hasFile={hasFile}
      hasError={hasError}
      cameraMode={cameraMode}
    >
      <input
        {...getInputProps({
          capture: 'camera',
        })}
      />

      {cameraMode && (
        <Camera
          cameraOnly={cameraOnly}
          distinctId={distinctId}
          dispatch={dispatch}
          open={open}
          setFile={saveFile}
          setCameraMode={setCameraMode}
        />
      )}
      {!cameraMode && hasFile && (
        <UploadedFile
          cameraOnly={cameraOnly}
          file={file}
          openFileDialog={openFileDialog}
          handleRemoveFile={removeFile}
        />
      )}

      {progress && progress !== 100 && (
        <progress
          className="progress is-primary is-small"
          value={progress}
          max="100"
        >
          {progress}%
        </progress>
      )}

      {!cameraMode && !hasFile && (
        <InnerWrapper>
          <div>
            <FontAwesomeIcon
              icon={['fas', uploadIcon]}
              size="2x"
              className="has-text-primary"
            />
            <br />
            {!cameraOnly && !isMobile() && (
              <p className="is-size-2">Drag and drop file here, or</p>
            )}
          </div>
          <Actions
            open={open}
            setCameraMode={setCameraMode}
            cameraOnly={cameraOnly}
          />
        </InnerWrapper>
      )}
      {status.message && (
        <Status className={`has-text-${status.type}`}>
          <FontAwesomeIcon {...icons[status.type]} /> {status.message}
        </Status>
      )}
    </Wrapper>
  );
}

File.propTypes = {
  attachmentType: PropTypes.string.isRequired,
  cameraOnly: PropTypes.boolean,
  resourceType: PropTypes.string.isRequired,
};

export default connect((state, ownProps) => {
  return {
    currentUser: state.current_user,
  };
})(FileUpload);
