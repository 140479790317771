import api from '../../../api';
import {
  LOAD_CONFIG_ERROR,
  LOAD_CONFIG_START,
  LOAD_CONFIG_SUCCESS,
  SENT_BRANCH_INVITE_ERROR,
  SENT_BRANCH_INVITE_START,
  SENT_BRANCH_INVITE_SUCCESS,
  UPDATE_CONFIG_ERROR,
  UPDATE_CONFIG_START,
  UPDATE_CONFIG_SUCCESS,
} from './constants';

export function sentBranchInvite(params, callbacks) {
  return (dispatch, getState) => {
    dispatch({
      payload: params,
      type: SENT_BRANCH_INVITE_START,
    });

    const entity_id = getState().current_user.current_entity.id;
    const headquarter = api(
      'headquarters',
      getState().current_user.access_token,
      entity_id
    );

    headquarter.sentBranchInvite(
      entity_id,
      (result) => {
        dispatch({
          type: SENT_BRANCH_INVITE_SUCCESS,
        });
        dispatch(callbacks[0]);
        dispatch(callbacks[1]);
      },
      (error) => {
        dispatch({
          payload: error,
          type: SENT_BRANCH_INVITE_ERROR,
        });
        dispatch(callbacks[2]);
      },
      { params }
    );
  };
}

export function updateConfig(params, callbacks) {
  return (dispatch, getState) => {
    dispatch({
      payload: params,
      type: UPDATE_CONFIG_START,
    });

    const entity_id = getState().current_user.current_entity.id;
    const headquarter = api(
      'headquarters',
      getState().current_user.access_token,
      entity_id
    );

    headquarter.updateConfig(
      entity_id,
      (result) => {
        dispatch({
          type: UPDATE_CONFIG_SUCCESS,
        });
        dispatch(callbacks[0]);
      },
      (error) => {
        dispatch({
          payload: error,
          type: UPDATE_CONFIG_ERROR,
        });
      },
      { params }
    );
  };
}

export function loadConfig(callback) {
  return (dispatch, getState) => {
    dispatch({
      payload: null,
      type: LOAD_CONFIG_START,
    });

    const entity_id = getState().current_user.current_entity.id;
    const headquarter = api(
      'headquarters',
      getState().current_user.access_token,
      entity_id
    );

    headquarter.loadConfig(
      entity_id,
      (result) => {
        dispatch({
          type: LOAD_CONFIG_SUCCESS,
        });
        callback(result.data);
      },
      (error) => {
        dispatch({
          payload: error,
          type: LOAD_CONFIG_ERROR,
        });
        console.error(error);
      }
    );
  };
}

export function loadHistory(callback) {
  return (dispatch, getState) => {
    const entity_id = getState().current_user.current_entity.id;
    const headquarter = api(
      'headquarters',
      getState().current_user.access_token,
      entity_id
    );

    headquarter.loadHistory(
      entity_id,
      (result) => {
        callback(result.data.cfg);
      },
      (error) => {
        dispatch({
          payload: error,
          type: LOAD_CONFIG_ERROR,
        });
        console.error(error);
      }
    );
  };
}

export function deleteEntities(params, callback) {
  return (dispatch, getState) => {
    const entity_id = getState().current_user.current_entity.id;
    const headquarter = api(
      'headquarters',
      getState().current_user.access_token,
      entity_id
    );

    headquarter.deleteEntities(
      entity_id,
      (result) => {
        callback(params.data, result.data);
      },
      (error) => {
        console.error(error);
      },
      { params }
    );
  };
}
