/* Import libraries */
import React from 'react';
import MaterialButton from '@material-ui/core/Button';

/* Import CSS */

/* Import components */
import SquareModal from 'modules/shared/components/widgets/static/SquareModal';

class ConflictModal extends React.Component {
  render() {
    const { dismissHandler, conflict_message } = this.props;
    return (
      <SquareModal title={'Conflicting Limits'} size="small">
        <div>
          <p style={{ marginTop: '10px' }}>
            {conflict_message ||
              'Limits of this ruleset conflicts with others.'}
          </p>
          <div>
            <MaterialButton color="primary" onClick={dismissHandler}>
              Dismiss
            </MaterialButton>
          </div>
        </div>
      </SquareModal>
    );
  }
}

export default ConflictModal;
