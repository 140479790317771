import get from 'lodash.get';
import ApplicationModel from 'models/ApplicationModel';
import isPresent from 'utils/isPresent';
import mixpanel from 'mixpanel-browser';
import AntiFraudCategoryRule from 'utils/AntiFraudCategoryRule';

const NAME_ERROR =
  'The identification provided does not match the selected signatory name';
const MISSING_FILE_ERROR = 'Please upload or take a photo of your ID.';
const FACE_MATCH_ERROR = `It seems like the selfie and ID don't match.\
  Please make sure you are taking a front-facing selfie and uploading a clear valid ID.`;
const FRONT_FACE_MISSING_FILE_ERROR =
  'Please take a front-facing selfie that matches the uploaded ID';
const IDENTIFICATION_UPLOAD_ERROR =
  'Please upload a valid form of identification';

export function getAntiFraudConfig(addonRules) {
  return addonRules.find(
    (addonRule) =>
      get(addonRule, 'attributes.addon_module_name', '') === 'anti_fraud'
  );
}

export function getErrorMessage({
  frontFaceImage,
  idNameMatched,
  image_64,
  isAntiFraudPassing,
  isFaceMatchEnabled,
  isValidIdentification,
  overrideIdValidation,
  type,
}) {
  const isIdUpload = type === 'image_64';
  const isFrontFaceUpload = type === 'front_face_image';

  if (overrideIdValidation) {
    return '';
  }

  if (isIdUpload && !isValidIdentification) {
    return IDENTIFICATION_UPLOAD_ERROR;
  }

  if (isIdUpload && !image_64) {
    return MISSING_FILE_ERROR;
  }

  if (isIdUpload && !idNameMatched) {
    return NAME_ERROR;
  }

  if (isFrontFaceUpload && !frontFaceImage) {
    return FRONT_FACE_MISSING_FILE_ERROR;
  }

  const hasFaceMatchError =
    isFaceMatchEnabled &&
    typeof isAntiFraudPassing === 'boolean' &&
    !isAntiFraudPassing;
  if (hasFaceMatchError) {
    return FACE_MATCH_ERROR;
  }

  return '';
}

export function getAntiFraudAddonVersionAttributes(addonRules) {
  const antiFraudConfig = getAntiFraudConfig(addonRules);
  return get(antiFraudConfig, 'attributes.addon_version.data.attributes');
}

export function getIsAntiFraudEnabled(addonRules) {
  const antiFraudConfig = getAntiFraudConfig(addonRules);

  return isPresent(antiFraudConfig);
}

export function cobAuthorisationTypes(state, authorisationTypes) {
  const {
    cob_cards: cobCards,
    cob_guarantors: cobGuarantors,
    cob_paperless: cobPaperless,
    cob_section: cobSection,
  } = state;

  function guarantor() {
    const { form_values: formValues } = cobGuarantors;
    return (
      formValues.length > 0 &&
      formValues.find((guarantor) => guarantor.is_applicant)
    );
  }

  function signatory() {
    const { current_people: currentPeople } = cobSection;
    return (
      currentPeople.length > 0 &&
      currentPeople.find((person) => person.attributes.is_applicant)
    );
  }

  function cardholder() {
    const { cardholders } = cobCards;
    return (
      cardholders.length > 0 &&
      cardholders.find((cardholder) => cardholder.attributes.isApplicant)
    );
  }
  function directDebit() {
    return cobPaperless.answers.applicantAuthorised;
  }

  const types = {
    applicant: () => true,
    cardholder: cardholder,
    direct_debit: directDebit,
    guarantor: guarantor,
    signatory: signatory,
  };

  return authorisationTypes.filter((authorisationType) =>
    types[authorisationType]()
  );
}

export function getIsFaceMatchEnabled(state = {}) {
  const cobSection = state.cob_section || {};
  const cobBusiness = state.cob_business || {};
  const authorisation = state.authorisation;
  const cobMoney = state.cob_money || {};
  let application = cobSection.application;
  let antiFraud = cobSection.antiFraud;
  const isAuthorisation = authorisation.data;

  if (
    application &&
    get(application, 'attributes.application_type') === 'cash'
  ) {
    return false;
  }

  if (isAuthorisation) {
    antiFraud = authorisation.antiFraud;
    application = authorisation.data.application;
  }

  if (!antiFraud) {
    return false;
  }

  if (!(application instanceof ApplicationModel)) {
    application = new ApplicationModel(application);
  }

  const antiFraudCategoryRules = new AntiFraudCategoryRule(
    antiFraud.config.category
  );
  const hasFaceMatchConfig = antiFraudCategoryRules.isFrontFaceImageRequired;
  let isFaceMatchEnabled = hasFaceMatchConfig;

  if (hasFaceMatchConfig) {
    const requestedLimit =
      cobMoney.requested_limit || application.tradeAccountLimit;
    const legalType = application.legalType || cobBusiness.entity_type;
    let isWithinLimitRange = true;

    const { config, legalTypes, maxCreditValue, minCreditValue } = antiFraud;

    if (legalTypes === 0 || !legalTypes.includes(legalType)) {
      return false;
    }

    if (maxCreditValue) {
      const maxCreditValueInt = parseInt(maxCreditValue);
      const minCreditValueInt = parseInt(minCreditValue || 0);
      isWithinLimitRange =
        requestedLimit <= maxCreditValueInt &&
        requestedLimit >= minCreditValueInt;
    }

    if (!isWithinLimitRange) {
      return false;
    }

    const configAuthorisationTypes = config.authorisation_types || [];
    let activeTypes = [];

    if (isAuthorisation) {
      const {
        cardholderApproved,
        guarantorApproved,
        signatoryApproved,
        paymentApproved,
      } = authorisation;

      const types = {
        cardholder: cardholderApproved,
        direct_debit: paymentApproved,
        guarantor: guarantorApproved,
        signatory: signatoryApproved,
      };

      activeTypes = Object.keys(types).filter((type) => types[type]);
    } else {
      activeTypes = cobAuthorisationTypes(
        state,
        antiFraud.config.authorisation_types
      );
    }

    isFaceMatchEnabled = activeTypes.some((type) =>
      configAuthorisationTypes.includes(type)
    );
  }

  return isFaceMatchEnabled;
}

export function getIsIdentificationCheckRequired(props) {
  const { isGuarantor, guarantor, requiresID, state } = props;

  let requiresApplicantIdentificationCheck = false;
  let requiresGuaranteesIdentificationCheck = false;
  let applicationType;
  if (state.cob_section.application) {
    requiresApplicantIdentificationCheck =
      state.cob_section.application.attributes
        .requires_applicant_identification_check;
    requiresGuaranteesIdentificationCheck =
      state.cob_section.application.attributes
        .requires_guarantees_identification_check;
    applicationType = state.cob_section.application.attributes.application_type;
  }
  if (guarantor && guarantor.application) {
    requiresApplicantIdentificationCheck =
      guarantor.application.attributes.requires_guarantees_identification_check;
    requiresGuaranteesIdentificationCheck =
      guarantor.application.attributes.requires_guarantees_identification_check;
    applicationType = guarantor.application.attributes.application_type;
  }

  if (applicationType === 'cash') {
    return false;
  }

  if (isGuarantor) {
    return guarantor && requiresID;
  }

  const applicantGuarantorIndex = state.cob_guarantors.form_values.findIndex(
    (g) => g && g.is_applicant
  );
  const applicantIsGuarantor = applicantGuarantorIndex >= 0;

  const applicantCardholderIndex = state.cob_cards.cardholders.findIndex(
    (c) => c.attributes && c.attributes.isApplicant
  );
  const applicantIsCardholder = applicantCardholderIndex >= 0;

  const applicantSignatoryIndex = state.cob_business.entity_party_details_values.findIndex(
    (s) => s && s.is_applicant
  );
  const applicantIsSignatory = applicantSignatoryIndex >= 0;

  if (applicantIsGuarantor) {
    return (
      requiresGuaranteesIdentificationCheck ||
      requiresApplicantIdentificationCheck ||
      requiresID
    );
  }

  if (applicantIsCardholder || applicantIsSignatory) {
    return requiresID;
  }

  return requiresApplicantIdentificationCheck || requiresID;
}

export function getAuthorisationID({ ownProps, state }) {
  const ownPropsAuthorisationID = ownProps.authorisationID;

  if (isPresent(ownPropsAuthorisationID)) {
    return ownPropsAuthorisationID;
  }

  const cobSectionAuthorisationID = get(
    state,
    'cob_section.authorisation.id',
    null
  );
  if (isPresent(cobSectionAuthorisationID)) {
    return cobSectionAuthorisationID;
  }

  return get(state, 'authorisation.data.authorisation.id', null);
}

export function trackMixPanelSimilarityScore({ attributes, data }) {
  if (typeof data.pass !== 'boolean') {
    return;
  }

  const passFailStatus = data.pass ? 'PASS' : 'FAIL';

  return mixpanel.track(`1CAF Similarity score ${passFailStatus}`, attributes);
}
