import React, { ReactElement, useEffect, useState } from 'react';
import api from 'api';
import Panel from 'modules/dashboard/components/Panel';
import { Props } from 'modules/dashboard/components/Panel/model';
// @ts-ignore
import { browserHistory, Link } from 'react-router';
import { Table } from 'modules/dashboard/components/ActionsPanel/styles';
import useRequestQuery from 'modules/dashboard/hooks/useRequestQuery';
import useParamsCompare from 'modules/dashboard/hooks/useParamsCompare';
import Message from 'modules/dashboard/components/Message';
import ActionSwitch from 'modules/dashboard/components/ActionsPanel/ActionSwitch';
import { loadFilterValues, set } from 'modules/dashboard/utils';
import { Header } from '../Panel/styles';
import Tooltip from '../Panel/Tooltip';
import { isMobileNew } from 'modules/shared/helpers/mobileDetect';
import isBlank from 'utils/isBlank';
import { isReviewerLevel } from 'models/ReviewModel.js';

function SupplierAction(props: Props): ReactElement {
  const { accessToken, currentRoleTypes, entityId, filterState } = props;
  const isStandardUser = currentRoleTypes.includes('standard');
  const apiKey = 'supplier_action';
  const savedCurrent = loadFilterValues(apiKey, entityId);
  const [current, setCurrent] = useState(
    isStandardUser ? 'owner' : savedCurrent || 'all'
  );

  const params = {
    ...filterState,
    user_scope: current,
  };

  const apiAction = () =>
    api('reporting', accessToken, entityId).get({
      api: apiKey,
      params,
    });
  const { data, error, forceUpdate, loading } = useRequestQuery(apiAction);

  const paramString = JSON.stringify(params);
  const { oldParams, setOldParams } = useParamsCompare(paramString);

  useEffect(() => {
    if (paramString !== oldParams) {
      setOldParams(paramString);
      forceUpdate();
    }
  }, [params]);

  const handleSwitchChange = (value: string) => {
    set(apiKey, value, entityId);
    setCurrent(value);
    forceUpdate();
  };

  const displayFilter = isMobileNew() ? false : !isStandardUser;
  const displaySecondColumn = !isMobileNew();

  const formatApprovalKind = (item) => {
    const {
      approval_level,
      consumer_name,
      id,
      reviewer_first_name,
      reviewer_level,
      reviewer_id,
    } = item;

    const getSecondColumnContent = ({
      approvalLevel,
      reviewerFirstName,
      reviewerLevel,
    }) => {
      const firstName = reviewerFirstName || 'Untagged';

      if (isBlank(reviewerLevel)) {
        let levelDisplayed = `L${approvalLevel}`;
        if (isReviewerLevel(approvalLevel)) {
          levelDisplayed = 'R';
        }

        return `${firstName} - ${levelDisplayed}`;
      }

      if (isReviewerLevel(reviewerLevel)) {
        return `${firstName} - R`;
      }

      return `${firstName} - L${reviewerLevel}`;
    };

    return (
      <tr key={`ready-for-approval-${id}${reviewer_id && `${reviewer_id}`}`}>
        <td>
          <a
            onClick={() => browserHistory.push(`/dashboard/applications/${id}`)}
          >
            {consumer_name}
          </a>
        </td>
        {displaySecondColumn && (
          <td>
            {getSecondColumnContent({
              approvalLevel: approval_level,
              reviewerFirstName: reviewer_first_name,
              reviewerLevel: reviewer_level,
            })}
          </td>
        )}
      </tr>
    );
  };

  const formatIufKind = (item) => {
    const {
      consumer_name,
      id,
      approver_first_name,
      approver_last_name,
      approver_id,
    } = item;

    return (
      <tr key={`ready-for-approval-${id}${approver_id && `${approver_id}`}`}>
        <td>
          <a
            onClick={() => browserHistory.push(`/dashboard/applications/${id}`)}
          >
            {consumer_name}
          </a>
        </td>
        {displaySecondColumn && (
          <td>
            {isBlank(approver_first_name)
              ? 'Untagged'
              : approver_first_name + ' ' + approver_last_name}{' '}
            - IUF
          </td>
        )}
      </tr>
    );
  };

  const formatRow = (item) => {
    switch (item.approval_kind) {
      case 'approval':
        return formatApprovalKind(item);
      case 'iuf':
        return formatIufKind(item);
      default:
        return null;
    }
  };

  return (
    <Panel {...props} error={error} loading={loading} refresh={forceUpdate}>
      <div>
        <Table>
          <thead>
            <tr>
              <th>
                <Header>
                  <div>
                    <h2>
                      Supplier action
                      <Tooltip>
                        <span>
                          These applications are ready to move through the
                          decisioning process. If untagged, click on customer
                          name to tag as appropriate.
                        </span>
                      </Tooltip>
                    </h2>
                  </div>
                </Header>
              </th>
              <th>
                {displayFilter && (
                  <ActionSwitch
                    current={current}
                    handleChange={handleSwitchChange}
                  />
                )}
              </th>
            </tr>
          </thead>
          {data && data.slice(0, 20).length > 0 && (
            <tbody>{data.slice(0, 15).map(formatRow)}</tbody>
          )}
        </Table>
      </div>
      {data && data.length >= 15 && (
        <Link to={'/dashboard/reporting'} style={{ margin: '20px 0 10px 0' }}>
          View more...
        </Link>
      )}
      {data && Array.isArray(data) && data.length === 0 && !loading && !error && (
        <Message
          faProps={{
            icon: ['fas', 'check-circle'],
          }}
          message="No pending approvals."
        />
      )}
    </Panel>
  );
}

export default SupplierAction;
