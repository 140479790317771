import React from 'react';
import PropTypes from 'prop-types';
import FileUpload from 'modules/FileUpload';
import SectionHeader from 'modules/shared/components/v2/SectionHeader';
import { setProofOfAddress } from '../actions';

function ProofOfAdressUpload(props) {
  const {
    apiType,
    authorisationID,
    dispatch,
    distinctId,
    resourceType,
    hasError,
    proof_of_address,
  } = props;

  const fileId = 'proof_of_address';

  return (
    <div>
      <SectionHeader title="Proof of address upload">
        <p>
          Please add proof of address (eg.{' '}
          <span className="has-text-danger-dark">utility bill</span>,{' '}
          <span className="has-text-danger-dark">current bank statement</span>,{' '}
          <span className="has-text-danger-dark">council rates notice</span>).{' '}
          Please note the file needs to be{' '}
          <span className="has-text-danger-dark">JPEG</span>,{' '}
          <span className="has-text-danger-dark">PNG</span> or a{' '}
          <span className="has-text-danger-dark">ONE PAGE PDF</span> smaller
          than 20MB.
        </p>
      </SectionHeader>
      <FileUpload
        apiType={apiType}
        authorisationID={authorisationID}
        attachmentType={fileId}
        distinctId={distinctId}
        errorMessage="Please upload your proof of address."
        fileId={fileId}
        hasError={hasError}
        resourceType={resourceType}
        uploadedFile={proof_of_address}
      />
    </div>
  );
}

ProofOfAdressUpload.propTypes = {
  apiType: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
};

ProofOfAdressUpload.defaultProps = {
  apiType: 'authorisations',
  resourceType: 'authorisation',
};

export default ProofOfAdressUpload;
