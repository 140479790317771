/* CSS */
import 'babel-polyfill';
import 'normalize-css';
// Base
import './css/base/base.css';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// Typography
import './css/typography/fonts.css';
import './css/typography/font-awesome.css';
import './css/typography/typography.css';
import 'css/bulma-custom.scss';
import 'font-awesome/css/font-awesome.min.css';

import { Router, browserHistory } from 'react-router';
import { getUserData, setTokenState } from './modules/user/actions';

import Application from './application';
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import config from '../config';
import { actions as idleActions } from './modules/redux-idle-monitor';
import { init } from '@sentry/browser';
import { datadogLogs } from '@datadog/browser-logs';
import { mixpanelLocationTracking } from './utils/mixpanelLocationTracking';
import { render } from 'react-dom';
// import configureStore from './stores/configureStore';
import store from './stores/store';
import { syncHistoryWithStore } from 'react-router-redux';
import ErrorBoundary from 'containers/ErrorBoundary';
import useFontawesome from 'hooks/useFontawesome';
import { getToken } from 'modules/shared/auth/actions';
import Loader from 'modules/shared/components/widgets/static/Loader';
import get from 'lodash.get';

// const initialState = {};
// const store = configureStore(initialState);
const history = syncHistoryWithStore(browserHistory, store);

//Google Analytics Tag Component
const GoogleAnalyticsTag = () => (
  <Helmet>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-NVX4QB4MQJ"
    ></script>
    <script>
      {`window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-NVX4QB4MQJ');`}
    </script>
  </Helmet>
);

history.listen((location) => mixpanelLocationTracking(location, store));

// Removed IE support in this demo for the sake of simplicity

module.exports.store = store;

const theme = createMuiTheme({
  palette: {
    primary: {
      light: 'var(--main-color)',
      main: 'var(--main-color)',
      dark: 'var(--main-color)',
      contrastText: '#fff',
    },
    error: {
      main: '#F44335',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

init({
  dsn: process.env.SENTRY_DSN,
  maxBreadcrumbs: 20,
});

if (process.env.DATADOG_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.DATADOG_TOKEN,
    site: process.env.DATADOG_SITE,
    env: process.env.DATADOG_ENV,
    forwardConsoleLogs: ['info'],
  });
}

useFontawesome();

function App() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    (async function authInit() {
      const token = await getToken();
      const location = history.getCurrentLocation();

      if (token !== null) {
        store.dispatch(setTokenState(token));
        store.dispatch(
          getUserData(token, null, null, get(location, 'query.entity'))
        );
      }
      store.dispatch(idleActions.start());

      setIsLoaded(true);

      datadogLogs.logger.log('App loaded', {}, 'info');
    })();
  }, []);

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Router
            onUpdate={() => {
              if (
                location.pathname.indexOf('/dashboard/reporting') < 0 &&
                location.pathname.indexOf('/trm/list') < 0 &&
                location.pathname.indexOf('/dashboard/leads/list') < 0
              ) {
                window.scrollTo(0, 0);
              }
            }}
            history={history}
            routes={Application}
          />
          {process.env.NODE_ENV !== 'production' && <GoogleAnalyticsTag />}
        </MuiThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
}

render(<App />, document.getElementById('Application'));
