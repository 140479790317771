import axios from './axios';
import { requestInterceptor } from 'utils/axios_interceptors';

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#entities-creating-an-entity
 */
const Entities = function(config) {
  config.timeout = 30000;
  this.axios = axios(config);
  this.type = 'entities';

  this.axios.interceptors.request.use(
    (request) => requestInterceptor('Entities API', request),
    (error) => Promise.reject(error)
  );
};

Entities.prototype.createEntity = function(attributes, success, error) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        attributes,
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

Entities.prototype.createEntityWithRelationship = function(
  attributes,
  relationships,
  success,
  error
) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        attributes,
        relationships,
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

Entities.prototype.getEntity = function(id, success, error, options = {}) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v2', 'v1');
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.getEntityUsers = function(id, success, error, options = {}) {
  return this.axios
    .get(`/${this.type}/${id}/users`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.getEntityWebhooks = function(
  id,
  success,
  error,
  options = {}
) {
  return this.axios
    .get(`/${this.type}/${id}/web_hooks`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.getEntityIntegrations = function(
  id,
  success,
  error,
  options = {}
) {
  return this.axios
    .get(`/${this.type}/${id}/integrations`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.getEntityPeople = function(
  id,
  success,
  error,
  options = {}
) {
  return this.axios
    .get(`/${this.type}/${id}/people`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.getEntityConsumerApplications = function(
  id,
  success,
  error,
  options = {}
) {
  return this.axios
    .get(`/${this.type}/${id}/consumer_applications`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.getEntitySupplierApplications = function(
  id,
  success,
  error,
  options = {}
) {
  return this.axios
    .get(`/${this.type}/${id}/supplier_applications`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.getEntityConnections = function(
  id,
  success,
  error,
  options = {}
) {
  return this.axios
    .get(`/${this.type}/${id}/connections`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.getEntityConsumerConnections = function(
  id,
  success,
  error,
  options = {}
) {
  return this.axios
    .get(`/${this.type}/${id}/consumer_connections`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.getEntitySupplierConnections = function(
  id,
  success,
  error,
  options = {}
) {
  return this.axios
    .get(`/${this.type}/${id}/supplier_connections`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.getEntityCards = function(id, success, error, options = {}) {
  return this.axios
    .get(`/${this.type}/${id}/cards`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.getEntityTransactions = function(
  id,
  success,
  error,
  options = {}
) {
  return this.axios
    .get(`/${this.type}/${id}/transactions`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.getEntitySubscriptions = function(
  id,
  success,
  error,
  options = {}
) {
  return this.axios
    .get(`/${this.type}/${id}/subscriptions`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.updateEntity = function(
  id,
  attributes,
  success,
  error,
  context = {}
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      context,
      data: {
        attributes,
        id,
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

Entities.prototype.setBusinessDescription = function(
  entityId,
  businessId,
  success,
  error
) {
  return this.axios
    .patch(`/${this.type}/${entityId}`, {
      data: {
        id: entityId,
        relationships: {
          business_description: {
            data: {
              id: businessId,
              type: 'business_descriptions',
            },
          },
        },
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

Entities.prototype.getEntityBusinessDescription = function(
  id,
  success,
  error,
  options = {}
) {
  return this.axios
    .get(`/${this.type}/${id}/business_description`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.deleteEntity = function(id, success, error) {
  return this.axios
    .delete(`/${this.type}/${id}`)
    .then(success)
    .catch(error);
};

Entities.prototype.getEntities = function(success, error, options = {}) {
  return this.axios
    .get(`/${this.type}`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.getTermsDocument = function(
  id,
  success,
  error,
  options = {}
) {
  return this.axios
    .get(`/${this.type}/${id}/terms_documents`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.becomeSupplier = function(id, success, error) {
  return this.axios
    .patch(`/become_supplier/${id}`, {
      data: {
        id,
        type: 'become_suppliers',
      },
    })
    .then(success)
    .catch(error);
};

Entities.prototype.getCurrentPricingPlan = function(id, success, error) {
  return this.axios
    .get(`/${this.type}/${id}/entity_plan`)
    .then(success)
    .catch(error);
};

Entities.prototype.deletePeople = function(id, success, error) {
  return this.axios
    .delete(`/${this.type}/${id}/people`)
    .then(success)
    .catch(error);
};

Entities.prototype.acceptBranchInvite = function(id, success, error, options) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios
    .post(`/${this.type}/${id}/accept_branch_invite`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.declineBranchInvite = function(id, success, error, options) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios
    .post(`/${this.type}/${id}/decline_branch_invite`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.getUserHeadquarterLink = function(id, success, error) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios
    .get(`/${this.type}/${id}/user_entity_link`)
    .then(success)
    .catch(error);
};

Entities.prototype.getHeadquarterKeyContact = function(id, success, error) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios
    .get(`/${this.type}/${id}/headquarter_key_contact`)
    .then(success)
    .catch(error);
};

Entities.prototype.getEntityEmailTemplates = function(
  id,
  success,
  error,
  options = {}
) {
  return this.axios
    .get(`/${this.type}/${id}/entity_email_templates`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.getTradingNames = function(id, success, error, options) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios
    .get(`/${this.type}/${id}/available_trading_names`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.createOrUpdatePeople = function(
  id,
  success,
  error,
  options
) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios
    .post(`/${this.type}/${id}/people`, options)
    .then(success)
    .catch(error);
};

Entities.prototype.getLogoAndTheme = function(id) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios.get(`/${this.type}/${id}/logo_and_theme`);
};

Entities.prototype.setSSOProvider = function(
  id,
  { sso_provider },
  success,
  error
) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios
    .patch(`/sso_provider/${id}`, {
      data: {
        sso_provider,
      },
    })
    .then(success)
    .catch(error);
};

Entities.prototype.assignBillingDetails = function(
  id,
  attributes,
  success,
  error
) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios
    .patch(`/${this.type}/${id}/assign_billing_details`, {
      attributes,
    })
    .then(success)
    .catch(error);
};

export default Entities;
