const defaultButtons = [
  {
    buttonLabel: 'Progress',
    buttonName: 'progress',
  },
  {
    buttonLabel: "T&C's",
    buttonName: 't-and-c',
  },
  {
    buttonLabel: "Q&A's",
    buttonName: 'q-and-a',
  },
];

const checklistButtonProperties = {
  buttonLabel: 'Checklist',
  buttonName: 'checklist',
};

const OverlayButtonsList = (withChecklist) => {
  if (!withChecklist) return defaultButtons;
  return [checklistButtonProperties, ...defaultButtons];
};

export default OverlayButtonsList;
