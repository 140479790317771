import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import get from 'lodash.get';
import Tab from 'mui-latest/Tab';
import Tabs from 'mui-latest/Tabs';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip.js';
import React, { ReactElement, useEffect, useState } from 'react';
import CallCentreLog from 'modules/new-applications/components/application-actions/CallCentreLog';
import EmailLog from 'modules/new-applications/components/application-actions/EmailLog';
import Paper from '@material-ui/core/Paper';
import { Icon } from './styles';
import AddonConfigModel from 'models/AddonConfigModel';
import mixpanel from 'mixpanel-browser';

type CurrentUser = {
  accessToken: string;
  currentEntity: {
    id: string;
  };
};

type Application = {
  id: string;
};

type Props = {
  application: Application;
  currentUser: CurrentUser;
};

const EmailTooltip = () => (
  <span style={{ marginLeft: '0.25rem' }}>
    Email Log{' '}
    <PopperTooltip
      extraComponent={
        <div className="has-text-left">
          <p>
            {' '}
            This feature tracks key emails your customer will engage with, and
            allows you to track these. We will be tracking four events -
            <br />
            1. Delivered 2. Opened 3. Clicked 4. Bounced. Sometimes the
            &apos;Open&apos; event for the initial email sent to your customer
            will trigger prematurely as their email provider may be scanning for
            viruses or spam. We will be working to mitigate this occurrence over
            time. Any emails initiated prior to this release will not display.
          </p>
        </div>
      }
    >
      <Icon>
        <FontAwesomeIcon icon={['fas', 'question-circle']} />
      </Icon>
    </PopperTooltip>
  </span>
);

const CallCentreTooltip = () => (
  <span style={{ marginLeft: '0.25rem' }}>
    Call Centre Log{' '}
    <PopperTooltip
      extraComponent={
        <div className="has-text-left">
          <p>
            {' '}
            Call Centre log keeps a record of the activity history when an
            authorised Supplier user is filling out an application on behalf of
            the customer.
          </p>
        </div>
      }
    >
      <Icon>
        <FontAwesomeIcon icon={['fas', 'question-circle']} />
      </Icon>
    </PopperTooltip>
  </span>
);

export default function HistoryLog(props: Props): ReactElement | null {
  const { application, currentUser } = props;

  const [currentTab, setCurrentTab] = useState(0);
  const onChangeTab = (_, newTab) => {
    setCurrentTab(newTab);

    if (newTab === 1) {
      mixpanel.track('VCF - History log - Call centre log', {
        'Entity ID': entityId,
        distinct_id: get(currentUser, 'data.data.id'),
      });
    }
  };

  const accessToken = get(currentUser, 'accessToken');
  const entityId = get(currentUser, 'currentEntity.id');
  const applicationId = get(application, 'id');

  const [isCallCentreActive, setIsCallCentreActive] = useState(false);
  useEffect(() => {
    (async () => {
      const addonConfigs = await AddonConfigModel.fetchAddonConfigByAddonType({
        accessToken,
        addonType: ['call_centre'],
        entityId,
      });
      const addonConfig = addonConfigs[0];
      setIsCallCentreActive(addonConfig && addonConfig.active);
    })();
  }, []);

  return (
    <Paper square elevation={0}>
      <Tabs
        style={{ textTransform: 'none' }}
        indicatorColor="primary"
        textColor="primary"
        value={currentTab}
        onChange={onChangeTab}
        scrollButtons="auto"
        variant="scrollable"
        aria-label="credit check tabs"
      >
        <Tab
          label={<EmailTooltip />}
          value={0}
          style={{ textTransform: 'none' }}
        />
        {isCallCentreActive && (
          <Tab
            label={<CallCentreTooltip />}
            value={1}
            style={{ textTransform: 'none' }}
          />
        )}
      </Tabs>
      <div className="pt-4">
        {currentTab === 0 && <EmailLog {...props} />}
        {currentTab === 1 && <CallCentreLog {...props} />}
      </div>
    </Paper>
  );
}
