import vcfAlerts from 'images/webhooks/vcf-alerts.png';
import {
  DefinitionList,
  Figure,
  OrderedList,
  SectionDescription,
  SectionHeader,
  SubSection,
} from 'modules/webhooks/v2/components/V2Documentation/Content/styles';
import React, { Fragment, ReactElement } from 'react';

const Events = (): ReactElement => (
  <Fragment>
    <SectionHeader>Events</SectionHeader>
    <SubSection>
      <SectionDescription>
        There are currently two events supported:
      </SectionDescription>
      <OrderedList>
        <li>
          <DefinitionList>
            <dt>Approved application</dt>
            <dd>
              This webhook event is triggered when an application has been
              accepted.
            </dd>
            <dd>
              When the receiving endpoint responds with a success HTTP code, the
              application from 1Centre will be then flagged as loaded in system.
            </dd>
          </DefinitionList>
        </li>

        <li>
          <DefinitionList>
            <dt>Alert notification</dt>
            <dd>
              <div>
                This webhook event is triggered when there is a change in an
                application&apos;s alerts. The application&apos;s alerts can be
                found in the Virtual Credit File - Credit control tab.
              </div>
              <Figure>
                <img src={vcfAlerts} alt="vcf-alerts" />
              </Figure>
            </dd>
          </DefinitionList>
        </li>
      </OrderedList>
    </SubSection>
  </Fragment>
);

export default Events;
