import get from 'lodash.get';
import intersection from 'lodash.intersection';
import AntiFraudCategoryRule, {
  DEFAULT_ANTI_FRAUD_CATEGORY,
} from 'utils/AntiFraudCategoryRule';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';

const getIsWithinLimitRange = ({ antiFraud, requestedLimit }) => {
  const { maxCreditValue, minCreditValue } = antiFraud;

  if (isBlank(maxCreditValue)) {
    return true;
  }

  const maxValue = parseInt(maxCreditValue);
  const minValue = parseInt(minCreditValue || 0);

  return requestedLimit <= maxValue && requestedLimit >= minValue;
};

const getApplicableAntiFraudRules = ({
  antiFraud,
  application,
  authorisationRoles,
  requestedLimit,
}) => {
  // If AntiFraud is not set, it means AML is selected
  if (isBlank(antiFraud) || !antiFraud.isPersisted) {
    return {
      requiresFrontFaceImage: false,
      requiresGovernmentVerification: false,
      requiresIdentificationImage: true,
      requiresIdentificationSection: true,
      requiresProofOfLife: false,
    };
  }

  if (!antiFraud.legalTypes.includes(application.legalType)) {
    // Disable the whole ID section
    return {
      requiresFrontFaceImage: false,
      requiresGovernmentVerification: false,
      requiresIdentificationImage: false,
      requiresIdentificationSection: false,
      requiresProofOfLife: false,
    };
  }

  const applicableAuthorisationTypes = get(
    antiFraud,
    'config.authorisation_types',
    []
  );

  let applicableCategory = DEFAULT_ANTI_FRAUD_CATEGORY;

  if (
    isPresent(intersection(authorisationRoles, applicableAuthorisationTypes)) &&
    getIsWithinLimitRange({
      antiFraud,
      requestedLimit,
    })
  ) {
    applicableCategory = antiFraud.category;
  }

  const categoryRule = new AntiFraudCategoryRule(applicableCategory);
  return categoryRule.rulesForCategory;
};

export default getApplicableAntiFraudRules;
