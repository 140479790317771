import React, { ReactElement, useEffect, useState } from 'react';
import api from 'api';
import Panel from 'modules/dashboard/components/Panel';
import { Props } from 'modules/dashboard/components/Panel/model';
// @ts-ignore
import { browserHistory } from 'react-router';
import { Table } from 'modules/dashboard/components/ActionsPanel/styles';
import useRequestQuery from 'modules/dashboard/hooks/useRequestQuery';
import useParamsCompare from 'modules/dashboard/hooks/useParamsCompare';
import Message from 'modules/dashboard/components/Message';
import ActionSwitch from 'modules/dashboard/components/ActionsPanel/ActionSwitch';
import { loadFilterValues, set } from 'modules/dashboard/utils';
import { Header } from '../Panel/styles';
import Tooltip from '../Panel/Tooltip';
import { isMobileNew } from 'modules/shared/helpers/mobileDetect';

function NeedActions(props: Props): ReactElement {
  const { accessToken, currentRoleTypes, entityId, filterState } = props;

  const isStandardUser = currentRoleTypes.includes('standard');
  const apiKey = 'applications_need_action';
  const savedCurrent = loadFilterValues(apiKey, entityId);
  const [current, setCurrent] = useState(
    isStandardUser ? 'owner' : savedCurrent || 'all'
  );

  const params = {
    ...filterState,
    user_scope: current,
  };

  const apiAction = () =>
    api('reporting', accessToken, entityId).get({
      api: apiKey,
      params,
    });

  const { data, error, forceUpdate, loading } = useRequestQuery(apiAction);

  const paramString = JSON.stringify(params);
  const { oldParams, setOldParams } = useParamsCompare(paramString);

  useEffect(() => {
    if (paramString !== oldParams) {
      setOldParams(paramString);
      forceUpdate();
    }
  }, [params]);

  const handleSwitchChange = (value: string) => {
    set(apiKey, value, entityId);
    setCurrent(value);
    forceUpdate();
  };

  const displaySecondColumn = !isMobileNew();

  return (
    <Panel error={error} loading={loading} refresh={forceUpdate} {...props}>
      <Table>
        <thead>
          <tr>
            <th>
              <Header>
                <div>
                  <h2>
                    Customer action
                    <Tooltip>
                      <span>
                        This column advises you which applications are completed
                        but still have actions that need to be completed - for
                        example a signature from a guarantor. Click on the
                        application name which will take you directly into the
                        credit file.{' '}
                        <span className="has-text-weight-bold">Please</span>{' '}
                        follow up with your customer and help them along.
                      </span>
                    </Tooltip>
                  </h2>
                </div>
              </Header>
            </th>
            <th>
              {!isStandardUser && (
                <ActionSwitch
                  current={current}
                  handleChange={handleSwitchChange}
                />
              )}
            </th>
          </tr>
        </thead>
        {data && data.length > 0 && (
          <tbody>
            {data.map((item) => {
              const { consumer_name, id, pending_reason } = item;
              return (
                <tr key={`needs-action-${id}`}>
                  <td>
                    <a
                      onClick={() =>
                        browserHistory.push(`/dashboard/applications/${id}`)
                      }
                    >
                      {consumer_name}
                    </a>
                  </td>
                  {displaySecondColumn && <td>{pending_reason}</td>}
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
      {data && Array.isArray(data) && data.length === 0 && !loading && !error && (
        <Message
          faProps={{
            icon: ['fas', 'check-circle'],
          }}
          message="No action needed."
        />
      )}
    </Panel>
  );
}

export default NeedActions;
