import get from 'lodash.get';
import MenuItem from 'modules/webhooks/v2/components/V2Documentation/Navigation/MenuItem';
import { MenuLabel } from 'modules/webhooks/v2/components/V2Documentation/Navigation/styles';
import React, { ReactElement } from 'react';

const Navigation = ({
  activeSection,
  setActiveSection,
}: {
  activeSection: string;
  setActiveSection: any;
}): ReactElement => {
  const onClickActiveSection = (event) =>
    setActiveSection(get(event, 'target.dataset.section', 'introduction'));

  return (
    <div className="menu">
      <MenuLabel className="menu-label">General</MenuLabel>
      <ul className="menu-list">
        <MenuItem
          section="introduction"
          activeSection={activeSection}
          onClick={onClickActiveSection}
        >
          Introduction
        </MenuItem>
        <MenuItem
          section="connecting"
          activeSection={activeSection}
          onClick={onClickActiveSection}
        >
          Connecting
        </MenuItem>
        <MenuItem
          section="events"
          activeSection={activeSection}
          onClick={onClickActiveSection}
        >
          Events
        </MenuItem>
      </ul>
      <MenuLabel className="menu-label">Schema definition</MenuLabel>
      <ul className="menu-list">
        <MenuItem
          section="approved-application-payload-structure"
          activeSection={activeSection}
          onClick={onClickActiveSection}
        >
          Approved application
        </MenuItem>
        <MenuItem
          section="alert-notification-payload-structure"
          activeSection={activeSection}
          onClick={onClickActiveSection}
        >
          Alert notifcation
        </MenuItem>
      </ul>
    </div>
  );
};

export default Navigation;
