import React, { Component } from 'react';

import Button from 'modules/shared/components/inputs/Button';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import SignatureContainer from './SignatureContainer';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import TextInput from 'modules/shared/components/inputs/TextInput';
import ToolTip from 'modules/shared/components/widgets/interactive/ToolTip';
import isBlank from 'utils/isBlank';
import styles from './css/SignatureContainer.css';
import termsStyles from './css/SignatureTerms.css';

const FIELD_NAME_MAPPING = {
  firstName: 'first name',
  lastName: 'last name',
};

export default class SignatureTerms extends Component {
  constructor() {
    super();
    this.state = {
      agreeToTermsAndConditions: false,
      firstName: '',
      formErrors: {
        firstName: '',
        lastName: '',
      },
      isAgreeToTermsTooltipVisible: false,
      lastName: '',
    };
  }

  onHandleChangeValue(field, event) {
    const value = event.target.value;

    this.setState({ [field]: value });
    const error = this.getErrorForField(field, value);

    this.setState({
      formErrors: {
        ...this.state.formErrors,
        [field]: error,
      },
    });
  }

  onToggleAgreeToTermsAndConditions() {
    this.setState({
      agreeToTermsAndConditions: !this.state.agreeToTermsAndConditions,
      isAgreeToTermsTooltipVisible: false,
    });
  }

  onClickConfirmButton() {
    const { hasScrolled, onConfirm, onShowScrollToTermsTooltip } = this.props;

    if (!hasScrolled && onShowScrollToTermsTooltip) {
      onShowScrollToTermsTooltip();
      return;
    }

    if (!this.state.agreeToTermsAndConditions) {
      this.setState({ isAgreeToTermsTooltipVisible: true });
      return;
    }

    const formErrors = this.validateFields();
    const errors = Object.values(formErrors);
    const isInvalid = errors.some((error) => error.length > 0);

    if (isInvalid) {
      this.setState({ formErrors });
      return;
    }

    if (onConfirm) {
      onConfirm();
    }
  }

  onClickDeclineButton() {
    const { onDecline } = this.props;

    if (onDecline) {
      onDecline();
    }
  }

  getErrorForField(field, value) {
    const { cardholder } = this.props;
    const fieldName = FIELD_NAME_MAPPING[field];

    if (isBlank(value)) {
      return `You must enter your ${fieldName}`;
    }

    if (cardholder[field].toLowerCase().trim() !== value.toLowerCase().trim()) {
      return `Incorrect ${fieldName}`;
    }

    return '';
  }

  validateFields() {
    const fields = ['firstName', 'lastName'];
    const formErrors = { firstName: '', lastName: '' };

    for (const field of fields) {
      formErrors[field] = this.getErrorForField(field, this.state[field]);
    }

    return formErrors;
  }

  termsAndConditions() {
    const { supplierTradingName, termsAndConditions } = this.props;

    return (
      <div>
        <div>
          <PanelTitle text={`${supplierTradingName} Ts & Cs`} />
        </div>
        {termsAndConditions}
        <div className={termsStyles.agreeCheckbox}>
          <SquareCheckbox
            checked={this.state.agreeToTermsAndConditions}
            handleChange={this.onToggleAgreeToTermsAndConditions.bind(this)}
            checkboxId="agreeToTermsAndConditions"
            label="I have read the terms and conditions"
          />
          {this.agreeTooltip()}
        </div>
      </div>
    );
  }

  agreeTooltip() {
    if (this.state.isAgreeToTermsTooltipVisible) {
      return (
        <ToolTip
          tip_name="ReviewSupplierTermsAgree"
          css_style="review_supplier_terms_agree"
        />
      );
    }
  }

  electronicSignature() {
    return (
      <div className={termsStyles.electronicSignatureContainer}>
        {this.electronicSignatureNotice()}
        {this.electronicSignatureFields()}
      </div>
    );
  }

  electronicSignatureNotice() {
    const { supplierTradingName } = this.props;

    return (
      <div>
        <PanelTitle text="Electronic Signature" margin_bottom="1em" />
        <div className={termsStyles.notice}>
          <p>
            By signing this section, each Card Signatory confirms and
            acknowledges that:
          </p>
          <div className={termsStyles.acknowledgementNotice}>
            <div className={termsStyles.acknowledgementNoticeItem}>
              <div>a)</div>
              <div>the information provided is true and correct,</div>
            </div>
            <div className={termsStyles.acknowledgementNoticeItem}>
              <div>b)</div>
              <div>
                once the Card Signatory uses the {supplierTradingName} Card they
                are bound by the Conditions of Use set out in&nbsp;
                {supplierTradingName} &#34;Terms and Conditions&#34; (as amended
                from time to time), a copy of which has been received, and
              </div>
            </div>
            <div className={termsStyles.acknowledgementNoticeItem}>
              <div>c)</div>
              <div>
                in the event the applicant does not pay for the purchases
                incurred by the Card Signatory using the {supplierTradingName}
                &nbsp;Card, the Card Signatory shall pay {supplierTradingName}
                &nbsp;for such purchase.
              </div>
            </div>
          </div>
        </div>
        <div className={termsStyles.notice}>
          1Centre is Trade account management software. We facilitate the
          aggregation of data for your supplier to make a decision about forming
          a trading relationship with you. Thanks so much and happy trading!
        </div>
        <div className={termsStyles.notice}>
          We also encourage you to view our 1Centre{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.1centre.com/1Centre%20Privacy%20Policy/"
          >
            privacy policy
          </a>
          .
        </div>
      </div>
    );
  }

  electronicSignatureFields() {
    const { firstName, formErrors, lastName } = this.state;

    return (
      <div className={termsStyles.signatureFields}>
        <div className={termsStyles.notice}>
          If you agree please fill out your first name and last name in the
          fields below and press confirm
        </div>
        <div className={styles.halfWidth}>
          <TextInput
            id="firstName"
            name="firstName"
            error={formErrors.firstName}
            label="First name"
            value={firstName}
            required={true}
            handleChange={this.onHandleChangeValue.bind(this, 'firstName')}
            handleBlur={this.onHandleChangeValue.bind(this, 'firstName')}
          />
        </div>
        <div className={styles.halfWidth}>
          <TextInput
            id="lastName"
            name="lastName"
            error={formErrors.lastName}
            label="Last name"
            value={lastName}
            required={true}
            handleChange={this.onHandleChangeValue.bind(this, 'lastName')}
            handleBlur={this.onHandleChangeValue.bind(this, 'lastName')}
          />
        </div>
      </div>
    );
  }

  confirmButtonTooltip() {
    if (this.props.isScrollToTermsTooltipVisible) {
      return (
        <ToolTip
          tip_name="ReviewSupplierTermsScroll"
          css_style="review_supplier_terms_scroll"
        />
      );
    }
  }

  render() {
    const { supplierLogo, supplierTradingName } = this.props;

    return (
      <SignatureContainer
        supplierLogo={supplierLogo}
        supplierTradingName={supplierTradingName}
      >
        <div className={styles.sectionContainer}>
          <div className={styles.content}>
            <div className={termsStyles.content}>
              {this.termsAndConditions()}
              {this.electronicSignature()}
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              text="no"
              handleClick={this.onClickDeclineButton.bind(this)}
            />
            <Button
              text="yes"
              handleClick={this.onClickConfirmButton.bind(this)}
            />
          </div>
          {this.confirmButtonTooltip()}
        </div>
      </SignatureContainer>
    );
  }
}
