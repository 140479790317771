import Form from 'modules/consumer-invite/v2/ConsumerInvite/FullPage/Form';
import MobileDismissButton from 'modules/consumer-invite/v2/ConsumerInvite/FullPage/MobileDismissButton';
import {
  Header,
  MessageWrapper,
  Wrapper,
} from 'modules/consumer-invite/v2/ConsumerInvite/FullPage/styles';
import SendSuccessMessage from 'modules/consumer-invite/v2/ConsumerInvite/SendSuccessMessage';
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import React, { ReactElement, useState } from 'react';
// @ts-ignore-next-line
import { browserHistory } from 'react-router';

const FullPage = (props): ReactElement => {
  const {
    params: { application_type: applicationType },
  } = props;

  const [email, setEmail] = useState('');
  const [isInviteSent, setIsInviteSent] = useState(false);
  const [hasError, setHasError] = useState(false);
  const onCancel = () => browserHistory.push('/dashboard/reporting');

  const onSuccessSendCallback = (email) => {
    setEmail(email);
    setIsInviteSent(true);
  };

  const onErrorCallback = (error) => {
    console.error(error);
    setHasError(true);
  };

  let content = (
    <Form
      applicationType={applicationType}
      onCancel={onCancel}
      onErrorCallback={onErrorCallback}
      onSuccessSendCallback={onSuccessSendCallback}
    />
  );

  if (isInviteSent) {
    content = (
      <MessageWrapper>
        <SendSuccessMessage email={email} />
        <MobileDismissButton onCancel={onCancel} />
      </MessageWrapper>
    );
  }

  if (hasError) {
    content = (
      <MessageWrapper>
        Something went wrong! Please refresh and try again.
        <MobileDismissButton onCancel={onCancel} />
      </MessageWrapper>
    );
  }

  return (
    <Wrapper>
      <span className="is-hidden-mobile">
        <CloseButton handleClick={onCancel} />
      </span>
      <Header>Send application - credit</Header>
      {content}
    </Wrapper>
  );
};

export default FullPage;
