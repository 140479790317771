import {
  COB_BUSINESS_COMPANY_DETAILS_ERROR,
  COB_BUSINESS_COMPANY_DETAILS_START,
  COB_BUSINESS_COMPANY_DETAILS_SUCCESS,
  COB_BUSINESS_COMPANY_MANUAL_SET,
  COB_BUSINESS_COMPANY_NAME_LOOKUP_CLEAR,
  COB_BUSINESS_COMPANY_NAME_LOOKUP_ERROR,
  COB_BUSINESS_COMPANY_NAME_LOOKUP_START,
  COB_BUSINESS_COMPANY_NAME_LOOKUP_SUCCESS,
  COB_BUSINESS_COMPANY_NAME_SELECT,
  COB_BUSINESS_COMPANY_SET_NAME,
  COB_BUSINESS_COMPONENT_COMPLETE,
  COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_CLEAR,
  COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_ERROR,
  COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_START,
  COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_SUCCESS,
  COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_CLEAR,
  COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_ERROR,
  COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_START,
  COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_SUCCESS,
  COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_CLEAR,
  COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_ERROR,
  COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_START,
  COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_SUCCESS,
  COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_CLEAR,
  COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_ERROR,
  COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_START,
  COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_SUCCESS,
  COB_BUSINESS_PRESET_PHYSICAL_ADDRESS,
  COB_BUSINESS_PRESET_POSTAL_ADDRESS,
  COB_BUSINESS_REMOVE_ENTITY_PARTY_DETAILS,
  COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_CLEAR,
  COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_ERROR,
  COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_START,
  COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_SUCCESS,
  COB_BUSINESS_SELECT_ENTITY_PARTY_COUNT,
  COB_BUSINESS_SELECT_ENTITY_TYPE,
  COB_BUSINESS_SELECT_PHYSICAL_ADDRESS,
  COB_BUSINESS_SELECT_POSTAL_ADDRESS,
  COB_BUSINESS_SET_ADDRESS,
  COB_BUSINESS_SET_DEFAULT_ENTITY_DETAILS,
  COB_BUSINESS_SET_DELIVERY_INSTRUCTION,
  COB_BUSINESS_SET_DIRECTORS_CONTAINER_INDEX,
  COB_BUSINESS_SET_APPLICATION_TYPE,
  COB_BUSINESS_SET_ENTITY_ID,
  COB_BUSINESS_SET_ENTITY_LOGO,
  COB_BUSINESS_SET_ENTITY_NAME,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CONTACT_COUNTRY_CODE,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CONTACT_PHONE_NUMBER,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMAIL,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMPLOYER,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_FIRSTNAME,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_IS_APPLICANT,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_LASTNAME,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_MIDDLENAME,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PERSON_POSTAL_ADDRESS,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PERSON_POSTAL_ADDRESS_API_ID,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_POSITION,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PREVIOUS_LASTNAME,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_ADDRESS,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_API_ID,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_TYPE,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_SHARE,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_INDEX,
  COB_BUSINESS_SET_INCORPORATED_NUMBER,
  COB_BUSINESS_SET_KEY_CONTACT_COUNTRY_CODE,
  COB_BUSINESS_SET_KEY_CONTACT_EMAIL,
  COB_BUSINESS_SET_KEY_CONTACT_FIRST_NAME,
  COB_BUSINESS_SET_KEY_CONTACT_LAST_NAME,
  COB_BUSINESS_SET_KEY_CONTACT_PASSWORD,
  COB_BUSINESS_SET_KEY_CONTACT_PHONE,
  COB_BUSINESS_SET_LEGAL_ENTITY_AGREEMENT,
  COB_BUSINESS_SET_PHONE,
  COB_BUSINESS_SET_PHYSICAL_ADDRESS,
  COB_BUSINESS_SET_POSTAL,
  COB_BUSINESS_SET_POSTAL_ADDRESS,
  COB_BUSINESS_SET_RECOVERY_EMAIL,
  COB_BUSINESS_SET_REGION,
  COB_BUSINESS_SET_SECTION_INDEX,
  COB_BUSINESS_SET_STAFF_COUNT,
  COB_BUSINESS_SET_STATE,
  COB_BUSINESS_SET_SUPPLIER_TRADING_NAME_ID,
  COB_BUSINESS_SHOW_WARNING,
  COB_BUSINESS_UNIQUE_EMAIL_ERROR,
  COB_BUSINESS_UNIQUE_EMAIL_START,
  COB_BUSINESS_UNIQUE_EMAIL_SUCCESS,
  COB_BUSINESS_UPDATE_KEY_CONTACT_PERCENTAGE,
  COB_BUSINESS_SET_ENTITY_BUSINESS_API_DETAILS,
} from '../constants/onboarding';

import { COB_GUARANTOR_RESET_TO_DEFAULT } from '../constants/guarantors';
import api from 'api';
import isGuarantorsRequiredForEntityType from 'utils/isGuarantorsRequiredForEntityType';
import { resetIdentityDetails } from 'modules/identity/actions';
import { BRANCHES_LOAD_END } from 'modules/addons/constants';

export function showWarning(warning) {
  return {
    type: COB_BUSINESS_SHOW_WARNING,
    payload: warning,
  };
}

export function businessComponentComplete(component, state) {
  return (dispatch, getState) => {
    let meta = {};

    if (state === true) {
      meta = {
        mixpanel: {
          event: 'Consumer onboarding',
          props: {
            distinct_id: getState().current_user.data.data.id,
            Component: component || 'start',
            Section: 'business',
          },
        },
      };
    }

    dispatch({
      type: COB_BUSINESS_COMPONENT_COMPLETE,
      payload: {
        component: component,
        state: state,
      },
      meta: meta,
    });
  };
}

export function updateKeyContactPercentageComplete(percent) {
  return {
    type: COB_BUSINESS_UPDATE_KEY_CONTACT_PERCENTAGE,
    payload: percent,
  };
}

export function setBusinessEntityLogo(dataUrl) {
  return {
    type: COB_BUSINESS_SET_ENTITY_LOGO,
    payload: dataUrl,
  };
}

export function setEntityPartyDetails(data) {
  return {
    type: COB_BUSINESS_SET_ENTITY_PARTY_DETAILS,
    payload: data,
  };
}

export function setEntityPartyDetailsIndex(index) {
  return {
    type: COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_INDEX,
    payload: index,
  };
}

export function setEntityPartyDetailsData(index, key, value) {
  let type;
  switch (key) {
    case 'first_name':
    case 'firstname':
      type = COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_FIRSTNAME;
      break;
    case 'last_name':
    case 'lastname':
      type = COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_LASTNAME;
      break;
    case 'email':
      type = COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMAIL;
      break;
    case 'percent':
      type = COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_SHARE;
      break;
    case 'previous_last_name':
      type = COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PREVIOUS_LASTNAME;
      break;
    case 'position':
      type = COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_POSITION;
      break;
    case 'residential_address':
      type = COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_ADDRESS;
      break;
    case 'residential_api_id':
      type = COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_API_ID;
      break;
    case 'residential_type':
      type = COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_TYPE;
      break;
    case 'employer':
      type = COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMPLOYER;
      break;
    case 'contact_phone_number':
      type = COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CONTACT_PHONE_NUMBER;
      break;
    case 'contact_country_code':
      type = COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CONTACT_COUNTRY_CODE;
      break;
    case 'person_postal_address':
      type = COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PERSON_POSTAL_ADDRESS;
      break;
    case 'person_postal_address_api_id':
      type = COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PERSON_POSTAL_ADDRESS_API_ID;
      break;
    case 'middle_name':
    case 'middlename':
      type = COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_MIDDLENAME;
      break;
  }

  return {
    payload: { index, name: key, value },
    type,
  };
}

export function toggleIsApplicant({ index, isApplicant }) {
  return (dispatch, getState) => {
    dispatch({
      payload: { index, name: 'is_applicant', value: isApplicant },
      type: COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_IS_APPLICANT,
    });

    const person = getState().cob_business.entity_party_details_values[index];
    resetIdentityDetails({
      dispatch,
      identity: getState().identity,
      isApplicant,
      person,
    });
  };
}

export function removeEntityPartyDetails(number) {
  return {
    type: COB_BUSINESS_REMOVE_ENTITY_PARTY_DETAILS,
    payload: number,
  };
}

export function setEntityFormValue(key, value) {
  let type;
  switch (key) {
    case 'application_type':
      type = COB_BUSINESS_SET_APPLICATION_TYPE;
      break;
    case 'entity_name':
      type = COB_BUSINESS_SET_ENTITY_NAME;
      break;
    case 'entity_staff_count':
      type = COB_BUSINESS_SET_STAFF_COUNT;
      break;
    case 'entity_phone':
      type = COB_BUSINESS_SET_PHONE;
      break;
    case 'entity_address':
      type = COB_BUSINESS_SET_ADDRESS;
      break;
    case 'entity_postal':
      type = COB_BUSINESS_SET_POSTAL;
      break;
    case 'entity_region':
      type = COB_BUSINESS_SET_REGION;
      break;
    case 'entity_state':
      type = COB_BUSINESS_SET_STATE;
      break;
    case 'entity_party_count':
      return selectEntityPartyCount(value);
    case 'registered_at':
      return {
        type: COB_BUSINESS_COMPANY_MANUAL_SET,
        payload: {
          registered_at: value,
        },
      };
    case 'entity_id':
      type = COB_BUSINESS_SET_ENTITY_ID;
      break;
    case 'supplier_trading_name_id':
      type = COB_BUSINESS_SET_SUPPLIER_TRADING_NAME_ID;
      break;
    case 'delivery_instruction':
      type = COB_BUSINESS_SET_DELIVERY_INSTRUCTION;
      break;
    case 'incorporated_number':
      type = COB_BUSINESS_SET_INCORPORATED_NUMBER;
      break;
    case 'legal_entity_agreement':
      type = COB_BUSINESS_SET_LEGAL_ENTITY_AGREEMENT;
      break;
  }

  return { payload: value, type };
}

export function selectEntityPartyCount(count) {
  return (dispatch, getState) => {
    dispatch({
      payload: count,
      type: COB_BUSINESS_SELECT_ENTITY_PARTY_COUNT,
    });

    const entityType = getState().cob_business.entity_type;
    const isGuarantorRequired = isGuarantorsRequiredForEntityType(
      entityType,
      count
    );

    // Reset guarantors if entity type does not require guarantors.
    // This is for an instance when a personal joint account is originally
    // selected which requires guarantors and possibly already persisted in
    // the database. Then switching it to a personal individual account which
    // doesn't require guarantors.
    if (!isGuarantorRequired) {
      dispatch({ type: COB_GUARANTOR_RESET_TO_DEFAULT });
    }
  };
}

export function setSectionIndex(index) {
  return {
    type: COB_BUSINESS_SET_SECTION_INDEX,
    payload: index,
  };
}

export function selectEntityType(type, title) {
  const meta = {
    mixpanel: {
      event: 'Consumer Onboarding: Set entity type',
      props: {
        selectedEntityType: type,
      },
    },
  };

  return {
    type: COB_BUSINESS_SELECT_ENTITY_TYPE,
    payload: {
      type: type,
      title: title,
    },
    meta,
  };
}

export function selectCompanyName(key_number) {
  return (dispatch, getState) => {
    var company_details = getState().cob_business.company_autosuggest_list[
      key_number
    ];
    dispatch({
      type: COB_BUSINESS_COMPANY_NAME_SELECT,
      payload: company_details,
    });
  };
}

export function setCompanyName(string) {
  return {
    type: COB_BUSINESS_COMPANY_SET_NAME,
    payload: string,
  };
}

export function manualSetCompany(key, value) {
  switch (key) {
    case 'company_name':
      return {
        type: COB_BUSINESS_COMPANY_MANUAL_SET,
        payload: {
          name: value,
        },
      };
    case 'company_number':
      return {
        type: COB_BUSINESS_COMPANY_MANUAL_SET,
        payload: {
          company_number: value,
        },
      };
    case 'business_number':
      return {
        type: COB_BUSINESS_COMPANY_MANUAL_SET,
        payload: {
          business_number: value,
        },
      };
    case 'has_entity_name_from_api':
      return {
        type: COB_BUSINESS_COMPANY_MANUAL_SET,
        payload: { hasEntityNameFromApi: value },
      };
    case 'registered_at':
      return {
        type: COB_BUSINESS_COMPANY_MANUAL_SET,
        payload: {
          registered_at: value,
        },
      };
    case 'trading_name':
      return {
        type: COB_BUSINESS_COMPANY_MANUAL_SET,
        payload: { tradingName: value },
      };
  }
}

export function lookupCompanyName(string, region) {
  return (dispatch, getState) => {
    // If the string is empty, then zero out the lookup list.
    if (string.length === 0) {
      dispatch({
        type: COB_BUSINESS_COMPANY_NAME_LOOKUP_CLEAR,
      });
    } else {
      dispatch({
        type: COB_BUSINESS_COMPANY_NAME_LOOKUP_START,
        payload: string,
      });

      var companySearch = api(
          'company_search',
          getState().current_user.access_token
        ),
        data = {};
      companySearch.companySearch(
        (result) => {
          for (var i = 0; i < result.data.data.length; i++) {
            var exists = false,
              applicant = false,
              supplier = false;

            if (result.data.data[i].hasOwnProperty('relationships')) {
              try {
                applicant =
                  result.data.data[i].relationships.entity.data.attributes
                    .applicant;
              } catch (e) {
                applicant = false;
              }

              try {
                supplier =
                  result.data.data[i].relationships.entity.data.attributes
                    .supplier;
              } catch (e) {
                supplier = false;
              }

              if (supplier === true || applicant === true) {
                exists = true;
              }
            }

            if (region === 'AU') {
              data[result.data.data[i].attributes.company_number] = {
                ...result.data.data[i].attributes,
                exists: exists,
                applicant: applicant,
                supplier: supplier,
              };
            } else {
              data[result.data.data[i].attributes.business_number] = {
                ...result.data.data[i].attributes,
                exists: exists,
                applicant: applicant,
                supplier: supplier,
              };
            }
          }
          dispatch({
            type: COB_BUSINESS_COMPANY_NAME_LOOKUP_SUCCESS,
            payload: data,
          });
        },
        (error) => {
          dispatch({
            type: COB_BUSINESS_COMPANY_NAME_LOOKUP_ERROR,
            payload: error,
          });
        },
        {
          params: {
            name: string,
            region: region,
          },
        }
      );
    }
  };
}

export function getCompanyDetails(business_number, region) {
  return (dispatch, getState) => {
    dispatch({
      type: COB_BUSINESS_COMPANY_DETAILS_START,
      payload: business_number,
    });

    var companySearch = api(
        'company_search',
        getState().current_user.access_token
      ),
      data = {};

    companySearch.companyDetails(
      business_number,
      (result) => {
        if (result.data.data.attributes) {
          data = result.data.data.attributes;
        }

        dispatch({
          type: COB_BUSINESS_COMPANY_DETAILS_SUCCESS,
          payload: data,
        });
      },
      (error) => {
        dispatch({
          type: COB_BUSINESS_COMPANY_DETAILS_ERROR,
          payload: error,
        });
      },
      {
        params: {
          region: region,
        },
      }
    );
  };
}

export function preSetCompanyDetails(data) {
  return {
    type: COB_BUSINESS_COMPANY_DETAILS_SUCCESS,
    payload: data,
  };
}

export function setDirectorsContainerIndex(index) {
  return {
    type: COB_BUSINESS_SET_DIRECTORS_CONTAINER_INDEX,
    payload: index,
  };
}

export function setKeyContactFormValue(key, value) {
  var type;
  switch (key) {
    case 'key_contact_first_name':
      type = COB_BUSINESS_SET_KEY_CONTACT_FIRST_NAME;
      break;
    case 'key_contact_last_name':
      type = COB_BUSINESS_SET_KEY_CONTACT_LAST_NAME;
      break;
    case 'key_contact_phone':
      type = COB_BUSINESS_SET_KEY_CONTACT_PHONE;
      break;
    case 'key_contact_country_code':
      type = COB_BUSINESS_SET_KEY_CONTACT_COUNTRY_CODE;
      break;
    case 'key_contact_email':
      type = COB_BUSINESS_SET_KEY_CONTACT_EMAIL;
      break;
    case 'key_contact_password':
      type = COB_BUSINESS_SET_KEY_CONTACT_PASSWORD;
      break;
  }
  return {
    type: type,
    payload: value,
  };
}

export function preSetPostalAddress(address) {
  return {
    type: COB_BUSINESS_PRESET_POSTAL_ADDRESS,
    payload: address,
  };
}

export function preSetPhysicalAddress(address) {
  return {
    type: COB_BUSINESS_PRESET_PHYSICAL_ADDRESS,
    payload: address,
  };
}

export function setPostalAddress(value) {
  return {
    type: COB_BUSINESS_SET_POSTAL_ADDRESS,
    payload: value,
  };
}

export function setPhysicalAddress(value) {
  return {
    type: COB_BUSINESS_SET_PHYSICAL_ADDRESS,
    payload: value,
  };
}

export function selectPhysicalAddress(api_id) {
  return {
    type: COB_BUSINESS_SELECT_PHYSICAL_ADDRESS,
    payload: api_id,
  };
}

export function selectPostalAddress(api_id) {
  return {
    type: COB_BUSINESS_SELECT_POSTAL_ADDRESS,
    payload: api_id,
  };
}

const addressConstants = {
  Delivery: {
    clear: COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_CLEAR,
    error: COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_ERROR,
    start: COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_START,
    success: COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_SUCCESS,
  },
  Physical: {
    clear: COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_CLEAR,
    error: COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_ERROR,
    start: COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_START,
    success: COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_SUCCESS,
  },
  Postal: {
    clear: COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_CLEAR,
    error: COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_ERROR,
    start: COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_START,
    success: COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_SUCCESS,
  },
};

export function lookupAddress(string, type, region) {
  const constants = addressConstants[type];

  return (dispatch, getState) => {
    // If the string is empty, then zero out the lookup list.
    if (string.length === 0) {
      dispatch({ type: constants.clear });
      return;
    }

    dispatch({
      payload: string,
      type: constants.start,
    });

    const addressSearch = api(
      'address_search',
      getState().current_user.access_token
    );
    let data = {};
    const rawData = {};
    const dropdownData = {};

    addressSearch.addressSearch(
      (result) => {
        for (let i = 0; i < result.data.data.length; i++) {
          const address = result.data.data[i];
          rawData[address.api_id] = address;
          dropdownData[address.api_id] = address.full_address;
        }

        data = { dropdownData, rawData };

        dispatch({
          payload: data,
          type: constants.success,
        });
      },
      (error) => {
        dispatch({
          payload: error,
          type: constants.error,
        });
      },
      {
        params: {
          address: string,
          region, // TODO: Replace with data from region drop down
          type,
        },
      }
    );
  };
}

export function checkEmailisUnique(email_address, success_callback) {
  return (dispatch, getState) => {
    var email = api('user_exists_email', getState().current_user.access_token);

    dispatch({
      type: COB_BUSINESS_UNIQUE_EMAIL_START,
    });

    email.checkEmailValid(
      email_address,
      (success) => {
        dispatch({
          type: COB_BUSINESS_UNIQUE_EMAIL_SUCCESS,
          payload: success.data.data.attributes,
        });

        if (success_callback) {
          success_callback();
        }
      },
      (error) => {
        dispatch({
          type: COB_BUSINESS_UNIQUE_EMAIL_ERROR,
          payload: error,
        });
      }
    );
  };
}

//
// When a supplier is filling out their very first application,
//   Set initial values
//
export function setDefaultEntityDetails() {
  return (dispatch, getState) => {
    let section = getState().cob_section || {},
      business = getState().cob_business || {},
      currentEntity = section.current_entity || {};

    if (Object.keys(currentEntity).length === 0) return;

    let attributes = currentEntity.attributes;

    if (!attributes.supplier) return;

    let data = {
      entity_name: business.entity_name || attributes.trading_name || '',
      entity_phone: business.entity_phone || attributes.contact_phone_number,
      entity_region: attributes.region,
      entity_state: business.entity_state || attributes.state,
      key_contact_first_name:
        business.key_contact_first_name || attributes.key_contact_first_name,
      key_contact_last_name:
        business.key_contact_last_name || attributes.key_contact_last_name,
      key_contact_email:
        business.key_contact_email || attributes.key_contact_email,
      key_contact_phone:
        business.key_contact_phone || attributes.key_contact_phone,
      key_contact_country_code:
        business.key_contact_country_code ||
        attributes.key_contact_country_code,
    };

    dispatch({
      type: COB_BUSINESS_SET_DEFAULT_ENTITY_DETAILS,
      payload: data,
    });
  };
}

export function websitebuttonBusinessComponentComplete(component, state) {
  return (dispatch, getState) => {
    let meta = {};

    if (state === true) {
      meta = {
        mixpanel: {
          event: 'Consumer onboarding',
          props: {
            Component: component || 'start',
            Section: 'business',
          },
        },
      };
    }

    dispatch({
      type: COB_BUSINESS_COMPONENT_COMPLETE,
      payload: {
        component: component,
        state: state,
      },
      meta: meta,
    });
  };
}

export function setEntityRecoveryEmail(email) {
  return {
    type: COB_BUSINESS_SET_RECOVERY_EMAIL,
    payload: email,
  };
}

export function lookupResidentialAddress(index, string, region) {
  return (dispatch, getState) => {
    // If the string is empty, then zero out the lookup list.
    if (string.length === 0) {
      dispatch({
        type: COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_CLEAR,
      });
    } else {
      dispatch({
        type: COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_START,
        payload: {
          index: index,
        },
      });

      var addressSearch = api(
          'address_search',
          getState().current_user.access_token
        ),
        data = {},
        rawData = {},
        dropdownData = {};

      //
      // Region here is just for priority in address list
      //
      let entityRegion = (getState().cob_business || {}).entity_region || 'NZ';

      addressSearch.addressSearch(
        (result) => {
          for (var i = 0; i < result.data.data.length; i++) {
            let address = result.data.data[i];

            rawData[address.api_id] = address;
            dropdownData[address.api_id] = address.full_address;
          }

          data = {
            index: index,
            rawData: rawData,
            dropdownData: dropdownData,
          };

          dispatch({
            type: COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_SUCCESS,
            payload: data,
          });
        },
        (error) => {
          dispatch({
            type: COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_ERROR,
            payload: {
              index: index,
              error,
            },
          });
        },
        {
          params: {
            address: string,
            type: 'Physical',
            region: entityRegion,
          },
        }
      );
    }
  };
}

export function lookupPersonPostalAddress(index, string, region) {
  return (dispatch, getState) => {
    // If the string is empty, then zero out the lookup list.
    if (string.length === 0) {
      dispatch({
        type: COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_CLEAR,
      });
    } else {
      dispatch({
        payload: { index },
        type: COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_START,
      });

      const addressSearch = api(
        'address_search',
        getState().current_user.access_token
      );
      const rawData = {};
      const dropdownData = {};

      let data = {};

      //
      // Region here is just for priority in address list
      //
      const entityRegion =
        (getState().cob_business || {}).entity_region || 'NZ';

      addressSearch.addressSearch(
        (result) => {
          for (let i = 0; i < result.data.data.length; i++) {
            const address = result.data.data[i];

            rawData[address.api_id] = address;
            dropdownData[address.api_id] = address.full_address;
          }

          data = { dropdownData, index, rawData };

          dispatch({
            payload: data,
            type: COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_SUCCESS,
          });
        },
        (error) => {
          dispatch({
            payload: { error, index },
            type: COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_ERROR,
          });
        },
        {
          params: {
            address: string,
            region: entityRegion,
            type: 'Postal',
          },
        }
      );
    }
  };
}

export function setCompanyDetails(key_number) {
  return (dispatch, getState) => {
    var company_details = getState().cob_business.company_autosuggest_list[
      key_number
    ];
    dispatch({
      type: COB_BUSINESS_COMPANY_DETAILS_SUCCESS,
      payload: company_details,
    });
  };
}

export function uploadLegalEntityAgreement(legalEntityAgreement) {
  return {
    payload: legalEntityAgreement,
    type: COB_BUSINESS_SET_LEGAL_ENTITY_AGREEMENT,
  };
}

export function setBusinessApiDetails(details) {
  return {
    type: COB_BUSINESS_SET_ENTITY_BUSINESS_API_DETAILS,
    payload: details,
  };
}

export function clearBusinessApiDetails() {
  return (dispatch) => dispatch(setBusinessApiDetails({}));
}
