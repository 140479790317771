import React from 'react';

import './css/Switch3.scss';

interface Switch3 {
  isDisabled?: boolean;
  label?: string;
  value?: boolean;
  onClick?: (newValue) => void;
}

const Switch3 = ({ isDisabled, label, value, onClick }: Switch3) => {
  const [checked, setChecked] = React.useState(value);

  React.useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleChange = () => {
    setChecked(!checked);
    if (onClick) onClick(!checked);
  };

  return (
    <div className="toggle-container">
      <div>
        <input
          onClick={handleChange}
          checked={checked}
          type="checkbox"
          name="toggle"
          className="toggle-input"
          id="toggle"
          disabled={isDisabled}
        />
        <label htmlFor="toggle"></label>
      </div>
      {label && <span className="toggle-label">{label}</span>}
    </div>
  );
};

Switch3.defaultProps = {
  isDisabled: false,
};

export default Switch3;
