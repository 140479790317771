import {
  accountType,
  paymentStatus,
  reportCode,
  role,
} from './helpers/codeTable';
import { amountTypeValue, countTypeValue } from './helpers/summary';
import { displayEmpty, formatMoney } from 'utils/formatting';
import { enquiryResponseData, isBusiness } from './helpers/reportHelpers';

import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { stringToSimpleDate } from 'utils/dateFormatter';
import styles from '../../../css/CommonReviewBusiness.css';

var MercantileAgentEnquiries = createClass({
  _creditEnquiries() {
    const { data, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      mercantileEnquiryList =
        response.organisation_credit_history.mercantile_enquiry_lisy || {},
      mercantileEnquiries = mercantileEnquiryList.mercantile_enquiries || [];

    if (mercantileEnquiries.constructor === Object)
      mercantileEnquiries = [mercantileEnquiries];

    return mercantileEnquiries.map((enquiry, index) => {
      return (
        <div className={styles.table_wrapper} key={index}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Account Type</th>
                <td>{accountType((enquiry.account_type || {}).type)}</td>
                <th>Collection Status</th>
                <td>{paymentStatus((enquiry.payment_status || {}).type)}</td>
              </tr>
              <tr>
                <th>Role in Enquiry</th>
                <td>{role((enquiry.role || {}).type)}</td>
                <th>Collection Status Date</th>
                <td>{stringToSimpleDate(enquiry.status_date)}</td>
              </tr>
              <tr>
                <th>Co-Borrower</th>
                <td>{displayEmpty(enquiry.co_borrower)}</td>
                <th>Collection Enquirer</th>
                <td>{displayEmpty(enquiry.enquirer)}</td>
              </tr>
              <tr>
                <th>Latest Amount</th>
                <td>${formatMoney(enquiry.amount)}</td>
                <th>Collection Date</th>
                <td>{stringToSimpleDate(enquiry.coll_date)}</td>
              </tr>
              <tr>
                <th>Client's Name</th>
                <td>{displayEmpty(enquiry.client_name)}</td>
                <th>Reason to Report</th>
                <td>{reportCode((enquiry.report_reason || {}).type)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  },

  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    return (
      <ReviewContainer
        subHeading="Mercantile Agent Enquiries"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <p>
          <strong>
            Total: {countTypeValue(summaryEntry, 'Mercantile_Agent')}
          </strong>
        </p>
        <p>
          <strong>
            Amount:{' '}
            {amountTypeValue(summaryEntry, 'Mercantile_Enquiries_Value')}
          </strong>
        </p>
        {this._creditEnquiries()}
      </ReviewContainer>
    );
  },
});

export default MercantileAgentEnquiries;
