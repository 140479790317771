/* eslint-disable max-lines */
import { makeStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import CancelIcon from '@material-ui/icons/Cancel';
import AddonConfigModel from 'models/AddonConfigModel';
import { PENDING_STATUS } from 'modules/new-applications/components/Application';
import {
  AUTO_DECISIONS_OPTIONS,
  ENTITY_TYPE_OPTIONS,
  LEAD_ENTITY_TYPE_OPTIONS,
  LOADED_IN_SYSTEM_OPTIONS,
  SALES_CHANNEL_OPTIONS,
} from 'modules/reporting/constants';
import BorderedAutocomplete from 'modules/shared/components/inputs/BorderedAutocomplete';
import BorderedPeriodSelect from 'modules/shared/components/inputs/BorderedPeriodSelect';
import BorderedRegionStatesSelect from 'modules/shared/components/inputs/BorderedRegionStatesSelect';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import Switch from 'modules/shared/components/widgets/interactive/Switch';
import { isHeadquarter } from 'modules/shared/helpers/headquarterDetect';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

import styles from './css/useFilterState.css';

export const drawerStyles = makeStyles(() => {
  return {
    paper: {
      paddingTop: '66px',
      width: '270px',
    },
  };
});

const INITIATOR_HQ_OPTIONS = [
  { label: 'All', value: 'All' },
  { label: 'Website channel', value: 'website_button' },
  { label: 'Location channel', value: 'qr_code' },
  { label: 'Rep channel', value: 'supplier_user' },
];

const APPLICATION_TYPE_OPTIONS = [
  { label: 'All', value: 'All' },
  { label: 'Credit', value: 'credit' },
  { label: 'Cash', value: 'cash' },
];

function AutocompleteFilter(props) {
  const { label, name, options, onChangeFilterParams, value } = props;
  const selectedOption = options.find((option) => option.value === value);

  const onChange = (_, selected) => {
    onChangeFilterParams({ [name]: selected.value });
  };

  return (
    <BorderedAutocomplete
      textFieldProps={{
        label,
      }}
      options={options}
      value={selectedOption}
      onChange={onChange}
    />
  );
}

export function Filter(props) {
  const {
    filterParams,
    isStatesOptionsVisible,
    onChangeFilterParams,
    name,
  } = props;
  const { label, options, rawOptions, type, visible } = props.filterConfig;

  if (!visible) {
    return <Fragment />;
  }

  switch (type) {
    case 'autocomplete':
      return (
        <AutocompleteFilter
          label={label}
          name={name}
          onChangeFilterParams={onChangeFilterParams}
          options={options}
          value={filterParams[name]}
        />
      );
    case 'dropdown':
      return (
        <BorderedSelect
          label={label}
          name={name}
          value={filterParams[name]}
          options={options}
          rawOptions={rawOptions}
          onChange={(res) => onChangeFilterParams({ [name]: res.target.value })}
          MenuProps={{
            style: {
              maxHeight: 300,
            },
          }}
        />
      );
    case 'date_picker':
      return (
        <BorderedPeriodSelect
          label={label}
          name={name}
          MenuProps={{
            style: {
              maxHeight: 300,
            },
          }}
          periodValue={filterParams[name]}
          isFutureDate={props.filterConfig.isFutureDate}
          periodFromToValues={{
            from: filterParams[`${name}_from`],
            to: filterParams[`${name}_to`],
          }}
          handleChange={(res) => {
            onChangeFilterParams({
              [name]: res.value,
              [`${name}_from`]: res.from,
              [`${name}_to`]: res.to,
            });
          }}
        />
      );
    case 'input':
      return (
        <BorderedTextField
          name={name}
          label={label}
          value={filterParams[name]}
          onChange={(event) =>
            onChangeFilterParams({ [name]: event.target.value })
          }
        />
      );
    case 'region_select':
      return (
        <BorderedRegionStatesSelect
          label={'Business location'}
          values={{ region: filterParams.region, state: filterParams.state }}
          isStatesOptionsVisible={isStatesOptionsVisible}
          handleChange={(res) => onChangeFilterParams(res)}
        />
      );
  }
}

function LimitSwitch(props) {
  const { filterParams, filterType, onChangeFilterParams } = props;

  if (filterType === 'application') {
    return (
      <Switch
        leftOption="requested_limits"
        rightOption="approved_limits"
        leftLabel="Requested limits"
        rightLabel="Approved limits"
        current={filterParams.limit_type}
        handleChange={(value) => onChangeFilterParams({ limit_type: value })}
        grey={true}
      />
    );
  }

  return null;
}

function LimitRange(props) {
  const { filterParams, filterType, onChangeFilterParams } = props;

  if (filterType === 'application') {
    return (
      <div className={styles.limits}>
        <span className={styles.limit_text}>Limit range</span>
        <div className={styles.limit_inputs}>
          <BorderedTextField
            label={'min'}
            value={filterParams.limit_min}
            onChange={(event) =>
              onChangeFilterParams({ limit_min: event.target.value })
            }
          />
        </div>
        <span className={styles.limit_text}>to</span>
        <div className={styles.limit_inputs}>
          <BorderedTextField
            label={'max'}
            value={filterParams.limit_max}
            onChange={(event) =>
              onChangeFilterParams({ limit_max: event.target.value })
            }
          />
        </div>
      </div>
    );
  }

  return null;
}

function FilterList(props) {
  const {
    dynamicFilterOptions,
    filterParams,
    filterType,
    isOpen,
    isStatesOptionsVisible,
    onChangeFilterParams,
    onToggleIsOpen,
    // TODO - We will refactor the rest of the filters eventually to adapt passFilter
    passedFilters,
    reset,
    isCallCentreActive,
  } = props;

  let [isSalesChannelSelected, setIsSalesChannelSelected] = useState(false);

  function getFiltersByType({
    alertTypeOptions,
    approverOptions,
    branchOptions,
    filterType,
    pendingStatusOptions,
    untaggedApplicationOptions,
    userOptions,
    isCallCentreActive,
    callCentreUsers,
  }) {
    if (filterType === 'application') {
      const result = [];
      result.push({
        key: 'start_date',
        label: 'Start date',
        type: 'date_picker',
        visible: true,
      });
      result.push({
        key: 'pending_state',
        label: 'Pending status',
        options: pendingStatusOptions,
        type: 'dropdown',
        visible: true,
      });
      result.push({
        key: 'branch',
        label: 'Tier',
        options: branchOptions,
        type: 'dropdown',
        visible: isHeadquarter(),
      });
      result.push({
        key: 'initiator_hq',
        label: 'Initiated by',
        options: INITIATOR_HQ_OPTIONS,
        type: 'dropdown',
        visible: isHeadquarter(),
      });
      result.push({
        key: 'owner',
        label: 'Owner',
        options: userOptions,
        type: 'dropdown',
        visible: !isHeadquarter(),
      });
      result.push({
        key: 'approver',
        label: 'Approver',
        options: approverOptions,
        type: 'dropdown',
        visible: true,
      });
      result.push({
        key: 'auto_decision',
        label: 'Auto decisions',
        options: AUTO_DECISIONS_OPTIONS,
        type: 'dropdown',
        visible: true,
      });
      result.push({
        key: 'sales_channel',
        label: 'Sales channels',
        options: SALES_CHANNEL_OPTIONS,
        type: 'dropdown',
        visible: isCallCentreActive,
        onChangeFilterParams: (value) => {
          setIsSalesChannelSelected(
            value.sales_channel === 'call_centre_channel'
          );
          onChangeFilterParams(value);
        },
      });
      result.push({
        key: 'call_centre_user',
        label: 'Call centre user',
        options: callCentreUsers,
        type: 'dropdown',
        visible: isSalesChannelSelected,
      });
      result.push({
        key: 'untagged',
        label: 'Untagged application',
        options: untaggedApplicationOptions,
        type: 'dropdown',
        visible: true,
      });
      result.push({
        isFutureDate: true,
        key: 'review_date',
        label: 'Review date',
        type: 'date_picker',
        visible: true,
      });
      result.push({
        key: 'application_type',
        label: 'Account type',
        options: APPLICATION_TYPE_OPTIONS,
        type: 'dropdown',
        visible: true,
      });
      result.push({
        key: 'entity_type',
        label: 'Entity type',
        options: ENTITY_TYPE_OPTIONS,
        type: 'dropdown',
        visible: true,
      });
      result.push({
        key: 'location',
        label: 'Business location',
        type: 'region_select',
        visible: true,
      });
      result.push({
        key: 'loaded_in_system',
        label: 'Loaded in system',
        options: LOADED_IN_SYSTEM_OPTIONS,
        type: 'dropdown',
        visible: true,
      });
      return result;
    }

    if (filterType === 'lead') {
      return [
        {
          key: 'start_date',
          label: 'Start date',
          type: 'date_picker',
          visible: true,
        },
        {
          key: isHeadquarter() ? 'branch' : 'initiator',
          label: 'Initiated by',
          options: isHeadquarter() ? branchOptions : userOptions,
          type: 'dropdown',
          visible: true,
        },
        {
          key: 'entity_type',
          label: 'Entity type',
          options: LEAD_ENTITY_TYPE_OPTIONS,
          type: 'dropdown',
          visible: true,
        },
        {
          key: 'location',
          label: 'Business location',
          type: 'region_select',
          visible: true,
        },
      ];
    }

    // TODO - Refer to task/7277 to utilize passedFilters props
    // instead adding more conditional rendering statements
    if (filterType === 'alerts') {
      return [
        {
          key: 'alert_type',
          label: 'Alert',
          options: alertTypeOptions,
          type: 'dropdown',
          visible: true,
        },
        {
          key: 'alert_date',
          label: 'Alert date',
          type: 'date_picker',
          visible: true,
        },
      ];
    }

    return [];
  }

  const approverOptions = [{ label: 'All', value: 'All' }].concat(
    dynamicFilterOptions.approver_options || []
  );

  const branchOptions = [{ label: 'All', value: 'All' }].concat(
    dynamicFilterOptions.branch_options || []
  );

  const untaggedApplicationOptions = [{ label: 'All', value: 'All' }].concat(
    dynamicFilterOptions.approval_hierarchy_levels || []
  );

  const alertTypeOptions = [{ label: 'All', value: 'All' }].concat(
    dynamicFilterOptions.alert_type || []
  );

  const pendingStatusOptions = [
    { label: 'All', value: 'All' },
    { label: 'No pending status', value: 'no_pending' },
    ...PENDING_STATUS,
  ];

  const userOptions = [{ label: 'All', value: 'All' }].concat(
    dynamicFilterOptions.user_options || []
  );

  const callCentreUsers = [{ label: 'All', value: 'All' }].concat(
    dynamicFilterOptions.call_centre_user_options || []
  );

  const filters =
    passedFilters ||
    getFiltersByType({
      alertTypeOptions,
      approverOptions,
      branchOptions,
      filterType,
      isCallCentreActive,
      pendingStatusOptions,
      untaggedApplicationOptions,
      userOptions,
      callCentreUsers,
    });

  return (
    <Drawer
      anchor={'right'}
      open={isOpen}
      variant="persistent"
      classes={drawerStyles()}
    >
      <div className={styles.header}>
        <CancelIcon className={styles.cancel_icon} onClick={onToggleIsOpen} />
        <div className={styles.reset_button} onClick={reset}>
          reset
        </div>
      </div>
      <div className={styles.filters}>
        <LimitSwitch
          filterParams={filterParams}
          filterType={filterType}
          onChangeFilterParams={onChangeFilterParams}
        />
        <LimitRange
          filterParams={filterParams}
          filterType={filterType}
          onChangeFilterParams={onChangeFilterParams}
        />
        {filters.map((filter) => (
          <Filter
            key={filter.key}
            name={filter.key}
            filterConfig={filter}
            filterParams={filterParams}
            isStatesOptionsVisible={isStatesOptionsVisible}
            onChangeFilterParams={
              filter.onChangeFilterParams || onChangeFilterParams
            }
          />
        ))}
      </div>
    </Drawer>
  );
}

FilterList.defaultProps = {
  filterType: 'application',
  isStatesOptionsVisible: true,
};

FilterList.propTypes = {
  filterType: PropTypes.oneOf(['application', 'lead', 'alerts']),
};

const defaultValues = {
  application_type: 'All',
  approver: 'All',
  auto_decision: 'All',
  branch: 'All',
  collateral_type: 'All',
  entity_type: 'All',
  expiry_date: 'All',
  initiator: 'All',
  initiator_hq: 'All',
  limit_max: '',
  limit_min: '',
  limit_type: 'requested_limits',
  loaded_in_system: 'All',
  owner: 'All',
  pending_state: 'All',
  region: 'All',
  review_date: 'All',
  start_date: 'All',
  state: 'All',
  untagged: 'All',
  sales_channel: 'All',
  call_centre_user: 'All',
};

const defaultAlertsValues = {
  alert_date: 'All',
  alert_type: 'All',
  status: 'started',
};

// TODO: This file needs to be updated to use consider
// different defaultValues from different type of index
function getDefaultValues(filterType) {
  if (filterType === 'alerts') {
    return defaultAlertsValues;
  }

  return defaultValues;
}

const validKeys = [
  'start_date_from',
  'start_date_to',
  'review_date_from',
  'review_date_to',
  ...Object.keys(defaultValues),
];

function getValidKeys(filterType) {
  if (filterType === 'alerts') {
    return [...Object.keys(defaultAlertsValues)];
  }

  return validKeys;
}

const keysFilter = (params) => {
  const res = {};
  Object.keys(params).forEach((key) => {
    if (getValidKeys(params.source_page).includes(key)) {
      res[key] = params[key];
    }
  });
  return res;
};

export default function useFilterState(searchParams, options = {}) {
  const [isOpen, setIsOpen] = useState(false);
  const [filterParams, setFilterParams] = useState({
    ...getDefaultValues(searchParams.source_page),
    ...keysFilter(searchParams),
  });
  const { isCallCentreActive } = options;

  const onToggleIsOpen = () => setIsOpen(!isOpen);
  const onChangeFilterParams = (params) => {
    let newParams = { ...filterParams, ...params, page: 1 };
    // reset call centre user if sales channel is not call centre
    if (
      newParams.call_centre_user !== 'All' &&
      newParams.sales_channel !== 'call_centre_channel'
    ) {
      newParams = { ...newParams, call_centre_user: 'All' };
    }
    setFilterParams(newParams);
  };

  const resetFilterParams = () => {
    setFilterParams(getDefaultValues(searchParams.source_page));
  };

  return {
    filter: (
      <FilterList
        isOpen={isOpen}
        reset={resetFilterParams}
        onChangeFilterParams={onChangeFilterParams}
        onToggleIsOpen={onToggleIsOpen}
        filterParams={filterParams}
        isCallCentreActive={isCallCentreActive}
      />
    ),
    filterParams,
    isOpen,
    onToggleIsOpen,
  };
}
