import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  createAddonVersion,
  loadWebsiteAddon,
  updateAddonRuleset,
} from 'modules/addons/actions';

import extractAttachmentUrl from 'utils/extractAttachmentUrl';
import AddonConfigModel from 'models/AddonConfigModel';
import UserModel from 'models/UserModel';
import SideMenuPanel from 'containers/next-gen/components/shared/Panel';
import PanelSection from 'containers/next-gen/components/shared/Section';
import CheckboxGroup from 'modules/addons/sales_channels/shared/CheckboxGroup';
import EmbeddedComponents from 'modules/addons/sales_channels/shared/EmbeddedComponents';
import Switch3 from 'modules/shared/components/widgets/interactive/Switch3';
import Button from 'modules/shared/components/inputs/Button';
import HistoryPanelSection from 'containers/next-gen/components/shared/HistorySection';
import ConfirmationModal from 'modules/addons/sales_channels/shared/ConfirmationModal';
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import './website-channel-content.scss';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';

type ApplicationTypes = string[];

type AddonAttributes = {
  addon_config_id?: string;
  addon_module_name?: string;
  application_types: ApplicationTypes;
  config: {};
  name?: string;
};

type NewChanges = {
  application_types: ApplicationTypes;
  active?: boolean;
};

const addon_module_name = 'website_button';

const checkboxOptions = {
  cash: { label: 'Cash application' },
  credit: { label: 'Credit application' },
};

const instructionsText = `Turning on your website channel will enable you to embed the below link onto any customised image on your website,
which once clicked will take your customer to your application workflow. Embedding the 'Button Widget' will display
the preset 'Start application' per the below page.`;

const applicationText = 'Apply website channel to the application types below.';

const instructionSet = {
  main: 'Follow the below instructions to set up your website channel.',
  steps: [
    'Copy the relevant embed link from the box below',
    "Log into your website editor, find the section where you can edit your site's HTML",
    'Paste the link into the relevant section for your website, save and publish your changes',
    'Refresh your website',
  ],
};

function initializeAddonModule() {
  return new AddonConfigModel({
    attributes: { addon_module_name },
  });
}

function getNewVersionAttribute(addonConfig, data: ApplicationTypes) {
  const attributes: AddonAttributes = {
    application_types: data,
    config: {},
  };

  if (addonConfig.isPersisted) {
    attributes.addon_config_id = addonConfig.id;
  } else {
    attributes.name = 'Website button';
    attributes.addon_module_name = addon_module_name;
  }

  return attributes;
}

const WebsiteChannelContent = ({
  applicationType,
  companyName,
  currentUser,
  dispatch,
  entityId,
  isReadonly,
  logo,
  websiteButtonActive,
  websiteButtonData,
  websiteButtonHistory,
}) => {
  const processedCurrentUser = UserModel.fromCurrentUser(currentUser);
  const [addonConfig, setAddonConfig] = React.useState(initializeAddonModule());

  const trackMixPanel = (eventName) => {
    mixpanel.track(eventName, {
      'Entity ID': entityId,
      distinct_id: get(currentUser, 'data.data.id'),
    });
  };

  useEffect(() => {
    setAddonConfig(websiteButtonData);
  }, [websiteButtonData]);

  useEffect(() => {
    trackMixPanel('Sales channels - Website channel');

    dispatch(loadWebsiteAddon());
  }, []);

  const websiteButtonLink = `${window.location.origin}/onboarding?supplier_id=${entityId}`;

  const [withChanges, setWithChanges] = React.useState(false);
  const [active, setActive] = React.useState(websiteButtonActive);
  const [newChanges, setNewChanges] = React.useState({} as NewChanges);
  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    setActive(websiteButtonActive);
  }, [websiteButtonActive]);

  const onErrorCallback = (error) => {
    console.error(error);
  };

  const reloadData = () => {
    // We could remove this if we could make the response of the other two api calls above same with this.
    dispatch(loadWebsiteAddon());

    setWithChanges(false);
    setShowModal(false);
  };

  const saveChanges = (changes?: any) => {
    const newVersion = addonConfig.generateNewVersion();
    const attributes = getNewVersionAttribute(
      addonConfig,
      changes || newChanges.application_types
    );
    newVersion.setAttributes(attributes);
    newVersion.save({
      addonConfig,
      currentUser: processedCurrentUser,
      onSuccessCallback: reloadData,
      onErrorCallback,
    });
  };

  const handleSwitchChange = (newValue) => {
    trackMixPanel(
      `Sales channels - Website channel - Toggle ${newValue ? 'on' : 'off'}`
    );
    setActive(newValue);
    setWithChanges(true);
    setShowModal(true);
  };

  const confirmModal = () => {
    const updateCheckbox = () => {
      // We need to force the following values to enable/disable the form
      if (active) {
        saveChanges(['credit']);
      } else {
        saveChanges([]);
      }
    };

    if (websiteButtonData.id) {
      dispatch(
        updateAddonRuleset(
          websiteButtonData.id,
          websiteButtonData.attributes,
          addon_module_name,
          updateCheckbox
        )
      );
    } else {
      dispatch(
        createAddonVersion(
          getNewVersionAttribute(initializeAddonModule(), ['credit']),
          addon_module_name,
          reloadData
        )
      );
    }

    trackMixPanel('Sales channels - Website channel - Saved changes');
  };

  const cancelCloseModal = () => {
    setActive(!active);
    setWithChanges(false);
    setShowModal(false);
  };

  const handleCheckboxChange = (newStatus) => {
    setNewChanges((changes) => ({
      ...changes,
      application_types: newStatus as ApplicationTypes,
    }));
    setWithChanges(true);
  };

  return (
    <SideMenuPanel>
      <PanelSection>
        <ConfirmationModal
          companyName={companyName}
          isActive={active}
          showModal={showModal}
          cancelCloseModal={cancelCloseModal}
          confirmModal={confirmModal}
          type={addon_module_name}
        />
        {instructionsText}
        <div className="switch-container">
          <Switch3
            label={active ? 'On' : 'Off'}
            value={active}
            onClick={handleSwitchChange}
            isDisabled={isReadonly}
          />
        </div>
      </PanelSection>
      <PanelSection>
        {applicationText}
        <CheckboxGroup
          options={checkboxOptions}
          passedValue={applicationType}
          handleChange={handleCheckboxChange}
          disabled={isReadonly || !active}
        />
      </PanelSection>
      {!isReadonly && (
        <>
          <PanelSection>
            {instructionSet.main}
            <ul className="integration-steps">
              {instructionSet.steps.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <div className="integration-components-container">
              <EmbeddedComponents.RawLink
                currentUser={currentUser}
                link={websiteButtonLink}
                title="Link"
              />
              <EmbeddedComponents.ButtonComponent
                currentUser={currentUser}
                link={websiteButtonLink}
                logo={logo}
                text="Start application"
                title="Button widget"
              />
            </div>
            <Button
              type="button"
              text="Save"
              disabled={isReadonly || (!active && !withChanges) || !withChanges}
              onClick={() => saveChanges()}
            />
          </PanelSection>
          <HistoryPanelSection
            historyList={websiteButtonHistory}
            historyCount={5}
          />
        </>
      )}
    </SideMenuPanel>
  );
};

export default connect((state) => {
  const currentUser = get(state, 'current_user', {});
  const entityId = get(currentUser, 'current_entity.id');
  const companyName = get(
    currentUser,
    'current_entity.attributes.company_name'
  );
  const websiteButtonData = get(state, 'add_ons.website_button');
  const websiteButtonHistory = get(
    websiteButtonData,
    'attributes.history_version.data',
    []
  );
  const websiteButtonActive = get(
    websiteButtonData,
    'attributes.active',
    false
  );
  const applicationType = get(
    websiteButtonHistory[0],
    'attributes.application_types',
    []
  );

  const logo = extractAttachmentUrl(
    state.current_user.current_entity.attributes.logo,
    'logo'
  );

  return {
    applicationType,
    companyName,
    currentUser,
    entityId,
    isReadonly: !isFeatureEditEnabled('WebsiteButton'),
    logo,
    websiteButtonActive,
    websiteButtonData,
    websiteButtonHistory,
  };
})(WebsiteChannelContent);
