import get from 'lodash.get';
import ModuleCardholderAuthorisationFlowModel from 'models/ModuleCardholderAuthorisationFlowModel';
import commonStyles from 'modules/card-management-authorisation/css/Section.css';
import Button from 'modules/shared/components/inputs/Button';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import React, { useEffect } from 'react';
import isBlank from 'utils/isBlank';

function formatAnswer(answer) {
  if (isBlank(answer)) {
    return '-';
  }

  if (Array.isArray(answer)) {
    return answer.join(', ');
  }

  if (Object.keys(answer).includes('main')) {
    return [`${answer.main} (main option)`, ...answer.other].join(', ');
  }

  return answer;
}

export default function Abstract(props) {
  const {
    cardholderAuthorisation,
    cardholderAuthorisationId,
    setCardholderAuthorisation,
  } = props;

  useEffect(() => {
    (async () => {
      const newCardholderAuthorisation = new ModuleCardholderAuthorisationFlowModel();
      await newCardholderAuthorisation.load(cardholderAuthorisationId);

      setCardholderAuthorisation(newCardholderAuthorisation);
    })();
  }, []);

  const toDecline = () => {
    cardholderAuthorisation.setAttributes({ approved: false });
    (async () => {
      const result = await cardholderAuthorisation.saveDecision(
        cardholderAuthorisationId
      );
      if (result.status === 200) props.toLastSection();
    })();
  };

  if (cardholderAuthorisation.attributes.reviwed) {
    return <div>This cardholder authorisation is already completed.</div>;
  }

  return (
    <div className={commonStyles.container}>
      <section className={commonStyles.section}>
        <div className={commonStyles.content}>
          <PanelTitle text={`Welcome ${cardholderAuthorisation.fullName}`} />
          <p>
            You are nominated by {cardholderAuthorisation.applicant_first_name}{' '}
            <strong>{cardholderAuthorisation.applicantName}</strong> to apply
            for a&nbsp;
            <strong>{cardholderAuthorisation.supplierName}</strong> card. You
            are required to review and/or complete, then confirm acceptance of
            the following information:
          </p>
          <p>1. If your details are true and correct on the following pages.</p>
          <p>
            2. Once you use the {cardholderAuthorisation.supplierName} card, you
            are bound by the conditions of use set out&nbsp; in the{' '}
            {cardholderAuthorisation.supplierName} terms and conditions (which
            may change from time to time),&nbsp; of which a copy is attached for
            your reference.
          </p>
          <p>
            3. In the event the applicant does not pay for the purchases
            incurred by you the cardholder&nbsp; using the{' '}
            {cardholderAuthorisation.supplierName} card, you shall pay{' '}
            {cardholderAuthorisation.supplierName} for such purchase.
          </p>
          <p>
            {cardholderAuthorisation.accountNameLabel}:&nbsp;
            {cardholderAuthorisation.accountName}
          </p>
          <p>
            {cardholderAuthorisation.accountNumberLabel}:&nbsp;
            {cardholderAuthorisation.accountNumber}
          </p>
          <p>
            Delivery address:&nbsp;
            {cardholderAuthorisation.deliveryAddress}
          </p>
          <p>
            Card issuing reason:&nbsp;
            {cardholderAuthorisation.cardIssuingReason}
          </p>
          {get(
            cardholderAuthorisation,
            'additionalQuestions.additional_fields',
            []
          ).map((q) => (
            <p key={q.description}>
              {q.description}:&nbsp;
              {formatAnswer(q.value)}
            </p>
          ))}
        </div>
        <div className={commonStyles.flow_buttons}>
          <Button text="Decline" onClick={toDecline} />
          <Button
            text="Next"
            onClick={props.toNextSection}
            loading={cardholderAuthorisation.isLoading}
          />
        </div>
      </section>
    </div>
  );
}
