import bankValidator from 'nz-bank-account-validator/lib/NZ-Bank-Account-Validator';
import REGION from 'types/region';

export const AU_ACCOUNT_NUMBER_RULE = {
  maximum: 10,
  minimum: 6,
};

const validateAustraliaAccountNumber = (accountNumber: string): boolean =>
  accountNumber.length >= AU_ACCOUNT_NUMBER_RULE.minimum &&
  accountNumber.length <= AU_ACCOUNT_NUMBER_RULE.maximum;

const validateAccountNumber = (
  region: 'au' | 'nz' | REGION,
  accountNumber: string | null | undefined
): boolean => {
  let isValid = true;

  switch (region.toUpperCase()) {
    case 'NZ':
      isValid = accountNumber && bankValidator.validate(accountNumber);
      break;
    case 'AU':
      isValid = validateAustraliaAccountNumber(accountNumber || '');
      break;
  }

  return isValid;
};

export default validateAccountNumber;
