/* eslint-disable max-lines */
import { EMAIL_REGEX } from 'constants';
import { isEmpty } from 'lodash';
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import ModuleCardholderApplicationFlowModel from 'models/ModuleCardholderApplicationFlowModel';
import commonStyles from 'modules/card-management-onboarding/css/Section.css';
import Button from 'modules/shared/components/inputs/Button';
import Radiobox from 'modules/shared/components/inputs/Radiobox';
import TextInput from 'modules/shared/components/inputs/TextInput';
import SectionHeader from 'modules/shared/components/v2/SectionHeader';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';

import AccountDetails from './components/AccountDetails';
import CardholderDetails from './components/CardholderDetails';
import CardRemoveModal from './components/CardRemoveModal';
import { Section } from './styles';
import { signatureRequired } from './utils';

const signatureCardholderSchema = {
  delivery_address: yup
    .object()
    .required('Please input and select an address.'),
  email: yup
    .string()
    .required('Please input an email.')
    .matches(EMAIL_REGEX, {
      excludeEmptyString: true,
      message: 'Please input a valid email.',
    })
    .trim(),
  first_name: yup
    .string()
    .required('Please input a first name.')
    .trim(),
  last_name: yup
    .string()
    .required('Please input a last name.')
    .trim(),
  middle_name: yup
    .string()
    .nullable()
    .trim(),
  phone_number: yup
    .string()
    .required('Please input a phone number.')
    .matches(/^\d+$/, {
      excludeEmptyString: true,
      message: 'Please input digital phone number.',
    })
    .trim(),
};

const detailsSchema = yup.object().shape({
  account_name: yup
    .string()
    .required('Please input an account name.')
    .trim(),
  account_number: yup
    .string()
    .required('Please input an account number.')
    .trim(),
  cardholderApplicant: yup.string().when('validateApplicantCardholder', {
    is: true,
    then: yup.string().required('Please select one of the option.'),
  }),
  cardholders: yup
    .array()
    .of(
      yup.object().shape({
        card_issuing_reason: yup.string().required('Please select one reason.'),
      })
    )
    .when('signatureRequired', {
      is: true,
      otherwise: yup.array().of(
        yup.object().shape({
          first_name: yup
            .string()
            .required('Please input a name to display on the card.')
            .trim(),
        })
      ),
      then: yup.array().of(yup.object().shape(signatureCardholderSchema)),
    })
    .when('cardLimitEnabled', {
      is: true,
      then: yup.array().of(
        yup.object().shape({
          card_limit: yup
            .number()
            .min(1, 'Limit cannot be lower than 1')
            .required('Please set a card limit.'),
        })
      ),
    }),
  number_of_cards: yup.number().when('hasCards', {
    is: false,
    then: yup
      .number()
      .min(1, 'Please add at least 1 card.')
      .required('Please add at least 1 card.'),
  }),
  region: yup
    .string()
    .required('Please select a region.')
    .oneOf(['AU', 'NZ'])
    .trim(),
});

function findApplicantCardholder(cardholder) {
  return (
    cardholder && cardholder.is_applicant && cardholder.is_applicant === 'Yes'
  );
}

function findRequestedCardholders(cardholder) {
  return (
    cardholder && (!cardholder.is_applicant || cardholder.is_applicant === 'No')
  );
}

function useCardholders(defaultCardholders) {
  const [cardholders, setCardholders] = useState(defaultCardholders || []);

  const updateCardholder = (index, attributes) => {
    const newCardholders = [...cardholders];
    newCardholders[index] = { ...newCardholders[index], ...attributes };
    setCardholders(newCardholders);
  };

  const deleteCardholder = (index) => {
    const newCardholders = [
      ...cardholders.slice(0, index),
      ...cardholders.slice(index + 1),
    ];

    setCardholders(newCardholders);
  };

  const addCardholders = (
    numberOfCards = 1,
    cardholderObject = {},
    ignoreEmpty = false
  ) => {
    if (ignoreEmpty) {
      const newCardholders = [...Array(numberOfCards).keys()].map(
        () => cardholderObject
      );
      return setCardholders([...newCardholders, ...cardholders]);
    }

    const applicantCardholder = cardholders.find(findApplicantCardholder);
    const filledCardholders = cardholders.filter(
      (cardholder) => !isEmpty(cardholder)
    );
    const processedNumberOfCards =
      (numberOfCards > 20 ? 20 : numberOfCards) -
      filledCardholders.length +
      (applicantCardholder ? 1 : 0);

    const newCardholders = [...Array(processedNumberOfCards).keys()].map(
      () => cardholderObject
    );
    setCardholders([...filledCardholders, ...newCardholders]);
  };

  return {
    addCardholders,
    cardholders,
    deleteCardholder,
    updateCardholder,
  };
}

function CardholderList(props) {
  const { cardholders, deleteCardholder } = props;
  const [activeCardIndex, setActiveCardIndex] = useState(null);

  const cardholderElements = cardholders.map(
    (cardholder, index) =>
      (!cardholder.is_applicant || cardholder.is_applicant === 'No') && (
        <CardholderDetails
          key={`CardholderDetails-${index}-${cardholders.length}-${cardholder.is_applicant}`}
          index={index}
          cardholder={cardholder}
          setActiveCardIndex={setActiveCardIndex}
          {...props}
          displayDeleteBtn
        />
      )
  );

  const closeModal = () => {
    setActiveCardIndex(null);
  };

  return (
    <div className="column is-12">
      {cardholderElements}
      {activeCardIndex !== null && (
        <CardRemoveModal
          activeCardIndex={activeCardIndex}
          closeModal={closeModal}
          deleteCardholder={deleteCardholder}
          setActiveCardIndex={setActiveCardIndex}
          zIndex={1000000000}
        />
      )}
    </div>
  );
}

function Details(props) {
  const {
    additionalFieldsFlags,
    cardManagementApplication,
    dispatch,
    location,
    setApplicant,
    signatureRequired,
    cardLimitEnabled,
    supplierRegion,
  } = props;

  const {
    addCardholders,
    cardholders,
    deleteCardholder,
    updateCardholder,
  } = useCardholders(cardManagementApplication.attributes.cardholders);

  const applicantCardholder = cardholders.find(findApplicantCardholder);
  const applicantCardholderIndex = cardholders.findIndex(
    findApplicantCardholder
  );
  const requestedCardholders = cardholders.filter(findRequestedCardholders);
  const hasCardholders = cardholders.length > 0;
  const [applicantIsCardholder, setApplicantIsCardholder] = useState(
    hasCardholders ? !!applicantCardholder : null
  );

  const [additionalFieldCheck, setAdditionalFieldCheck] = useState(false);
  const [
    additionalFieldsGeneralAnswers,
    setAdditionalFieldsGeneralAnswers,
  ] = useState(cardManagementApplication.additionalFieldsGeneralAnswers);
  const [accountNumber, setAccountNumber] = useState(
    cardManagementApplication.accountNumber
  );
  const [accountName, setAccountName] = useState(
    cardManagementApplication.accountName
  );
  const [region, setRegion] = useState(
    cardManagementApplication.region || supplierRegion
  );
  const [errors, setErrors] = useState({});

  useEffect(() => {
    mixpanel.identify(cardManagementApplication.applicantId);
    mixpanel.track('CMM started', {
      distinct_id: cardManagementApplication.applicantId,
    });
  }, []);

  // Location only get updated after Details component is mounted
  useEffect(() => {
    if (get(location, 'query.email', false)) {
      mixpanel.people.set({
        $email: location.query.email,
      });
    }
  }, [location]);

  useEffect(() => {
    if (additionalFieldCheck) {
      valid();
    }
  }, [cardholders, accountName, accountNumber]);

  const valid = (callback) => {
    cardManagementApplication.isLoading = true;
    const hasCards = cardholders.length > 0;
    detailsSchema
      .validate(
        {
          account_name: accountName,
          account_number: accountNumber,
          additional_fields_general_answers: additionalFieldsGeneralAnswers,
          cardLimitEnabled,
          cardholders,
          hasCards,
          region,
          signatureRequired,
          validateApplicantCardholder:
            signatureRequired && applicantIsCardholder === null,
        },
        { abortEarly: false }
      )
      .then((val) => {
        if (callback) {
          callback(val);
        }
      })
      .catch((err) => {
        cardManagementApplication.isLoading = false;
        const errorMessages = {};
        err.inner.forEach((validationError) => {
          errorMessages[validationError.params.path] = validationError.message;
        });
        setErrors(errorMessages);
      });
  };

  const toggleApplicantAsCardholder = (applicantIsCardholderEnabled) => {
    if (applicantIsCardholderEnabled === 'No') {
      setApplicantIsCardholder(false);

      if (applicantCardholder) {
        return deleteCardholder(applicantCardholderIndex);
      }
    }

    if (applicantIsCardholderEnabled === 'Yes') {
      setApplicantIsCardholder(true);

      if (!applicantCardholder) {
        addCardholders(1, { is_applicant: 'Yes' }, true);
      }
    }
  };

  const updateAndValidateCardholder = (index, attributes) => {
    updateCardholder(index, attributes);
  };

  const applicationSubmit = (data) => {
    const allValid = !additionalFieldsFlags.flat().some((flag) => !flag);

    if (allValid) {
      const applicant = data.cardholders.find(
        (cardholder) => cardholder.is_applicant === 'Yes'
      );
      if (applicant) {
        setApplicant({
          address: applicant.delivery_address,
          first_name: applicant.first_name,
          last_name: applicant.last_name,
          middle_name: applicant.middle_name,
        });
      } else {
        setApplicant({});
      }
      cardManagementApplication.setAttributes(data);
      (async () => {
        const result = await cardManagementApplication.saveDetails(
          props.supplierId
        );
        if (result && result.status === 200) {
          cardManagementApplication.setAttributes(result.data);
          props.toNextSection();
        }
      })();
    } else {
      cardManagementApplication.isLoading = false;
    }
  };

  const onSubmit = () => {
    setAdditionalFieldCheck(true);
    valid(applicationSubmit);
  };

  const applicantIsCardholderSelected =
    applicantIsCardholder || applicantCardholder;

  const numberOfCardOrderTitle = applicantCardholder
    ? 'No. of additional cards required (excl. yourself)'
    : 'No. of cards requested';

  return (
    <div>
      <form onSubmit={onSubmit} className={commonStyles.section}>
        <Section>
          <SectionHeader title="Account details" />
          <AccountDetails
            accountName={accountName}
            accountNumber={accountNumber}
            additionalFieldCheck={additionalFieldCheck}
            application={cardManagementApplication}
            errors={errors}
            region={region}
            numberOfCards={
              requestedCardholders ? requestedCardholders.length : 0
            }
            setAccountName={setAccountName}
            setAccountNumber={setAccountNumber}
            setRegion={setRegion}
            signatureRequired={signatureRequired}
            addCardholders={addCardholders}
            additionalFieldsAnswer={additionalFieldsGeneralAnswers}
            setAdditionalFieldsAnswer={setAdditionalFieldsGeneralAnswers}
          />
          {signatureRequired && (
            <Fragment>
              <SectionHeader title="I will be one of the cardholders" />
              <div className="columns is-multiline">
                <div className="column is-12">
                  <Radiobox
                    name="cardholderApplicant"
                    handleChange={(e) =>
                      toggleApplicantAsCardholder(e.target.value)
                    }
                    labelStyles={{
                      fontWeight: 400,
                    }}
                    defaultValue={
                      hasCardholders &&
                      (applicantIsCardholderSelected ? 'Yes' : 'No')
                    }
                    radioList={['Yes', 'No']}
                    error={get(errors, 'cardholderApplicant')}
                  />
                </div>
              </div>
            </Fragment>
          )}
          {applicantCardholder && (
            <div className="columns is-multiline">
              <div className="column is-12">
                <CardholderDetails
                  key={'Applicant-CardholderDetails'}
                  additionalFieldCheck={additionalFieldCheck}
                  application={cardManagementApplication}
                  cardholder={applicantCardholder}
                  deleteCardholder={deleteCardholder}
                  errors={errors}
                  region={region}
                  setErrors={setErrors}
                  updateAndValidateCardholder={updateAndValidateCardholder}
                  index={applicantCardholderIndex}
                  {...props}
                />
              </div>
            </div>
          )}
          <SectionHeader title={numberOfCardOrderTitle} />
          <div className="columns is-multiline">
            <div className="column is-4">
              <TextInput
                handleChange={(e) =>
                  addCardholders(parseInt(e.target.value) || 0)
                }
                value={requestedCardholders.length}
                type="number"
                required
                error={get(errors, 'number_of_cards', '')}
              />
            </div>
          </div>
          <div className="columns is-multiline">
            <CardholderList
              additionalFieldCheck={additionalFieldCheck}
              application={cardManagementApplication}
              cardholders={cardholders}
              hasApplicantCardholder={!!applicantCardholder}
              deleteCardholder={deleteCardholder}
              errors={errors}
              region={region}
              setErrors={setErrors}
              updateAndValidateCardholder={updateAndValidateCardholder}
              {...props}
            />
          </div>
        </Section>
        <div className={commonStyles.flow_buttons}>
          <Button
            text="Next"
            onClick={onSubmit}
            loading={cardManagementApplication.isLoading}
          />
        </div>
      </form>
    </div>
  );
}

function cardholderAttributesConverter(previewAddon) {
  const processedPreviewAddon = { ...previewAddon };

  const keys = {
    account_name: 'account_name_label',
    account_number: 'account_number_label',
  };

  Object.keys(processedPreviewAddon).map((key) => {
    if (keys[key]) {
      processedPreviewAddon[keys[key]] = processedPreviewAddon[key];
      delete processedPreviewAddon[key];
    }
  });

  const cardIssuingReasons = processedPreviewAddon['card_issuing_reasons'];
  processedPreviewAddon['card_issuing_reasons'] = Object.keys(
    cardIssuingReasons
  ).filter((key) => cardIssuingReasons[key]);

  return processedPreviewAddon;
}

export default connect((state, ownProps) => {
  const previewAddon = ownProps.previewAddon;
  const cardManagementApplication =
    ownProps.cardManagementApplication ||
    new ModuleCardholderApplicationFlowModel({
      attributes: cardholderAttributesConverter(previewAddon),
    });

  const sharedData = state.shared_data;
  const additionalFieldsFlags = [];
  Object.keys(sharedData).forEach((key) => {
    if (key.slice(0, 15) === 'card_management') {
      additionalFieldsFlags.push(sharedData[key]);
    }
  });

  const cardLimitEnabled = cardManagementApplication.cardLimitEnabled === 'on';

  return {
    additionalFieldsFlags,
    cardManagementApplication,
    cardLimitEnabled,
    deliveryAddressData: state.cob_business.delivery_address_raw_list,
    deliveryAddressLoading: state.cob_business.delivery_address_loading,
    deliveryAddressOptions: state.cob_business.delivery_address_list,
    signatureRequired: signatureRequired(cardManagementApplication),
  };
})(Details);
