import React, { Component } from 'react';
import {
  lookupAddress,
  selectAddress,
  setAddress,
} from 'modules/identity/actions';
import { updateAuthorisation, updateAuthorisationSignature } from '../actions';
import BackAndNextButtons from 'modules/shared/components/widgets/interactive/BackAndNextButtons';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import TextInput from 'modules/shared/components/inputs/TextInput';
import { connect } from 'react-redux';
import moment from 'moment';
import styles from './css/Section.css';
import { isSanitizedStringEqual } from 'utils/sanitizeName';
import isPresent from 'utils/isPresent';

const retrieveNameComponents = ({ authorisation, identity }) => {
  if (isPresent(identity.firstName) && isPresent(identity.lastName)) {
    return { firstName: identity.firstName, lastName: identity.lastName };
  }

  return {
    firstName: authorisation.firstName,
    lastName: authorisation.lastName,
  };
};

class Confirm extends Component {
  constructor() {
    super();
    this.state = {
      error: {},
      first_name: '',
      last_name: '',
    };
  }
  onNextClick() {
    const { params, toNextSection, dispatch } = this.props;
    const allValid = [];
    const fields = defaults.fields.standard;
    fields.forEach((field) =>
      allValid.push(this.fieldCheck(field, this.state[field]))
    );
    if (allValid.every((flag) => flag)) {
      dispatch(
        updateAuthorisation(params.authorisation_id, () => {
          dispatch(
            updateAuthorisationSignature(params.authorisation_id, () =>
              toNextSection()
            )
          );
        })
      );
    }
  }

  fieldCheck(key, value) {
    const validationResult = this.valid(key, value);
    const { error } = this.state;
    if (validationResult) {
      error[key] = '';
    } else {
      error[key] = this.errorMessage(key);
    }
    this.setState({ error });
    return validationResult;
  }

  errorMessage(key) {
    const { identity, referenceFirstName, referenceLastName } = this.props;
    const { first_name, last_name } = this.state;
    switch (key) {
      case 'first_name':
        if (isPresent(first_name) && first_name !== referenceFirstName) {
          return 'Incorrect first name';
        }

        return 'You must input first name';
      case 'last_name':
        if (isPresent(last_name) && last_name !== referenceLastName) {
          return 'Incorrect last name';
        }

        return 'You must input last name';

      case 'dob':
        if (identity[key]) return 'Please select a valid date of birth';
        return 'Please select date of birth';
      case 'personal_address':
        if (!identity.address || !identity.address.full_address)
          return 'Please input personal address';
        return 'Please select a valid address';
    }
  }

  valid(key, value) {
    const { referenceFirstName, referenceLastName } = this.props;
    const { identity } = this.props;

    switch (key) {
      case 'first_name':
        return (
          Boolean(value) && isSanitizedStringEqual(referenceFirstName, value)
        );
      case 'last_name':
        return (
          Boolean(value) && isSanitizedStringEqual(referenceLastName, value)
        );
      case 'dob': {
        const dob = moment(value, 'DD/MM/YYYY');
        const yearOfBirth = dob.year();
        const thisYear = new Date().getFullYear();

        // user must be less then 100 years old and over 18
        return (
          Boolean(value) &&
          thisYear - 100 <= yearOfBirth &&
          yearOfBirth <= thisYear - 18
        );
      }
      case 'personal_address':
        return Boolean(identity.address_api_id);
    }
  }

  handleAddressChange(value) {
    const { dispatch } = this.props;
    dispatch(setAddress({ full_address: value }));
    if (value.length > 0) {
      dispatch(lookupAddress(event.target.value));
    }
  }

  handleAddressClick(value) {
    const { dispatch } = this.props;
    dispatch(selectAddress(value));
  }

  renderAcknowledge() {
    const {
      hasCardholder,
      hasGuarantor,
      hasPaperless,
      supplierName,
    } = this.props;
    const items = [];
    items.push(<li> The information provided is true and correct.</li>);
    if (hasGuarantor) {
      items.push(
        <li>
          In electronically signing the guarantee, you should assume that this
          is a liability you will be called upon to honor, and you should
          satisfy yourself that you have the financial means to meet this
          liability, and are willing to do so. If you are not comfortable with
          that assumption, you should not consent to the guarantee.
        </li>
      );
    }
    if (hasCardholder) {
      items.push(
        <li>
          Once you use the {supplierName} card you are bound by the conditions
          of use set out in the {supplierName} Terms and Conditions (as amended
          form time to time).
        </li>
      );
      items.push(
        <li>
          In the event the applicant does not pay for the purchase incurred by
          the card signatory using the {supplierName} card, the card signatory
          shall pay {supplierName} for such purchase.
        </li>
      );
    }
    if (hasPaperless) {
      items.push(
        <li>
          I authorise {supplierName}, until further notice in writing to debit
          my/our account.
        </li>
      );
      items.push(
        <li>
          I acknowledge and accept that the bank accepts the direct debit
          authority based upon the conditions found in the Terms & Conditions.
        </li>
      );
    }
    return (
      <div>
        <p>By signing this section you confirm and acknowledge that:</p>
        <ul>{items}</ul>
      </div>
    );
  }

  renderSignatureFields() {
    const { identity } = this.props;
    const { error, first_name, last_name } = this.state;
    return (
      <div className={styles.row}>
        <div className={styles.half_width}>
          <TextInput
            id="first_name"
            name="first_name"
            handleChange={(event) =>
              this.setState({ first_name: event.target.value })
            }
            handleBlur={(event) =>
              this.fieldCheck('firstName', event.target.value)
            }
            label="First name"
            error={error.first_name}
            value={first_name}
            required={true}
          />
        </div>
        <div className={styles.half_width}>
          <TextInput
            id="last_name"
            name="last_name"
            handleChange={(event) =>
              this.setState({ last_name: event.target.value })
            }
            handleBlur={(event) =>
              this.fieldCheck('lastName', event.target.value)
            }
            label="Last name"
            error={error.last_name}
            value={last_name}
            required={true}
          />
        </div>
      </div>
    );
  }

  render() {
    const { toNextSection, toPreviousSection } = this.props;
    return (
      <div>
        <div className={styles.container}>
          <section className={styles.section}>
            <div className={`${styles.row} mb-4`}>
              <div className={styles.panel}>
                <PanelTitle text={defaults.title} />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.content}>{this.renderAcknowledge()}</div>
            </div>
            <p className="mb-2">
              If you agree please fill out your first and last name in the
              fields below and press confirm.
            </p>
            {this.renderSignatureFields()}
          </section>
        </div>
        <BackAndNextButtons
          onNextClick={this.onNextClick.bind(this)}
          onBackClick={toPreviousSection}
        />
      </div>
    );
  }
}

const defaults = {
  fields: {
    extra: ['first_name', 'last_name', 'dob', 'personal_address'],
    standard: ['first_name', 'last_name'],
  },
  title: 'Electronic signature',
};

export default connect((state) => {
  const nameComponents = retrieveNameComponents({
    authorisation: state.authorisation || {},
    identity: state.identity,
  });

  return {
    identity: state.identity,
    referenceFirstName: nameComponents.firstName,
    referenceLastName: nameComponents.lastName,
    supplierName: state.authorisation.supplierName,
  };
})(Confirm);
