import React, { ReactElement } from 'react';

const UnknownDetails = ({
  idVerified,
}: {
  idVerified: 'Pass' | 'Fail' | 'Unknown';
}): ReactElement | null => {
  if (idVerified !== 'Unknown') {
    return null;
  }

  return (
    <div>
      We have not been able to recognise this ID, so cannot verify it via the
      appropriate agency.
    </div>
  );
};

export default UnknownDetails;
