import {
  COB_GUARANTOR_COMPONENT_COMPLETE,
  COB_GUARANTOR_PRESET_DATA,
  COB_GUARANTOR_RESET_TO_DEFAULT,
  COB_GUARANTOR_SECTION_COMPLETE,
  COB_GUARANTOR_SET_GUARANTOR,
  COB_GUARANTOR_TOGGLE_IS_APPLICANT,
} from '../constants/guarantors';

import { COB_TRADEACCOUNT_CLEAR_DATA } from '../constants/section';
import { STATE_LOAD_STATE_SUCCESS } from '../../state/constants.js';

const guarantors_defaults = {
  form_values: [],
  completed: {},
  section_complete: false,
  section_data: {},
  guarantors: null,
};

export function consumerOnboardingGuarantorsReducer(
  state = guarantors_defaults,
  action
) {
  switch (action.type) {
    case COB_TRADEACCOUNT_CLEAR_DATA:
      return { ...guarantors_defaults };
    case COB_GUARANTOR_RESET_TO_DEFAULT:
      // This is the same as the action type COB_TRADEACCOUNT_CLEAR_DATA but
      // COB_TRADEACCOUNT_CLEAR_DATA is not isolated to the guarantors reducer
      // only
      return { ...guarantors_defaults };
    case COB_GUARANTOR_SET_GUARANTOR:
      var values;
      if (state.form_values[action.payload.index] !== undefined) {
        values = { ...state.form_values[action.payload.index] };
      } else {
        values = {};
      }
      values[action.payload.id] = action.payload.value;

      var form_values = state.form_values.slice();
      form_values[action.payload.index] = values;

      return { ...state, form_values };

    case COB_GUARANTOR_COMPONENT_COMPLETE:
      var component_state = new Object();
      component_state[action.payload.component] = action.payload.state;
      return {
        ...state,
        completed: { ...state.completed, ...component_state },
      };

    case COB_GUARANTOR_TOGGLE_IS_APPLICANT:
      form_values = toggleGuarantorIsApplicant(state, action.payload);
      return { ...state, form_values };

    case COB_GUARANTOR_SECTION_COMPLETE:
      return {
        ...state,
        section_data: action.payload.data,
        section_complete: action.payload.complete,
      };

    case STATE_LOAD_STATE_SUCCESS:
      if (action.payload['cob_guarantors']) {
        return { ...state, ...action.payload.cob_guarantors };
      }

    case COB_GUARANTOR_PRESET_DATA:
      return { ...state, form_values: action.payload };
    default:
      return { ...state };
  }
}

function toggleGuarantorIsApplicant(state, payload) {
  const guarantors = JSON.parse(JSON.stringify(state.form_values));
  const index = payload.index;
  const isApplicant = payload.is_applicant;

  // Reset other guarantor that is already an applicant
  if (isApplicant) {
    const isApplicantGuarantor = guarantors.find(
      (guarantor) => guarantor.is_applicant
    );
    if (isApplicantGuarantor) {
      isApplicantGuarantor.is_applicant = false;
    }
  }

  const guarantor = guarantors[index];
  if (guarantor) {
    guarantor.is_applicant = isApplicant;
  }

  return guarantors;
}
