import {
  AUTHORISATION_LOAD_DATA_ERROR,
  AUTHORISATION_LOAD_DATA_START,
  AUTHORISATION_LOAD_DATA_SUCCESS,
  AUTHORISATION_SET_VALUE,
  AUTHORISATION_UPDATE_DATA_ERROR,
  AUTHORISATION_UPDATE_DATA_START,
  AUTHORISATION_UPDATE_DATA_SUCCESS,
  AUTHORISATION_UPDATE_FORMATTED_SCRAPED_DETAILS,
} from './constants';

const authorisationDefaults = {
  cardholder: null,
  cardholderApproved: null,
  consumerName: null,
  data: null,
  firstName: '',
  guarantor: null,
  guarantorApproved: null,
  hasCardholder: false,
  hasGuarantor: false,
  hasPaperless: false,
  hasSignatory: false,
  lastName: '',
  loading: false,
  paperless: null,
  paymentApproved: null,
  signatoryApproved: false,
  supplierName: null,
  updating: false,
};

export function authorisationReducer(state = authorisationDefaults, action) {
  const data = { ...state.data };

  switch (action.type) {
    case AUTHORISATION_SET_VALUE:
      return { ...state, [action.payload.key]: action.payload.value };
    case AUTHORISATION_UPDATE_DATA_START:
      return { ...state, updating: true };
    case AUTHORISATION_UPDATE_DATA_SUCCESS:
      return { ...state, updating: false };
    case AUTHORISATION_UPDATE_DATA_ERROR:
      return { ...state, updating: false };
    case AUTHORISATION_LOAD_DATA_START:
      return { ...state, loading: true };
    case AUTHORISATION_LOAD_DATA_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case AUTHORISATION_UPDATE_FORMATTED_SCRAPED_DETAILS:
      if (data.signature) {
        data.signature.attributes.formatted_scraped_details = action.payload;
      } else {
        data.signature = {
          attributes: {
            formatted_scraped_details: action.payload,
          },
        };
      }

      return { ...state, data };
    case AUTHORISATION_LOAD_DATA_ERROR:
      return { ...state, loading: false };
    default:
      return { ...state };
  }
}
