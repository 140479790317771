import get from 'lodash.get';
import { loadInternalUseFieldsAddon } from 'modules/addons/actions';
import { createApplicationForNewConsumer } from 'modules/consumer-invite/actions/invite';
import ConfirmChecks, {
  OVERRIDE_REQUIRES_CREDIT_CHECK_FIELD_NAME,
  OVERRIDE_REQUIRES_GUARANTEES_FIELD_NAME,
  OVERRIDE_REQUIRES_TRADE_REFERENCES_FIELD_NAME,
} from 'modules/consumer-invite/v2/ConsumerInvite/FullPage/ConfirmChecks';
import InternalFields from 'modules/consumer-invite/v2/ConsumerInvite/FullPage/InternalFields';
import {
  ButtonWrapper,
  SectionWrapper,
} from 'modules/consumer-invite/v2/ConsumerInvite/FullPage/styles';
import getCanSetAccountRules from 'modules/consumer-invite/v2/ConsumerInvite/utils/getCanSetAccountRules';
import { loadCurrentEntity } from 'modules/profile/actions';
import Button from 'modules/shared/components/inputs/Button';
import RHFBorderedEmailField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedEmailField';
import {
  hasActiveIUFSearchSendConfig,
  isIUFSearchSendCompleted,
} from 'modules/shared/helpers/internalUseFieldsHelper';
import useYupValidationResolver from 'modules/shared/hooks/useYupValidationResolver';
import React, { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-latest';
import { connect } from 'react-redux';
// Refer to https://github.com/jquense/yup/issues/507
import isEmail from 'validator/lib/isEmail';
import * as yup from 'yup';

const EMAIL_FIELD = 'email';

type FormValues = {
  [EMAIL_FIELD]: string;
  [OVERRIDE_REQUIRES_CREDIT_CHECK_FIELD_NAME]: boolean;
  [OVERRIDE_REQUIRES_GUARANTEES_FIELD_NAME]: boolean;
  [OVERRIDE_REQUIRES_TRADE_REFERENCES_FIELD_NAME]: boolean;
};

const getApplicationChecks = (currentEntity) => {
  const attributes = (currentEntity || { attributes: {} }).attributes;

  return {
    requiresCreditCheck: attributes.requires_credit_check || false,
    requiresGuarantees: attributes.requires_guarantees || false,
    requiresTradeReferences: (attributes.minimum_trade_references || 0) > 0,
  };
};

const buildOverrideRuleAttributes = ({
  canOverrideApplicationRules,
  overrideRequiresCreditCheck,
  overrideRequiresGuarantees,
  overrideRequiresTradeReferences,
}: {
  canOverrideApplicationRules: boolean;
  overrideRequiresCreditCheck: boolean;
  overrideRequiresGuarantees: boolean;
  overrideRequiresTradeReferences: boolean;
}) => {
  if (!canOverrideApplicationRules) {
    return {};
  }

  const applicationRules = {};

  if (!overrideRequiresCreditCheck) {
    applicationRules['requires_credit_check'] = false;
  }

  if (!overrideRequiresGuarantees) {
    applicationRules['requires_guarantees'] = false;
    applicationRules['minimum_guarantees'] = 0;
  }

  if (!overrideRequiresTradeReferences) {
    applicationRules['requires_trade_reference_check'] = false;
    applicationRules['minimum_trade_references'] = 0;
  }

  return applicationRules;
};

const validationSchema = () =>
  yup.object().shape({
    [EMAIL_FIELD]: yup
      .string()
      .required('Please enter a valid email')
      .email('Please enter a valid email')
      .test('is-valid', 'Please enter a valid email', (value) =>
        isEmail(value)
      ),
    [OVERRIDE_REQUIRES_CREDIT_CHECK_FIELD_NAME]: yup.boolean(),
    [OVERRIDE_REQUIRES_GUARANTEES_FIELD_NAME]: yup.boolean(),
    [OVERRIDE_REQUIRES_TRADE_REFERENCES_FIELD_NAME]: yup.boolean(),
  });

const Form = (props): ReactElement => {
  const {
    currentEntity,
    currentUserProfile,
    dispatch,
    applicationType,
    internalUseFieldsLoaded,
    iufAnswers,
    onCancel,
    onErrorCallback,
    onSuccessSendCallback,
    requiresCreditCheck,
    requiresGuarantees,
    requiresTradeReferences,
  } = props;

  const [
    canOverrideApplicationRules,
    setCanOverrideApplicationRules,
  ] = useState(false);

  useEffect(() => {
    dispatch(loadInternalUseFieldsAddon());
    dispatch(loadCurrentEntity());
  }, []);

  useEffect(() => {
    const canSetAccountRules = getCanSetAccountRules({
      applicationType,
      currentEntity,
      currentUserProfile,
    });

    setCanOverrideApplicationRules(canSetAccountRules);
  }, [applicationType, currentEntity, currentUserProfile]);

  const [isIUFSectionVisible, setIsIUFSectionVisible] = useState(false);
  useEffect(() => {
    setIsIUFSectionVisible(
      hasActiveIUFSearchSendConfig(applicationType) && internalUseFieldsLoaded
    );
  }, [internalUseFieldsLoaded]);

  const {
    control,
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      [EMAIL_FIELD]: '',
      [OVERRIDE_REQUIRES_CREDIT_CHECK_FIELD_NAME]: false,
      [OVERRIDE_REQUIRES_GUARANTEES_FIELD_NAME]: false,
      [OVERRIDE_REQUIRES_TRADE_REFERENCES_FIELD_NAME]: false,
    },
    mode: 'onSubmit',
    resolver: useYupValidationResolver(validationSchema),
  });

  register(OVERRIDE_REQUIRES_CREDIT_CHECK_FIELD_NAME);
  register(OVERRIDE_REQUIRES_GUARANTEES_FIELD_NAME);
  register(OVERRIDE_REQUIRES_TRADE_REFERENCES_FIELD_NAME);

  useEffect(() => {
    setValue(OVERRIDE_REQUIRES_CREDIT_CHECK_FIELD_NAME, requiresCreditCheck);
  }, [requiresCreditCheck]);

  useEffect(() => {
    setValue(OVERRIDE_REQUIRES_GUARANTEES_FIELD_NAME, requiresGuarantees);
  }, [requiresGuarantees]);

  useEffect(() => {
    setValue(
      OVERRIDE_REQUIRES_TRADE_REFERENCES_FIELD_NAME,
      requiresTradeReferences
    );
  }, [requiresTradeReferences]);

  const onToggleApplicationChecks = (event) => {
    const fieldName = get(event, 'target.id');
    const currentValue = getValues(fieldName);

    setValue(fieldName, !currentValue);
  };

  const onSubmit = (data) => {
    if (isIUFSectionVisible && !isIUFSearchSendCompleted()) {
      return;
    }

    const {
      email,
      overrideRequiresCreditCheck,
      overrideRequiresGuarantees,
      overrideRequiresTradeReferences,
    } = data;

    const overriddenRules = buildOverrideRuleAttributes({
      canOverrideApplicationRules,
      overrideRequiresCreditCheck,
      overrideRequiresGuarantees,
      overrideRequiresTradeReferences,
    });

    dispatch(
      createApplicationForNewConsumer({
        application_type: applicationType,
        email,
        errorCallback: onErrorCallback,
        iufAnswers,
        overriddenRules,
        successCallback: () => onSuccessSendCallback(email),
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <SectionWrapper>
          <div className="columns">
            <div className="column is-one-third">
              <div className="field">
                <label className="label mb-0">
                  Please enter the email of the recipient.
                </label>
                <div className="control">
                  <RHFBorderedEmailField
                    control={control}
                    name={EMAIL_FIELD}
                    withIcon={false}
                    noValidationOnEmpty
                    label="Email address"
                  />
                </div>
              </div>
            </div>
          </div>
        </SectionWrapper>
        {isIUFSectionVisible && (
          <InternalFields application_type={applicationType} />
        )}
        {canOverrideApplicationRules && (
          <ConfirmChecks
            getValues={getValues}
            setValue={setValue}
            overrideRequiresCreditCheckValue={watch(
              OVERRIDE_REQUIRES_CREDIT_CHECK_FIELD_NAME
            )}
            overrideRequiresGuaranteesValue={watch(
              OVERRIDE_REQUIRES_GUARANTEES_FIELD_NAME
            )}
            overrideRequiresTradeReferencesValue={watch(
              OVERRIDE_REQUIRES_TRADE_REFERENCES_FIELD_NAME
            )}
            onToggleCheckBox={onToggleApplicationChecks}
            requiresCreditCheck={requiresCreditCheck}
            requiresGuarantees={requiresGuarantees}
            requiresTradeReferences={requiresTradeReferences}
          />
        )}
      </div>

      <ButtonWrapper>
        <div className="control">
          <Button text="Cancel" handleClick={onCancel} white />
        </div>
        <div className="control">
          <Button text="Send" type="submit" />
        </div>
      </ButtonWrapper>
    </form>
  );
};

export default connect((state) => {
  const addOnsState = get(state, 'add_ons', {});
  const manageProfileState = get(state, 'manage_profile', {});
  const currentEntity = manageProfileState.current_entity;
  const iufAnswers = get(state, 'cns_invite.iuf_answers');

  const {
    requiresCreditCheck,
    requiresGuarantees,
    requiresTradeReferences,
  } = getApplicationChecks(currentEntity);

  return {
    currentEntity,
    currentUserProfile: manageProfileState.current_user_profile,
    internalUseFieldsLoaded: addOnsState.internal_use_fields_loaded,
    iufAnswers,
    requiresCreditCheck,
    requiresGuarantees,
    requiresTradeReferences,
  };
})(Form);
