import React from 'react';

/* Import CSS */
import styles from './css/Review.css';

import LabeledInformation from '../../shared/components/widgets/static/LabeledInformation';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';

var ReviewGuarantors = createClass({
  render: function() {
    const { data } = this.props;
    let guarantors = data.guarantors;
    let guarantorElms = [];
    guarantors.forEach((v, i) => {
      guarantorElms.push(
        <ReviewContainer key={i} subHeading={'Guarantor ' + (i + 1)}>
          <div className={styles.col}>
            <LabeledInformation
              label="Name"
              data={v.attributes.full_name}
              noHumanize={true}
            />
          </div>
          <div className={styles.col}>
            <LabeledInformation label="Email" data={v.attributes.email} />
          </div>
        </ReviewContainer>
      );
    });

    return (
      <section className={styles.expandable}>
        <span className={styles.shadow} />
        {guarantorElms}
      </section>
    );
  },
});

module.exports = ReviewGuarantors;
