import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './css/Section.css';

class ReviewCardholder extends Component {
  render() {
    const { supplierName, title } = this.props;

    return (
      <section className={styles.section}>
        <div className={`${styles.row} mb-4`}>
          <div className={styles.panel}>
            <PanelTitle text={title} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.content}>
            <p className="mb-2">
              You are required to review and/or complete, then confirm
              acceptance of the following information:
            </p>
            <ol className={styles.authorisation_item_list}>
              <li>
                If your details are true and correct on the following pages.
              </li>
              <li>
                Once you use the {supplierName} card, you are bound by the
                conditions of use set out&nbsp; in the {supplierName} terms and
                conditions (which may change from time to time),&nbsp; of which
                a copy is attached for your reference.
              </li>
              <li>
                In the event the applicant does not pay for the purchases
                incurred by you the cardholder&nbsp; using the {supplierName}{' '}
                card, you shall pay {supplierName} for such purchase.
              </li>
            </ol>
          </div>
        </div>
      </section>
    );
  }
}

const defaults = {
  titlePrefix:
    'You have been nominated to be a cardholder for an application between ',
};

export default connect((state, ownProps) => {
  return {
    cardholder: state.authorisation.cardholder,
    cardholderApproved: state.authorisation.cardholderApproved,
    title: `${defaults.titlePrefix} ${ownProps.consumerName} and ${ownProps.supplierName}`,
  };
})(ReviewCardholder);
