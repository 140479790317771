import './menuitem.scss';
import React, { ReactElement } from 'react';
import StatusIndicator from 'modules/new-applications/components/StatusIndicator';

const IndicatorContent = ({
  indicator,
  isIndicatorVisible,
}: {
  indicator: boolean;
  isIndicatorVisible: boolean;
}): ReactElement => {
  if (!isIndicatorVisible) {
    return <></>;
  }

  let dotStatus = 'pending';
  let dotLabel = 'Off';

  if (indicator) {
    dotStatus = 'complete';
    dotLabel = 'On';
  }

  return (
    <StatusIndicator
      withStatusName={true}
      status={dotStatus}
      statusName={dotLabel}
    />
  );
};

const MenuItem = ({
  indicator,
  isActive,
  isIndicatorVisible,
  onClick,
  title,
}: {
  indicator: boolean;
  isActive: boolean;
  isIndicatorVisible: boolean;
  onClick: () => void;
  title: string;
}): ReactElement => (
  <div
    onClick={onClick}
    className={'menu-item' + `${isActive ? ' menu-item__active' : ''}`}
  >
    <p>{title}</p>
    <span className={`indicator-wrapper ${indicator ? 'active' : ''}`}>
      <IndicatorContent
        indicator={indicator}
        isIndicatorVisible={isIndicatorVisible}
      />
    </span>
  </div>
);

MenuItem.defaultProps = {
  isIndicatorVisible: true,
};

export default MenuItem;
