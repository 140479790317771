import get from 'lodash.get';
import { lookupAddress } from 'modules/consumer-onboarding/actions/onboarding';
import BorderedCalendarPicker from 'modules/shared/components/inputs/BorderedCalendarPicker';
import TextInput from 'modules/shared/components/inputs/TextInput';
import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';
import moment from 'moment';
import React, { Fragment, useState } from 'react';

import AddressPicker from './AddressPicker';
import commonStyles from './css/Section.css';
import { get100YearsAgo, getEighteenYearsAgo } from './utils';

function AddressAndIdDetails(props) {
  const {
    deliveryAddressLoading,
    deliveryAddressOptions,
    deliveryAddressData,
    dispatch,
    errors,
    isVisible,
    region,
    signature,
    updateSignature,
  } = props;

  const [addressKeyword, setAddressKeyword] = useState(
    get(signature, 'address.full_address')
  );

  const [addressRegion, setAddressRegion] = useState(
    get(signature, 'address.alpha2', region)
  );

  if (!isVisible) return null;

  const inputForm = [
    {
      label: 'Identity type',
      name: 'identification_type',
      options: [
        {
          label: 'Driver Licence',
          value: 'driver_licence',
        },
        {
          label: 'Passport',
          value: 'passport',
        },
        {
          label: 'Other',
          value: 'other',
        },
      ],
      required: true,
    },
    {
      label: 'Country of identity',
      name: 'region',
      options: [
        {
          label: 'New Zealand',
          value: 'NZ',
        },
        {
          label: 'Australia',
          value: 'AU',
        },
        {
          label: 'Other',
          value: 'Other',
        },
      ],
      required: true,
    },
  ];

  const lookupDeliveryAddress = (keyword) => {
    dispatch(lookupAddress(keyword, 'Delivery', addressRegion));
  };

  const handleDeliveryAddressChange = (event) => {
    const keyword = event.target.value;
    setAddressKeyword(keyword);
    lookupDeliveryAddress(keyword);
  };

  const handleDeliveryAddressClick = (value) => {
    const addressValue = deliveryAddressData[value];
    setAddressKeyword(addressValue.full_address);
    updateSignature({ address: addressValue });
  };

  const showVersionInput =
    signature.region === 'NZ' &&
    signature.identification_type === 'driver_licence';
  const showExpiryDate = signature.identification_type === 'passport';

  return (
    <Fragment>
      <div className={commonStyles.row}>
        <div className={commonStyles.col_4}>
          <BorderedCalendarPicker
            textFieldProps={{
              error: Boolean(errors.dob),
              helperText: get(errors, 'dob', ''),
              id: 'dob',
              name: 'dob',
              required: true,
            }}
            minDate={get100YearsAgo()}
            maxDate={getEighteenYearsAgo()}
            value={signature.dob}
            label="Date of birth"
            isStyledUnderlined
            onChange={(value) => updateSignature({ dob: value })}
          />
        </div>
      </div>
      <div className={commonStyles.row}>
        {inputForm.map((input) => (
          <div
            key={`${input.label}-${signature[input.name]}`}
            className={commonStyles.col_4}
          >
            <OptionsDropdown
              handleChange={(event) =>
                updateSignature({ [input.name]: event.value })
              }
              error={get(errors, input.name, '')}
              label={input.label}
              value={signature[input.name]}
              required={input.required}
              options={input.options}
            />
          </div>
        ))}
        <div className={commonStyles.col_4}>
          <TextInput
            label={'ID number'}
            handleChange={(event) =>
              updateSignature({ identification_number: event.target.value })
            }
            required={true}
            value={signature.identification_number}
            error={get(errors, 'identification_number', '')}
          />
        </div>
        {showExpiryDate && (
          <div className={commonStyles.col_4}>
            <BorderedCalendarPicker
              textFieldProps={{
                error: Boolean(errors.identification_expiry_date),
                helperText: get(errors, 'identification_expiry_date', ''),
                id: 'identification_expiry_date',
                name: 'identification_expiry_date',
                required: true,
              }}
              minDate={moment(moment().format('DD/MM/YYYY')).toDate()}
              maxDate={moment(
                moment()
                  .add(50, 'y')
                  .endOf('year')
                  .format('DD/MM/YYYY')
              ).toDate()}
              value={signature.identification_expiry_date}
              label="Passport expiry date"
              onChange={(value) =>
                updateSignature({ identification_expiry_date: value })
              }
              isStyledUnderlined
            />
          </div>
        )}
        {showVersionInput && (
          <div className={commonStyles.col_4}>
            <TextInput
              label="Version number"
              handleChange={(event) =>
                updateSignature({ identification_version: event.target.value })
              }
              required={true}
              value={signature.identification_version}
              error={get(errors, 'identification_version', '')}
            />
          </div>
        )}
      </div>
      <div>
        <AddressPicker
          addressKeyword={addressKeyword}
          addressRegion={addressRegion}
          deliveryAddressLoading={deliveryAddressLoading}
          deliveryAddressOptions={deliveryAddressOptions || []}
          errors={errors}
          handleDeliveryAddressChange={handleDeliveryAddressChange}
          handleDeliveryAddressClick={handleDeliveryAddressClick}
          onChangeAddressRegion={(selectedOption) => {
            setAddressRegion(selectedOption.value);
          }}
        />
      </div>
    </Fragment>
  );
}

export default AddressAndIdDetails;
