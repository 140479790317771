import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBrowser } from 'utils/getBrowser';

import {
  getAuthorisationID,
  getIsIdentificationCheckRequired,
  getIsFaceMatchEnabled,
} from '../utils';
import IDUpload from 'modules/identity/IDUpload';
import ProofOfAdressUpload from 'modules/identity/ProofOfAddressUpload';
import { Wrapper } from './styles';
import { get } from 'lodash';
import BiometricCapture from '../BiometricCapture';
import { isTokenised } from 'modules/consumer-onboarding/components/utils';
import UserModel from 'models/UserModel';

const determineIsUploadComplete = ({
  hasFrontFaceImage,
  hasIdentityImage,
  hasProofOfAddress,
  idNameMatched,
  isFaceMatchEnabled,
  isAntiFraudPassing,
  isProofOfAddressVisible,
  isValidIdentification,
  overrideIdValidation,
}) => {
  if (overrideIdValidation) {
    return true;
  }

  if (!isValidIdentification) {
    return false;
  }

  if (!hasIdentityImage || !idNameMatched) {
    return false;
  }

  if (isProofOfAddressVisible && !hasProofOfAddress) {
    return false;
  }

  if (isFaceMatchEnabled && !hasFrontFaceImage) {
    return false;
  }

  if (isFaceMatchEnabled && !isAntiFraudPassing) {
    return false;
  }

  return true;
};

const determineFrontFaceSelfieHasError = ({
  hasFrontFaceImage,
  isAntiFraudPassing,
}) => {
  if (!hasFrontFaceImage) {
    return true;
  }

  if (!isAntiFraudPassing) {
    return true;
  }

  return false;
};

const determineIdUploadHasError = ({
  idNameMatched,
  hasIdentityImage,
  isAntiFraudPassing,
  isValidIdentification,
  overrideIdValidation,
}) => {
  if (overrideIdValidation) {
    return false;
  }

  if (!isValidIdentification) {
    return true;
  }

  if (!idNameMatched) {
    return true;
  }

  if (!hasIdentityImage) {
    return true;
  }

  if (!isAntiFraudPassing) {
    return true;
  }

  return false;
};

function IdentityCapture(props) {
  const {
    frontFaceImage,
    handleComplete,
    idNameMatched,
    image_64,
    isFaceMatchEnabled,
    isAntiFraudPassing,
    isProofOfAddressVisible,
    isValidIdentification,
    overrideIdValidation,
    page_validation_start,
    proof_of_address,
    resourceType,
  } = props;
  const [errors, setErrors] = useState({
    bioUpload: false,
    idUpload: false,
    poaUpload: false,
  });
  const hasIdentityImage = !!image_64;
  const hasProofOfAddress = !!proof_of_address;
  const hasFrontFaceImage = !!frontFaceImage;
  // const hasCompleted = (
  //   isProofOfAddressVisible
  //   ? hasIdentityImage && hasProofOfAddress && idNameMatched
  //   : hasIdentityImage && idNameMatched
  // );
  const hasCompleted = determineIsUploadComplete({
    hasFrontFaceImage,
    hasIdentityImage,
    hasProofOfAddress,
    idNameMatched,
    isFaceMatchEnabled,
    isAntiFraudPassing,
    isProofOfAddressVisible,
    isValidIdentification,
    overrideIdValidation,
  });

  useEffect(() => {
    handleComplete(hasCompleted);
    // some page doesn't have page_validation_start
    if (page_validation_start) {
      const updatedErrors = { ...errors };
      if (!hasIdentityImage || !isValidIdentification || !idNameMatched) {
        Object.assign(updatedErrors, { idUpload: true });
      }
      if (isProofOfAddressVisible && !hasProofOfAddress) {
        Object.assign(updatedErrors, { poaUpload: true });
      }
      if (isFaceMatchEnabled) {
        Object.assign(updatedErrors, {
          idUpload: determineIdUploadHasError({
            hasIdentityImage,
            isAntiFraudPassing,
            idNameMatched,
          }),
          bioUpload: determineFrontFaceSelfieHasError({
            hasFrontFaceImage,
            isAntiFraudPassing,
          }),
        });
      }
      if (updatedErrors !== errors) {
        setErrors(updatedErrors);
      }
    }
  }, [
    frontFaceImage,
    hasIdentityImage,
    hasFrontFaceImage,
    idNameMatched,
    image_64,
    isFaceMatchEnabled,
    isAntiFraudPassing,
    isValidIdentification,
    overrideIdValidation,
    page_validation_start,
    proof_of_address,
  ]);

  useEffect(() => {
    if (hasIdentityImage && isValidIdentification) {
      setErrors({ ...errors, idUpload: false });
    }
    if (hasProofOfAddress) {
      setErrors({ ...errors, poaUpload: false });
    }
    if (hasFrontFaceImage) {
      setErrors({
        ...errors,
        bioUpload: !isAntiFraudPassing,
        idUpload: !isAntiFraudPassing,
      });
    }
  }, [
    image_64,
    proof_of_address,
    frontFaceImage,
    isAntiFraudPassing,
    isValidIdentification,
  ]);

  return (
    <Wrapper>
      {/* NOTE: Temporarily remove the popup modal to test which behaviour is better */}
      {/* <ScrapedDetailsConfirmModal {...props} /> */}
      <IDUpload
        {...props}
        resourceType={resourceType}
        hasError={errors.idUpload}
      />
      {isFaceMatchEnabled && (
        <BiometricCapture
          {...props}
          resourceType={resourceType}
          hasError={errors.bioUpload}
        />
      )}
      {isProofOfAddressVisible && (
        <ProofOfAdressUpload
          {...props}
          resourceType={resourceType}
          hasError={errors.poaUpload}
        />
      )}
    </Wrapper>
  );
}

IdentityCapture.propTypes = {
  apiType: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
};

IdentityCapture.defaultProps = {
  apiType: 'authorisations',
  resourceType: 'authorisation',
};

export default connect((state, ownProps) => {
  const { authorisation } = state;
  const identity = state.identity;
  const cobSection = state.cob_section;
  const s_browser = getBrowser(window.navigator.userAgent);
  const currentUser = new UserModel.fromCurrentUser(state.current_user);
  let authorisationID = ownProps.authorisationID;

  if (!ownProps.noInstantUpload) {
    authorisationID = getAuthorisationID({ ownProps, state });
  }

  const isIdentificationCheckRequired = getIsIdentificationCheckRequired({
    guarantor: ownProps.guarantor,
    isGuarantor: ownProps.isGuarantor,
    requiresID: ownProps.requiresID,
    state,
  });

  const {
    applicantSignatory,
    frontFaceImage,
    distinctId,
    imageUrl,
    isAntiFraudPassing,
    needDobAddress,
    proofOfAddress,
  } = ownProps;

  // const { id_name_matched } = identity;
  const { signature } = cobSection;

  let idNameMatched = true;

  if (applicantSignatory) {
    const signatoryName = get(
      applicantSignatory,
      'first_name',
      ''
    ).toLowerCase();
    let signatureName = get(
      signature,
      'formatted_scraped_details.first_name',
      ''
    ).toLowerCase();

    if (authorisation && authorisation.data) {
      signatureName = get(
        authorisation,
        'data.signature.attributes.formatted_scraped_details.first_name',
        ''
      ).toLowerCase();
    }

    if (signatureName) {
      idNameMatched = signatoryName.includes(signatureName);
    }
  }

  const isFaceMatchEnabled =
    ownProps.isFaceMatchEnabled || getIsFaceMatchEnabled(state);
  const isValidIdentification =
    ownProps.isValidIdentification || identity.is_valid_identification;
  const overrideIdValidation =
    ownProps.overrideIdValidation || identity.override_id_validation;

  return {
    authorisation,
    authorisationID,
    currentUser,
    distinctId,
    file_name: identity.file_name,
    file_size: identity.file_size,
    frontFaceImage: identity.front_face_image || frontFaceImage,
    identity,
    idNameMatched,
    image_64: identity.image_64 || imageUrl,
    isFaceMatchEnabled,
    isValidIdentification,
    // TODO - isAntiFraudPassing: isFaceMatchPassing || isProofOfLifePassing;
    isAntiFraudEnabled: !!cobSection.antiFraud,
    isAntiFraudPassing: identity.antiFraudCheckPassed,
    isIdentificationCheckRequired,
    isScrapeDetailsModalVisible: identity.isScrapeDetailsModalVisible,
    isTokenised: isTokenised(state),
    needDobAddress,
    noCamera: identity.noCamera,
    proof_of_address: identity.proof_of_address || proofOfAddress,
    overrideIdValidation,
    s_browser,
    signature,
    scraping: identity.scraping,
    scraping_result: identity.scraping_result,
  };
})(IdentityCapture);
