/* Import libraries */
import "!style-loader!css-loader!./css/ReactGridLayout.css";

import PlusButton from "modules/shared/components/inputs/PlusButton";
import { size } from "modules/shared/helpers/additionalSectionHelper";
import React, { useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

/* Import CSS */
import styles from "./css/FormBuilder.css";
/* Import components */
import FormBuilderComponentDraggableWrapper from 'modules/shared/components/widgets/interactive/form_builder/FormBuilderComponentDraggableWrapper';
import FormBuilderComponentEditor from "./FormBuilderComponentEditor";

const ResponsiveGridLayout = WidthProvider(Responsive);

function FormBuilder(props) {
  const {
    borderedStyle,
    editable,
    keyNamePrefix,
    module,
    onChange,
    showIndicatorOption,
    showFormTitle,
    ...formBuilderProps,
  } = props;

  const [components, setComponents] = useState(props.components || []);
  const [creatingComponent, setCreatingComponent] = useState(false);
  const [editingComponent, setEditingComponent] = useState(false);

  function showCreater() {
    setCreatingComponent(true);
    setEditingComponent(false);
  }

  function showUpdater() {
    setCreatingComponent(false);
    setEditingComponent(true);
  }

  function hideCreaterOrUpdater() {
    setCreatingComponent(false);
    setEditingComponent(false);
  }

  function existingDescriptions() {
    const descriptions = [];
    components.map(component => {
      if (!component.editing) {
        descriptions.push(
          component.field_description ||
            `${component.field_description_1}-${component.field_description_2}`,
        );
      }
    });
    return descriptions;
  }

  function createComponent(componentParams) {
    components.push(componentParams);
    setComponents(components);
    hideCreaterOrUpdater();
    onChange(components);
  }

  function updateComponent(componentParams, componentIndex) {
    components[componentIndex] = componentParams;
    setComponents(components);
    hideCreaterOrUpdater();
    onChange(components);
  }

  function editComponent(componentIndex) {
    showUpdater();
    components.forEach(component => {
      delete component.editing;
    });
    components[componentIndex].editing = true;
    setComponents(components);
  }

  function cancelEditComponent() {
    hideCreaterOrUpdater();
    components.forEach(component => {
      delete component.editing;
    });
    setComponents(components);
  }

  function deleteComponent(componentIndex) {
    components.splice(componentIndex, 1);
    setComponents(components);
    onChange(components);
  }

  function arrangeComponent(layout) {
    const sortedComponents = [];

    const newOrder = layout.sort((element1, element2) => {
      if (element1.y > element2.y) return 1;
      if (element1.y === element2.y && element1.x > element2.x) return 1;
      return -1;
    });
    newOrder.forEach(element => {
      const fieldDescription = element.i.replace(/.*?_.*?_/, "");
      const component = components.find(
        component =>
          component.field_description === fieldDescription ||
          `${component.field_description_1}-${component.field_description_2}` ===
            fieldDescription,
      );
      sortedComponents.push(component);
    });
    setComponents(components);
    onChange(sortedComponents);
  }

  function renderComponents() {
    const elements = [];
    const layouts = { md: [] };
    let currentRow = 0;
    let currentCol = 0;
    let lastComponentRequireRows = 0;
    (components || []).forEach((component, index) => {
      const descriptions =
        component.field_description ||
        `${component.field_description_1}-${component.field_description_2}`;
      const theKey = `${index}_${keyNamePrefix}_${descriptions}`;
      const componentSize = size(component);
      const componentRequireCols = componentSize.cols;
      const componentRequireRows = componentSize.rows;

      const notEnoughSpace = currentCol + componentRequireCols > 4;
      if (notEnoughSpace) {
        currentRow = currentRow + lastComponentRequireRows;
        currentCol = 0;
      }

      layouts.md.push({
        h: componentRequireRows,
        i: theKey,
        isResizable: false,
        static:
          editingComponent ||
          creatingComponent ||
          !editable,
        w: componentRequireCols,
        x: currentCol,
        y: currentRow,
      });

      let element = (
        <FormBuilderComponentDraggableWrapper
          borderedStyle={borderedStyle}
          formParams={component}
          handleEdit={() => editComponent(index)}
          handleDelete={() => deleteComponent(index)}
          editable={editable}
        />
      );
      if (component.editing) {
        element = (
          <FormBuilderComponentEditor
            onSubmit={params => updateComponent(params, index)}
            onCancel={cancelEditComponent}
            existingDescriptions={existingDescriptions()}
            showIndicatorOption={showIndicatorOption}
            componentParams={component}
            module={module}
            borderedStyle={borderedStyle}
            showFormTitle={showFormTitle}
            {...formBuilderProps}
          />
        );
      }
      elements.push(<div key={theKey}>{element}</div>);

      currentCol = currentCol + componentRequireCols;
      lastComponentRequireRows = componentRequireRows;
    });
    return (
      <ResponsiveGridLayout
        className="layout"
        useCSSTransforms={false}
        compactType={"vertical"}
        layouts={layouts}
        breakpoints={{ md: 0 }}
        cols={{ md: 4 }}
        rowHeight={36}
        onLayoutChange={layout => arrangeComponent(layout)}
        isResizable={false}
      >
        {elements}
      </ResponsiveGridLayout>
    );
  }

  const creating = creatingComponent;
  const editing = editingComponent;

  return (
    <div className={styles.form_builder_content}>
      <div className={styles.row}>
        {renderComponents()}
        {editable && !creating && !editing && (
          borderedStyle ? (
            <a
              className="has-text-primary"
              role="button"
              onClick={showCreater}
            >
              + Add field
            </a>
          ) :
          (
            <PlusButton
              onClick={showCreater}
              cssStyle={"button_float_right"}
            />
          )
        )}
      </div>
      {creating && (
        <FormBuilderComponentEditor
          onSubmit={params => createComponent(params)}
          onCancel={hideCreaterOrUpdater}
          showIndicatorOption={showIndicatorOption}
          existingDescriptions={existingDescriptions()}
          module={module}
          borderedStyle={borderedStyle}
          showFormTitle={showFormTitle}
          {...formBuilderProps}
        />
      )}
    </div>
  );
}

export default FormBuilder;
