import get from 'lodash.get';
import { failedApplicationStart } from 'modules/dashboard/actions';
/* Import components */
import ToolTip from 'modules/shared/components/widgets/interactive/ToolTip';
import { isMobileNew } from 'modules/shared/helpers/mobileDetect';
/* Import libraries */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { browserHistory, withRouter } from 'react-router';
import isBlank from 'utils/isBlank';
import { checkPopupExist, getPopup } from 'utils/tipHelper.js';
import NotificationIndicator from 'modules/notifications/components/NotificationIndicator';
import Modal from 'modules/dashboard/containers/components/Modal';
/* Import actions */
import {
  getCurrentPricingPlan,
  showPricingPlan,
} from '../../../pricing-plans/actions';
import { deleteUserPopup } from '../../../user/actions';
/* Import CSS */
import styles from '../css/DashboardContainer.css';
import MenuItem from './MenuItem';
import SubMenuItem from './SubMenuItem';
import MenuItemIndicator from './MenuItemIndicator';
import isPresent from 'utils/isPresent';
import { compose } from 'redux';

/* eslint-disable sort-keys-fix/sort-keys-fix */
const MODULES_MENU_MAPPING = {
  direct_debit_module: {
    path: '/dashboard/direct_debit_authorisations',
    title: 'Direct debit module',
  },
  card_management_module: {
    path: '/dashboard/card_orders',
    title: 'Card mgmt module',
  },
  ppsr_module: {
    path: '/dashboard/ppsr_registrations',
    title: 'PPSR module',
  },
  alert_module: {
    path: '/dashboard/alert_applications',
    title: 'Alerts module',
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

function CreditControlMenu(props) {
  const {
    active,
    currentUser,
    navActive,
    onClickLink,
    onToggleDropDown,
    visible,
  } = props;

  if (!visible) {
    return null;
  }

  const roleTypes = get(
    currentUser,
    'current_user_entity_link.attributes.role_types'
  );
  const modulesConfigured = get(
    currentUser,
    'current_entity.attributes.modules_configured',
    []
  );

  if (
    (!roleTypes.includes('admin') && !roleTypes.includes('approver')) ||
    isBlank(modulesConfigured)
  ) {
    return null;
  }

  const onClickTarget = () => {
    onToggleDropDown('credit_control');
  };

  const subMenuItems = modulesConfigured.map((module, index) => {
    const menuMapping = MODULES_MENU_MAPPING[module];

    return (
      <SubMenuItem
        key={`module-sub-menu-item-${index + 1}`}
        title={menuMapping.title}
        id={module}
        target={() => onClickLink(menuMapping.path, 'credit_control')}
      />
    );
  });

  return (
    <MenuItem
      title={'Credit control'}
      visible={true}
      active={active}
      navActive={navActive}
      icon={'credit_control'}
      target={onClickTarget}
    >
      {subMenuItems}
    </MenuItem>
  );
}

class NavMenu extends React.Component {
  state = {
    active: null,
    application_type: null,
    isFirstApplicationWithPermission: false,
    isTooltipActive: false,
    navActive: null,
    navToggle: false,
    showModal: false,
  };

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    let navActive = null;
    if (document.location.pathname === '/dashboard/home') {
      navActive = 'home';
    } else if (
      document.location.pathname.indexOf('/dashboard/leads/list') > -1
    ) {
      navActive = 'leads';
    } else if (
      document.location.pathname.indexOf('/dashboard/reporting') > -1
    ) {
      navActive = 'applications';
    } else if (
      document.location.pathname.indexOf('dashboard/connections') > -1 ||
      document.location.pathname === '/dashboard/map'
    ) {
      navActive = 'connections';
    } else if (document.location.pathname === '/dashboard/addons') {
      navActive = 'addons';
    } else if (
      document.location.pathname === '/dashboard/account-settings' ||
      document.location.pathname === '/dashboard/connected-apps/apps' ||
      document.location.pathname === '/dashboard/profile' ||
      document.location.pathname === '/dashboard/pricing-plans'
    ) {
      navActive = 'settings';
    } else if (document.location.pathname === '/dashboard/addons') {
      navActive = 'addons';
    } else if (
      document.location.pathname === '/dashboard/direct_debit_authorisations'
    ) {
      navActive = 'credit_control';
    }
    if (navActive !== nextState.navActive) {
      this.setState({
        navActive,
      });
    }
  }

  handleBlur = () => {
    this.setState({
      active: null,
    });
  };

  handleLinkClick = (url, name) => {
    const { location, addon_enabled } = this.props;

    if (url === '/dashboard/addons' && name === 'addons' && !addon_enabled) {
      // ! TODO
      this.setState({ showModal: true });
      return;
    }

    const { navToggle } = this.state;
    this.setState({
      active: null,
      navActive: name,
      navToggle: false,
    });
    browserHistory.push({
      pathname: url,
      state: {
        referrer: location.pathname,
        userTriggered: true,
      },
    });
  };

  hideIsFirstApplicationWithPermission = () => {
    const { dispatch, current_user_popups } = this.props;
    const { application_type } = this.state;
    const popup = getPopup(
      current_user_popups,
      'navbar_start_application_permissions'
    );
    if (popup) {
      dispatch(deleteUserPopup(popup.id));
    }
    this.setState({ isFirstApplicationWithPermission: false });
    if (application_type === 'credit') {
      browserHistory.push('/dashboard/invite/credit/company');
    } else {
      browserHistory.push('/dashboard/invite/cash/company');
    }
  };

  hideTooltip = () => {
    const { dispatch, current_user_popups } = this.props;
    const popup = getPopup(current_user_popups, 'new_feature_website_button');
    if (popup) {
      dispatch(deleteUserPopup(popup.id));
    }
    this.setState({ isTooltipActive: false });
  };

  isAdmin = () => {
    const { current_user } = this.props;

    return (
      current_user.current_user_entity_link.attributes.role_types.includes(
        'admin'
      ) || this.isSuperAdmin()
    );
  };

  isConsumer = () => {
    const { current_entity } = this.props;
    return !!current_entity.attributes.consumer;
  };

  isConsumerOnly = () => {
    const { current_user } = this.props;
    const roleTypes =
      current_user.current_user_entity_link.attributes.role_types;

    return roleTypes.length === 1 && roleTypes.includes('consumer_manager');
  };

  isSuperAdmin = () => {
    const { current_user } = this.props;

    return get(current_user, 'data.data.attributes.super_admin', false);
  };

  isSupplier = () => {
    const { current_entity } = this.props;
    return !!current_entity.attributes.supplier;
  };

  navToggleClick = (v) => {
    const { navToggle } = this.state;
    this.setState({
      active: null,
      navToggle: !navToggle,
    });
  };

  onboarding_completed = () => {
    const { current_entity } = this.props;
    if (current_entity != null) {
      return !current_entity.attributes.supplier_onboarding_checklist;
    }
    return false;
  };

  render() {
    const {
      current_user,
      current_entity,
      current_user_popups,
      displayUserProfile,
    } = this.props;
    const {
      active,
      navToggle,
      navActive,
      isTooltipActive,
      isFirstApplicationWithPermission,
      showModal,
    } = this.state;
    let start_application_btn = (
      <MenuItem
        title={'Send Application'}
        visible={true}
        active={active === 'startApplication'}
        navActive={navActive === 'startApplication'}
        icon={'application'}
        style={`${styles.link} ${styles.hidden_sm_md}`}
        target={() => this.toggleDropDown('startApplication')}
      >
        <SubMenuItem
          title={'Credit'}
          id={'creditApplication'}
          target={this.startApplicationClick.bind(null, 'credit')}
        />
        <SubMenuItem
          title={'Cash'}
          id={'cashApplication'}
          target={this.startApplicationClick.bind(null, 'cash')}
        />
      </MenuItem>
    );

    const settingsMenu = (
      <MenuItem
        title={'Settings'}
        visible={true}
        active={active === 'settings'}
        navActive={navActive === 'settings'}
        icon={'setting'}
        target={() => this.toggleDropDown('settings')}
        showTooltip={this.showTooltip}
      >
        {!isMobileNew() && (
          <Fragment>
            <SubMenuItem
              title="Suppliers"
              id="profile"
              target={() =>
                this.handleLinkClick(
                  '/dashboard/supplier_list',
                  'supplier_list'
                )
              }
              visible={this.isSuperAdmin()}
            />
            <SubMenuItem
              title="Your Profile"
              id="profile"
              target={() =>
                this.handleLinkClick('/dashboard/users/profile', 'profile')
              }
              visible={displayUserProfile}
            />
            <SubMenuItem
              title="Business Profile"
              id="profile"
              target={() =>
                this.handleLinkClick('/dashboard/profile', 'profile')
              }
              visible={this.isAdmin() && this.isSupplier()}
            />
            <SubMenuItem
              title={'Account'}
              id={'account'}
              target={() =>
                this.handleLinkClick('/dashboard/account-settings', 'settings')
              }
              visible={this.isAdmin() && this.isSupplier()}
              hiddenxs={true}
            />
            <SubMenuItem
              title={'Pricing'}
              id={'pricingPlans'}
              target={() =>
                this.handleLinkClick('/dashboard/pricing-plans', 'settings')
              }
              visible={this.isAdmin() && this.isSupplier()}
              hiddenxs={true}
            />
            <SubMenuItem
              title={'Integrations'}
              id={'connectedApps'}
              target={() =>
                this.handleLinkClick(
                  '/dashboard/connected-apps/webhooks',
                  'settings'
                )
              }
              visible={this.isAdmin() && this.isSupplier()}
              hiddenxs={true}
            />
          </Fragment>
        )}
        <SubMenuItem
          title={'Log Out'}
          id={'logout'}
          target={() => this.handleLinkClick('/user/logout', 'settings')}
        />
      </MenuItem>
    );

    /** Commented out due to the new designs but not totally removed for reference */
    // const connectionsMenu = (
    //   <MenuItem
    //     title={"Connections"}
    //     visible={true}
    //     active={active === "connections"}
    //     navActive={navActive === "connections"}
    //     icon={"connection"}
    //     target={() => this.toggleDropDown("connections")}
    //   >
    //     <SubMenuItem
    //       title={"Connections"}
    //       id={"connections"}
    //       target={() =>
    //         this.handleLinkClick("/dashboard/connections/all", "connections")
    //       }
    //     />
    //     {!isHeadquarter() && (
    //       <SubMenuItem
    //         title={"Customer Mapping"}
    //         id={"mapping"}
    //         target={() => this.handleLinkClick("/dashboard/map", "connections")}
    //         visible={this.isSupplier()}
    //         hiddenxs={true}
    //       />
    //     )}
    //   </MenuItem>
    // );

    if (!current_entity.attributes.supplier) {
      start_application_btn = null;
    }

    let tool_tip;
    if (
      isTooltipActive &&
      this.isAdmin() &&
      checkPopupExist(current_user_popups, 'new_feature_website_button') &&
      !isMobileNew()
    ) {
      tool_tip = (
        <ToolTip
          tip_name="SettingsWebsiteButton"
          css_style="settings_website_button"
          dismissHandler={this.hideTooltip}
        />
      );
    }

    const onDashboardClick = () => {
      if (this.isConsumerOnly()) {
        this.handleLinkClick('/dashboard/reporting', 'home');
      } else {
        this.handleLinkClick('/dashboard/home', 'home');
      }
    };

    return (
      <nav className={styles.nav}>
        {showModal && (
          <Modal
            onClose={() => this.setState({ showModal: false })}
            content={
              <React.Fragment>
                Add on functions are not activated. Please contact{' '}
                <a
                  href="mailto:support@1centre.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  support@1centre.com
                </a>{' '}
                to activate add ons for you.
              </React.Fragment>
            }
            footer={
              <a
                className="button is-primary is-rounded is-size-5"
                href="mailto:support@1centre.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact us
              </a>
            }
          />
        )}

        <div className={styles.nav_mobile}>
          {/* <i className={!navToggle ? styles.nav_toggle : styles.nav_toggle_close} onClick={this.navToggleClick}></i> */}
          <div
            className={!navToggle ? styles.nav_toggle : styles.nav_toggle_close}
            onClick={this.navToggleClick}
          >
            <span className={styles.lines}></span>
          </div>
        </div>
        <ul
          className={
            navToggle
              ? styles.nav_list
              : `${styles.nav_list} ${styles.hidden_xs_sm}`
          }
        >
          {start_application_btn}
          <MenuItemIndicator
            title={this.props.username}
            visible={!isMobileNew()}
            icon={'notification'}
          />

          <MenuItem
            title={'Dashboard'}
            visible={this.isSupplier()}
            active={active === 'home'}
            navActive={navActive === 'home'}
            icon={'home'}
            target={onDashboardClick}
          />
          <MenuItem
            title="Pipeline"
            visible={isMobileNew()}
            active={active === 'applications'}
            navActive={navActive === 'applications'}
            icon={'application'}
            target={() =>
              this.handleLinkClick('/dashboard/reporting', 'applications')
            }
          />
          <MenuItem
            title={current_entity.attributes.lead_name || 'Leads'}
            visible={this.isSupplier()}
            active={active === 'leads'}
            navActive={navActive === 'leads'}
            icon={'lead'}
            target={() =>
              this.handleLinkClick('/dashboard/leads/list', 'leads')
            }
          />
          <MenuItem
            title="Pipeline"
            visible={!isMobileNew()}
            active={active === 'applications'}
            navActive={navActive === 'applications'}
            icon={'application'}
            target={() =>
              this.handleLinkClick('/dashboard/reporting', 'applications')
            }
          />
          <CreditControlMenu
            active={active === 'credit_control'}
            navActive={navActive === 'credit_control'}
            currentUser={current_user}
            onClickLink={this.handleLinkClick}
            onToggleDropDown={this.toggleDropDown}
            visible={!isMobileNew()}
          />
          <MenuItem
            title={'Add Ons'}
            visible={this.isAdmin() && this.isSupplier() && !isMobileNew()}
            active={active === 'addons'}
            navActive={navActive === 'addons'}
            icon={'addons'}
            target={() => this.handleLinkClick('/dashboard/addons', 'addons')}
          />
          {settingsMenu}
        </ul>
        {tool_tip}
        {isFirstApplicationWithPermission && (
          <ToolTip
            tip_name="FirstApplicationWithPermission"
            css_style="first_application_with_permission"
            dismissHandler={this.hideIsFirstApplicationWithPermission}
          />
        )}
      </nav>
    );
  }

  showTooltip = () => {
    this.setState({ isTooltipActive: true });
  };

  startApplicationClick = (type) => {
    const { dispatch, current_user_popups } = this.props;
    const { navToggle } = this.state;
    this.setState({
      active: null,
      navToggle: false,
    });
    const that = this;
    if (this.onboarding_completed()) {
      dispatch(
        getCurrentPricingPlan(null, function(data) {
          if (
            data.attributes.plan_no == 0 ||
            data.attributes.application_tally < data.attributes.application_max
          ) {
            if (
              checkPopupExist(
                current_user_popups,
                'navbar_start_application_permissions'
              )
            ) {
              that.setState({
                application_type: type,
                isFirstApplicationWithPermission: true,
              });
            } else {
              browserHistory.push(`/dashboard/invite/${type}`);
            }
          } else {
            dispatch(showPricingPlan(true, 'application'));
          }
        })
      );
    } else {
      // toggle pulse animation
      dispatch(failedApplicationStart());
      browserHistory.push('/dashboard/home');
    }
  };

  toggleDropDown = (id) => {
    const { active } = this.state;
    if (active === id) {
      this.setState({
        active: null,
      });
    } else {
      this.setState({
        active: id,
      });
    }
    if (id === 'settings') {
      this.showTooltip();
    }
  };
}

export default compose(
  withRouter,
  connect((state) => {
    let username;
    const currentUserProfile = get(
      state,
      'manage_profile.current_user_profile.attributes'
    );
    const profileNoData = get(currentUserProfile, 'noData', null);

    if (state.current_user.data.hasOwnProperty('data')) {
      username = state.current_user.data.data.attributes.first_name;
    }

    if (!username) {
      username = state.current_user.data.data.attributes.full_name;
    }

    return {
      current_user_popups: state.current_user.current_user_popups,
      username,
      addon_enabled:
        state.current_user.current_entity.attributes.addon_enabled || false,
      displayUserProfile: !!(
        currentUserProfile && typeof profileNoData !== 'boolean'
      ),
    };
  })
)(NavMenu);
