import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import get from 'lodash.get';
import api from 'api';

export const getTradingNames = ({
  currentUser,
  errorCallback,
  successCallback,
  supplierId,
  channel,
}) => {
  const entityAPI = api('entities', currentUser.accessToken);

  entityAPI.getTradingNames(
    supplierId,
    (result) => {
      const retrievedTradingNames = get(result, 'data.data');

      successCallback(retrievedTradingNames);
    },
    (error) => {
      console.error(error);
      errorCallback(error);
    },
    {
      params: {
        channel,
      },
    }
  );
};

export const useTradingNamesState = (supplierAccountType) => {
  const [tradingNames, setTradingNames] = useState([]);

  const { tradingNameOptions, tradingNameRawOptions } = buildTradingNameOptions(
    {
      supplierAccountType,
      tradingNames,
    }
  );

  return {
    setTradingNames,
    tradingNameOptions,
    tradingNameRawOptions,
  };
};

const build1AccountTradingNameOptions = (
  tier2TradingNames,
  tier3TradingNames
) => {
  const tradingNameOptions: Array<any> = [];
  const tradingNameRawOptions: Array<any> = [];

  const dividerIndexLimit = tier2TradingNames.length - 1;
  let index = 0;

  for (const tradingName of tier2TradingNames) {
    const tier2Content = buildTradingNameContent(tradingName);
    const tier3Contents = buildTier3TradingNameOptions(
      tradingName.entity_id,
      tier3TradingNames
    );

    tradingNameOptions.push(tier2Content.option);
    tradingNameRawOptions.push(tier2Content.rawOption);

    tradingNameOptions.push(
      ...tier3Contents.map((tradingName) => tradingName.option)
    );
    tradingNameRawOptions.push(
      ...tier3Contents.map((tradingName) => tradingName.rawOption)
    );

    if (index < dividerIndexLimit) {
      tradingNameRawOptions.push(
        <hr key={`hr-${index}`} className="dropdown-divider" />
      );
    }

    index += 1;
  }

  return { tradingNameOptions, tradingNameRawOptions };
};

const buildRegularAccountTradingNameOptions = (tradingNames) => {
  const tradingNameOptions = (tradingNames || []).map((tradingName) => {
    const { option } = buildTradingNameContent(tradingName);
    return option;
  });

  return { tradingNameOptions, tradingNameRawOptions: null };
};

const buildTradingNameOptions = ({ supplierAccountType, tradingNames }) => {
  if (supplierAccountType === 'Regular') {
    return buildRegularAccountTradingNameOptions(tradingNames);
  }

  const tier2TradingNames = tradingNames.filter(
    (tradingName) => tradingName.type === 'Region'
  );
  const tier3TradingNames = tradingNames.filter(
    (tradingName) => tradingName.type === 'Branch'
  );

  return build1AccountTradingNameOptions(tier2TradingNames, tier3TradingNames);
};

const buildTier3TradingNameOptions = (tier2Id, tier3tradingNames) => {
  const tradingNames = tier3tradingNames.filter(
    (tradingName) => tradingName.parent_id === tier2Id
  );

  return tradingNames.map((tradingName) =>
    buildTradingNameContent(tradingName)
  );
};

const buildTradingNameContent = (tradingName) => {
  const label = tradingName.trading_name;
  let processedLabel = label;

  if (tradingName.type === 'Branch') {
    processedLabel = (
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {label}
      </span>
    );
  }

  const value = tradingName.id;
  const option = { label, value };
  const rawOption = (
    <MenuItem key={`menu-item-${value}`} value={value}>
      {processedLabel}
    </MenuItem>
  );

  return { option, rawOption };
};
