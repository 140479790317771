import api from 'api';
import * as _ from 'lodash';
import get from 'lodash.get';
import UserModel from 'models/UserModel';
import { OnboardingChecklistContext } from 'modules/consumer-onboarding/v2/Container';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import React, { ReactElement, useCallback, useContext, useState } from 'react';
import { connect } from 'react-redux';

import {
  CheckboxContainer,
  CheckboxWrapper,
  LinkWrapper,
  TitleWrapper,
  Wrapper,
} from './styles';
import { FormValues } from './types';

const DEBOUNCE_TIMEOUT = 3000;

function OverlayChecklist({
  applicationId,
  checklist,
  customisedMessage,
  currentUser,
  links,
}: FormValues): ReactElement {
  const [checklistState, setChecklistState] = useState<Array<any>>(checklist);
  const { onTickChecklist } = useContext(OnboardingChecklistContext);

  let checklistCallback: null | ((payload: any) => void) = null;
  if (onTickChecklist) {
    checklistCallback = onTickChecklist;
  } else {
    checklistCallback = checklistApi;
  }
  const debouncedCallback = useCallback(
    _.debounce((payload, callback) => callback(payload), DEBOUNCE_TIMEOUT),
    []
  );

  const toggleSelection = async (label: string) => {
    const currentChecklistState = checklistState;
    const tickedItem = currentChecklistState.find(
      (item) => item.label === label
    );

    tickedItem['value'] = tickedItem['value'] === '' ? 'true' : '';

    setChecklistState([...currentChecklistState]);

    debouncedCallback(
      {
        accessToken: currentUser.accessToken,
        applicationId,
        checklistState: currentChecklistState,
      },
      checklistCallback
    );
  };

  return (
    <Wrapper>
      <div className="columns is-multiline">
        <div className="column is-12">
          <p>
            {customisedMessage
              ? customisedMessage
              : 'Welcome! Please make sure you have the documents below.'}
          </p>
        </div>
        {checklistState.length > 0 && (
          <div className="column is-12">
            <CheckboxWrapper className="columns is-multiline">
              {checklistState.map(({ label, value }, index) => (
                <div
                  className="column is-12"
                  key={`checklist-checkbox-render-${index}`}
                >
                  <CheckboxContainer>
                    <SquareCheckbox
                      checkboxId={`checklist-item-${index}`}
                      checked={value === 'true'}
                      handleChange={() => toggleSelection(label!)}
                      key={`checklist-item-${index}`}
                      label={`${label}`}
                    />
                  </CheckboxContainer>
                </div>
              ))}
            </CheckboxWrapper>
          </div>
        )}
        {links.length > 0 && (
          <div className="column is-12">
            <TitleWrapper>Links</TitleWrapper>
            {links.map(({ label, value }, index) => (
              <div key={`link-render-${index}`}>
                <LinkWrapper>
                  <a href={value} target="_blank" rel="noopener noreferrer">
                    {label || value}
                  </a>
                </LinkWrapper>
              </div>
            ))}
          </div>
        )}
      </div>
    </Wrapper>
  );
}

async function checklistApi({ accessToken, applicationId, checklistState }) {
  const applicationAPI = api('applications', accessToken);

  try {
    await applicationAPI.updateChecklist(applicationId, checklistState);
  } catch (error) {
    console.error(error);
  }
}

export default connect((state) => {
  const currentUser = get(state, 'current_user', {});

  return {
    currentUser: UserModel.fromCurrentUser(currentUser),
  };
})(OverlayChecklist);
