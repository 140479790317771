/* eslint-disabled max-lines */
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import PreviewSaveButtons from 'modules/addons/addons_form/components/PreviewSaveButtons';
import { DigitalOnboardingContext } from 'modules/addons/components//DigitalOnboarding';
import styles from 'modules/addons/components/css/DirectDebitModuleComponents.css';
import { DigitalOnboardingItem } from 'modules/addons/components/DigitalOnboarding';
import EmbeddedComponents from 'modules/addons/sales_channels/shared/EmbeddedComponents';
import { getCardManagementModuleLink } from 'modules/addons/helpers';
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import FormCheckBox from 'modules/shared/components/inputs/FormCheckBox';
import FormRadioBox from 'modules/shared/components/inputs/FormRadioBox';
import UncontrolledTextInput from 'modules/shared/components/inputs/UncontrolledTextInput';
import FormBuilder from 'modules/shared/components/widgets/interactive/form_builder/FormBuilder';
import React, { Fragment, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import extractAttachmentUrl from 'utils/extractAttachmentUrl';
import * as yup from 'yup';

import ContentContainer from 'modules/shared/components/v2/ContentContainer';
import Header from './PPSRRegistration/forms/Header';
import { loadAmlCheckRules } from 'modules/profile/actions';

const SIGNATURE_PATH = '/dashboard/profile?active_tab=account_rules';
const EMAIL_PATH = '/dashboard/profile?active_tab=email_customisation';
const CardIssuingReasons = [
  { label: 'New', value: 'New card' },
  { label: 'Lost', value: 'Lost card' },
  { label: 'Additional', value: 'Additional card' },
  { label: 'Damaged', value: 'Damaged card' },
  { label: 'Stolen', value: 'Stolen card' },
  { label: 'Limit increase', value: 'Limit increase' },
  { label: 'Cancellation', value: 'Cancellation' },
];

const Togglers = [{ label: 'On', value: 'on' }, { label: 'Off', value: 'off' }];

const formSchema = yup.object().shape({
  accountName: yup.string().required('Please enter account name label'),
  accountNumber: yup.string().required('Please enter account number label'),
  cardIssuingReasons: yup
    .object()
    .test(
      'cardIssuingReasons',
      'Please select at least one reason',
      (obj) => Object.keys(obj).filter((k) => obj[k]).length > 0
    ),
  cardLimitEnabled: yup.string().required('Please select one of the option'),
  signatureRequired: yup.string().required('Please select one of the option'),
  terms: yup.string().required('Please enter terms'),
});

function Item(props) {
  const {
    addonConfig,
    currentUser,
    onUpdateModuleItem,
    isCardAddonConfigured,
  } = props;

  const { onSetAddonToEdit } = useContext(DigitalOnboardingContext);
  const onClickEdit = () => onSetAddonToEdit(addonConfig);

  const onSuccessCallback = (updatedAddonConfig) => {
    mixpanel.track('Update Card management module ruleset', {
      'Entity ID': get(currentUser, 'currentEntity.id'),
      Ruleset: updatedAddonConfig.data,
      'Ruleset ID': updatedAddonConfig.id,
      distinct_id: currentUser.id,
    });

    onUpdateModuleItem(updatedAddonConfig);
  };

  const onClickToggleState = () => {
    addonConfig.setAttribute('active', !addonConfig.active);
    addonConfig.update({ currentUser, onSuccessCallback });
  };

  return (
    <DigitalOnboardingItem
      activeState={addonConfig.active}
      areActionButtonsVisible={isCardAddonConfigured}
      body="Manage your existing cardholder requirements here. Includes lost, stolen, damaged, replacement cards and more."
      formattedState={addonConfig.formattedActiveState}
      header="Card management module"
      isConfigured={addonConfig.isConfigured}
      isEnabled={isCardAddonConfigured}
      onClickEdit={isCardAddonConfigured ? onClickEdit : null}
      onClickToggleState={onClickToggleState}
    />
  );
}

function getNewVersionAttribute(addonConfig, data) {
  const attributes = {
    config: {
      account_name: data.accountName,
      account_number: data.accountNumber,
      additional_fields: data.additional_fields,
      additional_fields_general: data.additional_fields_general,
      card_issuing_reasons: data.cardIssuingReasons,
      card_limit_enabled: data.cardLimitEnabled,
      signature_required: data.signatureRequired,
      terms: data.terms,
    },
  };

  if (addonConfig.isPersisted) {
    attributes.addon_config_id = addonConfig.id;
  } else {
    attributes.name = 'Card management module';
    attributes.addon_module_name = 'card_management_module';
  }

  return attributes;
}

function Edit(props) {
  const {
    addonConfig,
    currentUser,
    defaultCardsAdditionalFields,
    defaultCardsAdditionalFieldsGeneral,
    dispatch,
    onRefreshDigitalOnboardingModules,
    signatureRequired,
  } = props;

  const { onClickBack } = useContext(DigitalOnboardingContext);
  const { errors, setValue, control, handleSubmit, register, watch } = useForm({
    mode: 'onBlur',
    validationSchema: formSchema,
  });

  const currentEntity = currentUser.currentEntity || {};
  const logo = extractAttachmentUrl(currentEntity.logo, 'logo');
  const newVersion = addonConfig.generateNewVersion();
  const versionConfig = newVersion.config;
  if (Object.keys(newVersion.attributes).length === 0) {
    versionConfig.additional_fields = defaultCardsAdditionalFields;
  }
  const hasCardLimitChecked =
    watch('cardLimitEnabled') || versionConfig.card_limit_enabled;
  const hasSignatureRequiredChecked =
    signatureRequired &&
    (watch('signatureRequired') || versionConfig.signature_required);

  useEffect(() => {
    register({ name: 'additional_fields' });
    register({ name: 'additional_fields_general' });
    dispatch(loadAmlCheckRules());
  }, [register]);

  useEffect(() => {
    setValue(
      'additional_fields',
      versionConfig.additional_fields || defaultCardsAdditionalFields
    );
    setValue(
      'additional_fields_general',
      versionConfig.additional_fields_general ||
        defaultCardsAdditionalFieldsGeneral
    );
  }, []);

  const onSuccessCallback = (addonVersion) => {
    mixpanel.track('Configure Card management module', {
      'Entity ID': get(currentUser, 'currentEntity.id'),
      Ruleset: addonVersion.data,
      distinct_id: currentUser.id,
    });

    onRefreshDigitalOnboardingModules();
    onClickBack();
  };

  const onSubmit = (data) => {
    const attributes = getNewVersionAttribute(addonConfig, data);
    newVersion.setAttributes(attributes);
    newVersion.save({
      addonConfig,
      currentUser,
      onSuccessCallback,
    });
  };

  const onClickCloseButton = () => {
    onClickBack();
  };

  const onClickEmailLink = () => {
    browserHistory.push(EMAIL_PATH);
  };

  const onClickSignatureLink = () => {
    browserHistory.push(SIGNATURE_PATH);
  };

  const websiteButtonLink = getCardManagementModuleLink(currentEntity.id);

  let processedCardIssuingReasons = [...CardIssuingReasons];

  const handleChange = (callback, openModalCallback) => {
    // TODO - better way to handle passing data to preview, hack to improve performance
    handleSubmit((data) => {
      const newUnsavedAttributes = getNewVersionAttribute(addonConfig, data)
        .config;
      callback(newUnsavedAttributes);
      openModalCallback();
    })();
  };

  processedCardIssuingReasons = [...CardIssuingReasons].map((reason) => {
    if (reason.value === 'Limit increase') {
      reason['disabled'] =
        !hasCardLimitChecked || hasCardLimitChecked === 'off';
    }

    return reason;
  });

  return (
    <div className={styles.container}>
      <Header addonModuleName="card_management_module" />
      <CloseButton handleClick={onClickCloseButton} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div styled={{ counterReset: 'header' }}>
          <ContentContainer
            header="Monthly card limit"
            description={`Turning on the 'monthly card limit' will allow the applicant
              to add a monthly card limit onto each individual card applied for. If you set
              this to off, this question will not be populated.
            `}
          >
            <FormRadioBox
              control={control}
              options={Togglers}
              register={register}
              key="cardLimitEnabled"
              id="cardLimitEnabled"
              name="cardLimitEnabled"
              defaultValue={
                versionConfig.card_limit_enabled || hasCardLimitChecked
              }
              error={get(errors, 'cardLimitEnabled.message')}
            />
          </ContentContainer>
          <ContentContainer
            header="Signature"
            description={
              <Fragment>
                Turning on the &apos;signature&apos; function will require each
                individual cardholder to sign for their own card. Click{' '}
                <Link onClick={onClickSignatureLink}>here</Link> to go through
                to your identification options - profile tab &gt; account rules
                &gt; antifraud or AML. Our intelligent workflows will reach out
                to your cardholders for agreement and signing. If turned off,
                the applicant is signing for all cards to be issued.
              </Fragment>
            }
          >
            <FormRadioBox
              control={control}
              options={Togglers}
              register={register}
              key="signatureRequired"
              id="signatureRequired"
              name="signatureRequired"
              defaultValue={
                versionConfig.signature_required || hasSignatureRequiredChecked
              }
              error={get(errors, 'signatureRequired.message')}
            />
          </ContentContainer>
          {hasSignatureRequiredChecked && (
            <ContentContainer
              header="Card details"
              description={`'Card details' are the mandatory questions asked from the
                  applicant and cardholders (if required) at an individual card level.
                  They are dynamically populated based on your signatory requirements.
                  You can also ask any additional questions needed at a card level, use
                  the '+' button in this section to customise and add required questions.
                `}
            >
              <div style={{ marginBottom: '1.5rem' }}>
                {hasSignatureRequiredChecked === 'on' && (
                  <Fragment>
                    <div>First name</div>
                    <div>Middle name</div>
                    <div>Last name</div>
                    <div>Email</div>
                    <div>Phone</div>
                    <div>Card delivery address</div>
                    <div>Identification</div>
                  </Fragment>
                )}
                {hasSignatureRequiredChecked === 'off' && (
                  <div>Name to appear on card</div>
                )}
              </div>
              <FormBuilder
                module="card_management"
                components={versionConfig.additional_fields}
                editable={true}
                onChange={(fields) => {
                  setValue('additional_fields', fields);
                }}
              />
            </ContentContainer>
          )}
          <ContentContainer
            header="Account details"
            description={`Input the account name and number the applicant will recognise
              for your business. They will be asked this information first and prompted to
              complete, so you can easily recognise who the card/s belongs too. You can also
              ask any questions needed at an account level, use the '+' button in this section
              to customise and add required questions.
            `}
          >
            <div className="columns is-multiline">
              <div className="column is-12 is-6-desktop">
                <UncontrolledTextInput
                  id="accountName"
                  label="Account name label"
                  error={get(errors, 'accountName.message')}
                  inputRef={register}
                  defaultValue={versionConfig.account_name}
                />
              </div>
              <div className="column is-12 is-6-desktop">
                <UncontrolledTextInput
                  id="accountNumber"
                  label="Account number label"
                  error={get(errors, 'accountNumber.message')}
                  inputRef={register}
                  defaultValue={versionConfig.account_number}
                />
              </div>
            </div>
            <div>
              <FormBuilder
                module="card_management"
                components={versionConfig.additional_fields_general}
                editable={true}
                onChange={(fields) => {
                  setValue('additional_fields_general', fields);
                }}
              />
            </div>
          </ContentContainer>
          <ContentContainer header="Card issuing reasons">
            {processedCardIssuingReasons.map(({ disabled, label, value }) => (
              <div
                className={styles.checkbox_row}
                key={`cardIssuingReasons[${value}]`}
              >
                <FormCheckBox
                  id={`cardIssuingReasons[${value}]`}
                  label={label}
                  control={control}
                  disabled={disabled}
                  value={value}
                  register={register}
                  hideHelperText
                  defaultValue={
                    (versionConfig.card_issuing_reasons || {})[value] || false
                  }
                />
              </div>
            ))}
            <div className={styles.error}>
              {get(errors, 'cardIssuingReasons.message')}
            </div>
          </ContentContainer>
          <ContentContainer header="Special conditions">
            <UncontrolledTextInput
              id="terms"
              label="Special conditions related to notices and disputes"
              error={get(errors, 'terms.message')}
              inputRef={register}
              defaultValue={versionConfig.terms}
              multiline={true}
              rows={6}
            />
          </ContentContainer>
          <ContentContainer header="Email customisations">
            Customise the content of your cardholder emails. Click{' '}
            <Link onClick={onClickEmailLink}>here</Link> to go to your email
            customisation suite.
          </ContentContainer>
          <ContentContainer header="Website button">
            <ul>
              <li>Copy the embed link (in the boxes below)</li>
              <li>Log into your website editor.</li>
              <li>
                Find the section where you can edit your site&apos;s HTML.
              </li>
              <li>
                Paste the website button link in the relevant section for your
                website.
              </li>
              <li>Save and publish your changes</li>
              <li>Refresh your website and the widget should be visible.</li>
            </ul>
            <EmbeddedComponents.Component
              currentUser={currentUser}
              link={websiteButtonLink}
              logo={logo}
              text="Card order form"
            />
          </ContentContainer>
        </div>
        <PreviewSaveButtons
          moduleName="card_management_module"
          previewAddon={versionConfig}
          previewCallback={handleChange}
        />
      </form>
    </div>
  );
}

const CardManagementModuleComponents = {
  Edit: connect((state) => {
    const currentUser = state.current_user;
    const manageProfile = state.manage_profile;

    const amlRulesArray =
      get(manageProfile, 'settingsAmlCheckRules.selectedLevels') || [];
    const cardholderIdCheckOn = get(
      currentUser,
      'current_entity.attributes.requires_cardholder_identification_check'
    );
    const antiFraudCheckOn =
      get(manageProfile, 'settingsAntiFraud', []).length > 0;
    const amlCheckOn = amlRulesArray.includes('cardholder');
    const signatureRequired =
      antiFraudCheckOn || cardholderIdCheckOn || amlCheckOn;

    return {
      currentCardsAddonConfig: get(
        state,
        'add_ons.cards[0].attributes.history_version.data[0].attributes.config',
        {
          additional_fields: [],
          additional_fields_general: [],
        }
      ),
      signatureRequired,
    };
  })(Edit),
  Item,
};

export default CardManagementModuleComponents;
