import { createApplicationForNewConsumer } from 'modules/consumer-invite/actions/invite';
import {
  ButtonWrapper,
  FormWrapper,
} from 'modules/consumer-invite/v2/ConsumerInvite/Modal/Form/styles';
import Button from 'modules/shared/components/inputs/Button';
import RHFBorderedEmailField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedEmailField';
import useYupValidationResolver from 'modules/shared/hooks/useYupValidationResolver';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form-latest';
// Refer to https://github.com/jquense/yup/issues/507
import isEmail from 'validator/lib/isEmail';
import * as yup from 'yup';

type FormValues = {
  email: string;
};

const validationSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .required('Please enter a valid email')
      .email('Please enter a valid email')
      .test('is-valid', 'Please enter a valid email', (value) =>
        isEmail(value)
      ),
  });

const Form = (props): ReactElement => {
  const {
    applicationType,
    dispatch,
    onErrorCallback,
    onSuccessSendCallback,
  } = props;
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: { email: '' },
    mode: 'onSubmit',
    resolver: useYupValidationResolver(validationSchema),
  });

  const onSubmit = (data) => {
    const { email } = data;

    dispatch(
      createApplicationForNewConsumer({
        application_type: applicationType,
        email,
        errorCallback: onErrorCallback,
        iufAnswers: null,
        overriddenRules: {},
        successCallback: () => onSuccessSendCallback(email),
      })
    );
  };

  return (
    <>
      <div className="mb-4 has-text-centered">
        Please enter the email of the recipient.
      </div>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <div className="columns is-centered">
          <div className="column is-three-quarters">
            <div className="field">
              <div className="control">
                <RHFBorderedEmailField
                  control={control}
                  name="email"
                  withIcon={false}
                  noValidationOnEmpty
                  label="Email address"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          <div className="field">
            <div className="control">
              <ButtonWrapper>
                <Button text="Send" type="submit" />
              </ButtonWrapper>
            </div>
          </div>
        </div>
      </FormWrapper>
    </>
  );
};

export default Form;
