import { StyledDarkGreySpan } from 'modules/reporting/components/Reporting/ApplicationName/styles';
import React, { ReactElement } from 'react';
import { capitalizeSentence } from 'utils/formatting';

interface IProps {
  isFloating?: boolean;
  applicationName: string;
}

const ApplicationName = ({
  isFloating,
  applicationName,
}: IProps): ReactElement => (
  <StyledDarkGreySpan isFloating={isFloating}>
    {capitalizeSentence(applicationName)}
  </StyledDarkGreySpan>
);

export default ApplicationName;
