import api from 'api';
import isPresent from 'utils/isPresent';
import isBlank from 'utils/isBlank';

export const fetchApplicationDetails = async ({
  applicationId,
  currentUser,
  errorCallback,
  successCallback,
}: {
  applicationId: string;
  currentUser: any;
  errorCallback?: (error: any) => void;
  successCallback: (result: any) => void;
}): Promise<void> => {
  const onboardingAPI = api('onboarding', currentUser.accessToken);

  try {
    const result = await onboardingAPI.fetchApplicationDetails(applicationId);

    successCallback(result);
  } catch (error) {
    console.error(error);
    if (errorCallback) {
      errorCallback(error);
    }
  }
};

export const hasDetailsRequired = ({
  application,
  consumerIdOptions,
}: {
  application: any;
  consumerIdOptions: any[];
}): boolean =>
  hasRegion(application) &&
  isPresent(application.supplierTradingNameId) &&
  hasConsumerId(application, consumerIdOptions);

// Private functions
const hasRegion = (application: any) =>
  application.region === 'NZ' ||
  (application.region === 'AU' && isPresent(application.state));

const hasConsumerId = (application, consumerIdOptions) => {
  if (isPresent(application.consumerId)) {
    return true;
  }

  // If the only option is to add a new consumer, then there is no need
  // to select this option
  return consumerIdOptions.length === 1;
};
