export const COB_REVIEW_SUBMIT_DATA_START = 'COB_REVIEW_SUBMIT_DATA_START';
export const COB_REVIEW_SUBMIT_DATA_SUCCESS = 'COB_REVIEW_SUBMIT_DATA_SUCCESS';
export const COB_REVIEW_SUBMIT_DATA_ERROR = 'COB_REVIEW_SUBMIT_DATA_ERROR';

export const COB_REVIEW_SUBMIT_CLEAR_START = 'COB_REVIEW_SUBMIT_CLEAR_START';
export const COB_REVIEW_SUBMIT_CLEAR_ERROR = 'COB_REVIEW_SUBMIT_CLEAR_ERROR';
export const COB_REVIEW_SUBMIT_CLEAR_SUCCESS =
  'COB_REVIEW_SUBMIT_CLEAR_SUCCESS';

export const COB_REVIEW_SUBMIT_NETWORK_BREAK =
  'COB_REVIEW_SUBMIT_NETWORK_BREAK';

export const COB_REVIEW_SET_REVIEW_INDEX = 'COB_REVIEW_SET_REVIEW_INDEX';

export const COB_REVIEW_REDIRECT = 'COB_REVIEW_REDIRECT';

export const COB_REVIEW_SET_DECLARATION_FIRST_NAME =
  'COB_REVIEW_SET_DECLARATION_FIRST_NAME';
export const COB_REVIEW_SET_DECLARATION_EMAIL =
  'COB_REVIEW_SET_DECLARATION_EMAIL';
export const COB_REVIEW_SET_DECLARATION_DOB = 'COB_REVIEW_SET_DECLARATION_DOB';

export const COB_REVIEW_SET_TERMS_REJECT_REASON_TEXT =
  'COB_REVIEW_SET_TERMS_REJECT_REASON_TEXT';

export const COB_REVIEW_ADDRESS_LOOKUP_CLEAR =
  'COB_REVIEW_ADDRESS_LOOKUP_CLEAR';
export const COB_REVIEW_ADDRESS_LOOKUP_START =
  'COB_REVIEW_ADDRESS_LOOKUP_START';
export const COB_REVIEW_ADDRESS_LOOKUP_SUCCESS =
  'COB_REVIEW_ADDRESS_LOOKUP_SUCCESS';
export const COB_REVIEW_ADDRESS_LOOKUP_ERROR =
  'COB_REVIEW_ADDRESS_LOOKUP_ERROR';

export const COB_REVIEW_SET_ADDRESS = 'COB_REVIEW_SET_ADDRESS';
export const COB_REVIEW_SELECT_ADDRESS = 'COB_REVIEW_SELECT_ADDRESS';

export const COB_REVIEW_SET_COMPLETE = 'COB_REVIEW_SET_COMPLETE';

export const USER_UPDATE_START = 'USER_UPDATE_START';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';

export const COB_WEBSITEBUTTON_CREATE_FOR_NEW_CONSUMER_START =
  'COB_WEBSITEBUTTON_CREATE_FOR_NEW_CONSUMER_START';
export const COB_WEBSITEBUTTON_CREATE_FOR_NEW_CONSUMER_SUCCESS =
  'COB_WEBSITEBUTTON_CREATE_FOR_NEW_CONSUMER_SUCCESS';
export const COB_WEBSITEBUTTON_CREATE_FOR_NEW_CONSUMER_ERROR =
  'COB_WEBSITEBUTTON_CREATE_FOR_NEW_CONSUMER_ERROR';

export const COB_WEBSITEBUTTON_CREATE_FOR_CONSUMER_START =
  'COB_WEBSITEBUTTON_CREATE_FOR_CONSUMER_START';
export const COB_WEBSITEBUTTON_CREATE_FOR_CONSUMER_SUCCESS =
  'COB_WEBSITEBUTTON_CREATE_FOR_CONSUMER_SUCCESS';
export const COB_WEBSITEBUTTON_CREATE_FOR_CONSUMER_ERROR =
  'COB_WEBSITEBUTTON_CREATE_FOR_CONSUMER_ERROR';

export const ENTITY_UPDATE_START = 'ENTITY_UPDATE_START';
export const ENTITY_UPDATE_SUCCESS = 'ENTITY_UPDATE_SUCCESS';
export const ENTITY_UPDATE_ERROR = 'ENTITY_UPDATE_ERROR';

export const COB_REVIEW_SUBMIT_APPLICATION_DATA_START =
  'COB_REVIEW_SUBMIT_APPLICATION_DATA_START';
export const COB_REVIEW_SUBMIT_APPLICATION_DATA_SUCCESS =
  'COB_REVIEW_SUBMIT_APPLICATION_DATA_SUCCESS';
export const COB_REVIEW_SUBMIT_APPLICATION_DATA_ERROR =
  'COB_REVIEW_SUBMIT_APPLICATION_DATA_ERROR';

export const USER_UPDATE_IN_START_APPLICATION_START =
  'USER_UPDATE_IN_START_APPLICATION_START';
export const USER_UPDATE_IN_START_APPLICATION_SUCCESS =
  'USER_UPDATE_IN_START_APPLICATION_SUCCESS';
export const USER_UPDATE_IN_START_APPLICATION_ERROR =
  'USER_UPDATE_IN_START_APPLICATION_ERROR';

export const COB_REVIEW_UPDATE_AUTHORISATION_ERROR =
  'COB_REVIEW_UPDATE_AUTHORISATION_ERROR';
export const COB_REVIEW_UPDATE_AUTHORISATION_START =
  'COB_REVIEW_UPDATE_AUTHORISATION_START';
export const COB_REVIEW_UPDATE_AUTHORISATION_SUCCESS =
  'COB_REVIEW_UPDATE_AUTHORISATION_SUCCESS';

export const COB_REVIEW_UPDATE_TRADING_NAMES_ERROR =
  'COB_REVIEW_UPDATE_TRADING_NAMES_ERROR';
export const COB_REVIEW_UPDATE_TRADING_NAMES_START =
  'COB_REVIEW_UPDATE_TRADING_NAMES_START';
export const COB_REVIEW_UPDATE_TRADING_NAMES_SUCCESS =
  'COB_REVIEW_UPDATE_TRADING_NAMES_SUCCESS';

export const COB_REVIEW_SUPPLIER_TERMS_REDIRECT =
  'COB_REVIEW_SUPPLIER_TERMS_REDIRECT';
