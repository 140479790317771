import Complete from '../modules/guarantor/components/Complete';
import Confirm from '../modules/guarantor/components/Confirm';
import GuarantorOnBoarding from '../modules/guarantor/components/GuarantorOnBoarding';
import Review from '../modules/guarantor/components/Review';
import Terms from '../modules/guarantor/components/Terms';
import Layout from '../modules/guarantor/containers/Layout';

const GuarantorReview = [
  {
    path: 'review',
    component: Review,
  },
  {
    path: 'terms',
    component: Terms,
  },
  {
    path: 'complete',
    component: Complete,
  },
  {
    path: 'identity/:component(/**)',
    component: GuarantorOnBoarding,
  },
  {
    path: 'confirm',
    component: Confirm,
  },
];

module.exports = {
  path: 'guarantor',
  component: Layout,
  childRoutes: GuarantorReview,
};
